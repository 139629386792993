import React, { FC, useState, useRef } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import isEmail from 'validator/lib/isEmail';

import { validatePassword, rules } from '../../../utils/Password';
import {
  Button,
  GhostButton,
  InputField,
  Link,
  Typo,
  Checkbox,
  Popper,
  Snackbar,
  ImageContainer,
} from '../../primitives';
import { LoginSignupProps } from './LoginSignup.props';
import { useStyles } from './LoginSignup.styles';
import { AppBar, Icon, SvgIcon, Tab, Tabs } from '@material-ui/core';
import FacebookLogin, {
  ProfileSuccessResponse,
} from '@greatsumini/react-facebook-login';
import { useGoogleLogin } from '@react-oauth/google';

import FacebookIcon from 'assets/svgs/generated/Facebook';
import GoogleIcon from 'assets/svgs/generated/Google';

import { default as MuiButton } from '@material-ui/core/Button';

import { useStyles as useGhostButtonStyles } from '../../primitives/GhostButton/GhostButton.styles';
import { GhostButtonPublicProps } from 'components/primitives/GhostButton/GhostButton.props';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Login = (props: {
  classes: Record<string, any>;
  isMobile: boolean;
  isXs: boolean;
  login: (email: string, password: string, remember: boolean) => void;
  forgotPassword: () => void;
  loading: boolean;
  success: boolean;
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [openPopper, setOpenPopper] = useState(false);

  const pcRef = useRef(null);

  const { classes, isMobile, isXs, login, forgotPassword, loading, success } =
    props;

  const onChangeEmail = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    setEmailError('');
    setEmail(event.target.value);
  };

  const onChangePassword = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    setPasswordError('');
    setPassword(event.target.value);
  };

  const onLogin = () => {
    let hasError = false;
    if (!isEmail(email)) {
      setEmailError('Please enter a valid email');
      hasError = true;
    }

    const vp = validatePassword(password);
    if (Array.isArray(vp) && vp.length > 0) {
      setPasswordError('Please enter a valid password');
      hasError = true;
    }

    if (!hasError) login(email, password, remember);
  };

  return (
    <>
      <InputField
        id="email"
        placeholder="Email"
        type="email"
        value={email}
        error={emailError}
        onChange={onChangeEmail}
      />
      <Box mb={5} />
      <div ref={pcRef}>
        <InputField
          id="password"
          placeholder="Password"
          type="password"
          value={password}
          error={passwordError}
          onChange={onChangePassword}
          onFocus={() => setOpenPopper(true)}
          onBlur={() => setOpenPopper(false)}
        />

        <Popper
          open={openPopper}
          anchorEl={pcRef.current}
          placement={isXs ? 'bottom-end' : 'right'}
          margin={isXs ? '10px 0 0 0' : 20}
          content={
            <Box style={{ padding: '10px 10px 5px 10px' }}>
              {rules.map((rule) => (
                <Typo key={rule} variant="h6" style={{ marginBottom: 5 }}>
                  {rule}
                </Typo>
              ))}
            </Box>
          }
        />
      </div>

      <Box mb={2} />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item sm={6}>
          <Checkbox
            radius="small"
            label="Remember me"
            checked={remember}
            onChange={() => setRemember(!remember)}
            variant="h6"
          />
        </Grid>
        <Grid item sm={6} style={{ flexBasis: 'auto' }}>
          <Link onClick={() => forgotPassword()}>
            <Typo variant="h6">Forgot Password?</Typo>
          </Link>
        </Grid>
      </Grid>

      <Box mb={4} />
      {!isMobile ? (
        <Button
          onClick={onLogin}
          variant="h4"
          text="Login"
          loading={loading}
          success={success}
        />
      ) : (
        <GhostButton
          text="Login"
          style={classes.ghostButton}
          onClick={onLogin}
          loading={loading}
          success={success}
        />
      )}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Signup = (props: {
  classes: Record<string, any>;
  isMobile: boolean;
  isXs: boolean;
  signup: (email: string, password: string, subscribe: boolean) => void;
  loading: boolean;
  success: boolean;
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [subscribed, setSubscribed] = React.useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [openPopper, setOpenPopper] = useState(false);

  const pcRef = useRef(null);

  const { classes, isMobile, isXs, signup, loading, success } = props;

  const onChangeEmail = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    setEmailError('');
    setEmail(event.target.value);
  };

  const onChangePassword = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    setPasswordError('');
    setPassword(event.target.value);
  };

  const onSignup = () => {
    let hasError = false;
    if (!isEmail(email)) {
      setEmailError('Please enter a valid email');
      hasError = true;
    }

    const vp = validatePassword(password);
    if (Array.isArray(vp) && vp.length > 0) {
      setPasswordError('Please enter a valid password');
      hasError = true;
    }

    if (!hasError && !loading) signup(email, password, subscribed);
  };

  return (
    <>
      <Typo variant="body3">
        Being Unyoked is a state of mind, a way of being. You&apos;re one of us,
        we&apos;re one of you. Let&apos;s make it official.
      </Typo>
      <Box mb={4} />
      <InputField
        id="email"
        placeholder="Email"
        type="email"
        value={email}
        error={emailError}
        onChange={onChangeEmail}
      />
      <Box mb={5} />
      <div ref={pcRef}>
        <InputField
          id="password"
          placeholder="Password"
          type="password"
          value={password}
          error={passwordError}
          onChange={onChangePassword}
          onFocus={() => setOpenPopper(true)}
          onBlur={() => setOpenPopper(false)}
        />

        <Popper
          open={openPopper}
          anchorEl={pcRef.current}
          placement={isXs ? 'bottom-end' : 'right'}
          margin={isXs ? '10px 0 0 0' : 20}
          content={
            <Box style={{ padding: '10px 10px 5px 10px' }}>
              {rules.map((rule) => (
                <Typo key={rule} variant="h6" style={{ marginBottom: 5 }}>
                  {rule}
                </Typo>
              ))}
            </Box>
          }
        />
      </div>
      <Box mb={3} />
      <Checkbox
        id="subscribe"
        radius="small"
        label="Subscribe to Newsletter"
        checked={subscribed}
        onChange={() => setSubscribed(!subscribed)}
        variant="h6"
      />
      <Box mb={4} />
      {!isMobile ? (
        <Button
          onClick={onSignup}
          variant="h4"
          text="Create"
          loading={loading}
          success={success}
        />
      ) : (
        <GhostButton
          text="Create"
          style={classes.ghostButton}
          onClick={onSignup}
          loading={loading}
          success={success}
        />
      )}
    </>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
};

const LoginSignupView: FC<LoginSignupProps> = ({
  login,
  fbLogin,
  googleLogin,
  forgotPassword,
  signup,
  loginLoading,
  loginSuccess,
  signupLoading,
  signupSuccess,
  openErrorSnack,
  errorMessage,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const classes = useStyles();
  const ghostClasses = useGhostButtonStyles({} as GhostButtonPublicProps);

  const [tabValue, setTabValue] = useState(0);
  let fbData: { access_token: string; userID: string };

  const fbAppId = import.meta.env.VITE_FACEBOOK_APP_ID;

  const onFacebookLogin = (profile: ProfileSuccessResponse) => {
    if (fbData?.access_token && fbData?.userID) {
      fbLogin(fbData);
    }
  };

  const onGoogleLogin = useGoogleLogin({
    onSuccess: async (res) => {
      const userResponse = await fetch(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${res.access_token}`
      );
      const user = await userResponse.json();

      if (
        user.email &&
        user.given_name &&
        user.family_name &&
        user.picture &&
        res?.access_token &&
        user?.sub
      ) {
        googleLogin({
          email: user.email,
          firstName: user.given_name,
          lastName: user.family_name,
          name: user.name,
          picture: user.picture,
          access_token: res.access_token,
          userID: user.sub,
        });
      }
    },
  });

  return (
    <Grid container justifyContent="center" spacing={6}>
      <Grid
        item
        xs={12}
        sm={6}
        style={
          isXs
            ? {
                marginTop: theme.spacing(10),
                paddingBottom: theme.spacing(10),
              }
            : {
                marginTop: theme.spacing(20),
                marginBottom: theme.spacing(20),
              }
        }
      >
        <AppBar position="static">
          <Tabs
            value={tabValue}
            onChange={(_e, val) => setTabValue(val)}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
            scrollButtons="auto"
            aria-label="scrollable auto tab"
          >
            <Tab label="Login" {...a11yProps(0)} />
            <Tab label="Create An Account" {...a11yProps(1)} />
          </Tabs>
        </AppBar>

        <Box textAlign={'Center'} mt={4}>
          <FacebookLogin
            appId={fbAppId}
            fields="name,first_name,last_name,email,picture"
            scope="email,user_about_me,public_profile"
            onSuccess={(response) => {
              fbData = {
                access_token: response.accessToken,
                userID: response.userID,
              };

              onFacebookLogin(fbData);
            }}
            onFail={(error) => {
              console.log('Login Failed!', error);
            }}
            render={({ onClick, logout }) => (
              <MuiButton
                className={`${ghostClasses.button} ${classes.loginButton}`}
                onClick={onClick}
              >
                <FacebookIcon
                  style={{
                    width: 25,
                    height: 25,
                    marginTop: '-5px',
                    marginRight: '10px',
                  }}
                />{' '}
                Facebook
              </MuiButton>
            )}
          />

          <MuiButton
            className={`${ghostClasses.button} ${classes.loginButton}`}
            onClick={() => onGoogleLogin()}
          >
            <GoogleIcon
              style={{
                width: 25,
                height: 25,
                marginTop: '-5px',
                marginRight: '10px',
              }}
            />{' '}
            Google
          </MuiButton>

          {/* <GhostButton style={classes.loginButton} text="Apple" /> */}
        </Box>

        <Box textAlign={'Center'} mb={2} mt={3}>
          ------ Or continue with ------
        </Box>

        <TabPanel value={tabValue} index={0}>
          <Login
            classes={classes}
            isMobile={isMobile}
            isXs={isXs}
            login={login}
            forgotPassword={forgotPassword}
            loading={loginLoading}
            success={loginSuccess}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Signup
            classes={classes}
            isMobile={isMobile}
            isXs={isXs}
            signup={signup}
            loading={signupLoading}
            success={signupSuccess}
          />
        </TabPanel>

        {tabValue == 1 ? (
          <Box m={3}>
            <Typo variant="caption1">
              When you create an Unyoked account, we collect your email and
              personal data to enhance your shopping experience and, subject to
              your agreement, to provide you with exclusive email updates,
              promotions, and notifications.
            </Typo>
          </Box>
        ) : null}
      </Grid>

      <Snackbar message={errorMessage} open={openErrorSnack} type="warning" />
    </Grid>
  );
};

export default React.memo(LoginSignupView);
