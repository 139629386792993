import React, { FC, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { WishlistImage } from '../../../assets/images';
import { WaitlistModalContent } from '../../modules';
import { Button, GhostButton, ImageContainer, Typo } from '../../primitives';
import Modal from '../../primitives/Modal';
import { WaitlistBlockProps } from './WaitlistBlock.props';
import { useStyles } from './WaitlistBlock.styles';

// @ts-ignore
const Description: FC<T> = ({
  isMobile,
  classes,
  textColor,
  buttonColor,
  setOpen,
  content,
}) => {
  return (
    <Grid item xs={12} md={6} className={classes.leftContainer}>
      <Box pr={'20%'}>
        <Box mb={1}>
          <Typo
            variant={isMobile ? 'h3' : 'h1'}
            color={textColor}
            className={classes.notAvailable}
          >
            {content.subTitle}
          </Typo>
        </Box>
        <Typo variant={isMobile ? 'body3' : 'body2'} color={textColor}>
          {content.description}
        </Typo>
        {!isMobile && (
          <Box mt={8}>
            <Button
              text={content.ctaLabel}
              onClick={() => setOpen(true)}
              color={buttonColor}
            />
          </Box>
        )}
      </Box>
    </Grid>
  );
};

const WaitlistBlockView: FC<WaitlistBlockProps> = (props) => {
  const [open, setOpen] = useState(false);
  const { textColor, buttonColor, ghostButtonHoverColor, content } = props;
  const theme = useTheme();
  // @ts-ignore
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });
  return (
    <Grid container>
      {!isMobile && (
        <Grid item xs={12}>
          <Box mb={8}>
            <Typo
              variant="h3"
              color={textColor}
              className={classes.beFirstDesktop}
            >
              {content.title}
            </Typo>
          </Box>
        </Grid>
      )}
      {!isMobile && (
        <Description
          setOpen={setOpen}
          isMobile={isMobile}
          classes={classes}
          textColor={textColor}
          buttonColor={buttonColor}
          content={props.content}
        />
      )}
      <Grid item xs={12} md={6}>
        <ImageContainer
          alt="wishlist"
          src={content.image}
          className={classes.image}
        />
        {isMobile && (
          <Box mb={3}>
            <Typo variant="h6" color={textColor}>
              {content.title}
            </Typo>
          </Box>
        )}
      </Grid>
      {isMobile && (
        <Description
          setOpen={setOpen}
          isMobile={isMobile}
          classes={classes}
          textColor={textColor}
          buttonColor={buttonColor}
          content={props.content}
        />
      )}
      {isMobile && (
        <Box mt={5}>
          <GhostButton
            style={classes.button}
            text="Take me to the waitlist"
            color={buttonColor}
            hoverColor={ghostButtonHoverColor}
            onClick={() => setOpen(true)}
          />
        </Box>
      )}

      <Modal
        open={open}
        onClose={setOpen}
        onBackdropClick={() => setOpen(false)}
      >
        <WaitlistModalContent closeModal={() => setOpen(false)} />
      </Modal>
    </Grid>
  );
};

export default React.memo(WaitlistBlockView);
