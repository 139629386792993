import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';
import { pxToRem } from '../../../themeConfig/typography';

interface StyleProps {
  isMobile: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    },
    description: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 26,
      },
    },
    arrow: {
      fontSize: pxToRem(12),
    },
    horizontalLine: {
      backgroundColor: `${colors.primaryDarkGreen} !important`,
      height: 2.5,
    },
    contentContainer: {
      marginTop: theme.spacing(5),
    },
    scrollableCards: {
      display: 'flex',
      flexWrap: 'nowrap',
      overflowX: 'auto',
      overflowY: 'hidden',
    },
    houseCard: ({isMobile}) =>({
      flex: '0 0 auto',
      width: isMobile ? '80%' : 180,
      marginRight: 20,
    }),
    textLink: {
      cursor: 'pointer',
      fontStyle: 'italic',
      '&:hover': {
        borderBottom: `2px solid ${colors.primaryDarkGreen}`,
      },
    },
    ghostButton: ({ isMobile }) => ({
      marginTop: theme.spacing(2),
      width: isMobile ? '100%' : 180,
    }),

    detailsModalContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100dvw',
      position: 'relative',
      height: '100dvh',
    },

    closeBtn: ({ isMobile }) =>({
      position: 'absolute',
      zIndex: 1000,
      top: isMobile ? '5vh' : '8xp',
      right: '0px',

      '& svg > path':  {
        stroke: 'black',
        strokeWidth: '1px',
      }
    }),

    detailsModal: ({isMobile}: StyleProps) => (
      isMobile ? 
        {
          position: 'relative',
          width: '100dvw',
          height: '80dvh',
          overflowY: 'scroll',
        } :
        {
          position: 'relative',
          width: '800px',
          height: '800px',
          borderRadius: '4px',
          background: colors.primaryDarkGreen,
          overflowY: 'scroll',
        }),

    postcardImageContainer: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      justifyContent: 'center',
      overflow: 'hidden',
      
      '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      }
    },

    provisions: ({isMobile}) => ( {
      width: isMobile ? '100%' : 200
    }) 

  })
);
