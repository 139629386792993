import { Contentful } from 'types/Contentful';
import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'CONTENTFUL';
const createContentfulActions = makeCreateActions(ns);

const contentfulActions = createContentfulActions({
  getEntry: presetActions.makeAsyncAction<Contentful>(`${ns}/GET_ENTRY`),
});

export default contentfulActions;
