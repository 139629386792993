import React, { FC, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CURRENCY_SYMBOL } from 'consts/locations';

import {
  Button,
  GhostButton,
  HorizontalLine,
  ImageContainer,
  Modal,
  Typo,
} from '../../primitives';
import { VoucherCardProps } from './VoucherCard.props';
import { useStyles } from './VoucherCard.styles';

const VoucherCardView: FC<VoucherCardProps> = (props) => {
  const {
    id,
    symptom,
    image,
    title,
    price,
    currency,
    isInPaymentsPage,
    provisions,
    currentUser,
    countryCode,
  } = props;

  const onPrescribe = (payload: Record<string, any>) => {
    props.onPrescribe(payload);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });
  const currencySymbol = CURRENCY_SYMBOL[currency];

  return (
    <>
      <Box
        className={classes.container}
        onClick={() =>
          isInPaymentsPage || !isMobile
            ? {}
            : onPrescribe({
                id,
                symptom,
                image,
                title,
                price,
                provisions,
                currency,
                countryCode,
              })
        }
      >
        <HorizontalLine className={classes.line} />
        {symptom && !isInPaymentsPage && (
          <Box mt={1}>
            <Typo variant="h5">Symptoms</Typo>
            <Typo variant="body1">{symptom}</Typo>
          </Box>
        )}
        {isInPaymentsPage && (
          <Box mt={1}>
            <Typo variant="h5">Purchase Summary</Typo>
          </Box>
        )}
        <ImageContainer
          alt="voucher-card"
          className={classes.image}
          src={image}
        />
        <Box mb={2}>
          {!isMobile && !isInPaymentsPage && (
            <>
              <Typo variant="h5">Prescription</Typo>
              <Typo variant="body1">{title}</Typo>
            </>
          )}
          {isMobile && !isInPaymentsPage && (
            <Grid container justifyContent="space-between" spacing={1}>
              <Grid item>
                <Typo variant="h5">Prescription</Typo>
                <Typo variant="body1">{title}</Typo>
              </Grid>
              <Grid item>
                <Typo variant="h4">${price}</Typo>
              </Grid>
            </Grid>
          )}
          {isInPaymentsPage && symptom && (
            <>
              <Typo variant="h5">Symptoms</Typo>
              <Typo variant="body1">{symptom}</Typo>
            </>
          )}
        </Box>
        {!isMobile && !isInPaymentsPage && (
          <Box className={classes.prescribe}>
            <Button
              text={`${currencySymbol}${price}`}
              iconText="Prescribe"
              variant="h4"
              iconTextVariant="h6"
              onClick={() =>
                onPrescribe({
                  id,
                  symptom,
                  image,
                  title,
                  price,
                  provisions,
                  currency,
                  countryCode,
                })
              }
            />
          </Box>
        )}
        {isMobile && !isInPaymentsPage && (
          <GhostButton
            text="Prescribe"
            onClick={() =>
              onPrescribe({
                id,
                symptom,
                image,
                title,
                price,
                provisions,
                currency,
                countryCode,
              })
            }
            style={classes.ghostButton}
          />
        )}
      </Box>
    </>
  );
};

export default React.memo(VoucherCardView);
