import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import strikethrough from '../../../assets/svgs/strikethrough.svg';
import { colors } from '../../../themeConfig/themeConstants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      color: colors.primaryDarkGreen,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('lg')]: {
        marginTop: 0,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },
    carousel: {
      height: '100%',
      '& .slick-slide': {},
      '& .slick-arrow': {
        display: 'block',
        position: 'absolute',
        top: '25%',
        height: 33,
        width: 15,
        transform: 'translateY(-50%) scale(1.5)',
        zIndex: 5,
        borderBottom: `2px solid transparent`,
        '&:hover': {
          borderBottom: `2px solid ${colors.primaryDarkGreen}`,
        },
        [theme.breakpoints.up('sm')]: {
          top: '50%',
        },
      },
      '& .slick-arrow > path': {
        stroke: colors.primaryDarkGreen,
      },
      '& .slick-next': {
        right: '2%',
        color: colors.primaryDarkGreen,
      },
      '& .slick-prev': {
        left: '2%',
        transform: 'translateY(-50%) scale(1.5)',
      },
    },
    carouselContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingTop: 0,
      [theme.breakpoints.up('md')]: {
        paddingTop: 50,
      },
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    bigIcon: {
      width: '100%',
      height: 'auto',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    plainTitle: {
      whiteSpace: 'pre-line',
      marginLeft: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        fontSize: 44,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 66,
      },
    },
    categoryRead: {
      fontSize: 16,
      color: colors.primaryDarkGreen,
    },
    title: {
      lineHeight: '120%',
      display: '-webkit-box',
      '-webkit-line-clamp': 5,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontSize: 40,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 46,
      },
    },
    subTitle: {
      lineHeight: '120%',
      display: '-webkit-box',
      '-webkit-line-clamp': 5,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 30,
      },
    },
    inlineTitle: {
      display: 'inline',
    },
    strikethrough: {
      content: '""',
      position: 'absolute',
      top: '45%',
      left: '-5%',
      height: '100%',
      backgroundImage: `url(${strikethrough})`,
      backgroundRepeat: 'no-repeat',
      transform: 'rotate(3deg)',
      '-webkit-transition': '0.4s ease-out',
      transition: '0.4s ease-out',
    },
    caption: {
      textAlign: 'center',
    },
    image: {
      width: '100%',
      minHeight: (props: { contentHeight: number }) =>
        props.contentHeight - 400,
      marginBottom: theme.spacing(3),
      objectFit: 'cover',
      [theme.breakpoints.up('lg')]: {
        maxHeight: (props: { contentHeight: number }) =>
          props.contentHeight - 400,
      },
    },
    rightContainer: {
      paddingLeft: '5%',
      paddingRight: '5%',
      color: colors.primaryDarkGreen,

      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: '5%',
      },

      '& *': {
        color: 'inherit',
      },
    },
    joinDesktop: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 32,
      },
    },
    introContainer: {
      maxHeight: 160,
      whiteSpace: 'pre-line',
      textOverflow: 'ellipsis',
    },
    intro: {
      fontSize: 16,
    },
    description: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    },
    link: {
      textDecoration: 'none',
    },
    readMore: {
      borderColor: colors.primaryDarkGreen,
      width: 200,
    },
  })
);
