import { UnyokedCountry } from 'consts/locations';
import { Dispatch } from 'redux';
import { getVouchersEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { vouchersActions } from 'store/actions';

import { Store } from '../../types/store/Store';

interface Data {}

const getVouchers =
  (country: UnyokedCountry) => (dispatch: Dispatch, getState: () => Store) => {
    dispatch(
      vouchersActions.getAll(
        apiFetch<Data, any>(getVouchersEndpoint, country)(dispatch, getState)
      )
    );
  };

export default getVouchers;
