import React, { FC } from 'react';

import { CarouselPublicProps } from './Carousel.props';
import View from './Carousel.view';

const CarouselContainer: FC<CarouselPublicProps> = (
  props: CarouselPublicProps
) => <View {...props} />;

export default React.memo(CarouselContainer);
