import React, { FC } from 'react';

import { HouseSpecificationIconPublicProps } from './HouseSpecificationIcon.props';
import View from './HouseSpecificationIcon.view';

const HouseSpecificationIconContainer: FC<HouseSpecificationIconPublicProps> = (
  props: HouseSpecificationIconPublicProps
) => <View {...props} />;

export default React.memo(HouseSpecificationIconContainer);
