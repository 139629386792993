import React, { FC } from 'react';

import { TextAreaPublicProps } from './TextArea.props';
import View from './TextArea.view';

const TextAreaContainer: FC<TextAreaPublicProps> = (
  props: TextAreaPublicProps
) => <View {...props} />;

export default React.memo(TextAreaContainer);
