import React, { FC, Fragment, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import 'react-phone-number-input/style.css';
import { useSpring, animated } from 'react-spring';

import { HouseCardPayments } from '../../modules';
import { GhostButton, Typo } from '../../primitives';
import ConfirmAndPay from './components/ConfirmAndPay';
import HouseReview from './components/HouseReview';
import { HousePaymentsProps } from './HousePayments.props';
import { useStyles } from './HousePayments.styles';
import { getCabinTime } from 'consts/cabins';

export const nav = ['3. Review', '4. Confirm & Pay'];

const HousePaymentsView: FC<HousePaymentsProps> = (props) => {
  const {
    checkin,
    checkout,
    houseRules,
    houseCard,
    timezone,
    total,
    totalPrice,
    totalProvPrice,
    priceDetails,
    nights,
    currentGuests,
    setCurrentGuests,
    specialAddons,
    addons,
    notes,
    setNotes,
    checkedProvisions,
    setCheckedProvisions,
    bookingDetails,
    voucherType,
    voucherId,
    voucherDiscount,
    discount,
    discountValue,
    initialUserValues,
    isLoggedIn,
    goToLogin,
    bookingData,
    hasAddedProvisions,
    redirectStep,
    trackCheckoutStep,
    pricePerNight,
  } = props;

  // @ts-ignore
  const { addProvisions }: { addProvisions: boolean } = bookingData;

  const [confirm, setConfirm] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(!isLoggedIn);
  const [currentNav, setCurrentNav] = useState(
    addProvisions ? 1 : redirectStep
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles({ isXs });

  const onClickGhostButton = () => {
    if (currentNav !== 2) {
      window.scrollTo(0, 800);
      setOpenSnackbar(false);

      if (currentNav === 0) setCurrentNav(1);
      if (currentNav === 1) setCurrentNav(2);
    } else {
      setConfirm((prevState) => prevState + 1);
    }
  };

  const onClickNav = (index: number) => {
    if (addProvisions && !hasAddedProvisions && index === 2) return;
    setCurrentNav(index);
  };

  useEffect(() => {
    if (currentNav === 1 && !isLoggedIn) {
      goToLogin();
      return;
    }

    const navNum = currentNav ?? 0;
    console.log('checkout step tracking ', nav[navNum]);
    trackCheckoutStep(nav[navNum]);
  }, [currentNav]);

  const spring1 = useSpring({
    display: currentNav === 0 ? 'block' : 'none',
    opacity: currentNav === 0 ? 1 : 0,
  });
  const spring2 = useSpring({
    display: currentNav === 1 ? 'block' : 'none',
    opacity: currentNav === 1 ? 1 : 0,
  });

  const cabinTime = getCabinTime(houseCard.id);

  const CheckoutProcess = (
    <Grid item xs={12} sm={9}>
      <Box pr={isXs ? 0 : '15%'}>
        <animated.div style={spring1}>
          <HouseReview
            isMobile={isMobile}
            isXs={isXs}
            classes={classes}
            checkin={checkin}
            checkout={checkout}
            nights={nights}
            setCurrentNav={setCurrentNav}
            trackCheckoutStep={trackCheckoutStep}
            currentGuests={currentGuests}
            setCurrentGuests={setCurrentGuests}
            capacity={houseCard.capacity}
            specialAddons={specialAddons}
            country={houseCard.country}
            addons={addons}
            setCheckedProvisions={setCheckedProvisions}
            checkedProvisions={checkedProvisions}
            notes={notes}
            setNotes={setNotes}
            addProvisions={addProvisions}
            cabinTime={cabinTime}
          />
        </animated.div>
        <animated.div style={spring2}>
          <ConfirmAndPay
            bookingDetails={bookingDetails}
            isXs={isXs}
            isMobile={isMobile}
            name={houseCard.name}
            country={houseCard.country}
            houseCard={houseCard}
            classes={classes}
            addons={addons}
            guests={currentGuests}
            notes={notes}
            voucherType={voucherType}
            specialAddons={specialAddons}
            voucherId={voucherId}
            discount={discount}
            discountValue={discountValue}
            voucherDiscount={voucherDiscount}
            confirm={confirm}
            setConfirmLoading={setConfirmLoading}
            initialUserValues={initialUserValues}
            addProvisions={addProvisions}
            total={total}
            totalProvPrice={totalProvPrice}
            totalStay={nights}
            trackCheckoutStep={trackCheckoutStep}
            pricePerNight={pricePerNight}
            setCheckedProvisions={setCheckedProvisions}
            checkedProvisions={checkedProvisions}
          />
        </animated.div>
      </Box>
    </Grid>
  );

  const CheckoutCard = (
    <Grid item xs={12} sm={3}>
      <Box mt={isXs ? 0 : 7} pt={0}>
        <HouseCardPayments
          checkin={checkin}
          checkout={checkout}
          timezone={timezone}
          totalPrice={totalPrice}
          priceDetails={priceDetails}
          houseCardProps={houseCard}
          addProvisions={addProvisions}
          cabinTime={cabinTime}
          discount={discountValue}
        />
      </Box>
    </Grid>
  );

  const ResultsNav = (
    <>
      <div>
        <Box
          className={classes.navButton}
          onClick={props.goToResults}
          style={{
            cursor: 'pointer',
          }}
        >
          <Typo variant="h6" style={{ opacity: 0.5 }}>
            1. Cabin Search Results
          </Typo>
        </Box>
        <Box ml={2} mr={2}>
          <ArrowForwardIcon
            className={classes.navArrow}
            style={{ opacity: 0.5 }}
          />
        </Box>
      </div>
      <div>
        <Box
          className={classes.navButton}
          onClick={props.goToCabin}
          style={{
            cursor: 'pointer',
          }}
        >
          <Typo variant="h6" style={{ opacity: 0.5 }}>
            2. {houseCard.name}
          </Typo>
        </Box>
        <Box ml={2} mr={2}>
          <ArrowForwardIcon
            className={classes.navArrow}
            style={{ opacity: 0.5 }}
          />
        </Box>
      </div>
    </>
  );

  return (
    <Box>
      {isXs && (
        <>
          <Grid container alignItems="center" className={classes.breadcrumbs}>
            {ResultsNav}
            {nav.map((text, index) => (
              <div key={index}>
                <Box
                  className={classes.navButton}
                  onClick={() => onClickNav(index)}
                  style={{
                    cursor:
                      addProvisions && !hasAddedProvisions && index === 2
                        ? 'default'
                        : 'pointer',
                  }}
                >
                  <Typo
                    variant="h6"
                    style={{ opacity: currentNav === index ? 1 : 0.5 }}
                  >
                    {text}
                  </Typo>
                </Box>
                {index !== nav.length - 1 && (
                  <Box ml={2} mr={2}>
                    <ArrowForwardIcon
                      className={classes.navArrow}
                      style={{ opacity: currentNav === index ? 1 : 0.5 }}
                    />
                  </Box>
                )}
              </div>
            ))}
          </Grid>
          <Box mb={4}></Box>
          {CheckoutCard}
          <Box mb={4}></Box>
        </>
      )}
      <Grid container alignItems="center" className={classes.breadcrumbs}>
        {!isXs && (
          <>
            {ResultsNav}
            {nav.map((text, index) => (
              <Fragment key={index}>
                <Box
                  className={classes.navButton}
                  onClick={() => onClickNav(index)}
                  style={{
                    cursor:
                      addProvisions && !hasAddedProvisions && index === 2
                        ? 'default'
                        : 'pointer',
                  }}
                >
                  <Typo
                    variant="h6"
                    style={{ opacity: currentNav === index ? 1 : 0.5 }}
                  >
                    {text}
                  </Typo>
                </Box>
                {index !== nav.length - 1 && (
                  <Box ml={2} mr={2}>
                    <ArrowForwardIcon
                      className={classes.navArrow}
                      style={{ opacity: currentNav === index ? 1 : 0.5 }}
                    />
                  </Box>
                )}
              </Fragment>
            ))}
          </>
        )}
      </Grid>
      <Box mb={5} />
      <Grid container>
        {!isXs ? [CheckoutProcess, CheckoutCard] : [CheckoutProcess]}
      </Grid>

      {isXs && (currentNav || 0) < 1 && (
        <Box mt={3}>
          <GhostButton
            text={currentNav === 1 ? 'Confirm' : 'Continue'}
            loading={confirmLoading}
            style={classes.ghostButton}
            onClick={onClickGhostButton}
            disabled={addProvisions && !hasAddedProvisions}
          />
        </Box>
      )}

      {/* <Snackbar */}
      {/*  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} */}
      {/*  open={openSnackbar} */}
      {/*  type={'warning'} */}
      {/*  message={'Please login or signup to book the cabin.'} */}
      {/*  autoHideDuration={null} */}
      {/* /> */}
    </Box>
  );
};

export default React.memo(HousePaymentsView);
