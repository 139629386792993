import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    line: {
      backgroundColor: colors.primaryDarkGreen,
      height: 1,
    },
    priceDetail: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    value: {
      display: 'flex',
      alignItems: 'flex-end',
    },
  })
);
