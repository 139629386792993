import React, { FC } from 'react';

import { WidgetPublicProps } from './Widget.props';
import View from './Widget.view';

const WidgetContainer: FC<WidgetPublicProps> = (props: WidgetPublicProps) => (
  <View {...props} />
);

export default React.memo(WidgetContainer);
