import { Contentful } from 'types/Contentful';
import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'JOURNAL';
const createJournalActions = makeCreateActions(ns);

const journalActions = createJournalActions({
  getAll: presetActions.makeAsyncAction<Contentful[]>(`${ns}/GET_ALL`),
  getById: presetActions.makeAsyncAction<Contentful[]>(`${ns}/GET_BY_ID`),
});

export default journalActions;
