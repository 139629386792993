/* eslint-disable prettier/prettier */
import React, { FC, Fragment, useEffect, useState } from 'react';

import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import { CheckboxChecked2, CheckboxUnchecked } from 'assets/svgs';
import { GhostButton } from 'components/primitives';
import Typo from 'components/primitives/Typo';
import { FEATURE_FLAGS } from 'consts/featureFlags';
import { UnyokedCountry } from 'consts/locations';
import { SHOP_URL } from 'consts/urls';
import { pick } from 'ramda';
import { useDispatch } from 'react-redux';
import ROUTES from 'routes';
import { getContentfulEntry } from 'services/fetch/apiEndpoints';
import { countryActions, filterActions, routerActions } from 'store/actions';
import { City } from 'types/City';
import { Country } from 'types/Country';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { LocationsState } from 'types/store/CountryState';
import { FeatureFlagsState } from 'types/store/FeatureFlagState';
import { FilterState } from 'types/store/FilterState';
import { useQueryWrapper } from 'utils/Hooks/useQueryWrapper';
import { asyncData } from 'utils/Redux';

import useSelectorSafe from '../../../store/selectors/useSelectorSafe';
import { WaitlistModalContent } from '../../modules';
import Modal from '../../primitives/Modal';
import { FooterProps, SocialIcon } from './Footer.props';
import { useStyles } from './Footer.styles';
import View from './Footer.view';
import waitlist from 'thunks/common/waitlist';
import { AU } from './AURedirects';

const locationsFallback: LocationsState = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not get locations' },
]);

export let openCountryModal = () => {};

const FooterContainer: FC<FooterProps> = (props: FooterProps) => {
  const query: object = useSelectorSafe<object>(
    (state) => state.location.query,
    {
      openWaitlist: 'false',
    }
  );

  const payload: object = useSelectorSafe<object>(
    (state) => state.location.payload,
    {
      openWaitlist: false,
    }
  );

  const selectedCountry = useSelectorSafe((state) => state.country);
  const classes = useStyles({});
  // @ts-ignore
  const [open, setOpen] = useState(
    query.openWaitlist === 'true' || payload?.openWaitlist
  );
  const [countryModalOpen, setCountryModalOpen] = React.useState(false);
  const [checkedCountry, setCheckedCountry] = React.useState<UnyokedCountry>(
    selectedCountry?.countryCode || 'AU'
  );

  useEffect(() => {
    openCountryModal = () => setCountryModalOpen(true);
  }, []);

  const dispatch = useDispatch();

  const locations = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<LocationsState>(
      (state) => state.locations,
      locationsFallback
    )
  );

  const featureFlags = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<FeatureFlagsState>(
      (state) => state.featureFlags,
      asyncData(ASYNC_STATUS.ERROR, [
        { message: 'Could not get feature flags' },
      ])
    )
  );

  const { data: ribbonData } = useQueryWrapper<string, any>(
    ['friendbuyRibbon'],
    getContentfulEntry,
    '6JmKc7cpj3v6SnWprL3bcC',
    {
      refetchInterval: 3000000,
      staleTime: 3000000,
    }
  );
  const ribbonLabel = ribbonData?.payload?.leftBlock || 'Get 15% Off';

  // @ts-ignore
  const showRefer = featureFlags.data?.[FEATURE_FLAGS.REFER_CTA];

  const cities = locations.data?.cities;
  const countries = locations.data?.countries;
  const exploreLinks = [];

  const filterData = useSelectorSafe<FilterState>(
    (state) => state.filter,
    null
  );

  const onCountryChange = (value: string) => {
    countries &&
      dispatch(
        countryActions.set({
          countryCode: countries[value].code || 'AU',
          countryName: countries[value].name || 'Australia',
        })
      );

    if (value !== selectedCountry?.countryCode) {
      const rootLink = `_${value}`;
      const link = `ROOT${value === 'AU' ? '' : rootLink}`;
      setTimeout(() => new Promise((r) => r()), 200);
      dispatch(routerActions.link(ROUTES[link]));
    }
  };

  const countryAction = (params: Country) => {
    const payload = {
      ...filterData,
      location: {
        address: '',
        cityId: '',
        country: params.code,
      },
    };
    dispatch(filterActions.updated(payload));
    dispatch(
      routerActions.link(ROUTES.RESULTS, {
        country: params.code,
      })
    );
  };

  const citiesAction = (params: City) => {
    const redirect = AU[params.name];
    if (redirect) {
      window.location.href = redirect;
      return;
    }

    const payload = {
      ...filterData,
      location: {
        address: params.name,
        cityId: params.id,
        country: params.country.code,
      },
    };
    dispatch(filterActions.updated(payload));
    dispatch(
      routerActions.link(ROUTES.RESULTS, {
        country: params.country.code,
        location: params.name,
      })
    );
  };

  for (const code in cities) {
    exploreLinks.push({
      head: null,
      code,
      title: `${countries && countries[code].name}`,
      onClick: () => {
        countries
          ? countryAction(countries[code])
          : dispatch(routerActions.link(ROUTES.RESULTS, { country: code }));
      },
    });
    for (const city of cities[code]) {
      exploreLinks.push({
        head: code,
        title: city.name,
        onClick: () => {
          citiesAction(city);
        },
      });
    }
  }

  if (!featureFlags.data) {
    return <></>;
  }

  const showPrescription =
    selectedCountry?.countryCode !== 'NZ' ||
    (featureFlags?.data &&
      // @ts-ignore
      featureFlags?.data[FEATURE_FLAGS.SHOW_NZ_PRESCRIPTIONS]);
  const prescriptionLink = showPrescription
    ? [
        {
          title: 'Gift Prescriptions',
          onClick: () => dispatch(routerActions.link(ROUTES.VOUCHERS)),
        },
      ]
    : [];

  const moreItemsDesktop = {
    header: 'More',
    links: [
      {
        title: 'Store',
        onClick: () => window.location.assign(SHOP_URL),
      },
      {
        title: 'Waitlist',
        onClick: () => setOpen(true),
      },
      ...prescriptionLink,
      {
        title: 'Provisions',
        onClick: () => dispatch(routerActions.link(ROUTES.PROVISIONS)),
      },
      {
        title: showRefer ? ribbonLabel : '',
        onClick: () => window.location.assign('/refer?cta=footer'),
      },
      {
        title: 'Cabin Quiz',
        onClick: () => dispatch(routerActions.link(ROUTES.CABIN_QUIZ)),
      },
    ],
  };

  const moreItemsMobile = {
    header: 'More',
    links: [
      {
        title: 'Store',
        onClick: () => window.location.assign(SHOP_URL),
      },
      {
        title: 'Waitlist',
        onClick: () => setOpen(true),
      },
      ...prescriptionLink,
      {
        title: 'Provisions',
        onClick: () => dispatch(routerActions.link(ROUTES.PROVISIONS)),
      },
      {
        title: showRefer ? ribbonLabel : '',
        onClick: () => window.location.assign('/refer?cta=footer'),
      },
      {
        title: 'Cabin Quiz',
        onClick: () => dispatch(routerActions.link(ROUTES.CABIN_QUIZ)),
      },
    ],
  };

  const items = [
    {
      header: 'Escape From',
      links: exploreLinks,
    },
    {
      header: 'About',
      links: [
        {
          title: 'About Us',
          onClick: () => dispatch(routerActions.link(ROUTES.ABOUT)),
        },
        /*{
          title: 'Recommended Reading',
          onClick: () => dispatch(routerActions.link(ROUTES.BLOGS)),
        },*/
        {
          title: 'FAQ',
          onClick: () => dispatch(routerActions.link(ROUTES.FAQ)),
        },
        {
          title: 'Contact',
          onClick: () => dispatch(routerActions.link(ROUTES.CONTACT_US)),
        },
        {
          title: 'Host',
          onClick: () => dispatch(routerActions.link(ROUTES.HOST)),
        },
        {
          title: 'Unyoked at Work',
          onClick: () => dispatch(routerActions.link(ROUTES.UNYOKED_WORK)),
        },
      ],
    },
    {
      header: 'Resources',
      links: [
        {
          title: 'The Remedy Journal',
          onClick: () => dispatch(routerActions.link(ROUTES.JOURNAL)),
        },
        {
          title: 'The Science',
          onClick: () => dispatch(routerActions.link(ROUTES.SCIENCE)),
        },
        {
          title: 'Wellness',
          onClick: () => dispatch(routerActions.link(ROUTES.MENTAL_HEALTH)),
        },
        // {
        //   title: 'Creativity',
        //   onClick: () => dispatch(routerActions.link(ROUTES.CREATIVITY)),
        // },
        // {
        //   title: 'Relationships',
        //   onClick: () => dispatch(routerActions.link(ROUTES.RELATIONSHIPS)),
        // },
        // {
        //   title: 'Employee Wellness',
        //   onClick: () => dispatch(routerActions.link(ROUTES.EMPLOYEE_WELLNESS)),
        // },
      ],
    },
  ];

  const subItems = [
    {
      title: 'Privacy',
      onClick: () => dispatch(routerActions.link(ROUTES.PRIVACY_POLICY)),
    },
    {
      title: 'T&Cs',
      onClick: () => dispatch(routerActions.link(ROUTES.TERMS_AND_CONDITIONS)),
    },
  ];

  const countryModal = (
    <Modal
      open={countryModalOpen}
      onBackdropClick={() => setCountryModalOpen(false)}
      onClose={setCountryModalOpen}
    >
      <Box
        pl={5}
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <Typo variant="h5">Country</Typo>
        <Typo variant="body2">Choose one only</Typo>
        <br />
        {countries &&
          Object.keys(countries).map((code) => (
            <Box m={1} key={code}>
              <Badge
                className={classes.countryRadio}
                color="primary"
                onClick={() => setCheckedCountry(code)}
              >
                {checkedCountry && checkedCountry == code ? (
                  <CheckboxChecked2 />
                ) : (
                  <CheckboxUnchecked />
                )}
              </Badge>
              {countries[code].name}
            </Box>
          ))}
        <br />
        <Box mx="auto">
          <GhostButton
            style={classes.countryButton}
            text="Confirm"
            onClick={() => {
              onCountryChange(checkedCountry);
              setCountryModalOpen(false);
            }}
          />
        </Box>
      </Box>
    </Modal>
  );

  return (
    <Fragment>
      <View
        {...props}
        moreItemDesktop={moreItemsDesktop}
        moreItemMobile={moreItemsMobile}
        items={items}
        subItems={subItems}
        goToHome={() => dispatch(routerActions.link(ROUTES.ROOT))}
        setCountryModalOpen={setCountryModalOpen}
      />

      {countryModal}
      <Modal
        open={open}
        onClose={setOpen}
        onBackdropClick={() => setOpen(false)}
      >
        <WaitlistModalContent
          defaultValue={payload?.waitlistInput || {}}
          closeModal={() => setOpen(false)}
        />
      </Modal>
    </Fragment>
  );
};

export default React.memo(FooterContainer);
