import React, { FC, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  Button,
  GhostButton,
  Typo,
  InputField,
  TextArea,
} from '../../primitives';
import { ContactUsProps } from './ContactUs.props';
import { useStyles } from './ContactUs.styles';

const ContactUsView: FC<ContactUsProps> = (props) => {
  const [value, setValue] = useState({ name: '', message: '' });
  const [error, setError] = useState({ name: '', message: '' });
  const [subscribed, setSubscribed] = React.useState(false);
  const theme = useTheme();
  // @ts-ignore
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const onChange = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { target } = event;
    const { id, value: eventValue } = target;

    if (id === 'subscribe') {
      setSubscribed(!subscribed);
      return;
    }

    setError({ ...error, [id]: '' });
    setValue({ ...value, [id]: eventValue });
  };

  const onContactSubmit = () => {
    let errorObj = error;
    Object.entries(value).forEach(([key, objValue]) => {
      if (!objValue)
        errorObj = { ...errorObj, [key]: `Please enter your ${key}` };
    });
    setError(errorObj);

    const isFilled = Object.values(value).every((x) => x !== '');
    if (isFilled) {
      const recipient = 'hello@unyoked.co';
      const subject = `Contact Us <${value.name}>`;
      window.location.href = `mailto:${recipient}?subject=${subject}&body=${value.message}`;
    }
  };

  return (
    <Grid container alignItems="center" spacing={5}>
      <Grid item xs={12} sm={6}>
        <Typo variant={isMobile ? 'h3' : 'h1'} className={classes.title}>
          We’d love to hear from your neck of the woods…
        </Typo>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          id="name"
          placeholder="Name"
          value={value.name}
          error={error.name}
          onChange={onChange}
        />
        <Box mb={5} />
        <TextArea
          id="message"
          placeholder="Leave us a message..."
          value={value.message}
          error={error.message}
          onChange={onChange}
        />
        <Box mb={2} />
        {/* <Checkbox
          id="subscribe"
          label="Subscribe to Newsletter"
          checked={subscribed}
          onChange={onChange}
        /> */}
        <Box mb={isMobile ? 5 : 10} />
        {!isMobile ? (
          <Button onClick={onContactSubmit} variant="h4" text="Contact" />
        ) : (
          <GhostButton
            text="Contact"
            style={classes.ghostButton}
            onClick={onContactSubmit}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(ContactUsView);
