import { Contentful } from 'types/Contentful';
import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'HOME';
const createHomeActions = makeCreateActions(ns);

const authActions = createHomeActions({
  getHomeData: presetActions.makeAsyncAction<Contentful[]>(`${ns}/GET_HOME`),
});

export default authActions;
