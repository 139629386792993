import { Dispatch } from 'redux';
import { getProfileEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { authActions, userActions, tokenActions } from 'store/actions';

import { Store } from '../../types/store/Store';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';

interface Data {}

const getProfile =
  (data: Data) => (dispatch: Dispatch, getState: () => Store) => {
    dispatch(
      userActions.getProfile(
        apiFetch<Data, any>(getProfileEndpoint, data)(dispatch, getState)
          .then((profile) => {
            const userData = getState().user?.data || { user: {} };
            if (profile?.user) {
              dispatch(
                userActions.setUser({
                  status: ASYNC_STATUS.COMPLETE,
                  errors: [],
                  data: { 
                    ...userData,
                    user: {
                      ...userData?.user,
                      ...profile.user
                    }
                  },
                })
              );
            }

            return profile;
          })
          .catch((e) => {
            console.log(e)
            const user = getState().user?.data;

            if (user) {
              dispatch(authActions.logout());
              dispatch(tokenActions.clear());
            }
          })
      )
    );
  };

export default getProfile;
