import { useEffect, useState } from 'react';

import { addDays, differenceInDays, isBefore, subDays } from 'date-fns';
import { DateUtils } from 'react-day-picker';

import {
  isValidRange,
  rangeHasException,
} from '../../../utils/Data/bookedDates/bookedDates';

export default (
  onChange: (v: { to?: Date; from?: Date }) => void = () => {},
  bookedDates?: { after?: Date; before: Date }[],
  exceptions?: { freq: string; byday: string },
  onStart?: (from: Date) => void
) => {
  // @ts-ignore
  const [{ from, to }, setDate] = useState({});
  const [oneDisabled, setOneDisabled] = useState(false);
  const modifiers = { start: from, end: to };

  const handleDayClick = (
    day: Date,
    { disabled }: { disabled: boolean } = { disabled: false }
  ) => {
    //  Prevent previous date or same date
    if (day < new Date() || (from && from.toString() === day.toString())) {
      if (onStart) {
        setDate({ from: undefined, to: undefined });
        onChange({ from: undefined, to: undefined });
      }
      return;
    }

    // prevent clicking on disabled dates and only if from > day
    if (from && !to && disabled && !bookedDates && isBefore(day, from)) {
      setDate({ from: undefined, to: undefined });
      return;
    }

    // only used by booking bar
    // Prevent overlapping booking dates
    if (from && !to && bookedDates) {
      const dateUtilRange = DateUtils.addDayToRange(day, { from, to });

      if (rangeHasException(from, day, exceptions)) {
        setDate({ from: undefined, to: undefined });
        return;
      }

      if (!isValidRange(dateUtilRange.from, dateUtilRange.to, bookedDates)) {
        setDate({ from: undefined, to: undefined });
        return;
      }
    }

    if (from && to) {
      if (disabled) {
        return;
      }

      setDate({ from: day, to: undefined });
      return;
    }

    // Prevent booking on two disabled dates
    if (disabled && oneDisabled) {
      setOneDisabled(false);
      setDate({ from: undefined, to: undefined });
      return;
    }
    if (disabled) {
      setOneDisabled(true);
      if (onStart) return;
    } else {
      setOneDisabled(false);
    }

    setDate(DateUtils.addDayToRange(day, { from, to }));
  };

  useEffect(() => {
    if (to !== undefined) {
      onChange({ from, to });
    } else if (from !== undefined) {
      onStart && onStart(from);
    }


  }, [from, to]);

  const handleReset = (from?: Date, to?: Date) => {
    setDate({ from, to });
  };

  const checkForOneNightBooking = () => {
    if (!to) {
      return false;
    }
    return differenceInDays(to, from) === 1;
  };

  return {
    checkForOneNightBooking,
    handleReset,
    handleDayClick,
    modifiers,
    from,
    to,
  };
};
