import { Dispatch } from 'redux';
import { logoutEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { authActions } from 'store/actions';

import { Store } from '../../types/store/Store';

const logout = () => async (dispatch: Dispatch, getState: () => Store) => {
  await apiFetch(logoutEndpoint, {})(dispatch, getState)

  dispatch(authActions.logout());
};

export default logout;
