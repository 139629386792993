import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { colors, fontFamilies } from 'themeConfig/themeConstants';

import { pxToRem } from '../../../themeConfig/typography';
import { InputFieldProps } from './InputField.props';

const { plain, pitch } = fontFamilies;

const setPlaceholder = (color: string, bgColor: string) => {
  return {
    '&:-webkit-autofill': {
      '-webkit-box-shadow': `inset 0 0 0 50px transparent !important`,
      '-webkit-text-fill-color': 'red',
    },
    '&:-webkit-autofill:focus': {
      '-webkit-box-shadow': `inset 0 0 0 50px ${bgColor}`,
      '-webkit-text-fill-color': color,
    },
  };
};
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    input: ({ color }: InputFieldProps) => ({
      fontFamily: pitch,
      fontSize: pxToRem(12),
      color: color || colors.primaryDarkGreen,
      borderBottom: `2px solid ${color || colors.primaryDarkGreen}`,
      paddingLeft: 2,
      paddingRight: 2,
    }),
    inputRoot: {
      '&::placeholder': {
        opacity: 1,
      },
      '&:-webkit-autofill': {
        backgroundColor: colors.primaryYellow,
        '-webkit-box-shadow': `inset 0 0 0 50px ${colors.primaryYellow} !important`,
        '-webkit-text-fill-color': colors.primaryDarkGreen,
      },
    },
    inputLabel: {
      fontFamily: plain,
      fontWeight: 500,
    },
    error: {
      paddingLeft: 2,
      paddingRight: 2,
    },
    disabled: {
      opacity: 0.5,
    },
  });
});
