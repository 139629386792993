import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'themeConfig/themeConstants';
import { fallback } from 'utils/Data';

import { LocationSearchProps } from './LocationSearch.props';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputField: {
      borderColor: colors.primaryYellow,
    },
    container: {
      position: 'relative',
      width: '100%',
      maxWidth: (props: LocationSearchProps) =>
        fallback(
          // @ts-ignore
          (t) => t.width,
          '100%',
          props
        ),
    },
    suggestionsContainer: {
      position: 'absolute',
      width: '100%',
      backgroundColor: colors.primaryYellow,
      zIndex: 20,
      borderRadius: '5px',
      [theme.breakpoints.down('sm')]: {
        top: theme.spacing(7),
      },
      [theme.breakpoints.up('sm')]: {
        bottom: theme.spacing(7),
      },
    },
    active: {
      backgroundColor: colors.primaryYellow,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    inactive: {
      backgroundColor: 'white',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
  })
);
