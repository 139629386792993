import React, { FC } from 'react';

import { DarkModalPublicProps } from './DarkModal.props';
import View from './DarkModal.view';

const DarkDarkModalContainer: FC<DarkModalPublicProps> = (
  props: DarkModalPublicProps
) => <View {...props} />;

export default React.memo(DarkDarkModalContainer);
