import { colors } from './themeConstants';

const {
  primaryYellow,
  secondaryYellow,
  primaryDarkGreen,
  secondaryDarkGreen,
  lightGreen,
  primaryGrey,
  primaryWhite,
  primaryRed,
  primaryPink,
} = colors;

const palette = {
  common: {
    white: primaryWhite,
  },
  background: {
    default: primaryYellow,
  },
  primary: {
    main: primaryYellow,
  },
  secondary: {
    main: primaryDarkGreen,
  },
  text: {
    primary: primaryDarkGreen,
    secondary: secondaryDarkGreen,
    hint: primaryGrey,
  },
  error: {
    main: primaryRed,
  },
};

export default palette;
