import { FC } from 'react';
import React from 'react';
import { ReviewStripPublicProps, ReviewStripProps } from './ReviewStrip.props';
import ReviewStripView from './ReviewStrip.view';
import { useQueryWrapper } from 'utils/Hooks/useQueryWrapper';
import { getContentfulEntry } from 'services/fetch/apiEndpoints';
import { ContentfulTwoColumns } from 'types/Contentful';

const ReviewStripContainer: FC<ReviewStripPublicProps> = (
  publicProps: ReviewStripPublicProps
) => {
  const { data: reviewStrip } = useQueryWrapper<string, ContentfulTwoColumns>(
    ['reviewStrip'],
    getContentfulEntry,
    '2iAv0Q708sP4IE5y4GGGXY',
    {
      refetchInterval: 3000000,
      staleTime: 3000000,
    }
  );

  const combinedProps: ReviewStripProps = {
    ...reviewStrip?.payload,
    ...publicProps,
    // calcedProps
  };

  return <ReviewStripView {...combinedProps} />;
};

export default ReviewStripContainer;
