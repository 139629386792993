import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { debounce, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { getMinorValue } from '../../../utils/Data/pricing/pricing';
import { HorizontalLine, Typo, SubmitField } from '../../primitives';
import HouseCard from '../HouseCard';
import { HouseCardPaymentsProps } from './HouseCardPayments.props';
import { useStyles } from './HouseCardPayments.styles';
import theme from 'themeConfig/theme';

const HouseCardPaymentsView: FC<HouseCardPaymentsProps> = (props) => {
  const {
    checkin,
    checkout,
    priceDetails,
    houseCardProps,
    clearVoucher,
    redeemVoucher,
    voucher,
    voucherLoading,
    voucherSuccess,
    voucherError,
    addProvisions,
    cabinTime,
    discount,
  } = props;
  const isMounted = useRef(true);
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { currency } = houseCardProps;

  const [voucherCode, setVoucherCode] = useState(voucher ? voucher.code : '');
  const hasDiscount = false; //!!discount;

  const mt = (index: number, length: number) => {
    if (addProvisions) {
      return index === length - 3 ? 3 : 0;
    }
    return index === length - 2 ? 3 : 0;
  };

  useEffect(() => {
    if (isMounted.current && voucherCode) {
      redeemVoucher(voucherCode);
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  const searchStuff = useCallback(
    debounce((value) => {
      if (!voucherSuccess) {
        setVoucherCode(value);
        if (value && value.length) {
          redeemVoucher(value);
          window.analytics.track('Coupon Entered', {
            coupon_id: value,
          });
        } else {
          clearVoucher();
        }
      } else {
        clearVoucher();
        setVoucherCode(value);
      }
    }, 500),
    []
  );

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVoucherCode(e.target.value);
    searchStuff(e.target.value);
  };

  const ReviewDetailsHeader = (
    <div>
      <Typo variant={isXs ? 'h3' : 'h1'} className={classes.header}>
        Review your details
      </Typo>
      <Box mt={2} mb={1}>
        <HorizontalLine className={classes.fatLine} />
      </Box>
      <Box mb={isMobile ? 3 : 6} />
      <Typo variant="h6">{checkin}</Typo>
      <Typo variant="body3">Check-in: {cabinTime['start']}</Typo>
      <Box mb={2} />
      <Typo variant="h6">{checkout}</Typo>
      <Typo variant="body3">Check-out: {cabinTime['end']}</Typo>
      <Box mt={isMobile ? 3 : 6} mb={1}>
        <HorizontalLine className={classes.line} />
      </Box>
    </div>
  );

  return (
    <Box>
      <HouseCard {...houseCardProps} />
      <Box mt={5}>
        {isMobile ? (
          ReviewDetailsHeader
        ) : (
          <div>
            <Typo variant="h6">
              {checkin} - {checkout}
            </Typo>
            <Box mb={2} mt={1}>
              <HorizontalLine className={classes.line} />
            </Box>
          </div>
        )}

        {priceDetails.map(({ text, value, quantity }, index) => (
          <Box
            key={index}
            className={classes.priceDetail}
            mt={mt(index, priceDetails.length)}
          >
            <Typo
              variant="body3"
              dangerouslySetInnerHTML={{
                __html: quantity ? `${text} x ${quantity}` : text,
              }}
            ></Typo>
            <Typo variant="body3" className={classes.value}>
              {quantity
                ? getMinorValue(value, currency || 'AUD')
                    .multiply(quantity)
                    .toRoundedUnit(2)
                : value}
            </Typo>
          </Box>
        ))}
      </Box>
      <Box mt={4} mb={4}>
        <HorizontalLine className={classes.line} />
      </Box>
      {!addProvisions && (
        <>
          <Typo variant="h6">Have a Code ?</Typo>
          <SubmitField
            placeholder={hasDiscount ? "No voucher for discounted bookings" : "Enter here"}
            type="voucher"
            value={voucherCode}
            loading={voucherLoading}
            success={voucherSuccess}
            error={voucherError}
            onChange={onSearch}
            onSubmit={() => {
              if (!voucherSuccess) {
                redeemVoucher(voucherCode);
                window.analytics.track('Coupon Applied', {
                  coupon_id: voucherCode,
                });
              } else if (voucherError) {
                window.analytics.track('Coupon Denied', {
                  coupon_id: voucherCode,
                });
              }
            }}
          />
        </>
      )}
    </Box>
  );
};

export default React.memo(HouseCardPaymentsView);
