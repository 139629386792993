import { Dispatch } from 'redux';
import { getJournalsEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { journalActions } from 'store/actions';

import { Store } from '../../types/store/Store';

interface Data {}

const getJournals = (page?: number, pageLength?: number) => {
  const payload = page ? { page: page, page_length: pageLength } : {};
  return (dispatch: Dispatch, getState: () => Store) => {
    dispatch(
      journalActions.getAll(
        apiFetch<Data, any>(getJournalsEndpoint, payload)(dispatch, getState)
      )
    );
  };
};

export default getJournals;
