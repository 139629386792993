import React, { FC } from 'react';

import { CheckboxXmarkPublicProps } from './CheckboxXmark.props';
import View from './CheckboxXmark.view';

const CheckboxXmarkContainer: FC<CheckboxXmarkPublicProps> = (props) => (
  <View {...props} />
);

export default React.memo(CheckboxXmarkContainer);
