import React, {
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Badge,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
  Box,
} from '@material-ui/core';
import moment from 'moment';
// import Divider from '@material-ui/core/Divider';
import { ArrowBackIos } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { Skeleton } from '@material-ui/lab';
import { CheckboxChecked2, CheckboxUnchecked } from 'assets/svgs';
import {
  Button,
  Calendar,
  GhostButton,
  ImageContainer,
  Modal,
  Select,
  Typo,
} from 'components/primitives';
import { useCalendar } from 'components/primitives/Calendar';
import { addDays, format, parse, subDays, parseISO, setMonth } from 'date-fns';
import { useClickTrack } from 'hooks/useClickTrack';
import { flatten, fromPairs, pathOr, pick } from 'ramda';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { colors } from 'themeConfig/themeConstants';
import { pxToRem } from 'themeConfig/typography';
import entityThunks from 'thunks/entity';
import { Entity } from 'types/Entity';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { LocationsState } from 'types/store/CountryState';
import { CategoriesState, OffersState } from 'types/store/EntitiesState';
import {
  getFullyBookedDates,
  isMinimumStay,
  isMinimumStayOnEvents,
  transformBookedDates,
} from 'utils/Data/bookedDates/bookedDates';
import { asyncData } from 'utils/Redux';

import { BookingModalProps } from './BookingModal.props';
import { useStyles } from './BookingModal.styles';

const defaultWidgetParams = {
  widget_name: 'Cabin options select widget',
  widget_type: 'Booking',
  widget_step_type: '1',
  widget_step_option: '',
  widget_step_option_value: [] || '',
  click_text: '',
};

const BookingModalView: FC<BookingModalProps> = (props: BookingModalProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile, closeIconLeft: false });
  // const actionTrack = useClickTrack('Widget Interaction');
  const searchTrack = useClickTrack('Cabin Searched');

  const {
    questions,
    filterData,
    entities,
    isResultsPage,
    goToResults,
    filter,
    updateFilter,
  } = props;
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);

  const [startDate, setStartDate] = useState(
    pathOr('', ['startDate'], filterData)
  );
  const [endDate, setEndDate] = useState(pathOr('', ['endDate'], filterData));
  const [isTwoNightMinimum, setIsTwoNightMinimum] = useState(false);
  const [overAllTwoNightsMinimum, setOverAllTwoNightsMinimum] = useState<
    boolean[]
  >([]);
  const [isFlexibleDate, setIsFlexibleDate] = useState(false);

  const [fullyBookedDates, setFullyBookedDates] = useState<Date[]>([]);
  const [calendarBookedDates, setCalendarBookedDates] = useState<
    { before: Date; after?: Date }[]
  >([]);
  const [disabledDays, setDisabledDays] =
    useState<{ before: Date; after?: Date }[]>();

  const [calendarMonth, setCalendarMonth] = useState<Date>(
    startDate ? parse(startDate, 'MMM dd, yyyy', new Date()) : new Date()
  );

  useEffect(() => {
    // set calendar month as next month if today's day is the last day of the <month></month>
    if (startDate) return;
    const nowDate = moment().format('MMM DD, yyyy');
    const endDate = moment().endOf('month').format('MMM DD, yyyy');
    const isLastDay = nowDate === endDate;
    if (isLastDay) {
      onMonthChange(moment().add(1, 'month').startOf('month').toDate());
    }
  }, []);

  const country = useSelectorSafe((state) => state.country);
  const query: object = useSelectorSafe<object>(
    (state) => state.location.payload,
    {}
  );
  let countryCode: string = pathOr(
    country?.countryCode || 'AU',
    ['country'],
    query
  );

  if (filterData?.location?.country && filterData.location.address) {
    countryCode = filterData.location.country;
  }

  if (countryCode == 'UK') countryCode = 'GB';

  const [selectedCountry, setSelectedCountry] = useState<string>(
    countryCode || 'AU'
  );
  const [selectedCities, setSelectedCities] = useState<string[]>(
    filterData?.cities || []
  );

  const [selectedCategories, setSelectedCategories] = useState<
    string[] | undefined
  >(filterData?.categories || []);

  const [selectedOffers, setSelectedOffers] = useState<string[] | undefined>(
    filterData?.offers || []
  );
  const [nights, setNights] = useState<string>();
  const [guests, setGuests] = useState('2');
  const [showTooltip, setShowTooltip] = useState<Number>(-1);
  const [categoryTooltipContent, setCategoryTooltipContent] =
    useState<string>('');

  const [error, setError] = useState<ReactNode>();

  const locations = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<LocationsState>(
      (state) => state.locations,
      asyncData(ASYNC_STATUS.ERROR, [{ message: 'Could not get locations' }])
    )
  );

  const categories = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<CategoriesState>(
      (state) => state.categories,
      asyncData(ASYNC_STATUS.ERROR, [{ message: 'Could not get categories' }])
    )
  ).data?.categories;

  const offers = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<OffersState>(
      (state) => state.offers,
      asyncData(ASYNC_STATUS.ERROR, [{ message: 'Could not get offers' }])
    )
  ).data?.offers;

  const countries = locations.data?.countries;
  const cities = locations.data?.cities[selectedCountry];

  const breadcrumbs = useMemo(() => {
    const breadcrumbData = [];
    if (selectedCountry) {
      breadcrumbData.push(countries?.[selectedCountry].name);
    }
    if (selectedCities?.length) {
      breadcrumbData.push(
        cities?.filter((x) => x.id === selectedCities[0])[0].name
      );
    }

    return breadcrumbData.slice(0, step);
  }, [selectedCountry, selectedCities, step]);

  const q = (q: string, key: 'label' | 'description') => {
    const qString = questions && questions[q] && questions[q][key];
    return qString;
  };

  const qna = useMemo(
    () => [
      {
        question: q('booking_questions_country', 'label'),
        answer: selectedCountry,
      },
      {
        question: q('booking_questions_city', 'label'),
        answer: cities
          ?.filter((x) => x.id === selectedCities[0])
          .map((x) => x.name),
      },
      {
        question: q('booking_questions_date', 'label'),
        answer: isFlexibleDate ? `I'm flexible` : `${startDate} - ${endDate}`,
      },
    ],
    [
      selectedCountry,
      selectedCities,
      selectedCategories,
      selectedOffers,
      isFlexibleDate,
      startDate,
      endDate,
    ]
  );

  const backArrow =
    step > 0 ? (
      <ArrowBackIos
        fontSize={isMobile ? 'medium' : 'large'}
        style={{ float: 'left', position: 'absolute' }}
        onClick={() => {
          setError(undefined);
          setStep(step - 1);
        }}
      />
    ) : null;

  const closeButton = (
    <CloseIcon
      fontSize={isMobile ? 'medium' : 'large'}
      style={{ float: 'right' }}
      onClick={() => {
        updateFilter({ open: false });
      }}
    />
  );

  const calculateBookdates = (from: Date) => {
    let closestDate: Date | undefined;
    let diff: number | undefined = -1;
    for (const date of calendarBookedDates) {
      if (date.after && date.after > subDays(from, 1)) {
        if (diff < 0) {
          diff = date.after.getTime() - from.getTime();
          closestDate = date.after;
          continue;
        }

        if (date.after.getTime() - from.getTime() < diff) {
          diff = date.after.getTime() - from.getTime();
          closestDate = date.after;
        }
      }
    }

    const disableDay: { after?: Date; before: Date } = { before: from };
    if (closestDate) disableDay.after = addDays(closestDate, 1);

    setDisabledDays([disableDay]);
  };

  const calendarHook = useCalendar(
    ({ from, to }) => {
      setStartDate(from ? format(from, 'MMM dd, yyyy') : '');
      setEndDate(to ? format(to, 'MMM dd, yyyy') : '');
      setDisabledDays(undefined);
    },
    calendarBookedDates,
    undefined,
    (from) => {
      calculateBookdates(from);
      setStartDate(from ? format(from, 'MMM dd, yyyy') : '');
      setEndDate('');
    }
  );

  const onMonthChange = (date: Date) => {
    const year = date.getFullYear(),
      month = date.getMonth();
    const nextMonthKey =
      month < 12
        ? `${selectedCountry}-${year}-${month + 1}`
        : `${selectedCountry}-${year + 1}-0`;
    const rawEntities: { [key: string]: Entity[] } = pathOr(
      {},
      ['data', 'entities'],
      entities
    );

    const now = new Date();
    if (date > now) {
      if (rawEntities && entities && entities.status != ASYNC_STATUS.LOADING) {
        if (!rawEntities[`${selectedCountry}-${year}-${month}`]) {
          dispatch(entityThunks.getForCalendar(date, selectedCountry));
        } else if (!rawEntities[nextMonthKey]) {
          const nextDate =
            month < 12
              ? new Date(year, month + 1, 1)
              : new Date(year + 1, 0, 1);
          dispatch(entityThunks.getForCalendar(nextDate, selectedCountry));
        }
      }
    }

    setCalendarMonth(date);
  };

  const calendarReset = () => {
    setStartDate('');
    setEndDate('');
    setError(undefined);
    setShowTooltip(-1);
    calendarHook.handleReset();
  };

  useEffect(() => {
    setStep(0);
    calendarReset();
  }, [open]);

  useEffect(() => {
    setOpen(filterData?.open || false);
  }, [filterData?.open]);

  useEffect(() => {
    if (startDate && !endDate) {
      calculateBookdates(parse(startDate, 'MMM dd, yyyy', new Date()));
    }
  }, [calendarBookedDates]);

  useEffect(() => {
    setError(undefined);
    const date = calendarMonth || new Date();
    const year = date.getFullYear(),
      month = date.getMonth();
    let rawEntities = pathOr(
      [],
      ['data', 'entities', `${selectedCountry}-${year}-${month}`],
      entities
    );

    const initialEntitiesLength = rawEntities.length;

    rawEntities = rawEntities.filter((e: Entity) => {
      if (selectedOffers && selectedOffers.length) {
        const offerIds = e.offers.map((o) => o.id);
        for (const mustHave of selectedOffers) {
          if (!offerIds.includes(mustHave)) {
            return false;
          }
        }
      }

      if (selectedCategories && selectedCategories.length) {
        let categoryFound = false;
        for (const category of e.categories) {
          if (selectedCategories.includes(category.id)) {
            categoryFound = true;
            break;
          }
        }

        if (!categoryFound) return false;
      }

      if (!selectedCities.length) {
        if (selectedCountry === 'AU') {
          return (
            (e.metadata.country || '')
              .replace(/,\s|,\s+/, ',')
              .split(',')
              .indexOf('AU') > -1
          );
        } else if (selectedCountry === 'NZ') {
          return (
            (e.metadata.country || '')
              .replace(/,\s|,\s+/, ',')
              .split(',')
              .indexOf('NZ') > -1
          );
        } else if (selectedCountry === 'GB') {
          return (
            (e.metadata.country || '')
              .replace(/,\s|,\s+/, ',')
              .split(',')
              .indexOf('GB') > -1
          );
        }
      }

      for (const city of e.cities) {
        if (selectedCities.indexOf(city.id) > -1) {
          return true;
        }
      }

      return false;
    });

    if (!rawEntities.length) {
      if (initialEntitiesLength) {
        setError(
          <>
            No cabins at this location match your selection,
            <br />
            try changing your preferences.
          </>
        );
      }
      setDisabledDays([{ before: new Date(year, month + 1, 1) }]);
    } else {
      !startDate && setDisabledDays(undefined);
      setError(undefined);

      const overAllTNM = [true, true, true, true, true, true, true];

      const rawBd = rawEntities.map((e: Entity) => ({
        name: e.id,
        twoNightMinimum: pathOr([], ['metadata', 'twoNightMinimum'], e),
        bookings: pathOr([], ['schedule', '0', 'bookings'], e),
        changeOverDays: pathOr([], ['schedule', '0', 'changeOverDays'], e),
      }));

      const entitiesByDate: { [key: string]: string[] } = {};

      setFullyBookedDates(getFullyBookedDates(rawBd, entitiesByDate));

      const start = startDate
        ? format(parse(startDate, 'MMM dd, yyyy', new Date()), 'yyyy-MM-dd')
        : null;

      const rawCBD = rawEntities
        .filter(
          (e: Entity) =>
            !(
              start &&
              entitiesByDate[start] &&
              entitiesByDate[start].indexOf(e.id) < 0
            )
        )
        .map((e: Entity) => {
          const twoNightMinimum = pathOr(
            [],
            ['metadata', 'twoNightMinimum'],
            e
          );
          for (const index in twoNightMinimum) {
            if (!twoNightMinimum[index]) {
              overAllTNM[index] = false;
            }
          }

          return {
            bookings: pathOr([], ['schedule', '0', 'bookings'], e),
            changeOverDays: [],
          };
        });

      setOverAllTwoNightsMinimum(overAllTNM);

      setCalendarBookedDates(
        getFullyBookedDates(rawCBD).map((cbd) => ({
          before: addDays(cbd, 1),
          after: subDays(cbd, 1),
        }))
      );

      const nextMonthKey =
        month < 12
          ? `${selectedCountry}-${year}-${month + 1}`
          : `${selectedCountry}-${year + 1}-0`;

      if (
        rawEntities.length &&
        entities &&
        entities.data &&
        !entities.data.entities[nextMonthKey] &&
        entities.status != ASYNC_STATUS.LOADING
      ) {
        const nextMonth =
          month < 12 ? new Date(year, month + 1, 1) : new Date(year + 1, 0, 1);
        dispatch(entityThunks.getForCalendar(nextMonth, selectedCountry));
      }
    }
  }, [
    startDate,
    selectedCities,
    selectedCountry,
    selectedCategories,
    selectedOffers,
    entities,
    calendarMonth,
  ]);

  useEffect(() => {
    if (!entities || entities.status == ASYNC_STATUS.INITIAL) {
      dispatch(entityThunks.getForCalendar(calendarMonth, selectedCountry));
    }
  }, []);

  useEffect(() => {
    if (endDate) {
      const start = parse(startDate, 'MMM dd, yyyy', new Date());
      const end = parse(endDate, 'MMM dd, yyyy', new Date());

      if (overAllTwoNightsMinimum) {
        const isMinimum = !isMinimumStay(start, end, overAllTwoNightsMinimum);
        setError(undefined);

        setIsTwoNightMinimum(isMinimum);

        if (isMinimum) {
          setError('Sorry, 2 night minimum');
        }
      }

      let event;
      if (startDate && endDate && (event = isMinimumStayOnEvents(start, end))) {
        setError(
          `Bookings on ${(event as any).text} must be at least ${
            (event as any).nights
          } nights.`
        );
      }
    }
  }, [endDate]);

  const boxRef = useRef<any>();
  useEffect(() => {
    if (
      boxRef &&
      boxRef.current &&
      boxRef.current.parentElement &&
      boxRef.current.parentElement.parentElement
    )
      boxRef.current.parentElement.parentElement.scrollTo(0, 0);
  }, [step]);

  const year = (calendarMonth || new Date()).getFullYear(),
    month = (calendarMonth || new Date()).getMonth();

  const curYear = new Date().getFullYear();
  const curMonth = new Date().getMonth();

  useEffect(() => {
    const monthKey = `${selectedCountry}-${year}-${month}`;
    if (entities && entities.data && !entities.data.entities[monthKey]) {
      dispatch(entityThunks.getForCalendar(calendarMonth, selectedCountry));
    }
  }, [selectedCountry]);
  const rawEntities: { [key: string]: Entity[] } = pathOr(
    {},
    ['data', 'entities'],
    entities
  );

  let title = 'Follow the steps to let us find the right location for you.';
  switch (step) {
    case 2:
      title = 'Follow the steps to let us find the right nature for you.';
      break;
    case 3:
      title = 'Follow the steps to let us find the right nature for you.';
      break;
    case 4:
      title = 'Follow the steps to let us find the right nature for you.';
      break;
    case 5:
      title = 'Next stop, nature.';
      break;
    default:
      break;
  }

  const bookedDates = disabledDays || fullyBookedDates;

  const maxStep = 3;

  const CountryStep = (
    <Box
      m={isMobile ? 0 : 1}
      mb={5}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typo align="center" variant={isMobile ? 'h6' : 'h5'}>
        {step + 1}. {q('booking_questions_country', 'label')}
      </Typo>
      <Typo align="center" variant="body3" style={{ marginTop: '5px' }}>
        {q('booking_questions_country', 'description')}
      </Typo>
      <Box mt={5}>
        {countries &&
          Object.keys(countries).map((code) => (
            <Box mt={isMobile ? 1 : 3} key={code}>
              <Badge
                style={{ marginRight: isMobile ? 15 : 30 }}
                color="primary"
                onClick={() => {
                  setSelectedCountry(code);
                  setSelectedCities([]);
                }}
              >
                {selectedCountry == code ? (
                  <CheckboxChecked2
                    style={{
                      transform: isMobile ? 'scale(0.85)' : 'scale(1)',
                    }}
                  />
                ) : (
                  <CheckboxUnchecked
                    style={{
                      transform: isMobile ? 'scale(0.85)' : 'scale(1)',
                    }}
                  />
                )}
              </Badge>

              {countries[code].name}
            </Box>
          ))}
      </Box>
    </Box>
  );

  const CityStep = (
    <Box
      m={isMobile ? 0 : 1}
      mb={5}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typo align="center" variant={isMobile ? 'h6' : 'h5'}>
        {step + 1}. {q('booking_questions_city', 'label')}
      </Typo>
      <Typo align="center" variant="body3" style={{ marginTop: '5px' }}>
        {q('booking_questions_city', 'description')}
      </Typo>
      <Box mt={isMobile ? 1 : 5}>
        {cities &&
          cities.map((city) => (
            <Box
              mt={isMobile ? 1 : 3}
              key={city.id}
              height={isMobile ? 30 : ''}
            >
              <Badge
                style={{ marginRight: isMobile ? 15 : 30 }}
                color="primary"
                onClick={() =>
                  setSelectedCities(
                    selectedCities.indexOf(city.id) > -1 ? [] : [city.id]
                  )
                }
              >
                {selectedCities.length &&
                selectedCities.indexOf(city.id) > -1 ? (
                  <CheckboxChecked2
                    style={{
                      transform: isMobile ? 'scale(0.7)' : 'scale(1)',
                    }}
                  />
                ) : (
                  <CheckboxUnchecked
                    style={{
                      transform: isMobile ? 'scale(0.7)' : 'scale(1)',
                    }}
                  />
                )}
              </Badge>

              {city.name}
            </Box>
          ))}
      </Box>
    </Box>
  );

  const CategoryStep = (
    <Box m={isMobile ? 0 : 1} alignContent="center">
      <Typo align="center" variant={isMobile ? 'h6' : 'h5'}>
        {step + 1}. {q('booking_questions_categories', 'label')}
      </Typo>
      <Typo align="center" variant="body3" style={{ marginTop: '5px' }}>
        {q('booking_questions_categories', 'description')}
      </Typo>
      <Box mt={5} mb={8}>
        {categories &&
          categories.map((category, idx) => {
            return (
              <Box className={classes.rowItem} key={category.id}>
                <Badge
                  style={{ marginRight: '10px' }}
                  color="primary"
                  onClick={() =>
                    setSelectedCategories(
                      (selectedCategories || []).indexOf(category.id) > -1
                        ? (selectedCategories || []).filter(
                            (c) => c != category.id
                          )
                        : [...(selectedCategories || []), category.id]
                    )
                  }
                >
                  {selectedCategories &&
                  selectedCategories.length &&
                  selectedCategories.indexOf(category.id) > -1 ? (
                    <CheckboxChecked2 />
                  ) : (
                    <CheckboxUnchecked />
                  )}
                </Badge>

                <span style={{ paddingRight: '34px' }}>{category.name}</span>

                <span
                  style={{
                    position: 'absolute',
                    right: 0,
                    display: 'flex',
                  }}
                >
                  <Tooltip
                    title={
                      <>
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            padding: 0,
                          }}
                        >
                          <CloseIcon
                            style={{ cursor: 'pointer' }}
                            fontSize="small"
                            onClick={() => setShowTooltip(-1)}
                          />
                        </Box>
                        <Box>{category.information}</Box>
                      </>
                    }
                    placement="left"
                    arrow
                    open={showTooltip === idx}
                    classes={{ popper: classes.popper }}
                  >
                    <IconButton
                      onClick={() =>
                        setShowTooltip(showTooltip === idx ? -1 : idx)
                      }
                    >
                      <InfoIcon style={{ cursor: 'pointer' }} />
                    </IconButton>
                  </Tooltip>
                </span>
              </Box>
            );
          })}
        <Box className={classes.rowItem}>
          <Badge
            style={{ marginRight: '10px' }}
            color="primary"
            onClick={() => setSelectedCategories([])}
          >
            {selectedCategories && !selectedCategories.length ? (
              <CheckboxChecked2 />
            ) : (
              <CheckboxUnchecked />
            )}
          </Badge>
          <span>Nothing specific, I just want a cabin</span>
        </Box>
      </Box>
    </Box>
  );

  const MustHaveStep = (
    <Box
      m={isMobile ? 0 : 1}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typo align="center" variant={isMobile ? 'h6' : 'h5'}>
        {step + 1}. {q('booking_questions_must_haves', 'label')}
      </Typo>
      <Typo align="center" variant="body3" style={{ marginTop: '5px' }}>
        {q('booking_questions_must_haves', 'description')}
      </Typo>
      <Box mt={5} mb={5}>
        {offers &&
          offers.map((offer) => (
            <Box
              key={offer.id}
              mb={3}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Box>
                <Badge
                  style={{ marginRight: isMobile ? '10px' : '30px' }}
                  color="primary"
                  onClick={() => {
                    const selected = offer;

                    if ((selectedOffers || []).indexOf(offer.id) > -1) {
                      setSelectedOffers(
                        (selectedOffers || []).filter((c) => c != offer.id)
                      );
                    } else {
                      let exclude: string[] = [];

                      for (const off of offers) {
                        if (off.opposites) {
                          const opIds = off.opposites.map((o) => o.id);
                          if (off.id == selected.id) {
                            exclude = exclude.concat(opIds);
                          } else if (opIds.includes(selected.id)) {
                            exclude.push(off.id);
                          }
                        }
                      }

                      setSelectedOffers([
                        ...(selectedOffers || []).filter(
                          (o) => !exclude.includes(o)
                        ),
                        selected.id,
                      ]);
                    }
                  }}
                >
                  {selectedOffers &&
                  selectedOffers.length &&
                  selectedOffers.indexOf(offer.id) > -1 ? (
                    <CheckboxChecked2 />
                  ) : (
                    <CheckboxUnchecked />
                  )}
                </Badge>
              </Box>
              <Box>{offer.name}</Box>
              {(() => {
                const icon =
                  offer.icon && offer.icon.length > 0 ? offer.icon[0] : null;

                return (
                  icon && (
                    <ImageContainer
                      src={icon}
                      alt={offer.name}
                      className={classes.offerIcon}
                    />
                  )
                );
              })()}
            </Box>
          ))}
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box>
            <Badge
              style={{ marginRight: isMobile ? '10px' : '30px' }}
              color="primary"
              onClick={() => setSelectedOffers([])}
            >
              {selectedOffers && !selectedOffers.length ? (
                <CheckboxChecked2 />
              ) : (
                <CheckboxUnchecked />
              )}
            </Badge>
          </Box>
          <Box>I don’t mind, no preferences</Box>
        </Box>
      </Box>
    </Box>
  );

  const GuestsAndNightsStep = (
    <Box m={isMobile ? 0 : 1}>
      <Typo align="center" variant={isMobile ? 'h6' : 'h5'}>
        {step + 1}. {q('booking_questions_guests', 'label')}
      </Typo>
      <Typo align="center" variant="body3" style={{ marginTop: '5px' }}>
        {q('booking_questions_guests', 'description')}
      </Typo>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          margin: '40px 30% 40px',
        }}
      >
        <Box mr={5}>
          <Select
            id="nights"
            variant="xxlarge"
            // @ts-ignore
            options={[1, 2].map((v) => ({ label: v, value: `${v}` }))}
            value={`${guests}`}
            onChange={(e) => setGuests(e.target.value)}
          />
        </Box>
        <Typo>Guests</Typo>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          margin: '40px 30% 80px',
        }}
      >
        <Box mr={5}>
          <Select
            id="nights"
            variant="xxlarge"
            // @ts-ignore
            options={[...Array(14).keys()].map((v) => ({
              label: v + 1,
              value: `${v + 1}`,
            }))}
            value={`${nights}`}
            onChange={(e) => setNights(e.target.value)}
          />
        </Box>
        <Typo>Nights</Typo>
      </Box>
    </Box>
  );

  const CalendarStep = (
    <Box m={isMobile ? 0 : 1} mb={0} alignContent="center">
      <Typo align="center" variant={isMobile ? 'h6' : 'h5'}>
        {step + 1}. {q('booking_questions_date', 'label')}
      </Typo>
      <Typo align="center" variant="body3" style={{ marginTop: '5px' }}>
        {q('booking_questions_date', 'description')}
      </Typo>

      {new Date(year, month, 1) >= new Date(curYear, curMonth, 1) &&
      (!rawEntities[`${selectedCountry}-${year}-${month}`] ||
        !rawEntities[`${selectedCountry}-${year}-${month}`].length) ? (
        <Box
          style={{
            width: '100%',
            height: '300px',
            textAlign: 'center',
          }}
        >
          {rawEntities[`${selectedCountry}-${year}-${month - 1}`]?.length && (
            <Button
              textStyle={{
                fontSize: pxToRem(10),
                color: colors.primaryDarkGreen,
              }}
              iconStyle={{ width: 0, height: 0 }}
              text={'Back to previous month'}
              hideUnderline={true}
              onClick={() =>
                setCalendarMonth(
                  month > 0
                    ? new Date(year, month - 1, 1)
                    : new Date(year - 1, 11, 1)
                )
              }
            />
          )}
          <Skeleton />
          <Skeleton animation={false} />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          Loading {format(calendarMonth, 'MMMM YYY')}
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Box>
      ) : (
        <Box
          mt={3}
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            minHeight: '300px',
          }}
        >
          <Calendar
            month={calendarMonth}
            bookedDates={bookedDates}
            hookState={calendarHook}
            onMonthChange={onMonthChange}
          />
        </Box>
      )}

      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typo align="center" variant="h5">
          {startDate
            ? `${format(
                parse(startDate, 'MMM dd, yyyy', new Date()),
                'MMM dd'
              )} - ${
                endDate
                  ? format(parse(endDate, 'MMM dd, yyyy', new Date()), 'MMM dd')
                  : ''
              }`
            : null}
        </Typo>
        {endDate && (
          <CloseIcon
            fontSize={isMobile ? 'medium' : 'large'}
            style={{ marginLeft: 20 }}
            onClick={calendarReset}
          />
        )}
      </Box>

      {!startDate ? (
        <Box
          mb={4}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box>
            <Badge
              style={{ marginRight: '15px' }}
              color="primary"
              onClick={() => setIsFlexibleDate(!isFlexibleDate)}
            >
              {isFlexibleDate ? <CheckboxChecked2 /> : <CheckboxUnchecked />}
            </Badge>
          </Box>
          <Box>
            <b>I&apos;m flexible</b>
          </Box>
        </Box>
      ) : null}
    </Box>
  );

  return (
    <Modal
      open={open}
      rootStyle={classes.root}
      paperStyle={classes.paper}
      titleContainerStyle={classes.title}
      contentContainerStyle={classes.content}
      onBackdropClick={() => updateFilter({ open: false })}
      onClose={(open) => updateFilter({ open })}
      noClose={false}
      disableLogo
      title={
        <Typo color="primaryWhite" align="center" variant={'h4'}>
          {title}
        </Typo>
      }
    >
      <Box
        ref={boxRef}
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          fontSize: isMobile ? '0.85rem' : '1rem',
        }}
      >
        <Box width="1%">{backArrow}</Box>
        <Box
          style={{
            position: 'absolute',
            top: isMobile ? 2 : 6,
            left: 30,
          }}
        >
          {breadcrumbs.length
            ? breadcrumbs.map((x, idx) => {
                if (idx > 0) {
                  return ` < ${x}`;
                }

                return x;
              })
            : null}
        </Box>

        <Box mt={5} width="98%">
          {[CountryStep, CityStep, CalendarStep][step]}

          {error ? (
            <Typo align="center" color="primaryRed" variant="h5">
              {error}
            </Typo>
          ) : null}

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: isMobile ? '20px 0px 10px' : '10px 160px 30px',
            }}
          >
            {step < maxStep - 1 ? (
              <GhostButton
                hoverText="Let's go"
                text="Next"
                onClick={() => {
                  const nextStep = step + 1;

                  // - Redirect to bookeasy
                  if (nextStep === 1 && selectedCountry === 'AU') {
                    const temp = setTimeout(() => {
                      updateFilter({ open: false });
                      clearTimeout(temp);
                    }, 500);
                    window.location.href = 'https://bookings.unyoked.co/';
                    return;
                  }

                  if (nextStep == 3) {
                    setSelectedOffers([]);
                  }
                  if (nextStep == 4) {
                    startDate && setDisabledDays(undefined);
                    calendarReset();
                  }

                  if (
                    (nextStep == 1 && !selectedCountry) ||
                    (nextStep == 2 &&
                      (!selectedCities || !selectedCities.length)) ||
                    (nextStep == 3 && !selectedCategories) ||
                    (nextStep == 4 && !selectedOffers)
                  ) {
                    setError('Please select an option');
                    return;
                  }

                  if (nextStep == 4 && error && error != 'Please select option')
                    return;

                  setStep(nextStep);
                }}
              />
            ) : (
              <GhostButton
                hoverText="Let's go"
                text={'Show me the cabins'}
                disabled={
                  isFlexibleDate
                    ? false
                    : (!startDate && !endDate && isTwoNightMinimum) || !!error
                }
                onClick={() => {
                  if (!isFlexibleDate && (!startDate || !endDate)) return;
                  if (isTwoNightMinimum) return;
                  if (error) return;

                  const cityMap: any = {},
                    catMap: any = {},
                    mustMap: any = {};
                  cities?.forEach((c) => (cityMap[c.id] = c.name));
                  categories?.forEach((c) => (catMap[c.id] = c.name));
                  offers?.forEach((o) => (mustMap[o.id] = o.name));

                  const filterTemp = {
                    location: {
                      ...filterData?.location,
                      country: selectedCountry,
                      address:
                        (selectedCities.length && cityMap[selectedCities[0]]) ||
                        '',
                    },
                    startDate,
                    endDate,
                    guests,
                    cities: selectedCities,
                    categories: selectedCategories,
                    offers: selectedOffers,
                    open: false,
                  };

                  isResultsPage &&
                  filterData?.location?.address == filterTemp.location.address
                    ? filter(filterTemp)
                    : goToResults(
                        selectedCountry,
                        (selectedCities.length && cityMap[selectedCities[0]]) ||
                          'Sydney'
                      );

                  updateFilter(filterTemp);

                  searchTrack({
                    country: qna[0].answer,
                    closest_city: qna[1].answer,
                    nature_help: qna[2].answer,
                    preferences: qna[3]?.answer,
                    check_in: startDate,
                    check_out: endDate,
                    flexible: isFlexibleDate,
                    click_text: 'Show me the Cabins',
                  });
                }}
              />
            )}
          </Box>

          <Typo align="center">
            {step + 1} of {maxStep}
          </Typo>
        </Box>

        <Box width="1%">{closeButton}</Box>
      </Box>
    </Modal>
  );
};

export default BookingModalView;
