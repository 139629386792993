import React, { FC } from 'react';

import Pagination from 'rc-pagination';

import { PaginationProps } from './Pagination.props';
import 'rc-pagination/assets/index.css';
import { useStyles } from './Pagination.styles';

const PaginationView: FC<PaginationProps> = (props) => {
  const classes = useStyles();
  return (
    <Pagination {...props} className={`${classes.root} ${props.className}`} />
  );
};

export default React.memo(PaginationView);
