import React, { FC } from 'react';

import { Loader } from 'components/primitives';
import { getUniquePageEndpoint } from 'services/fetch/apiEndpoints';
import { useQueryWrapper } from 'utils/Hooks/useQueryWrapper';

import { WishlistImage } from '../../../assets/images';
import {
  WaitlistBlockAPIResponse,
  WaitlistBlockPublicProps,
} from './WaitlistBlock.props';
import View from './WaitlistBlock.view';

const defaultData = {
  title: 'Be the first to know',
  subTitle: 'Not available on your dates?',
  description: `We know, we know, waiting sucks right? But hey, 
  this'll be more like day-before-xmas-waiting and 
  less like slow-walking-old-lady-waiting. 
  Plus you'll get the first dibs when we unlock our next collection of cabins.
  Sound like a plan?`,
  ctaLabel: 'Take me to the waitlist',
  image: WishlistImage,
};

const WaitlistBlockContainer: FC<WaitlistBlockPublicProps> = (props) => {
  const {
    isLoading,
    error,
    data: apiData,
  } = useQueryWrapper<string, WaitlistBlockAPIResponse>(
    ['waitlist'],
    getUniquePageEndpoint,
    'Cabin Page',
    {
      refetchOnMount: false,
      staleTime: 3000000,
    }
  );

  if (error) {
    return (
      <Loader isLoading={false}>
        <View {...props} content={defaultData} />
      </Loader>
    );
  }

  const content = {
    ...(apiData?.payload[0] || defaultData),
  };

  return (
    <Loader isLoading={isLoading}>
      <View {...props} content={content} />
    </Loader>
  );
};

export default React.memo(WaitlistBlockContainer);
