export { API } from './apis';
export { document } from './sampleContentful';
export { mapStyles } from './map';
export { policy } from './policy';
export { terms } from './terms';
export { FILTER } from './filter';
export { INSTAGRAM_URL, GIFTS_URL, HOST_CONTACT_URL } from './urls';
export { faq, faqGroups } from './faq';
// export { locations } from './locations';
export { cabins } from './cabins';
export { paymentFormInitialState, paymentFormFields } from './payment';
export { remedyKitUpgrade } from './remedyKitUpgrade';
export { doggo } from './doggo';
export { expressions } from './expressions';

export const PhoneTemplate: { [key: string]: string } = {
  'AU': '4XX XXX XXX',
  'NZ': '2X XXX XXXX',
  'GB': '7XXX XXX XXX'
}
