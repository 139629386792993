import { Dispatch } from 'redux';
import { getFeatureFlagsEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { featureFlagActions } from 'store/actions';

import { Store } from '../../types/store/Store';

interface Data {}

const getFeatureFlags = () => (dispatch: Dispatch, getState: () => Store) => {
  dispatch(
    featureFlagActions.getFeatureFlags(
      apiFetch<Data, any>(getFeatureFlagsEndpoint, '')(dispatch, getState).catch(err => {
        return {
          "Head Country Selector" : false,
          "Journal Category Filter" : false,
          "Maintenance Banner" : false,
          "Refer CTA" : true,
          "Show NZ Prescription" : false,
          message : "Success"
        }
      })
    )
  );
};

export default getFeatureFlags;
