import { Dispatch } from 'redux';
import {
  getPaymentAfterpayEndpoint,
} from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { userActions } from 'store/actions';

import { Store } from '../../types/store/Store';

interface Data {
  country: string;
  amount?: number;
  description?: string;
  email?: string;
  name: string;
  surname: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  postCode: string;
  metadata?: {
    [key: string]: any;
  },
  origin: string;
}

const getPaymentAfterPay = (data: Data) => (
  dispatch: Dispatch,
  getState: () => Store
) => {
  dispatch(
    userActions.getPaymentAfterpay(
      apiFetch<Data, any>(getPaymentAfterpayEndpoint, data)(
        dispatch,
        getState
      )
    )
  );
};

export default getPaymentAfterPay;