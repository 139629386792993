import React, { FC } from 'react';

import {
  InputQuantityPublicProps,
  InputQuantityProps,
} from './InputQuantity.props';
import InputQuantityView from './InputQuantity.view';

const InputQuantityContainer: FC<InputQuantityPublicProps> = (
  publicProps: InputQuantityPublicProps
) => {
  const combinedProps: InputQuantityProps = {
    ...publicProps,
    // calcedProps
  };
  return <InputQuantityView {...combinedProps} />;
};

export default InputQuantityContainer;
