import React, { FC } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';

import { TextButtonProps } from './TextButton.props';
import { useStyles } from './TextButton.styles';

const TextButtonView: FC<TextButtonProps> = (props: TextButtonProps) => {
  const {
    text,
    style,
    buttonStyle,
    onClick,
    iconStyle,
    typographyStyle,
    success = false,
    loading = false,
    disabled = false,
  } = props;
  const classes = useStyles(props);

  return (
    <Button
      style={buttonStyle}
      className={`${classes.button} ${style}`}
      onClick={onClick}
      disabled={success || loading || disabled}
    >
      {!loading && !success && (
        <Typography
          variant="h5"
          className={`${classes.typography} ${typographyStyle}`}
        >
          {text}
        </Typography>
      )}

      {loading && <CircularProgress size={21} color="secondary" />}
      {success && (
        <CheckIcon
          fontSize="small"
          style={iconStyle}
          className={classes.icon}
        />
      )}
    </Button>
  );
};

export default React.memo(TextButtonView);
