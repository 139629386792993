import { NOT_FOUND as RFR_NOT_FOUND } from 'redux-first-router';
import { RouteDef } from 'types/RouteDef';
import { copyObjKeys } from 'utils/Data';

import { makeAuthGuard } from '../components/core/AuthGuard';

interface RouteDefMap {
  [k: string]: RouteDef;
}

const authedMiddleware = () =>
  makeAuthGuard({
    allowAnon: false,
    // eslint-disable-next-line
    redirectAnon: ROUTES.LOGIN_SIGNUP,
  });

const anonOnlyMiddleware = () =>
  makeAuthGuard({
    allowAuthed: false,
    // eslint-disable-next-line
    redirectAuthed: ROUTES.ROOT,
  });

const ROUTES: RouteDefMap = copyObjKeys<RouteDef, RouteDefMap>(
  {
    ROOT: {
      component: 'HomeV2',
      path: '/',
    },
    LOGIN_SIGNUP: {
      component: 'LoginSignup',
      path: '/auth',
      middleware: anonOnlyMiddleware,
    },
    FORGOT_PASSWORD: {
      component: 'ForgotPassword',
      path: '/forgot-password',
      middleware: anonOnlyMiddleware,
    },
    RESET_PASSWORD: {
      component: 'ResetPassword',
      path: '/reset-password/:token',
      middleware: anonOnlyMiddleware,
    },
    ACCOUNT: {
      component: 'Account',
      path: '/account',
      middleware: authedMiddleware,
    },
    ACCOUNT_PERSONAL_INFO: {
      component: 'AccountPersonalInfo',
      path: '/account/personal-info',
      middleware: authedMiddleware,
    },
    ACCOUNT_PREFERENCE_CENTRE: {
      component: 'AccountPreferenceCentre',
      path: '/account/preference-centre',
      middleware: authedMiddleware,
    },
    ACCOUNT_BOOKING_HISTORY: {
      component: 'AccountBookingHistory',
      path: '/account/booking-history',
      middleware: authedMiddleware,
    },
    ACCOUNT_PAYMENT_METHODS: {
      component: 'AccountPaymentMethods',
      path: '/account/payment-methods',
      middleware: authedMiddleware,
    },
    ACCOUNT_SECURITY_SETTINGS: {
      component: 'AccountSecuritySettings',
      path: '/account/security-settings',
      middleware: authedMiddleware,
    },
    RESULTS: {
      component: 'Results',
      path: '/results/:country?/:location?',
    },
    BLOGS: {
      component: 'Blogs',
      path: '/recommended-reading',
    },
    BLOG_ITEM: {
      component: 'BlogItem',
      path: '/recommended-reading/:id',
    },
    HOUSE_PAYMENTS: {
      component: 'HousePayments',
      path: '/cabin-payments/:name',
      confirmLeave: (state, action) => {
        const intent = state.paymentIntent?.data;
        const afterpay = state.paymentAfterpay?.data;

        if (intent || afterpay) {
          return 'Are you sure you want to leave these dates behind?';
        }
      },
    },
    CHECKOUT_PAYMENT: {
      component: 'CheckoutPayment',
      path: '/checkout-payment',
    },
    CHECKOUT_AFTERPAY: {
      component: 'CheckoutAfterpay',
      path: '/checkout-afterpay',
    },
    CHECKOUT_CONFIRMED: {
      component: 'CheckoutConfirmed',
      path: '/checkout-confirmed',
    },
    VOUCHERS: {
      component: 'Vouchers',
      path: '/prescriptions',
    },
    VOUCHER_PAYMENTS: {
      component: 'VoucherPayments',
      path: '/prescription-payments/:id',
    },
    PROVISIONS: {
      component: 'Provisions',
      path: '/provisions',
    },
    CONTACT_US: {
      component: 'ContactUs',
      path: '/contact',
    },
    ABOUT: {
      component: 'About',
      path: '/about',
    },
    JOURNAL: {
      component: 'Journal',
      path: '/journal',
    },
    FIELD_RECORDINGS: {
      component: 'JournalCategory',
      path: '/field-recordings',
    },
    WRITERS_PROGRAM: {
      component: 'JournalCategory',
      path: '/writers-program',
    },
    FROM_THE_FIELD: {
      component: 'JournalCategory',
      path: '/from-the-field',
    },
    DISPATCHES: {
      component: 'JournalCategory',
      path: '/dispatches',
    },
    PERSPECTIVE: {
      component: 'JournalCategory',
      path: '/perspective',
    },
    JOURNAL_CONTENT: {
      component: 'JournalContent',
      path: '/Journal/:slug',
    },
    PREVIEW: {
      component: 'Preview',
      path: '/preview/:id',
    },
    HOST: {
      component: 'Host',
      path: '/host',
    },
    TERMS_AND_CONDITIONS: {
      component: 'UniquePage',
      path: '/terms-and-conditions',
    },
    PRIVACY_POLICY: {
      component: 'UniquePage',
      path: '/privacy-policy',
    },
    FAQ: {
      component: 'FAQ',
      path: '/faq',
    },
    REDIRECT: {
      component: 'Redirect',
      path: '/redirect',
    },
    SCIENCE: {
      component: 'Science',
      path: '/science',
    },
    SCIENCE_BENEFITS: {
      component: 'Science',
      path: '/science#benefits',
    },
    SCIENCE_REFERENCE: {
      component: 'Science',
      path: '/science#reference',
    },
    STAGING_PASSWORD: {
      component: 'StagingPassword',
      path: '/stop-it-get-some-help',
    },
    CLARITY: {
      component: 'HomeV2',
      path: '/clarity',
    },
    SWITCHOFF: {
      component: 'HomeV2',
      path: '/switch-off',
    },
    CALM: {
      component: 'HomeV2',
      path: '/calm',
    },
    ROOT_NZ: {
      component: 'HomeV2',
      path: '/NZ',
    },
    ROOT_GB: {
      component: 'HomeV2',
      path: '/UK',
    },
    MATES: {
      component: 'Mates',
      path: '/mates',
    },
    REFER: {
      component: 'Refer',
      path: '/refer',
    },
    GLOBAL_STUDYS: {
      component: 'Empty',
      path: '/globalnaturestudy',
    },
    GLOBAL_STUDY_V2: {
      component: 'Empty',
      path: '/global-nature-study',
    },
    NATURE_WORK: {
      component: 'Empty',
      path: '/thenatureofwork',
    },
    UNYOKED_WORK: {
      component: 'Empty',
      path: '/unyoked-at-work',
    },
    NATURE_CREATIVITY: {
      component: 'Empty',
      path: '/nature-of-creativity',
    },
    MENTAL_HEALTH: {
      component: 'PillarPage',
      path: '/wellness',
    },
    CREATIVITY: {
      component: 'PillarPage',
      path: '/creativity',
    },
    RELATIONSHIPS: {
      component: 'PillarPage',
      path: '/relationships',
    },
    EMPLOYEE_WELLNESS: {
      component: 'PillarPage',
      path: '/employee-wellness',
    },
    MENTAL_HEALTH_JOURNAL: {
      component: 'PillarPage',
      path: '/wellness/:journal',
    },
    CREATIVITY_JOURNAL: {
      component: 'PillarPage',
      path: '/creativity/:journal',
    },
    RELATIONSHIPS_JOURNAL: {
      component: 'PillarPage',
      path: '/relationships/:journal',
    },
    EMPLOYEE_WELLNESS_JOURNAL: {
      component: 'PillarPage',
      path: '/employee-wellness/:journal',
    },
    CONTRIBUTOR: {
      component: 'Profile',
      path: '/contributors/:name',
    },
    CONTRIBUTORS: {
      component: 'ProfileList',
      path: '/contributors',
    },
    CABIN_QUIZ: {
      component: 'Empty',
      path: '/cabin-quiz',
    },
    SUBSCRIBE: {
      component: 'Subscribe',
      path: '/subscribe',
    },
    NOT_FOUND: {
      component: 'HomeV2',
      path: '/',
    },
    // dynamic url name must be on the bottom for routing to work with static names
    //
    RESULTS_ITEM: {
      component: 'ResultsItem',
      path: '/:cabin',
    },
  },
  'id'
);

export default ROUTES;
