import React, { FC, useRef, useState } from 'react';

import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import isEmpty from 'ramda/src/isEmpty';
import { useDispatch } from 'react-redux';
import { animated, useTransition } from 'react-spring';
import Link from 'redux-first-router-link';
import { entityActions } from 'store/actions';
import theme from 'themeConfig/theme';

import {
  Carousel,
  HorizontalLine,
  ImageContainer,
  Typo,
} from '../../primitives';
import ImageAndTextCarousel from '../ImageAndTextCarousel';
import { HouseCardProps } from './HouseCard.props';
import { useStyles } from './HouseCard.styles';
import { CURRENCY_SYMBOL } from 'consts/locations';
import {
  getTotalStay,
  getTotalStayPrice,
  getWeekendStay,
} from 'utils/Data/pricing/pricing';

const HouseCardImage: FC<
  HouseCardProps & { classes: any; isMobile: boolean }
> = ({
  isInPaymentsPage,
  classes,
  showCarouselImage,
  slideImages = [],
  isHero,
  image,
  isMobile,
}) => {
  const [toggle, setToggle] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  if (isMobile) {
    return (
      <Box
        className={classes.crossFade}
        style={{
          width: '100%',
          overflow: 'hidden',
        }}
      >
        {isInPaymentsPage || !showCarouselImage ? (
          <div>
            <div
              ref={ref}
              className={!isHero ? classes.imageHouse : classes.imageHouseHero}
              style={{
                height: '200px',
                backgroundImage: `url(${image})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            ></div>
          </div>
        ) : (
          <ImageAndTextCarousel autoplay={true} content={slideImages} />
        )}
      </Box>
    );
  }

  return (
    <Box
      onMouseEnter={() => !isInPaymentsPage && setToggle(true)}
      onMouseLeave={() => !isInPaymentsPage && setToggle(false)}
    >
      <Box
        className={classes.crossFade}
        style={
          toggle && showCarouselImage
            ? {
                display: 'block',
                height: ref.current?.clientHeight || 300,
                transition: 'opacity 0.4s linear',
                opacity: 1,
              }
            : {
                height: 0,
                opacity: 0,
              }
        }
      >
        <ImageAndTextCarousel
          autoplay={true}
          content={slideImages}
          hideMobileText={true}
          hideOnNavigate={true}
          overrideHeight={ref.current?.clientHeight || 300}
        />
      </Box>

      <div
        style={
          !toggle || !showCarouselImage
            ? {
                display: 'block',
                height: 'auto',
                opacity: 1,
                transition: 'opacity 0.4s linear',
              }
            : {
                height: 0,
                opacity: 0,
              }
        }
      >
        <div
          ref={ref}
          className={!isHero ? classes.imageHouse : classes.imageHouseHero}
          style={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></div>
      </div>
    </Box>
  );
};

const HouseCardView: FC<HouseCardProps> = (props) => {
  const {
    availability,
    image,
    location,
    name,
    metadata,
    toMore,
    id,
    smallIcon,
    isHero,
    imageHeight,
    isInPaymentsPage,
    isInAccountBookingHistoryPage,
    hideAvailability,
    slideImages = [],
    showCarouselImage = true,
    pricing,
    currency,
    acceptSingleNight,
    trackProductView,
    filter,
  } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ imageHeight, isMobile });
  const dispatch = useDispatch();

  const curSymbol = currency ? CURRENCY_SYMBOL[currency] : '$';

  // const transitions = useTransition(toggle, null, {
  //   from: { opacity: 0, position: 'absolute' },
  //   enter: { opacity: 1 },
  //   leave: { opacity: 0 },
  // });

  let bookNow: React.ReactNode;

  if (filter?.startDate && filter?.endDate && pricing && currency) {
    const checkin = filter.startDate;
    const checkout = filter.endDate;
    const totalStay = getTotalStay(checkin, checkout);
    const weekendCount = getWeekendStay(checkin, checkout);
    // const weeknightCount = totalStay - weekendCount;
    const { weeknight_single, weeknights, weekend } = pricing;
    const cabinTotal = getTotalStayPrice(
      { weeknightSingle: weeknight_single, weeknights, weekend },
      totalStay,
      weekendCount,
      acceptSingleNight,
      currency
    );

    bookNow = (
      <Box mt={1}>
        <Typo component="span" variant="body3">
          Book now for&nbsp;
        </Typo>
        <Typo component="span" variant="h6">
          {curSymbol}
          {cabinTotal}
        </Typo>
      </Box>
    );
  }

  const Content = () => (
    <Box className={classes.container}>
      {!hideAvailability && <HorizontalLine className={classes.line} />}
      {!isInPaymentsPage ? (
        <>
          {!hideAvailability && (
            <Box className={classes.alignStart} mt={'10px'} mb={'10px'}>
              <Typo variant="h6" color="primaryRed">
                {availability}
              </Typo>
            </Box>
          )}
        </>
      ) : (
        <>
          <Box className={classes.alignStart} mt={'10px'} mb={'10px'}>
            <Typo variant="h4" className={classes.name}>
              {name}
            </Typo>
            <ImageContainer
              alt="house-type"
              className={classes.imageType}
              src={smallIcon}
            />
          </Box>
        </>
      )}

      <HouseCardImage {...props} classes={classes} isMobile={isMobile} />

      <Box
        className={showCarouselImage ? classes.bottom : ''}
        padding={!isInPaymentsPage ? '10px 15px' : ''}
      >
        {!isInPaymentsPage && (
          <Box className={classes.alignStart} mb={1}>
            <Box>
              <Typo variant="h6" className={classes.location}>
                {location}
              </Typo>
              <Typo variant="h4" className={classes.name}>
                {name}
              </Typo>
            </Box>
            <ImageContainer
              alt="house-type"
              className={classes.imageType}
              src={smallIcon}
            />
          </Box>
        )}
        {metadata.map((item) => (
          <Typo key={item} variant="body3">
            {item}
          </Typo>
        ))}

        {!isInPaymentsPage && pricing?.weeknights
          ? bookNow ?? (
              <Box mt={1}>
                <Typo component="span" variant="body3">
                  Price starts at&nbsp;
                </Typo>
                <Typo component="span" variant="h6">
                  {curSymbol}
                  {pricing?.weeknights}/night
                </Typo>
              </Box>
            )
          : null}
      </Box>
    </Box>
  );

  return (
    <>
      {isInPaymentsPage || isInAccountBookingHistoryPage ? (
        <Content />
      ) : (
        <Link
          className={classes.link}
          to={toMore(name)}
          onClick={() => {
            dispatch(entityActions.removeEntity());
            trackProductView?.(id, name, image);
          }}
          target="_blank"
        >
          <Box className={classes.cardContainer}>
            <Content />
          </Box>
        </Link>
      )}
    </>
  );
};

export default React.memo(HouseCardView);
