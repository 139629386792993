import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    },
    ghostButton: {
      width: 180,
    },
  })
);
