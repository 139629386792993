import React, { FC } from 'react';

import { JournalBlogCardsPublicProps } from './JournalBlogCards.props';
import JournalBlogCardsView from './JournalBlogCards.view';

const JournalBlogCardsContainer: FC<JournalBlogCardsPublicProps> = (props) => {
  return <JournalBlogCardsView {...props} />;
};

export default React.memo(JournalBlogCardsContainer);
