import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'COMMON';
const createCommonActions = makeCreateActions(ns);

const commonActions = createCommonActions({
  subscribe: presetActions.makeAsyncAction(`${ns}/SUBSCRIBE`),
  resetSubscribe: presetActions.noPayload,
  waitlist: presetActions.makeAsyncAction(`${ns}/WAITLIST`),
  waitlistForm: presetActions.makeAsyncAction(`${ns}/GET_WAITLIST_FORM`),
  contact: presetActions.makeAsyncAction(`${ns}/CONTACT_US`),
});

export default commonActions;
