import React, { FC, useEffect, useRef } from 'react';

import pathOr from 'ramda/src/pathOr';
import pick from 'ramda/src/pick';
import { useDispatch } from 'react-redux';
import { Filter } from 'types/Filter';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { UniquePageState } from 'types/store/UniquePageState';
import { asyncData } from 'utils/Redux';

import useSelectorSafe from '../../../store/selectors/useSelectorSafe';
import { HouseResultsPublicProps } from './HouseResults.props';
import View from './HouseResults.view';

const HouseResultsContainer: FC<HouseResultsPublicProps> = (props) => {
  const filter = useSelectorSafe((state) => state.filter);
  const startDate = pathOr('', ['startDate'], filter);
  const endDate = pathOr('', ['endDate'], filter);

  // @ts-ignore
  const uniquePage: UniquePageData = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<UniquePageState>(
      (state) => state.uniquePage,
      asyncData(ASYNC_STATUS.ERROR, [
        { message: 'Could not get Page. Please refresh' },
      ])
    )
  );

  const noVacancyMsg = pathOr(
    '',
    ['data', 'payload', '0', 'leftBlock'],
    uniquePage
  );

  return (
    <View
      {...props}
      // contentTitle={startDate && endDate ? 'Your Cabins' : 'All Cabins'}
      contentTitle={startDate && endDate && 'Your Cabins'}
      noVacancyMsg={noVacancyMsg}
      filter={filter as Filter}
    />
  );
};

export default React.memo(HouseResultsContainer);
