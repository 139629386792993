import React, { FC, useEffect, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { InputQuantityProps } from './InputQuantity.props';
import { useStyles } from './InputQuantity.styles';
import { InputBaseComponentProps } from '@material-ui/core';

const InputQuantityView: FC<InputQuantityProps> = (
  props: InputQuantityProps
) => {
  const classes = useStyles({});
  const [value, setValue] = useState(props.defaultValue || 0);

  useEffect(() => {
    if (props.disabled) setValue(0);
  }, [props.disabled]);

  useEffect(() => {
    setValue(props.value as number);
  }, [props.value]);

  const { min, max } = props.inputProps as InputBaseComponentProps

  return (
    <OutlinedInput
      className={classes.input}
      labelWidth={0}
      margin="none"
      type="number"
      placeholder="0"
      value={value}
      onChange={(e) => {
        const val = Number(e.target.value);
        if (min !== undefined && val < min) return;
        if (max !== undefined && val > max) return;

        setValue(val);
        props.onChange && props.onChange(val);
      }}
      inputProps={props.inputProps}
      disabled={props.disabled}
      startAdornment={
        <InputAdornment position="start">
          <IconButton
            size="small"
            edge="start"
            disabled={props.disabled}
            onClick={() => {
              if (value > 0) {
                if (min !== undefined && (value-1) < min) return;
                setValue(value - 1);
                props.onChange && props.onChange(value - 1);
              }
            }}
          >
            <RemoveIcon />
          </IconButton>
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            size="small"
            edge="end"
            disabled={props.disabled}
            onClick={() => {
              if (max !== undefined && (value+1) > max) return;
              setValue(value + 1);
              props.onChange && props.onChange(value + 1);
            }}
          >
            <AddIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default InputQuantityView;
