import React, { FC, useEffect, useRef } from 'react';

import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import pathOr from 'ramda/es/pathOr';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import userThunks from 'thunks/user';

import ROUTES from '../../../routes';
import { routerActions } from '../../../store/actions';
import { ASYNC_STATUS } from '../../../types/store/AsyncStatus';
import { formatAvailability } from '../../../utils/Data/entity/entity';
import { BookingHistoryPublicProps, Booking } from './BookingHistory.props';
import View from './BookingHistory.view';


const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const BookingHistoryContainer: FC<BookingHistoryPublicProps> = (props) => {
  const dispatch = useDispatch();
  const isMounted = useRef(true);

  const booking = useSelectorSafe((state) => state.bookings) || {
    status: ASYNC_STATUS.INITIAL,
    data: {
      bookings: [],
    },
  };

  useEffect(() => {
    if (isMounted.current && !booking.data) dispatch(userThunks.getBooking());
    return () => {
      isMounted.current = false;
    };
  }, []);

  const profile = useSelectorSafe((state) => state.profile);
  const locationState = useSelectorSafe((state) => state.location);

  const toBookingDisplay = (b: any): Booking => {
    const sD = parseISO(pathOr('', ['startDate'], b));
    const eD = parseISO(pathOr('', ['endDate'], b));

    let entityMetadata: { [key: string]: any } = pathOr(
      [],
      ['entity', 'metadata'],
      b
    );

    if (Array.isArray(entityMetadata)) {
      const em: { [key: string]: any } = {};
      for (let m of entityMetadata) {
        em[m.key] = m.value;
      }

      entityMetadata = em;
    }

    return {
      id: pathOr('', ['id'], b),
      startDate: format(sD, 'MMM dd, yyyy'),
      endDate: format(eD, 'MMM dd, yyyy'),
      bookingMetadata: pathOr({}, ['metadata'], b),
      startDateFull: format(sD, 'MMM dd, yyyy hh:mm aa').toString(),
      endDateFull: format(eD, 'MMM dd, yyyy hh:mm aa').toString(),
      item: {
        id: pathOr('', ['entity', 'id'], b),
        image: pathOr('', ['preview'], entityMetadata),
        availability: `${formatAvailability(sD, eD, true)}`,
        name: pathOr('', ['entity', 'title'], b),
        location: pathOr('', ['location'], entityMetadata),
        metadata: [
          `Status: ${capitalize(pathOr('', ['status'], b))}`,
          // `Guests: ${pathOr('', ['metadata', 'guests'], b)} people`,
          // `Beds: ${pathOr('', ['beds'], entityMetadata)}`,
        ],
        smallIcon: pathOr('', ['smallIcon'], entityMetadata),
      },
    };
  };

  const isFutureDate = (b: any) => {
    return parseISO(b.startDate) >= new Date() && b.status === 'confirmed';
  };

  const isPastDate = (b: any) => {
    return parseISO(b.startDate) < new Date() && b.status === 'confirmed';
  };

  const upcomingBookings = pathOr([], ['data', 'bookings'], booking)
    .filter(isFutureDate)
    .reverse()
    .map(toBookingDisplay);

  const pastBookings = pathOr([], ['data', 'bookings'], booking)
    .filter(isPastDate)
    .map(toBookingDisplay);

  const goToResults = () => {
    dispatch(routerActions.link(ROUTES.RESULTS));
  };

  const onAddProvisions = (id: string) => {
    const provisionBooking = upcomingBookings.find((ub) => ub.id === id);
    dispatch(
      routerActions.link(ROUTES.HOUSE_PAYMENTS, {
        id: pathOr('', ['item', 'id'], provisionBooking),
        name: pathOr('', ['item', 'name'], provisionBooking),
        data: {
          startDate: pathOr('', ['startDate'], provisionBooking),
          endDate: pathOr('', ['endDate'], provisionBooking),
          guests: pathOr('', ['bookingMetadata', 'guests'], provisionBooking),
          bookingId: pathOr('', ['id'], provisionBooking),
          bookingMetadata: pathOr({}, ['bookingMetadata'], provisionBooking),
          addProvisions: true,
        },
        provision: pathOr({}, ['payload', 'redirectPayload'], locationState),
      })
    );
  };

  return (
    <View
      {...props}
      bookingStatus={booking.status}
      upcomingBookings={upcomingBookings}
      pastBookings={pastBookings}
      goToAccount={() => dispatch(routerActions.link(ROUTES.ACCOUNT))}
      goToResults={goToResults}
      onAddProvisions={onAddProvisions}
    />
  );
};

export default React.memo(BookingHistoryContainer);
