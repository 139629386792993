import { Dispatch } from 'redux';
import { getEntityByIdEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { entityActions } from 'store/actions';

import { Store } from '../../types/store/Store';

const getEntityById =
  (id: string) => (dispatch: Dispatch, getState: () => Store) => {
    dispatch(
      entityActions.getById(
        apiFetch<string, any>(getEntityByIdEndpoint, id)(dispatch, getState)
      )
    );
  };

export default getEntityById;
