import { Contentful } from 'types/Contentful';
import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'UNIQUEPAGE';
const createUniquePagesActions = makeCreateActions(ns);

const uniquePageActions = createUniquePagesActions({
  getByName: presetActions.makeAsyncAction<Contentful[]>(`${ns}/GET_BY_NAME`),
  getSciencePage: presetActions.makeAsyncAction<Contentful[]>(
    `${ns}/GET_SCIENCEPAGE`
  ),
});

export default uniquePageActions;
