import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors, fontFamilies } from '../../../themeConfig/themeConstants';
import { pxToRem } from '../../../themeConfig/typography';

const { plain, pitch } = fontFamilies;

interface StyleProps {
  isMobile?: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    },
    breadcrumbs: ({ isMobile }: StyleProps) => (
      isMobile ? 
        {
          display: 'flex',
          flexDirection: 'column',
          gap: 10,

          '& div': {
            display: 'flex'
          }
        } : {
          gap: 10,
          '& div': {
            display: 'flex'
          }
        }
    ),
    navButton: {
      display: 'flex',
      cursor: 'pointer',
    },
    navArrow: {
      fontSize: pxToRem(18),
    },
    fatLine: {
      backgroundColor: colors.primaryDarkGreen,
      height: 2.5,
    },
    line: {
      backgroundColor: colors.primaryDarkGreen,
      height: 1,
    },
    ghostButton: {
      width: 180,
    },
    mobile: {
      borderBottom: '2px solid rgb(6, 39, 11)',
      paddingBottom: '5px',
    },
    inputMobile: {
      backgroundColor: 'transparent !important',
      border: 'none',
      outline: 'none',
      fontFamily: pitch,
      fontSize: pxToRem(12),
      color: 'rgb(6, 39, 11)',
    },
    inputRoot: {
      '&::placeholder': {
        opacity: 1,
      },
    },
    inputLabel: {
      fontFamily: plain,
      fontWeight: 500,
      fontSize: '.8rem',
      lineHeight: '1rem',
    },
    error: {
      paddingLeft: 2,
      paddingRight: 2,
    },
    disabled: {
      opacity: 0.5,
    },
    mobileError: {
      paddingLeft: 2,
      paddingRight: 2,
      color: '#D2232A',
      margin: 0,
      fontSize: '0.75rem',
      marginTop: '8px',
      minHeight: '1em',
      textAlign: 'left',
      fontFamily: plain,
      fontWeight: 400,
      lineHeight: '1em',
    },
    sectionHeader: {
      display: 'flex',
      justifyContent: 'space-between',

      '& svg': {
        fontSize: 48,
      },
    },
  })
);
