import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors, fontFamilies } from '../../../themeConfig/themeConstants';
import { pxToRem } from '../../../themeConfig/typography';

const { plain, pitch } = fontFamilies;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    },
    ghostButton: {
      width: 180,
    },
    arrow: {
      fontSize: pxToRem(12),
    },
    horizontalLine: {
      backgroundColor: `${colors.primaryDarkGreen} !important`,
      height: 2.5,
    },
    contentContainer: {
      marginTop: theme.spacing(5),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(10),
        paddingLeft: '5%',
        paddingRight: '5%',
      },
    },
    mobile: {
      borderBottom: '2px solid rgb(6, 39, 11)',
      paddingBottom: '5px',
    },
    inputMobile: {
      backgroundColor: 'transparent !important',
      border: 'none',
      outline: 'none',
      fontFamily: pitch,
      fontSize: pxToRem(12),
      color: 'rgb(6, 39, 11)',
    },
    inputRoot: {
      '&::placeholder': {
        opacity: 1,
      },
    },
    inputLabel: {
      fontFamily: plain,
      fontWeight: 500,
      fontSize: '.8rem',
      lineHeight: '1rem',
    },
    error: {
      paddingLeft: 2,
      paddingRight: 2,
    },
    disabled: {
      opacity: 0.5,
    },
    mobileError: {
      paddingLeft: 2,
      paddingRight: 2,
      color: '#D2232A',
      margin: 0,
      fontSize: '0.75rem',
      marginTop: '8px',
      minHeight: '1em',
      textAlign: 'left',
      fontFamily: plain,
      fontWeight: 400,
      lineHeight: '1em',
    },
  })
);
