import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';
import { ModalPublicProps } from './Modal.props';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      paddingLeft: '15px',
      paddingRight: '15px',
      paddingBottom: '40px',
    },
    logo: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 15,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 'max-content',
    },
    closeIcon: ({ closeIconLeft }: ModalPublicProps) => ({
      width: '34px',
      height: '34px',
      position: 'absolute',
      top: '13px',
      cursor: 'pointer',
      ...(!closeIconLeft && { right: '13px' }),
      ...(closeIconLeft && { left: '13px' }),
      color: colors.primaryDarkGreen,
    }),
    titleContainer: {
      padding: '10px 15px',
      minHeight: '60px',
      boxSizing: 'border-box',
    },
    paper: {
      boxShadow: 'none',
      backgroundColor: colors.primaryYellow,
    },
    rootBlock: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    paperBlock: {
      boxShadow: 'none',
      backgroundColor: '#fff',
    },
    contentBlock: {
      paddingLeft: '15px',
      paddingRight: '15px',
      paddingBottom: '15px',
    }
  })
);
