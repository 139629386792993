import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Typo } from '../../primitives';
import { TextHeaderProps } from './TextHeader.props';
import { useStyles } from './TextHeader.styles';

const TextHeaderView: FC<TextHeaderProps> = (props: TextHeaderProps) => {
  const { leftText, rightText, rightSubtext } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles({ isMobile });

  return (
    <Grid container spacing={isXs ? 0 : 6}>
      <Grid item xs={12} sm={6}>
        <Box mb={isMobile ? 5 : 0}>
          <Typo variant={isMobile ? 'h3' : 'h1'} className={classes.leftText}>
            {leftText}
          </Typo>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        {rightText && (
          <Box mb={3}>
            <Typo
              variant={isMobile ? 'h5' : 'h3'}
              className={classes.rightText}
              dangerouslySetInnerHTML={{ __html: rightText }}
            />
          </Box>
        )}
        <Typo
          variant={isMobile ? 'body3' : 'body1'}
          dangerouslySetInnerHTML={{ __html: rightSubtext }}
        />
      </Grid>
    </Grid>
  );
};

export default TextHeaderView;
