import React, { FC, Fragment, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TrackVisibility from 'react-on-screen';
import { useSpring, animated } from 'react-spring';
import Link from 'redux-first-router-link';

import { LeftArrow, RightArrow } from '../../../assets/svgs';
import { useWindowSize } from '../../../utils/Hooks';
import { Typo, Carousel, GhostButton, ImageContainer } from '../../primitives';
import { JournalCarouselProps } from './JournalCarousel.props';
import { useStyles } from './JournalCarousel.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NextArrow = (props: any) => {
  const { className, style, onClick, setTextOpacity } = props;

  const onClickOverride = () => {
    setTextOpacity()(0);
    onClick();
  };

  return (
    <RightArrow
      className={className}
      style={{ ...style }}
      onClick={onClickOverride}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PrevArrow = (props: any) => {
  const { className, style, onClick, setTextOpacity } = props;

  const onClickOverride = () => {
    setTextOpacity()(0);
    onClick();
  };

  return (
    <LeftArrow
      className={className}
      style={{ ...style }}
      onClick={onClickOverride}
    />
  );
};

const JournalCarouselView: FC<JournalCarouselProps> = (props) => {
  const {
    content,
    dots = false,
    autoplay = true,
    speed = 500,
    autoplaySpeed = 5000,
    titleStyle = '',
    captionStyle = '',
    hideMobileText,
    hideOnNavigate,
    animateFade,
    crossedOutText = '',
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [textOpacity, setTextOpacity] = useState(1);
  const [trigger, setTrigger] = useState(false);

  // wont work in server-side
  const { height = 1024, width = 768 } = useWindowSize();
  const measure = height > width ? height : width;
  let contentHeight = isMobile ? measure / 2 : measure / 1.5;
  // very small phones
  if (measure <= 568) contentHeight = measure / 1.5;

  const classes = useStyles({
    contentHeight,
  });

  if (!content || content.length <= 0) {
    return <></>;
  }

  return (
    <Box className={classes.container}>
      <Carousel
        dots={dots}
        autoplay={autoplay}
        speed={speed}
        initialSlide={0}
        autoplaySpeed={autoplaySpeed}
        className={classes.carousel}
        adaptiveHeight={true}
        arrows={true}
        onSwipe={() => hideOnNavigate && setTextOpacity(0)}
        nextArrow={
          <NextArrow
            setTextOpacity={() => (hideOnNavigate ? setTextOpacity : () => {})}
          />
        }
        prevArrow={
          <PrevArrow
            setTextOpacity={() => (hideOnNavigate ? setTextOpacity : () => {})}
          />
        }
      >
        {content.map(
          (
            {
              id,
              externalUrl,
              image,
              imageCaption,
              banner,
              category,
              title,
              subtitle,
              slug,
              readingTime,
            },
            index
          ) => {
            const formatTitle = title || '';
            const categoryRead = category
              ? category.join(', ') + ' | ' + readingTime + ' min read'
              : imageCaption;

            return (
              <Box key={index}>
                <Box className={classes.carouselContainer}>
                  <Box
                    className={classes.contentContainer}
                    style={{
                      opacity: textOpacity,
                      flexDirection: isMobile ? 'column' : 'row',
                    }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <ImageContainer
                        alt={title}
                        src={category ? banner : image || ''}
                        className={classes.image}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      className={classes.rightContainer}
                    >
                      <Box mb={isMobile ? 2 : 2}>
                        <Typo variant="body0" className={classes.categoryRead}>
                          {categoryRead}
                        </Typo>
                      </Box>
                      <Box mb={isMobile ? 3 : 4}>
                        <Typo
                          variant={isMobile ? 'h3' : 'h1'}
                          className={classes.title}
                        >
                          {formatTitle}
                        </Typo>
                        <Typo
                          variant={isMobile ? 'h4' : 'h2'}
                          className={classes.subTitle}
                        >
                          {subtitle}
                        </Typo>
                      </Box>
                      {!isMobile && (
                        <Link
                          className={classes.link}
                          to={
                            (category ? '/Journal/' + slug : externalUrl) ?? ''
                          }
                          target={category ? '_self' : '_blank'}
                        >
                          <GhostButton
                            style={classes.readMore}
                            text="Read More"
                          />
                        </Link>
                      )}
                    </Grid>
                    {isMobile && (
                      <Link
                        className={classes.link}
                        to={(category ? '/Journal/' + slug : externalUrl) ?? ''}
                        target={category ? '_self' : '_blank'}
                      >
                        <GhostButton
                          style={classes.readMore}
                          text="Read More"
                        />
                      </Link>
                    )}
                  </Box>
                </Box>
              </Box>
            );
          }
        )}
      </Carousel>
    </Box>
  );
};

export default React.memo(JournalCarouselView);
