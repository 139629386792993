import React, { FC } from 'react';

import Link from '@material-ui/core/Link';
import { colors } from 'themeConfig/themeConstants';

import { LinkProps } from './Link.props';
import { useStyles } from './Link.styles';

const LinkView: FC<LinkProps> = (props: LinkProps) => {
  const classes = useStyles({
    linkColor: props.linkColor || (props.invert && colors.primaryYellow) || colors.primaryDarkGreen,
    isDisableAnimation: !props.animated,
    invert: props.invert,
    noHighlight: props.noHighlight,
  });

  return (
    <Link
      id={props.animated ? 'animatedLink' : ''}
      color="inherit"
      onClick={props.onClick}
      className={classes.link}
      {...(props.href && { 
        href: props.href, 
        target: props.target, 
        rel: props.rel 
      })}
    >
      {props.children}
    </Link>
  );
};

export default React.memo(LinkView);
