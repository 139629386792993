import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    shown: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    secondRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
    },
    line: {
      backgroundColor: colors.primaryDarkGreen,
      height: 1,
    },
  })
);
