import React, { FC } from 'react';

import { ImageTextPublicProps, ImageTextProps } from './ImageText.props';
import ImageTextView from './ImageText.view';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { asyncData } from 'utils/Redux';
import pick from 'ramda/es/pick';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { FeatureFlagsState } from 'types/store/FeatureFlagState';

const ImageTextContainer: FC<ImageTextPublicProps> = (
  ownProps: ImageTextPublicProps
) => {
  // {{containerBodyPrefabs}}

  const featureFlags = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<FeatureFlagsState>(
      (state) => state.featureFlags,
      asyncData(ASYNC_STATUS.ERROR, [
        { message: 'Could not get feature flags' },
      ])
    )
  );

  const combinedProps: ImageTextProps = {
    ...ownProps,
    // your calculated props
    featureFlags: featureFlags.data || {},
  };
  return <ImageTextView {...combinedProps} />;
};

export default ImageTextContainer;
