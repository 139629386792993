export const paymentFormInitialState = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  address: '',
  city: '',
  state: '',
  postCode: '',
  cardName: '',
};

export const paymentFormFields = [
  {
    id: 'firstName',
    label: 'First name',
  },
  {
    id: 'lastName',
    label: 'Last name',
  },
  {
    id: 'email',
    label: 'Email',
    type: 'email',
  },
  {
    id: 'mobile',
    label: 'Mobile',
    type: 'tel',
  },
  {
    id: 'address',
    label: 'Address',
  },
  {
    id: 'city',
    label: 'City',
  },
  {
    id: 'state',
    label: 'State / County',
  },
  {
    id: 'postCode',
    label: 'Postcode',
  },
];
