import React, { FC } from 'react';

import { SubmitFieldPublicProps } from './SubmitField.props';
import View from './SubmitField.view';

const SubmitFieldContainer: FC<SubmitFieldPublicProps> = (props) => (
  <View {...props} />
);

export default React.memo(SubmitFieldContainer);
