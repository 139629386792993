import React, { FC, useState } from 'react';

import { Button as CopyButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Typo, Button, InputField } from '../../primitives';
import { AccountProps } from './Account.props';
import { useStyles } from './Account.styles';

const AccountView: FC<AccountProps> = ({ settings }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles();

  return (
    <Box>
      <Typo variant={isXs ? 'h6' : 'h1'} className={classes.header}>
        Account Settings
      </Typo>
      <Box mb={isXs ? 5 : 10} />
      <Grid container spacing={isXs ? 0 : 6}>
        {settings.map(({ text, subtitle, onClick }) => (
          <Grid
            key={text}
            item
            container
            direction="column"
            xs={12}
            sm={4}
            style={{
              marginBottom: isXs ? theme.spacing(6) : theme.spacing(12),
            }}
          >
            <Button
              onClick={onClick}
              variant={isXs ? 'h3' : 'h5'}
              text={text}
              reverseUnderline={true}
            />
            <Box mb={isXs ? 1 : 3} />
            <Typo variant="body3">{subtitle}</Typo>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default React.memo(AccountView);
