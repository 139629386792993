import React, { FC, useEffect, useRef } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { getStripe } from 'consts/stripe';
import { useCheckoutTrack } from 'hooks/useCheckoutTrack';
import pathOr from 'ramda/es/pathOr';
import { useDispatch } from 'react-redux';
import { redirect } from 'redux-first-router';
import {
  checkoutActions,
  routerActions,
  userActions,
  vouchersActions,
} from 'store/actions';
import userThunks from 'thunks/user';

import ROUTES from '../../../routes';
import useCurrentRoute from '../../../store/selectors/useCurrentRoute';
import useSelectorSafe from '../../../store/selectors/useSelectorSafe';
import { ASYNC_STATUS } from '../../../types/store/AsyncStatus';
import { CheckoutFormPublicProps } from './CheckoutForm.props';
import CheckoutFormView from './CheckoutForm.view';
import { useQueryWrapper } from 'utils/Hooks/useQueryWrapper';
import { getContentfulEntry } from 'services/fetch/apiEndpoints';
import { format } from 'date-fns';

const CheckoutFormContainer: FC<CheckoutFormPublicProps> = (
  publicProps: CheckoutFormPublicProps
) => {
  const dispatch = useDispatch();
  const currentRoute = useCurrentRoute();

  const currentEntity = useSelectorSafe(
    (state) => state.currentEntity.data?.entity
  );
  const country =
    currentRoute === ROUTES.VOUCHER_PAYMENTS
      ? publicProps?.prescriptionDetails?.country || 'AU'
      : (currentEntity && currentEntity?.metadata?.country) || 'AU';

  const stripe = getStripe(country);

  const currentUser = useSelectorSafe((state) => state.profile?.data?.user);
  const setOrderProps = (payload: any) => {
    dispatch(
      checkoutActions.setProps({
        publicProps,
        currentUser,
        currentEntity,
        payload,
      })
    );
  };

  const { isLoading: authHoldLoading, data: authHoldInfo } = useQueryWrapper<
    string,
    any
  >(['authHoldInfo'], getContentfulEntry, '48NpYFRnpkDIgHo9b1PtMm');
  const authHoldMessage = authHoldInfo?.payload?.leftBlock;
  const bond = authHoldInfo?.payload?.additionalData[country];
  const finalAuthHoldMessage =
    authHoldMessage && authHoldMessage.replaceAll('{bond}', bond);

  const {
    trackCheckoutCompleted,
    trackBookingFailed,
    trackPaymentInfoEntered,
    trackOrderUpdated,
  } = useCheckoutTrack(publicProps, currentUser, currentEntity);

  const options = {
    clientSecret: publicProps.intent?.secret,
  };

  return (
    <Elements stripe={stripe} options={options}>
      <CheckoutFormView
        {...publicProps}
        clearVoucher={() => {
          dispatch(vouchersActions.clear());
        }}
        clearPayload={() => {
          dispatch(
            publicProps.intent
              ? userActions.clearPaymentIntent()
              : userActions.clearPaymentAfterpay()
          );
        }}
        setPayload={(payload: any) => {
          dispatch(
            publicProps.intent
              ? userActions.setPaymentIntent({
                  data: { ...publicProps.intent, payload },
                })
              : userActions.setPaymentAfterpay({
                  data: { ...publicProps.afterpay, payload },
                })
          );
        }}
        goToSuccess={() => {
          const { addProvisions } = publicProps;

          let message =
            "Details of your journey are headed to your inbox. We'll be in touch soon with everything else you need " +
            'to know. In the meantime, feel the grass beneath your feet, stare at the clouds, and feel comfortable ' +
            'doing nothing for a while.\n' +
            'Good vibes.';
          if (currentRoute === ROUTES.VOUCHER_PAYMENTS) {
            message = 'Check your email for a prescription confirmation.';

          }

          if (addProvisions)
            message = 'Provisions have now been added to your trip.';

          // dispatch(
          //   redirect({
          //     type: 'ROUTER/CHECKOUT_CONFIRMED',
          //     payload: {
          //       message,
          //     },
          //   })
          // );

          dispatch(routerActions.link(ROUTES.CHECKOUT_CONFIRMED, { message }));
        }}
        authHoldMessage={finalAuthHoldMessage}
        isAuthHoldLoading={authHoldLoading}
        isLoggedIn={!!useSelectorSafe((state) => state.token)}
        isInHousePayments={currentRoute === ROUTES.HOUSE_PAYMENTS}
        trackCheckoutCompleted={trackCheckoutCompleted}
        trackPaymentInfoEntered={trackPaymentInfoEntered}
        trackOrderUpdated={trackOrderUpdated}
        trackBookingFailed={trackBookingFailed}
        setOrderProps={setOrderProps}
        currentUser={currentUser}
        intent={publicProps.intent}
      />
    </Elements>
  );
};

export default React.memo(CheckoutFormContainer);
