import React, { FC } from 'react';

import { ResponsiveVideoPlayerModalPublicProps } from './ResponsiveVideoPlayerModal.props';
import View from './ResponsiveVideoPlayerModal.view';

const ResponsiveVideoPlayerModalContainer: FC<ResponsiveVideoPlayerModalPublicProps> = (
  props
) => <View {...props} />;

export default React.memo(ResponsiveVideoPlayerModalContainer);
