import { makeStyles, createStyles } from '@material-ui/core/styles';
import { colors } from 'themeConfig/themeConstants';

interface StyleProps {
  isMobile: boolean;
}

export const useStyles = makeStyles((theme) => {
  const containerStyles = {
    backgroundColor: colors.primaryDarkGreen,
    paddingTop: theme.spacing(8),
    paddingLeft: '5%',
    paddingRight: '5%',
    [theme.breakpoints.up(1441)]: {
      paddingLeft: '11%',
      paddingRight: '11%',
    },
  };

  return createStyles({
    approachContainer: {
      '& > * ': {
        color: colors.primaryYellow,
      },
      padding: '3rem 5% 2rem',
      [theme.breakpoints.up(1440)]: {
        paddingLeft: '11%',
        paddingRight: '11%',
      },
    },
    blockContainer: ({ isMobile }: StyleProps) => ({
      ...containerStyles,
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      gap: '5vw',
      paddingBottom: theme.spacing(8),
      justifyContent: 'center',
      alignItems: 'center',

      '&  img': {
        width: '100%',
      },
      '&  h1': {
        fontSize: '3rem',
      },
      '& :first-child': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        objectFit: 'cover',
        maxHeight: '568px',
      },
      '& :last-child': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        maxHeight: '568px',
      },
    }),
    unsetHeight: {
      '& :first-child': {
        maxHeight: 'unset !important',
      },
      '& :last-child': {
        maxHeight: 'unset !important',
      },
    },
    yellowBlock: {
      alignItems: 'flex-start !important',
      backgroundColor: `${colors.primaryYellow} !important`,
      color: colors.primaryDarkGreen,
    },
    mobileInverse: ({ isMobile }: StyleProps) =>
      isMobile
        ? {
            flexDirection: 'column-reverse',
          }
        : {},
    textContainer: ({ isMobile }: StyleProps) => ({
      color: colors.primaryYellow,
      ...(isMobile
        ? {
            marginTop: '50px',
            width: '90%',
            margin: 'auto',
          }
        : {
            width: '75%',
            margin: 'auto',
          }),
    }),
  });
});
