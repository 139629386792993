/* eslint-disable prettier/prettier */
import * as React from 'react';

function SvgCheckboxUnchecked(props) {
  return (
    <svg width="40px" height="40px" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle
        stroke="#041807"
        strokeWidth={2}
        cx={20.5}
        cy={20.5}
        r={18.5}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgCheckboxUnchecked;
