import React, { FC } from 'react';

import Popper from '@material-ui/core/Popper';
import { useSpring, animated } from 'react-spring';

import { PopperProps } from './Popper.props';
import { useStyles } from './Popper.styles';

interface FadeProps {
  children?: React.ReactElement;
  in?: boolean;
  onEnter?: () => {};
  onExited?: () => {};
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
  props,
  ref
) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const PopperView: FC<PopperProps> = (props: PopperProps) => {
  const classes = useStyles();
  const { open, anchorEl, placement, content, margin } = props;

  const id = open ? 'spring-popper' : undefined;

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      transition
    >
      {({ TransitionProps }) => (
        //  @ts-ignore
        <Fade {...TransitionProps}>
          <div className={classes.paper} style={{ margin }}>
            {content}
          </div>
        </Fade>
      )}
    </Popper>
  );
};

export default React.memo(PopperView);
