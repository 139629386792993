import { sv } from 'date-fns/locale';
import * as React from 'react';

const GlobeIcon = (props) => {

  let htmlColor = props.htmlColor;
  let svg = { ...props  };
  delete svg.htmlColor;

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 300 300"
      xmlSpace="preserve"
      {...svg}
    >
      <style>
        {`.st0{fill:none;stroke:${
          htmlColor || '#162d1b'
        };stroke-width:17.907;stroke-linecap:round;stroke-linejoin:round}`}
      </style>
      <g id="Icon_feather-globe" transform="translate(-2.5 -2.5)">
        <path
          id="Path_614"
          className="st0"
          d="M288.34 152.5c0 75.02-60.82 135.84-135.84 135.84-75.02 0-135.84-60.82-135.84-135.84 0-75.02 60.82-135.84 135.84-135.84 75.02 0 135.84 60.82 135.84 135.84 0 0 0 0 0 0z"
        />
        <path id="Path_615" className="st0" d="M16.66 152.5h271.69" />
        <path
          id="Path_616"
          className="st0"
          d="M152.5 16.66a207.822 207.822 0 0 1 54.33 135.84 207.822 207.822 0 0 1-54.33 135.84A207.822 207.822 0 0 1 98.17 152.5 207.822 207.822 0 0 1 152.5 16.66z"
        />
      </g>
    </svg>
  )
};

export default GlobeIcon;
