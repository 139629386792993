import React, { FC } from 'react';

import { BlockLocations, ScienceBlock } from 'types/UniquePage';

import {
  ScienceApproachPublicProps,
  ScienceApproachProps,
} from './ScienceApproach.props';
import ScienceApproachView from './ScienceApproach.view';

const entryFallback = {
  isImage: false,
  blockContent: '',
};

const ScienceApproachContainer: FC<ScienceApproachPublicProps> = (
  publicProps: ScienceApproachPublicProps
) => {
  const { contentEntries } = publicProps;

  if (!contentEntries.length) {
    return <></>;
  }

  const blocks = contentEntries.sort((a, b) => a.order - b.order);
  const combinedProps: ScienceApproachProps = {
    ...publicProps,
    // calcedProps
    blocks,
  };

  return <ScienceApproachView {...combinedProps} />;
};

export default ScienceApproachContainer;
