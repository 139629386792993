import { Dispatch } from 'redux';
import {
  deletePaymentMethodEndpoint,
  getPaymentMethodEndpoint,
} from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { userActions } from 'store/actions';

import { Store } from '../../types/store/Store';

interface Data {
  country: string;
  sourceId: string;
}

const deletePaymentMethod = (data: Data) => (
  dispatch: Dispatch,
  getState: () => Store
) => {
  dispatch(
    userActions.deletePaymentMethod(
      apiFetch<Data, any>(deletePaymentMethodEndpoint, data)(
        dispatch,
        getState
      ).then(() =>
        apiFetch<{}, any>(getPaymentMethodEndpoint, { country: data.country })(dispatch, getState)
      )
    )
  );
};

export default deletePaymentMethod;
