import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: '100%',
      height: 'auto',
      marginBottom: 10,
    },
    leftContainer: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    button: {
      width: 250,
    },
    beFirstDesktop: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 32,
      },
    },
    notAvailable: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    },
  })
);
