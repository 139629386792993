import React, { FC, useEffect, useState, useRef } from 'react';

import equals from 'ramda/es/equals';
import pathOr from 'ramda/es/pathOr';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';

import ROUTES from '../../../routes';
import { routerActions } from '../../../store/actions';
import userThunks from '../../../thunks/user';
import { ASYNC_STATUS } from '../../../types/store/AsyncStatus';
import { PersonalInfoPublicProps } from './PersonalInfo.props';
import View from './PersonalInfo.view';

const PersonalInfoContainer: FC<PersonalInfoPublicProps> = (props) => {
  const initial = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    address: '',
    city: '',
    state: '',
    postCode: '',
  };

  const [data, setData] = useState<object>(initial);
  const dispatch = useDispatch();
  const isMounted = useRef(true);

  const leftFields = [
    {
      id: 'firstName',
      label: 'First name',
    },
    {
      id: 'lastName',
      label: 'Last name',
    },
    {
      id: 'phoneNumber',
      label: 'Mobile',
      type: 'tel',
    },
    {
      id: 'address',
      label: 'Address',
    },
  ];

  const rightFields = [
    {
      id: 'city',
      label: 'City',
    },
    {
      id: 'state',
      label: 'State / County',
    },
    {
      id: 'postCode',
      label: 'Postcode',
    },
  ];

  const profile = useSelectorSafe((state) => state.profile);
  const status = useSelectorSafe((state) => state.profile.status);
  const country = useSelectorSafe((state) => state.country);

  const initialValues = pathOr(initial, ['data', 'profile'], profile);

  const onSave = (saveData: object) => {
    setData(saveData);
    dispatch(userThunks.updateProfile(saveData));
  };

  // TODO: fix profile error saving thunk
  useEffect(() => {
    if (
      status === ASYNC_STATUS.COMPLETE &&
      // @ts-ignore
      data.firstName &&
      isMounted.current &&
      equals(data, initialValues)
    ) {
      dispatch(routerActions.link(ROUTES.ACCOUNT));
    }
    return () => {
      isMounted.current = false;
    };
  }, [status, data, initialValues]);

  return (
    <View
      {...props}
      save={onSave}
      goToAccount={() => dispatch(routerActions.link(ROUTES.ACCOUNT))}
      success={status === ASYNC_STATUS.COMPLETE}
      loading={status === ASYNC_STATUS.LOADING}
      errorMessage=""
      openErrorSnack={false}
      leftFields={leftFields}
      rightFields={rightFields}
      initialValues={{ ...initialValues }}
      initialErrors={initial}
      country={country?.countryCode || 'AU'}
    />
  );
};

export default React.memo(PersonalInfoContainer);
