import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import MaterialButton from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import ArrowUpwardIcon from '@material-ui/icons/ExpandLess';
import ArrowDownwardIcon from '@material-ui/icons/ExpandMore';
import { Skeleton } from '@material-ui/lab';
import { addDays, subDays } from 'date-fns';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { pick } from 'ramda';
import pathOr from 'ramda/src/pathOr';
import ReactGA from 'react-ga';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { colors } from 'themeConfig/themeConstants';
import entityThunks from 'thunks/entity';
import { Entity } from 'types/Entity';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { LocationsState } from 'types/store/CountryState';
import useFilters from 'utils/Hooks/useFilters';
import { asyncData } from 'utils/Redux';

import { FILTER } from '../../../consts';
import theme from '../../../themeConfig/theme';
import { pxToRem } from '../../../themeConfig/typography';
import {
  getFullyBookedDates,
  transformBookedDates,
  isValidRange,
  isMinimumStay,
  rangeHasException,
  isMinimumStayOnNewYearsEve,
  isMinimumStayOnWeekNight,
  getDays,
  isMinimumStayOnEvents,
} from '../../../utils/Data/bookedDates/bookedDates';
import {
  Typo,
  GhostButton,
  Calendar,
  Button,
  Checkbox,
} from '../../primitives';
import { useCalendar } from '../../primitives/Calendar';
import Snackbar from '../../primitives/Snackbar';
import { HomeFilterMobileProps } from './HomeFilterMobile.props';
import { useStyles } from './HomeFilterMobile.styles';

const SELECTION = {
  LOCATION: 'location',
  DATE: 'date',
  GUESTS: 'guests',
  OFFERS: 'offers',
  BEDS: 'beds',
  EMPTY: '',
};

const getIcon = (selected: boolean) =>
  selected ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />;

const HomeFilterMobileView: FC<HomeFilterMobileProps> = (
  props: HomeFilterMobileProps
) => {
  const {
    filterData,
    alternate = false,
    entity,
    entities,
    onBook,
    nextAvailableStart,
    nextAvailableUntil,
    locations,
    questions,
    mustHaves,
    handleMustHavesChange,
    offers,
    isResultsPage,
  } = props;
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({});
  const filtersHelper = useFilters();

  const timezone = pathOr(
    'Australia/Melbourne',
    ['data', 'entity', 'timezone'],
    entity
  );

  const bookedDates = pathOr(
    [],
    ['data', 'entity', 'schedule', '0', 'bookings'],
    entity
  );

  const changeOverDays = pathOr(
    [],
    ['data', 'entity', 'schedule', '0', 'changeOverDays'],
    entity
  );

  const exceptions = pathOr(
    {
      freq: '',
      byday: '',
    },
    ['data', 'entity', 'schedule', '0', 'exceptions'],
    entity
  );

  const rawEntities: { [key: string]: Entity[] } = pathOr(
    {},
    ['data', 'entities'],
    entities
  );

  const initialStartDate = pathOr('', ['startDate'], filterData);
  const initialEndDate = pathOr('', ['endDate'], filterData);
  const currentEntity: Entity | {} = pathOr({}, ['data', 'entity'], entity);

  const [isOpen, setIsOpen] = useState(props.open || false);
  const [selected, setSelected] = useState(SELECTION.EMPTY);

  const [fullyBookedDates, setFullyBookedDates] = useState<Date[]>([]);
  const [calendarBookedDates, setCalendarBookedDates] = useState<
    { before: Date; after?: Date }[]
  >([]);
  const [disabledDays, setDisabledDays] =
    useState<{ before: Date; after?: Date }[]>();

  const [location, setLocation] = useState(
    props.initialLocation ||
      pathOr(
        {
          latitude: 0,
          longitude: 0,
          address: '',
          country: 'AU',
          cityId: null,
        },
        ['location'],
        filterData
      )
  );
  const [beds, setBeds] = useState(pathOr('', ['beds'], filterData));
  const [guests, setGuests] = useState(pathOr('', ['guests'], filterData));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [openCountries, setOpenCountries] = useState<{
    [key: string]: boolean;
  }>({});

  const [startDate, setStartDate] = useState(
    initialStartDate ||
      (nextAvailableStart ? format(nextAvailableStart, 'MMM dd, yyyy') : '')
  );
  const [endDate, setEndDate] = useState(
    initialEndDate ||
      (nextAvailableUntil ? format(nextAvailableUntil, 'MMM dd, yyyy') : '')
  );

  const [calendarMonth, setCalendarMonth] = useState<Date>(
    startDate ? parse(startDate, 'MMM dd, yyyy', new Date()) : new Date()
  );

  useEffect(() => {
    // set calendar month as next month if today's day is the last day of the <month></month>
    if (initialStartDate) return;
    const nowDate = moment().format('MMM DD, yyyy');
    const endDate = moment().endOf('month').format('MMM DD, yyyy');
    const isLastDay = nowDate === endDate;
    if (isLastDay) {
      onMonthChange(moment().add(1, 'month').startOf('month').toDate());
    }
  }, []);

  const cities = locations.data?.cities;
  const countries = locations.data?.countries;

  const { country } = location;
  const entityCapacity = pathOr(0, ['capacity'], currentEntity);
  const acceptSingleNight = pathOr(
    'false',
    ['metadata', 'acceptSingleNight'],
    currentEntity
  );
  const twoNightMinimum = pathOr(
    null,
    ['metadata', 'twoNightMinimum'],
    currentEntity
  );

  const onClose = (event: any) => {
    setIsOpen(false);
    if (typeof props.onClose === 'function') {
      props.onClose(event);
    }
  };

  const guestsLimit = Array.from(
    Array(
      entityCapacity > FILTER.GUESTS_LIMIT || !alternate
        ? FILTER.GUESTS_LIMIT
        : entityCapacity
    ).keys()
  ).map((v) => (v + 1).toString());

  const alterBookedDates = transformBookedDates(bookedDates, timezone);
  const transformedBookedDates = transformBookedDates(
    bookedDates,
    timezone,
    changeOverDays
  );

  const parsedSd = parse(startDate, 'MMM dd, yyyy', new Date());
  const parsedEd = parse(endDate, 'MMM dd, yyyy', new Date());

  const q = (q: string, key: 'label' | 'description') => {
    return questions && questions[q] && questions[q][key];
  };

  useEffect(() => {
    setIsOpen(props.open || false);
  }, [setIsOpen, props.open]);

  useEffect(() => {
    const monthKey = `${country}-${year}-${month}`;
    if (
      !alternate &&
      (!entities ||
        entities.status != ASYNC_STATUS.INITIAL ||
        (entities.data && !entities.data.entities[monthKey]))
    ) {
      dispatch(entityThunks.getForCalendar(calendarMonth, country));
    }
  }, [country]);

  useEffect(() => {
    props.updateFilter({
      ...filterData,
      location,
      startDate,
      endDate,
      beds,
      guests,
      offers: mustHaves,
    });
  }, [location, startDate, endDate, beds, guests, mustHaves]);

  useEffect(() => {
    const date = calendarMonth || new Date();
    const year = date.getFullYear(),
      month = date.getMonth();
    const { country, address } = location;
    let rawEntities = pathOr<Entity[]>(
      [],
      ['data', 'entities', `${country}-${year}-${month}`],
      entities
    );

    rawEntities = filtersHelper.getRawEntities({
      entities: rawEntities,
      mustHaves,
      country,
      address: address || '',
    });

    const rawBd = rawEntities.map((e: Entity) => ({
      bookings: pathOr([], ['schedule', '0', 'bookings'], e),
      changeOverDays: pathOr([], ['schedule', '0', 'changeOverDays'], e),
    }));

    setFullyBookedDates(getFullyBookedDates(rawBd));

    const rawCBD = rawEntities.map((e: Entity) => ({
      bookings: pathOr([], ['schedule', '0', 'bookings'], e),
      changeOverDays: [],
    }));

    setCalendarBookedDates(
      getFullyBookedDates(rawCBD).map((cbd) => ({
        before: addDays(cbd, 1),
        after: subDays(cbd, 1),
      }))
    );

    const nextMonthKey =
      month < 12
        ? `${country}-${year}-${month + 1}`
        : `${country}-${year + 1}-0`;

    if (
      rawEntities.length &&
      entities &&
      entities.data &&
      !entities.data.entities[nextMonthKey] &&
      entities.status != ASYNC_STATUS.LOADING
    ) {
      const nextMonth =
        month < 12 ? new Date(year, month + 1, 1) : new Date(year + 1, 0, 1);
      dispatch(entityThunks.getForCalendar(nextMonth, location.country));
    }
  }, [location, entities, calendarMonth]);

  const onClickGhostButton = () => {
    if (alternate) {
      if (startDate && endDate && guests) {
        onClose(null);
        onBook(startDate, endDate, guests);
      } else if (!startDate || !endDate) {
        setSelected(SELECTION.DATE);
      } else if (!guests) {
        setSelected(SELECTION.GUESTS);
      }
    } else if (props.isResultsPage) {
      onClose(null);
      props.goToResults(country, location.address);
      props.filter();
    } else {
      onClose(null);
      props.goToResults(country, location.address);
    }
  };

  const calendarHook = useCalendar(
    ({ from, to }) => {
      setStartDate(from ? format(from, 'MMM dd, yyyy') : '');
      setEndDate(to ? format(to, 'MMM dd, yyyy') : '');
      if (from && to) setSelected(SELECTION.EMPTY);
      setDisabledDays(undefined);
    },
    alternate ? alterBookedDates : calendarBookedDates,
    alternate ? exceptions : undefined,
    (from) => {
      const bookDates = alternate ? alterBookedDates : calendarBookedDates;
      let diff = 0;
      let closestDate: Date | undefined = undefined;
      for (const date of bookDates) {
        if (date.after && date.after > subDays(from, 1)) {
          if (!diff) {
            diff = date.after.getTime() - from.getTime();
            closestDate = date.after;
            continue;
          }

          if (date.after.getTime() - from.getTime() < diff) {
            diff = date.after.getTime() - from.getTime();
            closestDate = date.after;
          }
        }
      }

      const disableDay: { after?: Date; before: Date } = { before: from };
      if (closestDate) disableDay.after = addDays(closestDate, 1);

      setDisabledDays([disableDay]);

      setEndDate('');
    }
  );

  const onMonthChange = (date: Date) => {
    const year = date.getFullYear(),
      month = date.getMonth();
    const { country } = location;
    const nextMonthKey =
      month < 12
        ? `${country}-${year}-${month + 1}`
        : `${country}-${year + 1}-0`;
    const rawEntities: { [key: string]: Entity[] } = pathOr(
      {},
      ['data', 'entities'],
      entities
    );

    const now = new Date();
    if (date > now) {
      if (rawEntities && entities && entities.status != ASYNC_STATUS.LOADING) {
        if (
          !rawEntities[`${country}-${year}-${month}`] ||
          !rawEntities[`${country}-${year}-${month}`].length
        ) {
          dispatch(entityThunks.getForCalendar(date, country));
        } else if (
          !rawEntities[nextMonthKey] ||
          !rawEntities[nextMonthKey].length
        ) {
          const nextDate =
            month < 12
              ? new Date(year, month + 1, 1)
              : new Date(year + 1, 0, 1);
          dispatch(entityThunks.getForCalendar(nextDate, country));
        }
      }
    } else {
      date = now;
    }

    setCalendarMonth(date);
  };

  useEffect(() => {
    if (alternate && startDate && endDate && alterBookedDates.length > 0) {
      if (rangeHasException(parsedSd, parsedEd, exceptions)) {
        setStartDate('');
        setEndDate('');

        return;
      }

      if (!isValidRange(parsedSd, parsedEd, alterBookedDates)) {
        setStartDate('');
        setEndDate('');
      }
    }
  }, [currentEntity]);

  useEffect(() => {
    let timer: number;
    let event;
    if (!isMinimumStay(parsedSd, parsedEd, twoNightMinimum))
      setSnackbarMessage(
        twoNightMinimum &&
          (twoNightMinimum as boolean[]).map((o) => o).length == 7
          ? `Please select a minimum of 2 nights.`
          : `Bookings on ${getDays(
              twoNightMinimum
            )} must be at least two nights.`
      );
    else if (
      startDate &&
      endDate &&
      (event = isMinimumStayOnEvents(parsedSd, parsedEd))
    ) {
      setSnackbarMessage(
        `Bookings on ${(event as any).text} must be at least ${
          (event as any).nights
        } nights.`
      );
    } else if (!isMinimumStayOnNewYearsEve(parsedSd, parsedEd))
      setSnackbarMessage(
        `Bookings on New Year's Eve must be at least two nights.`
      );
    else if (
      !twoNightMinimum &&
      !isMinimumStayOnWeekNight(parsedSd, parsedEd, 'true')
    )
      setSnackbarMessage(`Bookings on weeknights must be at least two nights.`);
    if (
      alternate &&
      startDate &&
      endDate &&
      (!isMinimumStay(parsedSd, parsedEd, twoNightMinimum) ||
        event ||
        !isMinimumStayOnNewYearsEve(parsedSd, parsedEd) ||
        (!twoNightMinimum &&
          !isMinimumStayOnWeekNight(parsedSd, parsedEd, 'true')))
    ) {
      setStartDate('');
      setEndDate('');
      calendarHook.handleReset();
      setOpenSnackbar(true);

      timer = window.setTimeout(() => {
        setOpenSnackbar(false);
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [startDate, endDate]);

  const onDoIt = () => {
    if (startDate && endDate && guests) {
      ReactGA.event({
        category: 'Navigation',
        action: 'Clicked Do it with values',
      });

      onBook(startDate, endDate, guests);
    } else {
      ReactGA.event({
        category: 'Navigation',
        action: 'Clicked Do it',
      });
      setIsOpen(true);
    }
  };

  const year = (calendarMonth || new Date()).getFullYear(),
    month = (calendarMonth || new Date()).getMonth();

  const calendarDates =
    disabledDays ||
    (endDate
      ? fullyBookedDates.filter(
          (d) =>
            d < parse(startDate, 'MMM dd, yyyy', new Date()) ||
            d > addDays(parse(endDate, 'MMM dd, yyyy', new Date()), 1)
        )
      : fullyBookedDates);

  const LocationInput = () => (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
      }}
    >
      {cities &&
        countries &&
        (() => {
          const items = [];
          for (const code in cities) {
            items.push(
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <MaterialButton
                  key={code}
                  className={classes.expandedInputButton}
                  onClick={() =>
                    setOpenCountries({
                      ...openCountries,
                      [code]: !openCountries[code],
                    })
                  }
                >
                  {countries[code].name}
                </MaterialButton>
                <MaterialButton
                  style={{ justifyContent: 'flex-end' }}
                  onClick={() =>
                    setOpenCountries({
                      ...openCountries,
                      [code]: !openCountries[code],
                    })
                  }
                >
                  {openCountries[code] ? (
                    <ArrowUpwardIcon />
                  ) : (
                    <ArrowDownwardIcon />
                  )}
                </MaterialButton>
              </Box>
            );

            for (const city of cities[code]) {
              if (openCountries[code]) {
                items.push(
                  <MaterialButton
                    key={city.name}
                    style={{ marginLeft: '2em' }}
                    className={classes.expandedInputButton}
                    onClick={() => {
                      setLocation({
                        country: code,
                        address: city.name,
                        cityId: city.id,
                      });
                      setSelected(SELECTION.EMPTY);
                    }}
                  >
                    {city.name}
                  </MaterialButton>
                );
              }
            }
          }

          return items;
        })()}
    </Box>
  );

  const GuestInput = () => (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {guestsLimit.map((v) => (
        <MaterialButton
          key={v}
          className={classes.expandedInputButton}
          onClick={() => {
            setGuests(v);
            setSelected(SELECTION.EMPTY);
          }}
        >
          {v}
        </MaterialButton>
      ))}
    </Box>
  );

  const DateSelectionInput = () => (
    <>
      <Box>
        <Typo align="center" variant={isMobile ? 'h6' : 'h5'}>
          {q('booking_questions_date', 'label')}
        </Typo>
        <Typo align="center" variant="body3" style={{ marginTop: '5px' }}>
          {q('booking_questions_date', 'description')}
        </Typo>
      </Box>
      {!rawEntities[`${country}-${year}-${month}`] ? (
        <Box
          style={{
            width: '100%',
            height: '280px',
            textAlign: 'center',
          }}
        >
          {rawEntities[`${country}-${year}-${month - 1}`] &&
          rawEntities[`${country}-${year}-${month - 1}`].length ? (
            <Button
              textStyle={{
                fontSize: pxToRem(10),
              }}
              iconStyle={{ width: 0, height: 0 }}
              text={'Back to previous month'}
              hideUnderline={true}
              onClick={() =>
                setCalendarMonth(
                  month > 0
                    ? new Date(year, month - 1, 1)
                    : new Date(year - 1, 11, 1)
                )
              }
            />
          ) : null}
          <Skeleton />
          <Skeleton animation={false} />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          Loading {format(calendarMonth, 'MMMM YYY')}
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Box>
      ) : (
        <Box className={classes.calendarContainer}>
          <Calendar
            month={calendarMonth}
            bookedDates={
              alternate ? disabledDays || transformedBookedDates : calendarDates
            }
            transparent={false}
            hookState={calendarHook}
            exceptions={alternate ? exceptions : { freq: '', byday: '' }}
            isBooking={alternate}
            onMonthChange={onMonthChange}
          />
        </Box>
      )}
    </>
  );

  const OffersInput = () => (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box className={classes.mustHaveCheckBox} key={0}>
        <Checkbox
          radius="small"
          checkboxStyle={classes.mustHaveCheckBox}
          checked={mustHaves.length == 0 ? true : false}
          label="No preferences"
          onChange={(e) => {
            setSelected(SELECTION.EMPTY);
            handleMustHavesChange(e);
          }}
          value="0"
        />
      </Box>

      {offers &&
        offers.map((offer: any, index: number) => (
          <Box className={classes.mustHaveCheckBox} key={`check-${index}`}>
            <Checkbox
              radius="small"
              checkboxStyle={classes.mustHaveCheckBox}
              checked={mustHaves.includes(offer.id) ? true : false}
              label={offer.name}
              onChange={(e) => {
                setSelected(SELECTION.EMPTY);
                handleMustHavesChange(e);
                setStartDate('');
                setEndDate('');
                calendarHook.handleReset();
              }}
              value={offer.id}
            />
          </Box>
        ))}
    </Box>
  );

  const CollapseMenuButton: FC<{
    label: string;
    selectionType: string;
    displayValue?: string;
  }> = ({ label, selectionType, displayValue }) => (
    <MaterialButton
      className={classes.inputButton}
      onClick={() =>
        setSelected(
          selected === selectionType ? SELECTION.EMPTY : selectionType
        )
      }
    >
      <Box className={classes.inputContent}>
        <Box className={classes.inputContentColumn}>
          <Typo variant="body3">{label}</Typo>
          <Typo style={{ fontSize: pxToRem(18) }} variant="h5">
            {displayValue}
          </Typo>
        </Box>
        {getIcon(selected === selectionType)}
      </Box>
    </MaterialButton>
  );

  return (
    <>
      <Drawer
        className={classes.drawer}
        anchor="bottom"
        open={isOpen}
        onClose={onClose}
      >
        <Box className={classes.container} role="presentation">
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'flex-start',
            }}
          >
            <IconButton onClick={onClose} className={classes.close}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Box>
          <Box className={classes.inputsContainer}>
            {!alternate && (
              <Box borderBottom={2} className={classes.input}>
                {selected === SELECTION.LOCATION && <LocationInput />}
                <CollapseMenuButton
                  label="Location"
                  selectionType={SELECTION.LOCATION}
                  displayValue={
                    location.address ? `${location.address}` : 'All'
                  }
                />
              </Box>
            )}
            <Box
              borderBottom={2}
              className={classes.input}
              style={{
                height: selected === SELECTION.DATE ? '100%' : 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {selected === SELECTION.DATE && <DateSelectionInput />}
              <CollapseMenuButton
                label="Dates"
                selectionType={SELECTION.DATE}
                displayValue={
                  endDate &&
                  `${format(
                    parse(startDate, 'MMM dd, yyyy', new Date()),
                    'MMM dd'
                  )} - ${format(
                    parse(endDate, 'MMM dd, yyyy', new Date()),
                    'MMM dd'
                  )}`
                }
              />
            </Box>
            <Box borderBottom={2} className={classes.input}>
              {selected === SELECTION.GUESTS && <GuestInput />}
              <CollapseMenuButton
                label="Guests"
                selectionType={SELECTION.GUESTS}
                displayValue={guests ? `${guests}` : undefined}
              />
            </Box>
            {!alternate && (
              <Box borderBottom={2} className={classes.input}>
                {selected === SELECTION.OFFERS && <OffersInput />}
                <CollapseMenuButton
                  label="Must haves"
                  selectionType={SELECTION.OFFERS}
                  displayValue={
                    mustHaves?.length ? `${mustHaves?.length} Selected` : 'None'
                  }
                />
              </Box>
            )}
          </Box>
          <Box className={classes.button}>
            <GhostButton
              text={alternate ? 'Book Now' : 'Show me the cabins'}
              onClick={onClickGhostButton}
            />
          </Box>
        </Box>
      </Drawer>

      {alternate && (
        <MaterialButton className={classes.doItButton} onClick={onDoIt}>
          Book Now
        </MaterialButton>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnackbar}
        type={'normal'}
        message={snackbarMessage}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
};

export default HomeFilterMobileView;
