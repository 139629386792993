import React, { FC } from 'react';

import { BlogStoryItemPublicProps } from './BlogStoryItem.props';
import View from './BlogStoryItem.view';

const BlogStoryItemContainer: FC<BlogStoryItemPublicProps> = (props) => {
  return <View {...props} onShare={() => {}} onSave={() => {}} />;
};

export default React.memo(BlogStoryItemContainer);
