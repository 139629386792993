import React, { FC } from 'react';

import { useDispatch } from 'react-redux';
import ROUTES from 'routes';
import { authActions, routerActions } from 'store/actions';
import useSelectorSafe from 'store/selectors/useSelectorSafe';

import { INSTAGRAM_URL } from '../../../consts';
import { AppBarFieldsPublicProps } from './AppBarFields.props';
import View from './AppBarFields.view';

const AppBarFieldsContainer: FC<AppBarFieldsPublicProps> = (
  props: AppBarFieldsPublicProps
) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelectorSafe((state) => state.token);

  const mobileItems = [
    {
      header: 'The Remedy Journal',
      links: [
        {
          title: 'Latest',
          onClick: () => dispatch(routerActions.link(ROUTES.JOURNAL)),
        },
        {
          title: 'Field Recordings',
          onClick: () => dispatch(routerActions.link(ROUTES.FIELD_RECORDINGS)),
        },
        {
          title: "Writer's Program",
          onClick: () => dispatch(routerActions.link(ROUTES.WRITERS_PROGRAM)),
        },
        {
          title: 'From The Field',
          onClick: () => dispatch(routerActions.link(ROUTES.FROM_THE_FIELD)),
        },
        {
          title: 'Dispatches',
          onClick: () => dispatch(routerActions.link(ROUTES.DISPATCHES)),
        },
        {
          title: 'Perspective',
          onClick: () => dispatch(routerActions.link(ROUTES.PERSPECTIVE)),
        },
      ],
    },
  ];

  const subItems = [
    {
      title: 'Privacy',
      onClick: () => dispatch(routerActions.link(ROUTES.PRIVACY_POLICY)),
    },
    {
      title: 'T&Cs',
      onClick: () => dispatch(routerActions.link(ROUTES.TERMS_AND_CONDITIONS)),
    },
  ];

  const leftAppBarItems = [
    {
      text: 'The Remedy Journal',
      onClick: () => dispatch(routerActions.link(ROUTES.JOURNAL)),
    },
  ];

  const appBarItems = [
    {
      text: 'Latest',
      onClick: () => dispatch(routerActions.link(ROUTES.JOURNAL)),
    },
    {
      text: 'Field Recordings',
      onClick: () => dispatch(routerActions.link(ROUTES.FIELD_RECORDINGS)),
    },
    {
      text: "Writer's Program",
      onClick: () => dispatch(routerActions.link(ROUTES.WRITERS_PROGRAM)),
    },
    {
      text: 'From The Field',
      onClick: () => dispatch(routerActions.link(ROUTES.FROM_THE_FIELD)),
    },
    {
      text: 'Dispatches',
      onClick: () => dispatch(routerActions.link(ROUTES.DISPATCHES)),
    },
    {
      text: 'Perspective',
      onClick: () => dispatch(routerActions.link(ROUTES.PERSPECTIVE)),
    },
  ];

  return (
    <View
      {...props}
      leftAppBarItems={leftAppBarItems}
      appBarItems={appBarItems}
      mobileItems={mobileItems}
      subItems={subItems}
      goToHome={() => dispatch(routerActions.link(ROUTES.JOURNAL))}
    />
  );
};

export default React.memo(AppBarFieldsContainer);
