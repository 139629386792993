import { pink } from '@material-ui/core/colors';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { colors } from 'themeConfig/themeConstants';

interface StyleProps {
  isMobile: boolean;
}

export const useStyles = makeStyles((theme) => {
  const containerStyles = {
    backgroundColor: colors.primaryDarkGreen,
    paddingLeft: '5%',
    paddingRight: '5%',
    [theme.breakpoints.up(1440)]: {
      paddingLeft: '0',
      paddingRight: '11%',
    },
  };

  return createStyles({
    approachContainer: ({ isMobile }: StyleProps) => ({
      '& > * ': {
        color: colors.primaryYellow,
      },
      width: isMobile ? '100%' : '80%',
      margin: 'auto',
      marginTop: '2rem',
      textAlign: isMobile ? 'center' : 'left',
    }),
    blockContainer: ({ isMobile }: StyleProps) =>
      isMobile
        ? {
            ...containerStyles,
          }
        : {},
    yellowBlock: {
      backgroundColor: `${colors.primaryYellow} !important`,
      color: colors.primaryDarkGreen,
    },
    sliderContainer: {
      minHeight: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '90%',
      margin: 'auto',

      '& .slick-arrow': {
        height: '26px',
        transform: 'translate(0, 20%)',
      },

      '& .slick-next': {
        transform: 'rotate(180deg)',
      },

      '& p': {
        textAlign: 'center',
        marginTop: '1.5rem',
      },
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '70%',
      margin: 'auto',

      '& img': {
        height: 'auto',
        margin: 'auto',
      },
      '& p:after': {
        content: '""',
        width: '0',
        transition: 'width 0.1s ease-in',
        display: 'block',
      },
      '&.active p:after': {
        width: '80%',
        borderBottom: `3px solid ${colors.primaryYellow}`,
        height: '1px',
        marginTop: '0.5em',
        alignItems: 'center',
        textAlign: 'center',
        margin: 'auto',
      },
    },
    benefitImg: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      
    },
    readBtn: ({ isMobile }) => ({
      width: isMobile ? '100%' : 250,
      color: colors.primaryYellow,
      marginTop: '2rem',
    }),
    mobileInverse: ({ isMobile }: StyleProps) =>
      isMobile
        ? {
            flexDirection: 'column-reverse',
          }
        : {},
    textContainer: ({ isMobile }: StyleProps) =>
      isMobile
        ? {
            width: '90%',
            margin: 'auto',
          }
        : {
            width: '70%',
            margin: 'auto',
          },
  });
});
