/* eslint-disable prettier/prettier */
import React, { FC, Fragment, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowUpwardIcon from '@material-ui/icons/ExpandLess';
import ArrowDownwardIcon from '@material-ui/icons/ExpandMore';
import GlobeIcon from 'assets/svgs/generated/GlobeIcon';
import SocialIcons from 'components/modules/SocialIcons';
import { useDispatch } from 'react-redux';
import ROUTES from 'routes';
import { routerActions } from 'store/actions';
import { colors } from 'themeConfig/themeConstants';

import FooterLogo from '../../../assets/svgs/logo.svg';
import { HorizontalLine, ImageContainer, Link, Typo } from '../../primitives';
import { FooterProps } from './Footer.props';
import { useStyles } from './Footer.styles';
import SvgLogoWhite from 'assets/svgs/generated/LogoWhite';

const FooterView: FC<FooterProps> = ({
  moreItemDesktop,
  moreItemMobile,
  items = [],
  subItems = [],
  goToHome,
  expand,
  setCountryModalOpen,
}) => {
  const theme = useTheme();
  // @ts-ignore
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile, expand });
  const dispatch = useDispatch();
  const [openCountries, setOpenCountries] = useState<{
    [key: string]: boolean;
  }>({});

  const footerBanner = (
    <Box className={classes.footerBanner}>
      &copy; 2024 |&nbsp;
      <Link
        noHighlight
        invert
        onClick={() => dispatch(routerActions.link(ROUTES.PRIVACY_POLICY))}
      >
        Privacy&nbsp;
      </Link>
      |&nbsp;
      <Link
        noHighlight
        invert
        onClick={() =>
          dispatch(routerActions.link(ROUTES.TERMS_AND_CONDITIONS))
        }
      >
        T&Cs
      </Link>
    </Box>
  );

  const countrySelectorIcon = (
    <Button
      style={{
        padding: 0,
        minWidth: 0,
        marginLeft: 5,
        margin: '15px 0',
      }}
      disableRipple={false}
      onClick={() => setCountryModalOpen(true)}
    >
      <GlobeIcon
        style={{ width: 30, height: 30 }}
        htmlColor={colors.primaryYellow}
      />
    </Button>
  );

  if (isMobile) {
    const mobileItems = [...items, moreItemMobile];
    const itemsLength = mobileItems.length;

    return (
      <Grid container className={classes.mobileContainer}>
        <Grid item xs={12} className={classes.itemsPadding}>
          {mobileItems.map(({ header, links }, index) => (
            <Fragment key={header}>
              <Typo
                variant="h5"
                color="primary"
                className={classes.mobileHeader}
              >
                {header}
              </Typo>
              <Box pl={index == 0 ? 3 : 0}>
                {links.map(({ code, head, title, onClick }, i) =>
                  head === null && code ? (
                    <Grid
                      style={{ height: '25px', color: colors.primaryYellow }}
                      key={i}
                      container
                    >
                      <Grid item xs={11}>
                        <Link
                          noHighlight
                          invert
                          onClick={() =>
                            setOpenCountries({
                              ...openCountries,
                              [code]: !openCountries[code],
                            })
                          }
                        >
                          <Typo
                            component="span"
                            variant="body2"
                            color="primary"
                          >
                            {title}
                          </Typo>
                        </Link>
                      </Grid>
                      <Grid item xs={1}>
                        <Link
                          noHighlight
                          invert
                          onClick={() =>
                            setOpenCountries({
                              ...openCountries,
                              [code]: !openCountries[code],
                            })
                          }
                        >
                          {openCountries[code] ? (
                            <ArrowUpwardIcon />
                          ) : (
                            <ArrowDownwardIcon />
                          )}
                        </Link>
                      </Grid>
                    </Grid>
                  ) : !head || openCountries[head] ? (
                    <Link key={i} onClick={onClick}  invert noHighlight>
                      <Typo component="span" variant="body2" color="primary">
                        <Box pl={3}>{title}</Box>
                      </Typo>
                    </Link>
                  ) : null
                )}
              </Box>
              {itemsLength === index + 1 ? null : (
                <HorizontalLine className={classes.horizontalLine} />
              )}
            </Fragment>
          ))}
        </Grid>
        <Grid item xs={12}>
          <HorizontalLine className={classes.horizontalLine} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          className={classes.itemsPadding}
        >
          <SvgLogoWhite
            onClick={goToHome}
            style={{
              width: '120',
              height: '40',
              cursor: 'pointer',
              color: colors.primaryYellow,
            }}
          />
          <SocialIcons />
        </Grid>

        <Grid item xs={6} className={classes.itemsPadding}>
          <HorizontalLine className={classes.horizontalLine} />
        </Grid>

        <Grid item xs={12} className={classes.itemsPadding}>
          {countrySelectorIcon}
        </Grid>

        <Grid container className={classes.itemsPadding}>
          <Typo variant="body2" color="primaryYellow">
            &copy; 2024
          </Typo>
          <Divider orientation="vertical" className={classes.verticalLine} />
          {subItems.map(({ title, onClick }, index) => (
            <Box key={index} className={classes.subItem}>
              <Link onClick={onClick}  invert noHighlight>
                <Typo variant="body2" color="primaryYellow">
                  {title}
                </Typo>
              </Link>
              {(index + 1) % 2 === 0 ? null : (
                <Divider
                  orientation="vertical"
                  className={classes.verticalLine}
                />
              )}
            </Box>
          ))}
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <Grid container className={classes.desktopContainer}>
        <Grid
          item
          container
          xs={3}
          style={{ flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <SvgLogoWhite
            onClick={goToHome}
            style={{
              width: '120',
              height: '40',
              cursor: 'pointer',
              color: colors.primaryYellow,
            }}
          />
          <SocialIcons />

          {countrySelectorIcon}
        </Grid>
        <Grid item container xs={9} spacing={5} justifyContent="space-around">
          {[...items, moreItemDesktop].map((item) => (
            <Grid key={item.header} item xs={3}>
              <Typo variant="h5" color="primaryWhite">
                {item.header}
              </Typo>
              <HorizontalLine className={classes.horizontalLine} />
              {item.links.map((link, i) => {
                const { code, head, title, onClick } = link;
                return head === null && code ? (
                  <Grid
                    style={{ height: '25px', color: colors.primaryYellow }}
                    key={i}
                    container
                  >
                    <Grid item xs={10}>
                      <Link
                        noHighlight
                        invert
                        onClick={() =>
                          setOpenCountries({
                            ...openCountries,
                            [code]: !openCountries[code],
                          })
                        }
                      >
                        <Typo component="span" variant="body2" color="primary">
                          {title}
                        </Typo>
                      </Link>
                    </Grid>
                    <Grid item xs={2}>
                      <Link
                        noHighlight
                        invert
                        onClick={() =>
                          setOpenCountries({
                            ...openCountries,
                            [code]: !openCountries[code],
                          })
                        }
                      >
                        {openCountries[code] ? (
                          <ArrowUpwardIcon />
                        ) : (
                          <ArrowDownwardIcon />
                        )}
                      </Link>
                    </Grid>
                  </Grid>
                ) : !head || openCountries[head] ? (
                  <Link invert key={i} onClick={onClick} noHighlight >
                    <Typo component="span" variant="body2" color="primary">
                      <Box pl={3}>{title}</Box>
                    </Typo>
                  </Link>
                ) : null;
              })}
            </Grid>
          ))}
        </Grid>
      </Grid>
      {footerBanner}
    </div>
  );
};

export default React.memo(FooterView);
