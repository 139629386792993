import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { pxToRem } from '../../../themeConfig/typography';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ghostButton: {
      width: 180,
    },
    title: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    },
  })
);
