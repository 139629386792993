export const MOOD_CATEGORIES: { label: string; value: string }[] = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Relaxation',
    value: 'Relaxation',
  },
  {
    label: 'Creativity',
    value: 'Creativity',
  },
  {
    label: 'Romance',
    value: 'Romance',
  },
];

export const PLACE_CATEGORIES: { label: string; value: string }[] = [
  {
    label: 'recharge',
    value: 'relaxed',
  },
  {
    label: 'be creative',
    value: 'productive',
  },
  {
    label: 'connect',
    value: 'romantic',
  },
];
