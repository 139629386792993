import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { auth, Endpoint } from 'services/fetch/apiEndpoints';
import { queryApiRequest } from 'services/fetch/apiFetch';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import AppErr from 'utils/App/AppErr/AppErr';

export const useQueryWrapper = <
  PayloadType extends {},
  ResponseType extends {}
>(
  key: string[],
  endpoint: Endpoint,
  payload: PayloadType,
  options?: UseQueryOptions<PayloadType, any, ResponseType, any>
): UseQueryResult<ResponseType, any> => {
  const country = useSelectorSafe((state) => state.country);

  const token = useSelectorSafe((state) => state.token);

  const dataFetching: QueryFunction<PayloadType, any> = async () => {
    if (endpoint.auth === auth.REQUIRED) {
      if (!token)
        return Promise.reject(new AppErr('Token not set for authedFetch'));
    }
    const extraHeaders: HeadersInit = {
      'X-Country-Code': country?.countryCode || 'AU',
      Authorization: `Bearer ${token}`,
    };

    return queryApiRequest(endpoint, payload, extraHeaders);
  };

  return useQuery(key, dataFetching, options || {});
};
