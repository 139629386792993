import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface StyleProps {
  lineColor?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    default: ({ lineColor }: StyleProps) => ({
      backgroundColor: lineColor || 'rgb(245, 245, 245)',
    }),
  })
);
