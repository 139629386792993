import { Dispatch } from 'redux';
import {
  getProfileEndpoint,
  signupEndpoint,
} from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { authActions, userActions, tokenActions } from 'store/actions';

import { Store } from '../../types/store/Store';
import { User } from '../../types/User';

interface AccountCredentials {
  email: string;
  password: string;
  subscribe?: boolean;
}

interface Data {}

interface Signalling {
  finishUpdating?: (data: any) => void;
  updateErrorMessage?: (message: any) => void;
}

const createAccountThunk = async (
  credentials: AccountCredentials,
  dispatch: Dispatch,
  getState: () => Store,
  signalling?: Signalling
) => {
  // @ts-ignore

  try {
    const { signUp }: any = await apiFetch<AccountCredentials, User>(
      signupEndpoint,
      credentials
    )(dispatch, getState).then((response) => {
      return { signUp: response };
    });

    console.log(signUp);
    dispatch(tokenActions.updated(signUp.token));

    // Simulated sleep
    await new Promise((r) => setTimeout(r, 1000));

    dispatch(
      userActions.getProfile(
        await apiFetch<Data, any>(getProfileEndpoint, {})(
          dispatch,
          getState
        ).then((p) => {
          console.log(p);
          window.analytics.track('User Signed Up', {
            email: p.user.email,
            signup_date: new Date().toISOString(),
            source: 'Unyoked',
            newsletter_subscribed: credentials.subscribe,

            // to be remove
            databaseID: null,
            openbook_id: null,
          });

          window.analytics.identify(p.user.openbookId, {
            email: p.user.email,
            newsletter_subscribed: credentials.subscribe,
            source: 'Unyoked',

            // to be remove
            databaseID: null,
            openbook_id: null,
          });

          return p;
        })
      )
    );

    if (signalling && signalling.finishUpdating)
      signalling.finishUpdating(signUp);
    dispatch(authActions.signup(Promise.resolve(signUp)));
  } catch (error) {
    if (signalling && signalling.updateErrorMessage)
      signalling.updateErrorMessage(error);
    dispatch(authActions.signup(Promise.reject(error)));
  }
};

const createAccount =
  (credentials: AccountCredentials, signalling?: Signalling) =>
  (dispatch: Dispatch, getState: () => Store) => {
    createAccountThunk(credentials, dispatch, getState, signalling);
  };

export default createAccount;
