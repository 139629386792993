import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { HorizontalLine, Typo } from '../../primitives';
import { CheckoutConfirmedProps } from './CheckoutConfirmed.props';
import { useStyles } from './CheckoutConfirmed.styles';

const CheckoutConfirmedView: FC<CheckoutConfirmedProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <Typo variant={isMobile ? 'h3' : 'h1'} className={classes.title}>
        {props.error ? `Oops...` : `Huzzah`}
      </Typo>
      <Box mt={4} mb={4}>
        <HorizontalLine className={classes.line} />
      </Box>
      <Typo variant="body1">{props.error || props.message}</Typo>
    </Box>
  );
};

export default React.memo(CheckoutConfirmedView);
