import React, { FC } from 'react';

import { AboutVideoBlockPublicProps } from './AboutVideoBlock.props';
import View from './AboutVideoBlock.view';

const AboutVideoBlockContainer: FC<AboutVideoBlockPublicProps> = (props) => {
  return <View {...props} />;
};

export default React.memo(AboutVideoBlockContainer);
