import React, { FC } from 'react';

import { BlogItemContentPublicProps } from './BlogItemContent.props';
import View from './BlogItemContent.view';

const BlogItemContentContainer: FC<BlogItemContentPublicProps> = (props) => (
  <View {...props} />
);

export default React.memo(BlogItemContentContainer);
