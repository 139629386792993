import * as React from 'react';

function SvgCheckboxXmark(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={41}
      height={41}
      viewBox="0 0 41 41"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle stroke="#041807" strokeWidth={2} cx={20.5} cy={20.5} r={18.5} />
        <path
          fillRule="nonzero"
          fill="#041807"
          d="M13.668 9.848c-.305.504.098 1.507.902 2.21.805.704 2.012 3.016 2.614 5.024 1.105 3.719 1.003 4.02-2.512 8.14-3.82 4.524-4.422 5.93-2.313 5.93.606-.101 2.512-2.011 4.121-4.32l2.915-4.223 1.105 4.02c1.305 4.625 4.121 5.73 7.84 3.117 1.203-.906 1.808-1.71 1.305-1.71-.602-.099-1.707-.2-2.512-.302-.805 0-2.11-2.007-2.914-4.218-1.407-4.121-1.407-4.223 2.21-8.645 3.317-4.02 3.817-5.828 1.708-5.828-.403 0-2.008 1.91-3.516 4.223l-2.816 4.32-1.106-4.02c-.902-3.214-1.707-4.12-3.816-4.32-1.406-.203-2.914.098-3.215.602zm0 0"
        />
      </g>
    </svg>
  );
}

export default SvgCheckboxXmark;
