import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'themeConfig/themeConstants';

interface Props {
  closeIconLeft: boolean;
  isMobile: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'rgba(22,45,27,1)',
      zIndex: `10000 !important`,
    },
    title: {
      padding: '0px',
      [theme.breakpoints.down('sm')]: {
        margin: '20px 0px 0px',
        padding: '0px 30px',
      },
    },
    paper: {
      boxShadow: 'none',
      background: 'transparent',
    },
    content: ({ isMobile }: Props) => ({
      flex: 'none',
      ...(!isMobile && { padding: '10px 10px' }),
      ...(isMobile && { margin: '20px 20px' }),
      backgroundColor: colors.primaryYellow,
    }),
    rowItem: {
      margin: '25px',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        margin: '25px 0px',
        maxWidth: 'none',
      },
    },
    popper: ({ isMobile }) => ({
      zIndex: 11000,
      pointerEvents: 'unset',

      '& div': {
        fontSize: 14,
        fontFamily: 'Pitch',
        background: colors.primaryDarkGreen,
        padding: 10,
        maxWidth: isMobile ? '220px' : '',
        borderRadius: 0,
      },

      '& span': {
        color: colors.primaryDarkGreen,
      }
    }),
    tooltip: {
      position: 'absolute',
      color: 'white',
      maxWidth: '300px',
      minHeight: '100px',
      padding: '20px',
      right: '10px',
      top: '150px',
      background: '#06270B',
      fontSize: '15px',
    },
    tooltipRoot: {
      backgroundColor: 'rgba(22,22,22,0.8)',
      zIndex: `11000 !important`,
    },
    tooltipPaper: {
      color: '#fff',
      fontSize: '15px',
      maxWidth: '450px',
      background: '#06270B',
      padding: '10px 10px 35px',
    },
    tooltipTitle: {
      padding: '0',
      minHeight: '40px',
    },
    tooltipContent: {
      padding: '5px 15px 20px',
    },
    tooltipCloseIcon: ({ closeIconLeft }: Props) => ({
      width: '24px',
      height: '24px',
      position: 'absolute',
      top: '13px',
      cursor: 'pointer',
      ...(!closeIconLeft && { right: '13px' }),
      ...(closeIconLeft && { left: '13px' }),
      color: '#fff',
    }),
    offerIcon:({ isMobile }: Props) => ({
      position: 'absolute',
      right: isMobile ? '50px' : '100px',
      width: '40px'
    })
  })
);
