import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors, fontFamilies } from '../../../themeConfig/themeConstants';
import { pxToRem } from '../../../themeConfig/typography';

const { plain } = fontFamilies;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: plain,
      fontWeight: 500,
      fontSize: pxToRem(12),
      '& .rc-pagination-item': {
        borderColor: 'transparent',
        backgroundColor: 'transparent',
      },
      '& .rc-pagination-item-active': {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      '& .rc-pagination-item a': {
        color: colors.secondaryDarkGreen,
        opacity: 0.5,
      },
      '& .rc-pagination-item-active a': {
        color: colors.secondaryDarkGreen,
        opacity: 1,
      },
      '& .rc-pagination-prev, .rc-pagination-next': {
        color: colors.secondaryDarkGreen,
        borderColor: 'transparent',
      },
      '& .rc-pagination-disabled a': {
        color: colors.secondaryDarkGreen,
        opacity: 0.5,
      },
      '& .rc-pagination-jump-prev:after, .rc-pagination-jump-next:after': {
        color: colors.secondaryDarkGreen,
        opacity: 0.5,
      },
    },
  })
);
