import React, { FC } from 'react';

import { INSTAGRAM_URL } from '../../../consts';
import { SocialBlockPublicProps } from './SocialBlock.props';
import View from './SocialBlock.view';

const SocialBlockContainer: FC<SocialBlockPublicProps> = (props) => {
  return (
    <View
      {...props}
      onFollow={() =>
        window.open(
          props.ctaLink || INSTAGRAM_URL,
          '_blank',
          'noopener noreferrer'
        )
      }
    />
  );
};

export default React.memo(SocialBlockContainer);
