import React, { FC, useEffect, useMemo, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { parse } from 'date-fns';
import { useProductTracking } from 'hooks/useClickTrack';
import pathOr from 'ramda/es/pathOr';
import isEmpty from 'ramda/src/isEmpty';
import { animated, useTrail, useTransition } from 'react-spring';
import { isMinimumStay } from 'utils/Data/bookedDates/bookedDates';

import { HomeFilter, HouseCard } from '../../modules';
import { Checkbox, HorizontalLine, TextButton, Typo } from '../../primitives';
import ContentfulRenderer from '../ContentfulRenderer';
import { HouseResultsProps } from './HouseResults.props';
import { useStyles } from './HouseResults.styles';

const HouseResultsView: FC<HouseResultsProps> = (props) => {
  const {
    content,
    nextAvailable,
    filter,
    contentTitle,
    loading,
    noVacancyMsg,
  } = props;
  const theme = useTheme();
  const { setProductCounter } = useProductTracking();
  const belowTablet = useMediaQuery('(max-width: 767px)');
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles();
  const [anyMustHave, setAnyMustHave] = useState(false);
  const [viewCount, setViewCount] = useState(6);
  useEffect(() => {
    setViewCount(belowTablet ? 3 : 6);
  }, [belowTablet]);

  const filteredContent = useMemo(
    () =>
      [...content].filter((card) => {
        if (!anyMustHave) {
          if (filter && filter.offers && filter.offers.length) {
            for (const mustHave of filter.offers) {
              if (!card.offers || card.offers?.indexOf(mustHave) < 0) {
                return false;
              }
            }
          }
        }

        if (filter && filter.startDate && filter.endDate) {
          const start = parse(filter.startDate, 'MMM dd, yyyy', new Date());
          const end = parse(filter.endDate, 'MMM dd, yyyy', new Date());

          if (!isMinimumStay(start, end, card.twoNightMinimum)) {
            return false;
          }
        }

        return true;
      }),
    [content, filter.offers, filter.startDate, filter.endDate]
  );

  const newContent = useMemo(() => {
    return [...filteredContent].splice(0, viewCount);
  }, [
    viewCount,
    filteredContent,
    filter.offers,
    filter.startDate,
    filter.endDate,
  ]);
  const isLoadMoreVisible = useMemo(() => {
    return filteredContent.length > newContent.length;
  }, [viewCount, filteredContent.length]);

  const onLoadMore = () => {
    const newViewCount = viewCount + (belowTablet ? 3 : 6);
    setProductCounter(newViewCount);
    setViewCount(newViewCount);
  };
  const defaultMessage = `
    Smiley-face-with-tear, sorry friend, unfortunately we
    couldn't find any hideouts free on those dates. Can you move
    things around and come another time? Do tell your boss it's
    beneficial for your performance, they'll understand.
    We'll write them a note if you want.
  `;

  if (belowTablet) {
    return (
      <Box className={classes.mobileContainer}>
        {!loading && isEmpty(filteredContent) && (
          <Box mb={6}>
            {noVacancyMsg ? (
              <ContentfulRenderer forceVariant="body0" content={noVacancyMsg} />
            ) : (
              <Typo variant="body0">{defaultMessage}</Typo>
            )}
          </Box>
        )}

        {!loading && !isEmpty(filteredContent) && filteredContent.length > 0 && (
          <>
            <Grid container>
              <Grid item xs={6}>
                <Typo variant={isXs ? 'h4' : 'h3'} className={classes.title}>
                  {contentTitle}
                </Typo>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
            <Box mb={1} />
            {newContent.map((card, index) => {
              return (
                <>
                  <Box key={card.id} mb={6} className={classes.animate}>
                    {index % 3 == 0 ? (
                      <Typo variant={'h6'}>
                        {`${index + 1}-${
                          !isLoadMoreVisible &&
                          index > filteredContent.length - 3
                            ? filteredContent.length
                            : index + 3
                        } of ${content.length} nature locations.`}
                      </Typo>
                    ) : null}
                    <HouseCard {...card} filter={filter} position={index + 1} />
                    {(index + 1) % 3 !== 0 ? (
                      <HorizontalLine className={classes.line} />
                    ) : null}
                  </Box>
                </>
              );
            })}

            {isLoadMoreVisible && (
              <TextButton
                style={classes.loadMore}
                text="Load More"
                onClick={onLoadMore}
              />
            )}
          </>
        )}
      </Box>
    );
  }

  return (
    <>
      <Grid container>
        {!loading && isEmpty(filteredContent) && (
          <Box mb={6} mt={15}>
            {noVacancyMsg ? (
              <ContentfulRenderer forceVariant="body0" content={noVacancyMsg} />
            ) : (
              <Typo variant="body0">{defaultMessage}</Typo>
            )}
          </Box>
        )}
        {!loading && !isEmpty(filteredContent) && filteredContent.length > 0 && (
          <>
            <Grid item xs={10}>
              <Box mb={9} />
              {/* <Typo variant={'h3'} className={classes.desktopTitle}>
                {contentTitle ?? 'Your Cabins'}
              </Typo> */}
            </Grid>
            <Grid item xs={2}></Grid>

            {newContent.map((card, index) => {
              return (
                <>
                  {index % 6 == 0 ? (
                    <Grid
                      key={`t${card.id}`}
                      item
                      sm={12}
                      className={classes.desktopTitle}
                    >
                      <Typo variant={'h6'}>
                        {`${index + 1}-${
                          !isLoadMoreVisible &&
                          index > filteredContent.length - 6
                            ? filteredContent.length
                            : index + 6
                        }
                        of ${filteredContent.length} nature locations.`}
                      </Typo>
                    </Grid>
                  ) : null}
                  <Grid
                    key={index}
                    item
                    sm={newContent[index]?.isHero ? 12 : 4}
                    className={classes.card}
                  >
                    <HouseCard {...card} filter={filter} position={index + 1} />
                  </Grid>
                </>
              );
            })}
          </>
        )}
      </Grid>
      {isLoadMoreVisible && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <TextButton
            style={classes.loadMore}
            text="Load More"
            onClick={onLoadMore}
          />
        </Box>
      )}
    </>
  );
};

export default React.memo(HouseResultsView);
