import React, { FC } from 'react';

import { GalleryPortraitPublicProps } from './GalleryPortrait.props';
import GalleryPortraitView from './GalleryPortrait.view';

const GalleryPortraitContainer: FC<GalleryPortraitPublicProps> = (props) => {
  return <GalleryPortraitView {...props} />;
};

export default React.memo(GalleryPortraitContainer);
