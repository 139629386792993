import { Dispatch } from 'redux';
import { getProfileEndpoint, loginEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { authActions, userActions } from 'store/actions';

import { Profile } from '../../types/Profile';
import { Store } from '../../types/store/Store';
import { User } from '../../types/User';
import { resetLeaveListener } from 'exit-intent';

interface Credentials {
  email: string;
  password: string;
  remember: boolean;
}

interface Data {}

const login =
  (credentials: Credentials) => (dispatch: Dispatch, getState: () => Store) => {
    dispatch(
      authActions.login(
        apiFetch<Credentials, User>(loginEndpoint, credentials)(
          dispatch,
          getState
        ).then((u) => {
          dispatch(
            userActions.getProfile(
              apiFetch<Data, Profile>(getProfileEndpoint, {})(
                dispatch,
                getState
              ).then((r) => {
                const openbook_id = r.user.openbookId || '';
                const unyoked_id = u.user.id || ''
                  resetLeaveListener();
                window.analytics.identify(openbook_id, {
                  email: u.user.email,
                  newsletter_subscribed: !!u.user.newsletterSubscribed,
                  last_login_date: new Date().toISOString(),

                  // to be remove
                  openbook_id: null,
                  databaseID: null,
                });
                window.analytics.track('User Logged In', {
                  email: u.user.email,
                  source: 'Unyoked',
                  last_login_date: new Date().toISOString(),

                  // to be remove
                  openbook_id: null,
                  databaseID: null,
                });

                return r;
              })
            )
          );

          u.user.source = 'Unyoked';
          return u;
        })
      )
    );
  };

export default login;
