import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    card: {
      marginBottom: '8%',
      paddingLeft: '2%',
      paddingRight: '2%',
    },
    title: {
      paddingLeft: '2%',
      paddingRight: '8px',
    },
    subheader: {
      paddingLeft: '1%',
      marginTop: '14px',
      paddingRight: '8px',
    },
    dropdownContainer: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: 600,
    },
    dropdownContainerMobile: {
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'center',

      [theme.breakpoints.down(390)]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  })
);
