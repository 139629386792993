import React, { FC } from 'react';

import { FineprintPublicProps } from './Fineprint.props';
import View from './Fineprint.view';

const FineprintContainer: FC<FineprintPublicProps> = (props) => {
  return <View {...props} />;
};

export default React.memo(FineprintContainer);
