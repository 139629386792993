import React from 'react'
export const HomeBackgroundImage = React.lazy(() => import( './home.jpg'));
export const NewsletterImage = React.lazy(() => import( './newsletter.jpg'));
export const HouseCardImage = React.lazy(() => import( './house-card.jpg'));
export const BlogItemImage = React.lazy(() => import( './blog-pic.jpg'));
export const BlogBackgroundImage = React.lazy(() => import( './to-blog.jpg'));
export const SocialImage = React.lazy(() => import( './social-image.jpg'));
export const WishlistImage = React.lazy(() => import( './wishlist-image.jpg'));
export const VibeChillImage = React.lazy(() => import( './vibe-chill.jpg'));
export const VibeExploreImage = React.lazy(() => import( './vibe-explore.jpg'));
export const VibeFocusImage = React.lazy(() => import( './vibe-focus.jpg'));
export const VibeWellnessImage = React.lazy(() => import( './vibe-wellness.jpg'));
export const PackingListImage = React.lazy(() => import( './packing-list.jpg'));
export const WhatsProvidedImage = React.lazy(() => import( './whats-provided.jpg'));
export const HouseDetailsImage1 = React.lazy(() => import( './house-details-image1.jpg'));
export const HouseDetailsImage2 = React.lazy(() => import( './house-details-image2.jpg'));
export const LocationImage = React.lazy(() => import( './location.jpg'));
export const PrescriptionImage = React.lazy(() => import( './prescription.jpg'));
export const ProvisionsImage = React.lazy(() => import( './provisions.jpg'));
export const BlogHeaderImage = React.lazy(() => import( './blog-header.jpg'));
export const RemedyKitImage = React.lazy(() => import( './remedy-kit.jpg'));
