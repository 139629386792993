import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { pxToRem } from '../../../themeConfig/typography';

interface StyleProps {
  color?: string;
  backgroundColor?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: ({ color }: StyleProps) => ({
      color: color || 'white',
      fontSize: pxToRem(12),
    }),
    input: ({ color }: StyleProps) => ({
      color: color || 'white',
      fontSize: pxToRem(18),
    }),
    inputField: ({ backgroundColor }: StyleProps) => ({
      backgroundColor: backgroundColor || 'black',
      width: '100%',
    }),
    inputRoot: {
      '&::placeholder': {
        opacity: 1,
      },
    },
  })
);
