import { CURRENCY } from 'consts/locations';
import { zonedTimeToUtc } from 'date-fns-tz';
import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import parse from 'date-fns/parse';
import moment from 'moment-timezone';
import isEmpty from 'ramda/src/isEmpty';
import pathOr from 'ramda/src/pathOr';

import { Entity } from '../../../types/Entity';

export const getAvailabilityRange = (
  availableDates: string[],
  notAvailableDates: string[]
) => {
  const formatType =
    !isEmpty(availableDates) && availableDates[0].includes(' ')
      ? 'yyyy-MM-dd HH:mm'
      : 'yyyy-MM-dd';

  const formatTypeNA =
    !isEmpty(notAvailableDates) && notAvailableDates[0].includes(' ')
      ? 'yyyy-MM-dd HH:mm'
      : 'yyyy-MM-dd';

  const dateNow = moment().tz('Australia/Sydney');
  const dateTomorrow = dateNow.add(1, 'days').format('YYYY/MM/DD');
  const dateAfterTomorrow = dateNow.add(2, 'days').format('YYYY/MM/DD');
  const check = Number(dateNow.format('H')) < 18;
  const date = check
    ? `${dateTomorrow} 15:00:00`
    : `${dateAfterTomorrow} 15:00:00`;

  const coronaDate = zonedTimeToUtc(new Date(2020, 4, 31), 'Australia/Sydney');
  const dateAfter = addDays(coronaDate, 1);
  const futureDate = zonedTimeToUtc(new Date(), 'Australia/Sydney');
  const dateAfterYear = addYears(futureDate, 1);

  const startAvailability = !isEmpty(availableDates)
    ? availableDates.find(
        (d) => parse(d, formatType, dateAfter) > coronaDate
      ) || format(dateAfter, formatType)
    : format(dateAfterYear, formatType);

  let parsedStart = parse(startAvailability, formatType, dateAfter);
  if (parsedStart <= new Date(date)) parsedStart = new Date(date);

  const untilAvailability = !isEmpty(availableDates)
    ? notAvailableDates.find(
        (d) => parse(d, formatTypeNA, new Date()) > parsedStart
      ) || format(new Date(), formatTypeNA)
    : format(dateAfterYear, formatType);
  const parsedUntil = parse(untilAvailability, formatTypeNA, new Date());

  return {
    start: parsedStart,
    until: parsedUntil,
  };
};

export const formatAvailability = (
  start: Date,
  until: Date,
  datesOnly?: boolean
) => {
  try {
    if (isSameDay(start, until)) throw new Error();

    const diff = differenceInWeeks(new Date(), start);

    const dayStart = format(start, 'iii');
    const niceStart = format(start, 'iii MMM dd, yyyy');

    const dayUntil = format(until, 'iii');
    const niceUntil = format(until, 'iii MMM dd, yyyy');

    if (diff === 0) {
      return !datesOnly
        ? `Available this ${dayStart} - ${dayUntil}`
        : `${dayStart} - ${dayUntil}`;
    }
    if (diff === 1) {
      return !datesOnly
        ? `Available next ${dayStart} - ${dayUntil}`
        : `${dayStart} - ${dayUntil}`;
    }

    return !datesOnly
      ? `Available: ${niceStart} - ${niceUntil}`
      : `${niceStart} - ${niceUntil}`;
  } catch {
    return !datesOnly ? `Available soon` : `Soon`;
  }
};

export const getCardData = (
  entity: Entity,
  index: number,
  hideAvailability?: boolean
) => {
  let formattedAvailability = '';
  let nextAvailableStart = null;
  let nextAvailableUntil = null;

  const currency: Dinero.Currency = entity?.metadata?.country
    ? CURRENCY[entity.metadata.country]
    : 'AUD';
  if (!hideAvailability) {
    const availability = pathOr([], ['schedule', '0', 'availability'], entity);
    const notAvailable = pathOr([], ['schedule', '0', 'notAvailable'], entity);

    const { start, until } = getAvailabilityRange(availability, notAvailable);
    formattedAvailability = formatAvailability(start, until);
    nextAvailableStart = start;
    nextAvailableUntil = until;
  }

  let locations = [
    `Location: ${pathOr('', ['metadata', 'direction'], entity)}`,
  ];
  const landOwners = pathOr(null, ['metadata', 'landOwners'], entity);
  if (landOwners) {
    locations = [landOwners, pathOr('', ['metadata', 'direction'], entity)];
  }

  let dogFriendly = pathOr(
    false,
    ['metadata', 'houseRules', 'Pet Friendly'],
    entity
  );
  if (!dogFriendly) {
    const specs = pathOr<{ name: string }[]>([], ['specifications'], entity);
    if (specs && specs.length)
      dogFriendly = specs.find((s) => s.name == 'Dog Friendly') != undefined;
    if (!dogFriendly) {
      const offers = pathOr<{ name: string }[]>([], ['offers'], entity);
      if (offers && offers.length)
        dogFriendly = offers.find((s) => s.name == 'Dog Friendly') != undefined;
    }
  }

  let beds = [`Beds: ${pathOr('', ['metadata', 'beds'], entity)}`];
  let spiceLevel = pathOr<string | null>(
    null,
    ['metadata', 'spiceLevel'],
    entity
  );
  if (spiceLevel) {
    if (spiceLevel == 'others') {
      spiceLevel = pathOr('', ['metadata', 'spiceLevelOthers'], entity);
    } else {
      spiceLevel = spiceLevel.replace(/[A-Z]/, ' $&').trim();
      spiceLevel =
        (spiceLevel as string).charAt(0).toUpperCase() +
        (spiceLevel as string).substr(1);
    }

    beds = [`Adventure Level: ${spiceLevel}`];
  }

  let hike: string[] = [];
  const walkFromCar = pathOr(null, ['metadata', 'walkFromCar'], entity);
  if (walkFromCar) {
    hike = [
      `Walk From Car: ${pathOr('', ['metadata', 'walkFromCar'], entity)}`,
    ];
  }

  return {
    id: pathOr('', ['id'], entity),
    availability: formattedAvailability,
    image: pathOr('', ['metadata', 'preview'], entity),
    name: pathOr('', ['title'], entity),
    active: pathOr('', ['active'], entity),
    location: pathOr('', ['metadata', 'location'], entity),
    locationImage: pathOr('', ['metadata','locationImage'], entity),
    offers: pathOr<{ id: string }[]>([], ['offers'], entity).map((o) => o.id),
    twoNightMinimum: pathOr<boolean[]>(
      [],
      ['metadata', 'twoNightMinimum'],
      entity
    ),
    metadata: [
      ...locations,
      `Sleeps: ${pathOr('', ['capacity'], entity)} people. ${
        dogFriendly ? 'Doggo friendly.' : 'No doggos.'
      }`,
      ...beds,
      ...hike,
    ],
    smallIcon: pathOr('', ['metadata', 'smallIcon'], entity),
    // isHero: index > 0 && index % 4 === 0 && hideAvailability,
    hideAvailability,
    carouselImages: pathOr([], ['metadata', 'images'], entity),
    nextAvailableStart,
    nextAvailableUntil,
    pricing: entity?.metadata?.pricing,
    country: entity?.metadata?.country || 'AU',
    currency,
    acceptSingleNight: pathOr(
      'false',
      ['metadata', 'acceptSingleNight'],
      entity
    ),
  };
};

export const getNextAvailable = (
  availabilitiesRange: { id: string; start: Date; until: Date }[],
  entities: Entity[]
) => {
  // @ts-ignore
  const sorted = availabilitiesRange.sort((a, b) => a.start - b.start);
  const topThree = sorted.map((s) => s.id).slice(0, 3);
  const topThreeEntities = entities.filter((e) => topThree.includes(e.id));

  return topThreeEntities.map((e, index) => getCardData(e, index));
};

// old handpicked calculation
export const getHandPicked = (entities: Entity[]) => {
  const cardEntities = entities.map((entity: Entity, index) =>
    getCardData(entity, index)
  );

  const sydneyRecommendation: string = (
    entities.find((r) => r.metadata.location.toUpperCase().includes('NSW')) || {
      id: '',
    }
  ).id;
  const melbourneRecommendation: string = (
    entities.find((r) => r.metadata.location.toUpperCase().includes('VIC')) || {
      id: '',
    }
  ).id;
  const byronRecommendation: string = (
    entities.find((r) =>
      r.metadata.location.toUpperCase().includes('BYRON')
    ) || {
      id: '',
    }
  ).id;
  const canberraRecommendation: string = (
    entities.find((r) => r.metadata.location.toUpperCase().includes('ACT')) || {
      id: '',
    }
  ).id;

  const specialRecommendation: string = (
    entities.find((r) => r.metadata.isRecommended) || { id: '' }
  ).id;

  const RECOMMENDATIONS = [
    sydneyRecommendation,
    melbourneRecommendation,
    byronRecommendation,
    canberraRecommendation,
    specialRecommendation,
  ];
  const recommendations = cardEntities.filter((r) =>
    RECOMMENDATIONS.includes(r.id)
  );
  const target = 3 - recommendations.length;
  const fallbackRecommendations =
    target > 0
      ? cardEntities
          .filter((r) => !RECOMMENDATIONS.includes(r.id))
          .slice(0, target)
      : [];

  return [...recommendations, ...fallbackRecommendations];
};
