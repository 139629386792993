import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import PlaceholderImg from 'assets/images/home.jpg';
import { useSpring, animated } from 'react-spring';

import { Typo, GhostButton, ImageContainer } from '../../primitives';
import { ImageTextProps } from './ImageText.props';
import { useStyles } from './ImageText.styles';
import { FEATURE_FLAGS } from 'consts/featureFlags';

const ImageTextView: FC<ImageTextProps> = (props: ImageTextProps) => {
  const {
    title,
    description,
    shouldTitleBreak,
    breakTitle = () => title,
    background,
    titleStyle,
    customHeight,
    showBookNow = false,
    setShowMobileFilter,
    animateTitle,
    fadeInOnly,
    imgPosition,
    marginTop,
    alt = 'Image text',
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const hasBanner = props.featureFlags[FEATURE_FLAGS.MAINTENANCE];
  const classes = useStyles({ isMobile, customHeight, imgPosition, marginTop, hasBanner });

  const trigger = useScrollTrigger({
    disableHysteresis: true,
  });

  const spring = useSpring({
    to: fadeInOnly ? { opacity: 1 } : { opacity: trigger ? 0 : 1 },
    from: { opacity: 0 },
    delay: trigger ? 0 : 1000,
    immediate: !animateTitle,
  });

  const AnimatedTypo = animated(Typo);

  return (
    <Box className={classes.container}>
      <Box className={classes.imageContainer}>
        {background ? (
          <ImageContainer
            alt={alt}
            className={classes.image}
            src={background}
          />
        ) : (
          <ImageContainer
            alt={alt}
            className={classes.imageBlurred}
            src={PlaceholderImg}
          />
        )}
      </Box>
      <Box className={classes.titleContainer}>
        <AnimatedTypo
          variant="h1"
          color="primary"
          className={`${classes.title} ${titleStyle}`}
          style={spring}
        >
          {shouldTitleBreak ? breakTitle(title) : title}
        </AnimatedTypo>
        {description && (
          <AnimatedTypo
            variant="h5"
            color="primary"
            className={`${titleStyle}`}
            style={spring}
          >
            {description}
          </AnimatedTypo>
        )}

        {isMobile && showBookNow && (
          <Box style={{ width: '80%', marginTop: '20%' }}>
            <GhostButton
              text="Escape"
              color="primaryYellow"
              onClick={(event) => {
                if (setShowMobileFilter) {
                  setShowMobileFilter(true);
                }
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ImageTextView;
