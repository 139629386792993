import { Stripe, loadStripe } from '@stripe/stripe-js';

const stripeCache: { [key: string]: Promise<Stripe | null> } = {}

export function getStripe(country: string) {

  if (['GB', 'UK'].indexOf(country) > -1) {
    if (!stripeCache.UK) stripeCache.UK = loadStripe(import.meta.env.VITE_UK_STRIPE_API_KEY);
    return stripeCache.UK;
  }

  if (['NZ'].indexOf(country) > -1) {
    if (!stripeCache.NZ) stripeCache.NZ = loadStripe(import.meta.env.VITE_NZ_STRIPE_API_KEY);
    return stripeCache.NZ;
  }

  if (!stripeCache.default) stripeCache.default = loadStripe(import.meta.env.VITE_STRIPE_API_KEY);
  return stripeCache.default;

}

