import { Dinero } from 'dinero.js';

export function isWeeknightSingle({
  weeknightSinglePricing,
  weekendPricing,
  totalStay,
}: {
  weeknightSinglePricing: Dinero;
  weekendPricing: Dinero;
  totalStay: number;
}): boolean {
  return (
    !weeknightSinglePricing.isZero() &&
    weekendPricing.isZero() &&
    totalStay === 1
  );
}
