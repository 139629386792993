import * as React from 'react';

function SvgCheckboxChecked(props) {
  return (
    <svg width={41} height={41} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle stroke="#041807" strokeWidth={2} cx={20.5} cy={20.5} r={18.5} />
        <path
          d="M40.986 2C30.978 9.472 20.41 18.097 12.183 28.462c-1.753 2.373-8.197-3.738-7.046-6.105 2.058-1.878 4.139 3.91 6.784 1.251C21.645 16.434 29.818 6.42 41 2.016A.336.336 0 0040.986 2"
          fill="#041807"
        />
      </g>
    </svg>
  );
}

export default SvgCheckboxChecked;
