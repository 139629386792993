import React, { FC } from 'react';

import { useDispatch } from 'react-redux';
import ROUTES from 'routes';
import { routerActions } from 'store/actions';

import { BlogFeaturedBannerPublicProps } from './BlogFeaturedBanner.props';
import BlogFeaturedBannerView from './BlogFeaturedBanner.view';

const BlogFeaturedBannerContainer: FC<BlogFeaturedBannerPublicProps> = (
  props: BlogFeaturedBannerPublicProps
) => {
  const dispatch = useDispatch();
  return (
    <BlogFeaturedBannerView
      {...props}
      onMore={(id: string) =>
        dispatch(
          routerActions.link(ROUTES.BLOG_ITEM, {
            id,
          })
        )
      }
      goToURL={(url: string) =>
        window.open(url, '_blank', 'noopener noreferrer')
      }
    />
  );
};

export default BlogFeaturedBannerContainer;
