import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { BlogCard } from '../../modules';
import { BlogLandingProps } from './BlogLanding.props';
import { useStyles } from './BlogLanding.styles';

const BlogLandingView: FC<BlogLandingProps> = (props) => {
  // const [currentPage, setCurrentPage] = useState(1);

  const { content } = props;
  // @ts-ignore
  const belowTablet = useMediaQuery('(max-width: 767px)');
  const classes = useStyles();

  // const onChange = (current: number) => setCurrentPage(current);
  //
  // const pageSize = belowTablet ? 5 : 9;
  // const start = currentPage === 1 ? 0 : (currentPage - 1) * pageSize;
  // const end = start + pageSize;
  // const currentContent = content.slice(start, end);

  if (belowTablet) {
    return (
      <Box className={classes.mobileContainer}>
        {content.map((item) => (
          <Box key={item.title} mb={6}>
            <BlogCard {...item} />
          </Box>
        ))}
        {/* <Pagination */}
        {/*  total={content.length} */}
        {/*  current={currentPage} */}
        {/*  pageSize={pageSize} */}
        {/*  onChange={onChange} */}
        {/*  showLessItems */}
        {/* /> */}
      </Box>
    );
  }

  return (
    <Grid container>
      {content.map((item) => {
        return (
          <Grid
            key={item.title}
            item
            sm={item.isHero ? 12 : 4}
            className={classes.card}
          >
            <BlogCard {...item} />
          </Grid>
        );
      })}

      {/* <Grid item sm={12} className={classes.paginationContainer}> */}
      {/*  <Pagination */}
      {/*    total={content.length} */}
      {/*    current={currentPage} */}
      {/*    pageSize={pageSize} */}
      {/*    onChange={onChange} */}
      {/*  /> */}
      {/* </Grid> */}
    </Grid>
  );
};

export default React.memo(BlogLandingView);
