import React, { FC } from 'react';

import { ImageAndHeaderCarouselPublicProps } from './ImageAndHeaderCarousel.props';
import View from './ImageAndHeaderCarousel.view';

const ImageAndHeaderCarouselContainer: FC<ImageAndHeaderCarouselPublicProps> = (
  props
) => {
  return <View {...props} />;
};

export default React.memo(ImageAndHeaderCarouselContainer);
