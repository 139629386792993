import React, { FC } from 'react';

import { VideoPlayerPublicProps } from './VideoPlayer.props';
import View from './VideoPlayer.view';

const VideoPlayerContainer: FC<VideoPlayerPublicProps> = (props) => (
  <View {...props} />
);

export default React.memo(VideoPlayerContainer);
