import React, { FC, useState, useEffect } from 'react';

import { remedyKitUpgrade } from 'consts';
import { CURRENCY } from 'consts/locations';
import pathOr from 'ramda/src/pathOr';
import { useDispatch } from 'react-redux';
import ROUTES from 'routes';
import { routerActions } from 'store/actions';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import entityThunks from 'thunks/entity';

import {
  getMinorValue,
  getGST,
  getTotalProvisionPrice,
} from '../../../utils/Data/pricing/pricing';
import { VoucherPaymentsPublicProps } from './VoucherPayments.props';
import View from './VoucherPayments.view';

const VoucherPaymentsContainer: FC<VoucherPaymentsPublicProps> = (props) => {
  const dispatch = useDispatch();
  const country = props.voucherCard.country;
  const currency: Dinero.Currency = country ? CURRENCY[country] : 'AUD';

  const { redirectPayloadData, voucherCard } = props;
  const { prescription, price } = voucherCard;

  // Unused Provisions from Contentful
  const provisions = pathOr([], ['provisions'], voucherCard).map(
    (v: { text?: string; quantity: number; name?: string }) => ({
      quantity: v.quantity,
      text: v.text || v.name || '',
      value: 0,
    })
  );

  const [checkedProvisions, setCheckedProvisions] = useState<
    { text: string; value: number; quantity: number }[]
  >([]);
  const [shipping, setShipping] = useState<'physical' | 'digital'>('digital');

  const properPrice = getMinorValue(price, currency).toUnit();
  const provTotal = getTotalProvisionPrice(checkedProvisions, currency);

  let total = properPrice + provTotal;
  if (shipping !== 'digital') {
    const hasRemedy = checkedProvisions.some(
      (cp) => cp.text === remedyKitUpgrade.name
    );

    if (hasRemedy) {
      total += 12;
    } else {
      total += 5;
    }
  }

  const gstTotal = getGST(total, currency);
  let pricesList = [
    {
      text: prescription || '',
      value: properPrice,
    },
    ...checkedProvisions,
    ...provisions,
  ];
  if (shipping !== 'digital') {
    const hasRemedy = checkedProvisions.some(
      (cp) => cp.text === remedyKitUpgrade.name
    );

    if (hasRemedy) {
      pricesList = [...pricesList, { text: 'Post shipping', value: 12 }];
    } else {
      pricesList = [...pricesList, { text: 'Post shipping', value: 5 }];
    }
  }

  const taxAndTotal = [
    { text: currency == 'GBP' ? 'VAT' : 'GST', value: gstTotal },
    { text: `Total (${currency})`, value: total },
  ];
  const priceDetails = [...pricesList, ...taxAndTotal];

  const addonsList = pathOr(
    [],
    ['data', 'entities', '0', 'options', '0', 'addons'],
    useSelectorSafe((state) => state.entities)
  ).filter((a: { active: string }) => a.active === 'true');

  useEffect(() => {
    if (addonsList.length === 0) {
      dispatch(
        entityThunks.get({
          location: {
            address: '',
            latitude: 0,
            cityId: '',
            longitude: 0,
          },
          startDate: '',
          endDate: '',
          guests: '',
          beds: '',
        })
      );
    }
  }, []);

  useEffect(() => {
    const hasRemedy = checkedProvisions.some(
      (cp) => cp.text === remedyKitUpgrade.name
    );

    if (hasRemedy && country == 'AU') setShipping('physical');
  }, [checkedProvisions]);

  const profile = useSelectorSafe((state) => state.profile);
  const user = useSelectorSafe((state) => state.user);
  const email = pathOr('', ['data', 'user', 'email'], user);

  const profileData = pathOr(
    {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      address: '',
      city: '',
      state: '',
      postCode: '',
    },
    ['data', 'profile'],
    profile
  );
  const initialUserValues: Partial<typeof profileData> & {
    email: string;
    mobile: string;
  } = {
    ...{
      firstName: profileData.firstName || '',
      lastName: profileData.lastName || '',
      address: profileData.address || '',
      city: profileData.city || '',
      state: profileData.state || '',
      postCode: profileData.postCode || '',
    },
    email,
    mobile: profileData.phoneNumber || '',
  };

  delete initialUserValues.phoneNumber;

  const { redirectStep, ...initialRecipientValues } = redirectPayloadData || {};
  return (
    <View
      {...props}
      total={total}
      loading={addonsList.length === 0}
      priceDetails={priceDetails}
      // @ts-ignore
      remedyKit={remedyKitUpgrade}
      provisions={[]}
      goToLogin={(recipient) =>
        dispatch(
          routerActions.link(ROUTES.LOGIN_SIGNUP, {
            redirectTo: ROUTES.VOUCHER_PAYMENTS,
            redirectPayload: {
              id: voucherCard.id,
              data: {
                ...recipient,
                redirectStep: 1,
              },
            },
          })
        )
      }
      // @ts-ignore
      addonsList={addonsList}
      isLoggedIn={!!useSelectorSafe((state) => state.token)}
      checkedProvisions={checkedProvisions}
      setCheckedProvisions={setCheckedProvisions}
      initialUserValues={initialUserValues}
      initialRecipientValues={initialRecipientValues}
      shipping={shipping}
      setShipping={setShipping}
      redirectStep={redirectPayloadData?.redirectStep || 0}
      country={country || 'AU'}
    />
  );
};

export default React.memo(VoucherPaymentsContainer);
