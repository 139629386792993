export const terms = [
  {
    header: 'Website Terms and Conditions',
    items: [
      'These terms and conditions (the Unyoked Terms and Conditions) together with the Pre-Arrival Instructions and Additional House Rules, govern the relationship between Unyoked and any individual using or accessing this website (the Unyoked Website) and any of the content or services available through it as a traveller or potential traveller, and who is not using it in his/her/its capacity as a Landowner (each, a Traveller or You or Your). Travellers include people who use the Unyoked Website to access information about a Cabin or Property.',
      'The Unyoked Terms and Conditions constitute a legally binding agreement between Unyoked and You/Your/Traveller. By using or accessing the Unyoked Website, our platforms or systems, You acknowledge that You agree to and are subject to the Unyoked Terms and Conditions.',
      'Unyoked reserves the right to amend the Unyoked Terms and Conditions at any time and the use of the Unyoked Website and / or Unyoked Services by You constitutes your agreement to be bound by the Unyoked Terms and Conditions, as amended.  We therefore recommend that each time You access the Unyoked Website or use Unyoked Services, You read the Unyoked Terms and Conditions.',
    ],
  },
  {
    header: 'Our Services',
    items: [
      'Unyoked’s Services are provided to adults over the age of eighteen (18) years. By making a booking with Unyoked, You acknowledge that You are over 18 years of age.',
      'If You find a Cabin or Property in which You are interested, You can make a booking online.  Full payment is required at the time of booking. Until such time as payment is made Your booking remains unconfirmed.',
      'All prices are contained in the Fee Sheet which can be accessed on the Unyoked Website and Unyoked reserves the right to amend the Fee Sheet at any time. Prices on the Fee Sheet are in Australian Dollars (AUD) and are exclusive of GST. Unyoked will endeavour to ensure that the Fee Sheet is current.  Once a booking is confirmed, Unyoked undertakes to fulfil the booking at the price listed on the Fee Sheet at the time of the booking. If, for any reason at Unyoked’s discretion, Unyoked requires that the price of the booking be amended, it will provide three (3) months notice to You of the amended price.',
      'Unyoked will not charge You a security bond on Your booking.  If, following Your stay at the Property and / or Cabin, it is determined that loss or damage has been incurred by the Landowner or Unyoked, Unyoked reserves the right to charge your credit card to cover any additional costs incurred, including but not limited to any breakage, damage or excess cleaning requirements, excess refuse, extra guests beyond those declared etc.',
    ],
  },
  {
    header: 'Traveller Responsibility',
    items: [
      'Our Cabins are located on rural and often undeveloped areas of land that are not occupied by the Landowner all year round. As such You acknowledge that You are responsible for Your own safety in and around the Cabin and Property. You acknowledge that You are aware of the risks entailed in a wilderness environment.',
    ],
  },
  {
    header: 'Arrival at the Cabin and Property',
    items: [
      'Within 48 hours of Your arrival date, Unyoked will provide You with Pre-Arrival Instructions to the email address provided on the booking form.  Upon arrival at the Cabin and Property, You will also be provided with Additional House Rules which will be located in the Cabin.',
      'You agree to follow the Supplied Route and park any Vehicles in the Designated Parking Area (as defined in the Pre-Arrival Instructions). A maximum of two (2) Vehicles are permitted in the Designated Parking Area during any stay.',
      'You must not drive any Vehicles off the Supplied Route, including but not limited to the path up to the Cabin itself as this is not suitable for driving. You will be responsible for any loss or damage caused to the Cabin or Property if Your Vehicle/s do not remain on the Supplied Route.',
      'During wet or poor weather, You will be notified of a weather warning.  In the event you are notified of a weather warning, You agree to park your Vehicle/s in the Wet Weather Zones (as defined in the Pre-Arrival Instructions). Failure to follow the Pre-Arrival Instructions may result in damage to your Vehicle/s including becoming bogged and / or requiring towing.  Any costs associated with failure to adhere to the weather warning will be incurred by You at Your own expense.',
    ],
  },
  {
    header: 'Staying in a Cabin',
    items: [
      'During your stay, You must remain in the Designated Area provided on the Pre-Arrival Instructions.  You are not permitted to approach the Landowner’s Dwelling. In case of an emergency, use the emergency details provided on the Pre-Arrival Instructions.',
      'Campfires must be contained within the designated fire-pit and must be kept below knee-height and not left unattended. All fires must be extinguished by midnight. If there is a fire ban in place, You will be notified by text or email (Fire Ban Notice) and You must adhere to the Fire Ban Notice.  Failure to adhere to the Fire Ban Notice will result in immediate eviction from the Cabin and Property. You will be liable for any loss or damage to the Cabin and/or Property resulting from the failure to adhere to a Fire Ban Notice.',
      'You must respect the local wildlife and not approach any animals, domestic or otherwise on the Property.',
      'Any gates opened by You must be closed directly after. You are responsible for ensuring You do not leave any gates open on the Property.  You should be mindful that the Landowner may have livestock or other animals on the Property which may escape if gates are left open. You will be liable for any loss or damage resulting from the failure to close any gates opened by You.',
      'Pets are not permitted in the Cabin or on the Property without prior approval from Unyoked or if specifically advised in the Pre-Arrival Instructions.',
      'Noise can be a serious issue in rural communities. Good neighbourly conduct is expected and You must not make excessive noise and play loud music at any time of day or night.  All outdoor music and socializing must cease by 10pm and be taken indoors with doors shut so that noise does not travel to the neighbours.',
      'You acknowledge that You are fully liable for any loss or damage You cause to the Cabin or Property. Unyoked reserves the right to deduct charges from Your credit card for any loss or damage incurred.',
      'You agree that only those persons indicated on the booking form will enter the Cabin and Property. Unyoked reserves all rights to evict You from the Cabin and Property if this is not adhered to.',
      'You agree to behave responsibly when using the Cabin and the surrounding area. Parties are not permitted.',
      'Smoking is not permitted inside or within a distance of 10m from the Cabin.',
      'You agree to observe any and all Additional House Rules displayed inside and outside the Cabin.',
      'The Pre-Arrival Instructions / Additional House Rules will contain instructions as to the use of the composting toilet.  Failure to follow the instructions relating to the composting toilet, specifically if liquid is added to the solid waste unit and affects the compost operating, will result in a a fee (Cleaning Fee) in circumstances where disposal is necessary.  The Cleaning Fee will be charged to your credit card.',
    ],
  },
  {
    header: 'Departure from the Cabin and Property',
    items: [
      'All Vehicles are to depart the Cabin and Property via the Supplied Route.',
      'It is Your responsibility to take all your rubbish with You when You depart the Cabin and Property. Unyoked reserves the right to charge You any fees associated with the removal of rubbish after your stay.',
      'You agree to leave the Cabin and Property by the Departure Date and allocated time. Unyoked reserves the right to charge You fees for any early arrivals or late departures',
      'You agree to leave the Cabin and Property in the same condition as when You arrived, subject to natural wear and tear. Any rubbish or unreasonable mess created in or around the cabin will incur an extra cleaning fee.',
      'You agree to notify Unyoked of any damage caused to the Cabin and / or Property incurred during your stay.',
    ],
  },
  {
    header: 'Cancellation Policy',
    items: [
      'We understand that sometimes things can change. For changes made at least 15 days prior to arrival there is no cancellation fee and guests can choose to either reschedule, receive a gift voucher for the full amount or a refund minus our processing fee of 5% of the total. For changes made 8-15 days prior to arrival, there is a cancellation fee of 50% of the total booking amount. For cancellations within the 8 days prior to arrival this puts us in a really tricky situation to fill it such last minute so there will no refund, voucher or rebooking - partial or otherwise.',
    ],
  },
  {
    header: `Warranties and Unyoked's Liability`,
    items: [
      {
        text:
          'To the extent permitted by law, Unyoked excludes all liability to You or any other person, for any loss (including indirect, special or consequential loss), damages, costs (including legal costs) or expenses of any kind, arising from or in connection with:',
        list: [
          'any act or omission of a Landowner or third party;',
          'Your use and access of the Unyoked Website;',
          'Your acquisition or use of Unyoked’s Services, or that of any Gift Recipient or transferee;',
          'any errors or inaccuracies in the information in respect of Unyoked’s Services;',
          'delays to, interruptions of or cessation of transmission to or from the Unyoked Website.',
        ],
      },
      'Nothing in the Unyoked Terms and Conditions is intended to exclude, restrict or modify any guarantees under the Australian Consumer Law which apply and cannot be excluded, restricted or modified. Otherwise, all terms, conditions, warranties and representations, express or implied by statute or otherwise are excluded.',
      'We do not make any warranties or guarantees regarding Unyoked’s Services including without limitation, any guarantee that Unyoked’s Services are a particular quality or standard, and are defect free. You may be entitled to certain guarantees under the Australian Consumer Law.',
      'We do not warrant, guarantee or make any representation that the Unyoked Website, or the server that makes the site available on the World Wide Web are free of software viruses, free from errors, or that the Unyoked Website will operate uninterrupted.',
    ],
  },
  {
    header: 'Guarantee and Indemnity',
    items: [
      {
        text:
          'You guarantee to indemnify and hold Unyoked and the Landowner harmless against all Claims by third parties brought against Unyoked or the Landowner arising out of or in connection with:',
        list: [
          'any material or content You provide to Unyoked through the Unyoked Website;',
          'Your use of any content on the Unyoked Website including but not limited to the Services;',
          'Your entry to and stay at the Cabin and Property; and',
          'any breach of the Unyoked Terms and Conditions.',
        ],
      },
    ],
  },
  {
    header: 'Unyoked Website',
    items: [
      {
        title: '(A) Ownership',
        subItems: [
          'The material on the Unyoked Website is copyright of Unyoked and/or other copyright owners. Unyoked reserves all Intellectual Property Rights in the Unyoked Website and material contained in it.',
          'Subject to clause 41, You may view, download, and print pages from the Unyoked Website for your own personal and non-commercial use, provided Your do not remove any copyright and trademark notices contained on the material.',
          {
            text: 'Users must not:',
            list: [
              'upload, repost or republish material from the Unyoked Website (including to any other site on the World Wide Web);',
              '“frame” the material on the Unyoked Website with other material on any other World Wide Web site.',
              'sell, rent or sub-license material from the Unyoked Website;',
              'show any material from the Unyoked Website in public;',
              'reproduce, duplicate, copy or otherwise exploit material on the Unyoked Website for a commercial purpose;',
              'edit or otherwise modify any material on the Unyoked Website; or',
              'redistribute material from the Unyoked Website.',
            ],
          },
        ],
      },
      {
        title: '(B) Acceptable use',
        subItems: [
          {
            text: 'You must not:',
            list: [
              'use the Unyoked Website in any way that causes, or may cause, damage to the Unyoked Website or impairment of the availability or accessibility of the Unyoked Website;',
              'use the Unyoked Website in any way that in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity;',
              'use the Unyoked Website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software; conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to the Unyoked Website without Unyoked’s express written consent;',
              'use the Unyoked Website to transmit or send unsolicited commercial communications;',
              'use the Unyoked Website for any purposes related to marketing without Unyoked’s express written consent.',
            ],
          },
          'The Unyoked Website may from time to time contain hyperlinks to other websites. Such links are provided for convenience only and we take no responsibility for the content and maintenance of or privacy compliance by any linked website.  Any hyperlink on the Unyoked Website to another website does not imply our endorsement, support, or sponsorship of the operator of that website nor of the information and/or products which they provide.',
          'You may link the Unyoked Website without our consent. Any such linking will be entirely Your responsibility and at Your expense.  When linking, You must not alter any of our the Unyoked Website’s contents including any intellectual property notices and You must not frame or reformat any of our pages, files, images, text or other materials.',
        ],
      },
      {
        title: '(C) Member Content and Reviews',
        subItems: [
          'You grant to Unyoked a worldwide, perpetual, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish, modify, translate and distribute any content provided to us (Member Content) in any existing or future media. You also grant to Unyoked the right to sub-license these rights, and the right to bring an action for infringement of these rights.',
          {
            text:
              'You grant to Unyoked a worldwide, perpetual, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish, modify, translate and distribute any content provided to us (Member Content) in any existing or future media. You also grant to Unyoked the right to sub-license these rights, and the right to bring an action for infringement of these rights.',
            list: [
              'You are authorised to provide the Member Content;',
              'the Member Content is not false, misleading or defamatory;',
              'the Member Content does not contain any obscene or discriminatory language;',
              'you are the sole author of the Member Content and owner of the Intellectual Property Rights of the Member Content;',
              'the Member Content does not infringe any Intellectual Property Right of a third party;',
              'You consent to any acts or omissions by UnYoked in respect of Member Content which might otherwise infringe your “moral rights” (as defined under the Copyright Amendment (Moral Rights) Act 2000 (Cth)); and',
              'the Member Content does not infringe any law, statute or regulation (including but not limited to any unfair competition, anti-discrimination or false advertising).',
            ],
          },
          'Unyoked reserves the right to edit or remove any Member Content from the Unyoked Website.',
        ],
      },
      {
        title: '(D) Privacy',
        subItems: [
          'Unyoked undertakes to take all due care with any information which You may provide to us when accessing the Unyoked Website. However we do not warrant and cannot ensure the security of any information which You may provide to Unyoked. Information you transmit to us is entirely at your own risk although we undertake to take reasonable steps to preserve such information in a secure manner.',
          'Unyoked’s compliance with privacy legislation is set out in our separate Privacy Policy which may be accessed from the Unyoked Website home page.',
        ],
      },
      {
        title: '(E) Gift Card',
        subItems: [
          'When You purchase a Gift Card, You will be emailed a copy of the Gift Card to the email address You provided to Unyoked.',
          'You must redeem Your Gift Card, within the redemption period specified in the terms of the Gift Card, or otherwise within twelve (12) months of the purchase date.  Once a Gift Card has expired then the Gift Card ceases to be valid, and is non-refundable. Unyoked may, in its absolute discretion, decide to extend the redemption period of an expired Gift Card.',
          'Unyoked are not responsible if a Gift Card is lost, altered, damaged, destroyed or stolen, or used without permission. Please note weekends are very popular and Unyoked cannot guarantee availability, so we recommend You also consider a mid-week escape option and check availability online as soon as possible.',
        ],
      },
      {
        title: '(F) General',
        subItems: [
          'Amendment: We reserve the right, at Unyoked’s discretion, to update or revise the Unyoked Terms and Conditions.',
          'Notice: We may give notice under the Unyoked Terms and Conditions by means of notice on the Unyoked Website, or by email to Your email address provided on the booking form.',
          'Severability: If any provision of the Unyoked Terms and Conditions at any time is or becomes void, voidable or unenforceable, the remaining provisions will continue to have full force and effect.',
          'Waiver: Unless expressed in writing to You by Unyoked, any failure or delay by Unyoked in exercising any right, power or privilege available to Unyoked will not operate as a waiver of that power or right.',
        ],
      },
    ],
  },
];
