import { CSSProperties } from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { pxToRem } from '../../../themeConfig/typography';
import { colors } from 'themeConfig/themeConstants';

interface StyleProps {
  hidden?: boolean;
  stateColor?: string;
  stateBg?: string;
  stateBorderBg?: string;
  endItemBg?: string;
  endItemBorderBg?: string;
  trigger?: boolean;
  threshold: number;
  isMobile: boolean;
  hasBanner: boolean;
  bannerOffset: string;
}

export const useStyles = makeStyles((theme: Theme) => {
  const itemContainer: CSSProperties = {
    display: 'flex',
    transition: 'opacity 0.3s',
    justifyContent: 'center',
    alignItems: 'start',
    flex: 1,
    height: 76,
    flexDirection: 'column',
  };

  return createStyles({
    appBar: ({ trigger, threshold, isMobile, hasBanner, bannerOffset }) => ({
      top: isMobile ? hasBanner  ? bannerOffset  :
          55 : (trigger ? 0 : threshold),
      // position: 'absolute',
      bottom: 0,
      left: 0,
      boxShadow: 'none',
      height: '76px',
      width: isMobile ? '100%' : undefined,
    }),
    appBarDefault: {
      backgroundColor: 'transparent',
      transition: 'width 0.3s',
    },
    appBarElevated: ({ hidden }: StyleProps) => ({
      backgroundColor: 'transparent',
      transition: 'width 0.3s',
      width: '100%',
    }),
    container: ({ isMobile }) => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      height: isMobile ? '228px' : '76px',
      background: colors.primaryYellow,
    }),
    itemContainer: ({ stateBg, stateColor, stateBorderBg, hidden, isMobile }: StyleProps) => ({
      ...itemContainer,
      backgroundColor: stateBg,
      opacity: hidden ? 0 : 100,
      borderColor: stateBorderBg,
      borderBottom: `2px solid ${stateColor}`,
      ...(isMobile ? { flex: undefined, width: '100%' } : {})
    }),
    extendedItemContainer: ({
      stateBg,
      stateBorderBg,
      hidden,
      isMobile
    }: StyleProps) => ({
      backgroundColor: stateBg,
      borderColor: stateBorderBg,
      display: 'flex',
      opacity: hidden ? 0 : 100,
      transition: 'opacity 0.3s',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'start',
      minHeight: 76,
      width: isMobile ? '100%' : undefined
    }),
    itemContent: ({ trigger, stateBg, isMobile }) => 
    isMobile ? {
      position: 'absolute',
      width: '100%',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      top: '76px',
      background: colors.primaryYellow,
      color: colors.primaryDarkGreen,
      zIndex: 99999,
      maxHeight: 'calc(100dvh - 139px)',
      overflow: 'auto',
      paddingBottom: 20,
    } : trigger ? {
      position: 'absolute',
      width: '33.3%',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      top: '76px',
      background: stateBg,
      border: '1px solid',
      zIndex: 999,
    } : {
      width: '100%',
      maxHeight: '40vh',
      overflow: 'scroll',
    },
    firstItemContainer: {
      paddingLeft: `${theme.spacing(6)}px !important`,
    },
    endItemContainer: ({ hidden, endItemBg, endItemBorderBg, isMobile }: StyleProps) => ({
      ...itemContainer,
      alignItems: 'center',
      justifyContent: 'center !important',
      paddingTop: 10,
      backgroundColor: endItemBg,
      borderColor: endItemBorderBg,
      borderRight: hidden ? 'none' : '',
      borderBottom: `2px solid ${endItemBorderBg}`,
      minHeight: 76,
      width: isMobile ? '100%' : undefined
    }),
    arrowContainer: ({ stateBg, stateColor }: StyleProps) => ({
      height: 76,
      backgroundColor: stateBg,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      borderBottom: `1px solid ${stateColor}`
    }),
    mustHaveCheckBox: ({ stateColor }: StyleProps) => ({
      '& svg': {
        '& circle': {
          stroke: stateColor,
        },
        '& path': {
          fill: stateColor,
        },
      },
    }),
    textItems: ({ stateColor }: StyleProps) => ({
      color: stateColor,
      lineHeight: 0.5
    }),
    textItems2: ({ stateColor, isMobile }: StyleProps) => ({
      color: colors.primaryDarkGreen
    }),
    arrowButtons: ({ stateColor }: StyleProps) => ({
      padding: 0,
      minWidth: 0,
      fontSize: pxToRem(18),
      color: stateColor,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      textTransform: 'none',
      justifyContent: 'flex-start',
      width: '100%',
      "& .MuiButton-label": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textAlign: "left",
        display: "block"
      }
    }),
    arrowButtons2: ({ stateColor, isMobile }: StyleProps) => ({
      paddingLeft: 0,
      paddingRight: 0,
      minWidth: 0,
      fontSize: pxToRem(18),
      color: colors.primaryDarkGreen,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      textTransform: 'none',
      justifyContent: 'flex-start',
      width: '100%',
    }),
    hasValue: () => ({
      padding: 0,
    }),
    filterButton: {
      background: colors.primaryYellow,
      color: colors.secondaryDarkGreen,
      border: '2px solid',
      borderRadius: 0,
      position: 'absolute',
      right: '6.7%',
      [theme.breakpoints.up(1440)]: {
        right: '9.7%',
      },
    },
    sortBox: ({ isMobile }) => !isMobile ? ({
      width: '300px',
      position: 'absolute',
      right: 'calc(5% + 180px)',
      [theme.breakpoints.up(1440)]: {
        right: 'calc(8% + 200px)',
      },
    }) : ({
      width: '100%',
      paddingLeft: '20px',
      paddingRight: '20px'
    }),
    contentTitle: ({ isMobile, trigger }) => ({ 
      height: 76,
      width: '50%', 
      border: `0px solid`,
      backgroundColor: trigger ? colors.primaryYellow : 'transparent',
      display: 'flex',
      alignItems: 'center', 
      padding: theme.spacing(1),
      paddingLeft: !isMobile ?  '6.7%' : '20px',
      [theme.breakpoints.up(1440)]: {
        paddingLeft: '9.7%',
      },
    })
  });
});
