import React, { FC, ReactElement, useState } from 'react';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { LeftArrow } from 'assets/svgs';
import { parseLinksInText } from 'utils/String';

import HorizontalLine from '../HorizontalLine';
import Typo from '../Typo';
import { CollapseProps } from './Collapse.props';
import { useStyles } from './Collapse.styles';

const CollapseView: FC<CollapseProps> = ({
  title,
  titleNode,
  content,
  color,
  isArrowLeft = false,
  children = null,
  isOpen,
  titleVariant,
}: CollapseProps) => {
  const classes = useStyles({ color, isArrowLeft });

  const parsedText = content ? parseLinksInText(content, classes.link) : '';
  const [isExpanded, setIsExpanded] = useState(isOpen);

  return (
    <Box color={color}>
      <Box
        className={classes.headerContainer}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isArrowLeft && (
          <Box mr={0.5} color={color} className={classes.iconContainer}>
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </Box>
        )}
        {!titleNode ? (
          <Typo variant={titleVariant || 'h1'} color="inherit">
            {title}
          </Typo>
        ) : titleNode}
        {!isArrowLeft && (
          <Box color={color} className={classes.iconContainer}>
            {isExpanded ? (
              <LeftArrow
                styles={{
                  transform: 'rotate(90deg)',
                }}
                color={color}
              />
            ) : (
              <LeftArrow
                styles={{
                  transform: 'rotate(270deg)',
                }}
                color={color}
              />
            )}
          </Box>
        )}
      </Box>
      <HorizontalLine className={classes.fatLine} />
      <Collapse in={isExpanded}>
        {children ? (
          children
        ) : (
          <Typo
            color="inherit"
            variant="body2"
            dangerouslySetInnerHTML={{ __html: parsedText }}
          />
        )}
      </Collapse>
    </Box>
  );
};

export default CollapseView;
