import React, { FC, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import Skeleton from '@material-ui/lab/Skeleton';
import { Loading } from 'components/core';

import HouseCard from '../../modules/HouseCard';
import { HouseCardPublicProps } from '../../modules/HouseCard/HouseCard.props';
import {
  Button,
  GhostButton,
  HorizontalLine,
  Link,
  Typo,
} from '../../primitives';
import { BookingHistoryProps, Booking } from './BookingHistory.props';
import { useStyles } from './BookingHistory.styles';
import { Modal } from '@material-ui/core';
import DetailsModal from './DetailsModal';

// @ts-ignore
const Cards: FC<{
  content: Booking[];
  onAddProvisions?: (id: string) => void;
  classes: any;
  isMobile: boolean;
  isXs: boolean;
}> = ({ onAddProvisions, content, classes, isMobile, isXs }) => {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState();
  const [selectedId, setSelectedId] = useState('');

  const onShowDetails = (id, item) => {
    setShowModal(true);
    setSelectedId(id);
    setSelected(item);
  };

  return (
    <>
      <Modal open={showModal}>
        <DetailsModal
          id={selectedId}
          item={selected}
          isMobile={isMobile}
          onAddProvisions={(id: string) => {
            setShowModal(false);
            onAddProvisions?.(id);
          }}
          onClick={() => setShowModal(false)}
        />
      </Modal>
      <Grid container className={classes.contentContainer}>
        {isXs && (
          <Box className={classes.scrollableCards}>
            {content.map(({ id, item, ...cardData }) => (
              <Box
                key={id}
                className={classes.houseCard}
                onClick={() => onShowDetails(id, { ...item, ...cardData })}
              >
                <HouseCard {...item} isHero={false} imageHeight={'150px'} />
                {onAddProvisions && (
                  <GhostButton
                    text="Review or update your stay details"
                    onClick={() => onShowDetails(id, { ...item, ...cardData })}
                    style={classes.ghostButton}
                  />
                )}
              </Box>
            ))}
          </Box>
        )}

        {!isXs && (
          <Grid container spacing={4}>
            {content.map(({ id, item, ...cardData }) => (
              <Grid key={id} item sm={4} style={{ marginBottom: '3%' }}>
                <Box
                  onClick={() => onShowDetails(id, { ...item, ...cardData })}
                >
                  <HouseCard {...item} isHero={false} />
                  {onAddProvisions && (
                    <Button
                      text={''}
                      iconText="Review or update your stay details"
                      variant="h4"
                      iconTextVariant="h6"
                      onClick={() =>
                        onShowDetails(id, { ...item, ...cardData })
                      }
                    />
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </>
  );
};

const BookingHistoryView: FC<BookingHistoryProps> = ({
  bookingStatus,
  upcomingBookings,
  pastBookings,
  goToAccount,
  goToResults,
  onAddProvisions,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const classes = useStyles({ isMobile });

  return (
    <Box>
      <Link onClick={goToAccount}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ArrowBackIcon className={classes.arrow} />
          <Typo variant="h6">Account Settings</Typo>
        </Box>
      </Link>
      <Box mb={isXs ? 4 : 8} />
      <Typo variant={isMobile ? 'h3' : 'h1'} className={classes.header}>
        Upcoming nature stays
      </Typo>
      {upcomingBookings && upcomingBookings.length > 0 ? (
        <Cards
          onAddProvisions={onAddProvisions}
          content={upcomingBookings}
          classes={classes}
          isMobile={isMobile}
          isXs={isXs}
        />
      ) : (
        <Box mt={isXs ? 4 : 8} mb={isXs ? 4 : 8}>
          {bookingStatus != 'COMPLETE' ? (
            <>
              <Skeleton />
              <Skeleton animation={false} />
              <Skeleton animation="wave" />
            </>
          ) : (
            <Typo
              variant={isMobile ? 'h5' : 'h3'}
              className={classes.description}
            >
              There’s no time like the present to get an adventure on your
              calendar! Head{' '}
              <span onClick={goToResults} className={classes.textLink}>
                here.
              </span>
            </Typo>
          )}
        </Box>
      )}

      {pastBookings && pastBookings.length > 0 ? (
        <>
          <Box mt={isXs ? 8 : 0} mb={isXs ? 4 : 8}>
            {!isXs && (
              <Box mb={8}>
                <HorizontalLine className={classes.horizontalLine} />
              </Box>
            )}
            <Typo variant={isMobile ? 'h3' : 'h1'} className={classes.header}>
              Past stays
            </Typo>
          </Box>

          <Cards
            onAddProvisions={null}
            content={pastBookings}
            classes={classes}
            isMobile={isMobile}
            isXs={isXs}
          />
        </>
      ) : (
        <Box mt={isXs ? 0 : 40} />
      )}
    </Box>
  );
};

export default React.memo(BookingHistoryView);
