import React, { FC, useEffect } from 'react';

import moment from 'moment';
import pathOr from 'ramda/src/pathOr';
import pick from 'ramda/src/pick';
import { useDispatch } from 'react-redux';
import { routerActions, filterActions } from 'store/actions';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { bookingQuestionsState } from 'types/store/BookingQuestionsState';
import { CurrentEntityState } from 'types/store/CurrentEntityState';
import { FilterState } from 'types/store/FilterState';
import { asyncData } from 'utils/Redux';

import ROUTES from '../../../routes';
import { Entity } from '../../../types/Entity';
import { Filter } from '../../../types/Filter';
import {
  BookingBarPublicProps,
  BookingBarProps,
  EntityData,
} from './BookingBar.props';
import View from './BookingBar.view';

const entitiesFallback: CurrentEntityState = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not get entity' },
]);

const BookingBarContainer: FC<BookingBarPublicProps> = (
  publicProps: BookingBarPublicProps
) => {
  const dispatch = useDispatch();
  const filterData = useSelectorSafe<FilterState>(
    (state) => state.filter,
    null
  );

  const entity: EntityData = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<CurrentEntityState>(
      (state) => state.currentEntity,
      entitiesFallback
    )
  );
  const bookingQuestions = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<bookingQuestionsState>(
      (state) => state.bookingQuestions,
      asyncData(ASYNC_STATUS.ERROR, [
        { message: 'Could not get booking questions' },
      ])
    )
  );
  const currentEntity: Entity | undefined = pathOr<Entity | undefined>(
    undefined,
    ['data', 'entity'],
    entity
  );

  const query: object = useSelectorSafe<object>(
    (state) => state.location.payload,
    {}
  );
  const id: string = pathOr(pathOr('', ['id'], query), ['id'], currentEntity);
  const nextAvailableStart: Date | null = pathOr(
    null,
    ['nextAvailableStart'],
    query
  );
  const nextAvailableUntil: Date | null = pathOr(
    null,
    ['nextAvailableUntil'],
    query
  );

  const onBook = (startDate: string, endDate: string, guests: string) => {
    if (!currentEntity) return;

    window.analytics.track('Product Added', {
      product_id: currentEntity.id,
      product_name: currentEntity.title,
      product_url: window.location.href,
      product_image_url: currentEntity.metadata.preview,
      product_pricing_weekend: currentEntity.metadata.pricing.weekend,
      product_pricing_weeknights: currentEntity.metadata.pricing.weeknights,
      start_date: moment(startDate).toISOString(),
      end_date: moment(endDate).toISOString(),
      guests: guests,
    });

    dispatch(
      routerActions.link(ROUTES.HOUSE_PAYMENTS, {
        name: currentEntity.title,
        id,
        data: {
          startDate,
          endDate,
          guests,
        },
      })
    );
  };

  useEffect(() => {
    const zendeskIcon = document.querySelector('#launcher');
    zendeskIcon?.classList.add('higher');

    return () => {
      zendeskIcon?.classList.remove('higher');
    };
  }, []);

  const combinedProps: BookingBarProps = {
    ...publicProps,
    filterData,
    questions: bookingQuestions.data?.questions,
    nextAvailableStart,
    nextAvailableUntil,
    entity,
    id,
    onBook,
    updateFilter: (payload: Filter) =>
      dispatch(filterActions.updateBooking(payload)),
  };

  return <View {...combinedProps} />;
};

export default BookingBarContainer;
