import React, { FC, ReactNode, useEffect, useLayoutEffect } from 'react';

import { useDispatch } from 'react-redux';
import { NOT_FOUND } from 'redux-first-router';
import ROUTES from 'routes';
import { routerActions, filterActions } from 'store/actions';
import useSelectorSafe from 'store/selectors/useSelectorSafe';

import { RouteDef } from '../../../types/RouteDef';
import LazyComponent from '../LazyComponent/LazyComponent';
import { FilterState } from 'types/store/FilterState';
import PageTracker from '../PageTracker/PageTracker';
import { RouterPublicProps } from './Router.props';
import useGeoLocation from 'react-ipgeolocation';
import { resetLeaveListener } from 'exit-intent';

const isStaging = import.meta.env.VITE_NODE_ENV === 'staging';

const Middleware = ({
  route,
  children,
}: {
  route: RouteDef;
  children: ReactNode;
}) => {
  const MiddlewareWrapper = route.middleware && route.middleware();

  return MiddlewareWrapper ? (
    <MiddlewareWrapper>{children}</MiddlewareWrapper>
  ) : (
    <>{children}</>
  );
};

const STAGING_PASSWORD = '10H@rt$treet';
const ALLOWED_COUNTRIES = ['GB', 'NZ'];

const RouterContainer: FC<RouterPublicProps> = (props: RouterPublicProps) => {
  const dispatch = useDispatch();
  const url = window.location.pathname.replace('/', '');
  const location = useSelectorSafe<string>(
    (state) => state.location.type,
    NOT_FOUND
  );
  const { country } = useGeoLocation();
  const selectedCountry = useSelectorSafe((state) => state.country);

  useEffect(() => {
    resetLeaveListener();
    return;
  }, []);

  if (isStaging) {
    const stagingAuth = useSelectorSafe<string>(
      (state) => state.stagingAuth.password,
      ''
    );
    if (!stagingAuth || stagingAuth !== STAGING_PASSWORD) {
      const stagingRoute = ROUTES.STAGING_PASSWORD;
      dispatch(routerActions.link(ROUTES.STAGING_PASSWORD));
      return (
        <>
          {LazyComponent(`${stagingRoute.component}`, {
            route: stagingRoute,
          })}
        </>
      );
    }
  }

  let matchedRoute: RouteDef =
    location === NOT_FOUND
      ? props.components.NOT_FOUND
      : // strip the `ROUTER/` prefix to find in route map
        props.components[location.substr(7)];

  if (['', 'NZ', 'UK'].includes(url)) {
    const code =
      ALLOWED_COUNTRIES.indexOf(country) > -1
        ? country
        : selectedCountry?.code ?? 'AU';
    const newRoute = code === 'AU' ? 'ROOT' : `ROOT_${code}`;

    if (newRoute !== newRoute) {
      dispatch(routerActions.link(ROUTES[newRoute]));
      matchedRoute = props.components[newRoute];
    }
  }

  return (
    <Middleware route={matchedRoute}>
      <PageTracker>
        {LazyComponent(`${matchedRoute.component}`, {
          route: matchedRoute,
        })}
      </PageTracker>
    </Middleware>
  );
};

export default RouterContainer;
