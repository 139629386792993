import React, { FC } from 'react';

import Divider from '@material-ui/core/Divider';

import { HorizontalLineProps } from './HorizontalLine.props';
import { useStyles } from './HorizontalLine.styles';

const HorizontalLineView: FC<HorizontalLineProps> = (
  props: HorizontalLineProps
) => {
  const classes = useStyles({ lineColor: props.color || '' });
  return <Divider className={`${classes.default} ${props.className}`} />;
};

export default React.memo(HorizontalLineView);
