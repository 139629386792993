import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface StyleProps {
  isMobile: boolean;
  customHeight?: string;
  imgPosition?: string;
  marginTop?: number
hasBanner: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: ({ customHeight, marginTop, hasBanner }: StyleProps) => ({
      height: customHeight || '100%',
      marginBottom: `-2px`,
      marginTop: hasBanner && marginTop ? marginTop - 100 : 0,
    }),
    imageContainer: ({ customHeight, marginTop, hasBanner }: StyleProps) => ({
      height: customHeight || '100dvh',
      display: 'flex',
      position: 'absolute',
      flexDirection: 'column',
      top: marginTop ?? 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -100,
    }),
    image: ({ customHeight, imgPosition }: StyleProps) => ({
      height: customHeight || '100dvh',
      objectFit: 'cover',
      objectPosition: imgPosition ?? '50% 100%',
    }),
    imageBlurred: ({ customHeight }: StyleProps) => ({
      height: customHeight || '100dvh',
      objectFit: 'cover',
      objectPosition: '50% 100%',
      filter: `blur(10px)`,
    }),
    titleContainer: ({ customHeight }: StyleProps) => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: customHeight || '100dvh',
      textAlign: 'center',
    }),
    title: ({ isMobile }: StyleProps) => ({
      fontSize: isMobile ? 32 : 46,
      // [theme.breakpoints.up('lg')]: {
      //   fontSize: 90,
      // },
    }),
    filterContainer: {
      // height: 100,
    },
  })
);
