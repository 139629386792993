import React, { FC } from 'react';

import { BlogLandingPublicProps } from './BlogLanding.props';
import View from './BlogLanding.view';

const BlogLandingContainer: FC<BlogLandingPublicProps> = (props) => {
  return <View {...props} />;
};

export default React.memo(BlogLandingContainer);
