import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props: { isMobile: boolean }) => ({
      height: '100%',
      backgroundColor: colors.secondaryDarkGreen,
      paddingBottom: props.isMobile ? '15%' : '10%',
    }),
    carousel: {
      '& .slick-track': {
        backgroundColor: colors.secondaryDarkGreen,
      },
      '& .slick-list': {},
      '& .slick-arrow': {
        display: 'block',
        position: 'absolute',
        top: '60%',
        height: 33,
        width: 15,
        transform: 'translateY(-60%)',
        zIndex: 5,
      },
      '& .slick-next': {
        right: '2%',
        borderBottom: `2px solid transparent`,
        '&:hover': {
          borderBottom: `2px solid ${colors.primaryPink}`,
        },
      },
      '& .slick-prev': {
        left: '2%',
        borderBottom: '2px solid transparent',
        '&:hover': {
          borderBottom: `2px solid ${colors.primaryPink}`,
        },
      },
    },
    contentContainer: (props: {
      contentHeight: number;
      isMobile: boolean;
    }) => ({
      height: props.contentHeight,
      padding: props.isMobile ? '15% 5% 10% 5%' : '10% 5% 10% 5%',
      position: 'relative',
      [theme.breakpoints.up(1440)]: {
        paddingLeft: '11%',
        paddingRight: '11%',
      },
    }),
    image: {
      height: '150%',
      width: '100%',
      marginBottom: 5,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    imageDarken: {
      filter: 'brightness(0.7)',
    },
    headerContainer: {
      marginBottom: 20,
      [theme.breakpoints.up('sm')]: {
        height: '10%',
      },
    },
    leftHeader: {
      textAlign: 'left',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 32,
      },
    },
    rightHeaderContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    rightHeader: {
      zIndex: 1,
      textAlign: 'left',
      lineHeight: '110%',
      display: '-webkit-box',
      '-webkit-line-clamp': 5,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 44,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 66,
      },
    },
    caption: {
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    count: {
      textAlign: 'right',
    },
  })
);
