import { Dispatch } from 'redux';
import {
  getProfileEndpoint,
  updateProfileEndpoint,
} from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { userActions } from 'store/actions';

import { Store } from '../../types/store/Store';

interface Data {
  firstName?: string;
  lastName?: string;
  // email?: string,
  mobile?: string;
  address?: string;
  city?: string;
  state?: string;
  postcode?: string;
  accountCountry?: string;
}

interface Signalling {
  finishUpdating?: (data: any) => void;
  updateErrorMessage?: (message: any) => void;
}

const updateProfile =
  (data: Data, signalling?: Signalling) =>
  async (dispatch: Dispatch, getState: () => Store) => {
    const updateProfileFetch = await apiFetch<Data, any>(
      updateProfileEndpoint,
      data
    )(dispatch, getState)
      .then((p) => {
        if (signalling && signalling.finishUpdating) {
          signalling.finishUpdating(p.data);
        }

        window.analytics.identify(p.user.openbookId, {
          email: p.user.email,
          first_name: p.profile.firstName,
          last_name: p.profile.lastName,
          phone: p.profile.phoneNumber,
          traits: {
            address: p.profile.address,
            city: p.profile.city,
            state: p.profile.state,
            postcode: p.profile.postCode,
          }
        });

        return p;
      })
      .catch((err) => {
        if (signalling && signalling.updateErrorMessage)
          signalling.updateErrorMessage(err);
      });
    dispatch(userActions.updateProfile(updateProfileFetch));

    dispatch(
      userActions.getProfile(
        await apiFetch<Data, any>(getProfileEndpoint, {})(
          dispatch,
          getState
        )
      )
    );

    return updateProfileFetch;
  };

export default updateProfile;
