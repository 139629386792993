import React, { FC } from 'react';

import TextField from '@material-ui/core/TextField';

import { InputFieldProps } from './InputField.props';
import { useStyles } from './InputField.styles';

const InputFieldView: FC<InputFieldProps> = (props: InputFieldProps) => {
  const {
    id,
    placeholder,
    shrink,
    label,
    type,
    onChange,
    value,
    error,
    className,
    onFocus,
    onBlur,
    inputProps,
    disabled,
    startAdornment,
    endAdornment,
  } = props;

  const classes = useStyles(props);

  return (
    <TextField
      id={id}
      placeholder={placeholder}
      label={label}
      InputProps={{
        className: className ? `${className} ${classes.input}` : classes.input,
        classes: { input: classes.inputRoot, disabled: classes.disabled },
        disableUnderline: true,
        startAdornment,
        endAdornment,
      }}
      InputLabelProps={{
        className: classes.inputLabel,
        shrink,
      }}
      inputProps={inputProps}
      onChange={onChange}
      value={value}
      type={type || 'text'}
      fullWidth
      helperText={error}
      FormHelperTextProps={{
        classes: {
          root: classes.error,
        },
        error: true,
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
};

export default React.memo(InputFieldView);
