import React, { FC } from 'react';

import { LoaderPublicProps, LoaderProps } from './Loader.props';
import LoaderView from './Loader.view';

const LoaderContainer: FC<LoaderPublicProps> = (
  publicProps: LoaderPublicProps
) => {
  const combinedProps: LoaderProps = {
    ...publicProps,
    // calcedProps
  };

  return <LoaderView {...combinedProps} />;
};

export default LoaderContainer;
