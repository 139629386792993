import React, { FC, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import PhoneInput, {
  getCountryCallingCode,
  isPossiblePhoneNumber,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import {
  Button,
  GhostButton,
  HorizontalLine,
  InputField,
  Link,
  Snackbar,
  Typo,
} from '../../primitives';
import { PersonalInfoProps } from './PersonalInfo.props';
import { useStyles } from './PersonalInfo.styles';
import phone from 'phone';
import { PhoneTemplate } from 'consts';
import { toTitleCase } from 'utils/String';
import { is_valid_postcode, match_postcode } from 'utils/Data';

const transformErrorKey = (key: string) => {
  return key.replace(/([A-Z])/g, ' $1').toLowerCase();
};

const PersonalInfoView: FC<PersonalInfoProps> = ({
  save,
  loading,
  success,
  country,
  errorMessage,
  openErrorSnack,
  goToAccount,
  leftFields,
  rightFields,
  initialValues,
  initialErrors,
}) => {
  const [value, setValue] = useState(initialValues);
  const [error, setError] = useState(initialErrors);
  const [isChanged, setIsChanged] = useState(true);
  const [phoneCountry, setPhoneCountry] = useState<any>(country);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const classes = useStyles();

  const onChange = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { target } = event;
    const { id, value: eventValue } = target;
    setError({ ...error, [id]: '' });

    let val = eventValue; 
    if (id == 'firstName' || id == 'lastName') {

      if (/\d/.test(val)) {
        setError({ ...error, [id]: `${toTitleCase(id)} must not have numeric value`})
      }

      val = toTitleCase(eventValue)
    }

    // if (id == 'postCode') {

    //   val = match_postcode(country, val);
      
    //   if (!is_valid_postcode(country, val))  {
    //     setError({ ...error, [id]: `Invalid postcode.` })
    //   }
    // }

    setValue({ ...value, [id]: val });
    setIsChanged(true);
  };

  const onChangeMobile = (val: string) => {
    const res = phone(val)

    setError({ ...error, phoneNumber: !res.isValid ? 'Choose your country code, input your mobile number, and remove + or 0 from the start' : '' });
    setValue({ ...value, phoneNumber: res.isValid ? res.phoneNumber : (val || '') });
    setIsChanged(true);
  };

  const onSave = () => {
    let errorObj = error;
    Object.entries(value).forEach(([key, objValue]) => {
      if (!objValue)
        errorObj = {
          ...errorObj,
          [key]: `Please enter your ${transformErrorKey(key)}`,
        };
      else if (key === 'phoneNumber' && !isPossiblePhoneNumber(objValue))
        errorObj = {
          ...errorObj,
          [key]: `Choose your country code, input your mobile number, and remove + or 0 from the start`,
        };
    });
    setError(errorObj);

    const isFilled = Object.values(value).every((x) => x !== '');

    if (isFilled) {
      setIsChanged(false);
      save(value);
    }
  };

  return (
    <Box>
      <Link onClick={goToAccount}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ArrowBackIcon className={classes.arrow} />
          <Typo variant="h6">Account Settings</Typo>
        </Box>
      </Link>
      <Box mb={isXs ? 4 : 8} />
      <Typo variant={isMobile ? 'h3' : 'h1'} className={classes.header}>
        Personal Info
      </Typo>
      {!isXs && (
        <Box mt={3}>
          <HorizontalLine className={classes.horizontalLine} />
        </Box>
      )}
      <Grid
        container
        spacing={isXs ? 0 : 10}
        className={classes.contentContainer}
      >
        <Grid item xs={12} sm={6}>
          {leftFields.map(({ id, label, type }) => (
            <>
              <Box mb={5}>
                {id !== 'phoneNumber' ? (
                  <InputField
                    id={id}
                    label={label}
                    shrink={true}
                    type={type}
                    // @ts-ignore
                    value={value[id]}
                    // @ts-ignore
                    error={error[id]}
                    onChange={onChange}
                  />
                ) : (
                  <div>
                    <label className={classes.inputLabel} htmlFor={'mobile'}>
                      Mobile
                    </label>
                    <PhoneInput
                      placeholder={phoneCountry ? `Ex: ${PhoneTemplate[phoneCountry as string]}` : undefined}
                      countries={['AU', 'NZ', 'GB']}
                      defaultCountry="AU"
                      country={phoneCountry}
                      onCountryChange={(c) => setPhoneCountry(c)}
                      value={value[id] || ''}
                      onChange={onChangeMobile}
                      className={classes.mobile}
                      numberInputProps={{
                        className: classes.inputMobile,
                        classes: {
                          input: classes.inputRoot,
                          disabled: classes.disabled,
                        },
                      }}
                    />
                    {error[id] ? (
                      <p className={classes.mobileError}>{error[id]}</p>
                    ) : null}
                  </div>
                )}
              </Box>
            </>
          ))}
        </Grid>
        <Grid item xs={12} sm={6}>
          {rightFields.map(({ id, label, type }) => (
            <>
              <Box mb={5}>
                <InputField
                  id={id}
                  label={label}
                  shrink={true}
                  type={type}
                  // @ts-ignore
                  value={value[id]}
                  // @ts-ignore
                  error={error[id]}
                  onChange={onChange}
                />
              </Box>
            </>
          ))}
          {!isXs ? (
            <Box pt={2}>
              <Button
                onClick={onSave}
                variant="h4"
                text="Save"
                loading={loading}
                success={success && !isChanged}
              />
            </Box>
          ) : (
            <Box mt={10}>
              <GhostButton
                text="Save"
                style={classes.ghostButton}
                onClick={onSave}
                loading={loading}
                success={success && !isChanged}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Snackbar message={errorMessage} open={openErrorSnack} type="error" />
    </Box>
  );
};

export default React.memo(PersonalInfoView);
