import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'themeConfig/themeConstants';

export const useStyles = makeStyles((theme: Theme) => {
  const animate = {
    animation: `$myEffect 500ms ease-in`,
  };
  return createStyles({
    '@keyframes myEffect': {
      '0%': {
        opacity: 0,
        transform: 'translateY(50%)',
      },
      '100%': {
        opacity: 1,
        transform: 'translateY(0)',
      },
    },
    mobileContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    card: {
      marginBottom: '5%',
      paddingLeft: '2%',
      paddingRight: '2%',
      ...animate,
    },
    paginationContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    title: {
      alignSelf: 'flex-start',
    },
    animate,
    loadMore: {
      border: '2px solid',
      padding: '10px 20px',
      marginBottom: '20px',
    },
    line: {
      width: '100%',
      backgroundColor: `${colors.primaryDarkGreen} !important`,
      height: 2,
      marginTop: '10%',
    },
    desktopTitle: {
      alignSelf: 'flex-start',
      paddingLeft: '2%',
      marginBottom: 10,
      [theme.breakpoints.up(1440)]: {
        fontSize: 32,
      },
    },
  });
});
