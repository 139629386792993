import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { animated, useTrail } from 'react-spring';

import HouseCard from '../../modules/HouseCard';
import { Typo, GhostButton } from '../../primitives';
import { HandPickedProps } from './HandPicked.props';
import { useStyles } from './HandPicked.styles';

const HandPickedView: FC<HandPickedProps> = (props) => {
  const { content, explore, loading } = props;
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const belowTablet = useMediaQuery('(max-width: 767px)');
  const classes = useStyles();

  const visitCount = Number(localStorage.getItem('visitCount'));

  const trail = useTrail(content.length, {
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    from: { opacity: 0, transform: 'translate3d(0, 50%, 0)' },
  });

  const AnimatedGrid = animated(Grid);

  let mb = '30%';
  if (belowTablet) mb = '17%';
  if (isXs) mb = '0%';

  return (
    <Box mb={mb}>
      {/* <Typo variant="h6" className={classes.title}>
        Next Available
      </Typo> */}
      {loading ? (
        <LinearProgress color="secondary" />
      ) : (
        <Grid container spacing={4}>
          {trail.map((styleProps, index) => (
            <AnimatedGrid key={index} item xs={12} sm={4} style={styleProps}>
              <HouseCard
                {...content[index]}
                isHero={false}
                showCarouselImage={!isSm}
              />
            </AnimatedGrid>
          ))}
        </Grid>
      )}

      {isXs && visitCount < 2 && (
        <Box mt={3}>
          <GhostButton
            text="Explore All Cabins"
            onClick={explore}
            style={classes.ghostButton}
          />
        </Box>
      )}
    </Box>
  );
};

export default React.memo(HandPickedView);
