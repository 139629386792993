import React, { FC, useState } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';

import { GhostButtonProps } from './GhostButton.props';
import { useStyles } from './GhostButton.styles';

const GhostButtonView: FC<GhostButtonProps> = (props: GhostButtonProps) => {
  const {
    text,
    hoverText,
    style,
    buttonStyle,
    onClick,
    iconStyle,
    typographyStyle,
    success = false,
    loading = false,
    disabled = false,
    variant = 'h5',
  } = props;
  const classes = useStyles(props);

  const [onHover, setOnHover] = useState(false);

  let hoverProps = {};
  if (hoverText) {
    hoverProps = {
      onMouseEnter: () => setOnHover(true),
      onMouseLeave: () => setOnHover(false),
    };
  }

  return (
    <Button
      style={buttonStyle}
      className={`${classes.button} ${style}`}
      onClick={onClick}
      disabled={success || loading || disabled}
      {...hoverProps}
    >
      {!loading && !success && (
        <Typography
          variant={variant}
          className={`${classes.typography} ${typographyStyle}`}
        >
          {onHover ? hoverText : text}
        </Typography>
      )}

      {loading && <CircularProgress size={21} color="secondary" />}
      {success && (
        <CheckIcon
          fontSize="small"
          style={iconStyle}
          className={classes.icon}
        />
      )}
    </Button>
  );
};

export default React.memo(GhostButtonView);
