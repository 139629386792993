import { CountryState } from 'types/store/CountryState';
import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'COUNTRY';
const createCountryActions = makeCreateActions(ns);

const countryActions = createCountryActions({
  set: presetActions.makeIdentity<CountryState>(),
  getCities: presetActions.makeAsyncAction(`${ns}/GET_CITIES`),
});

export default countryActions;
