import React, { FC } from 'react';

import { JournalCarouselPublicProps } from './JournalCarousel.props';
import JournalCarouselView from './JournalCarousel.view';

const JournalCarouselContainer: FC<JournalCarouselPublicProps> = (props) => {
  const crossedOutText = 'hotel';

  return (
    <JournalCarouselView
      {...props}
      {...{
        crossedOutText,
      }}
    />
  );
};

export default React.memo(JournalCarouselContainer);
