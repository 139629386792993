import { Dispatch } from 'redux';
import {
  facebookAccessEndpoint,
  getProfileEndpoint,
} from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { authActions, userActions } from 'store/actions';

import { Store } from '../../types/store/Store';
import { User } from '../../types/User';

export interface FacebookCredentials {
  type?: string;
  // eslint-disable-next-line camelcase
  access_token: string;
  email?: string;
  firstName?: string,
  lastName?: string,
  name?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  picture?: any;
  userID: string;
}

interface Data {}

interface Signalling {
  finishUpdating?: (data: any) => void;
  updateErrorMessage?: (message: any) => void;
}

const facebookAccess =
  (credentials: FacebookCredentials, signalling: Signalling) =>
  (dispatch: Dispatch, getState: () => Store) => {
    dispatch(
      authActions.login(
        apiFetch<FacebookCredentials, User>(
          facebookAccessEndpoint,
          credentials
        )(dispatch, getState)
          .then((user) => {
            dispatch(
              userActions.getProfile(
                apiFetch<Data, any>(getProfileEndpoint, {})(
                  dispatch,
                  getState
                ).then((p) => {
                  const openbook_id = p.user?.openbookId || '';
                  const unyoked_id = p.user?.id || ''
                  window.analytics.identify(p.user.openbookId, {
                    email: user.user.email,
                    source: 'Facebook',
                    last_login_date: new Date().toISOString(),
                    
                    // to be remove
                    openbook_id: null,
                    databaseID: null,
                  });
                  window.analytics.track('User Logged In', {
                    email: user.user.email,
                    source: 'Facebook',
                    last_login_date: new Date().toISOString(),

                    // to be remove
                    databaseID: null,
                    openbook_id: null,
                  });
                  return p;
                })
              )
            );
            if (signalling.finishUpdating) {
              signalling.finishUpdating(user);
            }

            user.user.source = 'Facebook';
            return user;
          })
          .catch((err) => {
            console.log(err);
            if (signalling.updateErrorMessage) {
              signalling.updateErrorMessage(err);
            }

            throw err;
          })
      )
    );
  };

export default facebookAccess;
