import React, { FC, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import 'react-phone-number-input/style.css';

import {
  Button,
  Checkbox,
  Collapse,
  GhostButton,
  HorizontalLine,
  Link,
  Snackbar,
  Typo,
} from '../../primitives';
import { PreferenceCentreProps } from './PreferenceCentre.props';
import { useStyles } from './PreferenceCentre.styles';
import { HubspotOption } from 'types/Common';
import { Country } from 'types/Country';
import { colors } from 'themeConfig/themeConstants';

const CountryChoice: FC<{
  country: Country;
  options: HubspotOption[];
  classes: Record<string, any>;
  selected: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ country, options, classes, selected, onChange }) => {
  if (!options || !options.length || !country) return <></>;
  return (
    <Collapse
      isOpen={selected && country.code && selected.startsWith(country.code) || false}
      title={country.name || ''}
      titleNode={(
        <div>
          <Typo component="span" variant={'h6'} color="inherit">
            {country.name || ''}&nbsp; 
          </Typo>
          <Typo component="span" variant={'body3'} color="inherit">
            &nbsp;- choose your closest city
          </Typo>
        </div>
      )}
      color={colors.primaryDarkGreen}
    >
      <Box className={classes.choicesContainer}>
        {options.map((option) => (
          <Box key={option.label}>
            <Checkbox
              id={`${country.code}-${option.value}`}
              label={option.label}
              onChange={onChange}
              checkboxStyle={classes.checkbox}
              checked={selected == `${country.code}-${option.value}`}
            />
          </Box>
        ))}
      </Box>
    </Collapse>
  );
};

const PreferenceCentreView: FC<PreferenceCentreProps> = ({
  save,
  loading,
  success,
  errorMessage,
  openErrorSnack,
  goToAccount,
  newsletterSubscribed,
  homeCities,
  countries,
  cities,
  profile
}) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const classes = useStyles({ isMobile: isXs });

  const [subscribe, setSubscribed] = useState(newsletterSubscribed);

  const [selectedCity, setSelectedCity] = useState<string>(homeCities);

  useEffect(() => {
    setSelectedCity(homeCities);
  }, [homeCities])

  const onSave = () => {
    save({ 
      email: profile.email,
      firstName: profile.firstName,
      lastName: profile.lastName,
      newsletter: subscribe,
      cities: [selectedCity]
    })
  };

  const onCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    setSelectedCity(checked ? id : '')
  };

  return (
    <Box>
      <Link onClick={goToAccount}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ArrowBackIcon className={classes.arrow} />
          <Typo variant="h6">Account Settings</Typo>
        </Box>
      </Link>
      <Box mb={isXs ? 4 : 8} />
      <Typo variant={isMobile ? 'h3' : 'h1'} className={classes.header}>
        Communications Preference
      </Typo>
      {!isXs && (
        <Box mt={3}>
          <HorizontalLine className={classes.horizontalLine} />
        </Box>
      )}
      <Grid
        container
        spacing={isXs ? 0 : 10}
        className={classes.contentContainer}
      >
        <Grid item xs={12} sm={12}>
          {profile && profile.firstName && profile.lastName && (
            <Typo variant="h3">{profile.firstName} {profile.lastName}</Typo>
          )}
          <Typo variant="h4">{profile.email}</Typo>
          <Box mb={3} />
          <Typo variant="h5">Unyoked Newsletter</Typo>
          <Box mb={3} />
          <Checkbox
            id="newsletter"
            label="Weekly insider information on new cabins and exclusive launches. 1-2 emails per week tops."
            isSmall={true}
            onChange={() => setSubscribed(!subscribe)}
            checked={subscribe}
          />
          <Box mb={6} />
          <Typo variant="h5">Unplanned Community Membership</Typo>
          <Box mb={1} />
          <Typo variant="body3">Exclusive access to spontaneous cabin bookings every Wed AM.</Typo>

          {Object.keys(cities).map((country) => {
            if (!countries) return <></>;
            if (Object.keys(countries).includes(country)) {

              const options = cities[country].map((city, i) => ({ 
                label: city.name, 
                value: city.name,
                description: city.name,
                displayOrder: i,
              }));

              return (
                <Box width={'100%'} key={country} mt={4}>
                  <CountryChoice
                    selected={selectedCity}
                    country={countries[country] || {}}
                    options={options}
                    classes={classes}
                    onChange={onCityChange}
                  />
                </Box>
              );
            }
          })}
          
          {!isXs ? (
            <Box pt={10}>
              <Button
                onClick={onSave}
                variant="h4"
                text="Save"
                loading={loading}
                success={success}
              />
            </Box>
          ) : (
            <Box mt={10}>
              <GhostButton
                text="Save"
                style={classes.ghostButton}
                onClick={onSave}
                loading={loading}
                success={success}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Snackbar message={errorMessage} open={openErrorSnack} type="error" />
    </Box>
  );
};

export default React.memo(PreferenceCentreView);
