import React, { FC } from 'react';

import { TermsAndConditionsPublicProps } from './TermsAndConditions.props';
import View from './TermsAndConditions.view';

const TermsAndConditionsContainer: FC<TermsAndConditionsPublicProps> = (
  props
) => {
  return <View {...props} />;
};

export default React.memo(TermsAndConditionsContainer);
