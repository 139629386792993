import React, { FC, useEffect, useRef } from 'react';

import pathOr from 'ramda/src/pathOr';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';

import ROUTES from '../../../routes';
import { authActions, routerActions } from '../../../store/actions';
import authThunks from '../../../thunks/auth';
import { ASYNC_STATUS } from '../../../types/store/AsyncStatus';
import { SecuritySettingsPublicProps } from './SecuritySettings.props';
import View from './SecuritySettings.view';

const SecuritySettingsContainer: FC<SecuritySettingsPublicProps> = (props) => {
  const dispatch = useDispatch();
  const isMounted = useRef(true);

  const status = useSelectorSafe((state) => state.updatePassword.status);

  useEffect(() => {
    if (status !== ASYNC_STATUS.LOADING && isMounted.current)
      dispatch(authActions.resetUpdatePasswordState());
    return () => {
      isMounted.current = false;
    };
  }, []);

  const errors = useSelectorSafe((state) => state.updatePassword.errors);
  const errorMessage =
    status === ASYNC_STATUS.ERROR
      ? pathOr('Something went wrong. Please try again.', ['errors'], errors)
      : '';

  return (
    <View
      {...props}
      updatePassword={(currentPassword, newPassword) =>
        dispatch(authThunks.updatePassword({ currentPassword, newPassword }))
      }
      goToAccount={() => dispatch(routerActions.link(ROUTES.ACCOUNT))}
      success={status === ASYNC_STATUS.COMPLETE}
      loading={status === ASYNC_STATUS.LOADING}
      errorMessage={errorMessage}
      openErrorSnack={!!errorMessage}
    />
  );
};

export default React.memo(SecuritySettingsContainer);
