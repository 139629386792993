import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';
import { pxToRem } from '../../../themeConfig/typography';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: '100%',
      height: 'auto',
      objectFit: 'cover',
      marginBottom: 10,
      // below tablets
      [theme.breakpoints.up(767)]: {
        height: 130,
      },
      [theme.breakpoints.up('md')]: {
        height: 160,
      },
      [theme.breakpoints.up('lg')]: {
        height: 190,
      },
    },
    imageCaption: {
      marginBottom: 10,
    },
    line: {
      backgroundColor: colors.primaryDarkGreen,
      height: 5,
      marginBottom: 15,
    },
    titleArrowContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 26,
      },
    },
    arrow: {
      color: colors.primaryDarkGreen,
      padding: '0 7px 0 10px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    subTitle: {
      fontSize: pxToRem(16),
      marginTop: 10,
      marginBottom: 10,
    },
    desc: {
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': 3,
      [theme.breakpoints.up('md')]: {
        '-webkit-line-clamp': 5,
        width: '70%',
      },
      marginTop: 5,
      marginBottom: 15,
    },

    link: {
      textDecoration: 'none',
    },
  })
);
