import React, { FC, useState, useEffect, useRef } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import MaterialButton from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import ArrowBackwardIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';
import ArrowUpwardIcon from '@material-ui/icons/ExpandLess';
import ArrowDownwardIcon from '@material-ui/icons/ExpandMore';
import { Skeleton } from '@material-ui/lab';
import {
  Button,
  Typo,
  Calendar,
  Checkbox,
  Snackbar,
  Select,
  TextButton,
} from 'components/primitives';
import { addDays, subDays } from 'date-fns';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import {
  triggerProductListFilter,
  useProductTracking,
} from 'hooks/useClickTrack';
import moment from 'moment';
import pathOr from 'ramda/src/pathOr';
import { useDispatch } from 'react-redux';
import entityThunks from 'thunks/entity';
import { Entity } from 'types/Entity';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import useFilters from 'utils/Hooks/useFilters';

import { FILTER } from '../../../consts';
import { colors } from '../../../themeConfig/themeConstants';
import { pxToRem } from '../../../themeConfig/typography';
import {
  getFullyBookedDates,
  isMinimumStayOnEvents,
} from '../../../utils/Data/bookedDates/bookedDates';
import { useCalendar } from '../../primitives/Calendar';
import {
  isMinimumStay,
  getDays,
} from './../../../utils/Data/bookedDates/bookedDates';
import { HomeFilterProps, LocationData } from './HomeFilter.props';
import { useStyles } from './HomeFilter.styles';
import TuneIcon from '@material-ui/icons/Tune';
import theme from 'themeConfig/theme';
import { CabinFilter } from 'types/CabinFilter';
import { FEATURE_FLAGS } from 'consts/featureFlags';

const SELECTION = {
  LOCATION: 'location',
  DATE: 'date',
  GUESTS: 'guests',
  OFFERS: 'offers',
  BEDS: 'beds',
  EMPTY: '',
};

const getIcon = (selected: boolean) =>
  selected ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />;

// @ts-ignore
const ElevationScroll: FC<T> = ({ children, customStyle, trigger }) => {
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    className: trigger ? customStyle.appBarElevated : customStyle.appBarDefault,
  });
};

const HomeFilterView: FC<HomeFilterProps> = ({
  elevationThreshold = 700,
  offers,
  mustHaves,
  handleMustHavesChange,
  goToResults,
  isResultsPage,
  cabinFilter,
  sort,
  updateFilter,
  filterData,
  filter,
  initialLocation,
  isTriggered,
  entities,
  position,
  locations,
  questions,
  openFilterModal,
  featureFlags,
  maintenanceText,
}: HomeFilterProps) => {
  const dispatch = useDispatch();
  const filtersHelper = useFilters();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const threshold = Math.floor(screenHeight / 2);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold,
  });

  const { setSortForTracking } = useProductTracking();

  const containerRef = useRef(null);

  const [fullyBookedDates, setFullyBookedDates] = useState<Date[]>([]);
  const [calendarBookedDates, setCalendarBookedDates] = useState<
    { before: Date; after?: Date }[]
  >([]);
  const [disabledDays, setDisabledDays] =
    useState<{ before: Date; after?: Date }[]>();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [overAllTwoNightsMinimum, setOverAllTwoNightsMinimum] = useState<
    boolean[]
  >([]);

  const [hidden, setHidden] = useState(false);
  const [selected, setSelected] = useState(SELECTION.EMPTY);
  const [location, setLocation] = useState(
    initialLocation ||
      pathOr(
        {
          latitude: 0,
          longitude: 0,
          address: '',
          country: 'AU',
          cityId: null,
        },
        ['location'],
        filterData
      )
  );

  useEffect(() => {
    function handleResize() {
      setScreenHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setLocation(initialLocation);
  }, [initialLocation.cityId]);

  const { country, address } = location;

  const q = (q: string, key: 'label' | 'description') => {
    return questions && questions[q] && questions[q][key];
  };

  const [openCountries, setOpenCountries] = useState<{
    [key: string]: boolean;
  }>({});

  const [startDate, setStartDate] = useState(
    pathOr('', ['startDate'], filterData)
  );

  const [endDate, setEndDate] = useState(pathOr('', ['endDate'], filterData));
  const [beds, setBeds] = useState(pathOr('', ['beds'], filterData));
  const [guests, setGuests] = useState(pathOr('', ['guests'], filterData));

  const [calendarMonth, setCalendarMonth] = useState<Date>(
    startDate ? parse(startDate, 'MMM dd, yyyy', new Date()) : new Date()
  );

  useEffect(() => {
    // set calendar month as next month if today's day is the last day of the <month></month>
    if (startDate) return;
    const nowDate = moment().format('MMM DD, yyyy');
    const endDate = moment().endOf('month').format('MMM DD, yyyy');
    const isLastDay = nowDate === endDate;
    if (isLastDay) {
      onMonthChange(moment().add(1, 'month').startOf('month').toDate());
    }
  }, []);

  const cities = locations.data?.cities;
  const countries = locations.data?.countries;

  const { primaryDarkGreen, primaryWhite, primaryYellow } = colors;
  const stateColor = trigger || isTriggered ? primaryWhite : primaryWhite;
  const stateBg = trigger || isTriggered ? primaryDarkGreen : 'transparent';
  const stateBorderBg = trigger || isTriggered ? primaryYellow : primaryWhite;

  let endItemColor;
  let endItemBg;
  let endItemBorderBg;
  if (location && startDate && endDate && beds && guests) {
    endItemColor = primaryWhite;
    endItemBg = primaryDarkGreen;
    endItemBorderBg = primaryDarkGreen;
  } else if (trigger || isTriggered) {
    endItemColor = primaryWhite;
    endItemBg = primaryDarkGreen;
    endItemBorderBg = primaryYellow;
  } else {
    endItemColor = primaryWhite;
    endItemBg = 'transparent';
    endItemBorderBg = primaryWhite;
  }

  const classes = useStyles({
    hidden,
    stateColor,
    stateBg,
    stateBorderBg,
    endItemBg,
    endItemBorderBg,
    trigger,
    threshold,
    isMobile,
    hasBanner: featureFlags?.[FEATURE_FLAGS.MAINTENANCE],
    bannerOffset: maintenanceText?.payload?.additionalData?.bannerOffset,
  });

  const arrowStyle = {
    width: 21,
    height: 21,
    color: endItemColor,
  };

  const guestsLimit = Array.from(Array(FILTER.GUESTS_LIMIT).keys()).map((v) =>
    (v + 1).toString()
  );
  const bedsLimit = Array.from(Array(FILTER.BEDS_LIMIT).keys()).map((v) =>
    (v + 1).toString()
  );

  const handleClickOutside = (event: Event) => {
    // @ts-ignore
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      if (selected) setSelected(SELECTION.EMPTY);
    }
  };

  const onFilter = (override: { locationOverride?: LocationData } = {}) => {
    setHidden(false);
    const { locationOverride } = override;

    const filterTemp = {
      location: locationOverride || location,
      startDate,
      endDate,
      beds,
      guests,
      offers: mustHaves,
    };

    isResultsPage &&
    filterData?.location?.address == filterTemp.location.address
      ? filter(filterTemp)
      : goToResults(filterTemp.location.country, filterTemp.location.address);

    updateFilter(filterTemp);

    triggerProductListFilter({ ...filterTemp, ...cabinFilter });
  };

  // useEffect(() => {
  //   if (!entities || entities.status == ASYNC_STATUS.INITIAL) {
  //     dispatch(entityThunks.getForCalendar(new Date(), country));
  //   }
  // }, []);

  useEffect(() => {
    const monthKey = `${country}-${year}-${month}`;
    if (entities && entities.data && !entities.data.entities[monthKey]) {
      dispatch(entityThunks.getForCalendar(calendarMonth, country));
    }
  }, [country]);

  useEffect(() => {
    const date = calendarMonth || new Date();
    const year = date.getFullYear(),
      month = date.getMonth();

    const { country, address } = location;
    let rawEntities: Entity[] = pathOr(
      [],
      ['data', 'entities', `${country}-${year}-${month}`],
      entities
    );

    rawEntities = filtersHelper.getRawEntities({
      entities: rawEntities,
      mustHaves,
      country,
      address: address || '',
    });

    if (!rawEntities?.length) {
      setDisabledDays([{ before: new Date(year, month + 1, 1) }]);
    } else {
      !startDate && setDisabledDays(undefined);

      const overAllTNM = [true, true, true, true, true, true, true];

      const rawBd = rawEntities.map((e: Entity) => ({
        name: e.id,
        twoNightMinimum: pathOr([], ['metadata', 'twoNightMinimum'], e),
        bookings: pathOr([], ['schedule', '0', 'bookings'], e),
        changeOverDays: pathOr([], ['schedule', '0', 'changeOverDays'], e),
      }));

      const entitiesByDates: { [key: string]: string[] } = {};

      setFullyBookedDates(getFullyBookedDates(rawBd, entitiesByDates));

      const start = startDate
        ? format(parse(startDate, 'MMM dd, yyyy', new Date()), 'yyyy-MM-dd')
        : null;

      const rawCBD = rawEntities
        .filter(
          (e: Entity) =>
            !(
              start &&
              entitiesByDates[start] &&
              entitiesByDates[start].indexOf(e.id) < 0
            )
        )
        .map((e: Entity) => {
          const twoNightMinimum = pathOr(
            [],
            ['metadata', 'twoNightMinimum'],
            e
          );
          for (const index in twoNightMinimum) {
            if (!twoNightMinimum[index]) {
              overAllTNM[index] = false;
            }
          }

          return {
            bookings: pathOr([], ['schedule', '0', 'bookings'], e),
            changeOverDays: [],
          };
        });

      setOverAllTwoNightsMinimum(overAllTNM);

      setCalendarBookedDates(
        getFullyBookedDates(rawCBD).map((cbd) => ({
          before: addDays(cbd, 1),
          after: subDays(cbd, 1),
        }))
      );
    }
  }, [location, startDate, mustHaves, entities, calendarMonth]);

  const calculateBookdates = (from: Date) => {
    let diff = -1;
    let closestDate: Date | undefined = undefined;
    for (const date of calendarBookedDates) {
      if (date.after && date.after >= subDays(from, 1)) {
        if (diff < 0) {
          diff = date.after.getTime() - from.getTime();
          closestDate = date.after;
          continue;
        }

        if (date.after.getTime() - from.getTime() < diff) {
          diff = date.after.getTime() - from.getTime();
          closestDate = date.after;
        }
      }
    }

    const disableDay: { after?: Date; before: Date } = { before: from };
    if (closestDate) disableDay.after = addDays(closestDate, 1);

    setDisabledDays([disableDay]);
  };

  useEffect(() => {
    if (startDate && !endDate) {
      calculateBookdates(parse(startDate, 'MMM dd, yyyy', new Date()));
    }
  }, [calendarBookedDates]);

  useEffect(() => {
    if (!trigger) setHidden(false);
  }, [trigger]);

  const onCalendarChange = (
    { from, to }: { from?: Date; to?: Date },
    isOpen = false
  ) => {
    setStartDate(from ? format(from, 'MMM dd, yyyy') : '');
    setEndDate(to ? format(to, 'MMM dd, yyyy') : '');
    setDisabledDays(undefined);
    if (from && to && !isOpen) {
      setSelected(SELECTION.EMPTY);

      isMobile && onFilter();
    }
  };

  const calendarHook = useCalendar(
    onCalendarChange,
    calendarBookedDates,
    undefined,
    (from) => {
      calculateBookdates(from);
      setStartDate(from ? format(from, 'MMM dd, yyyy') : '');
      setEndDate('');
    }
  );

  const onMonthChange = (date: Date) => {
    const year = date.getFullYear(),
      month = date.getMonth();
    const { country } = location;
    const nextMonthKey =
      month < 12
        ? `${country}-${year}-${month + 1}`
        : `${country}-${year + 1}-0`;
    const rawEntities: { [key: string]: Entity[] } = pathOr(
      {},
      ['data', 'entities'],
      entities
    );

    const now = new Date();
    if (date > now) {
      if (rawEntities && entities && entities.status != ASYNC_STATUS.LOADING) {
        if (!rawEntities[`${country}-${year}-${month}`]) {
          dispatch(entityThunks.getForCalendar(date, country));
        } else if (!rawEntities[nextMonthKey]) {
          const nextDate =
            month < 12
              ? new Date(year, month + 1, 1)
              : new Date(year + 1, 0, 1);
          dispatch(entityThunks.getForCalendar(nextDate, country));
        }
      }
    }

    setCalendarMonth(date);
  };

  useEffect(() => {
    // onMonthChange(parse(startDate, 'MMM dd, yyyy', new Date()));
    if (!startDate || !endDate) return;
    onCalendarChange(
      {
        to: parse(endDate, 'MMM dd, yyyy', new Date()),
        from: parse(startDate, 'MMM dd, yyyy', new Date()),
      },
      true
    );
  }, [entities?.status]);

  useEffect(() => {
    if (endDate && overAllTwoNightsMinimum) {
      const parsedSd = parse(startDate, 'MMM dd, yyyy', new Date());
      const parsedEd = parse(endDate, 'MMM dd, yyyy', new Date());

      let event;
      if (
        startDate &&
        endDate &&
        (event = isMinimumStayOnEvents(parsedSd, parsedEd))
      ) {
        setSnackbarMessage(
          `Bookings on ${(event as any).text} must be at least ${
            (event as any).nights
          } nights.`
        );
      } else if (!isMinimumStay(parsedSd, parsedEd, overAllTwoNightsMinimum)) {
        setSnackbarMessage(
          overAllTwoNightsMinimum &&
            (overAllTwoNightsMinimum as boolean[]).filter((o) => o).length == 7
            ? `Please select a minimum of 2 nights.`
            : `Bookings on ${getDays(
                overAllTwoNightsMinimum
              )} must be at least two nights.`
        );
        event = true;
      }

      if (event) {
        setOpenSnackbar(true);
        setStartDate('');
        setEndDate('');
        calendarHook.handleReset();
      }
    }
  }, [endDate]);

  const curYear = new Date().getFullYear();
  const curMonth = new Date().getMonth();

  const year = (calendarMonth || new Date()).getFullYear(),
    month = (calendarMonth || new Date()).getMonth();
  const rawEntities: { [key: string]: Entity[] } = pathOr(
    {},
    ['data', 'entities'],
    entities
  );

  const bookedDates = disabledDays || fullyBookedDates;

  const sortOptions = [
    'Sort: Default',
    'Sort: Alphabetical: A <> Z',
    'Sort: Alphabetical: Z <> A',
    'Sort: Level: Mild <> Extra Spicy',
    'Sort: Level: Extra Spicy <> Mild',
  ].map((v) => ({ label: v, value: `${v.replace('Sort: ', '')}` }));

  const cabinFilterCount = Object.keys(cabinFilter ?? {}).filter(
    (k) =>
      k != 'sort' &&
      cabinFilter &&
      !!cabinFilter[k as keyof CabinFilter] &&
      cabinFilter[k as keyof CabinFilter]?.length
  ).length;

  return (
    <ElevationScroll
      customStyle={classes}
      elevationThreshold={threshold}
      trigger={trigger}
    >
      <AppBar
        position={trigger || isMobile ? 'fixed' : 'absolute'}
        classes={{ root: classes.appBar }}
      >
        <div ref={containerRef} className={classes.container}>
          <Box
            borderBottom={2}
            borderTop={5}
            borderRight={isMobile ? 0 : 1}
            className={
              selected === SELECTION.LOCATION
                ? `${classes.extendedItemContainer}`
                : `${classes.itemContainer}`
            }
          >
            {selected === SELECTION.LOCATION ? (
              <Box
                className={`${classes.itemContent} ${classes.firstItemContainer}`}
                pt={2}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: colors.primaryYellow,
                }}
              >
                <Typo className={classes.textItems2} variant="body3">
                  Country
                </Typo>
                {cities &&
                  countries &&
                  Object.keys(cities).map((code) => (
                    <React.Fragment key={code}>
                      <Grid container>
                        <Grid item sm={10}>
                          <MaterialButton
                            key={code}
                            className={classes.arrowButtons2}
                            onClick={() =>
                              setOpenCountries({
                                ...openCountries,
                                [code]: !openCountries[code],
                              })
                            }
                          >
                            {countries[code].name}
                          </MaterialButton>
                        </Grid>
                        <Grid item sm={2}>
                          <MaterialButton
                            className={classes.arrowButtons2}
                            onClick={() =>
                              setOpenCountries({
                                ...openCountries,
                                [code]: !openCountries[code],
                              })
                            }
                          >
                            {openCountries[code] ? (
                              <ArrowDownwardIcon />
                            ) : (
                              <ArrowUpwardIcon />
                            )}
                          </MaterialButton>
                        </Grid>
                      </Grid>
                      {openCountries[code] &&
                        cities[code] &&
                        cities[code].length && (
                          <Box marginLeft={3}>
                            {cities[code].map((city) => (
                              <MaterialButton
                                key={city.id}
                                className={classes.arrowButtons2}
                                onClick={() => {
                                  setLocation({
                                    country: code,
                                    address: city.name,
                                    cityId: city.id,
                                  });
                                  setSelected(SELECTION.EMPTY);
                                  isMobile &&
                                    onFilter({
                                      locationOverride: {
                                        country: code,
                                        address: city.name,
                                        cityId: city.id,
                                      },
                                    });
                                }}
                              >
                                {city.name}
                              </MaterialButton>
                            ))}
                          </Box>
                        )}
                    </React.Fragment>
                  ))}

                {/* <MaterialButton
                  className={classes.arrowButtons}
                  onClick={() => setSelected(SELECTION.EMPTY)}
                >
                  {getIcon(selected === SELECTION.LOCATION)}
                  {location.address ||
                    (countries &&
                      countries[location.country] &&
                      countries[location.country].name) ||
                    'Australia'}
                </MaterialButton> */}
              </Box>
            ) : null}
            <Box
              style={{
                padding: '19px 0px 7px 14px',
                width: isMobile ? '40vw' : '100%',
              }}
            >
              <Typo className={classes.textItems} variant="body3">
                Location
              </Typo>
              <MaterialButton
                className={`${classes.arrowButtons} ${classes.hasValue}`}
                onClick={() =>
                  setSelected(
                    selected === SELECTION.LOCATION
                      ? SELECTION.EMPTY
                      : SELECTION.LOCATION
                  )
                }
              >
                {getIcon(selected === SELECTION.LOCATION)}
                {location.address ??
                  (countries &&
                    countries[location.country] &&
                    countries[location.country].name) ??
                  'Australia'}
              </MaterialButton>
            </Box>
            {/* )} */}
          </Box>

          {/* <Box
            borderLeft={1}
            borderRight={1}
            borderTop={5}
            className={
              selected === SELECTION.OFFERS
                ? classes.extendedItemContainer
                : classes.itemContainer
            }
          >
            {selected === SELECTION.OFFERS ? (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Typo className={classes.textItems} variant="body3">
                  Must Haves
                </Typo>
                <Box
                  className={classes.mustHaveCheckBox}
                  style={{
                    color: stateColor,
                  }}
                  key={0}
                >
                  <Checkbox
                    radius="small"
                    checkboxStyle={classes.mustHaveCheckBox}
                    checked={mustHaves.length == 0 ? true : false}
                    label="No preferences"
                    onChange={(e) => {
                      setSelected(SELECTION.EMPTY);
                      handleMustHavesChange(e);
                    }}
                    value="0"
                  />
                </Box>

                {offers &&
                  offers.map((offer: any, index: number) => (
                    <Box
                      className={classes.mustHaveCheckBox}
                      style={{
                        color: stateColor,
                      }}
                      key={`check-${index}`}
                    >
                      <Checkbox
                        radius="small"
                        checkboxStyle={classes.mustHaveCheckBox}
                        checked={mustHaves.includes(offer.id) ? true : false}
                        label={offer.name}
                        onChange={(e) => {
                          setSelected(SELECTION.EMPTY);
                          handleMustHavesChange(e);
                          setStartDate('');
                          setEndDate('');
                          calendarHook.handleReset();
                        }}
                        value={offer.id}
                      />
                    </Box>
                  ))}

                <MaterialButton
                  className={classes.arrowButtons}
                  onClick={() => setSelected(SELECTION.EMPTY)}
                >
                  <ArrowDownwardIcon />
                </MaterialButton>
              </Box>
            ) : (
              <Box style={{ width: '100%' }}>
                <Typo className={classes.textItems} variant="body3">
                  Must Haves
                </Typo>
                <MaterialButton
                  className={`${classes.arrowButtons} ${
                    offers ? classes.hasValue : {}
                  }`}
                  onClick={() =>
                    setSelected(
                      selected === SELECTION.OFFERS
                        ? SELECTION.EMPTY
                        : SELECTION.OFFERS
                    )
                  }
                >
                  <ArrowUpwardIcon />
                  {mustHaves && mustHaves.length
                    ? `${mustHaves.length} selected`
                    : `No preferences`}
                </MaterialButton>
              </Box>
            )}
          </Box> */}

          <Box
            borderBottom={2}
            borderLeft={isMobile ? 0 : 1}
            borderRight={isMobile ? 0 : 1}
            borderTop={5}
            className={
              selected === SELECTION.DATE
                ? classes.extendedItemContainer
                : classes.itemContainer
            }
          >
            {selected === SELECTION.DATE &&
            new Date(year, month, 1) >= new Date(curYear, curMonth, 1) &&
            (!rawEntities[`${country}-${year}-${month}`] ||
              !rawEntities[`${country}-${year}-${month}`].length) ? (
              <Box
                pt={2}
                pl={2}
                pr={2}
                className={classes.itemContent}
                style={{
                  height: '300px',
                  textAlign: 'center',
                  ...(isMobile ? { top: '75px', left: 0 } : {}),
                }}
              >
                {rawEntities[`${country}-${year}-${month - 1}`] &&
                rawEntities[`${country}-${year}-${month - 1}`].length ? (
                  <Button
                    textStyle={{
                      fontSize: pxToRem(10),
                      color: primaryDarkGreen,
                    }}
                    iconStyle={{ width: 0, height: 0 }}
                    text={'Back to previous month'}
                    hideUnderline={true}
                    onClick={() =>
                      setCalendarMonth(
                        month > 0
                          ? new Date(year, month - 1, 1)
                          : new Date(year - 1, 11, 1)
                      )
                    }
                  />
                ) : null}
                <Skeleton />
                <Skeleton animation={false} />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                Loading {format(calendarMonth, 'MMMM YYY')}
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Box>
            ) : (
              selected === SELECTION.DATE && (
                <Box
                  className={classes.itemContent}
                  pt={2}
                  style={{
                    display: 'flex',
                    minHeight: '300px',
                    flexDirection: 'column',
                    backgroundColor: colors.primaryYellow,
                    ...(isMobile ? { top: '75px', left: 0 } : {}),
                  }}
                >
                  <Typo align="center" variant={'h5'}>
                    {q('booking_questions_date_no_flex', 'label')}
                  </Typo>
                  <Typo
                    align="center"
                    variant="body3"
                    style={{ marginTop: '5px' }}
                  >
                    {q('booking_questions_date_no_flex', 'description')}
                  </Typo>
                  <Calendar
                    month={calendarMonth}
                    bookedDates={bookedDates}
                    transparent={false}
                    hookState={calendarHook}
                    onMonthChange={onMonthChange}
                  />
                </Box>
              )
            )}
            <Box
              style={{
                padding: '14px',
                width: isMobile ? '60vw' : '100%',
                minWidth: '100px',
                ...(selected === SELECTION.DATE
                  ? {
                      paddingTop: '19px',
                      paddingBottom: '7px',
                    }
                  : {
                      paddingBottom: '0px',
                      paddingTop: '12px',
                    }),
              }}
            >
              <Typo className={classes.textItems} variant="body3">
                Dates
              </Typo>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <MaterialButton
                  className={`${classes.arrowButtons} ${
                    endDate ? classes.hasValue : {}
                  }`}
                  onClick={() =>
                    setSelected(
                      selected === SELECTION.DATE
                        ? SELECTION.EMPTY
                        : SELECTION.DATE
                    )
                  }
                >
                  {getIcon(selected === SELECTION.DATE)}
                  {startDate
                    ? `${format(
                        parse(startDate, 'MMM dd, yyyy', new Date()),
                        'MMM dd'
                      )} - ${
                        endDate
                          ? format(
                              parse(endDate, 'MMM dd, yyyy', new Date()),
                              'MMM dd'
                            )
                          : ''
                      }`
                    : null}
                </MaterialButton>
                <IconButton
                  onClick={() => {
                    setStartDate('');
                    setEndDate('');
                    calendarHook.handleReset();
                    setDisabledDays(undefined);
                    setSelected(SELECTION.EMPTY);
                  }}
                  style={{ padding: 0 }}
                >
                  <CloseIcon
                    style={{ width: 24, height: 24, color: stateColor }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>

          {/* <Box
            borderLeft={1}
            borderRight={1}
            borderTop={5}
            className={
              selected === SELECTION.GUESTS
                ? classes.extendedItemContainer
                : classes.itemContainer
            }
          >
            {selected === SELECTION.GUESTS ? (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Typo className={classes.textItems} variant="body3">
                  Guests
                </Typo>
                {guestsLimit.map((v) => (
                  <MaterialButton
                    key={v}
                    className={classes.arrowButtons}
                    onClick={() => {
                      setGuests(v);
                      setSelected(SELECTION.EMPTY);
                    }}
                  >
                    {v}
                  </MaterialButton>
                ))}

                <MaterialButton
                  className={classes.arrowButtons}
                  onClick={() => setSelected(SELECTION.EMPTY)}
                >
                  <ArrowUpwardIcon />{guests}
                </MaterialButton>
              </Box>
            ) : (
              <Box style={{ width: '100%' }}>
                <Typo className={classes.textItems} variant="body3">
                  Guests
                </Typo>
                <MaterialButton
                  className={`${classes.arrowButtons} ${
                    guests ? classes.hasValue : {}
                  }`}
                  onClick={() =>
                    setSelected(
                      selected === SELECTION.GUESTS
                        ? SELECTION.EMPTY
                        : SELECTION.GUESTS
                    )
                  }
                >
                  {getIcon(selected === SELECTION.GUESTS)}{guests}
                </MaterialButton>
              </Box>
            )}
          </Box> */}

          {/* <Box 
            borderLeft={2} 
            borderRight={2} 
            borderTop={5} 
            className={ 
              selected === SELECTION.BEDS 
                ? classes.extendedItemContainer 
                : classes.itemContainer 
            } 
           > 
            {selected === SELECTION.BEDS ? ( 
              <Box 
                style={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  width: '100%', 
                }} 
              > 
                <Typo className={classes.textItems} variant="body3"> 
                  Beds 
                </Typo> 
                {bedsLimit.map(v => ( 
                  <MaterialButton 
                    key={v} 
                    className={classes.arrowButtons} 
                    onClick={() => { 
                      setBeds(v); 
                      setSelected(SELECTION.EMPTY); 
                    }} 
                  > 
                    {v} 
                  </MaterialButton> 
                ))
                <MaterialButton 
                  className={classes.arrowButtons} 
                  onClick={() => setSelected(SELECTION.EMPTY)} 
                > 
                  <ArrowDownwardIcon /> 
                </MaterialButton> 
              </Box> 
            ) : ( 
              <Box style={{ width: '100%' }}> 
                <Typo className={classes.textItems} variant="body3"> 
                  Beds 
                </Typo> 
                <MaterialButton 
                  className={classes.arrowButtons} 
                  onClick={() => 
                    setSelected( 
                      selected === SELECTION.BEDS 
                        ? SELECTION.EMPTY 
                        : SELECTION.BEDS, 
                    ) 
                  } 
                > 
                  {beds || <ArrowUpwardIcon />} 
                </MaterialButton> 
              </Box> 
            )} 
           </Box>  */}

          {!isMobile ? (
            <Box
              className={`${classes.endItemContainer}`}
              borderRight={isResultsPage && (trigger || isTriggered) ? 2 : 0}
              borderTop={5}
              borderLeft={1}
            >
              <Box>
                <Button
                  textStyle={{
                    fontWeight: 'bold',
                    color: endItemColor,
                    lineHeight: 1,
                  }}
                  iconStyle={{
                    ...arrowStyle,
                    display: hidden ? 'none' : 'block',
                  }}
                  variant={'h4'}
                  text={isResultsPage ? 'Show me the cabins' : 'The Remedy'}
                  underlineOnHover={true}
                  onClick={onFilter}
                />
              </Box>
            </Box>
          ) : null}
          {/* {isResultsPage && (trigger || isTriggered) && (
            <Box
              className={classes.arrowContainer}
              style={{
                borderTop: hidden
                  ? `5px solid ${colors.primaryDarkGreen}`
                  : `5px solid ${colors.primaryDarkGreen}`,
              }}
            >
              <IconButton onClick={() => setHidden(!hidden)}>
                {!hidden ? (
                  <CloseIcon
                    style={{ width: 24, height: 24, color: stateColor }}
                  />
                ) : (
                  <ArrowBackwardIcon style={arrowStyle} />
                )}
              </IconButton>
            </Box>
          )} */}

          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={openSnackbar}
            type={'normal'}
            message={snackbarMessage}
            onClose={() => setOpenSnackbar(false)}
          />
        </div>
        <div
          className={classes.container}
          style={{
            background: !trigger ? 'transparent' : undefined,
            width: '100%',
            height: '76px',
            flexDirection: 'row',
          }}
        >
          {!isMobile ? (
            <Box className={classes.contentTitle}>
              <Typo variant={'h3'}>Your Cabins</Typo>
            </Box>
          ) : null}
          <Box
            className={classes.arrowContainer}
            style={{
              width: isMobile ? '100%' : '50%',
              paddingBottom: theme.spacing(trigger ? 1.5 : 0),
              paddingTop: theme.spacing(trigger ? 1 : !isMobile ? 1.5 : 0),
              border: `0px solid`,
              backgroundColor: colors.primaryYellow,
            }}
          >
            <Box className={classes.sortBox}>
              <Select
                id="sort"
                options={sortOptions}
                shrink={true}
                value={cabinFilter?.sort ?? 'Default'}
                onChange={(e) => {
                  sort(e.target.value);
                  setSortForTracking(e.target.value);
                }}
              />
            </Box>

            {!isMobile ? (
              <TextButton
                style={classes.filterButton}
                text={
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '3px 8px',
                    }}
                  >
                    <Typo variant="h5" style={{ lineHeight: 1.5 }}>
                      Filter {cabinFilterCount ? `(${cabinFilterCount})` : ''}
                    </Typo>
                    <Box pl={1} />
                    <TuneIcon />
                  </div>
                }
                onClick={() => {
                  openFilterModal?.(true);
                }}
              ></TextButton>
            ) : null}
          </Box>
        </div>
      </AppBar>
    </ElevationScroll>
  );
};

export default HomeFilterView;
