import React, { FC } from 'react';

import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { JournalBlogCard } from '../../modules';
import { useStyles } from './JournalBlogCards.styles';
import { JournalBlogCardsProps } from './JournalBlogCards.props';

const JournalBlogCardsView: FC<JournalBlogCardsProps> = (props) => {
  const { content, containerStyle } = props;
  // @ts-ignore
  const belowTablet = useMediaQuery('(max-width: 767px)');
  const classes = useStyles();

  if (belowTablet) return null;

  return (
    <Grid
      container
      spacing={8}
      className={`${classes.container} ${containerStyle}`}
    >
      {content.map((item, idx) => (
        <Grid key={`${item.title}-${idx}`} item sm={4}>
          <JournalBlogCard {...item} displayArea={props.displayArea} />
        </Grid>
      ))}
    </Grid>
  );
};

export default React.memo(JournalBlogCardsView);
