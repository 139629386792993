import React, { FC } from 'react';

import { CalendarPublicProps } from './Calendar.props';
import View from './Calendar.view';

const Container: FC<CalendarPublicProps> = (props: CalendarPublicProps) => (
  <View {...props} />
);

export default React.memo(Container);
