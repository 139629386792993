import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      paddingTop: '56.25%' /* 1080 / 1920 = 0.5625 */,
    },
    player: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
    previewContainer: {
      height: '30vh',
    },
    preview: {
      width: '100%',
      height: '100%',
    },
  })
);
