import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'CONTACT';
const createContactActions = makeCreateActions(ns);

const contactActions = createContactActions({
  submit: presetActions.makeAsyncAction(`${ns}/SUBMIT`),
});

export default contactActions;
