import getBlogs from './getBlogs';
import getHomeData from './getHomeData';
import getJournalDescription from './getJournalDescription';
import getJournals from './getJournals';

const homeThunks = {
  getHomeData,
  getBlogs,
  getJournals,
  getJournalDescription,
};

export default homeThunks;
