import React, { FC } from 'react';

import { JournalSubmitFieldPublicProps } from './JournalSubmitField.props';
import View from './JournalSubmitField.view';

const JournalSubmitFieldContainer: FC<JournalSubmitFieldPublicProps> = (
  props
) => <View {...props} />;

export default React.memo(JournalSubmitFieldContainer);
