import React, { FC } from 'react';

import {
  ContentfulRendererPublicProps,
  ContentfulRendererProps,
} from './ContentfulRenderer.props';
import ContentfulRendererView from './ContentfulRenderer.view';

const contentToArray = (content: string) => {
  if (!content) return;
  const contentArray = content.split('\n');
  return contentArray.filter((x) => x);
};

const ContentfulRendererContainer: FC<ContentfulRendererPublicProps> = (
  publicProps: ContentfulRendererPublicProps
) => {
  if (publicProps.forceVariant === 'string') {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: publicProps.content,
        }}
      ></span>
    );
  }

  const combinedProps: ContentfulRendererProps = {
    ...publicProps,
    contentArray: contentToArray(publicProps.content) || [],
  };
  return <ContentfulRendererView {...combinedProps} />;
};

export default ContentfulRendererContainer;
