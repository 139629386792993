import { FilterState } from 'types/store/FilterState';
import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'FILTER';
const createFilterActions = makeCreateActions(ns);

const filterActions = createFilterActions({
  updated: presetActions.makeIdentity<FilterState>(),
  updateBooking: presetActions.makeIdentity<FilterState>(),
  clear: presetActions.noPayload,
});

export default filterActions;
