import React, { FC } from 'react';

import { FieldsCarouselPublicProps } from './FieldsCarousel.props';
import FieldsCarouselView from './FieldsCarousel.view';

const FieldsCarouselContainer: FC<FieldsCarouselPublicProps> = (props) => {
  const crossedOutText = 'hotel';

  return (
    <FieldsCarouselView
      {...props}
      {...{
        crossedOutText,
      }}
    />
  );
};

export default React.memo(FieldsCarouselContainer);
