import { Dispatch } from 'redux';
import { getCategoriesEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { entityActions } from 'store/actions';

import { Store } from '../../types/store/Store';

const getCategories =
  () => (dispatch: Dispatch, getState: () => Store) => {
    dispatch(
      entityActions.getCategories(
        apiFetch<{}, any>(getCategoriesEndpoint, {})(dispatch, getState)
      )
    );
  };

export default getCategories;