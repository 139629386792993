import React, { FC, useEffect } from 'react';

import pathOr from 'ramda/src/pathOr';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { back, LocationState } from 'redux-first-router';
import { routerActions } from 'store/actions';
import useSelectorSafe from 'store/selectors/useSelectorSafe';

import { RouteDef } from '../../../types/RouteDef';
import { AuthGuardOptions, AuthGuardPublicProps } from './AuthGuard.props';

const checkRedirect = (
  dispatch: Dispatch,
  token: string,
  location: LocationState,
  options: AuthGuardOptions
) => {
  const redirectTo: RouteDef | null = pathOr(
    null,
    ['payload', 'redirectTo'],
    location
  );
  const redirectPayload: {} = pathOr(
    {},
    ['payload', 'redirectPayload'],
    location
  );
  const redirectOnLogin: boolean = pathOr(
    false,
    ['payload', 'redirectOnLogin'],
    location
  );

  if (token && redirectTo) {
    const id = pathOr('', ['id'], redirectPayload);
    const name = pathOr('', ['name'], redirectPayload);

    dispatch(
      routerActions.link(
        redirectTo,
        name
          ? {
              name,
              id,
              redirectPayload,
            }
          : redirectPayload
      )
    );
  } else if (token && redirectOnLogin) {
    back();
  } else if (!options.allowAnon && !token && options.redirectAnon) {
    dispatch(routerActions.link(options.redirectAnon));
  } else if (!options.allowAuthed && token && options.redirectAuthed) {
    dispatch(routerActions.link(options.redirectAuthed));
  }
};

const AuthGuardContainer: FC<AuthGuardPublicProps> = (
  ownProps: AuthGuardPublicProps
) => {
  const dispatch = useDispatch();

  const token = useSelectorSafe((state) => state.token) || '';
  const location = useSelectorSafe((state) => state.location);

  useEffect(() => {
    // @ts-ignore
    checkRedirect(dispatch, token, location, ownProps);
  }, [dispatch, token, ownProps]);

  return <div>{ownProps.children}</div>;
};

export default AuthGuardContainer;
