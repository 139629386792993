import React, { Suspense, FC } from 'react';

import Loading from '../Loading';
const LazyComp = (srcRelativeImportPath: string) =>
  React.lazy(
    () => import(`../../../routes/${srcRelativeImportPath}/index.tsx`)
  );

const LazyComponent = (srcRelativeImportPath: string, props: {} = {}) => {
  const Component = LazyComp(srcRelativeImportPath);
  return (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
};
//
export default LazyComponent;
