import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    line: {
      backgroundColor: `${colors.primaryDarkGreen} !important`,
      height: 2,
    },
    vibeImage: {
      height: 'auto',
      background: 'red',
      '& img': {
        width: '100%',
        height: 'auto',
      },
    },
    vibeDescription: {
      '& a': {
        color: colors.primaryDarkGreen,
        '&:hover': {
          background: colors.secondaryYellow,
        },
        '&:visited': {
          color: colors.lightGreen,
        },
      },
    },

    philosophyTitles: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 56,
      },
    },
    vibeTitles: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 30,
      },
    },
    segment: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 32,
      },
    },
  })
);
