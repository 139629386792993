import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'themeConfig/themeConstants';

interface StyleProps {
  color: string;
  isArrowLeft: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: ({ isArrowLeft }: StyleProps) => ({
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: isArrowLeft ? '' : 'space-between',
    }),
    line: ({ color }: StyleProps) => ({
      backgroundColor: color || colors.primaryDarkGreen,
      height: 1,
      marginBottom: theme.spacing(2),
    }),
    fatLine: ({ color }: StyleProps) => ({
      backgroundColor: color || colors.primaryDarkGreen,
      height: '2px',
    }),
    link: {
      color: colors.primaryDarkGreen,
    },
    iconContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '2rem',
    },
  })
);
