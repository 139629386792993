import { JournalDescriptionPayload } from 'types/Journal';
import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'JOURNAL_DESCRIPTION';
const createJournalDescriptionActions = makeCreateActions(ns);

const authActions = createJournalDescriptionActions({
  getJournalDescription:
    presetActions.makeAsyncAction<JournalDescriptionPayload>(
      `${ns}/GET_JOURNAL_DESCRIPTION`
    ),
});

export default authActions;
