import React, { FC } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { SelectProps } from './Select.props';
import { useStyles } from './Select.styles';

const SelectView: FC<SelectProps> = (props: SelectProps) => {
  const {
    id,
    onChange,
    value,
    label,
    placeholder,
    options,
    shrink,
    displayEmpty,
    error,
    disabled,
  } = props;

  const classes = useStyles(props);

  return (
    <FormControl
      className={`${classes.formControl} ${disabled ? classes.disabled : {}}`}
    >
      {label && (
        <InputLabel shrink={shrink} className={classes.inputLabel}>
          {label}
        </InputLabel>
      )}
      <Select
        name={id}
        autoWidth={true}
        classes={{
          root: classes.root,
          icon: classes.icon,
        }}
        MenuProps={{
          classes: {
            paper: classes.paper,
          },
          PopoverClasses: {
            root: classes.popoverRoot
          }
        }}
        value={value}
        // @ts-ignore
        onChange={onChange}
        IconComponent={ExpandMoreIcon}
        displayEmpty={displayEmpty}
        disabled={disabled}
      >
        {placeholder && (
          <MenuItem value="" className={classes.menuItem}>
            {placeholder}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            className={classes.menuItem}
            classes={{
              selected: classes.itemSelected,
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default React.memo(SelectView);
