import { Dispatch } from 'redux';
import { getAllEntitiesEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { entityActions } from 'store/actions';

import { Filter } from '../../types/Filter';
import { Store } from '../../types/store/Store';

const getAllEntities =
  (data: Filter) => (dispatch: Dispatch, getState: () => Store) => {
    dispatch(
      entityActions.get(
        apiFetch<Filter, any>(getAllEntitiesEndpoint, data)(dispatch, getState)
      )
    );
  };

export default getAllEntities;
