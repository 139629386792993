import React, { FC, useState } from 'react';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { pathOr } from 'ramda';

import { parseLinksInText } from '../../../utils/String';
import { HorizontalLine, Typo } from '../../primitives';
import { FAQProps } from './FAQ.props';
import { useStyles } from './FAQ.styles';

const FAQView: FC<FAQProps> = (props) => {
  const { content, faqDataOrder } = props;

  const [isIn, setIsIn] = useState([0]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const addOrRemove = (item: number) => {
    const exists = isIn.includes(item);

    if (exists)
      setIsIn(
        isIn.filter((i) => {
          return i !== item;
        })
      );
    else setIsIn([...isIn, item]);
  };

  // const faqGroups = Object.keys(content);
  const faqGroups = faqDataOrder
    .sort((a, b) => a.order - b.order)
    .map((a) => a.type);

  return (
    <Box>
      {faqGroups.map((group, faqGroupIndex) => {
        const faqGroup = pathOr([], [group.toLowerCase()], content);

        return (
          <Box key={faqGroupIndex} mt={isMobile ? 4 : 8}>
            <Typo variant={'h1'}>{group}</Typo>
            <Box mt={2}>
              <HorizontalLine className={classes.fatLine} />
            </Box>
            {faqGroup.map(({ title, text }, faqIndex) => {
              const exists = isIn.includes(faqIndex);
              const parsedText = parseLinksInText(text, classes.link);
              return (
                <Box key={faqIndex}>
                  <HorizontalLine className={classes.line} />
                  <Box
                    className={classes.headerContainer}
                    onClick={() => addOrRemove(faqIndex)}
                  >
                    <Box mr={0.5}>
                      {exists ? (
                        <ExpandLess color="secondary" />
                      ) : (
                        <ExpandMore color="secondary" />
                      )}
                    </Box>
                    <Typo variant="h4">{title}</Typo>
                  </Box>
                  <ul>
                    <Collapse in={exists}>
                      <Typo
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: parsedText }}
                      />
                    </Collapse>
                  </ul>
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default React.memo(FAQView);
