import React, { FC, ReactElement } from 'react';

import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typo, ImageContainer } from 'components/primitives';
import theme from 'themeConfig/theme';
import { v4 as uuid } from 'uuid';

import { ContentfulRenderer } from '..';
import { ScienceApproachProps } from './ScienceApproach.props';
import { useStyles } from './ScienceApproach.styles';

interface ApprochBlockProps {
  content?: string;
  classes?: Record<string, any>;
}

const ApproachBlock: FC<ApprochBlockProps> = ({ content, classes }) => {
  return (
    <Box>
      <ContentfulRenderer
        containerClass={classes?.textContainer || ''}
        content={content || ''}
      />
    </Box>
  );
};

const AdditionalRenders = ({ content, classes }: ApprochBlockProps) => {
  if (content) {
    return (
      <Box className={classes?.approachContainer}>
        <Typo variant={'h1'}>{content}</Typo>
      </Box>
    );
  }

  return <></>;
};

const ScienceApproachView: FC<ScienceApproachProps> = (
  props: ScienceApproachProps
) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });

  const blockClasses = [
    `${classes.blockContainer} ${classes.unsetHeight}`,
    `${classes.blockContainer}`,
    `${classes.blockContainer} ${classes.mobileInverse}`,
    `${classes.blockContainer}`,
    `${classes.blockContainer} ${classes.yellowBlock} ${classes.unsetHeight}`,
  ];
  const applyTextPadding = [1, 2, 3];

  const { blocks } = props;
  return (
    <Box>
      {blocks.map((elem, idx) => {
        return (
          <Box key={uuid()}>
            <AdditionalRenders content={elem.title} classes={classes} />
            <Box
              key={uuid()}
              className={blockClasses[idx] || `${classes.blockContainer}`}
            >
              <ApproachBlock
                classes={applyTextPadding.includes(idx) ? classes : {}}
                content={elem.leftBlock}
              />
              <ApproachBlock
                classes={applyTextPadding.includes(idx) ? classes : {}}
                content={elem.rightBlock}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ScienceApproachView;
