import React, { FC } from 'react';

import { HorizontalLinePublicProps } from './HorizontalLine.props';
import View from './HorizontalLine.view';

const HorizontalLineContainer: FC<HorizontalLinePublicProps> = (
  props: HorizontalLinePublicProps
) => <View {...props} />;

export default React.memo(HorizontalLineContainer);
