import React, { FC, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MOOD_CATEGORIES } from 'consts/categories';
import { FEATURE_FLAGS } from 'consts/featureFlags';

import { JournalBlogCard } from '../../modules';
import { GhostButton, Select, TextButton, Typo } from '../../primitives';
import JournalBlogCards from '../JournalBlogCards';
import { JournalBlockProps } from './JournalBlock.props';
import { useStyles } from './JournalBlock.styles';

const JournalBlockView: FC<JournalBlockProps> = (props) => {
  const {
    desktopContainerStyle,
    mobileContainerStyle,
    title,
    desc,
    moreButtonText,
    content,
    initJournalDescription,
    featureFlags,
  } = props;
  const belowTablet = useMediaQuery('(max-width: 767px)');
  const classes = useStyles();
  const [viewNumber, setViewNumber] = useState(belowTablet ? 3 : 6);
  const [category, setCategory] = useState(MOOD_CATEGORIES[0].value);
  const [contents, setContents] = useState(content);
  const [description, setDescription] = useState(initJournalDescription);

  const showCategoryFilter =
    (featureFlags && featureFlags[FEATURE_FLAGS.JOURNAL_CATEGORY_FILTER]) ??
    false;

  const onCategoryChange = async (value: string) => {
    setCategory(value);
    const newContents =
      value !== 'All'
        ? content.filter((item) => item?.moodCategory?.includes(value))
        : content;
    const newCount = belowTablet ? 3 : 6;
    setContents(newContents);
    setViewNumber(newCount < newContents.length ? newCount : content.length);
  };

  useEffect(() => {
    setContents(content);
  }, [content]);

  useEffect(() => {
    setDescription(initJournalDescription);
  }, [initJournalDescription]);

  const ViewAll = () => {
    const count = belowTablet ? 3 : 6;
    if (viewNumber + count < contents.length) {
      setViewNumber(viewNumber + count);
    } else {
      setViewNumber(contents.length);
    }
  };

  if (belowTablet) {
    return (
      <Box className={`${mobileContainerStyle}`}>
        {!showCategoryFilter && (
          <Box display="flex">
            <Box mb={2}>
              <Typo variant="h3">{title}</Typo>
              <Typo variant="h4" className={classes.desc}>
                {desc}
              </Typo>
            </Box>
          </Box>
        )}

        {showCategoryFilter && (
          <Box display="flex">
            <Box mb={2}>
              <Typo variant="h3">{description?.category}</Typo>
              <Typo variant="h4" className={classes.desc}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: description?.description || '',
                  }}
                ></span>
              </Typo>
            </Box>
            <Box>
              <Select
                id="category-selector"
                options={MOOD_CATEGORIES}
                onChange={(e) => {
                  onCategoryChange(e.target.value);
                }}
                value={category}
                variant="large"
              />
            </Box>
          </Box>
        )}

        {contents.slice(0, viewNumber).map((item, idx) => (
          <Grid key={`${item.title}-${idx}`} item sm={12}>
            <JournalBlogCard {...item} />
          </Grid>
        ))}
        {viewNumber >= contents.length ? null : (
          <GhostButton
            style={classes.moreButton}
            text={moreButtonText}
            onClick={ViewAll}
          />
        )}
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Box className={`${classes.topContainer} ${desktopContainerStyle}`}>
        {!showCategoryFilter && (
          <Box display="flex">
            <Box mb={2}>
              <Typo variant="h3">{title}</Typo>
              <Typo variant="h4" className={classes.desc}>
                {desc}
              </Typo>
            </Box>
          </Box>
        )}
        {showCategoryFilter && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              gap: '30%',
            }}
          >
            <Box mb={1} flex="2">
              <Typo variant="h2" className={classes.title}>
                {description?.category}
              </Typo>
              <Typo variant="h4" className={classes.desc}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: description?.description || '',
                  }}
                ></span>
              </Typo>
            </Box>
            <Box flex="1">
              <Select
                id="category-selector"
                shrink={true}
                options={MOOD_CATEGORIES}
                onChange={(e) => {
                  onCategoryChange(e.target.value);
                }}
                value={category}
                variant="large"
              />
            </Box>
          </Box>
        )}
      </Box>

      <Box className={`${classes.bottomContainer} ${desktopContainerStyle}`}>
        <JournalBlogCards content={contents.slice(0, viewNumber)} />

        {belowTablet || viewNumber >= contents.length ? null : (
          <Grid
            item
            xs={12}
            md={12}
            style={{
              textAlign: 'right',
              alignSelf: 'flex-end',
            }}
          >
            <TextButton
              style={classes.seeMore}
              color="primaryWhite"
              text={moreButtonText}
              onClick={ViewAll}
            />
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(JournalBlockView);
