import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import partial from 'ramda/src/partial';

import { BlogHeaderImage } from '../../../assets/images';
import { Typo, HorizontalLine, ImageContainer } from '../../primitives';
import { BlogFeaturedBannerProps } from './BlogFeaturedBanner.props';
import { useStyles } from './BlogFeaturedBanner.styles';

const BlogFeaturedBannerView: FC<BlogFeaturedBannerProps> = (
  props: BlogFeaturedBannerProps
) => {
  const {
    title,
    subtitle = 'to the articles',
    imageCaption,
    image = BlogHeaderImage,
    externalUrl,
    id,
    goToURL,
    onMore,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });

  const fn = externalUrl
    ? partial(goToURL, [externalUrl])
    : partial(onMore, [id]);

  return !isMobile ? (
    <Box className={classes.container}>
      <Box className={classes.imageContainer}>
        <ImageContainer alt="ImageText" className={classes.image} src={image} />
      </Box>
      <Box className={classes.titleContainer}>
        <Typo
          color="primaryWhite"
          variant="body3"
          className={classes.imageCaption}
        >
          {imageCaption}
        </Typo>
        <HorizontalLine className={classes.line} />
        <Typo color="primaryWhite" variant="h4" className={classes.title}>
          {title}
        </Typo>
        <Box className={classes.subtitleArrowContainer}>
          <Typo color="primaryWhite" variant="h5" className={classes.subTitle}>
            {subtitle}
          </Typo>
          <IconButton edge="end" className={classes.arrow} onClick={fn}>
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box />
  );
};

export default BlogFeaturedBannerView;
