import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ImageContainer, Typo } from '../../primitives';
import { HowItWorksProps } from './HowItWorks.props';
import { useStyles } from './HowItWorks.styles';

const HowItWorksView: FC<HowItWorksProps> = (props: HowItWorksProps) => {
  const {
    title = 'How it works',
    subtitle,
    direction = 'row',
    steps,
    textColor = 'primary',
    image,
    caption,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles({ isMobile });

  return (
    <Grid
      container
      alignItems="center"
      direction={direction}
      spacing={isXs ? 0 : 6}
    >
      <Grid item xs={12} sm={6}>
        <Typo
          variant={isMobile ? 'h6' : 'h3'}
          color={textColor}
          className={classes.title}
        >
          {title}
        </Typo>
        {subtitle && (
          <>
            <Box mb={2} />
            <Typo variant={isMobile ? 'body3' : 'body1'} color={textColor}>
              {subtitle}
            </Typo>
          </>
        )}
        <Box mb={isMobile ? 3 : 6} />
        {steps.map(({ step, description }, index) => (
          <Box key={index} mb={4}>
            <Typo
              variant={isMobile ? 'h5' : 'h4'}
              color={textColor}
              className={classes.step}
            >
              {step}
            </Typo>
            <Box mb={2} />
            <Typo variant={isMobile ? 'body3' : 'body1'} color={textColor}>
              {description}
            </Typo>
            {step === '3. Digital or physical' && (
              <Typo
                variant={isMobile ? 'body3' : 'body1'}
                color={textColor}
                style={{ marginTop: 6 }}
              >
                {
                  'Note: Digital prescriptions can take up to 24 hours, physical prescriptions come via snail mail and can take around 2-5 days depending on your area, and remedy kits usually arrive within 5 - 10 days.'
                }
              </Typo>
            )}
            {step === '3. Digital or physical' && (
              <Typo
                variant={isMobile ? 'body3' : 'body1'}
                color={textColor}
                style={{ marginTop: 6 }}
              >
                {
                  "We don't currently offer express shipping, so please make sure to plan ahead for that lucky someone."
                }
              </Typo>
            )}
          </Box>
        ))}
        {caption && (
          <Box mt={2}>
            <Typo variant="caption1" color={textColor}>
              {caption}
            </Typo>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <ImageContainer
          alt="how-it-works"
          src={image}
          className={classes.image}
        />
      </Grid>
    </Grid>
  );
};

export default HowItWorksView;
