import React, { FC, ReactElement, useState, useLayoutEffect } from 'react';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import HorizontalLine from 'components/primitives/HorizontalLine';
import Typo from 'components/primitives/Typo';
import { contentfulListParser } from 'utils/Data/contentfulParser/contentfulParser';
import { parseLinksInText } from 'utils/String';

import { UniquePagePublicProps } from './UniquePage.props';
import { useStyles } from './UniquePage.styles';

const UniquePageView: FC<UniquePagePublicProps> = (
  props: UniquePagePublicProps
) => {
  const { content, contentHeader } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [isIn, setIsIn] = useState([0]);

  useLayoutEffect(() => {
    const a = document.querySelector('#ot-content');
    const b = document.querySelector('#with-list');
    if (!b || !a) return;
    b.append(a);
    a.style.display = 'block';
  }, []);

  const addOrRemove = (item: number) => {
    const exists = isIn.includes(item);

    if (exists)
      setIsIn(
        isIn.filter((i) => {
          return i !== item;
        })
      );
    else setIsIn([...isIn, item]);
  };

  const pageTitle = contentHeader;
  let sortIdx = 0;
  const orderedContent = content.sort((a, b) => {
    sortIdx++;
    return a.order ? a.order - b.order : sortIdx;
  });

  return (
    <Box mt={isMobile ? 4 : 8}>
      <Typo variant={'h1'}>{pageTitle}</Typo>
      <Box mt={2}>
        <HorizontalLine className={classes.fatLine} />
      </Box>
      {orderedContent.map((block, idx) => {
        const blockData = block;

        const exists = isIn.includes(idx);
        const parsedList = parseLinksInText(blockData.text, classes.link);
        const parsedText = contentfulListParser(parsedList, classes.li);

        const isInfo = block.title === 'Collecting Information from Users';

        return (
          <Box key={idx}>
            <HorizontalLine className={classes.line} />
            <Box
              className={classes.headerContainer}
              onClick={() => addOrRemove(idx)}
            >
              <Box mr={0.5}>
                {exists ? (
                  <ExpandLess color="secondary" />
                ) : (
                  <ExpandMore color="secondary" />
                )}
              </Box>
              <Typo variant="h4">{blockData.title || blockData.group}</Typo>
            </Box>
            <ul>
              <Collapse in={exists}>
                <Typo
                  variant="body2"
                  dangerouslySetInnerHTML={{ __html: parsedText }}
                />
                {isInfo ? <div id="with-list"></div> : null}
              </Collapse>
            </ul>
          </Box>
        );
      })}
    </Box>
  );
};

export default UniquePageView;
