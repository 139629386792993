import { Contentful } from 'types/Contentful';
import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'FAQ';
const createFaqActions = makeCreateActions(ns);

const faqActions = createFaqActions({
  getAll: presetActions.makeAsyncAction<Contentful[]>(`${ns}/GET_ALL`),
});

export default faqActions;
