
import { CabinFilter } from 'types/CabinFilter';
import { Nullable } from 'types/util/Nullable';
import { makeCreateActions, presetActions } from 'utils/Redux';

type CabinFilterState = Nullable<CabinFilter>

const ns = 'CABINFILTER';
const createFilterActions = makeCreateActions(ns);

const cabinFilterActions = createFilterActions({
  updated: presetActions.makeIdentity<CabinFilterState>(),
  clear: presetActions.noPayload,
});

export default cabinFilterActions;
