import { Dispatch } from 'redux';
import { getOffersEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { entityActions } from 'store/actions';

import { Store } from '../../types/store/Store';

const getOffers =
  () => (dispatch: Dispatch, getState: () => Store) => {
    dispatch(
      entityActions.getOffers(
        apiFetch<{}, any>(getOffersEndpoint, {})(dispatch, getState)
      )
    );
  };

export default getOffers;