import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import strikethrough from '../../../assets/svgs/strikethrough.svg';
import { colors } from '../../../themeConfig/themeConstants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      paddingTop: 50,
      paddingLeft: '5%',
      paddingRight: '5%',
      [theme.breakpoints.up('md')]: {
        paddingLeft: '12%',
        paddingRight: '12%',
      },
    },
    title: {
      marginBottom: 50,
    },
    contentContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    flexContainer: {
      flex: '1 0 calc(33.33% - 10px)',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: '5%',
      paddingRight: '5%',
    },
    galleryContainer: {
      width: 'fit-content',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    galleryContainerLast: {
      width: 'fit-content',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'column',
      marginBottom: '2rem',
    },
    responsiveImage: {
      width: '100%',
      height: 'auto',
      objectFit: 'scale-down',
    },
    description: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    },
    imageDesc: {
      fontSize: 16,
      lineHeight: 2,
      whiteSpace: 'pre-line',
    },
  })
);
