import React, { FC, useEffect, useState, useRef, useMemo } from 'react';

import { Modal, Slide, useScrollTrigger } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Close } from '@material-ui/icons';
import Footer from 'components/layouts/Footer';
import HomeFilterMobile from 'components/layouts/HomeFilterMobile';
import {
  AppBar,
  BookingBar,
  BookingModal,
  HomeFilter,
} from 'components/modules';
import { Typo } from 'components/primitives';
import { useDispatch } from 'react-redux';
import ROUTES from 'routes';
import { getContentfulEntry } from 'services/fetch/apiEndpoints';
import { filterActions, routerActions } from 'store/actions';
import { useQueryWrapper } from 'utils/Hooks/useQueryWrapper';

import s from './Main.module.scss';
import { MainProps } from './Main.props';
import FilterModal from 'components/modules/FilterModal';
import useCurrentRoute from 'store/selectors/useCurrentRoute';
import { FEATURE_FLAGS } from 'consts/featureFlags';

// @ts-ignore
const HideOnScroll = (props) => {
  const trigger = useScrollTrigger();

  return props.disabled ? (
    props.children
  ) : (
    <Slide appear={false} direction="down" in={!trigger}>
      {props.children}
    </Slide>
  );
};

export const FriendBuyRibbon: FC<{
  invert?: boolean;
  ribbonText?: string;
  ribbonAction?: () => void;
  ribbonPosition?: string;
}> = ({ invert, ribbonText, ribbonAction, ribbonPosition }) => {
  const dispatch = useDispatch();
  const ribbonRef = useRef<HTMLDivElement>(null);
  const [leftPos, setLeftPos] = useState(-130);
  useEffect(() => {
    if (ribbonRef.current?.offsetWidth) {
      setLeftPos(ribbonRef.current.offsetWidth * -1);
    }
  }, [ribbonRef.current?.offsetWidth]);
  const { data: ribbonData } = useQueryWrapper<string, any>(
    ['friendbuyRibbon'],
    getContentfulEntry,
    '6JmKc7cpj3v6SnWprL3bcC',
    {
      refetchInterval: 3000000,
      staleTime: 3000000,
    }
  );
  const onClick = () => {
    if (ribbonAction) {
      return ribbonAction();
    }
    dispatch(routerActions.link(ROUTES.REFER));
  };
  const ribbonLabel =
    ribbonText || ribbonData?.payload?.leftBlock || 'Get 15% Off';

  return (
    <HideOnScroll>
      <div
        ref={ribbonRef}
        className={`${s.ribbon} ${invert ? s.invert : ''}`}
        style={{ left: leftPos / 2.7, top: ribbonPosition || '40vh' }}
      >
        <Typo
          variant={'body1'}
          onClick={onClick}
          className={`${s.ribbonText}`}
          id="friendbuyoverlay"
        >
          {ribbonLabel}
        </Typo>
      </div>
    </HideOnScroll>
  );
};

const MainView: FC<MainProps> = (props: MainProps) => {
  const {
    hideFilter,
    resultsBar,
    alternateBar,
    appBarProps,
    homeFilterProps,
    isNotStickyAppBar,
    mobileFilterBypass = false,
    setShowMobileFilterBypass,
    hideRibbon,
    // bgColor = colors.primaryYellow,
    invert,
    showRefer,
    featureFlags,
  } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  // const [hideRibbon, setHideRibbon] = useState(initHideRibbon);

  // const filter = useSelectorSafe<FilterState>((state) => state.filter, null);
  const { data: maintenanceText } = useQueryWrapper<string, any>(
    ['maintenanceText'],
    getContentfulEntry,
    '2tbMyO6ahGKwgGlaKozBti',
    {
      refetchInterval: 3000000,
      staleTime: 3000000,
    }
  );

  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const [openFilterModel, setOpenFilterModal] = useState(false);

  useEffect(() => {
    setShowMobileFilter(mobileFilterBypass);
  }, [mobileFilterBypass]);

  const bannerHeight = useMemo(() => {
    const newHeight = isMd
      ? maintenanceText?.payload?.additionalData?.height + 30
      : maintenanceText?.payload?.additionalData?.height;
    localStorage.setItem('banner', newHeight);
    return newHeight;
  }, [isMd]);

  const route = useCurrentRoute();
  const isBannerOpen =
    !props.hideBanner && featureFlags?.[FEATURE_FLAGS.MAINTENANCE];

  return (
    <Box>
      <AppBar
        {...appBarProps}
        hideFilterIcon={hideFilter || route.id != 'RESULTS'}
        hideBanner={props.hideBanner}
        disableAutoHide={
          !alternateBar && !hideFilter && !isNotStickyAppBar && isMobile
        }
        showMobileFilter={() =>
          !hideFilter && alternateBar
            ? setShowMobileFilter(true)
            : setOpenFilterModal(true)
        }
      />
      {isBannerOpen && !props.noMargin ? (
        <Box height={bannerHeight}></Box>
      ) : null}
      {props.children}
      {!hideFilter && isMobile && alternateBar && (
        <HomeFilterMobile
          alternate={alternateBar}
          onClose={() => {
            setShowMobileFilter(false);
            if (setShowMobileFilterBypass) {
              setShowMobileFilterBypass(false);
            }
          }}
          open={showMobileFilter}
        />
      )}
      {hideRibbon || !showRefer ? null : (
        <FriendBuyRibbon
          ribbonText={props.ribbonText}
          ribbonAction={props.ribbonAction}
          ribbonPosition={props.ribbonPosition}
        />
      )}
      {!alternateBar && !hideFilter && !isNotStickyAppBar && (
        <HomeFilter {...homeFilterProps} openFilterModal={setOpenFilterModal} />
      )}
      <BookingModal />
      <FilterModal setOpen={setOpenFilterModal} open={openFilterModel} />
      {alternateBar && !hideFilter && !isMobile && <BookingBar />}
      {/*
        // @ts-ignore */}
      <Footer expand={!hideFilter && !isMobile} />
    </Box>
  );
};

export default MainView;
