import { Dispatch } from 'redux';
import { contactUsEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { commonActions } from 'store/actions';

import { Store } from '../../types/store/Store';

interface AccountCredentials {
  email: string;
  fullName: string;
  message: string;
}

const contact = (credentials: AccountCredentials) => (
  dispatch: Dispatch,
  getState: () => Store
) => {
  dispatch(
    commonActions.contact(
      apiFetch<AccountCredentials, {}>(contactUsEndpoint, credentials)(
        dispatch,
        getState
      )
    )
  );
};

export default contact;
