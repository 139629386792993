import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftText: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    },
    rightText: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 26,
      },
    },
  })
);
