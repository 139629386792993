import React, { FC } from 'react';

import { SnackbarPublicProps } from './Snackbar.props';
import View from './Snackbar.view';

const SnackbarContainer: FC<SnackbarPublicProps> = (props) => (
  <View {...props} />
);

export default React.memo(SnackbarContainer);
