import React, { FC } from 'react';

import { TextButtonPublicProps } from './TextButton.props';
import View from './TextButton.view';

const TextButtonContainer: FC<TextButtonPublicProps> = (
  props: TextButtonPublicProps
) => <View {...props} />;

export default React.memo(TextButtonContainer);
