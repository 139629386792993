import { FC } from 'react';
import React from 'react';
import { AlgoliaSearchPublicProps, AlgoliaSearchProps } from "./AlgoliaSearch.props";
import AlgoliaSearchView from './AlgoliaSearch.view';

const AlgoliaSearchContainer: FC<AlgoliaSearchPublicProps> = (publicProps: AlgoliaSearchPublicProps) => {
  const combinedProps: AlgoliaSearchProps = {
    ...publicProps,
    // calcedProps
  };
  
  return <AlgoliaSearchView {...combinedProps} />
};

export default AlgoliaSearchContainer;
