/* eslint-disable prettier/prettier */
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import moment from 'moment';

import pathOr from 'ramda/src/pathOr';
import pick from 'ramda/src/pick';
import { useDispatch } from 'react-redux';
import ROUTES from 'routes';
import { routerActions, filterActions } from 'store/actions';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { bookingQuestionsState } from 'types/store/BookingQuestionsState';
import { LocationsState } from 'types/store/CountryState';
import { CurrentEntityState } from 'types/store/CurrentEntityState';
import {
  CalendarEntitiesState,
  EntitiesState,
  OffersState,
} from 'types/store/EntitiesState';
import { FilterState } from 'types/store/FilterState';
import { asyncData } from 'utils/Redux';

import useCurrentRoute from '../../../store/selectors/useCurrentRoute';
import entityThunks from '../../../thunks/entity';
import { Entity } from '../../../types/Entity';
import { Filter } from '../../../types/Filter';
import {
  HomeFilterMobilePublicProps,
  HomeFilterMobileProps,
  EntityData,
  EntitiesData,
} from './HomeFilterMobile.props';
import HomeFilterMobileView from './HomeFilterMobile.view';

const entityFallback: CurrentEntityState = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not get entity' },
]);

const entitiesFallback: CalendarEntitiesState = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not get entities' },
]);

const HomeFilterMobileContainer: FC<HomeFilterMobilePublicProps> = (
  publicProps: HomeFilterMobilePublicProps
) => {
  const dispatch = useDispatch();
  const filterData = useSelectorSafe<FilterState>(
    (state) => state.filter,
    null
  );
  const currentRoute = useCurrentRoute();

  const entity: EntityData = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<CurrentEntityState>(
      (state) => state.currentEntity,
      entityFallback
    )
  );
  const currentEntity: Entity | {} = pathOr({}, ['data', 'entity'], entity);

  const [mustHaves, setMustHaves] = useState<string[]>(
    pathOr([], ['offers'], filterData)
  );

  const entities: EntitiesData = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<CalendarEntitiesState>(
      (state) => state.calendarEntities,
      entitiesFallback
    )
  );

  const offers = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<OffersState>(
      (state) => state.offers,
      asyncData(ASYNC_STATUS.ERROR, [{ message: 'Could not get offers' }])
    )
  ).data?.offers;

  const locations = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<LocationsState>(
      (state) => state.locations,
      asyncData(ASYNC_STATUS.ERROR, [{ message: 'Could not get locations' }])
    )
  );

  const query: object = useSelectorSafe<object>(
    (state) => state.location.payload,
    {}
  );

  const countryState = useSelectorSafe((state) => state.country);
  const countryCode =
    filterData?.location?.country ??
    (countryState ? countryState.countryCode : 'AU');

  const id: string = pathOr(pathOr('', ['id'], query), ['id'], currentEntity);
  const name: string = pathOr(
    pathOr('', ['title'], query),
    ['title'],
    currentEntity
  );
  const location: string = pathOr(
    filterData?.location?.address ?? '',
    ['location'],
    query
  );
  let country: string = pathOr(countryCode, ['country'], query);
  if (country == 'UK') country = 'GB';

  const cityData = locations?.data?.cities[countryCode].find(
    (x) => x.name === location
  );

  const locationObject = {
    latitude: 0,
    longitude: 0,
    country,
    address: location,
    cityId: cityData?.id || '',
  };

  const nextAvailableStart: Date | null = pathOr(
    null,
    ['nextAvailableStart'],
    query
  );
  const nextAvailableUntil: Date | null = pathOr(
    null,
    ['nextAvailableUntil'],
    query
  );

  useEffect(() => {
    if (
      filterData &&
      filterData.location &&
      filterData.location.cityId != locationObject.cityId
    ) {
      dispatch(
        filterActions.updated({ ...filterData, location: locationObject })
      );
    }
  }, [locationObject]);

  const bookingQuestions = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<bookingQuestionsState>(
      (state) => state.bookingQuestions,
      asyncData(ASYNC_STATUS.ERROR, [
        { message: 'Could not get booking questions' },
      ])
    )
  );

  const handleMustHavesChange = (e: ChangeEvent<HTMLInputElement>) => {
    let mustHavesArr: string[] = [...mustHaves];

    if (e.target.value == '0') {
      mustHavesArr = [];
    } else {
      if (offers && e.target.checked) {
        if (!mustHaves.includes(e.target.value)) {
          let exclude: string[] = [];

          for (const offer of offers) {
            if (offer.opposites) {
              const opIds = offer.opposites.map((o) => o.id);
              if (offer.id == e.target.value) {
                exclude = exclude.concat(opIds);
              } else if (opIds.includes(e.target.value)) {
                exclude.push(offer.id);
              }
            }
          }

          mustHavesArr = [
            ...mustHaves.filter((m) => !exclude.includes(m)),
            e.target.value,
          ];
        }
      } else {
        mustHavesArr = mustHavesArr.filter((i) => i !== e.target.value);
      }
    }
    setMustHaves(mustHavesArr);
  };

  const combinedProps: HomeFilterMobileProps = {
    ...publicProps,
    filterData,
    offers,
    mustHaves,
    handleMustHavesChange,
    questions: bookingQuestions.data?.questions,
    goToResults: (country, location) => {
      dispatch(routerActions.link(ROUTES.RESULTS, { country, location }));
    },
    isResultsPage: Boolean(currentRoute.id === 'RESULTS'),
    updateFilter: (payload: Filter) =>
      dispatch(filterActions.updated({ ...filterData, ...payload })),
    onBook: (startDate: string, endDate: string, guests: string) => {
      if (!currentEntity?.id) return;

      window.analytics.track('Product Added', {
        product_id: currentEntity.id,
        product_name: currentEntity.title,
        product_url: window.location.href,
        product_image_url: currentEntity.metadata.preview,
        product_pricing_weekend: currentEntity.metadata.pricing.weekend,
        product_pricing_weeknights: currentEntity.metadata.pricing.weeknights,
        start_date: moment(startDate).toISOString(),
        end_date: moment(endDate).toISOString(),
        guests: guests,
      });
      dispatch(
        routerActions.link(ROUTES.HOUSE_PAYMENTS, {
          id,
          name,
          data: {
            startDate,
            endDate,
            guests,
          },
        })
      );
    },
    filter: () => {
      if (filterData) {
        dispatch(entityThunks.get(filterData));
      }
    },
    entity,
    entities,
    id,
    // @ts-ignore
    initialLocation: locationObject,
    nextAvailableStart,
    nextAvailableUntil,
    locations,
  };

  return <HomeFilterMobileView {...combinedProps} />;
};

export default HomeFilterMobileContainer;
