import React, { FC } from 'react';

import pathOr from 'ramda/src/pathOr';

import useSelectorSafe from '../../../store/selectors/useSelectorSafe';
import { LocationSearchPublicProps } from './LocationSearch.props';
import View from './LocationSearch.view';

const LocationSearchContainer: FC<LocationSearchPublicProps> = (props) => {
  const query: object = useSelectorSafe<object>(
    (state) => state.location.payload,
    {}
  );
  const location: string = pathOr('', ['location'], query);

  return <View {...props} initialLocation={location} />;
};

export default React.memo(LocationSearchContainer);
