import React, { createContext, useMemo, useState } from 'react';

import { CheckoutFormPublicProps } from 'components/modules/CheckoutForm/CheckoutForm.props';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { checkoutActions } from 'store/actions';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { Entity } from 'types/Entity';
import { User } from 'types/User';
import { getGST } from 'utils/Data/pricing/pricing';

import { changeNameToUrl } from './useClickTrack';
import { format } from 'date-fns';

export const useCheckoutTrack = (
  publicProps: CheckoutFormPublicProps | undefined,
  currentUser: User | undefined,
  currentEntity: Entity | undefined
) => {
  // Tracking stuff
  const trackCheckoutCompleted = () => {
    if (!currentEntity?.id || !currentUser?.openbookId) return;
    window.analytics.track('Checkout Step Completed', {
      product_id: currentEntity.id,
      product_name: currentEntity.title,
      user_id: currentUser.openbookId,
      user_email: currentUser.email,
    });
  };

  const trackPaymentInfoEntered = () => {
    if (!currentEntity?.id || !currentUser?.openbookId) return;
    window.analytics.track('Payment Info Entered', {
      product_id: currentEntity.id,
      product_name: currentEntity.title,
      user_id: currentUser.openbookId,
      user_email: currentUser.email,
    });
  };

  const trackOrderCompleted = (payload: any) => {
    if (publicProps?.prescriptionDetails) {
      const { prescriptionDetails} = publicProps
      window.analytics.track('Prescription_purchased', {
        prescription_name: `${prescriptionDetails.symptom} - ${prescriptionDetails.title}`,
        price: `${prescriptionDetails.price} ${prescriptionDetails.currency}`,
        giftValue: `${prescriptionDetails.giftValue} ${prescriptionDetails.currency}`,
        date: format(Date.now(), 'MMM dd, yyyy'),
        purhaser_name: prescriptionDetails.purchaser.name,
        purchaser_email: prescriptionDetails.purchaser.email,
      })
      return;
    }

    if (!currentEntity?.id || !currentUser?.openbookId) return;

    const booking_checkin = moment(payload.booking.checkin);
    const booking_checkout = moment(payload.booking.checkout);
    try {
      const currency = payload?.currency || 'AUD';
      const tax = getGST(payload.total || 0, currency);
      const revenue = (payload.total || 0) - tax;
      const products = [
        {
          product_id: currentEntity?.id,
          name: currentEntity?.title,
          brand: 'unyoked',
          category: 'Cabin',
          booking_addons: payload.addons,
          image_url: currentEntity?.metadata.locationImage,
          booking_checkin: moment(payload.booking.checkin).toISOString(),
          booking_checkout: moment(payload.booking.checkout).toISOString(),
          booking_stripe_token: payload.token,
          booking_notes: payload.notes,
          price: publicProps?.pricePerNight,
          quantity: 1,
          booking_nights: booking_checkout.diff(booking_checkin, 'days'),
        },
      ];

      const traits = {
        address: payload.form.address,
        city: payload.form.city,
        postcode: payload.form.postCode,
        state: payload.form.state,
      };

      const customerTraits = {
        ...traits,
        first_name: payload.form.firstName,
        last_name: payload.form.lastName,
        mobile: payload.form.mobile,
        timezone: payload.booking.timezone,
      };

      window.analytics.identify(currentUser?.openbookId, {
        traits,
      });
      window.analytics.track('Order Completed', {
        affiliation: 'Unyoked',
        coupon: payload.voucher,
        discount: payload.discount,
        order_id: payload.bookingId,
        user: { ...customerTraits },
        products: {
          ...products,
          url: changeNameToUrl(currentEntity?.title || ''),
        },
        currency,
        revenue: revenue * 0.01,
        shipping: 0,
        subTotal: (payload.total || 0) * 0.01,
        tax,
        total: (payload.total || 0) * 0.01,
      });
      window.friendbuyAPI.push([
        'track',
        'purchase',
        {
          id: payload.bookingId,
          amount: (payload.total || 0) * 0.01,
          currency,
          couponCode: payload.voucher,
          customer: customerTraits,
          products,
        },
      ]);
    } catch (e) {
      console.log('analytics error', e, payload);
    }
  };

  const trackOrderUpdated = (payload: any) => {
    if (!currentEntity?.id || !currentUser?.openbookId) return;
    const traits = {
      address: payload.form.address,
      city: payload.form.city,
      postcode: payload.form.postCode,
      state: payload.form.state,
    };
    try {
      window.analytics.identify(currentUser?.openbookId, {
        traits,
      });
      window.analytics.track('Order Updated', {
        affiliation: 'Unyoked',
        coupon: payload.voucher,
        discount: payload.discount,
        order_id: payload.booking.id,
        user: { ...traits },
        products: [
          {
            booking_addons: payload.addons,
            booking_checkin: moment(payload.booking.checkin).toISOString(),
            booking_checkout: moment(payload.booking.checkout).toISOString(),
            booking_stripe_token: payload.token,
            booking_guests: '2',
            booking_notes: '-',
            product_id: currentEntity.id,
            brand: 'unyoked',
            category: 'Cabin',
            image_url: currentEntity?.metadata.locationImage,
            name: currentEntity?.title,
            price: publicProps?.pricePerNight,
            quantity: 1,
            url: changeNameToUrl(currentEntity?.title || ''),
          },
        ],
        currency: payload?.currency || 'AUD',
        revenue: (payload.total || 0) * 0.01,
        shipping: 0,
        subTotal: (payload.total || 0) * 0.01,
        tax: 0,
        total: (payload.total || 0) * 0.01,
      });
    } catch (e) {
      console.log('analytics error', e, payload);
    }
  };

  const trackBookingFailed = (payload: any, fail_reason: string) => {
    if (
      !currentEntity?.id ||
      !currentUser?.openbookId ||
      fail_reason.length < 15
    )
      return;

    const data = {
      fail_reason,
      user_id: currentUser.openbookId,
      user_email: currentUser.email,
      booking: {
        entity_id: currentEntity.id,
        entity_name: currentEntity.title,
        booking_addons: payload.addons,
        booking_checkin: moment(payload.booking.checkin).toISOString(),
        booking_checkout: moment(payload.booking.checkout).toISOString(),
        booking_stripe_token: payload.token,
        booking_notes: payload.notes,
        price: publicProps?.pricePerNight,
        quantity: 1,
        coupon: payload.voucher,
        discount: payload.discount,
      },
    };
    window.analytics.track('Booking Failed', data);
  };

  return {
    trackCheckoutCompleted,
    trackPaymentInfoEntered,
    trackOrderCompleted,
    trackOrderUpdated,
    trackBookingFailed,
  };
};
