import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'ENTITY';
const createContactActions = makeCreateActions(ns);

const entityActions = createContactActions({
  get: presetActions.makeAsyncAction(`${ns}/GET`),
  getById: presetActions.makeAsyncAction(`${ns}/GET_BY_ID`),
  getForCalendar: presetActions.makeAsyncAction(`${ns}/CALENDAR`),
  getCategories: presetActions.makeAsyncAction(`${ns}/GET_CATEGORIES`),
  getOffers: presetActions.makeAsyncAction(`${ns}/GET_OFFERS`),
  removeEntity: presetActions.noPayload,
  setEntity: presetActions.makeIdentity(),
});

export default entityActions;
