import { makeCreateActions, presetActions } from 'utils/Redux';

import { Common } from '../../types/Common';
import { Profile } from '../../types/Profile';
import { AsyncData } from 'types/store/AsyncData';
import { User } from 'types/User';

const ns = 'USER';
const createUserActions = makeCreateActions(ns);

const userActions = createUserActions({
  setUser: presetActions.makeIdentity<AsyncData<User>>(),
  getProfile: presetActions.makeAsyncAction<Profile>(`${ns}/GET_PROFILE`),
  updateProfile: presetActions.makeAsyncAction<Profile>(`${ns}/UPDATE_PROFILE`),
  getPaymentMethod: presetActions.makeAsyncAction<Common>(
    `${ns}/GET_PAYMENT_METHOD`
  ),
  deletePaymentMethod: presetActions.makeAsyncAction<Common>(
    `${ns}/DELETE_PAYMENT_METHOD`
  ),
  updatePaymentMethod: presetActions.makeAsyncAction<Common>(
    `${ns}/UPDATE_PAYMENT_METHOD`
  ),
  getBooking: presetActions.makeAsyncAction<Common>(`${ns}/GET_BOOKING`),
  getPaymentIntent: presetActions.makeAsyncAction<Common>(
    `${ns}/GET_PAYMENT_INTENT`
  ),
  clearPaymentIntent: presetActions.noPayload,
  setPaymentIntent: presetActions.makeIdentity<{ data: { payload: any } }>(),
  getPaymentAfterpay: presetActions.makeAsyncAction<Common>(
    `${ns}/GET_PAYMENT_AFTERPAY`
  ),
  setPaymentAfterpay: presetActions.makeIdentity<{ data: { payload: any } }>(),
  clearPaymentAfterpay: presetActions.noPayload,
});

export default userActions;
