import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    line: {
      backgroundColor: colors.primaryDarkGreen,
      height: 1,
      marginBottom: theme.spacing(2),
    },
    image: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '65%',
      },
      height: 'auto',
      marginBottom: theme.spacing(0.5),
    },
  })
);
