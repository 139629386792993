import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { BlogItemContent } from '../../modules';
import { HorizontalLine, Typo } from '../../primitives';
import { BlogStoryItemProps } from './BlogStoryItem.props';
import { useStyles } from './BlogStoryItem.styles';

const BlogStoryItemView: FC<BlogStoryItemProps> = (props) => {
  const { data, onShare, onSave, onPrev, onNext, prevText, nextText } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles(props);

  const { contentTitle, contentSubtitle, content } = data;
  return (
    <Grid container direction="column" alignItems="center">
      <Box mb={4}>
        <Typo variant="h1" className={classes.title}>
          {contentTitle}
        </Typo>
      </Box>
      {contentSubtitle &&
        contentSubtitle.map((text) => (
          <Typo key={text} variant="body3">
            {text}
          </Typo>
        ))}
      {!isMobile && (
        <Box mt={5} width="100%">
          <HorizontalLine className={classes.line} />
        </Box>
      )}

      <Box mb={5} mt={5} pl={isMobile ? 0 : 3} pr={isMobile ? 0 : 3}>
        {content && <BlogItemContent content={content} />}
      </Box>

      {/* {!isMobile ? ( */}
      {/*  <Box width="100%"> */}
      {/*    <Box className={classes.bottomNav}> */}
      {/*      <Box className={`${classes.row} ${classes.prev}`} onClick={onPrev}> */}
      {/*        <IconButton className={classes.leftArrow}> */}
      {/*          <ArrowBackIcon /> */}
      {/*        </IconButton> */}
      {/*        <Typo variant="h5">{prevText}</Typo> */}
      {/*      </Box> */}
      {/*      <Box className={classes.row}> */}
      {/*        <Link onClick={onShare}> */}
      {/*          <Typo variant="h5">Share</Typo> */}
      {/*        </Link> */}
      {/*        <Box ml={3} mr={3}> */}
      {/*          <Typo variant="h6">|</Typo> */}
      {/*        </Box> */}
      {/*        <Link onClick={onSave}> */}
      {/*          <Typo variant="h5">Save</Typo> */}
      {/*        </Link> */}
      {/*      </Box> */}
      {/*      <Box className={`${classes.row} ${classes.next}`} onClick={onNext}> */}
      {/*        <Typo variant="h5">{nextText}</Typo> */}
      {/*        <IconButton edge="end" className={classes.rightArrow}> */}
      {/*          <ArrowForwardIcon /> */}
      {/*        </IconButton> */}
      {/*      </Box> */}
      {/*    </Box> */}
      {/*    <HorizontalLine className={classes.line} /> */}
      {/*  </Box> */}
      {/* ) : ( */}
      {/*  <> */}
      {/*    <Grid container spacing={1} justifyContent="space-between"> */}
      {/*      <GhostButton */}
      {/*        text="Share" */}
      {/*        onClick={onShare} */}
      {/*        style={classes.ghostButton} */}
      {/*      /> */}
      {/*      <GhostButton */}
      {/*        text="Save" */}
      {/*        onClick={onSave} */}
      {/*        style={classes.ghostButton} */}
      {/*      /> */}
      {/*    </Grid> */}
      {/*    <Box mb={5} /> */}
      {/*    <Grid container justifyContent="space-between"> */}
      {/*      <Box className={`${classes.row} ${classes.prev}`} onClick={onPrev}> */}
      {/*        <IconButton className={classes.leftArrow}> */}
      {/*          <ArrowBackIcon /> */}
      {/*        </IconButton> */}
      {/*        <Typo variant="h5">{prevText}</Typo> */}
      {/*      </Box> */}
      {/*      <Box className={`${classes.row} ${classes.next}`} onClick={onNext}> */}
      {/*        <Typo variant="h5">{nextText}</Typo> */}
      {/*        <IconButton edge="end" className={classes.rightArrow}> */}
      {/*          <ArrowForwardIcon /> */}
      {/*        </IconButton> */}
      {/*      </Box> */}
      {/*    </Grid> */}
      {/*  </> */}
      {/* )} */}
    </Grid>
  );
};

export default React.memo(BlogStoryItemView);
