import React, { FC, useState, useMemo } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Skeleton } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { colors } from 'themeConfig/themeConstants';
import vouchersThunks from 'thunks/vouchers';

import { VoucherCard } from '../../modules';
import { Select, Typo } from '../../primitives';
import { VouchersLayoutProps } from './VouchersLayout.props';
import { useStyles } from './VouchersLayout.styles';
import { CountryCurrency, CURRENCY } from 'consts/locations';

const availableCountries = ['AU', 'NZ', 'GB'];

const VouchersLayoutView: FC<VouchersLayoutProps> = (props) => {
  const dispatch = useDispatch();
  const {
    vouchers: vouchersData,
    countries,
    initCountry,
    isVouchersLoading,
    subheader,
  } = props;

  const theme = useTheme();
  const belowTablet = useMediaQuery('(max-width: 767px)');
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles();

  const [countryVoucher, setCountryVoucher] = useState(initCountry.countryCode);

  const vouchersGrouped = useMemo(() => {
    return vouchersData.length
      ? availableCountries.reduce((accum, elem) => {
          return {
            ...accum,
            [elem]: vouchersData
              .filter((x) => x.countries[elem])
              .map((x) => ({ ...x, currency: CURRENCY[elem], countryCode: elem })),
            // vouchers.data?.payload.reduce((accum, elem) => {
          };
        }, {})
      : {};
  }, [vouchersData]);
  console.log('sss',vouchersGrouped)

  const vouchers = useMemo(
    () => vouchersGrouped?.[countryVoucher] || [],
    [countryVoucher, vouchersGrouped]
  );
  const onCountryChange = (val: string) => {
    props.setSelectedCountry(val);
    setCountryVoucher(val);
  };

  return (
    <>
      {belowTablet ? (
        <>
          <Box mb={3} className={classes.dropdownContainerMobile}>
            <Typo variant={isXs ? 'h5' : 'h3'}>
              {vouchers.length
                ? 'Gift a prescription in'
                : 'No prescription at the moment'}
            </Typo>

            <Box mt={0.5} ml={0.5} width="fit-content">
              <Select
                id="presc-country-selector"
                options={countries}
                onChange={(e) => {
                  onCountryChange(e.target.value);
                }}
                value={countryVoucher}
                styleVariant={'prescription'}
                variant={'prescriptionMobile'}
                color={colors.primaryDarkGreen}
                background={colors.primaryYellow}
              />
            </Box>
          </Box>
          <Typo variant={'body2'}>{subheader}</Typo>
          <Box mb={8} />
          <Box className={classes.mobileContainer}>
            {vouchers.map((item) => (
              <Box key={item.id} mb={8}>
                <VoucherCard {...item} />
              </Box>
            ))}
          </Box>
        </>
      ) : (
        <>
          <Box className={classes.dropdownContainer}>
            <Typo variant="h3" className={classes.title}>
              {vouchers.length
                ? 'Gift a prescription in'
                : 'No prescription at the moment in'}
            </Typo>
            <Box>
              <Select
                id="presc-country-selector"
                options={countries}
                onChange={(e) => {
                  onCountryChange(e.target.value);
                }}
                value={countryVoucher}
                styleVariant={'prescription'}
                variant={'prescription'}
                color={colors.primaryDarkGreen}
                background={colors.primaryYellow}
              />
            </Box>
          </Box>
          <Typo variant={'body2'} className={classes.subheader}>
            {subheader}
          </Typo>
          <Box mb={isVouchersLoading ? 4 : 8} />
          <Grid container>
            {isVouchersLoading ? (
              <Box width={'100%'}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Box>
            ) : (
              vouchers.map((item, index) => {
                return (
                  <Grid key={item.id} item sm={4} className={classes.card}>
                    <VoucherCard {...item} />
                  </Grid>
                );
              })
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default React.memo(VouchersLayoutView);
