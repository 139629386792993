import React, { FC, Fragment, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TrackVisibility from 'react-on-screen';
import { useSpring, animated } from 'react-spring';
import Link from 'redux-first-router-link';

import { useWindowSize } from '../../../utils/Hooks';
import { Typo, Carousel, GhostButton, ImageContainer } from '../../primitives';
import { GalleryLandscape } from './GalleryLandscape.props';
import { useStyles } from './GalleryLandscape.styles';

const GalleryLandscapeView: FC<GalleryLandscapeProps> = (props) => {
  const { title, content } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // wont work in server-side
  const { height = 1024, width = 768 } = useWindowSize();
  const measure = height > width ? height : width;
  let contentHeight = isMobile ? measure / 2 : measure / 1.5;
  // very small phones
  if (measure <= 568) contentHeight = measure / 1.5;

  const classes = useStyles({
    contentHeight,
  });

  return (
    <Box className={classes.container}>
      {title && (
        <Typo variant={'h3'} className={classes.title}>
          {title}
        </Typo>
      )}
      <Box className={classes.contentContainer}>
        <Grid container justifyContent="center" spacing={4}>
          {content.map(({ url, title, description, height, width }, index) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Box className={classes.GalleryContainer}>
                  <ImageContainer
                    alt={title}
                    className={classes.responsiveImage}
                    src={url}
                  />
                </Box>
                <Typo
                  variant="body0"
                  className={classes.imageDesc}
                  dangerouslySetInnerHTML={{ __html: description }}
                ></Typo>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default React.memo(GalleryLandscapeView);
