import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import { TextAreaProps } from './TextArea.props';
import { useStyles } from './TextArea.styles';

const TextAreaView: FC<TextAreaProps> = (props: TextAreaProps) => {
  const {
    id,
    error,
    value,
    placeholder,
    onChange,
    onBlur,
    rows,
    rowsMax,
    disabled,
    className,
  } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.container}>
      <TextareaAutosize
        id={id}
        minRows={rows || 12}
        maxRows={rowsMax || rows || 12}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={`${classes.textArea} ${className} ${
          disabled ? classes.disabled : {}
        }`}
        disabled={disabled}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  );
};

export default React.memo(TextAreaView);
