import React, { FC, Fragment, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TrackVisibility from 'react-on-screen';
import { useSpring, animated } from 'react-spring';
import Link from 'redux-first-router-link';

import { useWindowSize } from '../../../utils/Hooks';
import { Typo, Carousel, GhostButton, ImageContainer } from '../../primitives';
import { Gallery } from './Gallery.props';
import { useStyles } from './Gallery.styles';

const GalleryView: FC<GalleryProps> = (props) => {
  const { content } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // wont work in server-side
  const { height = 1024, width = 768 } = useWindowSize();
  const measure = height > width ? height : width;
  let contentHeight = isMobile ? measure / 2 : measure / 1.5;
  // very small phones
  if (measure <= 568) contentHeight = measure / 1.5;

  const classes = useStyles({
    contentHeight,
  });

  return (
    <Box className={classes.container}>
      <Typo variant={'h3'} className={classes.title}>
        {content.length > 0 ? 'Photo Gallery' : ''}
      </Typo>
      <Box className={classes.contentContainer}>
        <Grid container justifyContent="center" spacing={4}>
          {content.map(({ url, title, description, height, width }, index) => {
            return (
              <Grid key={index} item xs={12} sm={12} md={12}>
                <Box
                  className={
                    index + 1 === content.length
                      ? classes.galleryContainerLast
                      : classes.galleryContainer
                  }
                >
                  <ImageContainer
                    alt={title}
                    className={classes.responsiveImage}
                    src={url}
                  />
                  <Typo
                    variant="body0"
                    className={classes.imageDesc}
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></Typo>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default React.memo(GalleryView);
