import React, { FC, useEffect, useState, useRef } from 'react';

import equals from 'ramda/es/equals';
import pathOr from 'ramda/es/pathOr';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';

import ROUTES from '../../../routes';
import { commonActions, routerActions } from '../../../store/actions';
import userThunks from '../../../thunks/user';
import { ASYNC_STATUS } from '../../../types/store/AsyncStatus';
import { PreferenceCentrePublicProps } from './PreferenceCentre.props';
import View from './PreferenceCentre.view';
import { LocationsState } from 'types/store/CountryState';
import { pick } from 'ramda';
import { asyncData } from 'utils/Redux';
import commonThunks from 'thunks/common';

const PreferenceCentreContainer: FC<PreferenceCentrePublicProps> = (props) => {
  
  const dispatch = useDispatch();
  const isMounted = useRef(true);

  const locations = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<LocationsState>(
      (state) => state.locations,
      asyncData(ASYNC_STATUS.ERROR, [{ message: 'Could not get locations' }])
    )
  );

  const countries = locations?.data?.countries || {};
  const cities = locations?.data?.cities || {};

  const [newsletter, homeCities, profile] = useSelectorSafe((state) => ([
    state.profile?.data?.user?.newsletterSubscribed,
    state.profile?.data?.user?.homeCities,
    {
      email: state.profile?.data?.user?.email,
      firstName: (state.profile?.data?.profile as any)['firstName'],
      lastName: (state.profile?.data?.profile as any)['lastName']
    }
  ])) as [boolean, string, { email: string, firstName: string, lastName: string }];
  const status = useSelectorSafe((state) => state.subscribe.status);

  const onSave = (data: { email: string, firstName: string, lastName: string,  newsletter: boolean, cities: string[]}) => {
    dispatch(commonThunks.subscribe(data));
  };

  useEffect(() => {
    if (status === ASYNC_STATUS.COMPLETE) {
      setTimeout(() =>  dispatch(commonActions.resetSubscribe()) ,3000)
    }
  }, [status])

  console.log({ homeCities })

  return (
    <View
      {...props}
      save={onSave}
      goToAccount={() => dispatch(routerActions.link(ROUTES.ACCOUNT))}
      success={status === ASYNC_STATUS.COMPLETE}
      loading={status === ASYNC_STATUS.LOADING}
      errorMessage=""
      openErrorSnack={false}
      profile={profile}
      newsletterSubscribed={newsletter}
      homeCities={homeCities}
      cities={cities}
      countries={countries}
    />
  );
};

export default React.memo(PreferenceCentreContainer);
