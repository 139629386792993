import { HouseCardPublicProps } from 'components/modules/HouseCard/HouseCard.props';
import { useEffect, useState } from 'react';

export const useClickTrack =
  (event: string) =>
  <T>(params: T) => {
    window.analytics.track(event, params);
  };

export const changeNameToUrl = (name: string) => {
  const replaced = name.toLowerCase().replace(/\s/g, '-');

  return `/${replaced}`;
};

const productsMapper = (product: HouseCardPublicProps, idx: number) => {
  return {
    product_id: product.id,
    product_name: product.name,
    product_pricing_weekend: product.pricing?.weekend,
    product_pricing_weeknights: product.pricing?.weeknights,
    position: idx + 1,
    product_url: changeNameToUrl(product.name ?? ''),
    product_image_url: product.locationImage,
    product_availability: product.active,
  };
};

export const triggerProductListFilter = (filters: Record<string, any>) => {
  const filterData = Object.keys(filters).reduce<
  {
      type: string;
      value: any;
    }[]
  >((accum, elem) => {
    if (['beds', 'offers', 'mustHaves', 'categories', 'cities', 'open', 'guests', 'endDate', 'sort'].includes(elem)) return accum;

    if (!filters[elem] || (Array.isArray(filters[elem]) && !filters[elem].length)) return accum;

    if (elem === 'startDate') {
      return accum.concat({
        type: 'Dates',
        value: `${filters.startDate} - ${filters.endDate}`,
      });
    }

    let type = elem;
    if (elem == 'petFriendly') {
      type = 'bring your four-legged friend';
    } else if (elem == 'adventureLevel') {
      type = 'adventure levels';
    } else if (elem == 'One night only') {
      type = 'Bring your four-legged friend';
    }

    return accum.concat({
      type,
      value: filters[elem] || '',
    });
  }, []);

  console.log({ filters, filterData, len: filterData.length })
  if (!filterData.length) return;
  window.analytics.track('Product List Filtered', {
    list_id: '',
    category: 'cabin_search_results',
    filters: filterData,
  });
}

export const useProductTracking = () => {
  const [products, setProducts] = useState<HouseCardPublicProps[]>([]);
  const [sort, setSort] = useState<string>()
  const [productCounter, setProductCounter] = useState(6);


  useEffect(() => {
    if (!products?.length && !productCounter) return;
    const finalProducts = [...products]
      .splice(0, productCounter)
      .map(productsMapper);

    if (!finalProducts.length) return;
    
    window.analytics.track('Product List Viewed', {
      list_id: '',
      category: 'cabin_search_results',
      products: finalProducts,
    });
  }, [products, productCounter]);

  useEffect(() => {
    if (!sort) return;

    window.analytics.track('Product List Sorted', {
      selection: sort
    });
  }, [sort])

  return {
    setProductsForTracking: setProducts,
    setSortForTracking: setSort,
    setProductCounter,
  };
};
