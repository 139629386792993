import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    line: {
      backgroundColor: colors.primaryDarkGreen,
      height: 2.5,
    },
    title: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    },
  })
);
