import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: '5%',
      paddingBottom: '5%',
    },
  })
);
