import { UnyokedCountry } from 'consts/locations';
import { JournalDescriptionPayload } from 'types/Journal';

import { API } from '../../consts';
import { Filter } from '../../types/Filter';

const { URL, VERSION } = API;

export const methods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const endpointGroups = {
  AUTH: 'auth',
  ENTITY: 'entity',
  USER: 'user',
  CONTENTFUL: 'contentful',
};

export const auth = {
  NOT_REQUIRED: 'NOT_REQUIRED',
  REQUIRED: 'REQUIRED',
  OPTIONAL: 'OPTIONAL',
};

export interface Endpoint {
  url: string;
  method: string;
  auth: string;
  urlTransformer?: (data: any) => string;
}

export const facebookAccessEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/facebook-access`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const googleAccessEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/google-access`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const signupEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/signup`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const loginEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/login`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const logoutEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/logout`,
  method: methods.GET,
  auth: auth.REQUIRED,
};

export const forgotPasswordEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/forgot-password`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const resetPasswordEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/reset-password`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const updatePasswordEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/update-password`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const getProfileEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.USER}/profile`,
  method: methods.GET,
  auth: auth.OPTIONAL,
};

export const updateProfileEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.USER}/profile`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const sendVerificationEmailEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/send-verification-email`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const getHomeDataEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/home`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
  urlTransformer: (id: string) => {
    return `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/home/${id}`;
  },
};

export const getNewsletterContentEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/home`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
};

export const getJournalDescriptionEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/journalDescription`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
  urlTransformer: (type: JournalDescriptionPayload) => {
    return `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/journalDescription/${type}`;
  },
};

export const subscribeEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/subscribe`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const forestrySubscribeEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/subscribe/forestry`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const waitlistEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/waitlist`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const waitlistFormEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/waitlist`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
};

export const contactUsEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/contact`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const getBlogsEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/blogs`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
};

export const getJournalsEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/journals`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
};

export const getJournalByIdEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/getby`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
  urlTransformer: (slug: string) => {
    return `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/getby/${slug}`;
  },
};

export const getPreviewByIdEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/preview`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
  urlTransformer: (id: string) => {
    return `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/preview/${id}`;
  },
};

export const getVouchersEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/prescriptions`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
  urlTransformer: (country: string) => {
    return `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/prescriptions/${country}`;
  },
};

export const getFAQEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/faq`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
};

export const getUniquePageEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/unique-page`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
  urlTransformer: (pageName: string) => {
    return `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/unique-page/${pageName}`;
  },
};

export const getArticleEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/article`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const getArticleProfileEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/profile`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const getAllArticleProfiles: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/profile/all`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
};

export const getRelatedArticlesEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/related-articles`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const getContentfulEntry: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/entry`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
  urlTransformer: (pageName: string) => {
    return `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/entry/${pageName}`;
  },
};

export const getSciencePage: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/science-page`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
};

export const getCitiesEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/city`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
};

export const getCategoriesEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/category`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
};

export const getOffersEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/offer`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
};

export const getProvisionsEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/provisions`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
};

export const getPaymentAfterpayEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/payment/afterpay`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const getPaymentIntentEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/payment/intent`,
  method: methods.POST,
  auth: auth.OPTIONAL,
};

export const getPaymentMethodEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/payment`,
  method: methods.GET,
  auth: auth.REQUIRED,
};

export const deletePaymentMethodEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/payment/delete`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const updatePaymentMethodEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/payment/update`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const getBookingEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/booking`,
  method: methods.GET,
  auth: auth.REQUIRED,
};

export const getBookingDetailsEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/booking/details`,
  method: methods.GET,
  auth: auth.REQUIRED,
  urlTransformer: (id) => {
    return  `${URL}/${VERSION}/booking/details/${id}`
  }
};

export const getAllEntitiesEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.ENTITY}`,
  method: methods.GET,
  auth: auth.OPTIONAL,
  urlTransformer: ({
    location,
    startDate,
    endDate,
    guests,
    beds,
    noexact,
    cities,
    categories,
    offers,
    changeOver,
  }: Filter & { noexact: boolean; changeOver: boolean }) => {
    let url = `${URL}/${VERSION}/${endpointGroups.ENTITY}`;
    if (!(location || startDate || endDate )) return url;
    url += '?';
    const params = [];
    if (location && location.country)
      params.push(`country=${location.country}`);
    if (location && location.address)
      location.cityId && params.push(`city=${location.cityId}`);

    if (location && location.latitude && location.longitude)
      params.push(
        `address=${location.address}&longitude=${location.longitude}&latitude=${location.latitude}`
      );
    if (startDate) params.push(`startDate=${startDate}`);
    if (endDate) params.push(`endDate=${endDate}`);
    if (noexact) params.push(`noexact=true`);
    if (changeOver) params.push(`withChangeOver=true`);
     // params.push(`guests=${3}`);
    // if (beds) params.push(`beds=${beds}`);

    if (cities)
      for (const cityId of cities) cityId && params.push(`cities[]=${cityId}`);
    if (categories)
      for (const catId of categories) params.push(`categories[]=${catId}`);
    if (offers)
      for (const offerId of offers) params.push(`offers[]=${offerId}`);

    return `${url}${params.join('&')}`;
  },
};

export const getEntityByIdEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.ENTITY}`,
  method: methods.GET,
  auth: auth.OPTIONAL,
  urlTransformer: (id: string) => {
    return `${URL}/${VERSION}/${endpointGroups.ENTITY}/${id}`;
  },
};

export const getFeatureFlagsEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/featureFlag`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
};

export const getEntityByNameEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.ENTITY}/getByName`,
  method: methods.GET,
  auth: auth.OPTIONAL,
  urlTransformer: (name: string) => {
    return `${URL}/${VERSION}/${endpointGroups.ENTITY}/getByName/${name}`;
  },
};

export const getEntityDetailsEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.ENTITY}/getDetails`,
  method: methods.GET,
  auth: auth.OPTIONAL,
  urlTransformer: (name: string) => {
    return `${URL}/${VERSION}/${endpointGroups.ENTITY}/getDetails/${name}`;
  },
};

type RedeemVoucherData = {
  code: string;
  cabin: string;
  nights: number;
  price: number;
  userId: string;
  country: UnyokedCountry;
};

export const redeemVoucherEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/voucher/redeem`,
  method: methods.GET,
  auth: auth.OPTIONAL,
  urlTransformer: (data: RedeemVoucherData) => {
    return `${URL}/${VERSION}/voucher/redeem/${encodeURIComponent(
      data.code
    )}?${Object.keys(data)
      .map((key) =>
        key != 'code' ? `${key}=${(data as any)[key]}` : undefined
      )
      .join('&')
      .replace(/&+/, '&')}`;
  },
};

export const getBookingQuestionsEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTENTFUL}/questions`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
};

export const postBookingQuestionsEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/hubspot/booking/questions`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};
