import React, { FC, ReactElement } from 'react';

import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

import Typo from '../Typo';
import { LoaderProps } from './Loader.props';

const LoaderView: FC<LoaderProps> = (props: LoaderProps) => {
  if (props.isLoading) {
    return (
      <Box>
        <Skeleton style={{ height: '70vh' }}></Skeleton>
        <Skeleton></Skeleton>
        <Skeleton></Skeleton>
        <Typo></Typo>
        <Skeleton></Skeleton>
        <Skeleton></Skeleton>
        <Skeleton></Skeleton>
      </Box>
    );
  }

  return <>{props.children}</>;
};

export default LoaderView;
