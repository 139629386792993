import { Dispatch } from 'redux';
import { getJournalDescriptionEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { journalDescriptionActions } from 'store/actions';
import { JournalDescriptionPayload } from 'types/Journal';

import { Store } from '../../types/store/Store';

interface Data {}

const getJournalDescription =
  (payload: string) => (dispatch: Dispatch, getState: () => Store) => {
    dispatch(
      journalDescriptionActions.getJournalDescription(
        apiFetch<Data, any>(getJournalDescriptionEndpoint, payload)(
          dispatch,
          getState
        )
      )
    );
  };

export default getJournalDescription;
