import React, { FC } from 'react';

import { InputFieldPublicProps } from './InputField.props';
import View from './InputField.view';

const InputFieldContainer: FC<InputFieldPublicProps> = (props) => (
  <View {...props} />
);

export default React.memo(InputFieldContainer);
