import { Dispatch } from 'redux';
import {
  updatePaymentMethodEndpoint,
  getPaymentMethodEndpoint,
} from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { userActions } from 'store/actions';

import { Store } from '../../types/store/Store';

interface Data {
  country: string;
  sourceId: string;
  isDefault: boolean;
}

const updatePaymentMethod = (data: Data) => (
  dispatch: Dispatch,
  getState: () => Store
) => {
  dispatch(
    userActions.updatePaymentMethod(
      apiFetch<Data, any>(updatePaymentMethodEndpoint, data)(
        dispatch,
        getState
      ).then(() =>
        apiFetch<{}, any>(getPaymentMethodEndpoint, { country: data.country })(dispatch, getState)
      )
    )
  );
};

export default updatePaymentMethod;
