export const fontFamilies = {
  plain: 'Plain, sans-serif, arial',
  pitch: 'Pitch, sans-serif, arial',
};

export const rootFontSizePx = 14;

export const colors = {
  // colors from designs
  primaryYellow: '#F4F3D8',
  secondaryYellow: '#EBCE70',
  primaryDarkGreen: '#06270B',
  secondaryDarkGreen: '#041807',
  secondaryOrange: '#d6824b',
  lightGreen: '#77723B',
  primaryGrey: '#989898',
  primaryWhite: '#FFFEF6',
  primaryRed: '#D2232A',
  primaryOrange: '#FFA000',
  primaryPink: '#E8DECE',
};
