import { Dispatch } from 'redux';
import { getBlogsEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { blogActions } from 'store/actions';

import { Store } from '../../types/store/Store';

interface Data {}

const getBlogs = () => (dispatch: Dispatch, getState: () => Store) => {
  dispatch(
    blogActions.getAll(
      apiFetch<Data, any>(getBlogsEndpoint, {})(dispatch, getState)
    )
  );
};

export default getBlogs;
