import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    left: {
      textAlign: 'left',
    },
    right: {
      textAlign: 'left',
    },
  })
);
