import React, { FC } from 'react';

import ROUTES from 'routes';

import { cabins } from '../../../consts';
import useCurrentRoute from '../../../store/selectors/useCurrentRoute';
import { HouseCardPublicProps } from './HouseCard.props';
import View from './HouseCard.view';

const HouseCardContainer: FC<HouseCardPublicProps> = (props) => {
  const currentRoute = useCurrentRoute();

  const getPayload = (name: string) => {
    //const cabin = cabins.find((c) => c.id === id);
    //const url = cabin ? cabin.url : 'cabin';

    return {
      cabin: changeNameToUrl(name),
      nextAvailableStart: props.nextAvailableStart,
      nextAvailableUntil: props.nextAvailableUntil,
    };
  };

  const changeNameToUrl = (name: string) => {
    const replaced = name.toLowerCase().replace(/\s/g, '-');

    return replaced;
  };

  const trackProductView = (id: string, name: string, imageUrl: string) => {
    window.analytics.track('Product Clicked', {
      product_id: id,
      product_name: name,
      product_image_url: imageUrl,
      product_url: `/${changeNameToUrl(name)}`,
      position: props.position,
      category: 'cabin',
      product_availability: true,
      quantity: 1,
      pricing_weekends: props.pricing?.weekend,
      pricing_weeknights: props.pricing?.weeknights,
      currency: props.currency,
    });
  };

  const slideImages = props.carouselImages?.map((x) => ({ image: x })) || [];
  const showCarouselImage = !!props.carouselImages?.length || props.hideCarouselImage;

  return (
    <View
      {...props}
      slideImages={slideImages}
      showCarouselImage={showCarouselImage}
      trackProductView={trackProductView}
      toMore={(name: string) => {
        return {
          type: 'ROUTER/RESULTS_ITEM',
          payload: {
            name,
            ...getPayload(name),
          },
        };
      }}
      isInPaymentsPage={currentRoute === ROUTES.HOUSE_PAYMENTS}
      isInAccountBookingHistoryPage={
        currentRoute === ROUTES.ACCOUNT_BOOKING_HISTORY
      }
    />
  );
};

export default React.memo(HouseCardContainer);
