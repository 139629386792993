import { Dispatch } from 'redux';
import { postBookingQuestionsEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import hubspotActions from 'store/actions/hubspot';

import { Store } from '../../types/store/Store';

interface BookingQuestionsData {
  email: string;
  city: string;
  categories: string[];
  mustHave: string[];
  guests: number;
  nights: number;
  date: string;
}

const postBookingQuestions = (data: BookingQuestionsData) => 
  (dispatch: Dispatch, getState: () => Store) => {
    dispatch(
      hubspotActions.postBookingQuestions(
        apiFetch<{}, any>(postBookingQuestionsEndpoint, data)(dispatch, getState)
      )
    );
  };

export default postBookingQuestions;