import React, { FC } from 'react';

import ROUTES from '../../../routes';
import useCurrentRoute from '../../../store/selectors/useCurrentRoute';
import { JournalMainPublicProps, JournalMainProps } from './JournalMain.props';
import JournalMainView from './JournalMain.view';

const JournalMainContainer: FC<JournalMainPublicProps> = (
  publicProps: JournalMainPublicProps
) => {
  const visitCount = Number(localStorage.getItem('visitCount'));

  const location = useCurrentRoute();
  const isNotStickyAppBar = location === ROUTES.ROOT && visitCount < 2;

  const combinedProps: JournalMainProps = {
    ...publicProps,
    isNotStickyAppBar,
  };

  return <JournalMainView {...combinedProps} />;
};

export default JournalMainContainer;
