import React, { FC, ReactElement, useState } from 'react';

import { useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { LeftArrow } from 'assets/svgs';
import { GhostButton, ImageContainer } from 'components/primitives';
import Typo from 'components/primitives/Typo';
import Slider, { Settings as SliderSettings } from 'react-slick';
import theme from 'themeConfig/theme';
import { colors } from 'themeConfig/themeConstants';
import { useWindowSize } from 'utils/Hooks';
import { v4 as uuid } from 'uuid';

import ContentfulRenderer from '../ContentfulRenderer';
import {
  ScienceBenefitsProps,
  ImageLinkProps,
  ScienceBenefitsCalcedProps,
} from './ScienceBenefits.props';
import { useStyles } from './ScienceBenefits.styles';

const ImageLink = ({ image, title, classes }: ImageLinkProps) => {
  return (
    <>
      <Box className={classes.benefitImg}>
        <ImageContainer src={image} alt={title} />
      </Box>
      <Typo
        variant={'body2'}
        color="inherit"
        align="center"
        style={{ fontFamily: 'Plain' }}
      >
        {title}
      </Typo>
    </>
  );
};

interface BenefitsCarouselProps extends ScienceBenefitsCalcedProps {
  classes: Record<string, any>;
  windowWidth: number;
  setActiveBenefit?: (idx: number) => void;
  activeBenefit?: number;
}

const CarouselArrow = (props: any) => {
  const { className, style, onClick, clickAction, currentSlide } = props;
  // const onClickOverride = () => {
  //   clickAction(currentSlide);
  //   onClick();
  // };

  return (
    <LeftArrow
      className={className}
      style={{ ...style }}
      onClick={() => onClick()}
    />
  );
};

const sliderConfig: SliderSettings = {
  autoplay: false,
  arrows: true,
  slidesToScroll: 1,
  infinite: true,
};

const BenefitsCarousel = ({
  imageLinks,
  classes,
  windowWidth,
  bodyContent,
}: BenefitsCarouselProps) => {
  if (!windowWidth) return <></>;
  const [activeBenefit, setActiveBenefit] = useState(0);
  const slidesToShow = Math.floor((windowWidth * 0.756) / 200);
  return (
    <>
      <Box className={classes.sliderContainer}>
        <Slider
          {...sliderConfig}
          slidesToShow={slidesToShow > 5 ? 5 : slidesToShow}
          nextArrow={<CarouselArrow />}
          prevArrow={<CarouselArrow />}
          focusOnSelect={true}
          beforeChange={(currentSlide, nextSlide) => {
            setActiveBenefit(nextSlide);
          }}
        >
          {imageLinks.map((imgLink, idx) => (
            <Box key={uuid()} onClick={() => setActiveBenefit(idx)}>
              <Box
                className={`${classes.imageContainer} ${
                  activeBenefit === idx ? 'active' : ''
                }`}
              >
                <ImageLink {...imgLink} classes={classes} />
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>

      <ContentfulRenderer
        content={bodyContent[activeBenefit].body}
        containerClass={classes.approachContainer}
      />
      {!!bodyContent[activeBenefit].studyLink && (
        <Box display="flex" justifyContent={'center'}>
          <GhostButton
            style={classes.readBtn}
            color="primaryYellow"
            hoverColor="primaryGrey"
            text={'Read Study'}
            onClick={() => {
              window.open(bodyContent[activeBenefit].studyLink, '_blank');
            }}
          />
        </Box>
      )}
    </>
  );
};

const ScienceBenefitsView: FC<ScienceBenefitsProps> = (
  props: ScienceBenefitsProps
) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });
  const windowSize = useWindowSize();

  return (
    <Box className={classes.blockContainer} color={colors.primaryYellow}>
      <Typo variant={!isMobile ? 'h1' : 'h3'} color="inherit">
        Benefits
      </Typo>
      <Box marginTop={5}>
        <Typo variant={'body2'} color="inherit" align="center">
          Click to see how an Unyoked nature session can positively impact:
        </Typo>
      </Box>
      <BenefitsCarousel
        {...props}
        classes={classes}
        windowWidth={windowSize.width || 0}
      />
    </Box>
  );
};

export default ScienceBenefitsView;
