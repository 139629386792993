import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    card: {
      marginBottom: '5%',
      paddingLeft: '2%',
      paddingRight: '2%',
    },
    paginationContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    title: {
      alignSelf: 'flex-start',
    },
    desktopTitle: {
      alignSelf: 'flex-start',
      paddingLeft: '2%',
      [theme.breakpoints.up(1440)]: {
        fontSize: 32,
      },
    },
  })
);
