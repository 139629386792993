import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import strikethrough from '../../../assets/svgs/strikethrough.svg';
import { colors } from '../../../themeConfig/themeConstants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
    },
    carousel: {
      height: '100%',
      '& .slick-slide': {},
      '& .slick-arrow': {
        display: 'block',
        position: 'absolute',
        top: '50%',
        height: 33,
        width: 15,
        transform: 'translateY(-50%)',
        zIndex: 5,
      },
      '& .slick-next': {
        right: '2%',
        borderBottom: `2px solid transparent`,
        '&:hover': {
          borderBottom: `2px solid ${colors.primaryPink}`,
        },
      },
      '& .slick-prev': {
        left: '2%',
        borderBottom: '2px solid transparent',
        '&:hover': {
          borderBottom: `2px solid ${colors.primaryPink}`,
        },
      },
    },
    image: {
      minHeight: (props: { contentHeight: number | 'auto' }) =>
        props.contentHeight,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingTop: 50,
      paddingBottom: 50,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: theme.spacing(5),
    },
    bigIcon: {
      width: '100%',
      height: 'auto',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    plainTitle: {
      whiteSpace: 'pre-line',
      marginLeft: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        fontSize: 44,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 66,
      },
    },
    titlePadding: {
      paddingLeft: '10%',
      paddingRight: '10%',
    },
    title: {
      whiteSpace: 'pre-line',
      textAlign: 'center',
      lineHeight: '120%',
      display: '-webkit-box',
      '-webkit-line-clamp': 5,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.up('sm')]: {
        fontSize: 44,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 66,
      },
    },
    inlineTitle: {
      display: 'inline',
    },
    strikethrough: {
      content: '""',
      position: 'absolute',
      top: '45%',
      left: '-5%',
      height: '100%',
      backgroundImage: `url(${strikethrough})`,
      backgroundRepeat: 'no-repeat',
      transform: 'rotate(3deg)',
      '-webkit-transition': '0.4s ease-out',
      transition: '0.4s ease-out',
    },
    caption: {
      textAlign: 'center',
    },
  })
);
