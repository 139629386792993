import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { BlogBackgroundImage } from '../../../assets/images';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: '#F5F5DD',
      [theme.breakpoints.up(1440)]: {
        fontSize: 32,
      },
    },
    description: {
      color: '#F5F5DD',
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    },
    moreButton: {
      maxWidth: 250,
    },
    seeMore: {
      color: '#01290D',
    },
    topContainer: {
      backgroundSize: 'cover',
      paddingTop: '5%',
      paddingBottom: '5%',
    },
    bottomContainer: {
      paddingTop: '5%',
      paddingBottom: '5%',
    },
  })
);
