import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import isEmpty from 'ramda/src/isEmpty';
import { animated, useTrail, useTransition } from 'react-spring';

import { HouseCard } from '../../modules';
import { Typo } from '../../primitives';
import { NoResultsProps } from './NoResults.props';
import { useStyles } from './NoResults.styles';

const NoResultsView: FC<NoResultsProps> = (props) => {
  const { content, nextAvailable, contentTitle, loading } = props;
  const theme = useTheme();
  const belowTablet = useMediaQuery('(max-width: 767px)');
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: isXs ? 1000 : 300,
  });

  const trail1 = useTrail(nextAvailable.length, {
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    from: { opacity: 0, transform: 'translate3d(0, 50%, 0)' },
  });
  const trail2 = useTrail(content.length, {
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    from: { opacity: 0, transform: 'translate3d(0, 50%, 0)' },
    config: {
      duration: 1000,
    },
  });
  const AnimatedContainer = belowTablet ? animated(Box) : animated(Grid);

  if (belowTablet) {
    return (
      <Box className={classes.mobileContainer}>
        {!loading && isEmpty(content) && (
          <Box mb={6}>
            <Typo
              variant="body0"
              dangerouslySetInnerHTML={{
                __html:
                  'Page not found, looks like you\'ve got lost. Head over to <a href="https://www.unyoked.co/" style="color: #06270B; font-weight: 700">unyoked.co</a> to continue your adventure.',
              }}
            ></Typo>
          </Box>
        )}

        {/* {!loading && !isEmpty(nextAvailable) && (
          <>
            <Typo variant={isXs ? 'h5' : 'h3'} className={classes.title}>
              Next Available
            </Typo>
            <Box mb={3} />

            {trail1.map((styleProps, index) => (
              <AnimatedContainer key={index} mb={6} style={styleProps}>
                <HouseCard {...nextAvailable[index]} />
              </AnimatedContainer>
            ))}
          </>
        )} */}

        {!loading && !isEmpty(content) && content.length > 0 && (
          <>
            <Typo variant={isXs ? 'h5' : 'h3'} className={classes.title}>
              {contentTitle}
            </Typo>
            <Box mb={1} />

            {trail2.map((styleProps, index) => (
              <AnimatedContainer key={index} mb={6} style={styleProps}>
                <HouseCard {...content[index]} />
              </AnimatedContainer>
            ))}
          </>
        )}
      </Box>
    );
  }

  return (
    <Grid container>
      {!loading && isEmpty(content) && (
        <Box mb={6}>
          <Typo
            variant="body0"
            dangerouslySetInnerHTML={{
              __html:
                'Page not found, looks like you\'ve got lost. Head over to <a href="https://www.unyoked.co/" style="color: #06270B; font-weight: 700">unyoked.co</a> to continue your adventure.',
            }}
          ></Typo>
        </Box>
      )}

      {/* {!loading && !isEmpty(nextAvailable) && (
        <>
          <Grid item xs={12}>
            <Typo variant={'h3'} className={classes.desktopTitle}>
              Next Available
            </Typo>
            <Box mb={3} />
          </Grid>

          {trail1.map((styleProps, index) => (
            <AnimatedContainer
              key={index}
              item
              sm={4}
              className={classes.card}
              style={styleProps}
            >
              <HouseCard {...nextAvailable[index]} />
            </AnimatedContainer>
          ))}
        </>
      )} */}

      {!loading && !isEmpty(content) && content.length > 0 && (
        <>
          <Grid item xs={12}>
            <Typo variant={'h3'} className={classes.desktopTitle}>
              {contentTitle}
            </Typo>
            <Box mb={1} />
          </Grid>

          {trail2.map((styleProps, index) => (
            <AnimatedContainer
              key={index}
              item
              sm={content[index].isHero ? 12 : 4}
              className={classes.card}
              style={styleProps}
            >
              <HouseCard {...content[index]} />
            </AnimatedContainer>
          ))}
        </>
      )}
    </Grid>
  );
};

export default React.memo(NoResultsView);
