import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({

  btnAfterPay: {
    '& > div': {
      width: '400px !important'
    }
  }
}));
