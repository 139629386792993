import React, { FC } from 'react';

import { GalleryLandscapePublicProps } from './GalleryLandscape.props';
import GalleryLandscapeView from './GalleryLandscape.view';

const GalleryLandscapeContainer: FC<GalleryLandscapePublicProps> = (props) => {
  return <GalleryLandscapeView {...props} />;
};

export default React.memo(GalleryLandscapeContainer);
