import React, { FC, useEffect, useRef, useState } from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import pick from 'ramda/src/pick';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { CommonState, WaitlistFormState } from 'types/store/CommonState';
import { LocationsState } from 'types/store/CountryState';
import { asyncData } from 'utils/Redux';

import commonThunks from '../../../thunks/common';
import {
  WaitlistInputValue,
  WaitlistModalContentPublicProps,
} from './WaitlistModalContent.props';
import View from './WaitlistModalContent.view';
import { routerActions } from 'store/actions';
import ROUTES from 'routes';

const WaitlistModalContentContainer: FC<WaitlistModalContentPublicProps> = (
  props
) => {
  const dispatch = useDispatch();
  const [selectedCities, setSelectedCities] = useState<string[]>();
  const [input, setInput] = useState<WaitlistInputValue>();

  // const waitlistForm: WaitlistData = pick(
  //   ['data', 'status', 'errors'],
  //   useSelectorSafe<WaitlistFormState>((state) => {
  //     return state.waitlistForm;
  //   }, asyncData(ASYNC_STATUS.ERROR, [{ message: 'Could not get waitlist forms' }]))
  // );

  const locations = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<LocationsState>(
      (state) => state.locations,
      asyncData(ASYNC_STATUS.ERROR, [{ message: 'Could not get locations' }])
    )
  );

  const countries = locations?.data?.countries || {};
  const cities = locations?.data?.cities || {};
  const location = useSelectorSafe((state) => state.location, undefined);
  const currentUser = useSelectorSafe(
    (state) => state.user.data?.user,
    undefined
  );

  const isMounted = useRef(true);
  useEffect(() => {
    if (isMounted.current) {
      dispatch(commonThunks.waitlistForm());
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const status = useSelectorSafe((state) => state.waitlist.status);

  const isLoading = status === ASYNC_STATUS.LOADING;
  return (
    <View
      {...props}
      defaultValue={{
        ...props.defaultValue,
        email: currentUser?.email || '',
      }}
      onSubmit={(inputValue, selected: string[]) => {
        setSelectedCities(selected);
        setInput(inputValue);
        if (props.isNewsletter) {
          dispatch(
            commonThunks.subscribe({
              ...pick(['firstName', 'lastName', 'email'], inputValue),
              cities: selected,
              newsletter: true,
            })
          );
        } else {
          dispatch(
            commonThunks.waitlist({
              ...pick(['firstName', 'lastName', 'email'], inputValue),
              location: selected,
            })
          );
        }
      }}
      countries={countries}
      cities={cities}
      success={status === ASYNC_STATUS.COMPLETE}
      loading={isLoading}
    />
  );
};

export default React.memo(WaitlistModalContentContainer);
