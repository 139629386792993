import { Dispatch } from 'redux';
import { getProfileEndpoint, loginEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { authActions, userActions } from 'store/actions';

import { Profile } from '../../types/Profile';
import { Store } from '../../types/store/Store';
import { User } from '../../types/User';

interface Credentials {
  email: string;
  password: string;
}

interface Data {}

const updateProfile = () => (dispatch: Dispatch, getState: () => Store) => {
  dispatch(
    userActions.getProfile(
      apiFetch<Data, Profile>(getProfileEndpoint, {})(dispatch, getState).then(
        (r) => {
          const openbook_id = r?.user?.openbookId || '';
          const unyoked_id = r.user.id;
          window.analytics.identify(openbook_id, {
            email: r.user.email,
            last_login_date: new Date().toISOString(),

            // to be remove
            openbook_id: null,
            databaseID: null,
          });
          return r;
        }
      )
    )
  );
};

export default updateProfile;
