import React, { FC } from 'react';

import { CheckboxPublicProps } from './Checkbox.props';
import View from './Checkbox.view';

const CheckboxContainer: FC<CheckboxPublicProps> = (props) => (
  <View {...props} />
);

export default React.memo(CheckboxContainer);
