import * as React from 'react';

const SpotifyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
    style={{
      enableBackground: 'new 0 0 300 300',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M30.3 157.5v-14.9c.1-.7.3-1.3.4-2 2.1-26 11.4-49.2 28.8-68.5 32.2-35.6 72.4-48.8 119-37.6 63.9 15.4 102.1 79.3 87.1 143.3-10.7 45.8-49.2 82-95.5 89.7-4.3.7-8.7 1.2-13.1 1.8h-14.9c-2.9-.4-5.8-.7-8.7-1.1-23.8-3.4-44.8-12.9-62.7-28.8C50 221.1 37 198.2 32.1 170.9c-.8-4.5-1.2-8.9-1.8-13.4zM129.6 93c-9.8.7-19.7 1-29.5 2.3-9 1.2-18 3.3-26.9 5.5-6.5 1.6-9.8 7.6-8.4 14 1.5 6.7 7.8 10.1 15 8.1 11.7-3.3 23.6-5.1 35.8-5.9 16.6-1.1 33.1-.3 49.4 2.4 16.3 2.6 32.1 6.9 46.9 14.4 2.1 1.1 4.2 2.4 6.3 3.4 7.7 3.8 16.2-1.6 16.1-10.2 0-4.7-1.9-8.4-6.1-10.5-8-3.9-16-8-24.3-11.1-23.8-8.8-48.8-11.8-74.3-12.4zm-2.9 41.6h-6.3c-14 .3-27.6 2.6-41 6.6-5.4 1.6-8 7-6.3 12.4 1.5 5 6.5 7.7 11.9 6.1 11.3-3.3 22.8-5.1 34.6-5.6 22.1-1 43.7 2.1 64.3 10.1 7 2.7 13.6 6.4 20.3 9.8 6.5 3.2 13.5-1.2 13.5-8.4 0-4.5-2.2-7.3-5.9-9.5-26.3-15.3-54.9-21.5-85.1-21.5zm1.5 38.3c-8.9.8-17.8 1.4-26.6 2.5-6.2.8-12.4 2.2-18.5 3.6-3.8.9-5.6 4.1-5.4 8.4.2 3.4 2.6 6.3 6 6.7 1.6.2 3.3 0 4.8-.4 11.5-2.6 23.2-4.2 35-4.6 20.7-.7 40.7 2.6 59.4 11.8 3.1 1.5 5.9 3.4 9 4.9 5.3 2.6 10.9-1 10.9-6.8 0-3.8-2-6.1-5.1-7.9-21.4-12.7-44.8-17.8-69.5-18.2z"
      style={{
        fill: '#f5f5db',
      }}
    />
  </svg>
);

export default SpotifyIcon;
