import React, { FC } from 'react';

import { useDispatch } from 'react-redux';
import ROUTES from 'routes';
import { routerActions } from 'store/actions';
import journalThunks from 'thunks/journal';

import { JournalBlogCardPublicProps } from './JournalBlogCard.props';
import View from './JournalBlogCard.view';

const JournalBlogCardContainer: FC<JournalBlogCardPublicProps> = (props) => {
  const dispatch = useDispatch();
  
  return (
    <View
      {...props}
      onMoreLink={(slug: string) => {
        return {
          type: 'ROUTER/JOURNAL_CONTENT',
          payload: {
            slug,
          },
        };
      }}
      onMore={(slug: string) => {
        dispatch(journalThunks.getJournalById(slug));
        dispatch(
          routerActions.link(ROUTES.JOURNAL_CONTENT, {
            slug,
          })
        );
      }}
      goToURL={(url: string) =>
        window.open(url, '_blank', 'noopener noreferrer')
      }
    />
  );
};

export default React.memo(JournalBlogCardContainer);
