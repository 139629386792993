import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchBoxRoot: {
      display: 'flex',
    },
    searchBox: {
      background: 'none',
      height: 24,
      width: 200 ,
      outline: 'none',
      border: 0,
      borderBottom: `1px solid ${colors.primaryDarkGreen}`
    },
    searchBoxButton: {
      display: 'none'
    },
    results: {
      position: 'absolute',
      width: 320,
      right: 0,
      maxHeight: 500,
      background: colors.secondaryYellow,
      padding: '10px 4px',
      borderRadius: 4,
      borderTopRightRadius: 0,
    },
    resultsList: {
      listStyle: 'none',
      paddingLeft: 0,
      overflow: 'hidden',
      width: '100%',
      height: '100%',
      borderRadius: 4,

      '& a': {
        textDecoration: 'none',
      },
      '& a:visited': {
        color: colors.primaryDarkGreen ,
        textDecoration: 'none',
      },


    },
    resultItem: {
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      padding: 4,
      transition: '0.3s all',
      borderRadius: 4,
      color: colors.primaryDarkGreen ,
      textDecoration: 'none',

      '&:hover': {
        background: colors.primaryDarkGreen,
        color: colors.primaryYellow,
      },



      '& h3': {
        fontSize: 16,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 700,
        margin: '0 0 0.25em'
      },

      '& p': {
        overflow: 'hidden',
        fontSize: 12,
        textOverflow: 'ellipsis',
        fontWeight: 300,
        margin: 0,
      }

    }
  })
);
