import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { colors, fontFamilies } from 'themeConfig/themeConstants';

import { pxToRem } from '../../../themeConfig/typography';
import { InputFieldProps } from '../InputField/InputField.props';
import { SelectProps } from './Select.props';

const { plain, pitch } = fontFamilies;

export const useStyles = makeStyles((theme: Theme) => {
  const fontSizes = {
    normal: {
      fontFamily: pitch,
      fontSize: pxToRem(12),
    },
    large: {
      fontFamily: plain,
      fontSize: pxToRem(18),
    },
    xlarge: {
      fontFamily: plain,
      paddingBottom: '5px',
      fontSize: pxToRem(35),
      lineHeight: pxToRem(40),
    },
    xlargem: {
      fontFamily: plain,
      paddingBottom: '5px',
      fontSize: pxToRem(30),
      lineHeight: pxToRem(40),
    },
    xxlarge: {
      fontFamily: plain,
      fontSize: pxToRem(70),
      lineHeight: pxToRem(100),
    },
    prescription: {
      fontFamily: plain,
      fontSize: '1.5em',
      fontWeight: 500,
    },
    prescriptionMobile: {
      fontFamily: plain,
      fontSize: '1.07143rem',
      fontWeight: 500,
    },
  };

  const escapeNatureMobileRoot = {
    minHeight: 'unset',
    fontSizes: `${pxToRem(30)} !important`,
  };

  const prescriptionMobileRoot = {
    minHeight: 'unset',
    width: '100%',
    paddingBottom: 3,
  };

  return createStyles({
    formControl: {
      width: '100%',
    },
    root: ({ color, variant }: SelectProps) => {
      return {
        ...fontSizes[variant || 'normal'],
        color: color || colors.primaryDarkGreen,
        borderBottom: `2px solid ${color || colors.primaryDarkGreen}`,
        paddingLeft: 2,
        paddingRight: 2,

        [theme.breakpoints.up('sm')]: {
          '&:hover ~ svg': {
            opacity: `1 !important`,
          },
        },
      };
    },
    paper: ({ color, background }: SelectProps) => ({
      backgroundColor: background || colors.primaryYellow,
      boxShadow: 'unset',
    }),
    popoverRoot: {
      zIndex: '99999 !important' as any
    },
    inputLabel: {
      fontFamily: plain,
      fontWeight: 500,
    },
    icon: ({ color, variant }: SelectProps) => ({
      color: color || colors.primaryDarkGreen,
      opacity: variant === 'xlargem' || variant === 'xlarge' ? 0 : 1,
      [theme.breakpoints.down('sm')]: {
        opacity: 1,
      },
      transition: 'linear 0.3s !important',
    }),
    itemSelected: ({ color, styleVariant }: SelectProps) => {
      if (styleVariant === 'escapeNature') {
        return {
          borderBottom: `2px solid ${colors.primaryYellow}`,
          background: 'transparent !important',
          paddingBottom: 10,
        };
      }
      return {};
    },
    menuItem: ({ color, variant, styleVariant }: SelectProps) => ({
      ...fontSizes[variant || 'normal'],
      width: '100%',
      color: color || colors.primaryDarkGreen,
      textTransform: styleVariant === 'escapeNature' ? 'lowercase' : 'none',

      ...(styleVariant === 'prescription' ? prescriptionMobileRoot : {}),
      [theme.breakpoints.down('xs')]: {
        ...(styleVariant === 'escapeNature' ? escapeNatureMobileRoot : {}),
        ...(styleVariant === 'prescription' ? prescriptionMobileRoot : {}),
      },
    }),
    disabled: {
      opacity: 0.5,
    },
  });
});
