import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  root: {
    '& .DayPicker-Day--selected': {
      backgroundColor: '#EBCE70'
    },
    '& .DayPicker-Day--start': {
      border: '2px solid #000'
    },
    '& .DayPicker-Day--end': {
      border: '2px solid #000'
    }
  }
}));
