import React, { FC } from 'react';

import { AboutPublicProps } from './About.props';
import View from './About.view';

const AboutContainer: FC<AboutPublicProps> = (props) => {
  return <View {...props} />;
};

export default React.memo(AboutContainer);
