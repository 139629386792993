import React, { FC, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Skeleton } from '@material-ui/lab';
import { format, parseISO } from 'date-fns';
import { indexOf, pathOr } from 'ramda';
import { Booking, BookingAddOn } from 'types/Booking';
import { getTotalStay } from 'utils/Data/pricing/pricing';

import { ProvisionCard } from '../../modules';
import { GhostButton, Select, Typo } from '../../primitives';
import { ProvisionsLayoutProps } from './ProvisionsLayout.props';
import { useStyles } from './ProvisionsLayout.styles';

const ProvisionsLayoutView: FC<ProvisionsLayoutProps> = (props) => {
  const { loading, provisions, bookings, isLoggedIn, onBag } = props;

  const theme = useTheme();
  const belowTablet = useMediaQuery('(max-width: 767px)');
  const classes = useStyles({ isMobile: belowTablet });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const [currentBooking, setCurrentBooking] = useState<string>('0');
  const [error, setError] = useState<string>();
  const [toBag, setToBag] = useState<{ [key: string]: number }>({});

  const bMap: { [key: string]: Booking } = {};
  const bookingOptions = bookings.map((booking) => {
    const checkIn = format(parseISO(booking.startDate), 'MMM dd, yyyy');
    const checkOut = format(parseISO(booking.endDate), 'MMM dd, yyyy');
    const totalStay = getTotalStay(checkIn, checkOut);
    bMap[booking.id] = { ...booking, totalStay };
    return {
      label: `${booking.entity.title}: ${checkIn} - ${checkOut}`,
      value: booking.id,
    };
  });

  bookingOptions.unshift({
    label: 'Select your upcoming session',
    value: '0',
  });

  const [bookingMap, setBookingMap] =
    useState<{ [key: string]: Booking }>(bMap);
  const [addonMap, setAddonMap] = useState<{ [key: string]: BookingAddOn }>({});
  const [country, setCountry] = useState<string>('AU');
  const [totalStay, setTotalStay] = useState<number>(0);

  useEffect(() => {
    if (!Object.keys(bookingMap).length && Object.keys(bMap).length) {
      setBookingMap(bMap);
    }

    const addOnMap: { [key: string]: BookingAddOn } = {};
    const oldBag: { [key: string]: number } = {};
    if (bookingMap[currentBooking]) {
      const addons = pathOr(
        [] as BookingAddOn[],
        ['metadata', 'addons'],
        bookingMap[currentBooking]
      );

      setTotalStay(bookingMap[currentBooking].totalStay || 0);
      setCountry(
        pathOr(
          'AU',
          ['entity', 'metadata', 'country'],
          bookingMap[currentBooking]
        )
      );

      for (const addon of addons) {
        addOnMap[addon.id] = addon;
        if (addon.quantity > 0) {
          oldBag[addon.id] = addon.quantity;
        }
      }

      const entityAddOns = provisions;

      const entityId = pathOr(
        null,
        ['entity', 'id'],
        bookingMap[currentBooking]
      );
      if (entityId) {
        for (const addon of entityAddOns) {
          if (
            ['item', 'package'].indexOf(addon.type || '') > -1 &&
            addon.active.toString() == 'true'
          ) {
            if (addon.entities && addon.entities.length) {
              for (const ent of addon.entities) {
                if (ent.label == entityId) {
                  addOnMap[addon.id] = {
                    ...addon,
                    ...(addOnMap[addon.id] || {}),
                  };
                  break;
                }
              }
            }
          }
        }
      }
    }

    setToBag(oldBag);
    setAddonMap(addOnMap);
  }, [currentBooking, bookings]);

  return isLoggedIn || loading || bookings.length ? (
    <Box className={classes.provisionsContainer}>
      {loading ? (
        <Box mb={16}>
          <Skeleton />
          <Skeleton animation={false} />
          <Skeleton animation="wave" />
        </Box>
      ) : (
        <>
          {belowTablet ? (
            <>
              <Typo variant={isXs ? 'h5' : 'h3'}>Shop provisions</Typo>
              <Box mt={4}>
                {bookings && bookings.length ? (
                  <Select
                    id="bookin-session"
                    options={bookingOptions}
                    label=""
                    shrink={true}
                    value={currentBooking}
                    error={error}
                    onChange={(e) => {
                      setCurrentBooking(e.target.value);
                      bookingMap[e.target.value] && setError(undefined);
                    }}
                    disabled={!bookings || !bookings.length}
                  />
                ) : null}
              </Box>
              <Box className={classes.mobileContainer}>
                {provisions.map((item, index) => (
                  <Box key={index} mb={8}>
                    {item.type !== 'doggo' && (
                      <ProvisionCard
                        {...item}
                        totalStay={totalStay}
                        country={country}
                        addOn={addonMap[item.id]}
                        value={
                          toBag[item.id] || addonMap[item.id]?.quantity || 0
                        }
                        onChange={(id, quantity) => {
                          setToBag({ ...toBag, [id]: quantity });
                        }}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            <>
              <Typo variant="h3" className={classes.title}>
                Shop provisions
              </Typo>
              <Box mt={4} mb={8} className={classes.select}>
                {bookings && bookings.length ? (
                  <Select
                    id="bookin-session"
                    options={bookingOptions}
                    label=""
                    shrink={true}
                    value={currentBooking}
                    error={error}
                    onChange={(e) => {
                      setCurrentBooking(e.target.value);
                      bookingMap[e.target.value] && setError(undefined);
                    }}
                  />
                ) : null}
              </Box>
              <Grid container>
                {provisions.map((item, index) => {
                  return (
                    item.type !== 'doggo' && (
                      <Grid key={item.id} item sm={4} className={classes.card}>
                        <ProvisionCard
                          {...item}
                          totalStay={totalStay}
                          country={country}
                          addOn={addonMap[item.id]}
                          value={
                            toBag[item.id] || addonMap[item.id]?.quantity || 0
                          }
                          onChange={(id, quantity) => {
                            setToBag({ ...toBag, [id]: quantity });
                          }}
                        />
                      </Grid>
                    )
                  );
                })}
              </Grid>
            </>
          )}
          <Box mb={8} display="flex" flexDirection="row-reverse">
            <GhostButton
              text="Add Provisions"
              buttonStyle={{ width: belowTablet ? '100%' : '33%' }}
              onClick={() =>
                bookingMap[currentBooking]
                  ? onBag(
                      Object.keys(toBag).map(
                        (id) =>
                          addonMap[id] && {
                            text: addonMap[id].name,
                            value: Number(addonMap[id].price),
                            quantity: toBag[id],
                          }
                      ),
                      bookingMap[currentBooking]
                    )
                  : setError('Please select your booking')
              }
            />
          </Box>
        </>
      )}
    </Box>
  ) : null;
};

export default React.memo(ProvisionsLayoutView);
