import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';

interface StyleProps {
  isMobile?: boolean;
  expand?: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileContainer: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(8),
      backgroundColor: colors.secondaryDarkGreen,
    },
    footerBanner: {
      padding: theme.spacing(1),
      textAlign: 'center',
      background: colors.primaryDarkGreen,
      color: colors.primaryYellow,
      display: 'flex',
      justifyContent: 'center',
    },
    desktopContainer: ({ expand }: StyleProps) => ({
      backgroundColor: colors.secondaryDarkGreen,
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(expand ? 20 : 7),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    }),
    horizontalLine: ({ isMobile }: StyleProps) => ({
      backgroundColor: isMobile ? colors.primaryYellow : colors.primaryWhite,
      height: 2,
      marginTop: 16,
      marginBottom: 16,
    }),
    itemsPadding: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },

    mobileHeader: {
      marginBottom: 10,
    },
    mobileTitle: {
      marginBottom: 3,
    },
    subItem: {
      display: 'flex',
      flexDirection: 'row',
    },
    verticalLine: {
      margin: '5px 10px 0',
      height: '60%',
      backgroundColor: theme.palette.background.default,
    },
    countryRadio: {
      marginRight: '20px',
    },
    countryButton: {
      width: '100px',
      height: '2.5rem',
      '& h5': {
        fontSize: '0.8rem',
      },
    },
  })
);
