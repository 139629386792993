import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 40,
      [theme.breakpoints.up(1440)]: {
        fontSize: 32,
        lineHeight: '38px',
        marginBottom: 100,
      },
    },
    ghostButton: {
      width: 200,
    },
  })
);
