import isSameDay from 'date-fns/isSameDay';
import lastDayOfYear from 'date-fns/lastDayOfYear';

export default [
  {
    text: `New Year's Eve Surcharge`,
    value: 200,
    active: true,
    isIncluded: (days: Date[]) => {
      return days.filter((d) => isSameDay(lastDayOfYear(d), d)).length > 0;
    },
  },
];

const currentYear = new Date().getFullYear();

export const minimumNights = [
  {
    text: `New Year (Dec 30 to Jan 1)`,
    nights: 3,
    active: true,
    dates: [  
      new Date(currentYear, 11, 30),
      new Date(currentYear, 11, 31),
      new Date(currentYear+1, 0, 1)
    ],
    isIncluded: function (days: Date[]) {
      for (let day of days) {
        for (let date of this.dates) {
          if (isSameDay(day, new Date(day.getFullYear(), date.getMonth(), date.getDate()))) {
            return true
          }
        }
      }

      return false;
    }
  },
]
