import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { BlogCard } from '../../modules';
import { GhostButton, TextButton, Typo } from '../../primitives';
import ContentfulRenderer from '../ContentfulRenderer';
import HorizontalBlogCards from '../HorizontalBlogCards';
import { ToBlogBlockProps } from './ToBlogBlock.props';
import { useStyles } from './ToBlogBlock.styles';

const ToBlogBlockView: FC<ToBlogBlockProps> = (props) => {
  const {
    desktopContainerStyle,
    mobileContainerStyle,
    title,
    moreButtonText,
    content,
    imageBg,
    goToBlog,
  } = props;
  const belowTablet = useMediaQuery('(max-width: 767px)');
  const [viewNumber, setViewNumber] = React.useState(belowTablet ? 3 : 6);
  const classes = useStyles();

  const ViewAll = () => {
    const count = belowTablet ? 3 : 6;
    if (viewNumber + count < content.length) {
      setViewNumber(viewNumber + count);
    } else {
      setViewNumber(content.length);
    }
  };

  if (belowTablet) {
    // initial content dynamic??
    const blogCard = content[0];

    return (
      <Box className={`${mobileContainerStyle}`}>
        <Box mb={2}>
          <ContentfulRenderer content={title} forceVariant={'h6'} />
        </Box>
        <BlogCard {...blogCard} />
        <GhostButton
          style={classes.moreButton}
          text={moreButtonText}
          onClick={goToBlog}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Box
        className={`${classes.topContainer} ${desktopContainerStyle}`}
        style={{ backgroundImage: `url(${imageBg})` }}
      >
        <Box width="50%">
          <Box mb={8}>
            <ContentfulRenderer
              content={title}
              containerClass={classes.title}
            />
          </Box>
          <GhostButton
            style={classes.moreButton}
            color="primaryWhite"
            text={moreButtonText}
            onClick={goToBlog}
          />
        </Box>
      </Box>
      <Box className={`${classes.bottomContainer} ${desktopContainerStyle}`}>
        <HorizontalBlogCards content={content.slice(0, viewNumber)} />
        {belowTablet || viewNumber >= content.length ? null : (
          <Grid
            item
            xs={12}
            md={12}
            style={{
              textAlign: 'right',
              alignSelf: 'flex-end',
            }}
          >
            <TextButton
              style={classes.seeMore}
              color="primaryWhite"
              text={'View More'}
              onClick={ViewAll}
            />
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(ToBlogBlockView);
