import { Dispatch } from 'redux';
import { getJournalByIdEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { journalActions } from 'store/actions';

import { Store } from '../../types/store/Store';

const getJournalById = (id: string) => (
  dispatch: Dispatch,
  getState: () => Store
) => {
  dispatch(
    journalActions.getById(
      apiFetch<string, any>(getJournalByIdEndpoint, id)(dispatch, getState)
    )
  );
};

export default getJournalById;
