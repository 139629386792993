import React, { FC, useEffect, useRef } from 'react';

import pathOr from 'ramda/src/pathOr';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';

import ROUTES from '../../../routes';
import {
  authActions,
  routerActions,
  tokenActions,
} from '../../../store/actions';
import authThunks from '../../../thunks/auth';
import { LoginSignupPublicProps } from './LoginSignup.props';
import View from './LoginSignup.view';

const LoginSignupContainer: FC<LoginSignupPublicProps> = (props) => {
  const dispatch = useDispatch();
  const isMounted = useRef(true);

  const loginStatus = useSelectorSafe((state) => state.user.status);
  const createAccountStatus = useSelectorSafe(
    (state) => state.createAccount.status
  );

  // for resetting to initial state, in case of errors
  useEffect(() => {
    if (isMounted.current && loginStatus !== ASYNC_STATUS.INITIAL) {
      dispatch(authActions.logout());
      dispatch(tokenActions.clear());
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const loginErrors = useSelectorSafe((state) => state.user.errors);
  const createAccountErrors = useSelectorSafe(
    (state) => state.createAccount.errors
  );

  let errorMessage = '';
  if (createAccountStatus === ASYNC_STATUS.ERROR) {
    errorMessage = pathOr(
      'Something went wrong. Please try again.',
      ['message'],
      createAccountErrors
    );
  } else if (loginStatus === ASYNC_STATUS.ERROR) {
    errorMessage = pathOr(
      'Something went wrong. Please try again.',
      ['message'],
      loginErrors
    );
  }

  return (
    <View
      {...props}
      login={(email, password, remember) => {
        dispatch(authThunks.login({ email, password, remember }));
      }}
      fbLogin={(data) => {
        dispatch(authThunks.facebookAccess(data, {}));
      }}
      googleLogin={(data) => {
        dispatch(authThunks.googleAccess(data, {}));
      }}
      signup={(email, password, subscribe) => {
        dispatch(authThunks.createAccount({ email, password, subscribe }));
      }}
      forgotPassword={() =>
        dispatch(routerActions.link(ROUTES.FORGOT_PASSWORD))
      }
      loginLoading={loginStatus === ASYNC_STATUS.LOADING}
      loginSuccess={loginStatus === ASYNC_STATUS.COMPLETE}
      signupLoading={createAccountStatus === ASYNC_STATUS.LOADING}
      signupSuccess={createAccountStatus === ASYNC_STATUS.COMPLETE}
      errorMessage={errorMessage}
      openErrorSnack={!!errorMessage}
    />
  );
};

export default React.memo(LoginSignupContainer);
