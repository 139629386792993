import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Radio from '@material-ui/core/Radio';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  useStripe,
  useElements,
  CardElement,
  PaymentRequestButtonElement,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { PaymentRequest } from '@stripe/stripe-js';
import BlockModal from 'components/primitives/Modal/BlockModal';
import { CURRENCY } from 'consts/locations';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { redirect } from 'redux-first-router';
import isEmpty from 'validator/lib/isEmpty';

import { API } from '../../../consts';
import ROUTES from '../../../routes';
import useCurrentRoute from '../../../store/selectors/useCurrentRoute';
import { Button, InputField, Snackbar, Typo } from '../../primitives';
import { cardDetails } from './CheckoutForm.consts';
import { CheckoutFormProps } from './CheckoutForm.props';
import { useStyles } from './CheckoutForm.styles';
import { Skeleton } from '@material-ui/lab';
import { ContentfulRenderer } from 'components/layouts';
import { forestrySubscribeEndpoint } from 'services/fetch/apiEndpoints';

const transformErrorKey = (key: string) => {
  return key
    .replace(/([A-Z])/g, ' $1')
    .replace('card', "card's")
    .toLowerCase();
};

const CheckoutFormView: FC<CheckoutFormProps> = (props: CheckoutFormProps) => {
  const {
    confirm = 0,
    formDetails,
    formErrors,
    setValue,
    setError,
    setConfirmLoading,
    addons,
    bookingDetails,
    guests,
    notes,
    prescriptionDetails,
    goToSuccess,
    voucherId,
    voucherDiscount,
    isLoggedIn,
    isInHousePayments,
    addProvisions,
    trackCheckoutCompleted,
    trackPaymentInfoEntered,
    trackOrderUpdated,
    trackBookingFailed,
    setOrderProps,
    currentUser,
    intent,
    afterpay,
    total,
    trackCheckoutStep,
    isAuthHoldLoading,
    authHoldMessage,
    houseCard,
  } = props;
  const classes = useStyles({});
  const [agreementBox, setAgreementBox] = useState(false);
  const [forestryBox, setForestryBox] = useState(false);
  const [showAgreementBoxError, setShowAgreementBoxError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Please try again!');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [afterPayWidget, setAfterPayWidget] = useState<any>();

  const [noCancel, setNoCancel] = useState(false);

  const currentRoute = useCurrentRoute();

  const stripe = useStripe();
  const elements = useElements();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const currency = useMemo(
    () => CURRENCY[intent?.country || 'AU'],
    [intent?.country]
  );

  const bond =
    intent?.country === 'GB' ? '£60' : `${intent?.country || 'AU'}$100`;

  useEffect(() => {
    if (stripe && intent) {
      try {
        const pr = stripe.paymentRequest({
          country: intent.country,
          currency: CURRENCY[intent.country].toLowerCase(),
          total: {
            label: 'Total',
            amount: +Number(intent.amount * 100).toFixed(2),
          },
          requestPayerName: true,
          requestPayerEmail: true,
          requestPayerPhone: true,
        });

        if (pr) {
          pr.canMakePayment().then((result) => {
            if (result && (result.applePay || result.googlePay)) {
              pr.on('paymentmethod', (ev) => {
                setOpen(false);
                setLoading(true);
                setConfirmLoading(true);

                processBooking(async () => {
                  const { paymentIntent, error: confirmError } =
                    await stripe.confirmCardPayment(
                      intent.secret,
                      {
                        payment_method: ev.paymentMethod.id,
                        return_url: `${window.location.origin}/checkout-payment`,
                      },
                      { handleActions: false }
                    );

                  if (confirmError) {
                    ev.complete('fail');
                    return { error: confirmError };
                  } else {
                    ev.complete('success');
                    if (paymentIntent.status === 'requires_action') {
                      // Let Stripe.js handle the rest of the payment flow.
                      const { error } = await stripe.confirmCardPayment(
                        intent.secret,
                        {
                          return_url: `${window.location.origin}/checkout-payment`,
                        }
                      );

                      if (error) {
                        return { error };
                      }

                      return { error: null };
                    } else {
                      return { error: null };
                    }
                  }
                });
              });

              setPaymentRequest(pr);
            }
          });
        }
      } catch (e) {
        console.log(e);
        setPaymentRequest(undefined);
      }
    }
  }, [stripe]);

  useEffect(() => {
    if (confirm > 0) validateUserDetails();
  }, [confirm]);

  useEffect(() => {
    // if (elements && intent && ['UK', 'GB'].indexOf(intent.country) > -1) {
    //   try {
    //     const options: any = {
    //       amount: intent.amount * 100,
    //       currency: CURRENCY[intent.country],
    //       paymentMethodTypes: ['klarna'],
    //       countryCode: intent.country,
    //     };

    //     const PaymentMessageElement = elements.create('paymentMethodMessaging' as any, options);
    //     PaymentMessageElement.mount('#klarna-msg');
    //   } catch(e) {}
    // }

    // @ts-ignore
    const AfterPay: any = window['AfterPay'];
    if (!AfterPay) {
      return console.error('AfterPay is undefined.');
    }

    if (afterpay) {
      const attributes = {
        currency: CURRENCY[afterpay.country],
        amount: afterpay.amount,
        introText: 'Pay',
        showInterestFree: false,
        styles: {
          fontFamily: 'Plain, sans-serif',
        },
      };

      if (!afterPayWidget) {
        setAfterPayWidget(
          new AfterPay.Widgets.Placement({
            target: '#btn-pay-with-afterpay',
            locale: `en-${afterpay.country}`,
            context: 'ORDER_CONFIRMATION',
            publicKey: 'testKey',
            attributes,
          })
        );
      } else {
        afterPayWidget.update(attributes);
      }
    }
  });

  useEffect(() => {
    if (!noCancel) {
      window.addEventListener('unload', onCloseTab);
    }
    return () => {
      window.removeEventListener('unload', onCloseTab);
    };
  });

  useEffect(() => {
    if (
      (intent && intent.payload && intent.payload.bookingId) ||
      (afterpay && afterpay.payload && afterpay.payload.bookingId)
    ) {
      props.clearPayload();
    }
  }, []);

  const onCloseTab = () => {
    if (noCancel) return;

    if (
      (intent && intent.id && intent.payload && intent.payload.bookingId) ||
      (afterpay &&
        afterpay.orderToken &&
        afterpay.payload &&
        afterpay.payload.bookingId)
    ) {
      const bookingId =
        intent && intent.payload
          ? intent.payload.bookingId
          : afterpay.payload.bookingId;
      const voucherCode =
        intent && intent.payload
          ? intent.payload.voucherCode
          : afterpay.payload.voucherCode;
      const payload = intent ? intent.payload : afterpay.payload;

      trackBookingFailed(payload, 'Browser tab was close');
      cancelBooking(bookingId, voucherCode, intent, addProvisions, '', true);
      props.clearPayload();
    }
  };

  const handleAgreementBoxChange = () => {
    setAgreementBox((prevState) => {
      return !prevState;
    });
  };

  const onSubmit = async () => {
    setOpen(false);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // if (!intent.method) {
    //   const cardElement = elements.getElement(CardElement);

    //   if (!cardElement) {
    //     setErrorMessage('Please fill in the payment details');
    //     setOpen(true);
    //     return;
    //   }

    //   const {error, paymentMethod} = await stripe.createPaymentMethod({
    //     type: 'card',
    //     card: cardElement,
    //   });

    //   if (error || !paymentMethod) {
    //     setErrorMessage('Please fill in the payment details');
    //     setOpen(true);
    //     return;
    //   }
    // }
    if (forestryBox) {
      const userPayload = {
        firstName: formDetails.firstName,
        lastName: formDetails.lastName,
        email: formDetails.email,
        phone: formDetails.mobile,
        address: `${formDetails.address}, ${formDetails.city}, ${formDetails.state} ${formDetails.postCode} ${intent.country}`,
      };
      fetch(forestrySubscribeEndpoint.url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userPayload),
      }).catch((e) => {
        console.warn(e);
      });
    }

    trackCheckoutCompleted();
    trackPaymentInfoEntered();

    await processBooking(async () => {
      if (!intent.method) {
        return stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${window.location.origin}/checkout-payment`,
            payment_method_data: {
              billing_details: {
                name: `${formDetails.firstName} ${formDetails.lastName}`,
                email: formDetails.email,
                phone: formDetails.mobile,
                address: {
                  city: formDetails.city,
                  line1: formDetails.address,
                  state: formDetails.state,
                  postal_code: formDetails.postCode,
                  country: intent.country,
                },
              },
            },
          },
          redirect: 'if_required',
        });
      } else {
        return stripe.confirmCardPayment(intent.secret, {
          payment_method: intent.method,
          return_url: `${window.location.origin}/checkout-payment`,
        });
      }
    });
  };

  const onSubmitAfterpay = async () => {
    setOpen(false);

    trackCheckoutCompleted();
    trackPaymentInfoEntered();

    await processBooking(() => {
      return new Promise((resolve) => {
        // @ts-ignore
        const AfterPay: any = window['AfterPay'];
        if (!AfterPay) {
          return { error: { message: 'Afterpay is undefined' } };
        }
        AfterPay.initialize({ countryCode: afterpay.country });

        AfterPay.open();

        AfterPay.onComplete = function (event: {
          data: { status: string; orderToken: string };
        }) {
          if (event.data.status == 'SUCCESS') {
            const orderToken = event.data.orderToken;

            const params = [
              `status=${event.data.status}`,
              `orderToken=${orderToken}`,
            ];

            setNoCancel(true);

            window.removeEventListener('unload', onCloseTab);

            window.location.assign(
              `${window.location.origin}/checkout-afterpay?${params.join('&')}`
            );
            resolve({ error: null });
          } else {
            resolve({ error: { message: 'Afterpay payment failed.' } });
          }
        };
        AfterPay.transfer({ token: afterpay.orderToken });
      });
    });
  };

  const processBooking = async (paymentCB: () => Promise<{ error?: any }>) => {
    let payload = {};
    let endpoint = '';

    if (currentRoute === ROUTES.HOUSE_PAYMENTS) {
      if (!addProvisions) {
        payload = {
          form: formDetails,
          addons,
          booking: bookingDetails,
          guests,
          notes,
          ...(afterpay
            ? { comments: `Booked via Afterpay #${afterpay.orderToken}\n` }
            : {}),
          voucher: voucherId,
          discount: voucherDiscount,
        };

        endpoint = 'charge';
      } else {
        payload = {
          addons,
          booking: bookingDetails,
        };

        endpoint = 'charge/update';
      }
    }

    if (currentRoute === ROUTES.VOUCHER_PAYMENTS) {
      payload = {
        form: formDetails,
        prescription: prescriptionDetails,
        addons,
      };

      endpoint = 'charge/prescription';
    }

    payload = {
      ...payload,
      user_id: currentUser?.id,
      user_email: currentUser?.email,
      user_source: currentUser?.source || 'Unyoked',
      intent: intent?.id,
    };

    setLoading(true);
    setConfirmLoading(true);

    const response = await fetch(`${API.URL}/${API.VERSION}/${endpoint}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    let message = '';
    let delayLoading = false;
    const res = await response.json();

    if (response.ok) {
      const { status, bookingId, voucherCode, total, metadata } = res.data;

      if (status == 'continue') {
        if (intent || afterpay) {
          if (bookingId) {
            props.setPayload({
              bookingId,
              voucherCode,
              total,
              metadata,
            });
          } else {
            props.setPayload({ voucherCode, total, ...payload });
          }
        }

        setOrderProps({
          ...payload,
          currency,
          total,
          bookingId,
        });

        const paymentRes = await Promise.race([
          paymentCB(),
          new Promise<{ error: any }>((resolve) =>
            setTimeout(
              () =>
                resolve({
                  error: {
                    message:
                      'Something went wrong, Please try different payment method',
                  },
                }),
              15 * 60 * 1000
            )
          ),
        ]);

        const { error } = paymentRes;
        let redirect = true;

        if (error && error.message) {
          message = error.message;

          if ((intent && intent.id) || (afterpay && afterpay.orderToken)) {
            [redirect, message] = await cancelBooking(
              bookingId,
              voucherCode,
              intent,
              addProvisions,
              message
            );
            delayLoading = true;
            setTimeout(() => props.clearPayload(), 3000);
          }

          trackBookingFailed(payload, error.message);
        }

        if (redirect && intent && intent.id) {
          const params = [
            `payment_intent=${intent.id}`,
            `payment_intent_client_secret=${intent.secret}`,
          ];

          setNoCancel(true);

          window.removeEventListener('unload', onCloseTab);

          return window.location.assign(
            `${window.location.origin}/checkout-payment?${params.join('&')}`
          );
        }
      } else {
        if (!message) {
          props.clearVoucher();
          props.clearPayload();

          return goToSuccess();
        }
      }
    } else {
      trackBookingFailed(payload, res.message);
      message = res.message || 'Please try again!';
    }

    setErrorMessage(message);
    setOpen(true);
    setTimeout(
      () => {
        setLoading(false);
        setConfirmLoading(false);
      },
      delayLoading ? 3000 : 0
    );
  };

  const cancelBooking = async (
    bookingId: string,
    voucherCode: string,
    intent: { id: string },
    addProvisions: any,
    message: string,
    force = false
  ) => {
    let redirect = false;
    const cancelRes = await fetch(`${API.URL}/${API.VERSION}/charge/cancel`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        bookingId,
        voucherCode,
        intent: intent ? intent.id : null,
        addProvisions: !!addProvisions || undefined,
        force,
      }),
    });

    if (!cancelRes.ok) {
      message =
        'Something went wrong with your booking please contact administrator';
    } else {
      const cancelBody = await cancelRes.json();
      if (cancelBody.data == 'continue') {
        redirect = true;
      } else if (cancelBody.data == 'processing') {
        await new Promise<boolean>((resolve) =>
          setTimeout(() => resolve(true), 10000)
        );
        redirect = true;
      } else {
        console.log('booking deleted', bookingId);
      }
    }

    return [redirect, message] as [boolean, string];
  };

  const onChange = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { target } = event;
    const { id, value: eventValue } = target;

    setError({ ...formErrors, [id]: '' });
    setValue({ ...formDetails, [id]: eventValue });
  };

  const validate = () => {
    if (isInHousePayments && !isLoggedIn) {
      return false;
    }

    let errorObj = formErrors;
    Object.entries(formDetails).forEach(([key, objValue]) => {
      if (objValue === null || isEmpty(objValue))
        errorObj = {
          ...errorObj,
          [key]: `Please enter your ${transformErrorKey(key)}`,
        };
      else if (key === 'mobile' && !isPossiblePhoneNumber(objValue)) {
        errorObj = {
          ...errorObj,
          [key]: `Choose your country code, input your mobile number, and remove + or 0 from the start`,
        };
      }
    });
    setError(errorObj);
    const noError = Object.values(errorObj).every((x) => x === '');

    if (!agreementBox) {
      setShowAgreementBoxError(true);
    }

    if (noError) {
      if (agreementBox) {
        setShowAgreementBoxError(false);
        return true;
      }
    }

    setErrorMessage('Please fill in required details');
    setOpen(true);
    return false;
  };

  const validateUserDetails = () => {
    if (validate()) {
      if (afterpay) {
        onSubmitAfterpay();
      } else {
        onSubmit();
      }
    }
  };

  const AggreementCheck = (
    <Grid container direction="row">
      <Grid item xs={12} sm={12}>
        <FormGroup>
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                checked={agreementBox}
                onChange={handleAgreementBoxChange}
              />
            }
            label={
              <Typo style={{ paddingTop: 3 }}>
                I have read and agree to the&nbsp;
                <Link
                  href="/terms-and-conditions"
                  target="_blank"
                  rel="noopener"
                  color="inherit"
                  underline="always"
                >
                  terms & conditions
                </Link>
                &nbsp;and&nbsp;
                <Link
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener"
                  color="inherit"
                  underline="always"
                >
                  privacy policy
                </Link>
              </Typo>
            }
          />

          {houseCard?.isForestryCabin ? (
            <FormControlLabel
              labelPlacement="end"
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={forestryBox}
                  onChange={() => setForestryBox((x) => !x)}
                />
              }
              label={
                <Typo style={{ paddingTop: 3 }}>
                  I&apos;d like to receive updates and marketing communications
                  from Forestry England via email (you can unsubscribe at any
                  time).
                </Typo>
              }
            />
          ) : (
            <></>
          )}

          {afterpay || prescriptionDetails ? null : (
            <div className={classes.holdInfo}>
              {isAuthHoldLoading || !authHoldMessage ? (
                <Skeleton />
              ) : (
                <ContentfulRenderer
                  content={authHoldMessage}
                  forceVariant="string"
                />
              )}
            </div>
          )}
        </FormGroup>
        {!agreementBox && showAgreementBoxError ? (
          <Typo className={classes.checkBoxError}>
            Please agree to Unyoked&apos;s terms and conditions before
            confirming your nature session.
          </Typo>
        ) : null}
      </Grid>
    </Grid>
  );

  const CardPayment = (
    <>
      {total > 0 ? (
        <Grid container direction="row">
          <Grid item xs={12} sm={12}>
            <Box
              className={classes.cardInputContainer}
              mb={3}
              mr={isXs ? 0 : '7.5%'}
            >
              {intent &&
                (!intent.method ? (
                  <>
                    {/* <CardElement /> */}
                    <PaymentElement
                      options={{
                        wallets: {
                          applePay: 'never',
                          googlePay: 'never',
                        },
                        defaultValues: {
                          billingDetails: {
                            name: `${formDetails.firstName} ${formDetails.lastName}`,
                            email: formDetails.email,
                            phone: formDetails.mobile,
                            address: {
                              city: formDetails.city,
                              line1: formDetails.address,
                              state: formDetails.state,
                              postal_code: formDetails.postCode,
                              country: intent.country,
                            },
                          },
                        },
                      }}
                    />
                  </>
                ) : (
                  <Typo variant={'h6'}>
                    Using ...{intent.method.substring(20, intent.method.length)}{' '}
                    {intent.methodName}
                  </Typo>
                ))}
            </Box>
          </Grid>
          {/* {cardDetails.map(({ id, label }) => (
            <Grid key={id} item xs={12} sm={6}>
              <Box mb={3} pr={isXs ? 0 : '15%'}>
                <InputField
                  id={id}
                  label={`Card ${label}`}
                  shrink={true}
                  value={formDetails[id]}
                  error={formErrors[id]}
                  onChange={onChange}
                />
              </Box>
            </Grid>
          ))} */}
        </Grid>
      ) : null}

      {afterpay && <Box mb={4} id="btn-pay-with-afterpay"></Box>}

      <Button loading={loading} text="Confirm" onClick={validateUserDetails} />
    </>
  );

  return (
    <Box>
      {AggreementCheck}

      {total > 0 && (
        <Box mt={3}>
          <a
            className={classes.reselect}
            onClick={() => {
              props.onChangePayment(true);
            }}
          >
            <Typo variant={'h6'}>{'< Choose a different payment method'}</Typo>
          </a>
        </Box>
      )}

      <Box mb={3} />
      {!afterpay && total > 0 ? (
        <>
          <Typo variant="h5">Pay with</Typo>
          <Box mb={3} />
        </>
      ) : null}
      {total == 0 || !paymentRequest ? (
        CardPayment
      ) : (
        <>
          {paymentRequest && (
            <PaymentRequestButtonElement
              options={{ paymentRequest: paymentRequest }}
            />
          )}
          <Box mb={3} />
          <Typo variant={'h6'}>-- Or using card ---</Typo>
          <Box mb={3} />
          {CardPayment}
        </>
      )}
      <Snackbar message={errorMessage} open={open} type="error" />
      <BlockModal onLeave={onCloseTab} />
    </Box>
  );
};

export default CheckoutFormView;
