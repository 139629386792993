import React, { FC } from 'react';

import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { v4 as uuid } from 'uuid';

import { BlogCard } from '../../modules';
import { HorizontalBlogCardsProps } from './HorizontalBlogCards.props';
import { useStyles } from './HorizontalBlogCards.styles';

const HorizontalBlogCardsView: FC<HorizontalBlogCardsProps> = (props) => {
  const { content, containerStyle } = props;
  // @ts-ignore
  const belowTablet = useMediaQuery('(max-width: 767px)');
  const classes = useStyles();

  if (belowTablet) return null;

  return (
    <Grid
      container
      spacing={8}
      className={`${classes.container} ${containerStyle}`}
    >
      {content.map((item) => (
        <Grid key={item.title} item sm={4}>
          <BlogCard {...item} />
        </Grid>
      ))}
    </Grid>
  );
};

export default React.memo(HorizontalBlogCardsView);
