import { useEffect, useRef } from 'react';

import ReactGA from 'react-ga';
import useSelectorSafe from 'store/selectors/useSelectorSafe';

let gaCode: string | boolean | undefined =
  import.meta.env.REACT_APP_GA_TRACKING_CODE || 'UA-72699308-1';

if (typeof gaCode === 'boolean' || typeof gaCode === 'undefined') {
  gaCode = 'UA-72699308-1';
}

ReactGA.initialize(gaCode);

interface Props {
  children: any;
}

const PageTracker = (props: Props) => {
  const location = useSelectorSafe((state) => state.location) || {
    kind: '',
    pathname: '',
  };

  const currPage = useRef('');

  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['setPath', location.pathname]);
    _hsq.push(['trackPageView']);

    ReactGA.pageview(window.location.pathname);

    // Segment Page View
    const pathname: string = window.location.pathname;
    let pageName: string;
    let pageType: string;

    if (pathname != ' ' && pathname != '/') {
      const hasSlash = pathname.lastIndexOf('/') !== pathname.indexOf('/');
      const isCheckout = pathname.includes('cabin-payments');

      if (isCheckout) {
        pageName = 'Checkout';
      } else {
        pageName = pathname.substring(pathname.lastIndexOf('/') + 1);
        pageName = pageName.replace(/-/g, ' ');
      }

      const words = pageName.split(' ');

      pageName = words
        .map((word: string) => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(' ');

      pageType = hasSlash
        ? pathname.substring(
            pathname.indexOf('/') + 1,
            pathname.lastIndexOf('/')
          )
        : pageName;

      pageType = isCheckout
        ? pathname.substring(pathname.lastIndexOf('/') + 1)
        : pageType;
    } else {
      pageName = 'Home';
      pageType = 'Home';
    }

    if (currPage.current === pageName) return;

    currPage.current = pageName;
    window.analytics.page(pageName, {
      page_category: pageName,
      page_type: pageType,
    });
  }, [location.pathname]);

  return props.children;
};

export default PageTracker;
