import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import pathOr from 'ramda/src/pathOr';
import pick from 'ramda/src/pick';
import { useDispatch } from 'react-redux';
import ROUTES from 'routes';
import {
  routerActions,
  filterActions,
  cabinFilterActions,
} from 'store/actions';
import useCurrentRoute from 'store/selectors/useCurrentRoute';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import entityThunks from 'thunks/entity';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { bookingQuestionsState } from 'types/store/BookingQuestionsState';
import { LocationsState } from 'types/store/CountryState';
import { OffersState } from 'types/store/EntitiesState';
import { FilterState } from 'types/store/FilterState';
import { asyncData } from 'utils/Redux';
import { FeatureFlagsState } from 'types/store/FeatureFlagState';

import { Filter } from '../../../types/Filter';
import {
  HomeFilterPublicProps,
  HomeFilterProps,
  CalendarEntitiesData,
} from './HomeFilter.props';
import HomeFilterView from './HomeFilter.view';
import { useQueryWrapper } from 'utils/Hooks/useQueryWrapper';
import { getContentfulEntry } from 'services/fetch/apiEndpoints';

const entitiesFallback: CalendarEntitiesData = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not get entities' },
]);

const HomeFilterContainer: FC<HomeFilterPublicProps> = (
  publicProps: HomeFilterPublicProps
) => {
  const dispatch = useDispatch();

  const filterData = useSelectorSafe<FilterState>(
    (state) => state.filter,
    null
  );

  const cabinFilter = useSelectorSafe((state) => state.cabinFilter, null);

  const offers = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<OffersState>(
      (state) => state.offers,
      asyncData(ASYNC_STATUS.ERROR, [{ message: 'Could not get offers' }])
    )
  ).data?.offers;

  const bookingQuestions = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<bookingQuestionsState>(
      (state) => state.bookingQuestions,
      asyncData(ASYNC_STATUS.ERROR, [
        { message: 'Could not get booking questions' },
      ])
    )
  );

  const featureFlags = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<FeatureFlagsState>(
      (state) => state.featureFlags,
      asyncData(ASYNC_STATUS.ERROR, [
        { message: 'Could not get feature flags' },
      ])
    )
  );

  const [mustHaves, setMustHaves] = useState<string[]>(
    pathOr([], ['offers'], filterData)
  );

  const handleMustHavesChange = (e: ChangeEvent<HTMLInputElement>) => {
    let mustHavesArr: string[] = [...mustHaves];

    if (e.target.value == '0') {
      mustHavesArr = [];
    } else {
      if (offers && e.target.checked) {
        if (!mustHaves.includes(e.target.value)) {
          let exclude: string[] = [];

          for (const offer of offers) {
            if (offer.opposites) {
              const opIds = offer.opposites.map((o) => o.id);
              if (offer.id == e.target.value) {
                exclude = exclude.concat(opIds);
              } else if (opIds.includes(e.target.value)) {
                exclude.push(offer.id);
              }
            }
          }

          mustHavesArr = [
            ...mustHaves.filter((m) => !exclude.includes(m)),
            e.target.value,
          ];
        }
      } else {
        mustHavesArr = mustHavesArr.filter((i) => i !== e.target.value);
      }
    }

    setMustHaves(mustHavesArr);
  };

  const currentRoute = useCurrentRoute();

  const query: object = useSelectorSafe<object>(
    (state) => state.location.payload,
    {}
  );

  const entities: CalendarEntitiesData = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<CalendarEntitiesData>(
      (state) => state.calendarEntities,
      entitiesFallback
    )
  );

  const locations = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<LocationsState>(
      (state) => state.locations,
      asyncData(ASYNC_STATUS.ERROR, [{ message: 'Could not get locations' }])
    )
  );

  const countryState = useSelectorSafe((state) => state.country);
  const countryCode = countryState ? countryState.countryCode : 'AU';

  const location: string = pathOr('', ['location'], query);
  let country: string = pathOr(countryCode, ['country'], query);
  if (filterData?.location?.country && filterData?.location?.address) {
    country = filterData.location.country;
  }

  if (country == 'UK') country = 'GB';

  const cityData =
    locations?.data?.cities[country].find(
      (x) =>
        x.name === location ||
        (filterData?.cities && filterData.cities.includes(x.id))
    ) || locations?.data?.cities[country][0];

  const locationObject = {
    latitude: 0,
    longitude: 0,
    country,
    address: cityData?.name || location,
    cityId: cityData?.id || '',
  };

  const { data: maintenanceText } = useQueryWrapper<string, any>(
    ['maintenanceText'],
    getContentfulEntry,
    '2tbMyO6ahGKwgGlaKozBti',
    {
      refetchInterval: 3000000,
      staleTime: 3000000,
    }
  );

  const combinedProps: HomeFilterProps = {
    ...publicProps,
    filterData,
    cabinFilter: cabinFilter ?? {},
    offers,
    mustHaves,
    questions: bookingQuestions.data?.questions,
    handleMustHavesChange,
    goToResults: (country, location) => {
      const loc = location || undefined;
      dispatch(routerActions.link(ROUTES.RESULTS, { country, location: loc }));
    },
    isResultsPage: Boolean(currentRoute.id === 'RESULTS'),
    sort: (value: string) =>
      dispatch(cabinFilterActions.updated({ ...cabinFilter, sort: value })),
    updateFilter: (payload: Filter) => dispatch(filterActions.updated(payload)),
    filter: (filter?: Filter) => {
      if (!filter && filterData) filter = filterData;

      if (filter) {
        window.analytics.track('Product Filtered', {
          location_address: filter.location?.address,
          location_country: filter.location?.country,
          location_latitude: filter.location?.latitude,
          location_longitude: filter.location?.longitude,
          start_date: filter.startDate,
          end_date: filter.endDate,
          must_haves: filter.mustHaves,
        });

        dispatch(entityThunks.get(filter));
      }
    },
    entities,
    // @ts-ignore
    locations,
    // @ts-ignore
    initialLocation: locationObject,
    maintenanceText,
    featureFlags: featureFlags?.data,
  };

  return <HomeFilterView {...combinedProps} />;
};

export default HomeFilterContainer;
