import React, { FC, Fragment } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { useSpring, animated } from 'react-spring';

import {
  VibeChillImage,
  VibeExploreImage,
  VibeFocusImage,
  VibeWellnessImage,
} from '../../../assets/images';
import { HorizontalLine, Typo, ImageContainer } from '../../primitives';
import ContentfulRenderer from '../ContentfulRenderer';
import { AboutProps } from './About.props';
import { useStyles } from './About.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PhilosophyList = (props: {
  classes: Record<string, any>;
  isMobile: boolean;
  listItems: {
    leftBlock: any;
    rightBlock: any;
  }[];
}) => {
  const { classes, isMobile, listItems: initListItems } = props;

  if (!initListItems.length) {
    return <></>;
  }

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 300,
  });

  const finalListItems = initListItems.map((x, idx) => ({
    title: x.leftBlock,
    desc: x.rightBlock,
    style: useSpring({
      to: trigger ? { opacity: 1, transform: 'translate3d(0, 0, 0)' } : {},
      from: { opacity: 0, transform: 'translate3d(0, 150%, 0)' },
      delay: 250 * (idx + 1),
    }),
  }));

  const AnimatedTypo = animated(Typo);

  return (
    <Fragment>
      {finalListItems.map((item, index) => (
        <Box key={item.title} mb={10}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12} sm={8}>
              <AnimatedTypo
                variant="h2"
                className={classes.philosophyTitles}
                style={item.style}
              >
                <ContentfulRenderer content={item.title} />
              </AnimatedTypo>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box pr={isMobile ? 0 : '25%'} mt={isMobile ? 1 : 0}>
                <ContentfulRenderer content={item.desc} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Fragment>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const VibeList = (props: {
  classes: Record<string, any>;
  isMobile: boolean;
  isXs: boolean;
  listItems: {
    title: string;
    imageUrl: string;
    description: string;
  }[];
  blockTitle?: string;
}) => {
  const {
    classes,
    isMobile,
    isXs,
    listItems: initListItems,
    blockTitle,
  } = props;

  if (!initListItems.length) {
    return <></>;
  }

  const finalListItems = initListItems.map((x) => ({
    title: x.title,
    image: x.imageUrl,
    desc: x.description,
  }));

  return (
    <Box>
      {!isXs && (
        <Box mb={8}>
          <Typo variant={'h4'} className={classes.segment}>
            {blockTitle || `Find your vibe`}
          </Typo>
        </Box>
      )}
      {isXs && (
        <Box mt={6} mb={2}>
          <Box mb={2}>
            <HorizontalLine className={classes.line} />
          </Box>
          <Typo variant={'h4'} className={classes.segment}>
            {blockTitle || `Find your vibe`}
          </Typo>
        </Box>
      )}
      <Grid container direction="row" spacing={isMobile ? 3 : 6}>
        {finalListItems.map((item, index) => (
          <Grid key={item.title} item xs={12} sm={6}>
            <Box mb={4}>
              <Grid direction="column">
                {(!isXs || index !== 0) && (
                  <HorizontalLine className={classes.line} />
                )}
                <Box mb={4} />
                <Typo variant={'h3'} className={classes.vibeTitles}>
                  {item.title}
                </Typo>
                <Box mb={isXs ? 2 : 4} />
                <ContentfulRenderer
                  containerClass={classes.vibeImage}
                  content={item.image}
                />
                <Box mb={4} />
                <ContentfulRenderer
                  containerClass={classes.vibeDescription}
                  content={item.desc}
                />
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const AboutView: FC<AboutProps> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Box mb={8}>
        <Typo variant={'h4'} className={classes.segment}>
          Our Philosophy
        </Typo>
      </Box>
      {/* <PhilosophyList classes={classes} isMobile={isMobile}  /> */}
      <VibeList
        classes={classes}
        isMobile={isMobile}
        isXs={isXs}
        listItems={[]}
      />
    </Grid>
  );
};

export default React.memo(AboutView);
