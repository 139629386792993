import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: colors.primaryYellow,
      marginTop: 68,
    },
    toolbar: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
      },
    },
    menuIcon: {
      width: '34px',
      height: '34px',
    },
    logoContainer: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 10,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 'max-content',
      cursor: 'pointer',
    },
    mobileContainer: {
      paddingTop: theme.spacing(4),
      paddingBottom: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    subItem: {
      display: 'flex',
      flexDirection: 'row',
    },
    horizontalLine: {
      backgroundColor: `${colors.primaryDarkGreen} !important`,
      height: 2,
      marginTop: 16,
      marginBottom: 16,
    },
    verticalLine: {
      marginTop: 5,
      marginLeft: 10,
      marginRight: 10,
      height: '60%',
      backgroundColor: colors.primaryDarkGreen,
    },
    journalLink: {
      borderBottom: '2px solid #06270B !important',
      '&::after': {
        content: '',
        display: 'block',
        borderBottom: '2px solid #06270B',
        width: '100%',
        '-webkit-transition': '0.20s ease',
        transition: '0.20s ease',
      },
    },
  })
);
