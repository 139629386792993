import React, { FC, useRef, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { rules, validatePassword } from '../../../utils/Password';
import {
  Button,
  GhostButton,
  InputField,
  Popper,
  Snackbar,
  Typo,
} from '../../primitives';
import { ResetPasswordProps } from './ResetPassword.props';
import { useStyles } from './ResetPassword.styles';

const ResetPasswordView: FC<ResetPasswordProps> = ({
  resetPassword,
  loading,
  success,
  openErrorSnack,
  errorMessage,
}) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const pcRef = useRef(null);
  const cpcRef = useRef(null);

  const isPopperOpen = Boolean(anchorEl);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const classes = useStyles();

  const onChangePassword = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    setPasswordError('');
    setPassword(event.target.value);
  };

  const onChangeConfirmPassword = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    setConfirmPasswordError('');
    setConfirmPassword(event.target.value);
  };

  const onReset = () => {
    let hasError = false;

    const vp = validatePassword(password);
    if (Array.isArray(vp) && vp.length > 0) {
      setPasswordError('Please enter a valid password');
      hasError = true;
    }

    const vcp = validatePassword(confirmPassword);
    if (Array.isArray(vcp) && vcp.length > 0) {
      setConfirmPasswordError('Please enter a valid password');
      hasError = true;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('The passwords does not match');
      hasError = true;
    }

    if (!hasError) resetPassword(password);
  };

  return (
    <Grid container item direction="column" xs={12} sm={6}>
      <Typo
        variant={isMobile ? 'h3' : 'h1'}
        className={classes.title}
        style={{ textAlign: isMobile ? 'left' : 'center' }}
      >
        Reset Password
      </Typo>
      <Box mb={2} />
      <Typo
        variant="caption1"
        style={{ textAlign: isMobile ? 'left' : 'center' }}
      >
        Please enter your desired password
      </Typo>

      <Box mb={8} />
      <div ref={pcRef}>
        <InputField
          id="password"
          placeholder="Password"
          type="password"
          value={password}
          error={passwordError}
          onChange={onChangePassword}
          onFocus={() => setAnchorEl(pcRef.current)}
          onBlur={() => setAnchorEl(null)}
        />
      </div>

      <Box mb={5} />
      <div ref={cpcRef}>
        <InputField
          id="password"
          placeholder="Confirm Password"
          type="password"
          value={confirmPassword}
          error={confirmPasswordError}
          onChange={onChangeConfirmPassword}
          onFocus={() => setAnchorEl(cpcRef.current)}
          onBlur={() => setAnchorEl(null)}
        />
      </div>

      <Box mb={10} />
      {!isMobile ? (
        <Button
          onClick={onReset}
          variant="h4"
          text="Reset"
          loading={loading}
          success={success}
        />
      ) : (
        <GhostButton
          text="Reset"
          style={classes.ghostButton}
          onClick={onReset}
          loading={loading}
          success={success}
        />
      )}

      <Popper
        open={isPopperOpen}
        anchorEl={anchorEl && anchorEl}
        placement={isXs ? 'bottom-end' : 'right'}
        margin={isXs ? '10px 0 0 0' : 20}
        content={
          <Box style={{ padding: '10px 10px 5px 10px' }}>
            {rules.map((rule) => (
              <Typo key={rule} variant="h6" style={{ marginBottom: 5 }}>
                {rule}
              </Typo>
            ))}
          </Box>
        }
      />

      <Snackbar message={errorMessage} open={openErrorSnack} type="error" />
    </Grid>
  );
};

export default React.memo(ResetPasswordView);
