import React, { FC, Fragment, useState } from 'react';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

import { terms } from '../../../consts';
import { HorizontalLine, Typo } from '../../primitives';
import { TermsAndConditionsProps } from './TermsAndConditions.props';
import { useStyles } from './TermsAndConditions.styles';

const TermsAndConditionsView: FC<TermsAndConditionsProps> = () => {
  const [isIn, setIsIn] = useState([0]);
  // @ts-ignore
  const classes = useStyles();

  const addOrRemove = (item: number) => {
    const exists = isIn.includes(item);

    if (exists)
      setIsIn(
        isIn.filter((i) => {
          return i !== item;
        })
      );
    else setIsIn([...isIn, item]);
  };

  return (
    <Box>
      {terms.map(({ header, items }, termsIndex) => {
        const exists = isIn.includes(termsIndex);
        return (
          <Box key={termsIndex}>
            <HorizontalLine className={classes.line} />
            <Box
              className={classes.headerContainer}
              onClick={() => addOrRemove(termsIndex)}
            >
              <Box mr={0.5}>
                {exists ? (
                  <ExpandLess color="secondary" />
                ) : (
                  <ExpandMore color="secondary" />
                )}
              </Box>
              <Typo variant="h4">{header}</Typo>
            </Box>
            <ul>
              <Collapse key={termsIndex} in={exists}>
                {/*
                  // @ts-ignore */}
                {items.map((item, itemsIndex) => {
                  if (typeof item === 'object' && 'title' in item) {
                    return (
                      <Fragment key={itemsIndex}>
                        <li className={classes.li}>
                          <Typo variant="h5">{item.title}</Typo>
                        </li>

                        <Box mb={2}>
                          <ul>
                            {item.subItems.map(
                              (
                                subItem:
                                  | string
                                  | { text: string; list: string[] },
                                subItemIndex: number
                              ) => {
                                if (typeof subItem === 'object') {
                                  return (
                                    <li
                                      key={subItemIndex}
                                      className={classes.li}
                                    >
                                      <Typo
                                        variant="body2"
                                        dangerouslySetInnerHTML={{
                                          __html: subItem.text,
                                        }}
                                      />
                                      <Box mb={1} />
                                      <ol>
                                        {subItem.list.map(
                                          (listItem, listItemIndex) => (
                                            <li
                                              key={listItemIndex}
                                              className={classes.li}
                                            >
                                              <Typo variant="body2">
                                                {listItem}
                                              </Typo>
                                            </li>
                                          )
                                        )}
                                      </ol>
                                    </li>
                                  );
                                }

                                return (
                                  <li key={subItemIndex} className={classes.li}>
                                    <Typo
                                      variant="body2"
                                      dangerouslySetInnerHTML={{
                                        __html: subItem,
                                      }}
                                    />
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </Box>
                      </Fragment>
                    );
                  }

                  if (typeof item === 'object') {
                    return (
                      <Fragment key={itemsIndex}>
                        <li className={classes.li}>
                          <Typo
                            variant="body2"
                            dangerouslySetInnerHTML={{ __html: item.text }}
                          />
                        </li>
                        <ol>
                          {item.list.map(
                            (listItem: string, listItemIndex: number) => (
                              <li key={listItemIndex} className={classes.li}>
                                <Typo
                                  variant="body2"
                                  dangerouslySetInnerHTML={{ __html: listItem }}
                                />
                              </li>
                            )
                          )}
                        </ol>
                      </Fragment>
                    );
                  }

                  return (
                    <li key={itemsIndex} className={classes.li}>
                      <Typo
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: item }}
                      />
                    </li>
                  );
                })}
              </Collapse>
            </ul>
          </Box>
        );
      })}
    </Box>
  );
};

export default React.memo(TermsAndConditionsView);
