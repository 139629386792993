/* eslint-disable prettier/prettier */
import React, { FC, Fragment, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import 'react-phone-number-input/style.css';
import { CURRENCY_SYMBOL } from 'consts/locations';
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  getCountryCallingCode,
  isPossiblePhoneNumber,
} from 'react-phone-number-input';
import { useDispatch } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import userThunks from 'thunks/user';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';

import {
  paymentFormInitialState,
  paymentFormFields,
  PhoneTemplate,
} from '../../../consts';
import {
  VoucherCardPayments,
  ProvisionAccordion,
  CheckoutForm,
  PaymentSetup,
} from '../../modules';
import {
  Button,
  GhostButton,
  HorizontalLine,
  InputField,
  Typo,
} from '../../primitives';
import { ConfirmValues, VoucherPaymentsProps } from './VoucherPayments.props';
import { useStyles } from './VoucherPayments.styles';
import phone from 'phone';
import { toTitleCase } from 'utils/String';
import { is_valid_postcode, match_postcode } from 'utils/Data';

// @ts-ignore
const ReviewAndRecipient: FC<T> = ({
  isMobile,
  isXs,
  classes,
  prescription,
  currency,
  price,
  recipient,
  recipientError,
  setRecipient,
  setRecipientError,
  setCurrentNav,
  invalidRecipient,
  isLoggedIn,
  goToLogin,
  setIsGuest,
}) => {
  const currencySymbol = CURRENCY_SYMBOL[currency];
  const onContinue = (isGuest: boolean) => {
    if (!invalidRecipient()) {
      setIsGuest(isGuest);
      if (!isLoggedIn && !isGuest) {
        goToLogin(recipient);
      } else {
        window.scrollTo(0, 0);
        setCurrentNav(1);
      }
    }
  };

  const onChange = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { target } = event;
    const { id, value: eventValue } = target;

    setRecipientError({ ...recipientError, [id]: '' });
    let val = eventValue;
    if (id == 'firstName' || id == 'lastName') {
      if (/\d/.test(val)) {
        setRecipientError({
          ...recipientError,
          [id]: `${toTitleCase(id)} must not have numeric value`,
        });
      }

      val = toTitleCase(eventValue);
    }

    setRecipient({ ...recipient, [id]: val });
  };

  return (
    <Box>
      <Typo variant={isXs ? 'h3' : 'h1'} className={classes.header}>
        Review
      </Typo>
      <Box mt={2} mb={1}>
        <HorizontalLine className={classes.fatLine} />
      </Box>
      <Typo variant="h5">{prescription}</Typo>
      <Box mb={5} />
      <Typo variant={isXs ? 'h3' : 'h1'}>Recipient</Typo>
      <Box mt={2} mb={1}>
        <HorizontalLine className={classes.fatLine} />
      </Box>
      <Typo variant="h5">Who&apos;s the prescription for? If you want it sent to you, put your own email here.</Typo>
      <Box mb={isMobile ? 5 : 10} mt={isMobile ? 5 : 10}>
        <Grid container spacing={isMobile ? 2 : 4}>
          <Grid item xs={12} sm={4}>
            <InputField
              id={'firstName'}
              label={'First name'}
              shrink={true}
              value={recipient.firstName}
              error={recipientError.firstName}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              id={'lastName'}
              label={'Last name'}
              shrink={true}
              value={recipient.lastName}
              error={recipientError.lastName}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              id={'email'}
              label={'Email to send prescription to'}
              shrink={true}
              type={'email'}
              value={recipient.email}
              error={recipientError.email}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </Box>
      {!isXs && (
        <Button
          text={isLoggedIn ? 'Continue' : 'Continue as a guest'}
          onClick={() => onContinue(true)}
        />
      )}
      {!isXs && !isLoggedIn && (
        <Button
          style={{ marginTop: '40px', width: '60%' }}
          text="Login or Sign up"
          onClick={() => onContinue(false)}
        />
      )}
    </Box>
  );
};

// @ts-ignore
const RemedyKitAndProvisions: FC<T> = ({
  isMobile,
  isXs,
  classes,
  country,
  remedyKit,
  addonsList,
  setCurrentNav,
  checkedProvisions,
  setCheckedProvisions,
  loading,
}) => {
  const onContinue = () => {
    window.scrollTo(0, 0);
    setCurrentNav(2);
  };

  const onUpdateProvisions = (name: string, price: string, amount: string) => {
    const quantity = parseInt(amount, 10);
    if (quantity > 0) {
      setCheckedProvisions([
        ...checkedProvisions.filter(
          (provision: { text: string }) => provision.text !== name
        ),
        { text: name, value: Number(price), quantity },
      ]);
    } else if (quantity === 0 || !quantity) {
      setCheckedProvisions(
        checkedProvisions.filter(
          (provision: { text: string }) => provision.text !== name
        )
      );
    }
  };

  return (
    <Box>
      <Typo variant={isXs ? 'h3' : 'h1'}>Remedy Kit</Typo>
      <Box mt={2} mb={1}>
        <HorizontalLine className={classes.fatLine} />
      </Box>
      {remedyKit && (
        <ProvisionAccordion
          isQuantityOne={true}
          onUpdate={onUpdateProvisions}
          country={country}
          {...remedyKit}
        />
      )}
      {/* <Box mt={isMobile ? 4 : 8} />
      <Typo variant={isXs ? 'h3' : 'h1'}>Provisions</Typo>
      <Box mt={2}>
        <HorizontalLine className={classes.fatLine} />
      </Box> */}
      <Box mt={2}>
        {/* {provisions && provisions.length > 0 && ( */}
        {/*  <> */}
        {/*    {provisions.map( */}
        {/*      ( */}
        {/*        provision: { quantity: number; text: string }, */}
        {/*        index: number, */}
        {/*      ) => ( */}
        {/*        <Typo key={index} variant="body3"> */}
        {/*          {provision.quantity} {provision.text} */}
        {/*        </Typo> */}
        {/*      ), */}
        {/*    )} */}
        {/*  </> */}
        {/* )} */}

        {/* {loading && (
          <Box mt={8}>
            <LinearProgress color="secondary" />
          </Box>
        )} */}

        {/* {provisions && provisions.length === 0 && addonsList && ( */}
        {/* {!loading && addonsList && (
          <>
            {addonsList.map(
              ({
                name,
                description,
                price,
                provider,
                yield: yieldRange,
              }: {
                name: string;
                description: string;
                price: string;
                provider: string;
                yield: string;
              }) => (
                <ProvisionAccordion
                  key={name}
                  name={name}
                  onUpdate={onUpdateProvisions}
                  description={description}
                  price={price}
                  provider={provider}
                  yieldRange={yieldRange}
                />
              )
            )}
          </>
        )} */}
      </Box>
      <Box mb={isMobile ? 4 : 8} />
      {!isXs && (
        <Button
          text="Continue"
          variant={isMobile ? 'h6' : 'h5'}
          onClick={onContinue}
        />
      )}
    </Box>
  );
};

// @ts-ignore
const ConfirmAndPay: FC<T> = ({
  isXs,
  classes,
  prescription,
  recipient,
  addons,
  confirm,
  setConfirmLoading,
  initialUserValues,
  shipping,
  setShipping,
  remedyKit,
  country,
  total,
  isGuest,
}) => {
  const [value, setValue] = useState<ConfirmValues>(initialUserValues);
  const [error, setError] = useState<ConfirmValues>(paymentFormInitialState);
  const [phoneCountry, setPhoneCountry] = useState(country);

  const paymentIntent = useSelectorSafe((state) => state.paymentIntent);
  const paymentAfterpay = useSelectorSafe((state) => state.paymentAfterpay);

  const intent = paymentIntent?.data;
  const afterpay = paymentAfterpay?.data;

  let validIntent = false;
  const amount = total;
  const email = value.email;
  let description = '',
    metadata = {};
  if (prescription) {
    description = `${prescription.prescription} puchased by ${email}`;

    metadata = {
      contentfulId: prescription.id,
      recipient: `${recipient.firstName} ${recipient.lastName} (${recipient.email})`,
      title: prescription.prescription,
      shipping,
    };
  }

  if (
    intent &&
    (intent as any).secret &&
    (intent as any).amount == amount &&
    (intent as any).email == email &&
    (intent as any).description == description &&
    (intent as any).country == country
  ) {
    validIntent = true;
  }

  if (
    afterpay &&
    (afterpay as any).orderToken &&
    (afterpay as any).amount == amount &&
    (afterpay as any).email == email &&
    (afterpay as any).description == description &&
    (afterpay as any).country == country
  ) {
    validIntent = true;
  }

  const onChange = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { target } = event;
    const { id, value: eventValue } = target;

    setError({ ...error, [id]: '' });

    let val = eventValue;
    if (id == 'firstName' || id == 'lastName') {
      if (/\d/.test(val)) {
        setError({
          ...error,
          [id]: `${toTitleCase(id)} must not have numeric value`,
        });
      }

      val = toTitleCase(eventValue);
    }

    // if (id == 'postCode') {

    //   val = match_postcode(country, val);

    //   if (!is_valid_postcode(country, val))  {
    //     setError({ ...error, [id]: `Invalid postcode.` })
    //   }
    // }

    const newValue = { ...value, [id]: val };
    setValue(newValue);

    if (id == 'firstName' || id == 'lastName') {
      setValue({
        ...newValue,
        cardName: `${newValue.firstName} ${newValue.lastName}`,
      });
    }
  };

  const hasRemedy = addons.some(
    (cp: { text: string }) => cp.text === remedyKit.name
  );

  const onChangeMobile = (val: any) => {
    const res = phone(val);

    setError({
      ...error,
      mobile: !res.isValid
        ? 'Choose your country code, input your mobile number, and remove + or 0 from the start'
        : '',
    });
    setValue({ ...value, mobile: res.isValid ? res.phoneNumber : val || '' });
  };

  useEffect(() => {
    const newValue = { ...initialUserValues };

    for (let key in value) {
      if (value[key]) {
        newValue[key] = value[key];
      }
    }

    const { cardName, firstName, lastName } = newValue;
    if ((!cardName && firstName) || lastName) {
      newValue.cardName = `${firstName} ${lastName}`;
    }

    setValue(newValue);
  }, [initialUserValues]);

  const setupProps = {
    intentStatus: paymentIntent?.status || ASYNC_STATUS.INITIAL,
    afterpayStatus: 'DISABLED', // paymentAfterpay?.status || ASYNC_STATUS.INITIAL,
    intent,
    afterpay,
    amount,
    email,
    description,
    metadata,
    country,
    name: `${value.firstName} ${value.lastName}`,
    firstName: value.firstName,
    lastName: value.lastName,
    phone: value.mobile,
    city: value.city,
    address: value.address,
    state: value.state,
    postCode: value.postCode,
    isGuest,
  };

  return (
    <Box>
      <Typo variant={isXs ? 'h3' : 'h1'}>Confirm & Pay</Typo>
      <Box mt={2} mb={1}>
        <HorizontalLine className={classes.fatLine} />
      </Box>
      <Typo variant="h5">Enter your details</Typo>
      <Box mb={3} />
      <Grid container direction="row">
        {paymentFormFields.map(({ id, label, type }) => (
          <Grid key={id} item xs={12} sm={6}>
            <Box mb={6} pr={isXs ? 0 : '15%'}>
              {id !== 'mobile' ? (
                <InputField
                  id={id}
                  label={label}
                  shrink={true}
                  type={type}
                  value={value[id]}
                  error={error[id]}
                  onChange={onChange}
                />
              ) : (
                <div>
                  <label className={classes.inputLabel} htmlFor={'mobile'}>
                    Mobile
                  </label>
                  <PhoneInput
                    placeholder={
                      phoneCountry
                        ? `Ex: ${PhoneTemplate[phoneCountry as string]}`
                        : undefined
                    }
                    countries={['AU', 'NZ', 'GB']}
                    defaultCountry="AU"
                    country={country}
                    value={(value[id] || '') as any}
                    onChange={onChangeMobile}
                    onCountryChange={(c) => setPhoneCountry(c)}
                    className={classes.mobile}
                    numberInputProps={{
                      className: classes.inputMobile,
                      classes: {
                        input: classes.inputRoot,
                        disabled: classes.disabled,
                      },
                    }}
                  />
                  {error[id] ? (
                    <p className={classes.mobileError}>{error[id]}</p>
                  ) : null}
                </div>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>

      <Box mb={3} />
      {total > 0 && !validIntent ? (
        <PaymentSetup {...setupProps} />
      ) : (
        <>
          <CheckoutForm
            intent={intent}
            afterpay={afterpay}
            total={total}
            confirm={confirm}
            formDetails={value}
            formErrors={error}
            setValue={setValue}
            setError={setError}
            setConfirmLoading={setConfirmLoading}
            prescriptionDetails={{
              recipient,
              purchaser: {
                name: `${value.firstName} ${value.lastName}`,
                email: `${value.email}`,
              },
              id: prescription.id,
              shipping,
              symptom: prescription.symptom,
              title: prescription.prescription,
              price: prescription.price,
              giftValue: prescription.giftValue,
              currency: prescription.currency,
              provisions: prescription.provisions,
              country,
            }}
            addons={addons}
          />
        </>
      )}
      {!isXs && <p>Note: Digital prescriptions can take up to 24hrs.</p>}
    </Box>
  );
};

const OtherNav = ['Review & Recipient', 'Confirm & Pay'];

const VoucherPaymentsView: FC<VoucherPaymentsProps> = (props) => {
  const {
    loading,
    total,
    priceDetails,
    voucherCard,
    remedyKit,
    provisions,
    addonsList,
    checkedProvisions,
    setCheckedProvisions,
    initialUserValues,
    shipping,
    setShipping,
    goToLogin,
    isLoggedIn,
    redirectStep,
    initialRecipientValues,
    country,
  } = props;

  const [confirm, setConfirm] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [currentNav, setCurrentNav] = useState(redirectStep || 0);
  const [recipient, setRecipient] = useState(
    initialRecipientValues || {
      firstName: '',
      lastName: '',
      email: '',
    }
  );
  const [recipientError, setRecipientError] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [isGuest, setIsGuest] = useState(true);

  const nav = OtherNav;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles();

  const invalidRecipient = () => {
    const { firstName, lastName, email } = recipient;

    let hasError;
    if (!firstName) {
      setRecipientError((prevState) => ({
        ...prevState,
        firstName: 'Please enter first name',
      }));
      hasError = true;
    }
    if (!lastName) {
      setRecipientError((prevState) => ({
        ...prevState,
        lastName: 'Please enter last name',
      }));
      hasError = true;
    }
    if (!email) {
      setRecipientError((prevState) => ({
        ...prevState,
        email: 'Please enter an email',
      }));
      hasError = true;
    }
    if (email && !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      setRecipientError((prevState) => ({
        ...prevState,
        email: 'Please enter a valid email',
      }));
      hasError = true;
    }

    return hasError;
  };

  const onClickGhostButton = (isGuest: boolean) => {
    if (currentNav !== 1) {
      window.scrollTo(0, 800);
      if (currentNav === 0 && !invalidRecipient()) {
        setIsGuest(isGuest);
        if (!isLoggedIn && !isGuest) {
          goToLogin(recipient);
        } else {
          setCurrentNav(0);
        }
      }
      if (currentNav === 0) setCurrentNav(1);
    } else {
      setConfirm((prevState) => prevState + 1);
    }
  };

  const onClickNav = (index: number) => {
    if (index !== 0 && invalidRecipient()) return;
    setCurrentNav(index);
  };

  const spring1 = useSpring({
    display: currentNav === 0 ? 'block' : 'none',
    opacity: currentNav === 0 ? 1 : 0,
  });
  const spring2 = useSpring({
    display: currentNav === 1 ? 'block' : 'none',
    opacity: currentNav === 1 ? 1 : 0,
  });
  const spring3 = useSpring({
    display: currentNav === 2 ? 'block' : 'none',
    opacity: currentNav === 2 ? 1 : 0,
  });

  const CheckoutProcess = (
    <Grid item xs={12} sm={9}>
      <Box pr={isXs ? 0 : '15%'}>
        <>
          <animated.div style={spring1}>
            <ReviewAndRecipient
              isMobile={isMobile}
              isXs={isXs}
              classes={classes}
              prescription={voucherCard.prescription}
              currency={voucherCard.currency}
              price={voucherCard.price}
              recipient={recipient}
              recipientError={recipientError}
              setRecipient={setRecipient}
              setRecipientError={setRecipientError}
              setCurrentNav={setCurrentNav}
              invalidRecipient={invalidRecipient}
              isLoggedIn={isLoggedIn}
              goToLogin={goToLogin}
              setIsGuest={setIsGuest}
            />
          </animated.div>
          <animated.div style={spring2}>
            <ConfirmAndPay
              isXs={isXs}
              classes={classes}
              total={total}
              country={country}
              recipient={recipient}
              addons={checkedProvisions}
              prescription={voucherCard}
              confirm={confirm}
              setConfirmLoading={setConfirmLoading}
              initialUserValues={initialUserValues}
              shipping={shipping}
              setShipping={setShipping}
              remedyKit={remedyKit}
              isGuest={isGuest}
            />
          </animated.div>
        </>
      </Box>
    </Grid>
  );

  const CheckoutCard = (
    <Grid item xs={12} sm={3}>
      <Box mt={isXs ? 0 : 7} pt={0}>
        <VoucherCardPayments
          priceDetails={priceDetails}
          voucherCardProps={voucherCard}
        />
      </Box>
    </Grid>
  );

  return (
    <Box>
      {isXs && (
        <>
          {CheckoutCard}
          <Box mb={4}></Box>
        </>
      )}
      <Grid container alignItems="center">
        {nav.map((text, index) => (
          <Fragment key={index}>
            <Box
              className={classes.navButton}
              onClick={() => onClickNav(index)}
            >
              <Typo
                variant="h6"
                style={{ opacity: currentNav === index ? 1 : 0.5 }}
              >
                {index + 1}. {text}
              </Typo>
            </Box>
            {index !== nav.length - 1 && (
              <Box ml={2} mr={2}>
                <ArrowForwardIcon
                  className={classes.navArrow}
                  style={{ opacity: currentNav === index ? 1 : 0.5 }}
                />
              </Box>
            )}
          </Fragment>
        ))}
      </Grid>
      <Box mb={5} />
      <Grid container>
        {!isXs ? [CheckoutProcess, CheckoutCard] : [CheckoutProcess]}
      </Grid>

      {isXs && (currentNav || 0) < 1 && (
        <Box mt={3}>
          {currentNav === 0 ? (
            <>
              <GhostButton
                text={isLoggedIn ? 'Continue' : 'Continue as a guest'}
                loading={confirmLoading}
                style={classes.ghostButton}
                onClick={() => onClickGhostButton(true)}
              />
              <GhostButton
                text={'Login or Sign up'}
                loading={confirmLoading}
                style={classes.ghostButton}
                onClick={() => onClickGhostButton(false)}
              />
            </>
          ) : (
            <GhostButton
              text={currentNav === 1 ? 'Confirm' : 'Continue'}
              loading={confirmLoading}
              style={classes.ghostButton}
              onClick={() => onClickGhostButton(isGuest)}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default React.memo(VoucherPaymentsView);
