import React, { useState } from 'react';

import { Box } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import { CheckboxChecked2, CheckboxUnchecked } from 'assets/svgs';
import { Modal, Typo } from 'components/primitives';
import pick from 'ramda/es/pick';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { LocationsState } from 'types/store/CountryState';
import { asyncData } from 'utils/Redux';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { GhostButton } from 'components/primitives';
import userThunks from 'thunks/user';
import { useRedirectAccount } from './AppBar.container';

interface AccountProps {
  showCountry: boolean;
  setShowCountry: React.Dispatch<React.SetStateAction<boolean>>;
}

const locationsFallback: LocationsState = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not get locations' },
]);

const Account = (props: AccountProps) => {
  const { showCountry, setShowCountry } = props;
  const dispatch = useDispatch();
  const profile = useSelectorSafe((state) => state.profile);
  const [checkedCountry, setCheckedCountry] = useState('');
  const { redirectAccount } = useRedirectAccount(setShowCountry);

  const locations = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<LocationsState>(
      (state) => state.locations,
      locationsFallback
    )
  );

  const countries = locations?.data?.countries;
  const onClose = () => setShowCountry(false);
  const onConfirm = () => {
    onClose();
    redirectAccount(checkedCountry);
    dispatch(
      userThunks.updateProfile({
        ...profile,
        accountCountry: checkedCountry,
      })
    );
  };

  return (
    <Modal open={showCountry} onClose={onClose}>
      <Box
        pl={5}
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <Typo variant="h5">Country</Typo>
        <Typo variant="body2">Choose one only</Typo>
        <br />
        {countries &&
          Object.keys(countries).map((code) => (
            <Box m={1} key={code}>
              <Badge
                color="primary"
                onClick={() => setCheckedCountry(code)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 15,
                }}
              >
                {checkedCountry && checkedCountry == code ? (
                  <CheckboxChecked2 />
                ) : (
                  <CheckboxUnchecked />
                )}
                {countries[code].name}
              </Badge>
            </Box>
          ))}
        <br />

        <Box mx="auto">
          <GhostButton text="Confirm" onClick={onConfirm} />
        </Box>
      </Box>
    </Modal>
  );
};

export default Account;
