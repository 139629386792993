import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import Typo from '../../primitives/Typo';
import { FilterInputProps } from './FilterInput.props';
import { useStyles } from './FilterInput.styles';

const FilterInputView: FC<FilterInputProps> = (props: FilterInputProps) => {
  const {
    id,
    placeholder,
    onChange,
    value,
    type,
    label,
    color,
    backgroundColor,
    readOnly,
    autoComplete,
  }: FilterInputProps = props;

  const classes = useStyles({ color, backgroundColor });

  return (
    <Box>
      <Typo className={classes.inputLabel} variant="body0">
        {label}
      </Typo>
      <TextField
        id={id}
        placeholder={placeholder}
        className={classes.inputField}
        InputProps={{
          disableUnderline: true,
          className: classes.input,
          classes: { input: classes.inputRoot },
          readOnly: Boolean(readOnly),
          autoComplete,
        }}
        autoComplete={autoComplete}
        onChange={onChange}
        value={value}
        type={type || 'text'}
      />
    </Box>
  );
};

export default FilterInputView;
