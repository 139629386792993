import createAccount from './createAccount';
import facebookAccess from './facebookAccess';
import forgotPassword from './forgotPassword';
import googleAccess from './googleAccess';
import login from './login';
import logout from './logout';
import resetPassword from './resetPassword';
import sendVerificationEmail from './sendVerificationEmail';
import updatePassword from './updatePassword';
import updateProfile from './updateProfile';

const authThunks = {
  login,
  logout,
  createAccount,
  facebookAccess,
  googleAccess,
  forgotPassword,
  resetPassword,
  sendVerificationEmail,
  updatePassword,
  updateProfile,
};

export default authThunks;
