import React from "react";
import useSelectorSafe from "store/selectors/useSelectorSafe";
import { Modal, Typo } from "..";
import { Box, Button } from "@material-ui/core";
import { useStyles } from "./Modal.styles";
import { ModalPublicProps } from "./Modal.props";

export const BlockModal = (props: { onLeave?: Function } ) => {

  const classes = useStyles({} as ModalPublicProps)

  const blockNavigation = useSelectorSafe(state => state.blockNavigation);
  const message = blockNavigation?.message;
  const canLeave = blockNavigation?.canLeave;
  
  const onLeave = () => {
    canLeave(true);
    props.onLeave && props.onLeave()
  }

  const onCancel = () => {
    canLeave(false);
  }

  return (
    <Modal 
      rootStyle={classes.rootBlock}
      paperStyle={classes.paperBlock}
      contentContainerStyle={classes.contentBlock}
      open={!!message}
      onClose={() => canLeave(false)}
    >
      <Typo style={{ fontFamily: 'Italianplate,Helvetica,Arial,sans-serif', textAlign: 'center' }}>
        {message}
      </Typo>
      <Box mb={4} />
      <div style={{ float: 'right' }}>
        <Button onClick={onLeave}>Ok</Button> <Button color="secondary" onClick={onCancel}>Cancel</Button>
      </div>
      
    </Modal>)
}

export default React.memo(BlockModal);