import { Dispatch } from 'redux';
import { subscribeEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { commonActions } from 'store/actions';

import { Store } from '../../types/store/Store';

interface AccountCredentials {
  email: string;
  firstName: string;
  lastName: string;

  newsletter?: boolean;
  cities?: string[];
}

const subscribe =
  (credentials: AccountCredentials) =>
  (dispatch: Dispatch, getState: () => Store) => {
    dispatch(
      commonActions.subscribe(
        apiFetch<AccountCredentials, {}>(subscribeEndpoint, credentials)(
          dispatch,
          getState
        ).then(async (res) => {
          const data = res.data;
          window.analytics.identify(data.id, {
            ...data,
          });

          await new Promise((r) => setTimeout(r, 200));
          window.analytics.reset();
        })
      )
    );
  };

export default subscribe;
