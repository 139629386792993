export const AU: {[key: string] : string} = {
  'Adelaide': 'https://bookings.unyoked.co/adelaide',
  'Brisbane': 'https://bookings.unyoked.co/brisbane',
  'Byron': 'https://bookings.unyoked.co/byron-bay',
  'Canberra': 'https://bookings.unyoked.co/canberra',
  'Gold Coast': 'https://bookings.unyoked.co/gold-coast',
  'Hunter Valley': 'https://bookings.unyoked.co/hunter-valley',
  'Melbourne': 'https://bookings.unyoked.co/melbourne',
  'Perth': 'https://bookings.unyoked.co/perth',
  'Sydney': 'https://bookings.unyoked.co/sydney',
}
