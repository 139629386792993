import React, { FC, useState } from 'react';

import { CONTENTFUL_SMALL_IMG, PROGRESSIVE_IMG_TRANSFORMER } from 'consts/urls';

import { ImageProps } from './Image.props';

const CONTENTFUL_HOST = 'images.ctfassets.net';

const ImageView: FC<ImageProps> = (props: ImageProps) => {
  const { key, ...prop } = props;
  const isContentful =
    typeof props.src === 'string'
      ? props.src?.includes(CONTENTFUL_HOST)
      : false;
  const [loaded, setLoaded] = useState(false);

  const imgStyles = {
    ...(prop.style || {}),
    display: 'block',
    opacity: loaded ? 1 : 0,
    marginTop: loaded ? 'inherit' : 3,
    transition: 'all linear 0.5s',
  };

  const bg = isContentful ? CONTENTFUL_SMALL_IMG(props.src || '') : props.src;

  const containerStyles = {
    display: loaded ? 'contents' : 'flex',
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    height: '100%',
    backgroundPostion: 'center',
    ...(prop.style || {}),
  };

  const src = isContentful
    ? PROGRESSIVE_IMG_TRANSFORMER(props.src || '')
    : props.src;

  return (
    <>
      <div style={containerStyles}>
        <img
          {...prop}
          key={key}
          src={src}
          style={isContentful ? props.style : imgStyles}
          onLoad={() => setLoaded(true)}
        />
      </div>
    </>
  );
};

export default ImageView;
