import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    card: {
      paddingLeft: '2%',
      paddingRight: '2%',
      marginBottom: '10%',
    },
    paginationContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
);
