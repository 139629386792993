import React, { FC } from 'react';

import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';

import { NewsletterPublicProps } from './Newsletter.props';
import View from './Newsletter.view';

const NewsletterContainer: FC<NewsletterPublicProps> = (props) => {
  const status = useSelectorSafe((state) => state.subscribe.status);
  return (
    <View
      success={status === ASYNC_STATUS.COMPLETE}
      loading={status === ASYNC_STATUS.LOADING}
      {...props}
    />
  );
};

export default React.memo(NewsletterContainer);
