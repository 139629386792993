import React, { FC, useRef, useState, useLayoutEffect } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';

import { rules, validatePassword } from '../../../utils/Password';
import {
  Button,
  GhostButton,
  HorizontalLine,
  InputField,
  Link,
  Popper,
  Snackbar,
  Typo,
} from '../../primitives';
import { SecuritySettingsProps } from './SecuritySettings.props';
import { useStyles } from './SecuritySettings.styles';
import Collapse from '@material-ui/core/Collapse';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const CollapseBox = () => {
  const [isCollapse, setIsCollapse] = useState(false);

  useLayoutEffect(() => {
    const a = document.querySelector('#ot-content');
    const b = document.querySelector('#with-list');
    if (!b || !a) return;
    b.append(a);
    a.style.display = 'block';
  });

  return (
    <Box display="none">
      <Box onClick={() => setIsCollapse(!isCollapse)}>
        <Box mr={0.5} display="flex" borderBottom="2px solid" pb="10px">
          <Typo variant="h4">Consent Cookies</Typo>
          {isCollapse ? (
            <ExpandLess color="secondary" />
          ) : (
            <ExpandMore color="secondary" />
          )}
        </Box>
      </Box>
      <ul>
        <Collapse in={isCollapse}>
          <div id="with-list"></div>
        </Collapse>
      </ul>
    </Box>
  );
};

const SecuritySettingsView: FC<SecuritySettingsProps> = ({
  updatePassword,
  loading,
  success,
  errorMessage,
  openErrorSnack,
  goToAccount,
}) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const opcRef = useRef(null);
  const npcRef = useRef(null);

  const isPopperOpen = Boolean(anchorEl);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const classes = useStyles();

  const onChangeOldPassword = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    setOldPasswordError('');
    setOldPassword(event.target.value);
  };

  const onChangeNewPassword = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    setNewPasswordError('');
    setNewPassword(event.target.value);
  };

  const onChangeConfirmPassword = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    setConfirmPasswordError('');
    setConfirmPassword(event.target.value);
  };

  const onUpdate = () => {
    let hasError = false;

    const vop = validatePassword(oldPassword);
    if (Array.isArray(vop) && vop.length > 0) {
      setOldPasswordError('Please enter a valid password');
      hasError = true;
    }

    const vnp = validatePassword(newPassword);
    if (Array.isArray(vnp) && vnp.length > 0) {
      setNewPasswordError('Please enter a valid password');
      hasError = true;
    }

    const vcp = validatePassword(confirmPassword);
    if (Array.isArray(vcp) && vcp.length > 0) {
      setConfirmPasswordError('Please enter a valid password');
      hasError = true;
    }

    if (newPassword !== confirmPassword) {
      setConfirmPasswordError('The passwords does not match');
      hasError = true;
    }

    if (!hasError) updatePassword(oldPassword, confirmPassword);
  };

  return (
    <Box>
      <Link onClick={goToAccount}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ArrowBackIcon className={classes.arrow} />
          <Typo variant="h6">Account Settings</Typo>
        </Box>
      </Link>
      <Box mb={isXs ? 4 : 8} />
      <Typo variant={isMobile ? 'h3' : 'h1'} className={classes.header}>
        Privacy and Security Settings
      </Typo>
      {!isXs && (
        <Box mt={3}>
          <HorizontalLine className={classes.horizontalLine} />
        </Box>
      )}
      <Grid
        container
        spacing={isXs ? 0 : 10}
        className={classes.contentContainer}
      >
        <Grid item xs={12} sm={6}>
          <div ref={opcRef}>
            <InputField
              id="oldPassword"
              label="Password"
              shrink={true}
              placeholder="Current password"
              type="password"
              value={oldPassword}
              error={oldPasswordError}
              onChange={onChangeOldPassword}
              onFocus={() => setAnchorEl(opcRef.current)}
              onBlur={() => setAnchorEl(null)}
            />
          </div>
          <Box mb={5} />
          <div ref={npcRef}>
            <InputField
              id="newPassword"
              placeholder="New password"
              type="password"
              value={newPassword}
              error={newPasswordError}
              onChange={onChangeNewPassword}
              onFocus={() => setAnchorEl(npcRef.current)}
              onBlur={() => setAnchorEl(null)}
            />
          </div>
          <Box mb={5} />
          <InputField
            id="confirmPassword"
            placeholder="Confirm password"
            type="password"
            value={confirmPassword}
            error={confirmPasswordError}
            onChange={onChangeConfirmPassword}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {!isXs ? (
            <Box mt={'15px'}>
              <Button
                onClick={onUpdate}
                variant="h4"
                text="Update Password"
                loading={loading}
                success={success}
              />
            </Box>
          ) : (
            <Box mt={10}>
              <GhostButton
                text="Update Password"
                style={classes.ghostButton}
                onClick={onUpdate}
                loading={loading}
                success={success}
              />
            </Box>
          )}
        </Grid>
        <CollapseBox />
      </Grid>

      <Popper
        open={isPopperOpen}
        anchorEl={anchorEl && anchorEl}
        placement={isXs ? 'bottom-end' : 'right'}
        margin={isXs ? '10px 0 0 0' : 20}
        content={
          <Box style={{ padding: '10px 10px 5px 10px' }}>
            {rules.map((rule) => (
              <Typo key={rule} variant="h6" style={{ marginBottom: 5 }}>
                {rule}
              </Typo>
            ))}
          </Box>
        }
      />

      <Snackbar message={errorMessage} open={openErrorSnack} type="error" />
    </Box>
  );
};

export default React.memo(SecuritySettingsView);
