import { Dispatch } from 'redux';
import {
  getPaymentIntentEndpoint,
  getPaymentMethodEndpoint,
} from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { userActions } from 'store/actions';

import { Store } from '../../types/store/Store';

interface Data {
  country: string;
  amount?: number;
  description?: string;
  email?: string;
  name: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  postCode: string;
  metadata?: {
    [key: string]: any;
  };
  method?: string;
}

const getPaymentIntent =
  (data: Data) => (dispatch: Dispatch, getState: () => Store) => {
    dispatch(
      userActions.getPaymentIntent(
        apiFetch<Data, any>(getPaymentIntentEndpoint, data)(dispatch, getState)
      )
    );
  };

export default getPaymentIntent;
