import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors, fontFamilies } from '../../../themeConfig/themeConstants';
import { pxToRem } from '../../../themeConfig/typography';
import { JournalSubmitFieldPublicProps } from './JournalSubmitField.props';

const { pitch } = fontFamilies;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: ({ color }: JournalSubmitFieldPublicProps) => ({
      fontFamily: pitch,
      fontSize: pxToRem(12),
      color: color || colors.primaryYellow,
      borderBottom: `1px solid ${color || colors.primaryYellow}`,
      paddingLeft: 2,
      paddingRight: 2,
    }),
    inputRoot: {
      '&::placeholder': {
        opacity: 1,
      },
    },
    error: {
      paddingLeft: 2,
      paddingRight: 2,
    },
    endAdornmentRoot: {
      marginRight: 2,
    },
    endAdornmentText: ({ color }) => ({
      color: color || colors.primaryYellow,
      userSelect: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    endAdornmentIconButton: {
      paddingRight: 6,
      color: ({ color }: JournalSubmitFieldPublicProps) =>
        color || colors.primaryYellow,
    },
  })
);
