import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: '100%',
      height: 'auto',
    },
    title: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 32,
      },
    },
    step: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 24,
      },
    },
  })
);
