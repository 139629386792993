import React, { FC, useEffect, useRef } from 'react';

import { pick } from 'ramda';
import { useDispatch } from 'react-redux';
import ROUTES from 'routes';
import { filterActions, routerActions } from 'store/actions';
import useCurrentRoute from 'store/selectors/useCurrentRoute';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import entityThunks from 'thunks/entity';
import hubspotThunks from 'thunks/hubspot';
import userThunks from 'thunks/user';
import { Filter } from 'types/Filter';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { bookingQuestionsState } from 'types/store/BookingQuestionsState';
import { FilterState } from 'types/store/FilterState';
import { asyncData } from 'utils/Redux';

import { CalendarEntitiesData } from '../HomeFilter/HomeFilter.props';
import {
  BookingModalPublicProps,
  BookingModalProps,
} from './BookingModal.props';
import BookingModalView from './BookingModal.view';

const entitiesFallback: CalendarEntitiesData = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not get entities' },
]);

const BookingModalContainer: FC<BookingModalPublicProps> = (
  publicProps: BookingModalPublicProps
) => {
  const dispatch = useDispatch();

  const filterData = useSelectorSafe<FilterState>(
    (state) => state.filter,
    null
  );

  const currentRoute = useCurrentRoute();

  const currentUser = useSelectorSafe((state) => {
    return state.user && state.user.data && state.user.data.user;
  });

  const entities: CalendarEntitiesData = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<CalendarEntitiesData>(
      (state) => state.calendarEntities,
      entitiesFallback
    )
  );

  const bookingQuestions = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<bookingQuestionsState>(
      (state) => state.bookingQuestions,
      asyncData(ASYNC_STATUS.ERROR, [
        { message: 'Could not get booking questions' },
      ])
    )
  );

  const questions = bookingQuestions.data?.questions;
  const isMounted = useRef(true);
  useEffect(() => {
    if (isMounted.current) {
      dispatch(hubspotThunks.getBookingQuestions());
      if (currentRoute.id != 'LOGIN_SIGNUP') {
        dispatch(userThunks.getProfile({}));
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const combinedProps: BookingModalProps = {
    ...publicProps,
    // calcedProps
    questions,
    filterData,
    goToResults: (country, location) => {
      const loc = location || undefined;
      dispatch(routerActions.link(ROUTES.RESULTS, { country, location: loc }));
    },
    isResultsPage: Boolean(currentRoute.id === 'RESULTS'),
    updateFilter: (payload: Filter) =>
      dispatch(filterActions.updated({ ...filterData, ...payload })),
    filter: (filter?: Filter) => {
      if (!filter && filterData) filter = filterData;

      if (filter) {
        dispatch(entityThunks.get(filter));
      }
    },
    // submitQuestions: (data: any) =>
    //   dispatch(
    //     hubspotThunks.postBookingQuestions({
    //       email: currentUser?.email,
    //       ...data,
    //     })
    //   ),
    entities,
  };

  return  <BookingModalView {...combinedProps} /> ;
};

export default BookingModalContainer;
