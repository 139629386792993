import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';
import { pxToRem } from '../../../themeConfig/typography';

interface StyleProps {
  displayArea: undefined | 'Home';
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: ({ displayArea }: StyleProps) => {
      if (displayArea === 'Home') {
        return {
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
          marginBottom: 10,
        };
      }
      return {
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
        marginBottom: 10,
        // below tablets
        [theme.breakpoints.up(767)]: {
          height: 130,
        },
        [theme.breakpoints.up('md')]: {
          height: 160,
        },
        [theme.breakpoints.up('lg')]: {
          height: 190,
        },
      };
    },
    noImage: {
      width: '100%',
      height: 'auto',
      marginBottom: 18,
      // below tablets
      [theme.breakpoints.up(767)]: {
        minHeight: 130,
        height: 130,
      },
      [theme.breakpoints.up('md')]: {
        minHeight: 160,
        height: 160,
      },
      [theme.breakpoints.up('lg')]: {
        minHeight: 190,
        height: 190,
      },
    },
    imageCaption: {
      marginBottom: 10,
    },
    line: {
      backgroundColor: colors.primaryDarkGreen,
      height: 5,
      marginBottom: 15,
    },
    line2: {
      backgroundColor: colors.primaryDarkGreen,
      height: 5,
      marginBottom: 30,
    },
    titleArrowContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'end',

      '& svg': {
        fill: colors.primaryDarkGreen,
      },
    },
    title: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 26,
      },
    },
    arrow: {
      color: colors.primaryDarkGreen,
      padding: '0 7px 0 10px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    subTitle: {
      fontSize: pxToRem(16),
      marginTop: 10,
      marginBottom: 10,
    },
    desc: {
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': 3,
      [theme.breakpoints.up('md')]: {
        '-webkit-line-clamp': 5,
        width: '70%',
      },
      marginTop: 5,
      marginBottom: 15,
    },

    link: {
      textDecoration: 'none',
      cursor: 'pointer',
    },
  })
);
