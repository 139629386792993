import { ComponentsProps } from '@material-ui/core/styles/props';

import MuiButtonBase from './MuiButtonBase';

// Look at the material UI docs for examples of how to override their props.
const props: ComponentsProps = {
  MuiButtonBase,
};

export default props;
