import React, { FC } from 'react';

import { VoucherCardPaymentsPublicProps } from './VoucherCardPayments.props';
import View from './VoucherCardPayments.view';

const VoucherCardPaymentsContainer: FC<VoucherCardPaymentsPublicProps> = (
  props
) => {
  return <View {...props} />;
};

export default React.memo(VoucherCardPaymentsContainer);
