import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';

interface Style {
  isMobile: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 26,
      },
    },
    checkbox: ({ isMobile }: Style) => ({
      height: 40,
      width: 40,
      transform: 'scale(0.5)',
      '&:hover': !isMobile
        ? {
            backgroundColor: colors.primaryDarkGreen,
          }
        : {},
      '& path': {
        transform: 'none',
      },
    }),
    choicesContainer: ({ isMobile }: Style) => ({
      display: 'grid',
      gridTemplateColumns: isMobile ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)',
      marginTop: '10px',
    }),
  })
);
