import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';
import { GhostButtonPublicProps } from './GhostButton.props';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: ({ color, hoverColor }: GhostButtonPublicProps) => ({
      backgroundColor: 'transparent',
      border: `2px solid ${color ? colors[color] : colors.primaryDarkGreen}`,
      borderRadius: 0,
      width: '100%',
      height: '50px',
      color: color ? colors[color] : colors.primaryDarkGreen,
      textTransform: 'none',
      '&:hover': {
        border: `2px solid ${
          hoverColor ? colors[hoverColor] : colors.primaryDarkGreen
        }`,
        backgroundColor: hoverColor
          ? colors[hoverColor]
          : colors.primaryDarkGreen,
        color: color ? colors[color] : colors.primaryWhite,
      },
      '&:disabled': {
        cursor: 'none',
      },
    }),
    typography: {
      color: 'inherit',
    },
    icon: {
      color: ({ color }: GhostButtonPublicProps) =>
        // @ts-ignore
        colors[color] || colors.primaryDarkGreen,
      padding: 0,
    },
  })
);
