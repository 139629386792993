import React, { FC } from 'react';

import { ContentCarouselPublicProps } from './ContentCarousel.props';
import View from './ContentCarousel.view';

const ContentCarouselContainer: FC<ContentCarouselPublicProps> = (props) => {
  const crossedOutText = 'hotel';

  return (
    <View
      {...props}
      {...{
        crossedOutText,
      }}
    />
  );
};

export default React.memo(ContentCarouselContainer);
