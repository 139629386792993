import React, { FC } from 'react';

import { useDispatch } from 'react-redux';
import ROUTES from 'routes';
import { routerActions } from 'store/actions';

import { BlogCardPublicProps } from './BlogCard.props';
import View from './BlogCard.view';

const BlogCardContainer: FC<BlogCardPublicProps> = (props) => {
  const dispatch = useDispatch();
  return (
    <View
      {...props}
      onMoreLink={(id: string) => {
        return {
          type: 'ROUTER/BLOG_ITEM',
          payload: {
            id,
          },
        };
      }}
      onMore={(id: string) =>
        dispatch(
          routerActions.link(ROUTES.BLOG_ITEM, {
            id,
          })
        )
      }
      goToURL={(url: string) =>
        window.open(url, '_blank', 'noopener noreferrer')
      }
    />
  );
};

export default React.memo(BlogCardContainer);
