import React, { FC } from 'react';

import { HostPublicProps } from './Host.props';
import View from './Host.view';

const HostContainer: FC<HostPublicProps> = (props) => {
  return <View {...props} />;
};

export default React.memo(HostContainer);
