import React, {
  FC,
  useEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

import { Box } from '@material-ui/core';
import { FEATURE_FLAGS } from 'consts/featureFlags';
import { SHOP_URL } from 'consts/urls';
import { filter, is, pick } from 'ramda';
import { useDispatch } from 'react-redux';
import ROUTES from 'routes';
import { getContentfulEntry } from 'services/fetch/apiEndpoints';
import {
  authActions,
  countryActions,
  filterActions,
  routerActions,
  tokenActions,
} from 'store/actions';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import authThunks from 'thunks/auth';
import countryThunks from 'thunks/country';
import entityThunks from 'thunks/entity';
import featureFlagThunks from 'thunks/featureFlag';
import { City } from 'types/City';
import { Country } from 'types/Country';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { LocationsState } from 'types/store/CountryState';
import { CategoriesState, OffersState } from 'types/store/EntitiesState';
import { FeatureFlagsState } from 'types/store/FeatureFlagState';
import { FilterState } from 'types/store/FilterState';
import { useQueryWrapper } from 'utils/Hooks/useQueryWrapper';
import { asyncData } from 'utils/Redux';

import { INSTAGRAM_URL } from '../../../consts';
import { AppBarPublicProps, MobileItem } from './AppBar.props';
import View from './AppBar.view';
import { CabinFilter } from 'types/CabinFilter';
import { Nullable } from 'types/util/Nullable';
import Account from './Account.action';

const isProd = import.meta.env.VITE_NODE_ENV === 'prod';

export const useRedirectAccount = (
  setShowCountry: Dispatch<SetStateAction<boolean>>
) => {
  const dispatch = useDispatch();

  const redirectAccount = (accountCountry: string) => {
    if (!accountCountry) {
      setShowCountry(true);
    } else {
      if (accountCountry === 'AU') {
        window.location.replace(
          'https://guests-sso.bookeasy.com.au/login?client_id=694p7hj6ptjn1o25l8ctcdugrd&redirect_uri=https%3A%2F%2Fguests.bookeasy.com.au%2Flogin%2Fsignin-oidc-beguest&response_type=code&scope=openid%20profile&code_challenge=61YwYPQ-OJx7OYJO8nSw-Q0dch5ZM_B_PYO8jzOAnq4&code_challenge_method=S256&response_mode=form_post&nonce=638754606427519156.ZmE2NjNlOTctZTU0Yy00YzliLTljOGMtYzQ4YjU0Y2E5OWUyOWVjZDViNWUtYjEyMS00MDdhLWE0MTItNDAzNmY1ZjJjYzdh&state=CfDJ8F0ZZucdObZFpAse2FYk9_1cAgEC40EnREuOC-0X5TShkRza0xeHlOYvXW6-haBum6ZwYTS7Jo5y_eRKpU17ycoSvv5lINlrQHD7YWNCNAAi5o5YhY8PvTkFOE_cnzVRU4gVdelcwcfFMuFRCV2LERP45Hgeta8S_rfjUDdv0yvR1GWWglNhncCnREQ9jciU1gLlAX1vzT045VSOfIymz83e29B00TACiy-MNHCEsdDkaYTyCvAV5eeE4ObINenJY3lddq4-d0uvX-zRNU3i5QCvz4ioXHEMPwTmF4t7_SLBgixgFPQkC-x2nsl54MZnhbNf6qr3ngI0k--rQ0UKis0f4rcguLmluTF_-U-0Elevp-5jddvgB6dAkfKFxG6CGGu5ucw1qwv845XX-0QwBXbN1tKzv8ir7CVFFZAr4mnmCUIPQHQuv6-ipzW5EqyEKZKtYdBBV329v1cpyqJXPTowpLTbtsBl94N3sr4GZ0Ja&x-client-SKU=ID_NETSTANDARD2_0&x-client-ver=6.10.0.0'
        );
      } else {
        dispatch(
          routerActions.link(ROUTES.ACCOUNT, {
            pathName: 'account',
          })
        );
      }
    }
  };

  return {
    redirectAccount,
  };
};

const AppBarContainer: FC<AppBarPublicProps> = (props: AppBarPublicProps) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelectorSafe((state) => state.token);
  const profile = useSelectorSafe((state) => state.profile);
  const selectedCountry = useSelectorSafe((state) => state.country);
  const [showCountry, setShowCountry] = useState(false);
  const { redirectAccount } = useRedirectAccount(setShowCountry);

  const locations = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<LocationsState>(
      (state) => state.locations,
      asyncData(ASYNC_STATUS.ERROR, [{ message: 'Could not get locations' }])
    )
  );

  const featureFlags = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<FeatureFlagsState>(
      (state) => state.featureFlags,
      asyncData(ASYNC_STATUS.ERROR, [
        { message: 'Could not get feature flags' },
      ])
    )
  );

  const { data: ribbonData } = useQueryWrapper<string, any>(
    ['friendbuyRibbon'],
    getContentfulEntry,
    '6JmKc7cpj3v6SnWprL3bcC',
    {
      refetchInterval: 3000000,
      staleTime: 3000000,
    }
  );
  const ribbonLabel = ribbonData?.payload?.leftBlock || 'Get 15% Off';

  const filterData = useSelectorSafe<FilterState>(
    (state) => state.filter,
    null
  );

  const cabinFilter = useSelectorSafe<Nullable<CabinFilter>>(
    (state) => state.cabinFilter,
    null
  );

  // @ts-ignore
  const showRefer = featureFlags.data?.[FEATURE_FLAGS.REFER_CTA];
  const showMaintenance =
    !props.hideBanner && featureFlags.data?.[FEATURE_FLAGS.MAINTENANCE];
  const { data: maintenanceText } = useQueryWrapper<string, any>(
    ['maintenanceText'],
    getContentfulEntry,
    '2tbMyO6ahGKwgGlaKozBti',
    {
      refetchInterval: 3000000,
      staleTime: 3000000,
    }
  );

  const cities = locations.data?.cities;
  const countries = locations.data?.countries;

  const mobileEscapeLinks = [],
    leftBarLinks = [];

  const countryAction = (params: Country) => {
    const payload = {
      ...filterData,
      location: {
        address: '',
        cityId: '',
        country: params.code,
      },
    };
    dispatch(filterActions.updated(payload));
    dispatch(
      routerActions.link(ROUTES.RESULTS, {
        country: params.code,
      })
    );
  };

  const citiesAction = (params: City) => {
    const payload = {
      ...filterData,
      location: {
        address: params.name,
        cityId: params.id,
        country: params.country.code,
      },
    };
    dispatch(filterActions.updated(payload));
    dispatch(
      routerActions.link(ROUTES.RESULTS, {
        country: params.country.code,
        location: params.name,
      })
    );
  };
  if (cities && countries) {
    for (const code in cities) {
      mobileEscapeLinks.push({
        head: null,
        code,
        title: countries[code].name,
        onClick: () => {
          countries
            ? countryAction(countries[code])
            : dispatch(routerActions.link(ROUTES.RESULTS, { country: code }));
        },
      });

      leftBarLinks.push({
        head: null,
        code,
        text: countries[code].name || 'Australia',
        oonClick: () => {
          countries
            ? countryAction(countries[code])
            : dispatch(routerActions.link(ROUTES.RESULTS, { country: code }));
        },
      });

      for (const city of cities[code]) {
        mobileEscapeLinks.push({
          head: code,
          title: <Box pl={3}>{city.name}</Box>,
          onClick: () => {
            citiesAction(city);
          },
        });

        leftBarLinks.push({
          head: code,
          text: `${city.name}`,
          onClick: () => {
            citiesAction(city);
          },
        });
      }
    }
  }

  const showPrescription =
    selectedCountry?.countryCode !== 'NZ' ||
    (featureFlags?.data &&
      // @ts-ignore
      featureFlags?.data[FEATURE_FLAGS.SHOW_NZ_PRESCRIPTIONS]);

  const prescriptionLink = showPrescription
    ? [
        {
          title: 'Gift Prescriptions',
          onClick: () => dispatch(routerActions.link(ROUTES.VOUCHERS)),
        },
      ]
    : [];

  const mobileReferItem = showRefer
    ? {
        header: ribbonLabel || 'Get 15% Off',
        links: [
          {
            title: ribbonLabel || 'Get 15% Off',
            onClick: () => window.location.assign('/refer?cta=header'),
          },
        ],
        isAccordion: false,
        accordionStateKey: 'escape',
      }
    : {};

  const mobileItems: MobileItem[] = [
    {
      header: ' ',
      links: [
        {
          title: 'Login / Sign up',
          onClick: () =>
            dispatch(
              routerActions.link(ROUTES.LOGIN_SIGNUP, {
                pathName: 'login',
              })
            ),
        },
      ],
    },
    {
      header: 'Book',
      links: [],
      isButton: true,
      onClick: () => dispatch(filterActions.updated({ ...filter, open: true })),
    },
    // @ts-ignore
    { ...mobileReferItem },
    {
      header: 'Gift',
      links: [
        {
          title: 'Gift',
          onClick: () => dispatch(routerActions.link(ROUTES.VOUCHERS)),
        },
      ],
      isAccordion: false,
      accordionStateKey: 'escape',
    },
    {
      header: 'Store',
      links: [
        {
          title: 'Shop All',
          onClick: () => window.location.assign(SHOP_URL),
        },
        {
          title: 'Apparel',
          onClick: () => window.location.assign(SHOP_URL + '/apparel'),
        },
        {
          title: 'Books',
          onClick: () => window.location.assign(SHOP_URL + '/books'),
        },
        {
          title: 'Gear',
          onClick: () => window.location.assign(SHOP_URL + '/gear'),
        },
        {
          title: 'Records',
          onClick: () => window.location.assign(SHOP_URL + '/records'),
        },
      ],
      isAccordion: true,
      accordionStateKey: 'store',
    },
    {
      header: 'About',
      links: [
        {
          title: 'About Us',
          onClick: () => dispatch(routerActions.link(ROUTES.ABOUT)),
        },
        /*{
          title: 'Recommended Reading',
          onClick: () => dispatch(routerActions.link(ROUTES.BLOGS)),
        },*/
        {
          title: 'FAQ',
          onClick: () => dispatch(routerActions.link(ROUTES.FAQ)),
        },
        {
          title: 'Contact',
          onClick: () => dispatch(routerActions.link(ROUTES.CONTACT_US)),
        },
        {
          title: 'Host',
          onClick: () => dispatch(routerActions.link(ROUTES.HOST)),
        },
        {
          title: 'Unyoked at Work',
          onClick: () => dispatch(routerActions.link(ROUTES.UNYOKED_WORK)),
        },
      ],
      isAccordion: true,
      accordionStateKey: 'about',
    },
    {
      header: 'Resources',
      links: [
        {
          title: 'The Remedy Journal',
          onClick: () => dispatch(routerActions.link(ROUTES.JOURNAL)),
        },
        {
          title: 'The Science',
          onClick: () => dispatch(routerActions.link(ROUTES.SCIENCE)),
        },
        {
          title: 'Field Recordings',
          onClick: () => dispatch(routerActions.link(ROUTES.FIELD_RECORDINGS)),
        },
        {
          title: "Writer's Program",
          onClick: () => dispatch(routerActions.link(ROUTES.WRITERS_PROGRAM)),
        },
        {
          title: 'From The Field',
          onClick: () => dispatch(routerActions.link(ROUTES.FROM_THE_FIELD)),
        },
        {
          title: 'Dispatches',
          onClick: () => dispatch(routerActions.link(ROUTES.DISPATCHES)),
        },
        {
          title: 'Perspective',
          onClick: () => dispatch(routerActions.link(ROUTES.PERSPECTIVE)),
        },
        {
          title: 'Wellness',
          onClick: () => dispatch(routerActions.link(ROUTES.MENTAL_HEALTH)),
        },
        // {
        //   title: 'Creativity',
        //   onClick: () => dispatch(routerActions.link(ROUTES.CREATIVITY)),
        // },
        // {
        //   title: 'Relationships',
        //   onClick: () => dispatch(routerActions.link(ROUTES.RELATIONSHIPS)),
        // },
        // {
        //   title: 'Employee Wellness',
        //   onClick: () => dispatch(routerActions.link(ROUTES.EMPLOYEE_WELLNESS)),
        // },
      ],
      isAccordion: true,
      accordionStateKey: 'remedy',
    },
    {
      header: 'More',
      links: [
        {
          title: 'Waitlist',
          onClick: () =>
            dispatch(
              routerActions.link(ROUTES.RESULTS, {
                scrollToWaitlist: true,
              })
            ),
        },
        ...prescriptionLink,
        {
          title: 'Provisions',
          onClick: () => dispatch(routerActions.link(ROUTES.PROVISIONS)),
        },
        {
          title: 'Cabin Quiz',
          onClick: () => dispatch(routerActions.link(ROUTES.CABIN_QUIZ)),
        },
      ],
      isAccordion: true,
      accordionStateKey: 'more',
    },
  ];

  const subItems = [
    {
      title: 'Privacy',
      onClick: () => dispatch(routerActions.link(ROUTES.PRIVACY_POLICY)),
    },
    {
      title: 'T&Cs',
      onClick: () => dispatch(routerActions.link(ROUTES.TERMS_AND_CONDITIONS)),
    },
  ];

  const leftAppBarItems = [
    // {
    //   text: 'Escape',
    //   onClick: () => dispatch(routerActions.link(ROUTES.RESULTS)),
    //   subLinks: leftBarLinks,
    // },
    {
      text: 'The Science',
      onClick: () => dispatch(routerActions.link(ROUTES.SCIENCE)),
    },
    {
      text: 'About',
      onClick: () => dispatch(routerActions.link(ROUTES.ABOUT)),
    },
    {
      text: 'Journal',
      onClick: () => dispatch(routerActions.link(ROUTES.JOURNAL)),
    },
    {
      text: 'Gift',
      onClick: () => dispatch(routerActions.link(ROUTES.VOUCHERS)),
    },
  ];

  let rightAppBarItems = [
    {
      text: showRefer ? ribbonLabel || 'Get 15% Off' : '',
      onClick: () => window.location.assign('/refer?cta=header'),
    },
    {
      text: 'Host',
      onClick: () => dispatch(routerActions.link(ROUTES.HOST)),
    },
    {
      text: 'Login / Sign Up',
      onClick: () =>
        dispatch(
          routerActions.link(ROUTES.LOGIN_SIGNUP, {
            pathName: 'login',
          })
        ),
    },
  ];

  if (isLoggedIn) {
    mobileItems.shift();
    mobileItems.unshift({
      header: ' ',
      links: [
        {
          title: 'Account',
          onClick: () =>
            dispatch(
              routerActions.link(ROUTES.ACCOUNT, {
                pathName: 'account',
              })
            ),
        },
      ],
    });

    rightAppBarItems = [
      {
        text: showRefer ? ribbonLabel || 'Get 15% Off' : '',
        onClick: () => window.location.assign('/refer?cta=header'),
      },
      {
        text: 'Host',
        onClick: () => dispatch(routerActions.link(ROUTES.HOST)),
      },
      {
        text: 'Account',
        onClick: () => {
          redirectAccount(profile?.data?.profile?.accountCountry);
        },
      },
    ];
  }

  const categories = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<CategoriesState>(
      (state) => state.categories,
      asyncData(ASYNC_STATUS.ERROR, [{ message: 'Could not get categories' }])
    )
  );

  const offers = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<OffersState>(
      (state) => state.offers,
      asyncData(ASYNC_STATUS.ERROR, [{ message: 'Could not get categories' }])
    )
  );

  const isMounted = useRef(null);

  useEffect(() => {
    if (isLoggedIn && profile?.data?.user && !profile?.data?.user?.openbookId) {
      dispatch(authActions.logout());
      dispatch(tokenActions.clear());
    }
    if (!locations || !locations.data) {
      dispatch(countryThunks.getCities());
    }
    dispatch(featureFlagThunks.getFeatures());
    if (!categories || !categories.data) {
      dispatch(entityThunks.getCategories());
    }
    if (!offers || !offers.data) {
      dispatch(entityThunks.getOffers());
    }

    if (filterData?.open) {
      dispatch(filterActions.updated({ ...filterData, open: false }));
    }
  }, []);

  useEffect(() => {
    if ('AfterPay' in window) return;

    const element = document.createElement('script');
    const baseUrl = isProd
      ? 'portal.afterpay.com'
      : 'portal.sandbox.afterpay.com';
    element.src = `https://${baseUrl}/afterpay.js`;
    element.async = true;
    document.head.appendChild(element);
  }, []);

  return (
    <>
      <Account showCountry={showCountry} setShowCountry={setShowCountry} />
      <div ref={isMounted}>
        <View
          countries={countries}
          {...props}
          leftAppBarItems={leftAppBarItems}
          rightAppBarItems={rightAppBarItems}
          mobileItems={mobileItems}
          isLoggedIn={!!isLoggedIn}
          subItems={subItems}
          goToHome={() => dispatch(routerActions.link(ROUTES.ROOT))}
          selectedCountry={selectedCountry}
          featureFlags={featureFlags?.data || {}}
          showMaintenance={showMaintenance}
          maintenanceText={maintenanceText?.payload?.leftBlock || ''}
          filter={filterData}
          cabinFilter={cabinFilter}
        />
      </div>
    </>
  );
};

export default React.memo(AppBarContainer);
