import * as React from "react"

const InstagramIcon = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 300 300"
    style={{
      enableBackground: "new 0 0 300 300",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".st2{fill:none;stroke:#f5f5db;stroke-width:17.907;stroke-linecap:round;stroke-linejoin:round}"
      }
    </style>
    <g
      id="Icon_feather-instagram_00000087373735864835007070000012167787387025104293_"
      transform="translate(-1.5 -1.5)"
    >
      <path
        id="Path_1242_00000067918491508152494520000009903166703812172929_"
        className="st2"
        d="M98.69 45.46h105.63c29.17 0 52.81 23.65 52.81 52.81V203.9c0 29.17-23.65 52.81-52.81 52.81H98.69c-29.17 0-52.81-23.65-52.81-52.81V98.28c-.01-29.17 23.64-52.82 52.81-52.82z"
      />
      <path
        id="Path_1243_00000120521150417383999480000008575289995376608651_"
        className="st2"
        d="M193.75 144.44c3.42 23.08-12.51 44.57-35.6 47.99-23.08 3.42-44.57-12.51-47.99-35.6s12.51-44.57 35.6-47.99c4.11-.61 8.29-.61 12.4 0 18.41 2.73 32.86 17.19 35.59 35.6z"
      />
      <path
        id="Path_1244_00000069375990573779392420000012799657688195957145_"
        d="M209.6 93h0"
        style={{
          fill: "none",
          stroke: "#f5f5db",
          strokeWidth: 25,
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
      />
    </g>
  </svg>
)

export default InstagramIcon
