export const INSTAGRAM_URL = 'https://www.instagram.com/unyoked.co/';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/unyoked/';
export const APPLEMUSIC_URL =
  'https://music.apple.com/au/playlist/unyoked-field-recordings-vol-ii/pl.1c373368b09a4f9696522a939c80d109';
export const SPOTIFY_URL =
  'https://open.spotify.com/user/nxly01xjlf0b9cicvn5qez158?si=634b0bf4c8a341f0';

export const GIFTS_URL = 'https://unplug.unyoked.co/prescription';
export const HOST_CONTACT_URL =
  'https://share.hsforms.com/1etqucFMmSVqBytIhCQ9uFQ2pwcr';
export const SHOP_URL =
  'https://unyoked-store.myshopify.com/collections/unyoked-merchandise';

const IMAGE_FORMATS = new RegExp('\\.(jpe?g|png)$');

export const PROGRESSIVE_IMG_TRANSFORMER = (url: string) => {
  if (IMAGE_FORMATS.test(url)) return `${url}?fl=progressive`;

  return url;
};

export const CONTENTFUL_SMALL_IMG = (url: string) => {
  if (IMAGE_FORMATS.test(url)) {
    return `${url}?fm=jpg&w=20`;
  }

  return url;
};
