/* eslint-disable */
import React, { FC, ReactNode } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, NodeData } from '@contentful/rich-text-types';
import { ThemeStyle } from '@material-ui/core/styles/createTypography';
import { BlogItemContentProps } from './BlogItemContent.props';
import { useStyles } from './BlogItemContent.styles';
import { HorizontalLine, Typo, ImageContainer } from '../../primitives';

const Text = (props: { variant: ThemeStyle; children: ReactNode }) => {
  const { variant, children } = props;

  const isBigText = variant === 'h1' || variant === 'h2';

  let mb;
  switch (variant) {
    case 'h1':
      mb = 6;
      break;
    case 'h2':
      mb = 5;
      break;
    case 'h3':
      mb = 4;
      break;
    case 'h4':
      mb = 3;
      break;
    case 'h5':
      mb = 3;
      break;
    case 'h6':
      mb = 2;
      break;
    default:
      mb = 2;
      break;
  }

  return (
    <Grid item xs={12} sm={isBigText ? 12 : 6}>
      <Box mb={mb}>
        <Typo
          variant={variant}
          style={{ textAlign: isBigText ? 'center' : 'left' }}
        >
          {children}
        </Typo>
      </Box>
    </Grid>
  );
};

const Image = (props: { node: NodeData; children: ReactNode }) => {
  const { node } = props;
  const classes = useStyles();

  const { fields } = node.data.target;
  const { file, title, description } = fields;
  const { url } = file;

  return (
    <Grid item xs={12} sm={6}>
      <Box mb={4}>
        <Grid container justifyContent="center">
          <ImageContainer alt={title} src={url} className={classes.image} />
          {title && (
            <Typo variant="caption1" noWrap>
              {title}
            </Typo>
          )}
          {description && (
            <Typo variant="caption1" noWrap>
              {description}
            </Typo>
          )}
        </Grid>
      </Box>
    </Grid>
  );
};

const BlogItemContentView: FC<BlogItemContentProps> = (props) => {
  const { content } = props;
  const classes = useStyles();

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: NodeData, children: ReactNode) => (
        <Text variant="body2">{children}</Text>
      ),
      [BLOCKS.HEADING_1]: (node: NodeData, children: ReactNode) => (
        <Text variant="h1">{children}</Text>
      ),
      [BLOCKS.HEADING_2]: (node: NodeData, children: ReactNode) => (
        <Text variant="h2">{children}</Text>
      ),
      [BLOCKS.HEADING_3]: (node: NodeData, children: ReactNode) => (
        <Text variant="h3">{children}</Text>
      ),
      [BLOCKS.HEADING_4]: (node: NodeData, children: ReactNode) => (
        <Text variant="h4">{children}</Text>
      ),
      [BLOCKS.HEADING_5]: (node: NodeData, children: ReactNode) => (
        <Text variant="h5">{children}</Text>
      ),
      [BLOCKS.HEADING_6]: (node: NodeData, children: ReactNode) => (
        <Text variant="h6">{children}</Text>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node: NodeData, children: ReactNode) => (
        <Image node={node}>{children}</Image>
      ),
      [BLOCKS.HR]: () => <HorizontalLine className={classes.line} />,
      [INLINES.HYPERLINK]: (node: NodeData, children: ReactNode) => {
        const { uri } = node.data;
        return (
          <Link
            component={() => (
              <a href={uri} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            )}
          />
        );
      },
    },
  };

  return (
    <Grid container spacing={5}>
      {documentToReactComponents(content, options)}
    </Grid>
  );
};

export default React.memo(BlogItemContentView);
