import React, { FC } from 'react';

import { HouseResultsItemPublicProps } from './HouseResultsItem.props';
import View from './HouseResultsItem.view';

const HouseResultsItemItemContainer: FC<HouseResultsItemPublicProps> = (
  props
) => {
  return <View {...props} />;
};

export default React.memo(HouseResultsItemItemContainer);
