// eslint-disable-next-line import/named
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'themeConfig/themeConstants';

interface StyleProps {
  isMobile: boolean;
  isLarge: boolean;
  linkColor: string;
  appBarTransparent: boolean | undefined;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: colors.primaryYellow,
    },
    toolbar: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
      },
    },
    menuIcon: {
      width: '34px',
      height: '34px',
    },
    logoContainer: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 10,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 'max-content',
      cursor: 'pointer',
    },
    mobileContainer: {
      paddingTop: theme.spacing(4),
      paddingBottom: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      zIndex: 3000,
    },
    subItem: {
      display: 'flex',
      flexDirection: 'row',
    },
    horizontalLine: {
      backgroundColor: `${colors.primaryDarkGreen} !important`,
      height: 2,
      marginTop: 6,
      marginBottom: 16,
    },
    verticalLine: {
      marginTop: 5,
      marginLeft: 10,
      marginRight: 10,
      height: '60%',
      backgroundColor: colors.primaryDarkGreen,
    },
    burgerVertical: {
      marginLeft: 10,
      marginRight: 10,
      height: '60%',
      backgroundColor: colors.primaryDarkGreen,
    },
    popupContainer: {
      width: '100%',
      background: colors.primaryDarkGreen,
      color: colors.primaryYellow,
      textAlign: 'center',

      '& div': {
        width: '100%',
        padding: '12px 0',
      },
      '& a': {
        color: colors.primaryYellow,
      },
    },
    popup: ({ isLarge }: StyleProps) => ({
      display: 'grid',
      gridTemplateColumns: isLarge ? '1fr 200px 200px' : '1fr',
      width: '90%',
      alignItems: 'center',
      margin: 'auto',
      gap: '20px',
      textAlign: isLarge ? 'left' : 'center',
      paddingBottom: '5px',
    }),
    ghostButton: {
      color: colors.primaryDarkGreen,
      background: colors.secondaryYellow,
      height: '2.5rem',
      fontSize: '1rem',
    },
    appBarTransparent: ({ isMobile }: StyleProps) => ({
      backgroundColor: isMobile ? `rgba(40,40,40,0.3)` : 'transparent',
      boxShadow: 'none',
      color: colors.primaryYellow,
    }),
    countryRadio: {
      marginRight: '20px',
    },
    countryButton: {
      width: '100px',
      height: '2.5rem',
      '& h5': {
        fontSize: '0.8rem',
      },
    },
    globeIcon: {
      width: 20,
      height: 20,
    },
    bookButton: ({ linkColor }: StyleProps) => ({
      marginTop: '-7px',
      padding: '0px 20px',
      height: '30px',
      color: linkColor,
      border: `1px solid ${linkColor}`,
    }),
    mobileBookButton: ({ linkColor }: StyleProps) => ({
      backgroundColor: colors.secondaryYellow,
    }),
    filterBadge: {
      top: '5px',
    },
  })
);
