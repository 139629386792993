import React, { FC } from 'react';

import pathOr from 'ramda/src/pathOr';

import useSelectorSafe from '../../../store/selectors/useSelectorSafe';
import { NoResultsPublicProps } from './NoResults.props';
import View from './NoResults.view';

const NoResultsContainer: FC<NoResultsPublicProps> = (props) => {
  const filter = useSelectorSafe((state) => state.filter);
  const startDate = pathOr('', ['startDate'], filter);
  const endDate = pathOr('', ['endDate'], filter);

  return (
    <View
      {...props}
      // contentTitle={startDate && endDate ? 'Your Cabins' : 'All Cabins'}
      contentTitle={startDate && endDate && 'Your Cabins'}
    />
  );
};

export default React.memo(NoResultsContainer);
