import React, { FC } from 'react';

import { HorizontalBlogCardsPublicProps } from './HorizontalBlogCards.props';
import View from './HorizontalBlogCards.view';

const HorizontalBlogCardsContainer: FC<HorizontalBlogCardsPublicProps> = (
  props
) => {
  return <View {...props} />;
};

export default React.memo(HorizontalBlogCardsContainer);
