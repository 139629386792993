import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boldTitles: {
      fontWeight: 'bold',
      color: 'inherit',
      marginBottom: 16,
    },
    underlinedTitles: {
      textDecoration: 'underline',
    },
    paragraph: {
      marginBottom: '1rem',
      color: 'inherit',
    },
    header: {
      marginBottom: '1em',
      color: 'inherit',
    },
    header1: {
      marginBottom: '1em',
      color: 'inherit',
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    },
  })
);
