import React, { FC, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import isEmail from 'validator/lib/isEmail';

import {
  Button,
  GhostButton,
  InputField,
  Typo,
  Link,
  Snackbar,
} from '../../primitives';
import { ForgotPasswordProps } from './ForgotPassword.props';
import { useStyles } from './ForgotPassword.styles';

const ForgotPasswordView: FC<ForgotPasswordProps> = ({
  forgotPassword,
  goBack,
  loading,
  success,
  errorMessage,
  openErrorSnack,
}) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();

  const onChangeEmail = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    setEmailError('');
    setEmail(event.target.value);
  };

  const onForgot = () => {
    if (!isEmail(email)) {
      setEmailError('Please enter a valid email');
      return;
    }

    forgotPassword(email);
  };

  return (
    <Grid container item direction="column" xs={12} sm={6}>
      <Typo
        variant={isMobile ? 'h3' : 'h1'}
        className={classes.title}
        style={{ textAlign: isMobile ? 'left' : 'center' }}
      >
        Forgot Password
      </Typo>
      <Box mb={2} />
      <Typo
        variant="caption1"
        style={{ textAlign: isMobile ? 'left' : 'center' }}
      >
        Please enter your email so we can send you a reset password request
      </Typo>
      <Box mb={8} />
      <InputField
        id="email"
        placeholder="Email"
        type="email"
        value={email}
        error={emailError}
        onChange={onChangeEmail}
      />
      <Box mb={5} />
      <Link onClick={() => goBack()}>
        <Typo variant="h6">Back?</Typo>
      </Link>
      <Box mb={10} />
      {!isMobile ? (
        <Button
          onClick={onForgot}
          variant="h4"
          text="Send"
          loading={loading}
          success={success}
        />
      ) : (
        <GhostButton
          text="Send"
          style={classes.ghostButton}
          onClick={onForgot}
          loading={loading}
          success={success}
        />
      )}

      <Snackbar message={errorMessage} open={openErrorSnack} type="error" />
    </Grid>
  );
};

export default React.memo(ForgotPasswordView);
