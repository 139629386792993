import React, { FC, Fragment } from 'react';

import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { VideoPlayer } from '../../primitives';
import { ResponsiveVideoPlayerModalProps } from './ResponsiveVideoPlayerModal.props';
import { useStyles } from './ResponsiveVideoPlayerModal.styles';

const ResponsiveVideoPlayerModalView: FC<ResponsiveVideoPlayerModalProps> = (
  props
) => {
  const { preview } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Fragment>
      {!isMobile ? (
        <Box className={classes.container}>
          <VideoPlayer
            {...props}
            className={classes.player}
            light={preview || false}
            playing
          />
        </Box>
      ) : (
        <Box className={classes.previewContainer}>
          <VideoPlayer
            {...props}
            className={classes.preview}
            light={preview || true}
            playing
          />
        </Box>
      )}
    </Fragment>
  );
};

export default React.memo(ResponsiveVideoPlayerModalView);
