import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import partial from 'ramda/src/partial';
import Link from 'redux-first-router-link';

import { HorizontalLine, ImageContainer, Typo } from '../../primitives';
import { BlogCardProps } from './BlogCard.props';
import { useStyles } from './BlogCard.styles';

const BlogCardView: FC<BlogCardProps> = (props) => {
  const {
    id,
    image,
    imageCaption,
    title,
    subtitle,
    description,
    onMore,
    onMoreLink,
    externalUrl,
    goToURL,
  } = props;
  const classes = useStyles();
  // @ts-ignore
  const belowTablet = useMediaQuery('(max-width: 767px)');
  const fn = externalUrl
    ? partial(goToURL, [externalUrl])
    : partial(onMore, [id]);

  return (
    <Link
      className={classes.link}
      to={externalUrl || onMoreLink(id)}
      target={externalUrl ? '_blank' : ''}
    >
      <Box onClick={belowTablet ? fn : () => {}}>
        <ImageContainer alt="blog-card" className={classes.image} src={image} />
        <Typo variant="body3" className={classes.imageCaption}>
          {imageCaption}
        </Typo>
        {!belowTablet && <HorizontalLine className={classes.line} />}
        <Box className={classes.titleArrowContainer}>
          <Typo variant="h4" className={classes.title}>
            {title}
          </Typo>
          {!belowTablet && (
            <IconButton edge="end" className={classes.arrow} onClick={fn}>
              <ArrowForwardIcon />
            </IconButton>
          )}
        </Box>
        {belowTablet && (
          <Typo variant="h5" className={classes.subTitle}>
            {subtitle}
          </Typo>
        )}
        <Typo variant="body3" className={classes.desc}>
          {description}
        </Typo>
      </Box>
    </Link>
  );
};

export default React.memo(BlogCardView);
