import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface StyleProps {
  iconColor?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: ({ iconColor }: StyleProps) => ({
      display: 'flex',

      '& svg': {
        fill: `${iconColor} !important`,
      },
      '& svg path': {
        stroke: `${iconColor} !important`,
        fill: `${iconColor} !important`,
      },
      '& svg.inverted': {
        fill: `${iconColor} !important`,
      },

      '& svg.inverted path': {
        stroke: `${iconColor} !important`,
        fill: `transparent !important`,
      },
    }),
  })
);
