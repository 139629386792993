import React, { FC } from 'react';

import Slider from 'react-slick';

import { CarouselProps } from './Carousel.props';

const CarouselView: FC<CarouselProps> = (props) => {
  return <Slider {...props} />;
};

export default React.memo(CarouselView);
