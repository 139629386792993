import React, { FC } from 'react';

import { useDispatch } from 'react-redux';
import ROUTES from 'routes';
import { routerActions } from 'store/actions';

import { ToBlogBlockPublicProps } from './ToBlogBlock.props';
import View from './ToBlogBlock.view';

const ToBlogBlockContainer: FC<ToBlogBlockPublicProps> = (props) => {
  const dispatch = useDispatch();
  return (
    <View
      {...props}
      goToBlog={() => dispatch(routerActions.link(ROUTES.JOURNAL))}
    />
  );
};

export default React.memo(ToBlogBlockContainer);
