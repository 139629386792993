import { StagingAuthState } from 'types/store/StagingAuth';
import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'STAGINGATUH ';
const createFaqActions = makeCreateActions(ns);

const stagingAuth = createFaqActions({
  set: presetActions.makeIdentity<StagingAuthState>(),
});

export default stagingAuth;
