import React, { FC } from 'react';

import { CURRENCY, CURRENCY_SYMBOL } from 'consts/locations';
import useSelectorSafe from 'store/selectors/useSelectorSafe';

import { ProvisionCardPublicProps } from './ProvisionCard.props';
import View from './ProvisionCard.view';

const ProvisionCardContainer: FC<ProvisionCardPublicProps> = (props) => {
  const { price, totalStay, country, countryPrices } = props;

  const pricesArr = [];
  const userCountry = useSelectorSafe(
    (state) => state.country?.countryCode || 'AU'
  );

  const currency = CURRENCY[country];
  const currencySymbol = CURRENCY_SYMBOL[currency];

  if (countryPrices && countryPrices.length > 0) {
    const entity: any = countryPrices.find(
      (countryPrice: any) => countryPrice.code == country
    );

    if (entity && entity.price) {
      let entityPrice = entity.price;
      if (totalStay && entity.per_night_prices) {
        for (let night of entity.per_night_prices) {
          if (night.night_count == totalStay) {
            entityPrice = night.price;
            break;
          }
        }
      }

      pricesArr.push(`${currencySymbol}${entityPrice} ${CURRENCY[country]}`);
    } else {
      pricesArr.push(`${currencySymbol}${price} ${CURRENCY[country]}`);
    }

    if (userCountry != country) {
      
      const otherCountry: any = countryPrices.find(
        (countryPrice: { code: string }) => countryPrice.code == userCountry
      );

      if (otherCountry && otherCountry.price && userCountry) {
        const currency = CURRENCY[userCountry];
        const currencySymbol = CURRENCY_SYMBOL[currency];

        if (currencySymbol) {

          let otherCountryPrice = otherCountry.price;
          if (totalStay && otherCountry.per_night_prices) {
            for (let night of otherCountry.per_night_prices) {
              if (night.night_count == totalStay) {
                otherCountryPrice = night.price;
                break;
              }
            }
          }

          pricesArr.push(`${currencySymbol}${otherCountryPrice} ${currency}`);
        }
      }
    }
  } else {
    pricesArr.push(`${currencySymbol}${price} ${CURRENCY[country]}`);
  }

  const provisionPrice = pricesArr.join(' / ');

  return <View {...props} provisionPrice={provisionPrice} />;
};

export default React.memo(ProvisionCardContainer);
