import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ResponsiveVideoPlayerModal } from '../../modules';
import { Typo, ImageContainer } from '../../primitives';
import ContentfulRenderer from '../ContentfulRenderer';
import { AboutVideoBlockProps } from './AboutVideoBlock.props';
import { useStyles } from './AboutVideoBlock.styles';

const AboutVideoBlockView: FC<AboutVideoBlockProps> = (props) => {
  const {
    caption = 'Footage Title',
    title = `An ancient remedy for modern life`,
    description = `We provide the 9-5ers and creative hustlers a place to define
            themselves beyond their desks. Giving easy access to the wild, so
            that getting lost in nature becomes as natural as getting stuck in
            traffic.`,
    videoUrl = '',
    customThumbnail = '',
  } = props;
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = useStyles();

  const breakTitle = title
    .replace(/remedy/g, 'remedy\n')
    .split('\n')
    .map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));

  if (!videoUrl) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
      }}>
        <ImageContainer src={customThumbnail} alt={'background'} />
      </div>
    );
  }

  return (
    <Grid container justifyContent="flex-start">
      <Grid item xs={12}>
        <Box style={{ marginBottom: 5, marginTop: 40 }}>
          <ResponsiveVideoPlayerModal
            url={videoUrl}
            preview={customThumbnail}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(AboutVideoBlockView);
