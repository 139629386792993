import {
  TypographyOptions,
  TypographyStyleOptions,
} from '@material-ui/core/styles/createTypography';

import { fontFamilies, rootFontSizePx } from './themeConstants';

const { plain, pitch } = fontFamilies;
export const pxToRem = (n: number) => `${n / rootFontSizePx}rem`;

const typography: TypographyOptions = {
  h1: {
    fontFamily: plain,
    fontSize: pxToRem(35),
    fontWeight: 500,
  },
  h2: {
    fontFamily: plain,
    fontSize: pxToRem(30),
    fontWeight: 500,
  },
  h3: {
    fontFamily: plain,
    fontSize: pxToRem(24),
    fontWeight: 500,
  },
  h4: {
    fontFamily: plain,
    fontSize: pxToRem(20),
    fontWeight: 500,
  },
  h5: {
    fontFamily: plain,
    fontSize: pxToRem(15),
    fontWeight: 500,
  },
  h6: {
    fontFamily: plain,
    fontSize: pxToRem(12),
    fontWeight: 500,
  },
  body1: {
    fontFamily: pitch,
    fontSize: pxToRem(15),
  },
  body2: {
    fontFamily: pitch,
    fontSize: pxToRem(14),
  },
};

export interface TypoOptsExtra {
  body0: TypographyStyleOptions;
  body3: TypographyStyleOptions;
  caption1: TypographyStyleOptions;
  body: TypographyStyleOptions;
}

const appTypo: TypoOptsExtra = {
  body0: {
    fontFamily: pitch,
    fontSize: pxToRem(18),
  },
  body3: {
    fontFamily: pitch,
    fontSize: pxToRem(12),
  },
  caption1: {
    fontFamily: pitch,
    fontSize: pxToRem(11),
  },
  body: {},
};

export const appTypoVariantMapping = {
  body0: 'body2',
  body3: 'body2',
  caption1: 'body2',
  body: 'body1',
};

export default {
  fontFamily: plain,
  ...typography,
  ...appTypo,
};
