import React, { FC } from 'react';

import { useDispatch } from 'react-redux';
import ROUTES from 'routes';
import { routerActions } from 'store/actions';

import { RecommendedPublicProps } from './Recommended.props';
import RecommendedView from './Recommended.view';

const RecommendedContainer: FC<RecommendedPublicProps> = (props) => {
  const dispatch = useDispatch();
  return (
    <RecommendedView
      {...props}
      goToBlog={() => dispatch(routerActions.link(ROUTES.JOURNAL))}
    />
  );
};

export default React.memo(RecommendedContainer);
