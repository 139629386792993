import React from 'react';

import { Entity } from 'types/Entity';

const useFilters = () => {
  const getRawEntities = ({
    entities,
    mustHaves,
    country,
    address,
  }: {
    entities: Entity[];
    mustHaves: string[];
    address: string;
    country: string;
  }): Entity[] => {
    let rawEntities = entities;
    rawEntities = rawEntities.filter((e: Entity) => {
      if (mustHaves.length) {
        const offerIds = e.offers.map((o) => o.id);
        for (const mustHave of mustHaves) {
          if (!offerIds.includes(mustHave)) {
            return [];
          }
        }
      }

      if (!e.cities || !e.cities.length) {
        if (country === 'AU') {
          if (address === 'Sydney') {
            return (
              (e.metadata.state || '')
                .replace(/,\s|,\s+/, ',')
                .split(',')
                .indexOf('NSW') > -1
            );
          } else if (address === 'Melbourne') {
            return (
              (e.metadata.state || '')
                .replace(/,\s|,\s+/, ',')
                .split(',')
                .indexOf('VIC') > -1
            );
          } else if (address === 'Brisbane') {
            return (
              (e.metadata.state || '')
                .replace(/,\s|,\s+/, ',')
                .split(',')
                .indexOf('QLD') > -1
            );
          } else if (address === 'Byron Hinterland') {
            return (
              (e.metadata.state || '')
                .replace(/,\s|,\s+/, ',')
                .split(',')
                .indexOf('BYRON') > -1
            );
          } else if (address === 'Canberra') {
            return (
              (e.metadata.state || '')
                .replace(/,\s|,\s+/, ',')
                .split(',')
                .indexOf('ACT') > -1
            );
          } else if (address === 'Hobart') {
            return (
              (e.metadata.state || '')
                .replace(/,\s|,\s+/, ',')
                .split(',')
                .indexOf('TAS') > -1
            );
          }
        } else if (country === 'NZ') {
          return (
            (e.metadata.country || '')
              .replace(/,\s|,\s+/, ',')
              .split(',')
              .indexOf('NZ') > -1
          );
        } else if (country === 'GB') {
          return (
            (e.metadata.country || '')
              .replace(/,\s|,\s+/, ',')
              .split(',')
              .indexOf('GB') > -1
          );
        }
      } else {
        if (address) {
          for (const city of e.cities) {
            if (city.name == address) {
              return true;
            }
          }
        } else {
          if (country === 'AU') {
            return (
              (e.metadata.country || '')
                .replace(/,\s|,\s+/, ',')
                .split(',')
                .indexOf('AU') > -1
            );
          } else if (country === 'NZ') {
            return (
              (e.metadata.country || '')
                .replace(/,\s|,\s+/, ',')
                .split(',')
                .indexOf('NZ') > -1
            );
          } else if (country === 'GB') {
            return (
              (e.metadata.country || '')
                .replace(/,\s|,\s+/, ',')
                .split(',')
                .indexOf('GB') > -1
            );
          }
        }
        return [];
      }
      return [];
    });
    return rawEntities;
  };

  return {
    getRawEntities,
  };
};

export default useFilters;
