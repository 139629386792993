import React, { FC } from 'react';

import { pick } from 'ramda';
import { VouchersData } from 'routes/Vouchers/Vouchers.props';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { Country } from 'types/Country';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { LocationsState } from 'types/store/CountryState';
import { VouchersState } from 'types/store/VouchersState';
import { asyncData } from 'utils/Redux';

import { VouchersLayoutPublicProps } from './VouchersLayout.props';
import View from './VouchersLayout.view';

const VouchersLayoutContainer: FC<VouchersLayoutPublicProps> = (props) => {
  const locations = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<LocationsState>(
      (state) => state.locations,
      asyncData(ASYNC_STATUS.ERROR, [{ message: 'Could not get locations' }])
    )
  );

  const vouchers: VouchersData = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<VouchersState>(
      (state) => state.vouchers,
      asyncData(ASYNC_STATUS.ERROR, [
        { message: 'Could not get prescriptions' },
      ])
    )
  );
  const selectedCountry = useSelectorSafe((state) => state.country);

  const countries = locations.data?.countries || {};
  const countryOptions = Object.keys(countries).map((countryCode) => ({
    label:
      countries[countryCode].name === 'United Kingdom'
        ? 'the United Kingdom'
        : countries[countryCode].name || '',
    value: countries[countryCode].code || '',
  }));

  const calcedProps = {
    ...props,
    countries: countryOptions,
    initCountry: selectedCountry as Country,
    isVouchersLoading: vouchers.status === ASYNC_STATUS.LOADING,
  };

  if (!countryOptions.length || !calcedProps.initCountry) {
    return <></>;
  }

  return <View {...calcedProps} />;
};

export default React.memo(VouchersLayoutContainer);
