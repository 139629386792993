import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';

interface StyleProps {
  imageHeight?: string;
  isMobile: boolean;
}

const cardColors = {
  background: colors.primaryWhite,
  transition: 'background 0.3s ease-out',
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
    },

    cardContainer: ({ isMobile }: StyleProps) => ({
      height: '100%',
      ...(isMobile
        ? {
            background: colors.primaryWhite,
          }
        : {}),

      '&:hover': {
        ...(isMobile ? {} : cardColors),
      },
    }),
    line: {
      backgroundColor: colors.primaryDarkGreen,
      height: 5,
    },
    location: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 16,
      },
    },
    name: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 26,
      },
    },
    imageType: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      objectFit: 'contain',
    },
    crossFade: {
      position: 'relative',
      overflow: 'hidden',
    },
    imageHouse: ({ imageHeight }: StyleProps) => ({
      width: '100%',
      height: imageHeight ? `${imageHeight} !important` : 'auto',
      objectFit: 'cover',
      // below tablets
      [theme.breakpoints.up(767)]: {
        height: 175,
      },
      [theme.breakpoints.up('md')]: {
        height: 200,
      },
      [theme.breakpoints.up('lg')]: {
        height: 300,
      },
    }),
    imageHouseHero: {
      width: '100%',
      height: 'auto',
      marginBottom: 10,
    },
    cardData: {
      padding: '10px 15px',
    },
    alignStart: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    link: {
      textDecoration: 'none',
      height: '100%',
    },
    bottom: {
      width: '100%',
      padding: '10px 5px',
      [theme.breakpoints.up(767)]: {
        top: 235,
      },
      [theme.breakpoints.up('md')]: {
        top: 260,
      },
      [theme.breakpoints.up('lg')]: {
        top: 360,
      },
    },
  })
);
