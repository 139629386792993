import { Dispatch } from 'redux';
import {
  waitlistEndpoint,
  waitlistFormEndpoint,
} from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { commonActions } from 'store/actions';

import { Store } from '../../types/store/Store';

interface AccountCredentials {
  email: string;
  firstName: string;
  lastName: string;
  location: string[];
}

const waitlist =
  (credentials: AccountCredentials) =>
  (dispatch: Dispatch, getState: () => Store) => {
    dispatch(
      commonActions.waitlist(
        apiFetch<AccountCredentials, {}>(waitlistEndpoint, credentials)(
          dispatch,
          getState
        )
      )
    );
  };

export const waitlistForm =
  () => (dispatch: Dispatch, getState: () => Store) => {
    dispatch(
      commonActions.waitlistForm(
        apiFetch(waitlistFormEndpoint, '')(dispatch, getState)
      )
    );
  };

export default waitlist;
