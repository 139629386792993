import React, { FC, useEffect, useRef } from 'react';

import pathOr from 'ramda/src/pathOr';
import { useDispatch } from 'react-redux';
import { back } from 'redux-first-router';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';

import { authActions } from '../../../store/actions';
import authThunks from '../../../thunks/auth';
import { ForgotPasswordPublicProps } from './ForgotPassword.props';
import View from './ForgotPassword.view';

const ForgotPasswordContainer: FC<ForgotPasswordPublicProps> = (props) => {
  const dispatch = useDispatch();
  const isMounted = useRef(true);

  const status = useSelectorSafe((state) => state.forgotPassword.status);

  useEffect(() => {
    if (status !== ASYNC_STATUS.LOADING && isMounted.current)
      dispatch(authActions.resetForgotPasswordState());
    return () => {
      isMounted.current = false;
    };
  }, []);

  const errors = useSelectorSafe((state) => state.forgotPassword.errors);
  const errorMessage =
    status === ASYNC_STATUS.ERROR
      ? pathOr('Something went wrong. Please try again.', ['errors'], errors)
      : '';

  return (
    <View
      {...props}
      forgotPassword={(email) => dispatch(authThunks.forgotPassword({ email }))}
      goBack={() => back()}
      success={status === ASYNC_STATUS.COMPLETE}
      loading={status === ASYNC_STATUS.LOADING}
      errorMessage={errorMessage}
      openErrorSnack={!!errorMessage}
    />
  );
};

export default React.memo(ForgotPasswordContainer);
