import React, { FC } from 'react';

import { GhostButtonPublicProps } from './GhostButton.props';
import View from './GhostButton.view';

const GhostButtonContainer: FC<GhostButtonPublicProps> = (
  props: GhostButtonPublicProps
) => <View {...props} />;

export default React.memo(GhostButtonContainer);
