import deletePaymentMethod from './deletePaymentMethod';
import getBooking from './getBooking';
import getPaymentAfterPay from './getPaymentAfterPay';
import getPaymentIntent from './getPaymentIntent';
import getPaymentMethod from './getPaymentMethod';
import getProfile from './getProfile';
import updatePaymentMethod from './updatePaymentMethod';
import updateProfile from './updateProfile';

const userThunks = {
  getProfile,
  updateProfile,
  getPaymentAfterPay,
  getPaymentIntent,
  getPaymentMethod,
  deletePaymentMethod,
  updatePaymentMethod,
  getBooking,
};

export default userThunks;
