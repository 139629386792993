import { Dispatch } from 'redux';
import { getHomeDataEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { homeActions } from 'store/actions';

import { Store } from '../../types/store/Store';

interface Data {}

const getHomeData =
  (id: string) => (dispatch: Dispatch, getState: () => Store) => {
    dispatch(
      homeActions.getHomeData(
        apiFetch<Data, any>(getHomeDataEndpoint, id)(dispatch, getState)
      )
    );
  };

export default getHomeData;
