import React, { FC, useEffect, useRef, useState } from 'react';

import { FilterModalPublicProps, FilterModalProps } from './FilterModal.props';
import FilterModalView from './FilterModal.view';
import { EntitiesData } from 'routes/Dispatches/Dispatches.props';
import { pathOr, pick } from 'ramda';
import { EntitiesState } from 'types/store/EntitiesState';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { asyncData } from 'utils/Redux';
import { CabinFilter } from 'types/CabinFilter';
import { Entity, Offer } from 'types/Entity';

const entitiesFallback: EntitiesState = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not get entities' },
]);

const FilterModalContainer: FC<FilterModalPublicProps> = (
  publicProps: FilterModalPublicProps
) => {
  const entities: EntitiesData = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<EntitiesState>((state) => state.entities, entitiesFallback)
  );

  const cabinFilter = useSelectorSafe((state) => state.cabinFilter) ?? {};
  const filter = useSelectorSafe((state) => state.filter) ?? {};

  const [filtered, setFiltered] = useState<Entity[]>();

  const filterCabins = (filter: CabinFilter) => {
    const filtered = entities.data?.entities.filter((entity) => {
      const { petFriendly, adventureLevel, reception, singleWeekNight } =
        filter;

      if (petFriendly) {
        let dogFriendly = pathOr(
          false,
          ['metadata', 'houseRules', 'Pet Friendly'],
          entity
        );
        if (petFriendly == 'Pet-friendly' && !dogFriendly) return false;
        if (petFriendly == 'No pets allowed' && dogFriendly) return false;
      }

      if (adventureLevel?.length) {
        let spiceLevel = pathOr<'mild' | 'medium' | 'spicy' | 'others'>(
          'others',
          ['metadata', 'spiceLevel'],
          entity
        );

        if (spiceLevel) {
          if (spiceLevel != 'others') {
            spiceLevel = spiceLevel.replace(/[A-Z]/, ' $&').trim() as
              | 'mild'
              | 'medium'
              | 'spicy';
          }

          if (adventureLevel.indexOf('spicy') > -1) {
            if (['spicy', 'extraSpicy'].indexOf(spiceLevel.toLowerCase()) > -1)
              return true;
          }

          if (adventureLevel.indexOf(spiceLevel) > -1) return true;

          return false;
        }
      }

      if (reception) {
        let offers = pathOr<Offer[]>([], ['offers'], entity).map((o) => o.name);
        if (reception == 'reception' && offers.indexOf('I need reception') < 0)
          return false;
        if (
          reception == 'no reception' &&
          offers.indexOf('I need reception') > -1
        )
          return false;
      }

      if (singleWeekNight) {
        let acceptSingleNight = pathOr(
          false,
          ['metadata', 'acceptSingleNight'],
          entity
        );
        if (singleWeekNight == 'Single weeknight' && !acceptSingleNight)
          return false;
        if (singleWeekNight == 'No single weeknight' && acceptSingleNight)
          return false;
      }

      return true;
    });

    setFiltered(filtered);
  };

  const isMounted = useRef(true);
  useEffect(() => {
    if (isMounted.current) {
      filterCabins(cabinFilter);
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const combinedProps: FilterModalProps = {
    ...publicProps,
    filter,
    filtered,
    filterCabins,
    cabinFilter,
  };

  return <FilterModalView {...combinedProps} />;
};

export default FilterModalContainer;
