import { Dispatch } from 'redux';
import { getPaymentMethodEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { userActions } from 'store/actions';

import { Store } from '../../types/store/Store';

const getPaymentMethod = (country: string) => (dispatch: Dispatch, getState: () => Store) => {
  dispatch(
    userActions.getPaymentMethod(
      apiFetch<{}, any>(getPaymentMethodEndpoint, { country })(dispatch, getState)
    )
  );
};

export default getPaymentMethod;
