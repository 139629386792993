import React, { FC, Fragment, useEffect, useState } from 'react';

import { Box, Collapse, Grid } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {
  CheckoutForm,
  PaymentSetup,
  ProvisionAccordion,
} from 'components/modules';
import { HorizontalLine, InputField, Typo } from 'components/primitives';
import {
  paymentFormInitialState,
  paymentFormFields,
  PhoneTemplate,
} from 'consts';
import PhoneInput from 'react-phone-number-input';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';

import { ConfirmValues } from '../HousePayments.props';
import phone from 'phone';
import { toTitleCase } from 'utils/String';
import { getTotalProvisionPrice } from 'utils/Data/pricing/pricing';

const TYPE_DESCRIPTIONS: { [key: string]: string } = {
  doggo: `Bring your pet`,
};

// @ts-ignore
const ConfirmAndPay: FC<T> = ({
  isXs,
  classes,
  addons,
  bookingDetails,
  guests,
  notes,
  voucherId,
  voucherType,
  voucherDiscount,
  discount,
  discountValue,
  confirm,
  setConfirmLoading,
  initialUserValues,
  addProvisions,
  name,
  country,
  total,
  totalProvPrice,
  trackCheckoutStep = (event: string, params: any) => null,
  pricePerNight,
  setCheckedProvisions,
  checkedProvisions,
  isMobile,
  totalStay,
  specialAddons,
  houseCard,
}) => {
  const [value, setValue] = useState<ConfirmValues>(initialUserValues);
  const [error, setError] = useState<ConfirmValues>(paymentFormInitialState);
  const [phoneCountry, setPhoneCountry] = useState(country);

  const paymentIntent = useSelectorSafe((state) => state.paymentIntent);
  const paymentAfterpay = useSelectorSafe((state) => state.paymentAfterpay);

  const intent = paymentIntent?.data;
  const afterpay = paymentAfterpay?.data;

  const intentError = paymentIntent?.errors;
  const afterpayError = paymentAfterpay?.errors;

  let validIntent = false;
  const amount = addProvisions ? totalProvPrice : total;
  const email = value.email;
  let description = '',
    metadata = {};
  if (bookingDetails) {
    const { checkin, checkout } = bookingDetails;
    const title = name;
    const voucher = voucherId;

    description = `Booking for ${title} ${checkin} - ${checkout}`;
    if (addProvisions) {
      description = `Update booking provision for ${title} ${checkin} - ${checkout}`;
    }

    metadata = {
      cabin: title,
      checkin: checkin,
      checkout: checkout,
      voucher,
      voucherValue: voucherDiscount,
      discount,
      discountValue,
      addons: checkedProvisions,
    };
  }

  if (
    intent &&
    (intent as any).secret &&
    (intent as any).amount == amount &&
    (intent as any).email == email &&
    (intent as any).description == description &&
    (intent as any).country == country
  ) {
    validIntent = true;
  }

  if (
    afterpay &&
    (afterpay as any).orderToken &&
    (afterpay as any).amount == amount &&
    (afterpay as any).email == email &&
    (afterpay as any).description == description &&
    (afterpay as any).country == country
  ) {
    validIntent = true;
  }

  useEffect(() => {
    const newValue = { ...initialUserValues };

    for (let key in value) {
      if (value[key]) {
        newValue[key] = value[key];
      }
    }

    const { cardName, firstName, lastName } = newValue;
    if ((!cardName && firstName) || lastName) {
      newValue.cardName = `${firstName} ${lastName}`;
    }

    setValue(newValue);
  }, [initialUserValues]);

  const onUpdateProvisions = (name: string, price: string, amount: string) => {
    const quantity = parseInt(amount, 10);
    if (quantity > 0) {
      setCheckedProvisions([
        ...checkedProvisions.filter(
          (provision: { text: string }) => provision.text !== name
        ),
        { text: name, value: Number(price), quantity },
      ]);
    } else if (quantity === 0 || !quantity) {
      setCheckedProvisions(
        checkedProvisions.filter(
          (provision: { text: string }) => provision.text !== name
        )
      );
    }
  };

  const onChange = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { target } = event;
    const { id, value: eventValue } = target;

    setError({ ...error, [id]: '' });

    let val = eventValue;
    if (id == 'firstName' || id == 'lastName') {
      if (/\d/.test(val)) {
        setError({
          ...error,
          [id]: `${toTitleCase(id)} must not have numeric value`,
        });
      }

      val = toTitleCase(eventValue);
    }

    // if (id == 'postCode') {

    //   val = match_postcode(country, val);

    //   if (!is_valid_postcode(country, val))  {
    //     setError({ ...error, [id]: `Invalid postcode.` })
    //   }
    // }

    const newValue = { ...value, [id]: val };
    setValue(newValue);

    if (id == 'firstName' || id == 'lastName') {
      setValue({
        ...newValue,
        cardName: `${newValue.firstName} ${newValue.lastName}`,
      });
    }
  };

  const onChangeMobile = (val: any) => {
    const res = phone(val);

    setError({
      ...error,
      mobile: !res.isValid
        ? 'Choose your country code, input your mobile number, and remove + or 0 from the start'
        : '',
    });
    setValue({ ...value, mobile: res.isValid ? res.phoneNumber : val || '' });
  };

  const setupProps = {
    intentStatus: paymentIntent?.status || ASYNC_STATUS.INITIAL,
    afterpayStatus: paymentAfterpay?.status || ASYNC_STATUS.INITIAL,
    intentError,
    afterpayError,
    intent,
    afterpay,
    voucherType,
    amount,
    description,
    metadata,
    country,
    email,
    name: `${value.firstName} ${value.lastName}`,
    firstName: value.firstName,
    lastName: value.lastName,
    phone: value.mobile,
    city: value.city,
    address: value.address,
    state: value.state,
    postCode: value.postCode,
    addProvisions: addProvisions,
    onPaymentSelect: () => {
      setShowOptions(false);
    },
  };

  const [showProvisions, setShowProvisions] = useState(false);
  const [showOptions, setShowOptions] = useState(true);

  const [loadingAddons, setLoadingAddons] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoadingAddons(false), 8000);
  }, []);

  return (
    <Box>
      {addProvisions &&
        specialAddons &&
        specialAddons.map(
          ({
            name,
            description,
            price,
            countryPrices,
            provider,
            yield: yieldRange,
            quantity,
            type,
            currency,
            maxPurchasable,
          }: {
            name: string;
            description: string;
            price: string;
            countryPrices: [];
            provider: string;
            yield: string;
            quantity: string;
            type: string;
            currency: string;
            maxPurchasable: number;
          }) => (
            <Fragment key={type}>
              <Box mt={isMobile ? 4 : 8} />
              <Typo
                variant={isXs ? 'h3' : 'h1'}
                style={{ textTransform: 'capitalize' }}
              >
                {TYPE_DESCRIPTIONS[type] || type}
              </Typo>
              <Box mt={2}>
                <HorizontalLine className={classes.fatLine} />
              </Box>
              <Box mt={2}>
                <ProvisionAccordion
                  name={name}
                  description={description}
                  onUpdate={onUpdateProvisions}
                  price={price}
                  countryPrices={countryPrices}
                  currency={currency}
                  provider={provider}
                  yieldRange={yieldRange}
                  quantity={quantity}
                  isQuantityOne={true}
                  maxPurchasable={maxPurchasable}
                  country={country}
                />
              </Box>
            </Fragment>
          )
        )}

      {addons && addons.length ? (
        <Fragment>
          <Box
            mt={isXs ? 0 : 4}
            onClick={() => setShowProvisions(!showProvisions)}
            className={classes.sectionHeader}
          >
            <Typo variant={isXs ? 'h3' : 'h1'}>Add Provisions</Typo>

            {showProvisions ? (
              <ExpandLess color="secondary" />
            ) : (
              <ExpandMore color="secondary" />
            )}
          </Box>
          <Box mt={2}>
            <HorizontalLine className={classes.fatLine} />
          </Box>
          <Collapse in={showProvisions}>
            {addons.map(
              ({
                name,
                description,
                price,
                countryPrices,
                provider,
                yield: yieldRange,
                quantity,
                maxPurchasable,
                currency,
              }: {
                name: string;
                description: string;
                price: string;
                countryPrices: [];
                provider: string;
                yield: string;
                quantity: string;
                currency: string;
                maxPurchasable: number;
              }) => (
                <Box key={name} mt={2}>
                  <ProvisionAccordion
                    name={name}
                    totalStay={totalStay}
                    onUpdate={onUpdateProvisions}
                    description={description}
                    price={price}
                    currency={currency}
                    countryPrices={countryPrices}
                    provider={provider}
                    yieldRange={yieldRange}
                    quantity={quantity}
                    maxPurchasable={maxPurchasable}
                    isQuantityOne={maxPurchasable == 1}
                    country={country}
                  />
                </Box>
              )
            )}
          </Collapse>
        </Fragment>
      ) : loadingAddons ? (
        <>
          <Skeleton />
          <Skeleton animation={false} />
          Loading Provisions ...
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      ) : null}
      <Box mb={6} />
      <Typo variant={isXs ? 'h3' : 'h1'}>Confirm & Pay</Typo>
      <Box mt={2} mb={1}>
        <HorizontalLine className={classes.fatLine} />
      </Box>
      <Typo variant="h5">Enter your details</Typo>
      <Box mb={3} />
      <Grid container direction="row">
        {paymentFormFields.map(({ id, label, type }) => (
          <Grid key={id} item xs={12} sm={6}>
            <Box mt={isMobile ? 3 : 6} pr={isXs ? 0 : '15%'}>
              {id !== 'mobile' ? (
                <InputField
                  id={id}
                  label={label}
                  shrink={true}
                  type={type}
                  value={value[id]}
                  error={error[id]}
                  onChange={onChange}
                  disabled={(addProvisions || id === 'email') && !!value[id]}
                />
              ) : (
                <div>
                  <label className={classes.inputLabel} htmlFor={'mobile'}>
                    Mobile
                  </label>
                  <PhoneInput
                    placeholder={
                      phoneCountry
                        ? `Ex: ${PhoneTemplate[phoneCountry as string]}`
                        : undefined
                    }
                    countries={['AU', 'NZ', 'GB']}
                    defaultCountry={country}
                    country={phoneCountry}
                    value={(value[id] || '') as any}
                    onChange={onChangeMobile}
                    disabled={addProvisions && !!value[id]}
                    className={classes.mobile}
                    onCountryChange={(c) => setPhoneCountry(c)}
                    numberInputProps={{
                      className: classes.inputMobile,
                      classes: {
                        input: classes.inputRoot,
                        disabled: classes.disabled,
                      },
                    }}
                  />
                  {error[id] ? (
                    <p className={classes.mobileError}>{error[id]}</p>
                  ) : null}
                </div>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box mb={3} />
      {total > 0 && (!validIntent || showOptions) ? (
        <>
          <PaymentSetup {...setupProps} />
        </>
      ) : (
        <>
          <CheckoutForm
            houseCard={houseCard}
            onChangePayment={() => setShowOptions(true)}
            intent={intent}
            afterpay={afterpay}
            total={total}
            confirm={confirm}
            formDetails={value}
            formErrors={error}
            setValue={setValue}
            setError={setError}
            setConfirmLoading={setConfirmLoading}
            addons={checkedProvisions}
            bookingDetails={bookingDetails}
            guests={guests}
            notes={notes}
            voucherId={voucherId}
            voucherDiscount={voucherDiscount}
            addProvisions={addProvisions}
            trackCheckoutStep={trackCheckoutStep}
            pricePerNight={pricePerNight}
          />
        </>
      )}
    </Box>
  );
};

export default ConfirmAndPay;
