import { v4 as uuid } from 'uuid';

const LIST_CONTAINERS = ['<ul>', '</ul>', '<ol>', '</ol>'];

export const contentfulListParser = (
  content: string,
  className: string | Record<string, string>
): string => {
  const contentToParse = content[0] === '-' ? `\n${content}` : content;
  if (!contentToParse.includes('\n- ')) {
    return content;
  }

  const contentSplit = contentToParse.split('\n- ');
  let parsedList = ``;

  contentSplit.forEach((text, idx) => {
    if (!text) return;
    if (text.includes('##')) {
      parsedList = `${parsedList}
      <li key="${uuid()}" class=${className}><h2>${text
        .replaceAll('- ##', '')
        .replaceAll('##', '')}</h2></li>`;
      return;
    }

    if (text.includes('___')) {
      parsedList = `${parsedList}
        <u key="${uuid()}"><h3>${text
        .replaceAll('___', '')
        .replaceAll('___', '')}</h3></u>`;
      return;
    }

    // if (LIST_CONTAINERS.some((str) => text.includes(str))) {
    //   parsedList = `${parsedList}${text}`;
    //   return;
    // }

    parsedList = `${parsedList}
      <li key="${uuid()}" class="${className}">${text}</li>
    `;
  });

  return parsedList || '';
};
