import React, { FC } from 'react';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Typo } from '../../primitives';
import { FineprintProps } from './Fineprint.props';
import { useStyles } from './Fineprint.styles';

const FineprintView: FC<FineprintProps> = (props: FineprintProps) => {
  const { title, items } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });

  return (
    <>
      <Typo variant="caption1" className={classes.title}>
        {title}
      </Typo>
      {items.map((item, index) => (
        <Typo key={index} variant="caption1">
          - {item}
        </Typo>
      ))}
    </>
  );
};

export default FineprintView;
