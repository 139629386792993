import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  CheckboxChecked,
  CheckboxChecked2,
  CheckboxChecked3,
  CheckboxUnchecked,
  CheckboxXmark,
} from '../../../assets/svgs';
import Typo from '../Typo';
import { CheckboxXmarkProps } from './CheckboxXmark.props';
import { useStyles } from './CheckboxXmark.styles';

const CheckboxXmarkView: FC<CheckboxXmarkProps> = (
  props: CheckboxXmarkProps
) => {
  const {
    label,
    variant = 'body3',
    isSmall,
    randomChecked,
    checkboxStyle = '',
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile, isSmall });

  const getRandomCheckedIcon = () => {
    const num = Math.floor(Math.random() * Math.floor(4));
    switch (num) {
      case 0:
        return <CheckboxChecked />;
      case 1:
        return <CheckboxChecked2 />;
      case 2:
        return <CheckboxChecked3 />;
      default:
        return <CheckboxChecked />;
    }
  };

  return (
    <Box className={classes.container}>
      <Checkbox
        {...props}
        className={`${classes.checkbox} ${checkboxStyle}`}
        icon={<CheckboxXmark />}
        checkedIcon={
          randomChecked ? getRandomCheckedIcon() : <CheckboxChecked />
        }
      />
      <Typo variant={variant}>{label}</Typo>
    </Box>
  );
};

export default React.memo(CheckboxXmarkView);
