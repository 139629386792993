import React, { FunctionComponent, useState, useEffect } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Hidden from '@material-ui/core/Hidden';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import { Logo } from 'assets/svgs';

import { ModalProps } from './Modal.props';
import { useStyles } from './Modal.styles';
import { cs } from 'date-fns/locale';

const ModalView: FunctionComponent<ModalProps> = (props: ModalProps) => {
  const {
    title,
    noClose,
    paperStyle,
    rootStyle,
    titleContainerStyle,
    contentContainerStyle,
    backDropProps,
    closeIconStyle,
    disableMobileFullScreen,
    disableLogo,
  } = props;
  const [open, setOpen] = useState(props.open);
  const classes = useStyles(props);
  const theme = useTheme();
  // @ts-ignore

  let fullScreen: any;

  if (disableMobileFullScreen) {
    fullScreen = false;
  } else {
    fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  }

  const closeModal = () => {
    if (typeof props.onClose === 'function') {
      props.onClose(false);
    }
    setOpen(false);
  };

  useEffect(() => {
    setOpen(props.open);
  }, [setOpen, props.open]);

  let cls: { [key: string]: string} = { paper: paperStyle || classes.paper };
  if (rootStyle) cls.root = rootStyle;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      open={open}
      classes={cls}
      onClose={props.onBackdropClick}
      BackdropProps={backDropProps}
    >
      <div className={`${classes.titleContainer} ${titleContainerStyle}`}>
        {title}
        { !disableLogo && (
          <div>
            <Hidden only={['md', 'lg', 'xl']}>
              <Logo className={classes.logo} />
            </Hidden>
          </div>
        )}
        
        {noClose !== false && <CloseIcon className={closeIconStyle || classes.closeIcon} onClick={closeModal} />}
      </div>
      <DialogContent
        className={`${classes.contentContainer} ${contentContainerStyle}`}
      >
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(ModalView);
