import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { colors, fontFamilies } from 'themeConfig/themeConstants';

import { pxToRem } from '../../../themeConfig/typography';
import { TextAreaPublicProps } from './TextArea.props';

const { pitch } = fontFamilies;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    textArea: ({ color }: TextAreaPublicProps) => ({
      fontFamily: pitch,
      fontSize: pxToRem(12),
      color: color || colors.primaryDarkGreen,
      backgroundColor: 'transparent',
      resize: 'none',
      display: 'flex',
      flex: 1,
      border: `2px solid ${color || colors.primaryDarkGreen}`,
      padding: theme.spacing(2),
      '&::placeholder': {
        color: color || colors.primaryDarkGreen,
      },
    }),
    disabled: {
      opacity: 0.5,
    },
  })
);
