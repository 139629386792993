import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { SocialImage } from '../../../assets/images';
import { Typo, GhostButton, ImageContainer } from '../../primitives';
import { SocialBlockProps } from './SocialBlock.props';
import { useStyles } from './SocialBlock.styles';

const SocialBlockView: FC<SocialBlockProps> = (props) => {
  const {
    onFollow,
    image = SocialImage,
    title = 'No Microsoft Office, you’re not welcome here.',
    hashtags = [],
    description = '',
    ctaLabel = '',
    subTitle = '',
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });

  return (
    <Grid container>
      {!isMobile && !description && (
        <Grid item xs={12}>
          <Box mb={10}>
            <Typo variant="h3" className={classes.followDesktop}>
              Follow @unyoked.co
            </Typo>
          </Box>
        </Grid>
      )}
      <Grid item style={{ display: 'flex' }} xs={12} md={6}>
        <ImageContainer
          alt="newsletter"
          src={image || ''}
          className={classes.image}
        />
      </Grid>
      {isMobile && !description && (
        <Grid item xs={12}>
          <Box mb={3}>
            <Typo variant="h6">Follow @unyoked.co</Typo>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} md={6} className={classes.rightContainer}>
        <Box mb={4}>
          {subTitle && (
            <Box mb={2}>
              <Typo variant={'body3'}> {subTitle}</Typo>
            </Box>
          )}

          <Typo variant={isMobile ? 'h3' : 'h1'} className={classes.title}>
            {title}
          </Typo>
        </Box>
        <Box mb={isMobile ? 5 : 8}>
          <Typo variant="body3" className={classes.hashtags}>
            {hashtags.join(' ')}
            {description}
          </Typo>
        </Box>
        <GhostButton
          style={classes.followButton}
          text={ctaLabel || 'Follow @unyoked.co'}
          onClick={onFollow}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(SocialBlockView);
