import * as React from "react"

const AppleMusicIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
    style={{
      enableBackground: "new 0 0 300 300",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M149.5 265.4c-21.1 0-42.2.5-63.2-.1-19.4-.6-34.4-9.6-43.3-28-3.7-7.7-5.1-16-5.1-24.5-.2-28.9-.2-57.8-.2-86.7 0-12.9 0-25.9.4-38.8.4-13.9 3.9-26.7 13.6-37 8.7-9.3 19.4-14.2 31.8-15 9.5-.6 19-.7 28.5-.7 32.5 0 65.1-.1 97.6.2 12.2.1 23.9 2.9 33.7 11.2 10.4 8.7 16.1 20.1 17.6 33.7.5 4.5.7 9 .7 13.5.1 38.9.2 77.9.2 116.8 0 9.6-1.1 19.1-5.4 27.8-9 18.2-23.9 27.1-43.2 27.7-21.4.4-42.6-.1-63.7-.1zm52.4-133.1s.1 0 0 0c.1-18.3.1-36.7.1-55 0-1.1.1-2.2-.1-3.3-.5-3.4-2.8-5.3-6.1-4.9-1.1.1-2.1.3-3.2.5-22.7 4.7-45.5 9.4-68.2 14.2-6 1.3-8 3.8-8 10.2 0 27.8 0 55.6.1 83.4 0 1.3 0 2.6-.1 3.9-.2 3-1.8 5-4.6 5.8-1.2.3-2.5.5-3.7.8-5.7 1.6-11.6 2.7-17 5.1-6.5 2.9-9.7 8.9-9.7 16.2 0 7.3 3.3 13 9.7 16.2 7.8 3.9 15.6 2 23-1.5 5.6-2.6 9.1-7.5 10.4-13.7.7-3.5 1-7.1 1-10.7.1-24.4.1-48.8.1-73.1 0-4.4.6-5.3 4.7-6.1 18.9-4 37.8-7.9 56.7-11.8 4.6-.9 5.6 0 5.6 4.8v47.3c0 9.5-.4 9.7-9.4 11.8-5.5 1.3-11 2.6-16.1 4.9-6.8 3.1-10.5 11.2-9.4 18.4 1.1 7.7 6.7 14.2 14.1 15.4 7.6 1.2 15-.1 21.4-4.7 6.5-4.7 8.6-11.6 8.6-19.4.1-18.3.1-36.5.1-54.7z"
      style={{
        fill: "#f5f5db",
      }}
    />
  </svg>
)

export default AppleMusicIcon
