import React, { FC } from 'react';

import { FieldPublicProps } from './Field.props';
import View from './Field.view';

const FieldContainer: FC<FieldPublicProps> = (props: FieldPublicProps) => (
  <View {...props} />
);

export default React.memo(FieldContainer);
