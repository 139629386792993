import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';
import { pxToRem } from '../../../themeConfig/typography';

interface StyleProps {
  hidden?: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: ({ hidden }: StyleProps) => ({
      top: 'auto',
      bottom: 0,
      left: 0,
      boxShadow: 'none',
      backgroundColor: 'transparent',
      transition: 'width 0.3s',
      width: hidden ? 0 : '80%',
    }),
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    itemContainer: ({ hidden }: StyleProps) => ({
      backgroundColor: colors.primaryYellow,
      display: 'flex',
      opacity: hidden ? 0 : 100,
      transition: 'opacity 0.3s',
      justifyContent: 'center',
      alignItems: 'start',
      padding: theme.spacing(1),
      borderColor: colors.primaryDarkGreen,
      flex: 1,
      height: 76,
      flexDirection: 'column',
    }),
    extendedItemContainer: ({ hidden }: StyleProps) => ({
      backgroundColor: colors.primaryYellow,
      borderColor: colors.primaryDarkGreen,
      opacity: hidden ? 0 : 100,
      transition: 'opacity 0.3s',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'start',
      minHeight: 76,
      padding: theme.spacing(1),
    }),
    firstItemContainer: {
      paddingLeft: `${theme.spacing(6)}px !important`,
    },
    arrowContainer: {
      height: 71,
      backgroundColor: colors.primaryYellow,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
    },
    textItems: {
      color: colors.primaryDarkGreen,
    },
    arrowButtons: {
      paddingLeft: 0,
      paddingRight: 0,
      minWidth: 0,
      fontSize: pxToRem(18),
      color: colors.primaryDarkGreen,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      textTransform: 'none',
      justifyContent: 'flex-start',
      width: '100%',
    },
    bookingContainer: {
      zIndex: 10,
      position: 'fixed',
      bottom: 0,
      right: 0,
      backgroundColor: colors.primaryDarkGreen,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '20%',
      height: 76,
      flexDirection: 'row',
    },
    hasValue: {
      padding: 0,
    },
  })
);
