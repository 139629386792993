import React, { FC } from 'react';

import {
  ScienceBenefitsPublicProps,
  ScienceBenefitsProps,
} from './ScienceBenefits.props';
import ScienceBenefitsView from './ScienceBenefits.view';

const ScienceBenefitsContainer: FC<ScienceBenefitsPublicProps> = (
  publicProps: ScienceBenefitsPublicProps
) => {
  const { content } = publicProps;

  const imageLinks = content.map((a) => ({
    image: a.imageLink,
    title: a.title,
  }));

  const bodyContent = content.map((a) => ({
    body: a.body,
    studyLink: a.studyLink || '',
  }));

  const combinedProps: ScienceBenefitsProps = {
    ...publicProps,
    // calcedProps
    imageLinks,
    bodyContent,
  };

  return <ScienceBenefitsView {...combinedProps} />;
};

export default ScienceBenefitsContainer;
