import { Dispatch } from 'redux';
import { getEntityByNameEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { entityActions } from 'store/actions';

import { Store } from '../../types/store/Store';

const getEntityByName =
  (name: string) => (dispatch: Dispatch, getState: () => Store) => {
    dispatch(
      entityActions.getById(
        apiFetch<string, any>(getEntityByNameEndpoint, name)(dispatch, getState)
      )
    );
  };

export default getEntityByName;
