import React, { FC } from 'react';

import { PrivacyPolicyPublicProps } from './PrivacyPolicy.props';
import View from './PrivacyPolicy.view';

const PrivacyPolicyContainer: FC<PrivacyPolicyPublicProps> = (props) => {
  return <View {...props} />;
};

export default React.memo(PrivacyPolicyContainer);
