import React, { FC } from 'react';

import { FAQPublicProps } from './FAQ.props';
import View from './FAQ.view';

const FAQContainer: FC<FAQPublicProps> = (props) => {
  return <View {...props} />;
};

export default React.memo(FAQContainer);
