import React, { FC, useEffect, useRef } from 'react';

import { pick } from 'ramda';
import { useDispatch } from 'react-redux';
import ROUTES from 'routes';
import { routerActions } from 'store/actions';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { FeatureFlagsState } from 'types/store/FeatureFlagState';
import { asyncData } from 'utils/Redux';

import { JournalBlockPublicProps } from './JournalBlock.props';
import JournalBlockView from './JournalBlock.view';
import homeThunks from 'thunks/home';
import { useInstantSearch } from 'react-instantsearch';

const SPECIAL_KEYS = { heroImage: 'banner', body: 'body' };

const JournalBlockContainer: FC<JournalBlockPublicProps> = (props) => {
  const dispatch = useDispatch();
  const { results } = useInstantSearch();

  const featureFlags = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<FeatureFlagsState>(
      (state) => state.featureFlags,
      asyncData(ASYNC_STATUS.ERROR, [
        { message: 'Could not get feature flags' },
      ])
    )
  );

  const initJournalDescription =
    props.journalDescriptions.filter((x) => x.type === 'All')[0] ||
    props.journalDescriptions[0];

  const fetchJournals = (page: number) => {
    dispatch(homeThunks.getJournals(page, 12));
  };

  const content = props.content;

  return (
    <JournalBlockView
      {...{ ...props, initJournalDescription }}
      content={content}
      goToBlog={() => dispatch(routerActions.link(ROUTES.BLOGS))}
      featureFlags={featureFlags.data}
      fetchJournals={fetchJournals}
    />
  );
};

export default React.memo(JournalBlockContainer);
