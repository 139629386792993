import React, { FC, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import pick from 'ramda/es/pick';
import { colors } from 'themeConfig/themeConstants';
import { HubspotOption, WaitlistForm } from 'types/Common';
import { Country } from 'types/Country';

import { Button, Typo, InputField, Checkbox, Collapse } from '../../primitives';
import {
  WaitlistInputValue,
  WaitlistModalContentProps,
} from './WaitlistModalContent.props';
import { useStyles } from './WaitlistModalContent.styles';

const WaitlistModalContentView: FC<WaitlistModalContentProps> = (props) => {
  const {
    onSubmit,
    closeModal,
    success,
    loading,
    cities,
    countries,
    defaultValue,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });

  const [selectedCity, setSelectedCity] = useState<string>();
  const [isSubmitted, setIsSubmitted] = useState(false);

  // @ts-ignore
  const acceptanceOption = {
    label:
      'Are you ready to put a stick in this hamster-wheel we call modern life?',
    option: {
      description: '',
      displayOrder: 0,
      label: 'Hell yeah, ready to go',
      value: 'Hell yeah, ready to go',
    },
  };

  const [inputValue, setInputValue] = useState<WaitlistInputValue>({
    firstName: '',
    lastName: '',
    email: '',
    ...defaultValue,
  });
  const [inputError, setInputError] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [checkboxValue, setCheckboxValue] = useState({
    Sydney: false,
    Melbourne: false,
    Brisbane: false,
    Canberra: false,
    ByronHinterland: false,
    'Somewhere else': false,
    [acceptanceOption.option.value]: true,
  });

  const onChange = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { target } = event;
    // @ts-ignore
    const { id, value: eventValue, checked, type } = target;

    if (type === 'checkbox') {
      if (id !== acceptanceOption.option?.label) {
        setCheckboxValue({ ...checkboxValue, [id]: checked });
      }
    } else {
      setInputError({ ...inputError, [id]: '' });
      setInputValue({ ...inputValue, [id]: eventValue });
    }
  };

  const onCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    setSelectedCity(checked ? id : '');
  };

  const validate = () => {
    let errorObj = inputError;
    Object.entries(inputValue).forEach(([key, objValue]) => {
      if (!objValue)
        errorObj = { ...errorObj, [key]: `Please enter your ${key}` };
    });
    setInputError(errorObj);

    const isFilled = Object.values(inputValue).every((x) => x !== '');

    return isFilled;
  };

  const onWaitlistSubmit = () => {
    if (validate()) {
      setIsSubmitted(true);
      onSubmit(inputValue, selectedCity ? [selectedCity] : []);
      // closeModal();
    }
  };

  return (
    <Box pl={isMobile ? 0 : 5} pr={isMobile ? 0 : 5}>
      <Grid container direction="column" alignItems="center">
        <Box pt={isMobile ? 2 : 0} mb={5} width="100%">
          <Typo variant="h4" className={classes.title}>
            {props.title || 'Share your details'}
          </Typo>
        </Box>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} sm={6}>
            <InputField
              value={inputValue.firstName}
              id="firstName"
              label="First name"
              error={inputError.firstName}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              value={inputValue.lastName}
              id="lastName"
              label="Last name"
              error={inputError.lastName}
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Box mb={5} />
        <InputField
          value={inputValue.email}
          id="email"
          label="Email"
          type="email"
          error={inputError.email}
          onChange={onChange}
        />
        <Box mb={5} />
        <Box width="100%">
          <Typo variant="h4" >
            Select your home city
          </Typo>
        </Box>
        {Object.keys(cities).map((country) => {
          if (!countries) return <></>;
          if (Object.keys(countries).includes(country)) {
            const options = cities[country].map((city, i) => ({
              label: city.name,
              value: city.name,
              description: city.name,
              displayOrder: i,
            }));
            return (
              <Box width={'100%'} key={country} mt={4}>
                <CountryChoice
                  selected={selectedCity || ''}
                  country={countries[country] || {}}
                  options={options}
                  classes={classes}
                  onChange={onCityChange}
                />
              </Box>
            );
          }
        })}

        <Box mb={5} />
        <Grid container direction="column">
          <Box mb={7} pl={isMobile ? 0 : 1} pr={isMobile ? 0 : 1}>
            <Typo variant="h6">{acceptanceOption?.label}</Typo>
            <Box mb={1} />
            <Checkbox
              id="Hell yeah, ready to go"
              label="Hell. Yes"
              isSmall={true}
              onChange={onChange}
              checked={checkboxValue[acceptanceOption?.option.value]}
            />
          </Box>
        </Grid>
        {!isSubmitted ? (
          <Button
            success={isSubmitted}
            loading={loading}
            text={'Submit'}
            onClick={onWaitlistSubmit}
            variant="h4"
          />
        ) : (
          <Typo variant="h4">Huzzah! Welcome to the pack.</Typo>
        )}
      </Grid>
    </Box>
  );
};

export default React.memo(WaitlistModalContentView);

const CountryChoice: FC<{
  country: Country;
  options: HubspotOption[];
  classes: Record<string, any>;
  selected: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ country, selected, options, classes, onChange }) => {
  if (!options || !options.length || !country) return <></>;
  return (
    <Collapse
      isOpen={false}
      title={country.name || ''}
      color={colors.primaryDarkGreen}
      titleVariant={'h6'}
    >
      <Box className={classes.choicesContainer}>
        {options.map((option) => (
          <Box key={option.label}>
            <Checkbox
              id={`${country.code}-${option.value}`}
              label={option.label}
              onChange={onChange}
              checked={selected == `${country.code}-${option.value}`}
              checkboxStyle={classes.checkbox}
            />
          </Box>
        ))}
      </Box>
    </Collapse>
  );
};
