import React, { FC, useEffect, useRef } from 'react';

import { pathOr } from 'ramda';
import { useDispatch } from 'react-redux';
import { userActions } from 'store/actions';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import userThunks from 'thunks/user';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';

import {
  PaymentSetupPublicProps,
  PaymentSetupProps,
} from './PaymentSetup.props';
import PaymentSetupView from './PaymentSetup.view';

const PaymentSetupContainer: FC<PaymentSetupPublicProps> = (
  publicProps: PaymentSetupPublicProps
) => {
  const dispatch = useDispatch();

  const isMounted = useRef(true);
  const token = useSelectorSafe((state) => state.token) || '';
  const paymentMethod = useSelectorSafe((state) => state.paymentMethod) || {
    status: ASYNC_STATUS.INITIAL,
    data: {
      paymentMethod: [],
    },
  };

  const {
    country,
    intent,
    afterpay,
    amount,
    description,
    email,
    name,
    firstName,
    lastName,
    phone,
    address,
    city,
    state,
    postCode,
    metadata,
  } = publicProps;

  const toCardDisplay = (method: any) => {
    return {
      id: method.id,
      customer: method.customer,
      last4Number: method.card.last4,
      expiryMonth: method.card.exp_month,
      expiryYear: method.card.exp_year,
      brand: method.card.brand,
      isDefault: method.isDefault,
      name: method.billing_details.name,
      email: method.billing_details.email,
    };
  };

  const cards = pathOr([], ['data', 'cards'], paymentMethod).map(toCardDisplay);
  const savedPaymentMethod =
    cards.find((c: { isDefault: boolean }) => c.isDefault) ||
    (cards.length && cards[0]) ||
    null;

  useEffect(() => {
    dispatch(userActions.clearPaymentIntent());
    dispatch(userActions.clearPaymentAfterpay());
    if (token && isMounted.current) {
      dispatch(userThunks.getPaymentMethod(country));
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const combinedProps: PaymentSetupProps = {
    ...publicProps,
    method: savedPaymentMethod,
    clearIntent: () => dispatch(userActions.clearPaymentIntent()),
    onPayNow: (method) => {
      if (
        !intent ||
        (intent as any).amount != amount ||
        (intent as any).email != email ||
        (intent as any).description != description ||
        (intent as any).country != country ||
        (intent as any).method != method
      ) {
        const data = method
          ? {
              method: method.id,
              methodName: `${method.brand}...${method.last4Number}`,
            }
          : {};

        dispatch(
          userThunks.getPaymentIntent({
            country,
            amount,
            email,
            name,
            phone,
            address,
            city,
            state,
            postCode,
            description,
            metadata,
            ...data,
          })
        );

        publicProps.onPaymentSelect?.();
        dispatch(userActions.clearPaymentAfterpay());
      }
    },
    clearAfterpay: () => dispatch(userActions.clearPaymentAfterpay()),
    onPayWithAfterPay: () => {
      if (
        !afterpay ||
        (afterpay as any).amount != amount ||
        (afterpay as any).email != email ||
        (afterpay as any).description != description ||
        (afterpay as any).country != country
      ) {
        dispatch(
          userThunks.getPaymentAfterPay({
            country,
            amount,
            name: firstName,
            surname: lastName,
            email,
            phone,
            address,
            city,
            state,
            postCode,
            description,
            metadata,
            origin: window.location.origin,
          })
        );

        publicProps.onPaymentSelect?.();
        dispatch(userActions.clearPaymentIntent());
      }
    },
  };

  return <PaymentSetupView {...combinedProps} />;
};

export default PaymentSetupContainer;
