/* eslint-disable prettier/prettier */
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';

import { Badge, Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import ArrowUpwardIcon from '@material-ui/icons/ExpandLess';
import ArrowDownwardIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import { openCountryModal } from 'components/layouts/Footer/Footer.container';
import { UnyokedCountries, UnyokedCountry } from 'consts/locations';
import ReactGA from 'react-ga';
import useGeoLocation from 'react-ipgeolocation';
import { useDispatch } from 'react-redux';
import { countryActions, filterActions } from 'store/actions';

import {
  CalendarIcon,
  CheckboxChecked2,
  CheckboxUnchecked,
  Logo,
} from '../../../assets/svgs';
import GlobeIcon from '../../../assets/svgs/generated/GlobeIcon';
import { colors } from '../../../themeConfig/themeConstants';
import {
  GhostButton,
  HorizontalLine,
  Link,
  Modal,
  Select,
  Typo,
} from '../../primitives';
import SocialIcons from '../SocialIcons';
import { AppBarLink, AppBarProps } from './AppBar.props';
import { useStyles } from './AppBar.styles';
import TuneIcon from '@material-ui/icons/Tune';
import { CabinFilter } from 'types/CabinFilter';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

// @ts-ignore
const ModalContent: FC<T> = (props) => {
  const classes = useStyles(props);
  const {
    accordionBoxDisplay,
    handleChangeAccordionBoxDisplay,
    mobileItems,
    subItems,
    setOpen,
  } = props;
  const itemsLength = mobileItems.length;

  const countrySelectorIcon = (
    <Button
      style={{
        padding: 0,
        minWidth: 0,
        marginLeft: 5,
        margin: '15px 0',
        width: 30,
      }}
      disableRipple={false}
      onClick={openCountryModal}
    >
      <GlobeIcon
        style={{ width: 30, height: 30 }}
        htmlColor={colors.primaryDarkGreen}
      />
    </Button>
  );

  const getIcon = (accordionStateKey: string) => {
    return accordionBoxDisplay[accordionStateKey] == 'none' ? (
      <ArrowDownwardIcon />
    ) : (
      <ArrowUpwardIcon />
    );
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
      }}
    >
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        {mobileItems[0].links.map(
          (link: { title: string; onClick: () => void }, index: number) => (
            <Fragment key={index}>
              <Box
                mb={0.5}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Link onClick={link.onClick} noHighlight>
                  <Typo component="span" variant="body2">
                    {link.title}
                  </Typo>
                </Link>
                <Button onClick={link.onClick}>
                  <NavigateNextIcon />
                </Button>
              </Box>
              {index !== mobileItems[0].links.length - 1 && (
                <Divider
                  orientation="vertical"
                  className={classes.verticalLine}
                />
              )}
            </Fragment>
          )
        )}
      </Box>

      <HorizontalLine className={classes.horizontalLine} />

      <Box style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        {/* @ts-ignore */}
        {mobileItems.map(
          (
            {
              header,
              links,
              isAccordion,
              accordionStateKey,
              isButton,
              onClick,
            },
            index
          ) => {
            if (index === 0) return <></>;
            if (!header) return <></>;
            return (
              <Fragment key={header}>
                {isAccordion ? (
                  <Fragment>
                    <Box
                      mb={1.25}
                      sx={{
                        justifyContent: 'space-between',
                        display: 'flex',
                      }}
                    >
                      <Typo variant="h4">{header}</Typo>
                      <Button
                        onClick={handleChangeAccordionBoxDisplay(
                          accordionStateKey
                        )}
                      >
                        {getIcon(accordionStateKey)}
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        display: accordionBoxDisplay[accordionStateKey],
                      }}
                    >
                      {links.map(
                        (
                          link: { title: string; onClick: () => void },
                          index: number
                        ) => (
                          <Box key={index} mb={0.5}>
                            <Link onClick={link.onClick} noHighlight>
                              <Typo component="span" variant="body2">
                                {link.title}
                              </Typo>
                            </Link>
                          </Box>
                        )
                      )}
                    </Box>
                  </Fragment>
                ) : isButton ? (
                  <Box mt={3} mb={5}>
                    <GhostButton
                      text="Book a Cabin"
                      style={classes.mobileBookButton}
                      variant={'h6'}
                      onClick={() => {
                        onClick();
                        setOpen(false);
                      }}
                    />
                  </Box>
                ) : (
                  links.map(
                    (
                      link: { title: string; onClick: () => void },
                      index: number
                    ) => (
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                        key={index}
                        mb={0.5}
                      >
                        <Link onClick={link.onClick} noHighlight>
                          <Typo variant="h4">{link.title}</Typo>
                        </Link>
                        <Button onClick={link.onClick}>
                          <NavigateNextIcon />
                        </Button>
                      </Box>
                    )
                  )
                )}

                {itemsLength === index + 1 || isButton || index == 0 ? null : (
                  <HorizontalLine className={classes.horizontalLine} />
                )}
              </Fragment>
            );
          }
        )}
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginBottom: '10px',
        }}
      >
        <SocialIcons iconFillColor={colors.primaryDarkGreen} />
        {subItems && <HorizontalLine className={classes.horizontalLine} />}
        {countrySelectorIcon}
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typo variant="body2" color="primaryDarkGreen">
            &copy; 2024
          </Typo>
          {subItems &&
            subItems.map(
              (
                subItem: { title: string; onClick: () => void },
                index: number
              ) => (
                <Fragment key={index}>
                  <Divider
                    orientation="vertical"
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      height: '60%',
                      backgroundColor: colors.primaryDarkGreen,
                    }}
                  />
                  <Box className={classes.subItem}>
                    <Link onClick={subItem.onClick} noHighlight>
                      {subItem.title}
                    </Link>
                  </Box>
                </Fragment>
              )
            )}
        </Box>
      </Box>
    </Box>
  );
};

// @ts-ignore
const HideOnScroll = (props) => {
  const trigger = useScrollTrigger();

  return props.disabled ? (
    props.children
  ) : (
    <Slide appear={false} direction="down" in={!trigger}>
      {props.children}
    </Slide>
  );
};

const AppBarLeftItem = (props: {
  item: AppBarLink & { subLinks?: AppBarLink[] };
  linkColor: string;
  appBarTransparent: boolean;
}) => {
  const { item, linkColor, appBarTransparent } = props;
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const isMedium = useMediaQuery(theme.breakpoints.down('lg'));
  const classes = useStyles({ isLarge, isMobile: !isLarge, linkColor });

  const [toggleSubLinks, setToggleSubLinks] = useState(false);
  const [openCountries, setOpenCountries] = useState<{
    [key: string]: boolean;
  }>({});

  const boxRef = useRef<any>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setToggleSubLinks(false);
        setOpenCountries({});
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box
      ref={boxRef}
      key={item.text}
      mr={item.subLinks ? 2 : isMedium ? 2 : 4}
      style={{
        display: 'flex',
        visibility: item.isHidden ? 'hidden' : 'visible',
      }}
    >
      <Link
        onClick={item.onClick}
        animated={true}
        linkColor={linkColor}
        noHighlight
      >
        <Typo
          variant="h5"
          style={{
            color: linkColor,
            fontSize: 14,
          }}
        >
          {item.text}
        </Typo>
      </Link>
      {item.subLinks && item.subLinks.length && (
        <>
          <IconButton
            style={{ padding: '0px', marginLeft: '5px' }}
            onClick={() => setToggleSubLinks(!toggleSubLinks)}
          >
            {toggleSubLinks ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </IconButton>
          {toggleSubLinks && (
            <Box
              style={{
                top: '49px',
                left: '0px',
                minWidth: '200px',
                paddingBottom: '12px',
                ...(appBarTransparent
                  ? {
                      background: !isLarge
                        ? `rgba(40,40,40,0.3)`
                        : 'transparent',
                      border: '1px solid',
                      paddingTop: '10px',
                    }
                  : { background: colors.primaryYellow }),
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                position: 'absolute',
              }}
            >
              {item.subLinks.map(({ head, code, text, onClick }, key) =>
                head === null && code ? (
                  <div key={key}>
                    <HorizontalLine className={classes.horizontalLine} />
                    <Grid
                      style={{ height: '25px', paddingLeft: '12px' }}
                      container
                    >
                      <Grid item xs={10}>
                        <Link
                          noHighlight
                          onClick={() =>
                            setOpenCountries({
                              ...openCountries,
                              [code]: !openCountries[code],
                            })
                          }
                          animated={true}
                        >
                          <Typo
                            variant="h5"
                            style={{
                              color: linkColor,
                              fontSize: 14,
                            }}
                          >
                            {text}
                          </Typo>
                        </Link>
                      </Grid>
                      <Grid item xs={2}>
                        <Link
                          noHighlight
                          onClick={() =>
                            setOpenCountries({
                              ...openCountries,
                              [code]: !openCountries[code],
                            })
                          }
                        >
                          {openCountries[code] ? (
                            <ArrowUpwardIcon />
                          ) : (
                            <ArrowDownwardIcon />
                          )}
                        </Link>
                      </Grid>
                    </Grid>
                  </div>
                ) : !head || openCountries[head] ? (
                  <Box key={key} pl={3}>
                    <Link onClick={onClick} animated={true} noHighlight>
                      <Typo
                        variant="h5"
                        style={{
                          color: linkColor,
                          fontSize: 14,
                        }}
                      >
                        {text}
                      </Typo>
                    </Link>
                  </Box>
                ) : null
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

const AppBarView: FC<AppBarProps> = ({
  countries,
  mobileItems = [],
  subItems = [],
  linkColor = colors.primaryDarkGreen,
  leftAppBarItems = [],
  rightAppBarItems = [],
  goToHome,
  hideFilterIcon,
  showMobileFilter,
  disableAutoHide,
  isLoggedIn,
  selectedCountry,
  appBarTransparent,
  featureFlags,
  filter,
  cabinFilter,
  showMaintenance,
  maintenanceText,
}: AppBarProps) => {
  const [accordionBoxDisplay, setAccordionBoxDisplay] = useState({
    escape: 'none',
    store: 'none',
    remedy: 'none',
    about: 'none',
    more: 'none',
  });

  const handleChangeAccordionBoxDisplay = (boxKey: string) => (e: Event) => {
    setAccordionBoxDisplay((prevState) => ({
      ...prevState,
      [boxKey]:
        prevState[boxKey as keyof typeof prevState] == 'none'
          ? 'block'
          : 'none',
    }));
  };

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const [checkedCountry, setCheckedCountry] = React.useState<UnyokedCountry>(
    selectedCountry?.countryCode || 'AU'
  );
  const isGlobalShown = localStorage.getItem('UNYOKED_GLOBAL_SHOWN');
  const [showPopup, setShowPopup] = React.useState(!(isGlobalShown == 'true'));
  const { country } = useGeoLocation();

  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const isMedium = useMediaQuery(theme.breakpoints.down('lg'));
  const classes = useStyles({
    isLarge,
    isMobile: !isLarge,
    linkColor,
    appBarTransparent,
  });

  const onShowMobileFilter = () => {
    if (typeof showMobileFilter === 'function') {
      ReactGA.event({
        category: 'Navigation',
        action: 'Clicked Mobile filter',
      });

      showMobileFilter();
    }
  };

  const onCountryChange = (value: string) => {
    countries &&
      dispatch(
        countryActions.set({
          countryCode: countries[value].code || 'AU',
          countryName: countries[value].name || 'Australia',
        })
      );

    if (value !== selectedCountry?.countryCode)
      setTimeout(() => location.reload(), 100);
  };

  const onContinue = () => {
    localStorage.setItem('UNYOKED_GLOBAL_SHOWN', 'true');
    setShowPopup(false);
  };

  useEffect(() => {
    setShowPopup(
      !(isGlobalShown == 'true') &&
        (featureFlags as any)['Head Country Selector']
    );
  }, [featureFlags]);

  useEffect(() => {
    setOpen(false);
  }, [isLarge]);

  const cabinFilterCount = Object.keys(cabinFilter ?? {}).filter(
    (k) =>
      k != 'sort' &&
      cabinFilter &&
      !!cabinFilter[k as keyof CabinFilter] &&
      cabinFilter[k as keyof CabinFilter]?.length
  ).length;
  return (
    <HideOnScroll disabled={disableAutoHide}>
      <AppBar
        className={
          appBarTransparent ? classes.appBarTransparent : classes.appBar
        }
      >
        {showPopup && (
          <Toolbar className={classes.popupContainer}>
            <Box className={classes.popup}>
              <Typo variant={'body2'} color="primaryYellow">
                We have gone global. Choose another region to see availability
                near you
              </Typo>
              <Select
                id="country-selector"
                options={
                  countries
                    ? Object.keys(countries).map((code) => ({
                        label: countries[code].name || 'AU',
                        value: code,
                      }))
                    : [{ label: 'Australia', value: 'AU' }]
                }
                label=""
                color={colors.primaryYellow}
                shrink={true}
                value={
                  selectedCountry?.countryCode ||
                  (UnyokedCountries.indexOf(country) > -1 ? country : 'AU')
                }
                onChange={(e) => {
                  onCountryChange(e.target.value);
                }}
              />
              <GhostButton
                text="Continue"
                onClick={() => onContinue()}
                style={classes.ghostButton}
              />
            </Box>
          </Toolbar>
        )}
        {showMaintenance ? (
          <Toolbar className={classes.popupContainer}>
            {maintenanceText ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: maintenanceText,
                }}
              ></div>
            ) : (
              `The Unyoked site is currently in maintenance to help serve you
            better! We might run some trouble in the bookings, so don't hesitate
            to reach out for any issues.`
            )}
          </Toolbar>
        ) : null}
        <Toolbar className={classes.toolbar}>
          <Hidden mdUp>
            <Box
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <IconButton
                edge="start"
                style={{ color: linkColor }}
                aria-label="menu"
                disableRipple={false}
                onClick={() => setOpen(true)}
              >
                <MenuIcon className={classes.menuIcon} />
              </IconButton>
              <Box pt={2}>
                {!hideFilterIcon && (
                  <Button
                    style={{
                      padding: 0,
                      minWidth: 0,
                    }}
                    disableRipple={false}
                    onClick={onShowMobileFilter}
                  >
                    <Badge
                      badgeContent={cabinFilterCount}
                      color="secondary"
                      classes={{
                        badge: classes.filterBadge,
                      }}
                    >
                      <TuneIcon
                        style={{ color: linkColor, width: 30, height: 30 }}
                      />
                    </Badge>
                  </Button>
                )}
              </Box>
            </Box>
          </Hidden>
          <Hidden smDown>
            <Box
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <Box style={{ display: 'flex', flexDirection: 'row' }}>
                {leftAppBarItems.map((item, key) => (
                  <AppBarLeftItem
                    key={key}
                    item={item}
                    linkColor={linkColor}
                    appBarTransparent={appBarTransparent || false}
                  />
                ))}
              </Box>
              <Box style={{ display: 'flex', flexDirection: 'row' }}>
                {rightAppBarItems.map((item) => (
                  <Box key={item.text} mr={4}>
                    <Link
                      onClick={item.onClick}
                      animated={true}
                      linkColor={linkColor}
                      noHighlight
                    >
                      <Typo
                        variant="h5"
                        style={{
                          color: linkColor,
                          fontSize: 14,
                        }}
                      >
                        {item.text}
                      </Typo>
                    </Link>
                  </Box>
                ))}
                <div id="toolbar-cart"></div>
                <Box mr={isMedium ? 2 : 4} style={{ display: 'flex' }}>
                  <GhostButton
                    text="Book Now"
                    style={classes.bookButton}
                    variant={'h6'}
                    onClick={() =>
                      dispatch(filterActions.updated({ ...filter, open: true }))
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Hidden>
          <Box className={classes.logoContainer}>
            <Logo onClick={goToHome} fill={linkColor} />
          </Box>
        </Toolbar>
        <Modal
          open={open}
          closeIconLeft
          onClose={setOpen}
          contentContainerStyle={classes.mobileContainer}
        >
          <ModalContent
            setOpen={setOpen}
            accordionBoxDisplay={accordionBoxDisplay}
            handleChangeAccordionBoxDisplay={handleChangeAccordionBoxDisplay}
            mobileItems={mobileItems}
            subItems={subItems}
          />
        </Modal>
      </AppBar>
    </HideOnScroll>
  );
};

export default React.memo(AppBarView);
