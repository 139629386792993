import React, { FC } from 'react';

import { useCheckoutTrack } from 'hooks/useCheckoutTrack';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { CheckoutState } from 'types/store/CheckoutState';

import { CheckoutConfirmedPublicProps } from './CheckoutConfirmed.props';
import View from './CheckoutConfirmed.view';

const CheckoutConfirmedContainer: FC<CheckoutConfirmedPublicProps> = (
  props
) => {
  const checkoutProps = useSelectorSafe<CheckoutState>((state) => {
    return state.checkout;
  });

  // @ts-ignore
  const { publicProps, currentUser, currentEntity, payload } = checkoutProps;

  const { trackOrderCompleted, trackBookingFailed } = useCheckoutTrack(
    publicProps,
    currentUser,
    currentEntity
  );

  if (!props.error) {
    trackOrderCompleted(payload);
  } else {
    trackBookingFailed(payload, props.error)
  }

  return <View {...props} />;
};

export default React.memo(CheckoutConfirmedContainer);
