import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';
import { CheckboxXmarkPublicProps } from './CheckboxXmark.props';

interface Style extends CheckboxXmarkPublicProps {
  isMobile: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    checkbox: ({ isMobile, isSmall }: Style) => ({
      padding: 0,
      marginRight: theme.spacing(2),
      width: isMobile || isSmall ? 40 : 'auto',
      height: 40,
      '&:hover': !isMobile
        ? {
            backgroundColor: colors.primaryDarkGreen,
          }
        : {},
    }),
  })
);
