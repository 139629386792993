import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import isFriday from 'date-fns/isFriday';
import isSaturday from 'date-fns/isSaturday';
import isSunday from 'date-fns/isSunday';
import parse from 'date-fns/parse';
import subDays from 'date-fns/subDays';
import Dinero from 'dinero.js';

import events from '../events/events';
import { isWeeknightSingle } from './specialCasePricing';

export const getMinorValue = (
  base: number | string,
  currency: Dinero.Currency
) => {
  const ref = Number(base).toString().split('.');
  const dollars = Dinero({
    amount: Number(ref[0] || 0) * 100,
    currency,
  });
  const cents = Dinero({
    amount: parseInt(String(Number(`0.${ref[1] || 0}`) * 100), 10),
    currency,
  });
  return dollars.add(cents);
};

export function getTotalStay(from: string, to: string) {
  const start = parse(from, 'MMM dd, yyyy', new Date());
  const end = parse(to, 'MMM dd, yyyy', new Date());
  return differenceInCalendarDays(end, start);
}

export function getWeekendStay(from: string, to: string) {
  try {
    const start = parse(from, 'MMM dd, yyyy', new Date());
    const end = subDays(parse(to, 'MMM dd, yyyy', new Date()), 1);
    const days = eachDayOfInterval({
      start,
      end,
    });
    return days.filter((d) => isSaturday(d) || isSunday(d) || isFriday(d))
      .length;
  } catch {
    return 0;
  }
}

export function getTotalStayPrice(
  {
    weeknightSingle,
    weeknights,
    weekend,
  }: {
    weeknightSingle?: string;
    weeknights: string;
    weekend: string;
  },
  totalStay: number,
  weekendCount: number,
  acceptSingleNight: string,
  currency: Dinero.Currency
) {
  try {
    const weeknightSinglePricing = getMinorValue(
      weeknightSingle || 0,
      currency
    );

    const weeknightsPricing = getMinorValue(weeknights, currency).multiply(
      totalStay - weekendCount
    );

    const weekendPricing = getMinorValue(weekend, currency).multiply(
      weekendCount
    );

    const totalStayPrice = weeknightsPricing.add(weekendPricing);

    if (
      isWeeknightSingle({
        weeknightSinglePricing,
        weekendPricing,
        totalStay,
      })
    )
      return weeknightSinglePricing.toUnit();

    return totalStayPrice.toUnit();
  } catch {
    return 0;
  }
}

export function getGST(base: number, currency: Dinero.Currency) {
  let percent = 10;
  if (currency == 'NZD') percent = 15;
  if (currency == 'GBP') percent = 20;

  const tax = percent / 100;

  return getMinorValue(base - base / (1 + tax), currency).toUnit();
}

export function getTotalProvisionPrice(
  provisions: { value: number; quantity: number }[],
  currency: Dinero.Currency
) {
  return provisions
    .reduce(
      (total, provision) => {
        return total.add(
          getMinorValue(provision.value, currency).multiply(provision.quantity)
        );
      },
      Dinero({
        amount: 0,
        currency,
      })
    )
    .toUnit();
}

export function getTotalPrice(array: number[], currency: Dinero.Currency) {
  return array
    .reduce(
      (total, current) => {
        if (current >= 0) {
          return total.add(getMinorValue(current, currency));
        }
        return total.subtract(getMinorValue(Math.abs(current), currency));
      },
      Dinero({
        amount: 0,
        currency,
      })
    )
    .toUnit();
}

export function getPercentDiscountedPrice(
  base: number,
  percent: number,
  currency: Dinero.Currency
) {
  return getMinorValue(base, currency)
    .percentage(100 - percent)
    .toUnit();
}

export function getEventsSurcharge(from: string, to: string) {
  try {
    const start = parse(from, 'MMM dd, yyyy', new Date());
    const end = subDays(parse(to, 'MMM dd, yyyy', new Date()), 1);
    const days = eachDayOfInterval({
      start,
      end,
    });
    return events
      .filter((event) => event.active && event.isIncluded(days))
      .map(({ text, value }) => ({ text, value }));
  } catch {
    return [];
  }
}
