import { Contentful } from 'types/Contentful';
import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'OFFER';
const createOfferActions = makeCreateActions(ns);

const offerActions = createOfferActions({
  getAll: presetActions.makeAsyncAction<Contentful[]>(`${ns}/GET_ALL`),
});

export default offerActions;
