import React, { FC } from 'react';

import Loader from 'components/primitives/Loader';
import { pathOr, isEmpty } from 'ramda';
import { getUniquePageEndpoint } from 'services/fetch/apiEndpoints';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { useQueryWrapper } from 'utils/Hooks/useQueryWrapper';

import { NewsletterImage } from '../../../assets/images';
import {
  JournalNewsletterPublicProps,
  NewsLetterAPIResponse,
} from './JournalNewsletter.props';
import View from './JournalNewsletter.view';

const JournalNewsletterContainer: FC<JournalNewsletterPublicProps> = (
  props
) => {
  const status = useSelectorSafe((state) => state.subscribe.status);
  const {
    isLoading,
    error,
    data: apiData,
  } = useQueryWrapper<string, NewsLetterAPIResponse>(
    ['newsletter'],
    getUniquePageEndpoint,
    'Journal Page',
    {
      refetchOnMount: isEmpty(props.data),
      staleTime: 3000000,
    }
  );

  if (error) {
    return <></>;
  }

  const data = {
    ...apiData?.payload[0],
  };

  const calcedProps = {
    ...props,
    data: !isEmpty(props.data) ? { ...props.data } : data,
  };

  return (
    <Loader isLoading={isLoading}>
      <View
        success={status === ASYNC_STATUS.COMPLETE}
        loading={status === ASYNC_STATUS.LOADING}
        {...calcedProps}
      />
    </Loader>
  );
};

export default React.memo(JournalNewsletterContainer);
