import React, { FC } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import CheckIcon from '@material-ui/icons/Check';

import Typo from '../Typo';
import { SubmitFieldProps } from './SubmitField.props';
import { useStyles } from './SubmitField.styles';

// @ts-ignore
const EndAdornment: FC<T> = (props) => {
  const { endAdornmentType = 'arrow', onSubmit, loading, success } = props;
  const classes = useStyles(props);
  const Icon = success ? CheckIcon : ArrowForwardIcon;
  return (
    <InputAdornment position="end" className={classes.endAdornmentRoot}>
      {endAdornmentType === 'arrow' ? (
        <IconButton
          className={classes.endAdornmentIconButton}
          edge="end"
          disabled={loading || success}
          onClick={onSubmit}
        >
          {loading ? (
            <CircularProgress size={21} color="secondary" />
          ) : (
            <Icon fontSize="small" />
          )}
        </IconButton>
      ) : (
        <Typo
          onClick={onSubmit}
          variant="h6"
          className={classes.endAdornmentText}
        >
          Submit
        </Typo>
      )}
    </InputAdornment>
  );
};

const SubmitFieldView: FC<SubmitFieldProps> = (props) => {
  const {
    placeholder,
    type,
    onChange,
    value,
    error,
    endAdornmentType,
    onSubmit,
    loading = false,
    success = false,
    disabled = false,
    onKeydown,
  } = props;
  const classes = useStyles(props);

  return (
    <TextField
      placeholder={placeholder}
      disabled={disabled}
      InputProps={{
        className: classes.input,
        classes: { input: classes.inputRoot },
        disableUnderline: true,
        endAdornment: (
          <EndAdornment
            endAdornmentType={endAdornmentType}
            onSubmit={onSubmit}
            loading={loading}
            success={success}
          />
        ),
      }}
      onKeyDown={onKeydown}
      onChange={onChange}
      value={value}
      type={type || 'text'}
      fullWidth
      helperText={error}
      FormHelperTextProps={{
        classes: {
          root: classes.error,
        },
        error: true,
      }}
    />
  );
};

export default React.memo(SubmitFieldView);
