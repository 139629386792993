import React, { FC } from 'react';

import Box from '@material-ui/core/Box';

import Typo from '../Typo';
import { WidgetProps } from './Widget.props';
import { useStyles } from './Widget.styles';

const WidgetView: FC<WidgetProps> = ({ heading, bodyChild }: WidgetProps) => {
  const classes = useStyles();
  return (
    <Box>
      <Box>
        <Typo variant="h3" className={classes.field}>
          {heading}
        </Typo>
      </Box>
      <Box>{bodyChild}</Box>
    </Box>
  );
};

export default React.memo(WidgetView);
