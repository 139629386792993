import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    line: {
      backgroundColor: colors.primaryDarkGreen,
      height: 1,
      marginBottom: theme.spacing(2),
    },
    li: {
      marginBottom: theme.spacing(1),
    },
  })
);
