import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { BlogBackgroundImage } from '../../../assets/images';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: '1rem',
      paddingLeft: '5%',
      paddingRight: '5%',
    },
    title: {
      color: '#01290D',
      [theme.breakpoints.up(1440)]: {
        fontSize: 32,
      },
    },
    seeMore: {
      color: '#01290D',
      width: 'auto',
      padding: '20px 50px',
    },
    description: {
      color: '#01290D',
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    },
    moreButton: {
      width: '100%',
    },
    topContainer: {
      display: 'flex',
      paddingTop: '5%',
    },
    bottomContainer: {
      paddingTop: '1%',
      paddingBottom: '5%',
    },
    homeMobile: {
      '& .slick-arrow': {
        height: '30px',
        transform: 'translate(0, 20%) scale(2)',
        zIndex: 100,
        top: '30%',
      },
      '& .slick-prev': {
        left: -25,
      },
      '& .slick-next': {
        right: -25,
        transform: 'rotate(180deg) translate(0, 20%) scale(2)',
      },
    },
  })
);
