import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';
import { BlogStoryItemPublicProps } from './BlogStoryItem.props';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.up(1440)]: {
      fontSize: 46,
    },
    ghostButton: {
      width: 150,
    },
    line: {
      backgroundColor: colors.primaryDarkGreen,
      height: 2.5,
    },
    verticalLine: {
      backgroundColor: `${colors.primaryDarkGreen} !important`,
      height: 20,
      margin: 5,
    },
    leftArrow: {
      color: colors.primaryDarkGreen,
      paddingLeft: 0,
      paddingRight: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    rightArrow: {
      color: colors.primaryDarkGreen,
      paddingLeft: 4,
      paddingRight: 6,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    bottomNav: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    prev: {
      userSelect: 'none',
      cursor: 'pointer',
      opacity: ({ onPrev }: BlogStoryItemPublicProps) => (onPrev ? 1 : 0),
    },
    next: {
      userSelect: 'none',
      cursor: 'pointer',
      opacity: ({ onNext }: BlogStoryItemPublicProps) => (onNext ? 1 : 0),
    },
  })
);
