import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { pxToRem } from 'themeConfig/typography';

import strikethrough from '../../../assets/svgs/strikethrough.svg';
import { colors } from 'themeConfig/themeConstants';

interface Style {
  isMobile: boolean;
  isXs: boolean;
  closeIconLeft: boolean;
}


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: pxToRem(24),
      [theme.breakpoints.up('sm')]: {
        fontSize: pxToRem(26),
      },
      [theme.breakpoints.up(750)]: {
        paddingRight: '11%',
        fontSize: pxToRem(30),
      },
      [theme.breakpoints.up(850)]: {
        paddingRight: '15%',
      },
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    },
    locationHeader: {
      textDecoration: 'underline',
      [theme.breakpoints.up(1440)]: {
        fontSize: 18,
      },
    },
    story: {
      '& p': {
      margin: 0,
      },
      [theme.breakpoints.up(1440)]: {
        fontSize: 26,
      },
    },


    mainContainer: {
      '& a':  { 
        color: colors.secondaryDarkGreen, 
        transition: 'all 0.3s',

        '&:hover': {
          background: colors.secondaryYellow,
        },
        '&:visited': {
          color: colors.lightGreen,
        },

      }
    },

    whatsProvided: ({ isXs, isMobile }: Style) => ({
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(6),
      // eslint-disable-next-line no-nested-ternary
      paddingLeft: isXs
        ? theme.spacing(1)
        : isMobile
        ? theme.spacing(7)
        : theme.spacing(14),
      marginTop: isXs ? theme.spacing(3) : 0,
    }),
    image: {
      width: '100%',
      height: 'auto',
    },
    rhsImage: ({ isXs }: Style) => ({
      width: '100%',
      height: 'auto',
      maxHeight: isXs ? 300 : 730,
      objectFit: 'cover',
    }),
    smallIcon: ({ isMobile }: Style) => ({
      width: isMobile ? 50 : 75,
      height: isMobile ? 50 : 75,
      objectFit: 'contain',
    }),
    locationImage: ({ isMobile }: Style) => ({
      objectFit: 'cover',
      width: '100%',
      height: isMobile ? '400px' : '600px',
    }),
    strikethrough: {
      position: 'relative',
      width: 'fit-content',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '45%',
        left: '-3%',
        width: '0%',
        height: '100%',
        backgroundImage: `url(${strikethrough})`,
        backgroundRepeat: 'no-repeat',
        transform: 'rotate(3deg)',
        '-webkit-transition': '0.4s ease-out',
        transition: '0.4s ease-out',
      },
    },
    s0: {
      '&:before': {
        width: '105%',
      },
    },
    s1: {
      '&:before': {
        width: '105%',
        'transition-delay': '0.5s',
      },
    },
    s2: {
      '&:before': {
        width: '105%',
        'transition-delay': '1s',
      },
    },
    tooltipRoot: {
      backgroundColor: "rgba(22,22,22,0.8)"
    },
    tooltipPaper: {
      color: "#fff",
      fontSize: "15px",
      maxWidth: "450px",
      background: "#06270B",
      padding: "10px",
    },
    tooltipTitle: {
      padding: "0",
      minHeight: "40px",
    },
    tooltipContent: {
      padding: "5px 15px 20px",
    },
    tooltipCloseIcon: ({ closeIconLeft } ) => ({
      width: '24px',
      height: '24px',
      position: 'absolute',
      top: '13px',
      cursor: 'pointer',
      ...(!closeIconLeft ? { right: '13px' } : { left: '13px' }),
      color: '#fff',
    }),
  })
);
