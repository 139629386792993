import React, { FC } from 'react';

import { MediaTextPublicProps, MediaTextProps } from './MediaText.props';
import MediaTextView from './MediaText.view';

const MediaTextContainer: FC<MediaTextPublicProps> = (
  publicProps: MediaTextPublicProps
) => {
  const combinedProps: MediaTextProps = {
    ...publicProps,
  };

  return <MediaTextView {...combinedProps} />;
};

export default MediaTextContainer;
