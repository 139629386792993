const LEAVE_KEY = 'show_leave';
const onLeaveListener = () => {
  const showOnLeave = window.sessionStorage.getItem(LEAVE_KEY) || 'show';
  if (showOnLeave === 'show') {
    window.sessionStorage.setItem(LEAVE_KEY, 'hide');
    document.removeEventListener('mouseleave', onLeaveListener);
    const name = location.pathname.replace('/', '');
    const pageName = name ? name : 'Home';
    window.analytics.track('Exit Intent', {
      url: window.location.href,
      pageName,
    });
  }
};

const listenToLeave = () => {
  document.addEventListener('mouseleave', onLeaveListener);
};

export const resetLeaveListener = () => {
  window.sessionStorage.setItem(LEAVE_KEY, 'show');
  listenToLeave();
};
