import * as React from 'react';

function SvgCheckboxChecked3(props) {
  return (
    <svg width={41} height={41} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle stroke="#041807" strokeWidth={2} cx={20.5} cy={20.5} r={18.5} />
        <path
          d="M11.104 23.48C18.573 12.95 29.202 2.807 41 2 11.121 14.907 15.81 33.68 10.51 27.935c-5.519-3.637-6.7-9.826.594-4.456"
          fill="#041807"
        />
      </g>
    </svg>
  );
}

export default SvgCheckboxChecked3;
