import { Dispatch } from 'redux';
import { resetPasswordEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { authActions } from 'store/actions';

import { Store } from '../../types/store/Store';

interface AccountCredentials {
  newPassword: string;
  token: string;
}

const resetPassword = (credentials: AccountCredentials) => (
  dispatch: Dispatch,
  getState: () => Store
) => {
  dispatch(
    authActions.resetPassword(
      apiFetch<AccountCredentials, {}>(resetPasswordEndpoint, credentials)(
        dispatch,
        getState
      )
    )
  );
};

export default resetPassword;
