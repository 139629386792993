import * as React from 'react';

function SvgLeftArrow(props) {
  return (
    <svg
      width={15}
      height={26}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={props.styles}
    >
      <path
        d="M14 1L2 13.163 14 25"
        stroke={props.color || '#F4F3D8'}
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgLeftArrow;
