import React, { FC, Fragment, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  InfoOutlined,
  Lens,
  LocationOn,
  RadioButtonUnchecked,
} from '@material-ui/icons';
import { isAfterpayDisabled } from 'consts/afterpay';
import GoogleMapReact from 'google-map-react';
import TrackVisibility from 'react-on-screen';
import { colors } from 'themeConfig/themeConstants';
import { Category } from 'types/Entity';

import {
  HouseDetailsImage1,
  HouseDetailsImage2,
  PackingListImage,
  WhatsProvidedImage,
  LocationImage,
} from '../../../assets/images';
import afterpayImg from '../../../assets/images/afterpay-bar.png';
import { mapStyles } from '../../../consts';
import { HouseSpecification } from '../../../types/HouseSpecification';
import {
  Checkbox,
  HouseSpecificationIcon,
  Link,
  Modal,
  Typo,
  ImageContainer,
} from '../../primitives';
import ContentfulRenderer from '../ContentfulRenderer';
import { HouseResultsItemProps } from './HouseResultsItem.props';
import { useStyles } from './HouseResultsItem.styles';

// @ts-ignore
const Details: FC<T> = ({
  classes,
  isXs,
  isMobile,
  image1 = { image: '', description: '' },
  image2 = { image: '', description: '' },
  lat = 33.8688,
  lng = 151.2093,
  content,
}) => {
  const {
    title,
    location,
    level,
    spiceLevel,
    specifications,
    description,
    directions,
    pricing,
    note,
    smallIcon,
    currency,
    landOwners,
    country,
    cities,
    categories,
    offers,
    addressUrl,
  } = content;

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [categoryTooltipContent, setCategoryTooltipContent] =
    useState<string>('');

  const Specification = () => {
    if (specifications.length === 0 || !Array.isArray(specifications)) {
      return null;
    } else {
      return (
        <Box style={{ display: 'flex', flexFlow: 'wrap' }}>
          {specifications.map(
            (hs: { id: string; name: HouseSpecification; icon: string }) => {
              let src: string | undefined = undefined;
              if (hs.icon) {
                const icons = JSON.parse(hs.icon);
                if (Array.isArray(icons)) {
                  src = icons[0];
                } else src = hs.icon;
              }

              return (
                <Box
                  key={hs.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    flex: '1 0 auto',
                    textAlign: 'center',
                  }}
                  mr={isMobile ? 2 : 6}
                  mb={2}
                >
                  <HouseSpecificationIcon
                    type={hs.name}
                    width={35}
                    height={35}
                    src={src}
                  />
                  <Typo variant="body3">{hs.name}</Typo>
                </Box>
              );
            }
          )}
        </Box>
      );
    }
  };

  const Image2 = () => {
    return (
      <Grid item xs={12} sm={6}>
        <Box mb={0.5}>
          <ImageContainer
            alt="details-image2"
            src={image2.image || HouseDetailsImage2}
            className={classes.image}
          />
        </Box>
        <Typo variant="caption1">{image2.description || 'Focus'}</Typo>
      </Grid>
    );
  };

  const RatingAndSpecification = (
    <>
      <Box mt={8} style={{ display: 'flex', flexDirection: 'column' }}>
        {showTooltip && (
          <Modal
            open={showTooltip}
            onBackdropClick={() => setShowTooltip(false)}
            onClose={setShowTooltip}
            rootStyle={classes.tooltipRoot}
            paperStyle={classes.tooltipPaper}
            closeIconStyle={classes.tooltipCloseIcon}
            titleContainerStyle={classes.tooltipTitle}
            contentContainerStyle={classes.tooltipContent}
            disableMobileFullScreen={true}
            disableLogo={true}
          >
            {categoryTooltipContent}
          </Modal>
        )}

        {categories &&
          categories.map((category: Category) => {
            const [infoOpen, setInfoOpen] = useState(false);

            return (
              <Box
                mb={4}
                key={category.id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Box width="300px">
                  <Typo style={{ fontSize: '13pt' }}>{category.name}</Typo>
                </Box>

                {[1, 2, 3].map((rate) => (
                  <Box key={rate} mr={isMobile ? 1 : 4} height={'11pt'}>
                    {rate <= (category?.rating || 0) ? (
                      <Lens style={{ width: '11pt', height: '11pt' }} />
                    ) : (
                      <RadioButtonUnchecked
                        style={{ width: '11pt', height: '11pt' }}
                      />
                    )}
                  </Box>
                ))}
                {/* <InfoOutlined
                  fontSize="large"
                  onClick={() => {
                    setShowTooltip(true);
                    setCategoryTooltipContent(category.information);
                  }}
                /> */}

                <Box
                  style={{
                    border: `1px solid ${colors.primaryDarkGreen}`,
                    borderRadius: '50%',
                    width: '20pt',
                    height: '20pt',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    paddingBottom: 4,
                  }}
                  onClick={() => {
                    setShowTooltip(true);
                    setCategoryTooltipContent(category.information);
                  }}
                >
                  <Typo
                    style={{
                      fontSize: '13pt',
                      fontWeight: 700,
                      height: '13pt',
                    }}
                    variant={'body2'}
                  >
                    i
                  </Typo>
                </Box>
              </Box>
            );
          })}
      </Box>

      <Box mt={4} width="100%">
        <Specification />
      </Box>
    </>
  );

  // useEffect(() => {

  //   // @ts-ignore
  //   const AfterPay: any = window['AfterPay'];
  //   if (!AfterPay) {
  //     return console.error('AfterPay is undefined.');
  //   }

  //   new AfterPay.Widgets.Placement({
  //     target: "#afterpay-widget-cabin-weekends",
  //     locale: `en-${country}`,
  //     context: "PRODUCT_PAGE",
  //     publicKey: "testKey",
  //     attributes: {
  //         max: "2000",
  //         showLowerLimit: false,
  //         styles: {
  //           fontFamily: "Plain, sans-serif",
  //         }
  //     }
  //   });

  // }, [])

  return (
    <Grid container>
      <Grid container>
        <Grid
          item
          container
          direction="column"
          xs={12}
          sm={6}
          style={{
            flexWrap: 'nowrap',
          }}
        >
          <Box pr={isXs ? 0 : 8}>
            <Box mb={4} style={{ display: 'flex', flexDirection: 'row' }}>
              <Grid
                container
                direction={isMobile ? 'column-reverse' : 'column'}
              >
                <Typo
                  variant={isMobile ? 'h3' : 'h1'}
                  className={classes.header}
                >
                  {title}
                </Typo>
                <Box mt={1} style={{ display: 'flex', flexDirection: 'row' }}>
                  <Link
                    href={addressUrl}
                    target="_blank"
                    rel="noreferrer"
                    linkColor={'none'}
                  >
                    <Typo
                      variant={isMobile ? 'h6' : 'h5'}
                      className={classes.locationHeader}
                      style={
                        isMobile
                          ? {
                              borderBottom: 'unset',
                              textDecoration: 'underline',
                            }
                          : {
                              borderBottom: `4px solid ${colors.primaryDarkGreen}`,
                              textDecoration: 'none',
                            }
                      }
                    >
                      {location}
                    </Typo>
                  </Link>
                  <LocationOn fontSize="small" />
                </Box>
              </Grid>
              <Box>
                <ImageContainer
                  alt="small-icon"
                  src={smallIcon}
                  className={classes.smallIcon}
                />
              </Box>
            </Box>
            {directions || ''}
            <Box mb={1} />
            <Box>{landOwners || ''}</Box>
            <Box mb={1} />
            <Typo variant={isMobile ? 'body3' : 'body2'}>
              Adventure Level - {level}
            </Typo>

            <Box mt={4} mb={isXs ? 6 : 4}>
              <Typo
                variant={isMobile ? 'h5' : 'h3'}
                dangerouslySetInnerHTML={{ __html: description }}
              ></Typo>
            </Box>

            {!isMobile && RatingAndSpecification}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box mb={0.5}>
            <ImageContainer
              alt="details-image1"
              src={image1.image || HouseDetailsImage1}
              className={classes.rhsImage}
            />
          </Box>
          <Typo variant="caption1">{image1.description || 'Explore'}</Typo>

          {isMobile && (
            <>
              <Box mb={4} />
              {RatingAndSpecification}
            </>
          )}
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: isXs ? 0 : 100 }}>
        {!isXs && <Image2 />}
        <Grid
          item
          container
          direction="column"
          xs={12}
          sm={6}
          justifyContent={isXs ? 'flex-start' : 'center'}
        >
          {/* eslint-dsable-next-line no-nested-ternary */}
          <Box mt={isXs ? 4 : 0} pl={isXs ? 0 : isMobile ? 7 : 14}>
            <Typo variant={isMobile ? 'h3' : 'h1'} className={classes.header}>
              {currency.symbol}
              {pricing.weeknights} weeknights
            </Typo>
            <Typo variant={isMobile ? 'h3' : 'h1'} className={classes.header}>
              {currency.symbol}
              {pricing.weekend} weekends
            </Typo>
            <Typo variant="h5">Price per night in {currency.name}.</Typo>
            {!isAfterpayDisabled(country) ? (
              <>
                <Box mb={3} />
                <img width="100%" src={afterpayImg} />
              </>
            ) : null}

            {/* {['UK', 'GB'].indexOf(country) > -1 ? (
              <>
                <Box mb={3} />
                <img width="100%" src={klarnaBanner} />
              </>
            ) : null} */}
            {/* <div id="afterpay-widget-cabin-weekends" /> */}
            <Box mb={3} />
            <Typo
              variant={isMobile ? 'body3' : 'body1'}
              dangerouslySetInnerHTML={{ __html: note }}
            ></Typo>
          </Box>
        </Grid>
        {isXs && <Image2 />}
      </Grid>
    </Grid>
  );
};

// @ts-ignore
const Story: FC<T> = ({ classes, isMobile, isXs, story }) => {
  return (
    <Box pl={isMobile ? 0 : 10} pr={isMobile ? 0 : 10}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typo variant={isMobile ? 'h3' : 'h1'} className={classes.header}>
            Notes from the field
          </Typo>
        </Grid>
        <Grid item xs={12} sm={8}>
          <div
            className={classes.story}
            dangerouslySetInnerHTML={{ __html: story }}
          ></div>
          <Typo variant={isMobile ? 'body3' : 'h3'}></Typo>
        </Grid>
      </Grid>
    </Box>
  );
};

// @ts-ignore
const MapBlock: FC<T> = ({
  classes,
  isMobile,
  lat = 33.8688,
  lng = 151.2093,
  location,
  locationImage = LocationImage,
}) => {
  const initDriveTimeVal = 'Calculate Drive Time';

  const [mapsApi, setMapsApi] = useState(undefined);
  const [driveTime, setDriveTime] = useState(initDriveTimeVal);
  const [postCode, setPostCode] = useState('');
  const [error, setError] = useState('');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    if (error) setError('');
    if (driveTime !== initDriveTimeVal) setDriveTime(initDriveTimeVal);
    setPostCode(target.value);
  };

  const calculateDriveTime = () => {
    setDriveTime('Calculating...');

    try {
      // @ts-ignore
      const AutocompleteService = new mapsApi.places.AutocompleteService();
      // @ts-ignore
      const Geocoder = new mapsApi.Geocoder();
      // @ts-ignore
      const DistanceMatrixService = new mapsApi.DistanceMatrixService();
      AutocompleteService.getPlacePredictions(
        {
          componentRestrictions: {
            country: 'AU',
          },
          input: `${postCode} AU`,
        },
        (places: any[]) => {
          if (!places || !places[0]) {
            setDriveTime('Location not found! Please try again...');
          } else {
            const filteredPlaces = places.filter((p) => p.place_id);
            if (filteredPlaces.length === 0) {
              setDriveTime('Location not found! Please try again...');
            } else {
              Geocoder.geocode(
                {
                  placeId: filteredPlaces[0].place_id,
                },
                (addresses: any) => {
                  if (!addresses || !addresses[0])
                    setDriveTime('Location not found! Please try again...');
                  else {
                    const address = addresses[0];
                    const calcLat = address.geometry.location.lat();
                    const calcLng = address.geometry.location.lng();
                    DistanceMatrixService.getDistanceMatrix(
                      {
                        origins: [{ lat: calcLat, lng: calcLng }],
                        destinations: [
                          // location,
                          { lat: Number(lat), lng: Number(lng) },
                        ],
                        travelMode: 'DRIVING',
                      },
                      (directions: any) => {
                        if (!directions || !directions.rows[0]) {
                          setDriveTime(
                            'Directions were not calculated! Please try again...'
                          );
                        } else {
                          const { elements } = directions.rows[0];
                          if (elements[0].status === 'OK') {
                            setDriveTime(
                              `Your driving time is ${elements[0].duration.text}.`
                            );
                          } else {
                            setDriveTime(
                              `Your driving time is ${elements[1].duration.text}.`
                            );
                          }
                        }
                      }
                    );
                  }
                }
              );
            }
          }
        }
      );
    } catch (e) {
      setDriveTime('Something went wrong! Please try again...');
    }
  };

  // @ts-ignore
  const apiIsLoaded = (maps) => setMapsApi(maps);

  const onSubmit = () => {
    // Australian post code range
    const re = RegExp('^(?:(?:[2-8]\\d|9[0-7]|0?[28]|0?9(?=09))(?:\\d{2}))$');

    if (!re.test(postCode)) {
      setError('Please enter an Australian Post code');
      return;
    }

    calculateDriveTime();
  };

  const onKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    if (key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <Grid container direction="column">
      <Box pl={isMobile ? 0 : 10} pr={isMobile ? 0 : 10}>
        <Grid item xs={12}>
          <ImageContainer
            alt="location-image"
            src={locationImage}
            className={classes.locationImage}
          />
          <Box style={{ display: 'none' }}>
            <GoogleMapReact
              defaultCenter={{
                lat: Number(lat),
                lng: Number(lng),
              }}
              zoom={14}
              options={{ styles: mapStyles }}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ maps }) => apiIsLoaded(maps)}
            />
          </Box>
        </Grid>
        {/*<Box mb={4} />
        {mapsApi && (
          <Grid item xs={6}>
            <Typo variant="h6">{driveTime}</Typo>
            <SubmitField
              placeholder="Enter post code"
              endAdornmentType={isMobile ? 'submit' : 'arrow'}
              error={error}
              onChange={onChange}
              onSubmit={onSubmit}
              onKeydown={onKeydown}
            />
          </Grid>
        )}*/}
      </Box>
    </Grid>
  );
};

// @ts-ignore
const WhatsProvided: FC<T> = ({
  classes,
  isMobile,
  isXs,
  image = { image: '', description: '' },
  list,
}) => {
  return (
    <Grid container>
      <Grid
        container
        direction="column"
        item
        xs={12}
        sm={6}
        alignContent="center"
      >
        <Box mb={0.5}>
          <ImageContainer
            alt="whats-provided"
            src={image.image || WhatsProvidedImage}
            className={classes.image}
          />
        </Box>
        <Typo variant="caption1">{image.description || 'Wellness'}</Typo>
      </Grid>
      <Grid
        container
        direction="column"
        item
        xs={12}
        sm={6}
        className={classes.whatsProvided}
      >
        <Box mb={4}>
          <Typo variant={isMobile ? 'h6' : 'h1'} className={classes.header}>
            What&apos;s Provided?
          </Typo>
        </Box>
        <Box pl={isXs ? 1 : 0}>
          <Grid container direction="row" spacing={isMobile ? 2 : 4}>
            {list &&
              [...list].map((label) => {
                return (
                  <Grid key={label} item xs={6}>
                    <Checkbox
                      label={label}
                      checked={true}
                      disabled={true}
                      randomChecked={true}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

// @ts-ignore
const PackingList: FC<T> = ({
  classes,
  isMobile,
  isXs,
  image = { image: '', description: '' },
  list = [''],
  crossed,
}) => {
  const packingText = 'Expedition Packing List';
  const notIncluded = crossed || ['Spreadsheets', 'Anxiety', 'Busyness'];

  const [trigger, setTrigger] = useState(false);

  return (
    <Grid container direction="row-reverse">
      <Grid item xs={12} sm={6}>
        <ImageContainer
          alt="packing-image"
          src={image.image || PackingListImage}
          className={classes.image}
        />
        <Box mb={0.5} />
        <Typo variant="caption1">{image.description || 'Chill'}</Typo>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={6}
        direction="column"
        alignContent={isMobile ? 'flex-start' : 'center'}
        justifyContent="center"
      >
        <Box mb={isMobile ? 2 : 1} mt={isXs ? 3 : 0}>
          <Typo variant="h6">{packingText}</Typo>
        </Box>
        {list.map((item: string) => (
          <Box key={item} mb={1} style={{ whiteSpace: 'nowrap' }}>
            <Typo variant={'h3'} className={classes.header}>
              {item}
            </Typo>
          </Box>
        ))}

        <TrackVisibility once>
          {({ isVisible }) => {
            setTrigger(isVisible);

            return (
              <>
                {notIncluded.map((item, index) => (
                  <Box
                    key={item}
                    mb={1}
                    className={`${classes.strikethrough} ${
                      trigger ? classes[`s${index}`] : ''
                    }`}
                  >
                    <Typo
                      variant={isMobile ? 'h3' : 'h1'}
                      className={classes.header}
                    >
                      {item}
                    </Typo>
                  </Box>
                ))}
              </>
            );
          }}
        </TrackVisibility>
      </Grid>
    </Grid>
  );
};

const HouseResultsItemView: FC<HouseResultsItemProps> = (props) => {
  const {
    loading,
    detailsContent,
    detailsImage1,
    detailsImage2,
    latitude,
    longitude,
    whatsProvidedImage,
    whatsProvidedList,
    packingImage,
    packingList,
    packingListCrossed,
    locationImage,
    story,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles({ isMobile, isXs });

  return (
    <Box className={classes.mainContainer}>
      {loading ? (
        <Box pt={!isXs ? 10 : 0}>
          <LinearProgress color="secondary" />
        </Box>
      ) : (
        <>
          <Details
            classes={classes}
            isMobile={isMobile}
            isXs={isXs}
            content={detailsContent}
            image1={detailsImage1}
            image2={detailsImage2}
          />
          <Box mb={isMobile ? 10 : 15} />
          <Story
            classes={classes}
            isMobile={isMobile}
            isXs={isXs}
            story={story}
          />
          <Box mb={isMobile ? 10 : 15} />
          <MapBlock
            classes={classes}
            isMobile={isMobile}
            lat={latitude}
            lng={longitude}
            locationImage={locationImage}
            location={detailsContent.location}
          />
          <Box mb={isMobile ? 10 : 30} />
          <WhatsProvided
            classes={classes}
            isMobile={isMobile}
            isXs={isXs}
            image={whatsProvidedImage}
            list={whatsProvidedList}
          />
          <Box mb={isMobile ? 5 : 30} />
          <PackingList
            classes={classes}
            isMobile={isMobile}
            isXs={isXs}
            image={packingImage}
            list={packingList}
            crossed={packingListCrossed}
          />
        </>
      )}
    </Box>
  );
};

export default React.memo(HouseResultsItemView);
