import React, { FC, useState } from 'react';

import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  HorizontalLine,
  ImageContainer,
  InputQuantity,
  Typo,
} from '../../primitives';
import { ProvisionCardProps } from './ProvisionCard.props';
import { useStyles } from './ProvisionCard.styles';

const ProvisionCardView: FC<ProvisionCardProps> = (props) => {
  const {
    id,
    name,
    image = '',
    description = '',
    provider = '',
    yieldRange = '',
    maxPurchasable,
    addOn,
    provisionPrice,
    onChange,
    value
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });
  const quantity = (addOn && addOn.quantity) || 0;
  return (
    <Box className={classes.container}>
      {!isMobile && <HorizontalLine className={classes.line} />}
      <Box mt={1}>
        <Typo variant="h5">{name}</Typo>
      </Box>
      <ImageContainer
        alt="voucher-card"
        className={classes.image}
        src={image}
      />
      <Box mb={2}>
        {!isMobile && (
          <>
            {provider && (
              <Box mb={1}>
                <Typo variant="body1">{`by ${provider}`}</Typo>
              </Box>
            )}
            {description &&
              description
                .split('\n')
                .filter((value) => value.length > 0)
                .map((value) => (
                  <Box key={value} mb={0.25}>
                    <Typo variant="body1">{value}</Typo>
                  </Box>
                ))}
            {yieldRange && (
              <Box mt={1}>
                <Typo variant="body1">{`Yield: ${yieldRange}`}</Typo>
              </Box>
            )}
          </>
        )}
        {isMobile && (
          <Grid container direction="column" spacing={1}>
            {provider && (
              <Grid item>
                <Typo variant="body1">{`by ${provider}`}</Typo>
              </Grid>
            )}
            {description && (
              <Grid item direction="column">
                {description
                  .split('\n')
                  .filter((value) => value.length > 0)
                  .map((value) => (
                    <Box key={value} mb={0.25}>
                      <Typo variant="body1">{value}</Typo>
                    </Box>
                  ))}
              </Grid>
            )}
            {yieldRange && (
              <Grid item>
                <Typo variant="body1">{`Yield: ${yieldRange}`}</Typo>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
      <Box className={classes.bag}>
        {addOn ? (
          <Grid container>
            <Grid item xs={8} sm={8} md={8} lg={8}>
              <Typo variant="h5">{provisionPrice}</Typo>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <InputQuantity
                value={value}
                inputProps={{ min: quantity, max: maxPurchasable }}
                onChange={(quantity) => onChange && onChange(id, quantity)}
                disabled={!addOn}
              /> 
            </Grid>
          </Grid>
        ) : (
          <>
            <Box flexGrow={1}>
              <Typo variant="h5">{provisionPrice}</Typo>
            </Box>
            <Box>
              <Button
                className={classes.notAvailable}
                variant="outlined"
                disabled
              >
                Not available
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(ProvisionCardView);
