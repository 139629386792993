import React, { FC, useState, useEffect, useRef } from 'react';
import moment from 'moment';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import MaterialButton from '@material-ui/core/Button';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import ArrowUpwardIcon from '@material-ui/icons/ExpandLess';
import ArrowDownwardIcon from '@material-ui/icons/ExpandMore';
import { Button, Calendar, Typo } from 'components/primitives';
import { addDays, subDays } from 'date-fns';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import pathOr from 'ramda/src/pathOr';
import { Entity } from 'types/Entity';

import { FILTER } from '../../../consts';
import { colors } from '../../../themeConfig/themeConstants';
import { pxToRem } from '../../../themeConfig/typography';
import {
  transformBookedDates,
  isValidRange,
  rangeHasException,
  isMinimumStay,
  isMinimumStayOnNewYearsEve,
  isMinimumStayOnWeekNight,
  is3NightsMinimumStay,
  getDays,
  isMinimumStayOnEvents,
} from '../../../utils/Data/bookedDates/bookedDates';
import { useCalendar } from '../../primitives/Calendar';
import Snackbar from '../../primitives/Snackbar';
import { BookingBarProps } from './BookingBar.props';
import { useStyles } from './BookingBar.styles';
import { useClickTrack } from 'hooks/useClickTrack';

const SELECTION = {
  DATE_START: 'startDate',
  DATE_END: 'endDate',
  GUESTS: 'guests',
  BEDS: 'beds',
  EMPTY: '',
};

const getIcon = (selected: boolean) =>
  selected ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />;

const BookingBarView: FC<BookingBarProps> = ({
  filterData,
  nextAvailableStart,
  nextAvailableUntil,
  entity,
  onBook,
  updateFilter,
  questions,
}: BookingBarProps) => {
  const hideTrigger = useScrollTrigger({
    threshold: 300,
  });

  const containerRef = useRef(null);

  const currentEntity: Entity | {} = pathOr({}, ['data', 'entity'], entity);

  const [hidden, setHidden] = useState(false);

  const [selected, setSelected] = useState(SELECTION.EMPTY);
  const selectTracker = useClickTrack('Cabin Page Interaction');
  const _setSelected = (toPass: string, data: any) => {
    if (toPass === SELECTION.EMPTY) {
      const isDateStart = selected === SELECTION.DATE_START;
      const value = isDateStart
        ? {
            startDate: format(data?.startDate, 'MMM dd, yyyy'),
            endDate: format(data?.endDate, 'MMM dd, yyyy'),
          }
        : data;
      selectTracker({
        clicked: isDateStart ? 'Dates' : 'Guests',
        value,
      });
    }
    setSelected(toPass);
  };

  const [location, setLocation] = useState(
    pathOr(
      {
        cityId: null,
        latitude: 0,
        longitude: 0,
        address: '',
      },
      ['location'],
      filterData
    )
  );

  const initialStartDate = pathOr('', ['startDate'], filterData);
  const initialEndDate = pathOr('', ['endDate'], filterData);

  const [startDate, setStartDate] = useState(
    initialStartDate ||
      (nextAvailableStart ? format(nextAvailableStart, 'MMM dd, yyyy') : '')
  );
  const [endDate, setEndDate] = useState(
    initialEndDate ||
      (nextAvailableUntil ? format(nextAvailableUntil, 'MMM dd, yyyy') : '')
  );

  useEffect(() => {
    // set calendar month as next month if today's day is the last day of the <month></month>
    if (startDate) return;
    const nowDate = moment().format('MMM DD, yyyy');
    const endDate = moment().endOf('month').format('MMM DD, yyyy');
    const isLastDay = nowDate === endDate;
    if (isLastDay) {
      setStartDate(
        moment().add(1, 'month').startOf('month').format('MMM DD, yyyy')
      );
    }
  }, []);

  const [beds, setBeds] = useState(pathOr('', ['beds'], filterData));
  const [guests, setGuests] = useState(pathOr('', ['guests'], filterData));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [disabledDays, setDisabledDays] =
    useState<{ before: Date; after?: Date }[]>();

  const classes = useStyles({
    hidden,
  });
  const timezone = pathOr('Australia/Melbourne', ['timezone'], currentEntity);
  const entityCapacity = pathOr(0, ['capacity'], currentEntity);
  const entityBeds = pathOr(0, ['metadata', 'beds'], currentEntity);
  const acceptSingleNight = pathOr(
    'false',
    ['metadata', 'acceptSingleNight'],
    currentEntity
  );
  const twoNightMinimum = pathOr(
    null,
    ['metadata', 'twoNightMinimum'],
    currentEntity
  );

  const threeNightsMinimum = pathOr(null, ['is3NightsMinimum'], currentEntity);

  const guestsLimit = Array.from(
    Array(
      entityCapacity < FILTER.GUESTS_LIMIT
        ? FILTER.GUESTS_LIMIT
        : entityCapacity
    ).keys()
  ).map((v) => (v + 1).toString());
  // const bedsLimit = Array.from(
  //   Array(
  //     entityBeds > FILTER.BEDS_LIMIT ? FILTER.BEDS_LIMIT : entityBeds,
  //   ).keys(),
  // ).map(v => (v + 1).toString());

  const handleClickOutside = (event: Event) => {
    // @ts-ignore
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      if (selected) setSelected(SELECTION.EMPTY);
    }
  };

  const onClickBook = () => {
    if (startDate && endDate && guests) {
      onBook(startDate, endDate, guests);
    } else if (!startDate) {
      setHidden(false);
      setSelected(SELECTION.DATE_START);
    } else if (!endDate) {
      setHidden(false);
      setSelected(SELECTION.DATE_END);
    } else if (!guests) {
      setHidden(false);
      setSelected(SELECTION.GUESTS);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    if (!hideTrigger) setHidden(false);
    else setHidden(true);
  }, [hideTrigger]);

  useEffect(() => {
    if (!location.cityId) return;
    updateFilter({
      ...filterData,
      location,
      startDate,
      endDate,
      beds,
      guests,
    });
  }, [location, startDate, endDate, beds, guests]);

  const bookedDates = pathOr(
    [],
    ['data', 'entity', 'schedule', '0', 'bookings'],
    entity
  );

  const changeOverDays = pathOr(
    [],
    ['data', 'entity', 'schedule', '0', 'changeOverDays'],
    entity
  );

  const exceptions = pathOr(
    {
      freq: '',
      byday: '',
    },
    ['data', 'entity', 'schedule', '0', 'exceptions'],
    entity
  );

  const fullyBookedDates = transformBookedDates(bookedDates, timezone);
  const transformedBookedDates = transformBookedDates(
    bookedDates,
    timezone,
    changeOverDays
  );

  const calendarHook = useCalendar(
    ({ from, to }) => {
      setStartDate(from ? format(from, 'MMM dd, yyyy') : '');
      setEndDate(to ? format(to, 'MMM dd, yyyy') : '');

      if (from && to)
        _setSelected(SELECTION.EMPTY, { startDate: from, endDate: to });
      setDisabledDays(undefined);
    },
    fullyBookedDates,
    exceptions,
    (from) => {
      let diff = 0;
      let closestDate: Date | undefined = undefined;
      for (const date of fullyBookedDates) {
        if (date.after > subDays(from, 1)) {
          if (!diff) {
            diff = date.after.getTime() - from.getTime();
            closestDate = date.after;
            continue;
          }

          if (date.after.getTime() - from.getTime() < diff) {
            diff = date.after.getTime() - from.getTime();
            closestDate = date.after;
          }
        }
      }

      const disableDay: { after?: Date; before: Date } = { before: from };
      if (closestDate) disableDay.after = addDays(closestDate, 1);

      setDisabledDays([disableDay]);
      // setStartDate(from ? format(from, 'MMM dd, yyyy') : '');
      setEndDate('');
    }
  );

  const parsedSd = parse(startDate, 'MMM dd, yyyy', new Date());
  const parsedEd = parse(endDate, 'MMM dd, yyyy', new Date());

  const q = (q: string, key: 'label' | 'description') => {
    return questions && questions[q] && questions[q][key];
  };

  useEffect(() => {
    if (startDate && endDate && fullyBookedDates.length > 0) {
      if (rangeHasException(parsedSd, parsedEd, exceptions)) {
        setStartDate('');
        setEndDate('');

        return;
      }

      if (!isValidRange(parsedSd, parsedEd, fullyBookedDates)) {
        setStartDate('');
        setEndDate('');
      }
    }
  }, [currentEntity]);

  useEffect(() => {
    let timer: number;
    let event;
    if (!isMinimumStay(parsedSd, parsedEd, twoNightMinimum))
      setSnackbarMessage(
        twoNightMinimum &&
          (twoNightMinimum as boolean[]).map((o) => o).length == 7
          ? `Please select a minimum of 2 nights.`
          : `Bookings on ${getDays(
              twoNightMinimum
            )} must be at least two nights.`
      );

    const is3Nights = is3NightsMinimumStay(
      parsedSd,
      parsedEd,
      threeNightsMinimum
    );
    if (!is3Nights) {
      setSnackbarMessage('Mininum of three nights booking');
    }

    if (
      startDate &&
      endDate &&
      (event = isMinimumStayOnEvents(parsedSd, parsedEd))
    ) {
      setSnackbarMessage(
        `Bookings on ${(event as any).text} must be at least ${
          (event as any).nights
        } nights.`
      );
    } else if (!isMinimumStayOnNewYearsEve(parsedSd, parsedEd))
      setSnackbarMessage(
        `Bookings on New Year's Eve must be at least two nights.`
      );
    else if (
      !twoNightMinimum &&
      !isMinimumStayOnWeekNight(parsedSd, parsedEd, 'true')
    )
      setSnackbarMessage(`Bookings on weeknights must be at least two nights.`);

    if (
      (startDate &&
        endDate &&
        (!isMinimumStay(parsedSd, parsedEd, twoNightMinimum) ||
          event ||
          !isMinimumStayOnNewYearsEve(parsedSd, parsedEd) ||
          (!twoNightMinimum &&
            !isMinimumStayOnWeekNight(parsedSd, parsedEd, 'true')))) ||
      (threeNightsMinimum &&
        !is3NightsMinimumStay(parsedSd, parsedEd, threeNightsMinimum))
    ) {
      setStartDate('');
      setEndDate('');
      calendarHook.handleReset();
      setOpenSnackbar(true);

      timer = window.setTimeout(() => {
        setOpenSnackbar(false);
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [startDate, endDate, threeNightsMinimum]);

  const calendarBookedDates = disabledDays || transformedBookedDates;

  return (
    <div ref={containerRef}>
      <AppBar classes={{ root: classes.appBar }}>
        <Box className={classes.container}>
          <Box
            borderLeft={1}
            borderRight={1}
            borderTop={5}
            className={
              selected === SELECTION.DATE_START
                ? `${classes.extendedItemContainer} ${classes.firstItemContainer}`
                : `${classes.itemContainer} ${classes.firstItemContainer}`
            }
          >
            {selected === SELECTION.DATE_START && (
              <Box
                style={{
                  display: 'flex',
                  width: '100%',
                  minHeight: '300px',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box>
                  <Typo
                    className={classes.textItems}
                    variant="body3"
                    style={{ marginBottom: 15 }}
                  >
                    Check-in / Check-out
                  </Typo>
                  <Typo align="center" variant={'h5'}>
                    {q('booking_questions_date_no_flex', 'label')}
                  </Typo>
                  <Typo
                    align="center"
                    variant="body3"
                    style={{ marginTop: '5px' }}
                  >
                    {q('booking_questions_date_no_flex', 'description')}
                  </Typo>
                </Box>
                <Box>
                  <Calendar
                    month={
                      startDate
                        ? parse(startDate, 'MMM dd, yyyy', new Date())
                        : new Date()
                    }
                    bookedDates={calendarBookedDates}
                    hookState={calendarHook}
                    exceptions={exceptions}
                    isBooking={true}
                  />
                </Box>
              </Box>
            )}
            <Box style={{ width: '100%', minWidth: '280px' }}>
              {selected !== SELECTION.DATE_START ? (
                <Typo className={classes.textItems} variant="body3">
                  Check-in / Check-out
                </Typo>
              ) : null}
              <MaterialButton
                className={`${classes.arrowButtons} ${
                  startDate ? classes.hasValue : {}
                }`}
                onClick={() =>
                  setSelected(
                    selected === SELECTION.DATE_START
                      ? SELECTION.EMPTY
                      : SELECTION.DATE_START
                  )
                }
              >
                {startDate && endDate
                  ? `${format(
                      parse(startDate, 'MMM dd, yyyy', new Date()),
                      'MMM dd'
                    )} - ${format(
                      parse(endDate, 'MMM dd, yyyy', new Date()),
                      'MMM dd'
                    )}`
                  : getIcon(selected === SELECTION.DATE_START)}
              </MaterialButton>
            </Box>
          </Box>
          {/* <Box
            borderLeft={1}
            borderRight={1}
            borderTop={5}
            className={
              selected === SELECTION.DATE_END
                ? classes.extendedItemContainer
                : classes.itemContainer
            }
          >
            {selected === SELECTION.DATE_END && (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  minHeight: '328px',
                  flexDirection: 'column',
                }}
              >
                <Box>
                  <Typo align="center" variant={'h5'}>
                    {q('booking_questions_date', 'label')}
                  </Typo>
                  <Typo
                    align="center"
                    variant="body3"
                    style={{ marginTop: '5px' }}
                  >
                    {q('booking_questions_date', 'description')}
                  </Typo>
                </Box>
                <Calendar
                  month={
                    endDate
                      ? parse(endDate, 'MMM dd, yyyy', new Date())
                      : startDate
                      ? parse(startDate, 'MMM dd, yyyy', new Date())
                      : new Date()
                  }
                  bookedDates={calendarBookedDates}
                  hookState={calendarHook}
                  exceptions={exceptions}
                  isBooking={true}
                />
              </Box>
            )}
            <Box style={{ width: '100%', minWidth: '280px' }}>
              <Typo className={classes.textItems} variant="body3">
                Check-out
              </Typo>
              <MaterialButton
                className={`${classes.arrowButtons} ${
                  endDate ? classes.hasValue : {}
                }`}
                onClick={() =>
                  setSelected(
                    selected === SELECTION.DATE_END
                      ? SELECTION.EMPTY
                      : SELECTION.DATE_END
                  )
                }
              >
                {endDate
                  ? `${format(
                      parse(endDate, 'MMM dd, yyyy', new Date()),
                      'MMM dd'
                    )}`
                  : getIcon(selected === SELECTION.DATE_END)}
              </MaterialButton>
            </Box>
          </Box> */}
          <Box
            borderLeft={1}
            borderRight={2}
            borderTop={5}
            className={
              selected === SELECTION.GUESTS
                ? classes.extendedItemContainer
                : classes.itemContainer
            }
          >
            {selected === SELECTION.GUESTS ? (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Typo className={classes.textItems} variant="body3">
                  Guests
                </Typo>
                {guestsLimit.map((v) => (
                  <MaterialButton
                    key={v}
                    className={classes.arrowButtons}
                    onClick={() => {
                      setGuests(v);
                      _setSelected(SELECTION.EMPTY, v);
                    }}
                  >
                    {v}
                  </MaterialButton>
                ))}

                <MaterialButton
                  className={classes.arrowButtons}
                  onClick={() => setSelected(SELECTION.EMPTY)}
                >
                  <ArrowDownwardIcon />
                </MaterialButton>
              </Box>
            ) : (
              <Box style={{ width: '100%' }}>
                <Typo className={classes.textItems} variant="body3">
                  Guests
                </Typo>
                <MaterialButton
                  className={`${classes.arrowButtons} ${
                    guests ? classes.hasValue : {}
                  }`}
                  onClick={() =>
                    setSelected(
                      selected === SELECTION.GUESTS
                        ? SELECTION.EMPTY
                        : SELECTION.GUESTS
                    )
                  }
                >
                  {guests || <ArrowUpwardIcon />}
                </MaterialButton>
              </Box>
            )}
          </Box>

          {/* <Box */}
          {/*  borderLeft={1} */}
          {/*  borderRight={2} */}
          {/*  borderTop={5} */}
          {/*  className={ */}
          {/*    selected === SELECTION.BEDS */}
          {/*      ? classes.extendedItemContainer */}
          {/*      : classes.itemContainer */}
          {/*  } */}
          {/* > */}
          {/*  {selected === SELECTION.BEDS ? ( */}
          {/*    <Box */}
          {/*      style={{ */}
          {/*        display: 'flex', */}
          {/*        flexDirection: 'column', */}
          {/*        width: '100%', */}
          {/*      }} */}
          {/*    > */}
          {/*      <Typo className={classes.textItems} variant="body3"> */}
          {/*        Beds */}
          {/*      </Typo> */}
          {/*      {bedsLimit.map(v => ( */}
          {/*        <MaterialButton */}
          {/*          key={v} */}
          {/*          className={classes.arrowButtons} */}
          {/*          onClick={() => { */}
          {/*            setBeds(v); */}
          {/*            setSelected(SELECTION.EMPTY); */}
          {/*          }} */}
          {/*        > */}
          {/*          {v} */}
          {/*        </MaterialButton> */}
          {/*      ))} */}

          {/*      <MaterialButton */}
          {/*        className={classes.arrowButtons} */}
          {/*        onClick={() => setSelected(SELECTION.EMPTY)} */}
          {/*      > */}
          {/*        <ArrowDownwardIcon /> */}
          {/*      </MaterialButton> */}
          {/*    </Box> */}
          {/*  ) : ( */}
          {/*    <Box style={{ width: '100%' }}> */}
          {/*      <Typo className={classes.textItems} variant="body3"> */}
          {/*        Beds */}
          {/*      </Typo> */}
          {/*      <MaterialButton */}
          {/*        className={classes.arrowButtons} */}
          {/*        onClick={() => */}
          {/*          setSelected( */}
          {/*            selected === SELECTION.BEDS */}
          {/*              ? SELECTION.EMPTY */}
          {/*              : SELECTION.BEDS, */}
          {/*          ) */}
          {/*        } */}
          {/*      > */}
          {/*        {beds || <ArrowUpwardIcon />} */}
          {/*      </MaterialButton> */}
          {/*    </Box> */}
          {/*  )} */}
          {/* </Box> */}
        </Box>
      </AppBar>
      <Box className={classes.bookingContainer}>
        <Box style={{ flexDirection: 'row' }}>
          <Button
            textStyle={{
              fontSize: pxToRem(20),
              color: colors.primaryWhite,
            }}
            iconStyle={{
              width: 21,
              height: 21,
              color: colors.primaryWhite,
            }}
            text={'Book Now'}
            hideUnderline={true}
            onClick={onClickBook}
          />
        </Box>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnackbar}
        type={'normal'}
        message={snackbarMessage}
        onClose={() => setOpenSnackbar(false)}
      />
    </div>
  );
};

export default BookingBarView;
