import React, { FC } from 'react';

import { useDispatch } from 'react-redux';

import ROUTES from '../../../routes';
import { routerActions } from '../../../store/actions';
import { HandPickedPublicProps } from './HandPicked.props';
import View from './HandPicked.view';

const HandPickedContainer: FC<HandPickedPublicProps> = (props) => {
  const dispatch = useDispatch();

  return (
    <View
      {...props}
      explore={() => dispatch(routerActions.link(ROUTES.RESULTS))}
    />
  );
};

export default React.memo(HandPickedContainer);
