import React, { FC, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Footer from 'components/layouts/Footer';
import HomeFilterMobile from 'components/layouts/HomeFilterMobile';
import {
  AppBar,
  BookingBar,
  BookingModal,
  HomeFilter,
} from 'components/modules';
import { colors } from 'themeConfig/themeConstants';

import { FriendBuyRibbon } from '../Main/Main.view';
import { JournalMainProps } from './JournalMain.props';

const JournalMainView: FC<JournalMainProps> = (props: JournalMainProps) => {
  const {
    hideFilter,
    alternateBar,
    appBarProps,
    homeFilterProps,
    isNotStickyAppBar,
    mobileFilterBypass = false,
    setShowMobileFilterBypass,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showMobileFilter, setShowMobileFilter] = useState(false);

  useEffect(() => {
    setShowMobileFilter(mobileFilterBypass);
  }, [mobileFilterBypass]);

  return (
    <Box>
      <AppBar
        {...appBarProps}
        hideFilterIcon={hideFilter}
        showMobileFilter={() => !hideFilter && setShowMobileFilter(true)}
      />

      {props.children}

      <FriendBuyRibbon />
      {alternateBar && !hideFilter && !isMobile && <BookingBar />}
      <Box style={{ paddingTop: alternateBar && isMobile ? 60 : 0 }} />

      <BookingModal />

      {/*
        // @ts-ignore */}
      <Footer expand={!hideFilter && !isMobile} />
    </Box>
  );
};

export default JournalMainView;
