import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'HUBSPOT';
const createHubspotActions = makeCreateActions(ns);

const hubspotActions = createHubspotActions({
  getBookingQuestions: presetActions.makeAsyncAction(`${ns}/BOOKING_QUESTIONS`),
  postBookingQuestions: presetActions.makeAsyncAction(`${ns}/POST_BOOKING_QUESTIONS`),
});

export default hubspotActions;
