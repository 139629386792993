import React, { FC } from 'react';
import { useStyles } from './BookingHistory.styles';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from 'themeConfig/themeConstants';
import { Box, Button } from '@material-ui/core';
import { Typo, Link, GhostButton } from 'components/primitives';
import { useQueryWrapper } from 'utils/Hooks/useQueryWrapper';
import { getBookingDetailsEndpoint } from 'services/fetch/apiEndpoints';
import { Booking, BookingItem } from './BookingHistory.props';
import { CURRENCY } from 'consts/locations';

interface DetailsModalProps {
  id: string;
  item: Booking & BookingItem;
  onClick: () => void;
  onAddProvisions: (id: string) => void;
  isMobile: boolean;
}

interface FullBookingDetails {
  created_at: string;
  discount?: string;
  updated_at: string;
  metadata?: {
    field_guide: string;
  };
}

interface EntryProps {
  title: string;
  value: string;
  isMobile: boolean;
  invert?: boolean;
}
const Entry: FC<EntryProps> = ({ title, value, isMobile, invert }) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
      }}
      paddingY={1}
    >
      <Typo
        color={invert ? 'primaryYellow' : 'primaryDarkGreen'}
        style={{ fontWeight: 700 }}
      >
        {title}:{' '}
      </Typo>
      <Typo color={invert ? 'primaryYellow' : 'primaryDarkGreen'}>{value}</Typo>
    </Box>
  );
};

const DetailsModal: React.FC<DetailsModalProps> = (props) => {
  const { id, item, onAddProvisions } = props;
  const s = useStyles({ isMobile: props.isMobile });

  const { isLoading, data } = useQueryWrapper<string, FullBookingDetails>(
    ['booking-details', id],
    getBookingDetailsEndpoint,
    id,
    {
      staleTime: 300000,
      refetchInterval: 3000000,
      refetchOnMount: false,
    }
  );

  if (!item || !data || isLoading) return <></>;

  const invertEntries = [
    {
      title: 'Booked on',
      value: data?.created_at,
    },
    {
      title: 'Last Updated',
      value: data?.updated_at,
    },
  ];

  const entries = [
    {
      title: 'Check-in',
      value: item?.startDateFull,
    },
    {
      title: 'Check-out',
      value: item?.endDateFull,
    },
    {
      title: 'You paid',
      value: `${item?.bookingMetadata.paid} ${
        CURRENCY[item?.bookingMetadata.countryCode]
      }`,
    },
    {
      title: 'Guests',
      value: `Arriving with ${item?.bookingMetadata.guests} guest/s`,
    },
  ];

  const modalContent = (
    <Box position="relative" height="100%" width="100%">
      <Box
        position="relative"
        zIndex="5"
        display="flex"
        flexDirection="column"
        width="100%"
        padding="5% 10%"
      >
        <Box paddingX={2} bgcolor={colors.primaryDarkGreen} paddingY={4}>
          <Typo color="primaryYellow" variant="h3" gutterBottom>
            Booking Details for your stay in
          </Typo>
          <Typo color="primaryYellow" variant="h2" gutterBottom>
            {item?.name}{' '}
          </Typo>

          {invertEntries.map((x) => (
            <Entry
              key={x.title}
              title={x.title}
              value={x.value || ''}
              isMobile={props.isMobile}
              invert={true}
            />
          ))}
        </Box>
        <Box padding={5} bgcolor={colors.primaryYellow} flex="1">
          <Box
            style={{ display: 'flex', justifyContent: 'space-between' }}
            paddingBottom={2}
          >
            {data?.entity?.metadata?.fieldGuide ? (
              <Link
                href={data?.entity?.metadata?.fieldGuide}
                target="_blank"
                animated
              >
                <Typo variant="h6" color="primaryDarkGreen">
                  Click here to access your Field Guide for your cabin's
                  up-to-date info, directions, maps, recipes, playlists and
                  more. &gt;
                </Typo>
              </Link>
            ) : null}
          </Box>

          {entries.map((x) => (
            <Entry
              key={x.title}
              title={x.title}
              value={x.value || ''}
              isMobile={props.isMobile}
            />
          ))}

          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            paddingY={2}
          >
            <Typo style={{ fontWeight: 700 }}>Your note: </Typo>
            <Typo>{item.bookingMetadata?.notes || `Didn't leave a note.`}</Typo>
          </Box>

          <Box
            style={{
              display: 'flex',
              flexDirection: props.isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
            }}
            paddingY={2}
          >
            <Typo style={{ fontWeight: 700 }}>Add-ons:</Typo>
            <Box mt={2}>
              {item.bookingMetadata.addons
                ? item.bookingMetadata.addons?.map((x) => (
                    <Typo>
                      {x.name} {+x.quantity > 1 ? `x ${x.quantity}` : ''}
                    </Typo>
                  ))
                : 'None added.'}
              <GhostButton
                onClick={() => {
                  onAddProvisions(id);
                }}
                text="Add provisions"
                style={s.provisions}
              ></GhostButton>
            </Box>
          </Box>
        </Box>

        <Box paddingLeft={2} bgcolor={colors.primaryYellow} height={50}>
          <Box display="flex" style={{ gap: 5 }}>
            <Typo variant="h6">Got Questions?</Typo>
            <Link href="/contact" target="_blank" animated>
              <Typo variant="h6">Get in contact.</Typo>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const closeBtn = (
    <Box className={s.closeBtn}>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          props.onClick();
        }}
      >
        <CloseIcon
          style={{ width: 32, height: 32, color: colors.primaryYellow }}
        />
      </a>
    </Box>
  );

  return (
    <Box className={s.detailsModalContainer}>
      {props.isMobile ? closeBtn : null}
      <Box
        className={s.detailsModal}
        style={{
          background: `url('${item.image}')`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        {!props.isMobile ? closeBtn : null}
        {modalContent}
      </Box>
    </Box>
  );
};

export default DetailsModal;
