import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      display: 'flex',
      alignItems: 'center',
      color: colors.primaryWhite,
      width: '300px',
    },
    icon: {
      fontSize: 20,
    },
    normal: {
      backgroundColor: theme.palette.secondary.main,
    },
    warning: {
      backgroundColor: colors.primaryOrange,
    },
    error: {
      backgroundColor: theme.palette.error.main,
    },
  })
);
