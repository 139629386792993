import { Dispatch } from 'redux';
import { getBookingEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { userActions } from 'store/actions';

import { Store } from '../../types/store/Store';

interface Data {}

const getBooking = () => (dispatch: Dispatch, getState: () => Store) => {
  dispatch(
    userActions.getBooking(
      apiFetch<{}, any>(getBookingEndpoint, {})(dispatch, getState)
    )
  );
};

export default getBooking;
