import React, { FC, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import Typo from '../../primitives/Typo';
import FilterInput from '../FilterInput';
import { LocationSearchProps } from './LocationSearch.props';
import { useStyles } from './LocationSearch.styles';

const LocationSearchView: FC<LocationSearchProps> = (
  props: LocationSearchProps
) => {
  const classes = useStyles(props);
  const [address, setAddress] = useState(
    props.initialValue || props.initialLocation || ''
  );

  const searchOptions = {
    componentRestrictions: {
      country: 'AU',
    },
  };

  const geocode = async (addr: string) => {
    return geocodeByAddress(addr)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) =>
        props.onChange({ latitude: lat, longitude: lng, address: addr })
      )
      .catch((error) => {
        // eslint-disable-next-line
        console.error('Error', error);
      });
  };

  useEffect(() => {
    geocode(address);
  }, [props.initialLocation]);

  const handleSelect = async (addr: string) => {
    setAddress(addr);
    geocode(addr);
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const { value, onChange } = getInputProps({});
        return (
          <Box className={classes.container}>
            <FilterInput
              id={props.id}
              type="text"
              label={props.label || ''}
              value={value || ''}
              color={props.color}
              backgroundColor={props.backgroundColor}
              onChange={onChange}
              autoComplete="off"
              placeholder="Search location..."
            />
            <Box
              className={`autocomplete-dropdown-container ${classes.suggestionsContainer}`}
            >
              {loading && (
                <Box className={classes.inactive}>
                  <Typo variant="body2">Loading...</Typo>
                </Box>
              )}
              {suggestions.map((suggestion) => {
                const style = suggestion.active
                  ? classes.active
                  : classes.inactive;
                return (
                  <Box
                    key={suggestion.placeId}
                    {...getSuggestionItemProps(suggestion)}
                    className={style}
                  >
                    <Typo variant="body2">{suggestion.description}</Typo>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default React.memo(LocationSearchView);
