import { expressions } from '../../../consts';

export default function parseLinksInText(
  text: string,
  linkStyle?: string,
  linkInNewTab?: boolean
): string {
  return text
    .replace(/\n/g, ' <br>')
    .replace(expressions.LINK, (word) => {
      // word is a link.
      return `<a href="${word}" class="${linkStyle || ''}" ${linkInNewTab ? 'target="_blank"' : ''} >${word}</a>`;
    })
    .replace(expressions.UNYOKED_EMAIL, (word) => {
      return `<a href="mail:${word}" class="${linkStyle || ''}">${word}</a>`;
    });
}
