import React, { FC, Fragment, useState } from 'react';

import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TrackVisibility from 'react-on-screen';
import { useSpring, animated } from 'react-spring';

import { LeftArrow, RightArrow } from '../../../assets/svgs';
import { useWindowSize } from '../../../utils/Hooks';
import { Typo, Carousel, ImageContainer } from '../../primitives';
import { ImageAndTextCarouselProps } from './ImageAndTextCarousel.props';
import { useStyles } from './ImageAndTextCarousel.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NextArrow = (props: any) => {
  const { className, style, onClick, setTextOpacity } = props;

  const onClickOverride = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setTextOpacity()(0);
    onClick();
  };

  return (
    <RightArrow
      className={className}
      style={{ ...style }}
      onClick={onClickOverride}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PrevArrow = (props: any) => {
  const { className, style, onClick, setTextOpacity } = props;

  const onClickOverride = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setTextOpacity()(0);
    onClick();
  };

  return (
    <LeftArrow
      className={className}
      style={{ ...style }}
      onClick={onClickOverride}
    />
  );
};

const ImageAndTextCarouselView: FC<ImageAndTextCarouselProps> = (props) => {
  const {
    content,
    dots = false,
    autoplay = false,
    speed = 500,
    autoplaySpeed = 3000,
    titleStyle = '',
    captionStyle = '',
    hideMobileText,
    hideOnNavigate,
    animateFade,
    crossedOutText = '',
    overrideHeight = 0,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [textOpacity, setTextOpacity] = useState(1);
  const [trigger, setTrigger] = useState(false);

  // wont work in server-side
  const { height = 1024, width = 768 } = useWindowSize();
  const measure = height > width ? height : width;
  let contentHeight = isMobile ? measure / 2 : measure / 1.5;
  // very small phones
  if (measure <= 568) contentHeight = measure / 1.5;

  contentHeight = overrideHeight || contentHeight - 100;

  const classes = useStyles({
    contentHeight,
  });

  const hideText = isMobile && Boolean(hideMobileText);

  const spring1 = useSpring({
    to: trigger ? { opacity: 1 } : {},
    from: { opacity: 0 },
    immediate: !animateFade,
  });
  const spring2 = useSpring({
    to: trigger ? { width: '105%' } : {},
    from: { width: '0%' },
    delay: 1000,
    immediate: !animateFade,
  });
  const spring3 = useSpring({
    to: trigger ? { opacity: 1 } : {},
    from: { opacity: 0 },
    delay: 2000,
    immediate: !animateFade,
  });
  const AnimatedTypo = animated(Typo);
  const AnimatedBox = animated(Box);

  return (
    <Box className={classes.container}>
      <Carousel
        dots={dots}
        autoplay={autoplay}
        speed={speed}
        initialSlide={1}
        autoplaySpeed={autoplaySpeed}
        className={classes.carousel}
        onSwipe={() => hideOnNavigate && setTextOpacity(0)}
        nextArrow={
          <NextArrow
            setTextOpacity={() => (hideOnNavigate ? setTextOpacity : () => {})}
          />
        }
        prevArrow={
          <PrevArrow
            setTextOpacity={() => (hideOnNavigate ? setTextOpacity : () => {})}
          />
        }
      >
        {content.map(({ image, title, caption, icon }, index) => {
          const formattedTitle = (title || '').replace(/,\s/g, ',\n');
          const filteredTitle = formattedTitle.replace(crossedOutText, '');
          const splitTitle = filteredTitle.split('.');
          const firstTitle = `${splitTitle[0]}`;
          const rest = splitTitle.slice(1, splitTitle.length);
          const restOfTitle = rest.map((text, i) =>
            i !== rest.length - 1 ? `${text}.` : text
          );

          return (
            <Box key={index}>
              <Box
                className={classes.image}
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundPosition: 'center',
                }}
              >
                {!hideText && title && (
                  <Fragment>
                    <Box
                      className={classes.contentContainer}
                      style={{
                        opacity: textOpacity,
                      }}
                    >
                      {icon && (
                        <Box>
                          <ImageContainer
                            alt="big-icon"
                            src={icon}
                            className={classes.bigIcon}
                          />
                        </Box>
                      )}
                      <TrackVisibility once>
                        {({ isVisible }) => {
                          return (
                            <Box className={classes.titleContainer}>
                              <AnimatedTypo
                                variant="h4"
                                color="primary"
                                className={`${
                                  icon ? classes.plainTitle : classes.title
                                } ${
                                  isMobile ? classes.titlePadding : ''
                                } ${titleStyle}`}
                                style={spring1}
                              >
                                {firstTitle}
                                <Box
                                  display="inline"
                                  style={{ position: 'relative' }}
                                >
                                  <Typo
                                    variant="h4"
                                    color="primary"
                                    className={`${
                                      icon ? classes.plainTitle : classes.title
                                    } ${classes.inlineTitle}`}
                                  >
                                    {crossedOutText}.
                                  </Typo>
                                  <AnimatedBox
                                    className={classes.strikethrough}
                                    style={spring2}
                                  />
                                </Box>
                              </AnimatedTypo>
                              <AnimatedTypo
                                variant="h4"
                                color="primary"
                                className={`${
                                  icon ? classes.plainTitle : classes.title
                                } ${
                                  isMobile ? classes.titlePadding : ''
                                } ${titleStyle}`}
                                style={spring3}
                              >
                                {restOfTitle}
                              </AnimatedTypo>
                            </Box>
                          );
                        }}
                      </TrackVisibility>
                    </Box>
                    {caption && (
                      <Typo
                        variant="h6"
                        color="primary"
                        className={`${classes.caption} ${captionStyle}`}
                        style={{
                          opacity: textOpacity,
                        }}
                      >
                        {caption}
                      </Typo>
                    )}
                  </Fragment>
                )}
              </Box>
            </Box>
          );
        })}
      </Carousel>
    </Box>
  );
};

export default React.memo(ImageAndTextCarouselView);
