import { FC, ReactElement } from 'react';
import React from 'react';
import { ReviewStripProps } from './ReviewStrip.props';
import { colors } from 'themeConfig/themeConstants';
import { Rating } from '@material-ui/lab';
import { Typo } from 'components/primitives';

const ReviewStripView: FC<ReviewStripProps> = (props: ReviewStripProps) => {
  if (!props?.leftBlock) {
    return <></>;
  }
  const { leftBlock, additionalData, invert, rightBlock } = props;
  return (
    <div
      style={{
        padding: '15px 0',
        background: invert ? colors.secondaryYellow : colors.primaryDarkGreen,
        color: invert ? colors.primaryDarkGreen : colors.primaryYellow,
      }}
    >
      <a
        href={additionalData.link}
        style={{
          justifyContent: 'center',
          display: 'flex',
          pointerEvents: additionalData.disabled ? 'none' : 'all',
          textDecoration: 'none',
        }}
      >
        <Typo
          variant="h5"
          style={{
            margin: '4px 15px 1px',
            fontFamily: 'Caprasimo',
            letterSpacing: '-0.03em',
            fontSize: props.bottom ? 16 : 30,
            textAlign: 'center',
          }}
        >
          {props.bottom ? rightBlock : leftBlock}
        </Typo>
      </a>
    </div>
  );
};

export default ReviewStripView;
