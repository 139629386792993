import { isWednesday } from 'date-fns';
import { zonedTimeToUtc, formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import getTimezoneOffset from 'date-fns-tz/getTimezoneOffset';
import addDays from 'date-fns/addDays';
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import differenceInHours from 'date-fns/differenceInHours';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import format from 'date-fns/format';
import isFriday from 'date-fns/isFriday';
import isMonday from 'date-fns/isMonday';
import isSameDay from 'date-fns/isSameDay';
import isSaturday from 'date-fns/isSaturday';
import isSunday from 'date-fns/isSunday';
import isThursday from 'date-fns/isThursday';
import isTuesday from 'date-fns/isTuesday';
import isValid from 'date-fns/isValid';
import lastDayOfYear from 'date-fns/lastDayOfYear';
import parseISO from 'date-fns/parseISO';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import moment from 'moment';
import { concat } from 'ramda';
import countBy from 'ramda/src/countBy';
import flatten from 'ramda/src/flatten';
import toLower from 'ramda/src/toLower';
import uniq from 'ramda/src/uniq';
import uniqBy from 'ramda/src/uniqBy';
import { minimumNights } from '../events/events'

const timeZone = 'Etc/UTC';

const DAY = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const IS_DAY = [
  isSunday,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isSaturday,
];

export function getFullyBookedDates(
  bookedDates: { 
    name?: string;
    twoNightMinimum?: boolean[];
    bookings: { startDate: string; endDate: string }[]; 
    changeOverDays: string[];
  }[],
  namesByDates: { [key: string]: string[] } = {}
) {

  const names: string[] = bookedDates.map(b => b.name || '').filter(a => a);

  const individualBookings: string[][] = bookedDates.map((b) => {

    const { twoNightMinimum, name, bookings, changeOverDays } = b;

    // @ts-ignore
    const ranges: Date[] = bookings.map(({ startDate, endDate }) => {
      let parsedStart = parseISO(startDate);
      
      if (twoNightMinimum && twoNightMinimum.length) {
        const beforeStart = subDays(parsedStart, 1);
        if (twoNightMinimum[beforeStart.getDay()]) {
          parsedStart = beforeStart;
        }
      }

      const parsedEnd = subDays(parseISO(endDate), 1);
      if (parsedStart > parsedEnd) {
        return [parsedStart];
      }
      return eachDayOfInterval({
        start: parsedStart,
        end: parsedEnd,
      });
    });

    // step 1 of removing utc time
    // @ts-ignore
    const daysArr: string[] = uniq(
      flatten(ranges).map((r) => format(r, 'yyyy-MM-dd'))
                     .concat(changeOverDays.map(d => d.split(' ')[0]))
    );

    if (names.length) {
      daysArr.map(d => {
        if (!namesByDates[d]) {
          namesByDates[d] = names.filter(n => n != name)
        } else {
          namesByDates[d] = namesByDates[d].filter(n => n != name)
        }
      })
    }
    
    return daysArr;
  });

  const flattenedBookings = flatten(individualBookings);

  // @ts-ignore
  const count = countBy((v: string) => v)(flattenedBookings);

  return (
    Object.keys(count)
      .filter((dateKey) => count[dateKey] >= bookedDates.length)
      // step 2 of removing utc time
      .map((dateKey) => {
        const time = moment(dateKey).isDST() ? '05:00' : '04:00';
        return zonedTimeToUtc(`${dateKey} ${time}`, timeZone);
      })
  );
}

export function transformBookedDates(
  bookedDates: { startDate: string; endDate: string; status: string }[],
  timezone: string,
  changeOverDays: string[] = [],
) {
  const filteredBookedDates = bookedDates.filter(
    (item) => parseISO(item.endDate) > new Date() && (item.status === 'confirmed' || item.status === 'pending')
  );
  const uniqueBookedDates = uniqBy(
    (v) => v.startDate + v.endDate,
    filteredBookedDates
  );

  return uniqueBookedDates.map((v) => ({
    before: utcToZonedTime(parseISO(v.endDate), timezone),
    after: utcToZonedTime(subDays(parseISO(v.startDate), 1), timezone)
  })).concat(changeOverDays.map(cod => ({
    before: utcToZonedTime(addDays(parseISO(cod), 1), timezone),
    after: utcToZonedTime(subDays(parseISO(cod), 1), timezone),
  })))
}

export function isValidRange(
  startDate: Date,
  endDate: Date,
  bookedDates: { after: Date; before: Date }[]
) {
  const overLappedIndex = bookedDates.findIndex((bookedDate) => {
    const startBookedDate = addDays(startOfDay(bookedDate.after), 1);
    const endBookedDate = startOfDay(bookedDate.before);
    return areIntervalsOverlapping(
      {
        start: startOfDay(startDate),
        end: startOfDay(endDate),
      },
      {
        start: startBookedDate,
        end: endBookedDate,
      }
    );
  });
  return overLappedIndex === -1;
}

export function getDays(twoNightMinimum: null | boolean[]) {
  if (twoNightMinimum) {
    const twoNights = [];
    for (let i = 0; i < twoNightMinimum.length; i++) {
      const isTwoNight = twoNightMinimum[i];
      if (isTwoNight) twoNights.push(DAY[i]);
    }

    const lastDay = twoNights.pop();
    return `${twoNights.join(', ')} and ${lastDay}`;
  }

  return 'weekend';
}

export function isMinimumStay(
  startDate: Date,
  endDate: Date,
  twoNightMinimum: null | boolean[]
) {
  if (!twoNightMinimum && (isSaturday(startDate) || isFriday(startDate))) {
    // two nights
    return differenceInHours(endDate, startDate) >= 44;
  } else if (twoNightMinimum) {
    let valid = true;
    for (let i = 0; i < twoNightMinimum.length; i++) {
      const isTwoNight = twoNightMinimum[i];

      if (isTwoNight && IS_DAY[i](startDate)) {
        valid = differenceInHours(endDate, startDate) >= 44;
        if (!valid) return valid;
      }
    }

    return valid;
  }
  return true;
}


export function is3NightsMinimumStay(
  startDate: Date,
  endDate: Date,
  is3NightsMinimum: null | boolean
) {
  if (!isValid(startDate) || !isValid(endDate)) {
    return true
  }

  if (is3NightsMinimum) {
    // three nights
    return differenceInHours(endDate, startDate) >= 72;
  } 

  return true;
}

export function isMinimumStayOnEvents(start: Date, end: Date) {
  const days = eachDayOfInterval({ start, end });

  for (let rule of minimumNights) {
    if (rule.isIncluded(days)) {

      const maxHours = (rule.nights * 24) - 4;
      if (!(differenceInHours(end, start) >= maxHours)) {
        return rule;
      }
    }
  }

  return false;
}

export function isMinimumStayOnNewYearsEve(startDate: Date, endDate: Date) {
  if (isSameDay(lastDayOfYear(startDate), startDate)) {
    // two nights
    return differenceInHours(endDate, startDate) >= 44;
  }
  return true;
}

export function isMinimumStayOnWeekNight(
  startDate: Date,
  endDate: Date,
  acceptSingleNight: string
) {
  if (
    acceptSingleNight !== 'true' &&
    isValid(startDate) &&
    !isFriday(startDate) &&
    !isSaturday(startDate)
  ) {
    // two nights
    return differenceInHours(endDate, startDate) >= 44;
  }
  return true;
}

export const exception = (exceptions: { freq: string; byday: string }) => (
  day: Date
) => {
  // only support Sunday exceptions for now
  if (exceptions.freq === 'WEEKLY' && exceptions.byday === 'SU') {
    return day.getDay() === 0;
  }
  return false;
};

export function rangeHasException(
  start: Date,
  end: Date,
  exceptions?: { freq: string; byday: string }
) {
  if (!exceptions || !exceptions.byday) return false;

  const range = eachDayOfInterval({
    start: start > end ? end : start,
    end: start > end ? start : end,
  });
  return range.some((d: Date) => {
    //do not include end(checkout) date
    if (d.toLocaleDateString() === end.toLocaleDateString()) return;
    return exception(exceptions)(d);
  });
}
