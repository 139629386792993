import React, { FC, useEffect, useRef, useState } from 'react';

import { UnyokedCountry } from 'consts/locations';
import pathOr from 'ramda/es/pathOr';
import { useDispatch } from 'react-redux';
import { vouchersActions } from 'store/actions';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { RedeemVoucher } from 'types/Vouchers';
import { getTotalStay } from 'utils/Data/pricing/pricing';

import voucherThunks from '../../../thunks/vouchers';
import { HouseCardPaymentsPublicProps } from './HouseCardPayments.props';
import View from './HouseCardPayments.view';

const HouseCardPaymentsContainer: FC<HouseCardPaymentsPublicProps> = (
  props
) => {
  const { checkin, checkout, totalPrice, houseCardProps } = props;
  const [voucherCode, setVoucherCode] = useState('');

  const dispatch = useDispatch();
  const isMounted = useRef(true);

  const user = useSelectorSafe((state) => state.user);
  const userId = pathOr(undefined, ['data', 'user', 'id'], user);
  const { country } = houseCardProps;
  const cabin = houseCardProps.name;

  const nights = getTotalStay(checkin, checkout);

  const redeemedVoucher = useSelectorSafe((state) => state.redeemVoucher);
  const paymentAfterpay = useSelectorSafe((state) => state.paymentAfterpay);

  const afterpay = paymentAfterpay?.data;

  const voucher: RedeemVoucher | undefined = pathOr(
    undefined,
    ['data', 'voucher'],
    redeemedVoucher
  );

  const voucherStatus: string = pathOr(
    ASYNC_STATUS.INITIAL,
    ['status'],
    redeemedVoucher
  );

  let voucherError = '';
  if (voucherStatus === ASYNC_STATUS.ERROR) {
    voucherError = "Hmm sorry, this one's not valid";
    window.analytics.track('Coupon Redemption Failed', { code: voucherCode });
  } else if (
    voucher &&
    (voucher as RedeemVoucher).type != 'gift_card' &&
    afterpay
  ) {
    voucherError = 'Hmm sorry, Discount codes are not available with Afterpay.';
    window.analytics.track('Coupon Afterpay Redemption Failed', {
      code: voucherCode,
    });
  }

  const [error, setError] = useState(voucherError);

  const redeemVoucher = (code: string) => {
    setVoucherCode(code);
    dispatch(
      voucherThunks.redeem({
        code,
        cabin,
        checkin,
        checkout,
        nights,
        price: totalPrice,
        userId,
        country,
      })
    );
  };

  useEffect(() => {
    if (afterpay && voucher && (voucher as RedeemVoucher).type != 'gift_card') {
      dispatch(vouchersActions.clear());
      setError('Hmm sorry, Discount codes are not available with Afterpay.');
    } else if (!afterpay) {
      setError(voucherError);
    }
  }, [afterpay, voucher, voucherError]);

  useEffect(() => {
    if (isMounted.current && !voucher) dispatch(vouchersActions.clear());
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <View
      {...props}
      clearVoucher={() => {
        dispatch(vouchersActions.clear());
      }}
      voucher={voucher}
      redeemVoucher={redeemVoucher}
      voucherLoading={voucherStatus === ASYNC_STATUS.LOADING}
      voucherSuccess={voucherStatus === ASYNC_STATUS.COMPLETE}
      voucherError={error}
    />
  );
};

export default React.memo(HouseCardPaymentsContainer);
