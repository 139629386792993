import React, { FC } from 'react';

import { PaginationPublicProps } from './Pagination.props';
import View from './Pagination.view';

const PaginationContainer: FC<PaginationPublicProps> = (
  props: PaginationPublicProps
) => <View {...props} />;

export default React.memo(PaginationContainer);
