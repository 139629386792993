import React, { FC, useState } from 'react';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

import { policy } from '../../../consts';
import { HorizontalLine, Typo } from '../../primitives';
import { PrivacyPolicyProps } from './PrivacyPolicy.props';
import { useStyles } from './PrivacyPolicy.styles';

const PrivacyPolicyView: FC<PrivacyPolicyProps> = () => {
  const [isIn, setIsIn] = useState([0]);
  // @ts-ignore
  const classes = useStyles();

  const addOrRemove = (item: number) => {
    const exists = isIn.includes(item);

    if (exists)
      setIsIn(
        isIn.filter((i) => {
          return i !== item;
        })
      );
    else setIsIn([...isIn, item]);
  };

  return (
    <Box>
      {policy.map(({ header, items }, policyIndex) => {
        const exists = isIn.includes(policyIndex);
        return (
          <Box key={policyIndex}>
            <HorizontalLine className={classes.line} />
            <Box
              className={classes.headerContainer}
              onClick={() => addOrRemove(policyIndex)}
            >
              <Box mr={0.5}>
                {exists ? (
                  <ExpandLess color="secondary" />
                ) : (
                  <ExpandMore color="secondary" />
                )}
              </Box>
              <Typo variant="h4">{header}</Typo>
            </Box>
            <ul>
              <Collapse key={policyIndex} in={exists}>
                {items.map((item, itemsIndex) => (
                  <li key={itemsIndex} className={classes.li}>
                    <Typo
                      variant="body2"
                      dangerouslySetInnerHTML={{ __html: item }}
                    />
                  </li>
                ))}
              </Collapse>
            </ul>
          </Box>
        );
      })}
    </Box>
  );
};

export default React.memo(PrivacyPolicyView);
