import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'FEATURE_FLAG';
const creatFeatureFlagActions = makeCreateActions(ns);

const featureFlagActions = creatFeatureFlagActions({
  getFeatureFlags: presetActions.makeAsyncAction(`${ns}/GET_FEATURE_FLAGS`),
});

export default featureFlagActions;
