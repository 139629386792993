import React, { FC, useLayoutEffect } from 'react';

import { FEATURE_FLAGS } from 'consts/featureFlags';
import pick from 'ramda/es/pick';
import { getContentfulEntry } from 'services/fetch/apiEndpoints';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { FeatureFlagsState } from 'types/store/FeatureFlagState';
import { useQueryWrapper } from 'utils/Hooks/useQueryWrapper';
import { asyncData } from 'utils/Redux';

import ROUTES from '../../../routes';
import useCurrentRoute from '../../../store/selectors/useCurrentRoute';
import { MainPublicProps, MainProps } from './Main.props';
import MainView from './Main.view';

const MainContainer: FC<MainPublicProps> = (publicProps: MainPublicProps) => {
  const visitCount = Number(localStorage.getItem('visitCount'));

  const { data: featureFlags } = pick(
    ['data', 'status', 'errors'],
    useSelectorSafe<FeatureFlagsState>(
      (state) => state.featureFlags,
      asyncData(ASYNC_STATUS.ERROR, [
        { message: 'Could not get feature flags' },
      ])
    )
  );

  const location = useCurrentRoute();
  const isNotStickyAppBar = location === ROUTES.ROOT && visitCount < 2;

  const combinedProps: MainProps = {
    ...publicProps,
    isNotStickyAppBar,
    // @ts-ignore
    showRefer: featureFlags?.[FEATURE_FLAGS.REFER_CTA],
    featureFlags,
  };

  return <MainView {...combinedProps} />;
};

export default MainContainer;
