import { Overrides } from '@material-ui/core/styles/overrides';

import MuiBackdrop from './MuiBackdrop';
import MuiContainer from './MuiContainer';

// Look at the material UI docs for examples of how to override their components.
const overrides: Overrides = {
  MuiContainer,
  MuiBackdrop,
};

export default overrides;
