import React, { FC } from 'react';

import Box from '@material-ui/core/Box';

import { getMinorValue } from '../../../utils/Data/pricing/pricing';
import { HorizontalLine, Typo } from '../../primitives';
import VoucherCard from '../VoucherCard';
import { VoucherCardPaymentsProps } from './VoucherCardPayments.props';
import { useStyles } from './VoucherCardPayments.styles';

const VoucherCardPaymentsPaymentsView: FC<VoucherCardPaymentsProps> = (
  props
) => {
  const { priceDetails, voucherCardProps } = props;
  const { currency } = voucherCardProps;
  const classes = useStyles();
  return (
    <Box>
      <VoucherCard {...voucherCardProps} />
      <Box mt={3}>
        <Box mb={2} mt={1}>
          <HorizontalLine className={classes.line} />
        </Box>
        {priceDetails.map(({ text, value, quantity }, index) => (
          <Box
            key={index}
            className={classes.priceDetail}
            mt={index === priceDetails.length - 2 ? 3 : 0}
          >
            <Typo
              variant="body3"
              dangerouslySetInnerHTML={{
                __html: quantity ? `${text} x ${quantity}` : text,
              }}
            />
            <Typo variant="body3" className={classes.value}>
              {quantity
                ? getMinorValue(value, currency).multiply(quantity).toUnit()
                : value}
            </Typo>
          </Box>
        ))}
      </Box>
      <Box mt={4} mb={4}>
        <HorizontalLine className={classes.line} />
      </Box>
    </Box>
  );
};

export default React.memo(VoucherCardPaymentsPaymentsView);
