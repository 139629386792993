import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import { SocialIconsProps } from './SocialIcons.props';
import { Link } from '../../primitives';
import { useStyles } from './SocialIcons.styles';

const SocialIconsView: FC<SocialIconsProps> = (props: SocialIconsProps) => {
  const { socialIcons } = props;
  const classes = useStyles(props);
  
  return (
    <Box className={classes.container}>
      {socialIcons.map((SocialIcon, idx) => (
        <Link href={SocialIcon.url} target="_blank" rel="noopener noreferrer" key={`socialicon-${idx}`}>
          <SocialIcon.icon
            style={{
              width: 30,
              height: 30,
            }}
            className={SocialIcon.className}
          />
        </Link>
      ))}
    </Box>
  );
};

export default SocialIconsView;
