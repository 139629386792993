import React, { FC, useEffect, useRef } from 'react';

import pathOr from 'ramda/es/pathOr';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import userThunks from 'thunks/user';

import ROUTES from '../../../routes';
import { routerActions } from '../../../store/actions';
import { ASYNC_STATUS } from '../../../types/store/AsyncStatus';
import { PaymentMethodsPublicProps } from './PaymentMethods.props';
import View from './PaymentMethods.view';
import {Elements} from '@stripe/react-stripe-js';
import { getStripe } from 'consts/stripe';


const PaymentMethodsContainer: FC<PaymentMethodsPublicProps> = (props) => {
  const dispatch = useDispatch();
  const isMounted = useRef(true);

  const token = useSelectorSafe((state) => state.token) || '';
  const selectedCountry = useSelectorSafe((state) => state.country);
  const country = selectedCountry ? selectedCountry.countryCode : 'AU'

  const status = useSelectorSafe((state) => state.paymentMethod.status);
  const paymentMethod = useSelectorSafe((state) => state.paymentMethod) || {
    status: ASYNC_STATUS.INITIAL,
    data: {
      paymentMethod: [],
    },
  };
  const errorMessage =
    status === ASYNC_STATUS.ERROR
      ? 'Something went wrong. Please try again.'
      : '';

  const toCardDisplay = (method: any) => {
    return {
      id: method.id,
      customer: method.customer,
      last4Number: method.card.last4,
      expiryMonth: method.card.exp_month,
      expiryYear: method.card.exp_year,
      brand: method.card.brand,
      isDefault: method.isDefault,
      name: method.billing_details.name,
      email: method.billing_details.email,
    };
  };

  const cards = pathOr([], ['data', 'cards'], paymentMethod).map(toCardDisplay);

  useEffect(() => {
    if (isMounted.current) dispatch(userThunks.getPaymentMethod(country));
    return () => {
      isMounted.current = false;
    };
  }, []);

  const options = {
    clientSecret: props.intent.secret
  }

  return (
    <Elements stripe={getStripe(country)} options={options} >
      <View
        {...props}
        country={country}
        goToAccount={() => dispatch(routerActions.link(ROUTES.ACCOUNT))}
        onDelete={(sourceId: string) =>
          dispatch(userThunks.deletePaymentMethod({ sourceId, country  }))
        }
        onUpdate={(sourceId: string) =>
          dispatch(
            userThunks.updatePaymentMethod({ country, sourceId, isDefault: true })
          )
        }
        refresh={() => dispatch(userThunks.getPaymentMethod(country))}
        loading={status === ASYNC_STATUS.LOADING}
        success={false}
        errorMessage={errorMessage}
        openErrorSnack={!!errorMessage}
        token={token}
        cards={cards}
      />
    </Elements>
  );
};

export default React.memo(PaymentMethodsContainer);
