import React, { FunctionComponent, useState, useEffect } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';

import { DarkModalProps } from './DarkModal.props';
import { useStyles } from './DarkModal.styles';

const ModalView: FunctionComponent<DarkModalProps> = (
  props: DarkModalProps
) => {
  const [open, setOpen] = useState(props.open);
  const classes = useStyles(props);
  const theme = useTheme();
  // @ts-ignore
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const closeModal = () => {
    if (typeof props.onClose === 'function') {
      props.onClose(false);
    }
    setOpen(false);
  };

  useEffect(() => {
    setOpen(props.open);
  }, [setOpen, props.open]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      open={open}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.titleContainer}>
        <CloseIcon className={classes.closeIcon} onClick={closeModal} />
      </div>
      <DialogContent className={classes.contentContainer}>
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(ModalView);
