import React, { FC, SyntheticEvent, useEffect } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';

import Typo from '../Typo';
import { SnackbarProps } from './Snackbar.props';
import { useStyles } from './Snackbar.styles';

const SnackbarView: FC<SnackbarProps> = (props) => {
  const {
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'right',
    },
    message,
    type = 'normal',
    autoHideDuration = 6000,
    onClose,
  } = props;
  const [open, setOpen] = React.useState(props.open);

  const classes = useStyles();

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    onClose && onClose();
  };

  return (
    <Snackbar
      // @ts-ignore
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classes[type]}
        aria-describedby="client-snackbar"
        message={
          <Typo variant="h6" className={classes.message}>
            {message}
          </Typo>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default React.memo(SnackbarView);
