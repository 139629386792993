import React, { FC, Fragment, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { ProvisionAccordion } from 'components/modules';
import {
  Button,
  Checkbox,
  CheckboxXmark,
  HorizontalLine,
  Select,
  TextArea,
  Typo,
} from 'components/primitives';
import { FILTER } from 'consts/filter';

import { nav } from '../HousePayments.view';

const TYPE_DESCRIPTIONS: { [key: string]: string } = {
  doggo: `Bring your pet`,
};
//  @ts-ignore
const HouseReview: FC<T> = ({
  isMobile,
  isXs,
  classes,
  checkin,
  checkout,
  houseRules,
  nights,
  setCurrentNav,
  trackCheckoutStep,
  currentGuests,
  setCurrentGuests,
  capacity,
  specialAddons,
  country,
  addons,
  setCheckedProvisions,
  checkedProvisions,
  notes,
  setNotes,
  addProvisions,
  cabinTime,
}) => {
  const onContinue = () => {
    window.scrollTo(0, 0);
    setCurrentNav(1);
    trackCheckoutStep(nav[1]);
  };
  const [internalNotes, setInternalNotes] = useState(notes);

  const onUpdateProvisions = (name: string, price: string, amount: string) => {
    const quantity = parseInt(amount, 10);
    if (quantity > 0) {
      setCheckedProvisions([
        ...checkedProvisions.filter(
          (provision: { text: string }) => provision.text !== name
        ),
        { text: name, value: Number(price), quantity },
      ]);
    } else if (quantity === 0 || !quantity) {
      setCheckedProvisions(
        checkedProvisions.filter(
          (provision: { text: string }) => provision.text !== name
        )
      );
    }
  };

  const guestsOptions = Array.from(
    Array(
      capacity > FILTER.GUESTS_LIMIT ? FILTER.GUESTS_LIMIT : capacity
    ).keys()
  ).map((v) => {
    const stringV = (v + 1).toString();
    return {
      label: stringV,
      value: stringV,
    };
  });

  const ReviewDetailsHeader = (
    <div>
      <Typo variant={isXs ? 'h3' : 'h1'} className={classes.header}>
        Review your details
      </Typo>
      <Box mt={2} mb={1}>
        <HorizontalLine className={classes.fatLine} />
      </Box>
      <Box mb={isMobile ? 3 : 6} />
      <Typo variant="h6">{checkin}</Typo>
      <Typo variant="body3">Check-in: {cabinTime['start']}</Typo>
      <Box mb={2} />
      <Typo variant="h6">{checkout}</Typo>
      <Typo variant="body3">Check-out: {cabinTime['end']}</Typo>
      <Box mt={isMobile ? 3 : 6} mb={1}>
        <HorizontalLine className={classes.line} />
      </Box>
    </div>
  );

  return (
    <Box>
      {isMobile ? null : ReviewDetailsHeader}
      <Box mt={3} width="150px">
        <Select
          id="guests"
          // @ts-ignore
          options={guestsOptions}
          label="Guests"
          shrink={true}
          value={currentGuests}
          onChange={(e) => setCurrentGuests(e.target.value)}
        />
      </Box>
      <Box mt={isMobile ? 3 : 6} mb={1}></Box>

      {specialAddons &&
        specialAddons.map(
          ({
            name,
            description,
            price,
            countryPrices,
            provider,
            yield: yieldRange,
            quantity,
            type,
            currency,
            maxPurchasable,
          }: {
            name: string;
            description: string;
            price: string;
            countryPrices: [];
            provider: string;
            yield: string;
            quantity: string;
            type: string;
            currency: string;
            maxPurchasable: number;
          }) => (
            <Fragment key={type}>
              <Box mt={isMobile ? 4 : 8} />
              <Typo
                variant={isXs ? 'h3' : 'h1'}
                style={{ textTransform: 'capitalize' }}
              >
                {TYPE_DESCRIPTIONS[type] || type}
              </Typo>
              <Box mt={2}>
                <HorizontalLine className={classes.fatLine} />
              </Box>
              <Box mt={2}>
                <ProvisionAccordion
                  name={name}
                  description={description}
                  onUpdate={onUpdateProvisions}
                  price={price}
                  countryPrices={countryPrices}
                  currency={currency}
                  provider={provider}
                  yieldRange={yieldRange}
                  quantity={quantity}
                  isQuantityOne={true}
                  maxPurchasable={maxPurchasable}
                  country={country}
                />
              </Box>
            </Fragment>
          )
        )}

      <Box mt={6}></Box>
      <Typo variant="h5" style={{ opacity: addProvisions ? 0.5 : 1 }}>
        Any notes for us?
      </Typo>
      <Box mt={3} mb={isMobile ? 4 : 8}>
        <TextArea
          id="notes"
          placeholder="Tell us a little about yourself..."
          onChange={(e) => setInternalNotes(e.target.value)}
          onBlur={(e) => setNotes(e.target.value)}
          value={internalNotes}
          rows={6}
          rowsMax={6}
          disabled={addProvisions}
        />
      </Box>

      {!isXs && (
        <Button
          text="Continue"
          variant={isMobile ? 'h6' : 'h5'}
          onClick={onContinue}
        />
      )}
    </Box>
  );
};

export default HouseReview;
