import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'themeConfig/themeConstants';

import { BlogBackgroundImage } from '../../../assets/images';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
    title: {
      color: '#01290D',
      [theme.breakpoints.up(1440)]: {
        fontSize: 32,
      },
    },
    desc: {
      marginTop: '1.5em',

      '& a': {
        color: colors.primaryDarkGreen,
      },
    },
    seeMore: {
      color: '#01290D',
    },
    description: {
      color: '#01290D',
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    },
    moreButton: {
      width: 150,
    },
    topContainer: {
      display: 'flex',
      paddingTop: '5%',
    },
    bottomContainer: {
      paddingTop: '1%',
      paddingBottom: '5%',
    },
  })
);
