import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import CheckIcon from '@material-ui/icons/Check';

import Typo from '../Typo';
import { ButtonProps } from './Button.props';
import { useStyles } from './Button.styles';

const ButtonView: FC<ButtonProps> = (props: ButtonProps) => {
  const classes = useStyles(props);
  const {
    text,
    iconText,
    onClick,
    hideUnderline,
    hideIcon,
    textStyle,
    iconStyle,
    iconTextStyle,
    variant = 'h5',
    iconTextVariant = 'h5',
    color,
    success = false,
    loading = false,
    disabled = false,
    style,
  } = props;

  const Icon = success ? CheckIcon : ArrowForwardIcon;

  return (
    <Button
      style={style}
      disabled={success || loading || disabled}
      className={classes.button}
      onClick={onClick}
    >
      <Box className={classes.container} borderBottom={hideUnderline ? -1 : 2}>
        <Typo
          className={classes.text}
          style={textStyle}
          variant={variant}
          color={color}
        >
          {text}
        </Typo>
        {!hideIcon && (
          <Box className={classes.iconContainer}>
            {iconText && (
              <Typo style={iconTextStyle} variant={iconTextVariant} color={color}>
                {iconText}
              </Typo>
            )}
            {loading ? (
              <CircularProgress size={21} color="secondary" />
            ) : (
              <Icon
                fontSize="small"
                style={iconStyle}
                className={classes.icon}
              />
            )}
          </Box> 
        )}
      </Box>
    </Button>
  );
};

export default React.memo(ButtonView);
