import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'black',
      paddingLeft: 0,
      paddingRight: 0,
    },
    closeIcon: {
      width: '34px',
      height: '34px',
      position: 'absolute',
      right: '13px',
      top: '13px',
      cursor: 'pointer',
      color: 'white',
    },
    titleContainer: {
      padding: '10px 15px',
      minHeight: '50px',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: 'black',
    },
    paper: {
      boxShadow: 'none',
    },
  })
);
