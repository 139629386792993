import { UnyokedCountry } from 'consts/locations';
import { Dispatch } from 'redux';
import { redeemVoucherEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { vouchersActions } from 'store/actions';

import { Store } from '../../types/store/Store';

interface Data {
  code: string;
  cabin: string;
  checkin: string;
  checkout: string;
  nights: number
  price: number,
  userId?: string,
  country?: UnyokedCountry,
}

const redeem = (data: Data) => (dispatch: Dispatch, getState: () => Store) => {
  dispatch(
    vouchersActions.redeem(
      apiFetch<Data, any>(redeemVoucherEndpoint, data)(dispatch, getState)
    )
  );
};

export default redeem;
