import { makeCreateActions, presetActions } from 'utils/Redux';

import { Contentful } from '../../types/Contentful';

const ns = 'VOUCHERS';
const createVouchersActions = makeCreateActions(ns);

const vouchersActions = createVouchersActions({
  getAll: presetActions.makeAsyncAction<Contentful[]>(`${ns}/GET_ALL`),
  redeem: presetActions.makeAsyncAction(`${ns}/REDEEM`),
  clear: presetActions.noPayload,
});

export default vouchersActions;
