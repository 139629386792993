import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      '& > .MuiOutlinedInput-input': {
        textAlign: 'center',
        padding: '4.5px 0',
      },
      paddingLeft: '3px',
      paddingRight: '3px',
    },
  })
);
