import { Dispatch } from 'redux';
import { getCitiesEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { countryActions } from 'store/actions';
import { City } from 'types/City';
import { Country } from 'types/Country';

import { Store } from '../../types/store/Store';

interface Data {}

const getCities = () => (dispatch: Dispatch, getState: () => Store) => {
  dispatch(
    countryActions.getCities(
      (async () => {
        const result = await apiFetch<Data, any>(getCitiesEndpoint, {})(
          dispatch,
          getState
        );

        const countries: { [key: string]: Country } = {};
        const cities: { [key: string]: City[] } = {};
        const countryNames: { [key: string]: string } = {};

        for (const city of result.cities) {
          if (!countries[city.country.code]) {
            countries[city.country.code] = city.country;
            countryNames[city.country.name] = city.country.code;
          }

          if (!cities[city.country.code]) {
            cities[city.country.code] = [];
          }

          cities[city.country.code].push(city);
        }

        const sortedCountries: { [key: string]: Country } = {};
        const sortedCities: { [key: string]: City[] } = {};
        Object.keys(countryNames)
          .sort()
          .map((c) => {
            const code = countryNames[c];
            sortedCities[code] = cities[code];
            sortedCountries[code] = countries[code];
          });

        return {
          countries: sortedCountries,
          cities: sortedCities,
          message: 'Success',
        };
      })()
    )
  );
};

export default getCities;
