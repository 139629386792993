import React, { FC } from 'react';
import {
  SocialIconsPublicProps,
  SocialIconsProps,
  SocialIcon,
} from './SocialIcons.props';
import SocialIconsView from './SocialIcons.view';
import {
  INSTAGRAM_URL,
  LINKEDIN_URL,
  APPLEMUSIC_URL,
  SPOTIFY_URL,
} from '../../../consts/urls';

import {
  LinkedInIcon,
  InstagramIcon,
  AppleMusicIcon,
  SpotifyIcon,
} from 'assets/svgs';
import { colors } from 'themeConfig/themeConstants';

const SocialIconsContainer: FC<SocialIconsPublicProps> = (
  publicProps: SocialIconsPublicProps
) => {
  const socialIcons: SocialIcon[] = [
    {
      icon: LinkedInIcon,
      url: LINKEDIN_URL,
    },
    {
      icon: InstagramIcon,
      url: INSTAGRAM_URL,
      className: 'inverted',
    },
    {
      icon: AppleMusicIcon,
      url: APPLEMUSIC_URL,
    },
    {
      icon: SpotifyIcon,
      url: SPOTIFY_URL,
    },
  ];

  const combinedProps: SocialIconsProps = {
    ...publicProps,
    socialIcons,
    iconColor: publicProps.iconFillColor || colors.primaryYellow,
  };

  return <SocialIconsView {...combinedProps} />;
};

export default SocialIconsContainer;
