import React, { FC } from 'react';

import { HowItWorksPublicProps } from './HowItWorks.props';
import View from './HowItWorks.view';

const HowItWorksContainer: FC<HowItWorksPublicProps> = (props) => {
  return <View {...props} />;
};

export default React.memo(HowItWorksContainer);
