import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';
import { pxToRem } from '../../../themeConfig/typography';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
    },
    imageContainer: {
      display: 'flex',
      position: 'absolute',
      flexDirection: 'column',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -100,
    },
    image: {
      height: '100%',
      objectFit: 'cover',
      objectPosition: '50% 100%',
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      flexDirection: 'column',
      height: '85vh',
      textAlign: 'center',
      paddingLeft: '5%',
      paddingBottom: '5%',
    },

    imageCaption: {
      marginBottom: 10,
    },
    line: {
      backgroundColor: colors.primaryWhite,
      height: 2.5,
      width: '40%',
      marginBottom: 15,
    },
    subtitleArrowContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 26,
      },
    },
    arrow: {
      color: colors.primaryWhite,
      padding: '0 7px 0 10px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    subTitle: {
      fontSize: pxToRem(16),
      marginTop: 10,
      marginBottom: 10,
    },
  })
);
