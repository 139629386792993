import React, { FC } from 'react';

import { FilterInputPublicProps, FilterInputProps } from './FilterInput.props';
import FilterInputView from './FilterInput.view';

const FilterInputContainer: FC<FilterInputPublicProps> = (
  ownProps: FilterInputPublicProps
) => {
  // {{containerBodyPrefabs}}
  const combinedProps: FilterInputProps = {
    ...ownProps,
    // your calculated props
  };

  return <FilterInputView {...combinedProps} />;
};

export default FilterInputContainer;
