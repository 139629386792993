import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'themeConfig/themeConstants';

interface StyleProps {
  isInverted: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: '100%',
      height: 'auto',
      marginBottom: theme.spacing(3),
    },
    rightContainer: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: '10%',
      },
    },
    joinDesktop: ({ isInverted }: StyleProps) => ({
      color: isInverted ? colors.primaryDarkGreen : '#F4F3D8',
      [theme.breakpoints.up(1440)]: {
        fontSize: 32,
      },
    }),
    description: ({ isInverted }: StyleProps) => ({
      color: isInverted ? colors.primaryDarkGreen : '#F4F3D8',
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    }),
  })
);
