import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'themeConfig/themeConstants';

interface StyleProps {
  isMobile: boolean;
  customHeight?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: ({ customHeight, isMobile }: StyleProps) => ({
      minHeight: '780px',
      width: '100%',
      height: customHeight || '100%',
      marginBottom: isMobile ? '-12px' : `-1px`,
    }),
    imageContainer: ({ isMobile }) => ({
      minHeight: '780px',
      height: '100%',
      width: '100vw',
      display: 'flex',
      position: 'absolute',
      flexDirection: 'column',
      top: 0,
      left: 0,
      right: 0,
      zIndex: -100,
      overflow: 'hidden',

      '& img': {
        objectPosition: isMobile ? '30% 0' : `unset`,
      },
    }),
    video: ({ isMobile }: StyleProps) => ({
      width: 'auto',
      height: '100vh',
      [theme.breakpoints.up(1440)]: {
        width: '110%',
        height: 'auto',
      },
    }),
    image: ({ customHeight }: StyleProps) => ({
      // height: customHeight || '100%',
      minHeight: '800px',
      height: customHeight || '100vh',
      width: '100vw',
      objectFit: 'cover',
      [theme.breakpoints.up(1440)]: {
        height: '100vh',
      },
    }),
    imageBlurred: ({ customHeight }: StyleProps) => ({
      height: customHeight || '100%',
      objectFit: 'cover',
      objectPosition: '50% 100%',
      filter: `blur(10px)`,
    }),
    titleContainer: ({ customHeight }: StyleProps) => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: customHeight || '100vh',
      textAlign: 'center',
    }),
    title: ({ isMobile }: StyleProps) => ({
      ...(isMobile
        ? {
            width: '75%',
            fontSize: '48px',
          }
        : {
            fontSize: 64,
          }),
    }),
    subtitle: {
      width: '75%',
      marginTop: '30px',
    },
    button: {
      marginTop: '50px',
      padding: '15px 60px',
      borderRadius: '4px',
      fontWeight: 700,

      '&.green': {
        color: colors.primaryYellow,
        backgroundColor: colors.primaryDarkGreen,
      },
      '&.yellow': {
        color: colors.primaryDarkGreen,
        backgroundColor: colors.primaryYellow,
      },
      '&.white': {
        color: `${colors.primaryDarkGreen} !important`,
        backgroundColor: `${colors.primaryWhite} !important`,
      },
    },

    filterContainer: {
      // height: 100,
    },
  })
);
