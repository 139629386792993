import React, { FC } from 'react';

import { UniquePagePublicProps } from './UniquePage.props';
import UniquePageView from './UniquePage.view';

const UniquePageContainer: FC<UniquePagePublicProps> = (
  publicProps: UniquePagePublicProps
) => {
  return <UniquePageView {...publicProps} />;
};

export default React.memo(UniquePageContainer);
