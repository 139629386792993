import React, { FC, useEffect, useRef } from 'react';

import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { pathOr } from 'ramda';
import { useDispatch } from 'react-redux';
import ROUTES from 'routes';
import { routerActions } from 'store/actions';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import userThunks from 'thunks/user';
import { Booking } from 'types/Booking';

import { ASYNC_STATUS } from '../../../types/store/AsyncStatus';
import { ProvisionsLayoutPublicProps } from './ProvisionsLayout.props';
import View from './ProvisionsLayout.view';

const ProvisionsLayoutContainer: FC<ProvisionsLayoutPublicProps> = (props) => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelectorSafe((state) => state.token);
  const booking = useSelectorSafe((state) => state.bookings) || {
    status: ASYNC_STATUS.INITIAL,
    data: {
      bookings: [] as Booking[],
    },
  };

  let bookings: Booking[] = [];
  if (booking.data && booking.data.bookings && booking.data.bookings.length) {
    bookings = booking.data.bookings.filter((b: any) => {
      return parseISO(b.startDate) >= new Date() && b.status === 'confirmed';
    });
  }

  useEffect(() => {
    if (isLoggedIn && !booking.data) {
      dispatch(userThunks.getBooking());
    }
  }, [isLoggedIn]);

  return (
    <View
      {...props}
      loading={booking.status === ASYNC_STATUS.LOADING}
      isLoggedIn={isLoggedIn ? true : false}
      bookings={bookings}
      onBag={(provisions: object[], booking: Booking) => {
        if (isLoggedIn) {
          if (booking) {
            const checkIn = parseISO(pathOr('', ['startDate'], booking));
            const checkOut = parseISO(pathOr('', ['endDate'], booking));
            dispatch(
              routerActions.link(ROUTES.HOUSE_PAYMENTS, {
                id: pathOr('', ['entity', 'id'], booking),
                name: pathOr('', ['entity', 'title'], booking),
                data: {
                  startDate: format(checkIn, 'MMM dd, yyyy'),
                  endDate: format(checkOut, 'MMM dd, yyyy'),
                  guests: pathOr('', ['metadata', 'guests'], booking),
                  bookingId: pathOr('', ['id'], booking),
                  bookingMetadata: pathOr({}, ['metadata'], booking),
                  redirectStep: 1,
                  redirectProvisions: provisions,
                  addProvisions: true,
                },
              })
            );
          } else {
            dispatch(routerActions.link(ROUTES.ACCOUNT_BOOKING_HISTORY));
          }
        } else {
          dispatch(
            routerActions.link(ROUTES.LOGIN_SIGNUP, {
              redirectTo: ROUTES.PROVISIONS,
            })
          );
        }
      }}
    />
  );
};

export default React.memo(ProvisionsLayoutContainer);
