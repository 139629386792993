import React, { FC, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { animated, useSprings } from 'react-spring';

import { LeftArrow, RightArrow } from '../../../assets/svgs';
import { useWindowSize } from '../../../utils/Hooks';
import { Typo, Carousel } from '../../primitives';
import { ImageAndHeaderCarouselProps } from './ImageAndHeaderCarousel.props';
import { useStyles } from './ImageAndHeaderCarousel.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <RightArrow className={className} style={{ ...style }} onClick={onClick} />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <LeftArrow className={className} style={{ ...style }} onClick={onClick} />
  );
};

const ImageAndHeaderCarouselView: FC<ImageAndHeaderCarouselProps> = (
  componentProps
) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const {
    content,
    dots = false,
    autoplay = true,
    speed = 500,
    autoplaySpeed = 4000,
    pauseOnHover = false,
  } = componentProps;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // wont work in server-side
  const { height = 1024, width = 768 } = useWindowSize();
  const measure = height > width ? height : width;
  const mobileRatio = height > width ? (height / width) * 2 : width / height;
  const contentHeight = isMobile ? measure / mobileRatio : measure / 2;

  const classes = useStyles({
    contentHeight,
    isMobile,
  });

  const preventWidowWord = (sentence: string) => {
    const words = sentence.split(' ');
    if (words.length > 2) {
      const base = words.slice(0, words.length - 2).join(' ');
      const end = words.slice(words.length - 2, words.length).join('\u00A0');
      return `${base} ${end}`;
    }

    return sentence;
  };

  const [springs, set] = useSprings(content.length, (index) => ({
    opacity: index === activeSlide ? 1 : 0,
  }));

  const AnimatedTypo = animated(Typo);

  return (
    <Box className={classes.container}>
      <Carousel
        dots={dots}
        autoplay={autoplay}
        speed={speed}
        autoplaySpeed={autoplaySpeed}
        pauseOnHover={pauseOnHover}
        className={classes.carousel}
        arrows={!isMobile}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        afterChange={(current: number) => {
          // @ts-ignore
          set((index) => (index === current ? { opacity: 1 } : {}));
          setActiveSlide(current);
        }}
        beforeChange={(current: number) => {
          // @ts-ignore
          set((index) => (index === current ? { opacity: 0 } : {}));
        }}
      >
        {springs.map((props, index) => {
          return (
            <Box key={index}>
              <Box className={classes.contentContainer}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  className={classes.headerContainer}
                >
                  <Grid item xs={12} md={6}>
                    <AnimatedTypo
                      variant="h6"
                      color="primary"
                      className={classes.leftHeader}
                      style={props}
                    >
                      {content[index].leftHeader}
                    </AnimatedTypo>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className={classes.rightHeaderContainer}
                  >
                    <AnimatedTypo
                      variant="h3"
                      color="primary"
                      className={classes.rightHeader}
                      style={props}
                    >
                      {preventWidowWord(content[index].rightHeader)}
                    </AnimatedTypo>
                  </Grid>
                </Grid>
                <Box
                  className={`${classes.image} ${
                    // third image
                    index === 2 ? classes.imageDarken : ''
                  }`}
                  style={{
                    backgroundImage: `url(${content[index].image})`,
                  }}
                />
                <Grid
                  container
                  spacing={2}
                  justifyContent={isMobile ? 'space-between' : 'flex-end'}
                >
                  <Grid item sm={4}>
                    {content[index].caption && (
                      <Typo
                        variant="body3"
                        color="primary"
                        className={classes.caption}
                      >
                        {content[index].caption}
                      </Typo>
                    )}
                  </Grid>
                  <Grid item sm={1}>
                    <Typo
                      variant="body3"
                      color="primary"
                      className={classes.count}
                    >
                      {`${activeSlide + 1}/${content.length}`}
                    </Typo>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          );
        })}
      </Carousel>
    </Box>
  );
};

export default React.memo(ImageAndHeaderCarouselView);
