import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { HOST_CONTACT_URL } from '../../../consts';
import { GhostButton, Typo } from '../../primitives';
import { HostProps } from './Host.props';
import { useStyles } from './Host.styles';

const firstText =
  'At Unyoked we’ve been exploring ways to meaningfully connect people with nature and enact positive ' +
  'change in a generation that’s increasingly dislocated from the outdoors. By partnering with you as a landowner, ' +
  'we hope to unlock the wilderness on otherwise underutilised areas of your property and help you generate additional ' +
  'passive income while helping people reconnect with our natural world.';

const whoWeLookForList =
  `- Located 2-3 hours from a city (or if further if unique) <br/><br/>` +
  `- Locations throughout the property with uninterrupted wilderness views, no man-made structures in sight and minimal to no road noise <br/><br/>` +
  `- Properties with unique features, not just open paddocks <br/><br/>` +
  `- A passion for conservation, sustainability and the outdoors <br/><br/>`;

const whyPartnerList = [
  {
    title: 'We’re Market Leaders',
    desc:
      `- We started this whole thing in Aus and continue to lead the category since our beginnings in 2016 <br/><br/>` +
      `- Our cabins are architecturally designed by leading Aussie architects <br/><br/>` +
      `- Our carefully curated experience has been covered in over 500 publications and reached an audience of over 120 ` +
      `million people in the last year alone <br/><br/>` +
      `- Since launch we have maintained an occupancy rate of over 92% (incl. mid-week) <br/><br/>`,
  },
  {
    title: 'We Share Your Values',
    desc:
      `- We believe in conservation and sustainable tourism as does all our community <br/><br/>` +
      `- We help you generate revenue while educating people about the natural world and respecting the land that our 
          experience takes place on <br/><br/>` +
      `- Our designs and practices are eco-friendly and take an environment-first approach <br/><br/>`,
  },
  {
    title: 'We Help You Achieve Your Goals Without Hassles',
    desc:
      `- We handle all the bookings, customer service, transactions and curate the experience at all stages of 
            the customer journey <br/><br/>` +
      `- We provide the cabin, help coordinate setup and make sure you’re insured for ` +
      `hosting customers on your property <br/><br/>` +
      `- We help with training and quality control for servicing of the cabins <br/><br/>`,
  },
];

const partnerSteps =
  `1. Fill out an Enquiry Form below<br/><br/>` +
  `2. Show us your property <br/><br/>` +
  `3. Roll out cabins`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const WhyPartnerList = (props: {
  classes: Record<string, any>;
  isMobile: boolean;
}) => {
  const { classes, isMobile } = props;

  return (
    <>
      {whyPartnerList.map((item, index) => (
        <Box key={item.title}>
          <Box mb={2}>
            <Typo variant={'body2'} className={classes.whyTitles} style={{marginTop: '1.5em'}}>
              {item.title}
            </Typo>
          </Box>
          <Typo
            variant={'body2'}
            dangerouslySetInnerHTML={{ __html: item.desc }}
          />
        </Box>
      ))}
    </>
  );
};

const HostView: FC<HostProps> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const onFill = () =>
    window.open(HOST_CONTACT_URL, '_blank', 'noopener noreferrer');

  return (
    <Box>
      <Box mb={isMobile ? 5 : 10}>
        <Typo variant={'body2'}>{firstText}</Typo>
      </Box>
      <Box mb={isMobile ? 5 : 10}>
        <Box mb={4}>
          <Typo variant={'h3'} className={classes.headers}>
            Who We Look For
          </Typo>
        </Box>
        <Box mb={2}>
          <Typo variant={'body2'}>
            We’re looking for hosts that have properties that ideally fit the
            following:
          </Typo>
        </Box>
        <Box mb={2}>
          <Typo
            variant={'body2'}
            dangerouslySetInnerHTML={{ __html: whoWeLookForList }}
          />
        </Box>
        <Typo variant={'body2'}>
          We know all properties are unique and special in some way. If your
          property doesn’t fit all of the above, don’t stress - still reach out
          and we can talk about potential opportunities to partner and help more
          people get outside.
        </Typo>
      </Box>
      <Box mb={isMobile ? 5 : 10}>
        <Box mb={4}>
          <Typo variant={'h3'} className={classes.headers}>
            Why Partner With Unyoked?
          </Typo>
        </Box>
        <WhyPartnerList classes={classes} isMobile={isMobile} />
      </Box>
      <Box mb={isMobile ? 5 : 10}>
        <Box mb={4}>
          <Typo variant={'h3'} className={classes.headers}>
            Partner with Unyoked in Three Easy Steps
          </Typo>
        </Box>
        <Typo
          variant={'body2'}
          dangerouslySetInnerHTML={{ __html: partnerSteps }}
        />
      </Box>

      <GhostButton
        style={classes.fillButton}
        text="Fill Out Enquiry Form"
        onClick={onFill}
      />
    </Box>
  );
};

export default React.memo(HostView);
