import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';
import { CheckboxPublicProps } from './Checkbox.props';

interface Style extends CheckboxPublicProps {
  isMobile: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    checkbox: ({ isMobile, isSmall, radius }: Style) => ({
      padding: 0,
      marginTop: '-5px',
      marginRight: theme.spacing(1),
      width: isMobile || isSmall ? 40 : 'auto',
      '& path': {
        transform: 'translate(12%, -5%)',
      },
      height: 40,

      ...(radius && radius == 'small'
        ? {
            '& circle': {
              r: '8px',
              cx: '9px',
              cy: '9px',
            },
            '& svg': {
              width: '20px',
              height: '18px',
            },
            '& path': {
              transform: 'translateX(-15%) translateY(-78%)',
            },
            width: '18px',
            height: '18px',
          }
        : {}),
      '&:hover': !isMobile
        ? {
            backgroundColor: colors.primaryDarkGreen,
          }
        : {},
    }),
  })
);
