import React, { createContext, useContext, useState } from 'react';

const ScrollSpyContext = createContext<{
  visibleSection: string;
  setVisibleSection: (section: string) => void;
}>({
  visibleSection: '',
  setVisibleSection: (section) => console.log(section),
});

export const useScrollSpy = () => {
  return useContext(ScrollSpyContext);
};

const ScrollSpyProvider: React.FC = ({ children }) => {
  const [visibleSection, setVisibleSection] = useState('');

  const contextValues = {
    visibleSection,
    setVisibleSection,
  };

  return (
    <ScrollSpyContext.Provider value={contextValues}>
      {children}
    </ScrollSpyContext.Provider>
  );
};

export default ScrollSpyProvider;
