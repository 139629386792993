import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { colors, fontFamilies } from 'themeConfig/themeConstants';
const { plain, pitch } = fontFamilies;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ghostButton: {
      width: 180,
    },
    cardInputContainer: {
      // borderBottom: `2px solid ${colors.primaryDarkGreen}`,
      paddingBottom: theme.spacing(1),
    },
    checkBoxError: {
      paddingLeft: 2,
      paddingRight: 2,
      color: '#D2232A',
      margin: 0,
      fontSize: '0.75rem',
      marginTop: '8px',
      minHeight: '1em',
      textAlign: 'left',
      fontFamily: plain,
      fontWeight: 400,
      lineHeight: '1em',
    },
    reselect: {
      textDecoration: 'none',
      color: colors.primaryDarkGreen,
      fontWeight: 500,
      cursor: 'pointer',
    },

    holdInfo: {
      background: colors.secondaryYellow,
      padding: 10,
      borderRadius: 5,
      marginTop: 10,
    },

    checkbox: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: 20,
    },
  })
);
