import React, { FC, useEffect, useRef } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import RightArrow from 'assets/svgs/right-arrow.svg';
import partial from 'ramda/src/partial';
import Link from 'redux-first-router-link';

import { HorizontalLine, ImageContainer, Typo } from '../../primitives';
import { JournalBlogCardProps } from './JournalBlogCard.props';
import { useStyles } from './JournalBlogCard.styles';

const JournalBlogCardView: FC<JournalBlogCardProps> = (props) => {
  const {
    id,
    category,
    banner,
    image,
    imageCaption,
    title,
    subtitle,
    slug,
    readingTime,
    wordsBy,
    photographyBy,
    isFeatured,
    date,
    body,
    moodCategory,
    onMore,
    onMoreLink,
    externalUrl,
    goToURL,
    displayArea,
  } = props;
  const classes = useStyles({ displayArea });
  // @ts-ignore
  const belowTablet = useMediaQuery('(max-width: 767px)');
  const fn = externalUrl
    ? partial(goToURL, [externalUrl])
    : partial(onMore, [slug]);

  const imageRef = useRef<HTMLImageElement>(null);

  if (!category) {
    return (
      <Link
        className={classes.link}
        to={externalUrl || onMoreLink(slug)}
        target={externalUrl ? '_blank' : undefined}
      >
        <Box onClick={fn}>
          <ImageContainer
            alt="blog-card"
            className={classes.image}
            src={image}
            ref={imageRef}
          />
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typo variant="body3" className={classes.imageCaption}>
              {imageCaption}
            </Typo>
            <Typo variant="h5" className={classes.imageCaption}>
              {!!moodCategory?.length && moodCategory[0]}
            </Typo>
          </Box>

          {!belowTablet && <HorizontalLine className={classes.line} />}
          <Box className={classes.titleArrowContainer}>
            <Typo variant="h4" className={classes.title}>
              {title}
            </Typo>
            {displayArea === 'Home' ? (
              <>
                <ArrowForwardIcon />
              </>
            ) : (
              <></>
            )}
          </Box>
          {belowTablet && (
            <Typo variant="h5" className={classes.subTitle}>
              {subtitle}
            </Typo>
          )}
          {belowTablet && <HorizontalLine className={classes.line2} />}
        </Box>
      </Link>
    );
  }

  return (
    <Box className={classes.link} onClick={fn}>
      {banner ? (
        <ImageContainer
          alt="blog-card"
          className={classes.image}
          src={banner}
          ref={imageRef}
          style={{
            height:
              props.displayArea === 'Home'
                ? imageRef?.current?.width || 'auto'
                : '',
          }}
        />
      ) : (
        <div className={classes.noImage}></div>
      )}
      <Typo variant="body3" className={classes.imageCaption}>
        {category.join(', ') + ' | ' + readingTime + ' min read'}
      </Typo>
      {!belowTablet && <HorizontalLine className={classes.line} />}
      <Box className={classes.titleArrowContainer}>
        <Typo variant="h4" className={classes.title}>
          {title}
        </Typo>
        {displayArea === 'Home' ? (
          <>
            <ArrowForwardIcon />
          </>
        ) : (
          <></>
        )}
      </Box>
      {belowTablet && (
        <Typo variant="h5" className={classes.subTitle}>
          {subtitle}
        </Typo>
      )}
      {belowTablet && <HorizontalLine className={classes.line2} />}
    </Box>
  );
};

export default React.memo(JournalBlogCardView);
