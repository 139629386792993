import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'themeConfig/themeConstants';

interface StyleProps {
  linkColor?: string;
  isDisableAnimation: boolean;
  invert?: boolean;
  noHighlight?: boolean;
}


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: ({ linkColor, isDisableAnimation, invert, noHighlight }: StyleProps) => ({
      width: 'fit-content',
      cursor: 'pointer',
      textDecoration: `none !important`,
      color: linkColor,
      fontSize: 18,
      transition: 'all 0.3s',
      display: `block`,
      '&:after': {
        content: `''`,
        display: 'block',
        borderBottom: linkColor !== 'none' ? `2px solid ${linkColor}` : 'none',
        width: '0',
        transition: '0.20s ease',
      },


      '&:hover': {
        color: `${ noHighlight ? '' : invert ? colors.primaryDarkGreen : colors.primaryYellow} !important`,
        borderRadius: 4,
        background: noHighlight ? '' : 
          invert ? colors.primaryYellow :  colors.secondaryYellow,

        '& span': {
          color: 'inherit',
        }
      },
      '&:visited': {
        color: colors.lightGreen,
      },

      '&:hover:after': { width: isDisableAnimation ? 0 : '100%' },
    }),
  })
);
