import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import PlaceholderImg from 'assets/images/home.jpg';
import { useSpring, animated } from 'react-spring';

import { Typo, ImageContainer, GhostButton, Link } from '../../primitives';
import { ImageTextProps } from '../ImageText/ImageText.props';
import { MediaTextProps } from './MediaText.props';
import { useStyles } from './MediaText.styles';
import { colors } from 'themeConfig/themeConstants';
import { HomeBackgroundImage } from 'assets/images';

const DisplayEmbed: FC<{
  url?: string;
  type?: string;
  classes: Record<string, any>;
  width: string;
  height: string;
}> = ({ url, type, classes, width, height }) => {
  if (type && url) {
    if (type.includes('video')) {
      return (
        <>
          <video
            muted
            className={classes.video}
            autoPlay={true}
            loop={true}
            playsInline={true}
          >
            <source src={url} type={type} />
          </video>
        </>
      );
    }
    if (type.includes('image')) {
      return (
        <ImageContainer
          alt="ImageText"
          width={width}
          height={height}
          className={classes.image}
          src={url || HomeBackgroundImage}
        />
      );
    }
  }

  return (
    <ImageContainer
      alt="ImageText"
      className={type === 'image' ? classes.image : classes.imageBlurred}
      src={PlaceholderImg}
    />
  );
};

const btnColor = {
  yellow: 'primaryYellow',
  green: 'primaryDarkGreen',
  white: 'primaryWhite',
};

const ImageTextView: FC<MediaTextProps> = (props: MediaTextProps) => {
  const {
    title,
    description,
    shouldTitleBreak,
    breakTitle = () => title,
    background,
    titleStyle,
    customHeight,
    showBookNow = false,
    setShowMobileFilter,
    campaignAction,
    campaignLabel = '',
    campaignColor = 'yellow',
    animateTitle,
    fadeInOnly,
    embed,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile, customHeight });

  const trigger = useScrollTrigger({
    disableHysteresis: true,
  });

  const spring = useSpring({
    to: fadeInOnly ? { opacity: 1 } : { opacity: trigger ? 0 : 1 },
    from: { opacity: 0 },
    delay: trigger ? 0 : 1000,
    // immediate: !animateTitle,
  });

  const AnimatedTypo = animated(Typo);
  return (
    <Box className={classes.container}>
      <Box className={classes.imageContainer}>
        <DisplayEmbed
          classes={classes}
          width={props.width}
          height={props.height}
          url={embed?.url}
          type={embed?.contentType || embed?.type}
        />
      </Box>
      <Box className={classes.titleContainer}>
        <Typo
          variant="h1"
          color="primaryYellow"
          className={`${classes.title} ${titleStyle}`}
        >
          {shouldTitleBreak ? breakTitle(title) : title}
        </Typo>
        <Typo
          variant="h2"
          color="primaryYellow"
          className={`${classes.subtitle} ${titleStyle}`}
        >
          {description}
        </Typo>
        {campaignAction ? (
          <AnimatedTypo variant="h2">
            <GhostButton
              color={btnColor[campaignColor]}
              text={campaignLabel || 'Book Now'}
              style={`${classes.button} ${campaignColor}`}
              // typographyStyle={classes.learnMoreButtonText}
              onClick={() => {
                // window.braze?.logContentCardClick?.();
                campaignAction();
              }}
            />
          </AnimatedTypo>
        ) : null}
      </Box>
    </Box>
  );
};

export default ImageTextView;
