import React, { FC } from 'react';

import { GalleryPublicProps } from './Gallery.props';
import GalleryView from './Gallery.view';

const GalleryContainer: FC<GalleryPublicProps> = (props) => {
  return <GalleryView {...props} />;
};

export default React.memo(GalleryContainer);
