import React, { FC, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { colors } from 'themeConfig/themeConstants';

import { NewsletterImage } from '../../../assets/images';
import {
  Typo,
  JournalSubmitField,
  ImageContainer,
  TextButton,
  Button,
  Modal,
} from '../../primitives';
import { JournalNewsletterProps } from './JournalNewsletter.props';
import { useStyles } from './JournalNewsletter.styles';
import { WaitlistModalContent } from 'components/modules';

const JournalNewsletterView: FC<JournalNewsletterProps> = (props) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);

  const { onSubmit, loading, success, data } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isInverted: !!props.invertColors });
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    if (error) setError('');
    setValue(target.value);
  };

  const onEmailSubmit = () => {
    // TODO: error states
    if (!value) setError('Please enter email');
    onSubmit(value);
  };

  const onClick = () => {
    setOpen(true);
  };

  return (
    <>
      <Grid container>
        {!isMobile && (
          <Grid item xs={12}>
            <Box mt={9} mb={5}>
              <Typo variant="h3" className={classes.joinDesktop}>
                {data.title}
              </Typo>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <ImageContainer
            alt="newsletter"
            src={data.banner || NewsletterImage}
            className={classes.image}
          />
        </Grid>
        {isMobile && (
          <Grid item xs={12}>
            <Box mb={3}>
              <Typo variant="h6">{data.title}</Typo>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={6} className={classes.rightContainer}>
          <Box mb={isMobile ? 5 : 8}>
            <Typo variant={'h3'} className={classes.description}>
              {data.description ||
                'We’re waging a war on “busy” and we’re looking for recruits.'}
            </Typo>
          </Box>

          <Button
            variant="h4"
            text={'Enter your details'}
            onClick={onClick}
            loading={false}
          />

          {/*
        <JournalSubmitField
          endAdornmentType="submit"
          placeholder="Enter email"
          type="email"
          value={success ? 'Thanks for joining the movement!' : value}
          loading={loading}
          success={success}
          error={error}
          color={
            props.invertColors ? colors.primaryDarkGreen : colors.primaryYellow
          }
          onChange={onChange}
          onSubmit={onEmailSubmit}
        />
           */}
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={setOpen}
        onBackdropClick={() => setOpen(false)}
      >
        <WaitlistModalContent
          title="Subscribe to our Newsletter"
          selectText="Select your home city"
          closeModal={() => setOpen(false)}
          isNewsletter
        ></WaitlistModalContent>
      </Modal>
    </>
  );
};

export default React.memo(JournalNewsletterView);
