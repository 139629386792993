import React, { FC, useEffect, useRef } from 'react';

import Box from '@material-ui/core/Box';
import { zonedTimeToUtc } from 'date-fns-tz';
import $ from 'jquery';
import moment from 'moment-timezone';
import isEmpty from 'ramda/src/isEmpty';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import './Calendar.css';

import { exception } from '../../../utils/Data/bookedDates/bookedDates';
import { CalendarProps } from './Calendar.props';
import { useStyles } from './Calendar.styles';

const CalendarView: FC<CalendarProps> = ({
  hookState,
  transparent,
  bookedDates,
  month,
  exceptions = {
    freq: '',
    byday: '',
  },
  isBooking,
  onMonthChange,
}) => {
  const ref = useRef(null);

  const classes = useStyles()

  // make checkout dates hoverable
  useEffect(() => {
    if (isBooking && ref && ref.current && !isEmpty(bookedDates)) {
      // console.error(
      //   'dates yeah',
      //   isBooking && ref && ref.current && !isEmpty(bookedDates)
      // );
      // console.error('isBooking yeah', isBooking);
      // console.error('ref yeah', ref.current);
      // console.error('isEmpty yeah', !isEmpty(bookedDates));
      // @ts-ignore
      // const { dayPicker } = ref.current;

      // const disabled = $(dayPicker).find('.DayPicker-Day--disabled');
      // $(disabled).each(function e() {
      //   if ($(this).prev().is('[aria-disabled=false]')) {
      //     if (hookState.from) {
      //       $(this).addClass('checkout-override');
      //     } else {
      //       $(this).removeClass('checkout-override');
      //     }

      //     $(this).on('mouseenter', function me() {
      //       $(this).addClass('checkout-hover-override');
      //     });

      //     $(this).on('mouseleave', function ml() {
      //       $(this).removeClass('checkout-hover-override');
      //     });
      //   }
      // });
    }
  }, [isBooking, ref, bookedDates, hookState.from]);
  const dateNow = moment().tz('Australia/Sydney');
  const dateTomorrow = dateNow.add(1, 'days').format('YYYY/MM/DD');
  const dateAfterTomorrow = dateNow.add(2, 'days').format('YYYY/MM/DD');
  const check = Number(dateNow.format('H')) < 18;
  const date = check ? dateTomorrow : dateAfterTomorrow;

  return (
    <Box>
      <DayPicker
        ref={ref}
        className={transparent ? `Calendar alternate ${classes.root}` : `Calendar ${classes.root}`}
        firstDayOfWeek={0}
        month={month}
        numberOfMonths={1}
        onMonthChange={onMonthChange}
        // @ts-ignore
        selectedDays={[
          hookState.from,
          { from: hookState.from as Date, to: hookState.to as Date },
        ]}
        // @ts-ignore
        disabledDays={[
          exception(exceptions),
          {
            before: new Date(date),
          },
          //  corona
          {
            before: zonedTimeToUtc(new Date(2020, 5, 1), 'Australia/Sydney'),
          },
          ...(bookedDates || []),
        ]}
        modifiers={{
          ...hookState.modifiers,
          // sunday,
          weekend: { daysOfWeek: [0, 7] },
        }}
        onDayClick={hookState.handleDayClick}
      />
    </Box>
  );
};

export default React.memo(CalendarView);
