import React, { FC, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import pathOr from 'ramda/es/pathOr';

import { API } from '../../../consts';
import {
  Button,
  GhostButton,
  HorizontalLine,
  InputField,
  Link,
  Snackbar,
  Typo,
  Modal,
} from '../../primitives';
import { PaymentMethodsProps } from './PaymentMethods.props';
import { useStyles } from './PaymentMethods.styles';

import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const PaymentMethodsView: FC<PaymentMethodsProps> = ({
  cards,
  loading,
  success,
  errorMessage,
  openErrorSnack,
  goToAccount,
  country,
  token,
  refresh,
  onDelete,
  onUpdate,
}) => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(loading);
  const [deleteTarget, setDeleteTarget] = useState('');
  const [updateTarget, setUpdateTarget] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const classes = useStyles();

  const stripe = useStripe();
  const elements = useElements();

  const onChange = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    setNameError('');
    setName(event.target.value);
  };

  const handleUpdate = async () => {
    let hasError = false;

    if (!name) {
      setNameError('Please enter your last name');
      hasError = true;
    }
    if (hasError) return;

    if (isEdit) {
      // EDIT
    } else if (stripe && elements) {

      const cardElement = elements.getElement(CardElement);

      if (cardElement) {
        setIsLoading(true);

        const {error, paymentMethod} = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });

        if (paymentMethod) {
          // save to BE
          const response = await fetch(`${API.URL}/${API.VERSION}/payment`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ country, token: paymentMethod.id, name }),
          });
          if (response.ok) {
            setOpenModal(false);
            setIsLoading(false);
            refresh();
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      }
    }
  };

  const onDeleteCard = async (sourceId: string) => {
    onDelete(sourceId);
    setDeleteTarget('');
  };

  const onUpdateCard = async (sourceId: string) => {
    onUpdate(sourceId);
    setUpdateTarget('');
  };

  useEffect(() => {
    let timer: number;
    if (!openModal) {
      timer = window.setTimeout(() => {
        setName('');
        setIsEdit(false);
      }, 500);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [openModal]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <Box>
      <Link onClick={goToAccount}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ArrowBackIcon className={classes.arrow} />
          <Typo variant="h6">Account Settings</Typo>
        </Box>
      </Link>
      <Box mb={isXs ? 4 : 8} />
      <Typo variant={isMobile ? 'h3' : 'h1'} className={classes.header}>
        Payment Methods
      </Typo>
      {!isXs && (
        <Box mt={3}>
          <HorizontalLine className={classes.horizontalLine} />
        </Box>
      )}
      <Grid
        container
        spacing={isXs ? 0 : 10}
        className={classes.contentContainer}
      >
        <Grid item xs={12} sm={6}>
          {cards && cards.length > 0 ? (
            cards.map((card) => (
              <Box key={card.id} mb={5}>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typo variant="h6">
                    ...{card.id.substring(20, card.id.length)}
                    {card.isDefault ? '(default)' : ''}
                  </Typo>
                  <Typo variant="h6">
                    {card.brand}....{card.last4Number}{' '}
                  </Typo>
                  <Box
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Link
                      onClick={() => {
                        setUpdateTarget(card.id);
                        // setName(card.name);
                        // setIsEdit(true);
                        // setOpenModal(true);
                      }}
                    >
                      <Typo variant="h6" className={classes.actions}>
                        Edit
                      </Typo>
                    </Link>
                    <Box ml={2} />
                    <Link onClick={() => setDeleteTarget(card.id)}>
                      <Typo variant="h6" className={classes.actions}>
                        Delete
                      </Typo>
                    </Link>
                  </Box>
                </Box>
                <Box mb={1} />
                <Typo variant="body3">
                  {card.expiryMonth}/{card.expiryYear}
                </Typo>
                <HorizontalLine className={classes.horizontalLine} />
              </Box>
            ))
          ) : (
            <>
              {isLoading ? (
                <Typo variant="body1">Getting your cards...</Typo>
              ) : (
                <Typo variant="body1">Please add a card.</Typo>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {!isXs ? (
            <Box mt={cards && cards.length > 0 ? '19px' : 0}>
              <Button
                text="Add Payment Method"
                variant="h4"
                onClick={() => setOpenModal(true)}
              />
            </Box>
          ) : (
            <Box mt={10}>
              <GhostButton
                text="Add Payment Method"
                style={classes.ghostButton}
                onClick={() => setOpenModal(true)}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Modal
        open={openModal}
        onClose={setOpenModal}
        onBackdropClick={() => setOpenModal(false)}
      >
        <Box pl={isMobile ? 0 : 5} pr={isMobile ? 0 : 5} mb={5}>
          <Box pt={isMobile ? 2 : 0} mb={5}>
            <Typo
              variant="h4"
              className={classes.modalTitle}
              style={{ textAlign: 'center' }}
            >
              {isEdit ? 'Edit Payment Method' : 'Add Payment Method'}
            </Typo>
          </Box>
          {!isEdit && (
            <Box className={classes.cardInputContainer} mb={4}>
              <CardElement />
            </Box>
          )}
          <InputField
            id="name"
            label="Name"
            shrink={true}
            value={name}
            error={nameError}
            onChange={onChange}
          />
          <Box mb={8} />
          <Button
            text={isEdit ? 'Edit Card' : 'Add Card'}
            variant="h4"
            onClick={handleUpdate}
            loading={isLoading}
            success={success}
          />
        </Box>
      </Modal>

      <Modal
        open={deleteTarget.length > 0}
        onClose={() => setDeleteTarget('')}
        onBackdropClick={() => setDeleteTarget('')}
      >
        <Box pl={isMobile ? 0 : 5} pr={isMobile ? 0 : 5} mb={5}>
          <Box pt={isMobile ? 2 : 0} mb={5}>
            <Typo variant="h4" style={{ textAlign: 'center' }}>
              Are you sure?
            </Typo>
          </Box>
          <Button
            text="Confirm"
            variant="h4"
            onClick={() => onDeleteCard(deleteTarget)}
            loading={isLoading}
            success={success}
          />
        </Box>
      </Modal>

      <Modal
        open={updateTarget.length > 0}
        onClose={() => setUpdateTarget('')}
        onBackdropClick={() => setUpdateTarget('')}
      >
        <Box pl={isMobile ? 0 : 5} pr={isMobile ? 0 : 5} mb={5}>
          <Box pt={isMobile ? 2 : 0} mb={5}>
            <Typo variant="h4" style={{ textAlign: 'center' }}>
              Set as default payment method?
            </Typo>
          </Box>
          <Button
            text="Set as Default"
            variant="h4"
            onClick={() => onUpdateCard(updateTarget)}
            loading={isLoading}
            success={success}
          />
        </Box>
      </Modal>

      <Snackbar message={errorMessage} open={openErrorSnack} type="error" />
    </Box>
  );
};

export default PaymentMethodsView;
