import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    whyTitles: {
      fontWeight: 'bold',
    },
    headers: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 30,
      },
    },
    fillButton: {
      width: 250,
    },
  })
);
