import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors, fontFamilies } from '../../../themeConfig/themeConstants';
import { pxToRem } from '../../../themeConfig/typography';

const { plain, pitch } = fontFamilies;

interface Style {
  isMobile: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    },
    ghostButton: {
      width: 180,
    },
    arrow: {
      fontSize: pxToRem(12),
    },
    horizontalLine: {
      backgroundColor: `${colors.primaryDarkGreen} !important`,
      height: 2.5,
    },
    contentContainer: {
      width: '100%',
      marginTop: theme.spacing(5),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(10),
        paddingLeft: '5%',
        paddingRight: '5%',
      },
    },
    error: {
      paddingLeft: 2,
      paddingRight: 2,
    },
    disabled: {
      opacity: 0.5,
    },
    mobileError: {
      paddingLeft: 2,
      paddingRight: 2,
      color: '#D2232A',
      margin: 0,
      fontSize: '0.75rem',
      marginTop: '8px',
      minHeight: '1em',
      textAlign: 'left',
      fontFamily: plain,
      fontWeight: 400,
      lineHeight: '1em',
    },
    checkbox: ({ isMobile }: Style) => ({
      height: 40,
      width: 40,
      transform: 'scale(0.5)',
      '&:hover': !isMobile
        ? {
            backgroundColor: colors.primaryDarkGreen,
          }
        : {},
      '& path': {
        transform: 'none',
      },
    }),
    choicesContainer: ({ isMobile }: Style) => ({
      display: 'grid',
      gridTemplateColumns: isMobile ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)',
      marginTop: '10px',
    }),
  })
);
