import PasswordValidator from 'password-validator';

const schema = new PasswordValidator();

schema.is().min(6).has().lowercase().has().uppercase().has().not().spaces();

export const rules = [
  'Minimum 6 characters',
  'At least an uppercase',
  'Has a lowercase',
  'No spaces allowed',
];

const validatePassword = (password: string): boolean | string[] =>
  schema.validate(password, { list: true });

export default validatePassword;
