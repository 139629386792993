import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';

interface StyleProps {
  isMobile?: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: ({ isMobile }: StyleProps) => ({
      position: 'relative',
      minHeight: isMobile ? '100%' : '108%',
    }),
    line: {
      backgroundColor: colors.primaryDarkGreen,
      height: 2.5,
    },
    image: {
      width: '100%',
      objectFit: 'contain',
      height: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      // below tablets
      [theme.breakpoints.up(767)]: {
        height: 150,
      },
      [theme.breakpoints.up('md')]: {
        height: 175,
      },
      [theme.breakpoints.up('lg')]: {
        height: 250,
      },
    },
    prescriptionMobile: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    prescribe: {
      position: 'absolute',
      width: '100%',
      bottom: 0,
    },
    ghostButton: {
      width: 180,
    },
  })
);
