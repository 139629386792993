import React, { FC, ReactElement } from 'react';

import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { GhostButton, ImageContainer, Typo } from 'components/primitives';
import { useClickTrack } from 'hooks/useClickTrack';
import theme from 'themeConfig/theme';
import { contentfulListParser } from 'utils/Data/contentfulParser/contentfulParser';
import { v4 as uuid } from 'uuid';

import { ContentfulRendererProps } from './ContentfulRenderer.props';
import { useStyles } from './ContentfulRenderer.styles';

const cleanText = (toClean: string, identifier: string) => {
  return toClean.replaceAll(identifier, '');
};

const identifiers = {
  h6: '###### ',
  h5: '##### ',
  h4: '#### ',
  h3: '### ',
  h2: '## ',
  h1: '# ',
  underline: '___',
  bold: '__',
};

const elementIdentifiers: { [key: string]: RegExp } = {
  media: new RegExp(/!\[(.*?)]\((\/\/\S+\.\w+)/),
  button: new RegExp(/\[button/),
  link: new RegExp(/\[dynamic_link/),
};

const identifiersConditions = (classes: Record<string, string>) => [
  {
    identifier: identifiers.h6,
    variant: 'h6',
    className: classes.header,
  },
  {
    identifier: identifiers.h5,
    variant: 'h5',
    className: classes.header,
  },
  {
    identifier: identifiers.h4,
    variant: 'h4',
    mobileVariant: 'h5',
    className: classes.header,
  },
  {
    identifier: identifiers.h3,
    variant: 'h3',
    mobileVariant: 'h5',
    className: classes.header,
  },
  {
    identifier: identifiers.h2,
    variant: 'h2',
    mobileVariant: 'h3',
    className: classes.header,
  },
  {
    identifier: identifiers.h1,
    variant: 'h1',
    mobileVariant: 'h3',
    className: classes.header,
  },
  {
    identifier: identifiers.underline,
    variant: 'body2',
    className: classes.underlinedTitles,
  },
  {
    identifier: identifiers.bold,
    variant: 'body2',
    className: classes.boldTitles,
  },
];

const newTextToHtml = ({
  text,
  classes,
  forceVariant,
  isMobile,
  newTabLink,
}: {
    text: string;
    classes: Record<string, string>;
    forceVariant?: string;
    isMobile?: boolean;
    newTabLink?: boolean;
}) => {
  const clickTracker = useClickTrack('CTA Click');
  const identifierProps = identifiersConditions(classes);
  let identifierIdx = -1;
  let MediaElement;

  for (const key in elementIdentifiers) {
    const match = elementIdentifiers[key].exec(text);

    if (match) {
      if (key === 'media') {
        MediaElement = (
          <Box
            key={uuid()}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
            className={`${
              classes.contentfulImageContainer || ''
            } imageContainer`}
          >
            <ImageContainer
              style={{
                objectFit: 'cover',
                height: '100%',
              }}
              src={match[2]}
              alt={match[1]}
              className={classes.contentfulImage}
            />
          </Box>
        );
      }
      if (key === 'button') {
        const texts = text.split('(').reduce(
          (accum, elem) => {
            if (!elem.includes('=')) return accum;
            const data = elem.split('=');
            const closingParenthesisIdx = data[1].lastIndexOf(')');

            return {
              ...accum,
              [data[0]]: closingParenthesisIdx
                ? data[1].substring(0, data[1].length - 2)
                : data[1],
            };
          },
          {
            label: '',
            url: '',
          }
        );
        MediaElement = (
          <Box
            key={uuid()}
            style={{
              width: '100%',
            }}
          >
            <GhostButton
              text={texts.label}
              onClick={() => {
                clickTracker({
                  click_text: texts.label,
                  click_url: texts.url,
                });
                window.open(texts.url, '_blank', 'noopener noreferrer');
              }}
            />
          </Box>
        );
      }
      if (key === 'link') {
        const propsIndicatorIdx = text.indexOf('(') + 1;
        const propsLasIndicatorIdx = text.lastIndexOf(')');
        const newText = text.substring(propsIndicatorIdx, propsLasIndicatorIdx);
        const props = newText.split('|').reduce(
          (accum, elem) => {
            const data = elem.split('=');
            return {
              ...accum,
              [data[0].trim()]: data[1],
            };
          },
          {
            link_href: '',
            link_label: '',
          }
        );

        MediaElement = (
          <Box key={uuid()}>
            <a href={props.link_href} target={ newTabLink ? '_blank' : '_self'}>
              <Typo variant={forceVariant || 'body2'}>{props.link_label}</Typo>
            </a>
          </Box>
        );
      }
    }
  }

  if (MediaElement) {
    return MediaElement;
  }

  identifierProps.some((identifier, idx) => {
    if (text.includes(identifier.identifier)) {
      identifierIdx = idx;
      return true;
    }
  });

  if (forceVariant === 'string') {
    if (identifierIdx !== -1)
      return cleanText(text, identifierProps[identifierIdx].identifier);
    return text;
  }

  return identifierIdx !== -1 ? (
    <Typo
      key={uuid()}
      variant={
        forceVariant ||
        (isMobile && identifierProps[identifierIdx].mobileVariant) ||
        identifierProps[identifierIdx].variant
      }
      className={identifierProps[identifierIdx].className}
      color="inherit"
    >
      {cleanText(text, identifierProps[identifierIdx].identifier)}
    </Typo>
  ) : (
    <Box key={uuid()}>
      <Typo
        variant={forceVariant || 'body2'}
        dangerouslySetInnerHTML={{
          __html: contentfulListParser(text, classes),
        }}
        color="inherit"
      />
    </Box>
  );
};

const ContentfulRendererView: FC<ContentfulRendererProps> = (
  props: ContentfulRendererProps
) => {
  const classes = useStyles();
  const { contentArray, forceVariant, isMobile, newTabLink } = props;

  if (!contentArray.length) {
    return <></>;
  }

  if (forceVariant) {
    return (
      <Box>
        {contentArray.map((text) =>
          newTextToHtml({ text, classes, forceVariant, isMobile, newTabLink })
        )}
      </Box>
    );
  }
  return (
    <Box className={props.containerClass}>
      {contentArray.map((text) => newTextToHtml({ text, classes, isMobile, newTabLink}))}
    </Box>
  );
};

export default ContentfulRendererView;
