import * as React from 'react';

const LinkedInIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
    style={{
      enableBackground: 'new 0 0 300 300',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M260.6 93.5c0-4.5-.3-9-.7-13.5-1.5-13.7-7.2-25-17.6-33.7-9.8-8.2-21.5-11.1-33.7-11.2-32.5-.3-65.1-.2-97.6-.2-9.5 0-19 .1-28.5.7-12.3.8-23 5.7-31.8 15C41 61 37.5 73.8 37.1 87.6c-.4 12.9-.4 25.9-.4 38.8 0 28.9 0 57.8.2 86.7.1 8.5 1.4 16.8 5.1 24.5 8.9 18.4 23.9 27.4 43.3 28 21.1.6 42.1.1 63.2.1 21.2 0 42.3.5 63.5-.1 19.3-.6 34.2-9.5 43.2-27.7 4.3-8.7 5.4-18.2 5.4-27.8.2-38.7.1-77.7 0-116.6zm-156 136.3h-33V123.1h33v106.7zm2.3-137.1c-1.6 8.9-9.6 15.7-18.8 15.8-9.1.1-17.2-6.6-19-15.7-.1-.5-.2-.9-.3-1.3v-4.7c.1-.3.2-.7.3-1 1-5 3.5-9 7.6-12.1 2.7-2 5.8-3 9-3.7h4.7c1.4.4 2.9.7 4.3 1.2 8.8 3.1 13.9 12.2 12.2 21.5zM228.8 229c0 .2 0 .4-.1.7h-33.1v-2c0-17.9 0-35.8-.1-53.7 0-3.7-.4-7.5-1.1-11.2-2-10.2-8.7-14.2-18.8-13.2-9.4 1-14.6 6.1-16.2 15.8-.6 3.9-.9 8-1 12-.1 16.8 0 33.5 0 50.3v2h-33V123.1h31.7v14.7c.5-.5.7-.7.9-1 5.6-9.3 14.1-14.3 24.5-16 8.1-1.3 16.2-.6 24 2.2 10.1 3.7 16 11.2 19 21.2 2.2 7.1 3 14.4 3 21.7.4 21.2.3 42.2.3 63.1z"
      style={{
        fill: '#f5f5db',
      }}
    />
  </svg>
);

export default LinkedInIcon;
