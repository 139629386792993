import * as React from 'react';

function SvgCheckboxChecked2(props) {
  return (
    <svg width={41} height={41} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle stroke="#041807" strokeWidth={2} cx={20.5} cy={20.5} r={18.5} />
        <path
          d="M40.971 2c.185 2.958-7.016 6.021-8.842 8.2-5.751 4.196-10.853 10.007-16.384 15.244-2.217 1.503-3.121 6.453-5.558 3.776-1.014-2.938-5.527-2.576-3.795-6.637 2.32-1.575 3.343 4.007 5.994 3.002C21.193 17.122 30.626 9.067 41 2.036L40.971 2z"
          fill="#041807"
        />
      </g>
    </svg>
  );
}

export default SvgCheckboxChecked2;
