import React, { FC } from 'react';

import { TextHeaderPublicProps } from './TextHeader.props';
import View from './TextHeader.view';

const TextHeaderContainer: FC<TextHeaderPublicProps> = (props) => {
  return <View {...props} />;
};

export default React.memo(TextHeaderContainer);
