import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';
import { pxToRem } from '../../../themeConfig/typography';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      '& > .MuiDrawer-paper': {
        height: '100vh',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      backgroundColor: colors.primaryYellow,
      width: 'auto',
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      //  iphone SE (320) down
      [theme.breakpoints.down(321)]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
    title: {
      fontSize: pxToRem(16),
      flex: 1,
      textAlign: 'center',
    },
    close: {
      color: 'black',
      paddingLeft: 0,
      paddingRight: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      [theme.breakpoints.down(321)]: {
        padding: 0,
      },
    },
    closeIcon: {
      width: '34px',
      height: '34px',
    },
    inputsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      flex: 1,
      marginBottom: '10%',
    },
    calendarContainer: {
      //  iphone SE (320) up
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    input: {
      display: 'flex',
      flexDirection: 'column',
      borderColor: 'black',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    inputButton: {
      textTransform: 'none',
      padding: 0,
      width: '100%',
    },
    expandedInputButton: {
      fontSize: pxToRem(18),
      color: colors.primaryDarkGreen,
      textTransform: 'none',
      justifyContent: 'flex-start',
    },
    inputContent: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'end',
    },
    inputContentColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    button: {
      marginTop: theme.spacing(2),
    },
    doItButton: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: colors.secondaryYellow,
      color: colors.primaryDarkGreen,
      borderRadius: 0,
      textTransform: 'none',
      height: 65,
      fontSize: 20,
      zIndex: 10,
    },

    mustHaveCheckBox: ({ stateColor }: StyleProps) => ({
      '& svg': {
        '& circle': {
          stroke: stateColor,
        },
        '& path': {
          fill: stateColor,
        },
      },
    }),


  })
);
