import { Dispatch } from 'redux';
import format from 'date-fns/format';
import { getAllEntitiesEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { entityActions, filterActions } from 'store/actions';

import { Filter } from '../../types/Filter';
import { Store } from '../../types/store/Store';
import { Entity } from 'types/Entity';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';

const DEFAULT_FILTER = {
  location: {
    country: 'AU',
    cityId: null,
    address: '',
    latitude: 0,
    longitude: 0,
  },
  startDate: '',
  endDate: '',
  guests: '',
  beds: '',
};

const FETCH_LOCKED: { [key: string]: boolean } = {};

const getEntitiesForCalendar = (date: Date, country: string) => (
  dispatch: Dispatch,
  getState: () => Store
) => {
  const year = date.getFullYear(), month = date.getMonth();
  const oldEntities = getState().calendarEntities.data?.entities;
  const initial = !oldEntities || Object.keys(oldEntities).find(key => key.startsWith(country)) === undefined;

  if (FETCH_LOCKED[`${country}-${year}-${month}`]) return;
  FETCH_LOCKED[`${country}-${year}-${month}`] = true;

  dispatch(
    entityActions.getForCalendar((async () => {
      
      return {
        entities: {
          ...getState().calendarEntities.data?.entities,
          [`${country}-${year}-${month}`]: []
        },
        changeOverDays: getState().calendarEntities.data?.changeOverDays || {},
        message: 'Success'
      }
    })())
  )
  
  dispatch(
    entityActions.getForCalendar(
      (async () => {

        const defaultFilter = { 
          ...DEFAULT_FILTER, location: { ...DEFAULT_FILTER.location, country } 
        };

        const chunkMonth = [
          {
            ...defaultFilter,
            startDate: format(new Date(year, month, 1), 'MMM dd, yyyy'),
            endDate: format(new Date(year, month, 7), 'MMM dd, yyyy'),
            changeOver: initial
          }, {
            ...defaultFilter,
            startDate: format(new Date(year, month, 8), 'MMM dd, yyyy'),
            endDate: format(new Date(year, month, 15), 'MMM dd, yyyy')
          },  {
            ...defaultFilter,
            startDate: format(new Date(year, month, 16), 'MMM dd, yyyy'),
            endDate: format(new Date(year, month, 22), 'MMM dd, yyyy')
          }, {
            ...defaultFilter,
            startDate: format(new Date(year, month, 23), 'MMM dd, yyyy'),
            endDate: format(new Date(year, month + 1, 0), 'MMM dd, yyyy')
          }
        ]

        const results = await Promise.all(chunkMonth.map(chunkData =>
          apiFetch<Filter & { noexact: boolean, changeOver?: boolean }, { entities: any[]}>(
            getAllEntitiesEndpoint, 
            { ...chunkData, noexact: true }
          )(dispatch, getState),
        ))

        const entitiesMap: { [key: string]: Entity } = {};
        const changeOverDays: { [key: string]: string[] } = {};
        const oldChangeOverDays = getState().calendarEntities.data?.changeOverDays || {};

        const entityFn = (e: Entity) => {

          if (initial && e.schedule[0].changeOverDays.length) {
            changeOverDays[e.id] = e.schedule[0].changeOverDays
          }

          if (!entitiesMap[e.id]) {
            entitiesMap[e.id] = e;
          } else {
            entitiesMap[e.id] = {
              ...entitiesMap[e.id],
              schedule: [
                {
                  ...entitiesMap[e.id].schedule[0],
                  bookings: [
                    ...entitiesMap[e.id].schedule[0].bookings,
                    ...e.schedule[0].bookings
                  ],
                  changeOverDays: initial ? changeOverDays[e.id] : oldChangeOverDays[e.id]
                }
              ]
            }
          }
        };

        results.forEach(res => res.entities.forEach(entityFn));

        return {
          entities: {
            ...getState().calendarEntities.data?.entities,
            [`${country}-${year}-${month}`]: results[0].entities.map(e => entitiesMap[e.id])
          },
          changeOverDays: initial ? { ...oldChangeOverDays, ...changeOverDays } : oldChangeOverDays,
          message: 'Success'
        }

      })()
    )
  );
};

export default getEntitiesForCalendar;
