import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'PROVISIONS';
const createProvisionsActions = makeCreateActions(ns);

const provisionsActions = createProvisionsActions({
  getAll: presetActions.makeAsyncAction(`${ns}/GET_ALL`),
});

export default provisionsActions;
