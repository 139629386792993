import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { pxToRem } from '../../../themeConfig/typography';

type Props = {
  isMobile: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: '100%',
      height: 'auto',
    },
    rightContainer: {
      maxHeight: '90%',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: '5%',
        paddingRight: '5%',
        marginTop: '3rem',
      },
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: '10%',
        paddingRight: '10%',
      },
    },
    followDesktop: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 32,
      },
    },
    title: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    },
    hashtags: {
      // fontSize: pxToRem(16),
      // lineHeight: '22px',
    },
    followButton: ({ isMobile }: Props) => ({
      width: isMobile ? '100%' : 200,
    }),
  })
);
