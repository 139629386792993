import React, { FC, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { Checkbox, HorizontalLine, Typo, InputField } from '../../primitives';
import { ProvisionAccordionProps } from './ProvisionAccordion.props';
import { useStyles } from './ProvisionAccordion.styles';
import { InputAdornment } from '@material-ui/core';

const ProvisionAccordionView: FC<ProvisionAccordionProps> = ({
  name,
  description = '',
  provider = '',
  yieldRange = '',
  isQuantityOne = false,
  price,
  provisionPrice,
  collapsed,
  onUpdate,
  quantity = '0',
  maxPurchasable
}) => {
  const [isIn, setIsIn] = useState(collapsed);
  const [currentQuantity, setCurrentQuantity] = useState(quantity);
  const [checkboxDisabled, setCheckboxDisabled] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (isQuantityOne && Number(quantity) > 0) setCheckboxDisabled(true);
  }, [])

  const onChange = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { target } = event;
    let { value: amount } = target;
    const max = Number(maxPurchasable);
    if (!isNaN(max) && max > 0 &&  Number(amount) > max) {
      amount = maxPurchasable + '';
    }

    if (isQuantityOne) {
      // @ts-ignore
      if (target.checked) onUpdate(name, price, '1');
      else onUpdate(name, price, '0');

      // @ts-ignore
      setCurrentQuantity(target.checked ? 1 : 0);
    } else if (Number(amount) < Number(quantity)) {
      setCurrentQuantity(quantity);
    } else {
      setCurrentQuantity(amount);
      onUpdate(name, price, amount);
    }
  };

  return (
    <Box>
      <Box className={classes.shown}>
        <Typo variant="h5" style={{ width: '40%' }}>
          {name}
        </Typo>

        <Box className={classes.secondRow} onClick={() => setIsIn(!isIn)}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typo variant="h5">
              {provisionPrice} {!isQuantityOne ? 'x' : ''}
            </Typo>

            {isQuantityOne ? (
              <Box ml={1}>
                <Checkbox
                  id={name}
                  value={name}
                  checked={Number(currentQuantity) > 0}
                  onChange={onChange}
                  isSmall={true}
                  disabled={checkboxDisabled}
                />
              </Box>
            ) : (
              <Box
                ml={1}
                style={{
                  width: '50px',
                }}
              >
                <InputField
                  id={name}
                  placeholder="0"
                  label=""
                  onChange={onChange}
                  value={currentQuantity}
                  type="number"
                  inputProps={{
                    min: quantity,
                  }}
                  endAdornment={
                    maxPurchasable ? <InputAdornment position="end">/{maxPurchasable}</InputAdornment> : undefined
                  }
                /> 
              </Box>
            )}
          </Box>
          <Box ml={1} pt={1}>
            {isIn ? (
              <ExpandLess color="secondary" />
            ) : (
              <ExpandMore color="secondary" />
            )}
          </Box>
        </Box>
      </Box>
      <Box mt={0.5} mb={2}>
        <HorizontalLine className={classes.line} />
      </Box>
      <Collapse in={isIn}>
        {provider && (
          <Box mb={0.5} pl={5} style={{ marginBottom: 16 }}>
            <Typo variant="body2">{`by ${provider}`}</Typo>
          </Box>
        )}
        {description
          .split('\n')
          .filter((d) => d.length > 0)
          .map((d) => (
            <Box key={d} mb={0.5} pl={5}>
              <Typo variant="body2">{d}</Typo>
            </Box>
          ))}
        {yieldRange && (
          <Box mb={0.5} pl={5} style={{ marginTop: 16 }}>
            <Typo variant="body2">{`Yield: ${yieldRange}`}</Typo>
          </Box>
        )}
      </Collapse>
    </Box>
  );
};

export default React.memo(ProvisionAccordionView);
