import contact from './contact';
import subscribe from './subscribe';
import waitlist, { waitlistForm } from './waitlist';

const commonThunks = {
  subscribe,
  waitlist,
  contact,
  waitlistForm,
};

export default commonThunks;
