import { FC, ReactElement, useState } from 'react';
import React from 'react';
import Box from '@material-ui/core/Box';
import { AlgoliaSearchProps } from './AlgoliaSearch.props';
import { useStyles } from './AlgoliaSearch.styles';
import { SearchBox, SearchBoxProps, Hits, useHits } from 'react-instantsearch';

let timer: any;
const AlgoliaSearchView: FC<AlgoliaSearchProps> = (
  props: AlgoliaSearchProps
) => {
  const classes = useStyles({});
  const [isOpen, setIsOpen] = useState(false);

  const onSearch: SearchBoxProps['queryHook'] = (
    query: string,
    search: (x: string) => void
  ) => {
    setIsOpen(!!query);
    clearTimeout(timer);
    timer = setTimeout(() => {
      search(query);
    }, 500);
  };

  return (
    <Box position="relative">
      <SearchBox
        placeholder="Search for an Article"
        queryHook={onSearch}
        classNames={{
          root: classes.searchBoxRoot,
          input: classes.searchBox,
          submit: classes.searchBoxButton,
          reset: classes.searchBoxButton,
          loading: classes.searchBoxButton,
        }}
      />
      {isOpen ? <CustomHits classes={classes} /> : null}
    </Box>
  );
};

export default AlgoliaSearchView;

const CustomHits: React.FC<{ classes: any }> = (props) => {
  const s = props.classes;
  const { items, results, banner, sendEvent } = useHits();

  console.log(items);
  return (
    <div className={s.results}>
      <div className={s.resultsList}>
        {!items?.length ? (
          <Box
            className={s.resultItem}
            style={{
              pointerEvents: 'none',
            }}
          >
            <h3>No Results Found</h3>
          </Box>
        ) : (
          items.map((hit) => {
            if (!hit.slug && !hit.externalUrl) return null;
            return (
              <a
                href={hit?.slug ? `journal/${hit?.slug}` : hit?.externalUrl}
                target={hit?.externalUrl ? '_blank' : '_self'}
              >
                <div className={s.resultItem} title={hit?.title}>
                  <h3>{hit?.title}</h3>
                  <p>{hit?.subtitle}</p>
                </div>
              </a>
            );
          })
        )}
      </div>
    </div>
  );
};
