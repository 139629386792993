import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';
import { ButtonPublicProps } from './Button.props';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: 'none',
      width: '100%',
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    text: {
      textAlign: 'left',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      flex: 1,
    },
    container: ({ hideUnderline, reverseUnderline, color, underlineOnHover }) => ({
      display: 'flex',
      flexDirection: 'row',
      paddingBottom:
        !hideUnderline && !reverseUnderline ? theme.spacing(0.5) : 0,
      paddingTop: reverseUnderline ? theme.spacing(0.5) : 0,
      borderBottom: !hideUnderline && !reverseUnderline ? '2px solid' : 'none',
      borderTop: reverseUnderline ? '2px solid' : 'none',
      // @ts-ignore
      color: colors[color] || colors.primaryDarkGreen,
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      '&:disabled': {
        cursor: 'none',
      },
      ...(underlineOnHover ? {
        '&:hover': {
          borderBottom: `3px solid ${colors[color ?? 'primaryYellow']}`
        }
      } : {})
      
    }),
    iconContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 15,
    },
    icon: {
      color: ({ color }: ButtonPublicProps) =>
        // @ts-ignore
        colors[color] || colors.primaryDarkGreen,
      padding: 0,
    },
  })
);
