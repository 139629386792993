import React, { FC } from 'react';

import ReactPlayer from 'react-player';

import { VideoPlayerProps } from './VideoPlayer.props';

const VideoPlayerView: FC<VideoPlayerProps> = (props) => {
  const {
    controls = true,
    light = false,
    width = '100%',
    height = '100%',
    className,
  } = props;

  return (
    <ReactPlayer
      {...props}
      controls={controls}
      light={light}
      width={width}
      height={height}
      className={className}
    />
  );
};

export default React.memo(VideoPlayerView);
