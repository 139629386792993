import { Action } from 'types/Action';
import { RouteDef } from 'types/RouteDef';

const link: Function = <P>(nextRoute: RouteDef, payload: P): Action<P> => {
  // window.analytics.track('Link Clicked', {
  //   click_text: nextRoute.id,
  //   click_url: nextRoute.path,
  // });

  return {
    type: `ROUTER/${nextRoute.id}`,
    payload,
  };
};

export default {
  link,
};
