import React, { FC, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { NewsletterImage } from '../../../assets/images';
import {
  Typo,
  SubmitField,
  ImageContainer,
  Link,
  TextButton,
} from '../../primitives';
import { NewsletterProps } from './Newsletter.props';
import { useStyles } from './Newsletter.styles';

const NewsletterView: FC<NewsletterProps> = (props) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const { onSubmit, loading, success, data } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    if (error) setError('');
    setValue(target.value);
  };

  const onEmailSubmit = () => {
    // TODO: error states
    if (!value) setError('Please enter email');
    onSubmit(value);
  };
  return (
    <Grid container>
      {!isMobile && (
        <Grid item xs={12}>
          <Box mb={3}>
            <Typo variant="h3" className={classes.joinDesktop}>
              {data.title}
            </Typo>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <ImageContainer
          alt="newsletter"
          src={data.banner || NewsletterImage}
          className={classes.image}
        />
      </Grid>
      {isMobile && (
        <Grid item xs={12}>
          <Box mb={3}>
            <Typo variant="h6">{data.title}</Typo>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} md={6} className={classes.rightContainer}>
        <Box mb={isMobile ? 5 : 8}>
          <Typo
            variant={isMobile ? 'h3' : 'h1'}
            className={classes.description}
          >
            {data.description ||
              'We’re waging a war on “busy” and we’re looking for recruits.'}
          </Typo>
        </Box>

        <SubmitField
          endAdornmentType="submit"
          placeholder="Enter email"
          type="email"
          value={success ? 'Thanks for joining the movement!' : value}
          loading={loading}
          success={success}
          error={error}
          onChange={onChange}
          onSubmit={onEmailSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(NewsletterView);
