import React, { FC } from 'react';

import { ImageAndTextCarouselPublicProps } from './ImageAndTextCarousel.props';
import View from './ImageAndTextCarousel.view';

const ImageAndTextCarouselContainer: FC<ImageAndTextCarouselPublicProps> = (
  props
) => {
  const crossedOutText = 'hotel';

  return (
    <View
      {...props}
      {...{
        crossedOutText,
      }}
    />
  );
};

export default React.memo(ImageAndTextCarouselContainer);
