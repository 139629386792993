import React, {
  FC,
  useEffect,
  useState,
} from 'react';

import {
  useMediaQuery,
  useTheme,
  Box,
  Divider,
  Button as MUIButton,
} from '@material-ui/core';
// import Divider from '@material-ui/core/Divider';
import {
  Button,
  Checkbox,
  Modal,
  Select,
  Typo,
} from 'components/primitives';

import { FilterModalProps } from './FilterModal.props';
import { useStyles } from './FilterModal.styles';
import CloseIcon from '@material-ui/icons/Close';
import { CabinFilter } from 'types/CabinFilter';
import { colors } from 'themeConfig/themeConstants';
import { useDispatch } from 'react-redux';
import { cabinFilterActions } from 'store/actions';
import { triggerProductListFilter } from 'hooks/useClickTrack';

const FilterModalView: FC<FilterModalProps> = (props: FilterModalProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile, closeIconLeft: false });

  const { filter, filtered, filterCabins, cabinFilter } = props;

  const [open, setOpen] = useState(props.open ?? false);
  const [loading, setLoading] = useState(false);
  const [petFriendly, setPetFriendly] = useState<CabinFilter['petFriendly']>(cabinFilter.petFriendly);
  const [adventureLevel, setAdventureLevel] = useState(cabinFilter.adventureLevel ?? [])
  const [reception, setReception] = useState<CabinFilter['reception']>(cabinFilter.reception)
  const [singleWeekNight, setSingleWeekNight] = useState<CabinFilter['singleWeekNight']>(cabinFilter.singleWeekNight);

  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(props?.open ?? true);
  }, [props?.open]);

  useEffect(() => {
    filterCabins({
      ...cabinFilter,
      petFriendly,
      adventureLevel,
      reception,
      singleWeekNight,
    })
  }, [props?.open, petFriendly, adventureLevel, reception, singleWeekNight])

  const setLevel = (value: 'mild' | 'medium' | 'spicy', checked: boolean) => {
    let index = adventureLevel.indexOf(value);
    if (index > -1) {
      if (!checked) {
        return [ ...adventureLevel.filter(v => v != value) ];
      }
    } else {
      if (checked) return [ ...adventureLevel, value ];
    }

    return adventureLevel;
  }
  
  return (
    <Modal
      open={open}
      rootStyle={classes.root}
      paperStyle={classes.paper}
      titleContainerStyle={classes.title}
      contentContainerStyle={classes.content}
      onBackdropClick={() => props?.setOpen(false)}
      onClose={(open) => props?.setOpen(open)}
      noClose={false}
      disableLogo
      title={
        <Box pl={isMobile ? 0 : 2} style={{ display: 'flex', flexDirection: 'row' }}>
          <Typo color="primaryWhite" align="center" variant={isMobile ? 'h4' : 'h3'}>
            Select the filters to refine your choice
          </Typo>
          <Box style={{ color: colors.primaryWhite, position: 'absolute', right: 16 }}>
            <CloseIcon onClick={(() => props.setOpen(false))} />
          </Box>
        </Box>
        
      }
    >
      <Box
        pl={2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          fontSize: isMobile ? '0.85rem' : '1rem',
        }}
      >
        <Box width="98%">
          <Box mb={1} />
          <Typo variant={"h5"}>Bring your four-legged friend</Typo>
          <Typo variant={"body3"} style={{ fontSize: '14px'}}>
            Select for cabins where you can bring your furry friend for a fee.
          </Typo>
          <Box mb={2} />
          <Checkbox 
            checked={petFriendly == 'Pet-friendly'} onChange={(_e, checked) => setPetFriendly(checked ? 'Pet-friendly' : undefined)}
            label={'Pet-friendly'} variant={'h6'} radius='small' />
          <Box mb={4} />
          <Typo variant={"h5"}>Adventure levels</Typo>
          <Typo variant={"body3"} style={{ fontSize: '14px'}}>
            Adventure levels reflect the immersion in nature and the difficulty of the hike to cabin.
          </Typo>
          <Box mb={2} />
          <Checkbox
            checked={adventureLevel.indexOf('mild') > -1} onChange={(e, checked) => setAdventureLevel(setLevel('mild', checked))}
            variant={'h6'} radius='small' label={'Mild easy-entry cabins'} />
          <Box mb={1} />
          <Checkbox
            checked={adventureLevel.indexOf('medium') > -1} onChange={(e, checked) => setAdventureLevel(setLevel('medium', checked))}
            variant={'h6'} radius='small' label={'Medium Spice cabins with a longer walk'} />
          <Box mb={1} />
          <Checkbox 
            checked={adventureLevel.indexOf('spicy') > -1} onChange={(e, checked) => setAdventureLevel(setLevel('spicy', checked))}
            variant={'h6'} radius='small' label={'Spicy or Extra Spicy cabins with challenging walks'} />
          <Box mb={4} />
          <Typo variant={"h5"}>Reception</Typo>
          <Typo variant={"body3"} style={{ fontSize: '14px'}}>
            When you want to be fully detached, select our cabins with no reception at all.
          </Typo>
          <Box mb={2} />
          <Checkbox 
            checked={reception == 'reception'} onChange={(e, checked) => setReception(checked ? 'reception' : undefined)} 
            variant={'h6'} radius='small' label={'I need reception'} />
          <Box mb={1} />
          <Checkbox 
            checked={reception == 'no reception'} onChange={(e, checked) => setReception(checked ? 'no reception' : undefined)}
            variant={'h6'} radius='small' label={'No reception for me'} />
          <Box mb={4} />
          <Typo variant={"h5"}>One night only</Typo>
          <Typo variant={"body3"} style={{ fontSize: '14px'}}>
            Our cabins have a two night minimum over weekends, select to find single week night stays.
          </Typo>
          <Box mb={2} />
          <Checkbox 
            checked={singleWeekNight == 'Single weeknight'} onChange={(_e, checked) => setSingleWeekNight(checked ? 'Single weeknight' : undefined)}
            variant={'h6'} radius='small' label={'Accept single weeknight stays'} />
          <Box mb={3} />
          <Box
            mb={isMobile ? 0 : 3}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Box mt={1}>
              <Button 
                hideIcon variant={'body3'} text="Clear all" 
                onClick={() => {
                  setPetFriendly(undefined);
                  setAdventureLevel([]);
                  setReception(undefined)
                  setSingleWeekNight(undefined)
                }}  
              />
            </Box>
            
            
            {filtered?.length ? (
              <MUIButton 
                variant="contained" className={classes.showButton}
                disabled={loading}
                onClick={() => {

                  setLoading(true)

                  const newFilter = {
                    petFriendly,
                    adventureLevel,
                    reception,
                    singleWeekNight,
                  }

                  dispatch(cabinFilterActions.updated(newFilter))
                  triggerProductListFilter({ ...filter, ...cabinFilter, ...newFilter })

                  setOpen(false);
                  props.setOpen(false);
                  setTimeout(() => setLoading(false), 2000)
                }}>
                {loading ? `Loading ...` : `Show ${filtered?.length} cabins`}
              </MUIButton>
            ) : (
              <MUIButton 
                style={{ position: 'absolute', right: 40, color: colors.primaryRed }}
                variant="outlined" color="secondary" disabled
              >
                No Cabin Options
              </MUIButton>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default FilterModalView;
