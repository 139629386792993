import React, { FC, useEffect, useRef, useState } from 'react';

import { remedyKitUpgrade, doggo } from 'consts';
import { CURRENCY_SYMBOL } from 'consts/locations';
import moment from 'moment';
import has from 'ramda/src/has';
import isEmpty from 'ramda/src/isEmpty';
import pathOr from 'ramda/src/pathOr';
import { useDispatch } from 'react-redux';
import { FilterState } from 'types/store/FilterState';

import ROUTES from '../../../routes';
import { routerActions } from '../../../store/actions';
import useSelectorSafe from '../../../store/selectors/useSelectorSafe';
import {
  getTotalStay,
  getWeekendStay,
  getTotalStayPrice,
  getGST,
  getTotalProvisionPrice,
  getTotalPrice,
  getPercentDiscountedPrice,
  getEventsSurcharge,
  getMinorValue,
} from '../../../utils/Data/pricing/pricing';
import { HousePaymentsPublicProps } from './HousePayments.props';
import View from './HousePayments.view';

const HousePaymentsContainer: FC<HousePaymentsPublicProps> = (props) => {
  const {
    checkin,
    checkout,
    timezone,
    houseCard,
    guests,
    voucher,
    bookingData,
    isRedirect,
  } = props;
  const dispatch = useDispatch();

  const currentEntity = useSelectorSafe(
    (state) => state.currentEntity?.data?.entity
  );

  const filterData = useSelectorSafe<FilterState>(
    (state) => state.filter,
    null
  );

  const paymentAfterpay = useSelectorSafe((state) => state.paymentAfterpay);

  const afterpay = paymentAfterpay?.data;

  const trackCheckoutStep = (step: string, paymentMethod?: string) => {
    if (!currentEntity) return;
    window.analytics.track('Checkout Step Viewed', {
      checkout_step: step,
      shipping_method: '-',
      ...(paymentMethod ? { payment_method: paymentMethod } : {}),
    });
  };

  // @ts-ignore
  const { bookingMetadata }: { bookingMetadata: {} } = bookingData;
  const redirectStep = pathOr(0, ['redirectStep'], bookingData);
  const redirectNotes = pathOr('', ['redirectNotes'], bookingData);
  const redirectProvisions = pathOr([], ['redirectProvisions'], bookingData);
  const addProvisions = pathOr(false, ['addProvisions'], bookingData);
  const bookingNotes = pathOr('', ['notes'], bookingMetadata);

  const [checkedProvisions, setCheckedProvisions] = useState<
    {
      text: string;
      value: number;
      quantity: number;
    }[]
  >(redirectProvisions);

  const [initialProvTotal, setInitialProvTotal] = useState<number>(0);

  const [currentGuests, setCurrentGuests] = useState(guests);
  const [notes, setNotes] = useState(redirectNotes || bookingNotes);

  const { weeknightSingle, weeknights, weekend } = houseCard.pricing;
  const { acceptSingleNight, currency } = houseCard;
  const currencySymbol = CURRENCY_SYMBOL[currency];

  const totalStay = getTotalStay(checkin, checkout);
  const weekendCount = getWeekendStay(checkin, checkout);
  const weeknightCount = totalStay - weekendCount;
  const provTotal = getTotalProvisionPrice(checkedProvisions, currency);

  const cabinTotal = getTotalStayPrice(
    { weeknightSingle, weeknights, weekend },
    totalStay,
    weekendCount,
    acceptSingleNight,
    currency
  );

  const fromDate = moment(checkin);
  const toDate = moment(checkout);
  const diff = toDate.diff(fromDate, 'days');
  const dateRangeArr: string[] = [];

  for (let i = 0; i <= diff; i++) {
    dateRangeArr.push(moment(checkin).add(i, 'days').format('YYYY-MM-DD'));
  }

  const eventsSurcharge = houseCard.surcharges
    .map(({ amount, name, start_date, end_date }) => {
      let surchargeFlag = false;
      const startDate = moment(start_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
      const endDate = moment(end_date, 'DD/MM/YYYY').format('YYYY-MM-DD');

      for (let i = 0; i < dateRangeArr.length; ++i) {
        const isDateInBetween = moment(dateRangeArr[i], 'YYYY-MM-DD').isBetween(
          startDate,
          endDate,
          undefined,
          '[]'
        );

        if (isDateInBetween) {
          surchargeFlag = true;
          break;
        }
      }

      if (surchargeFlag == true) {
        return {
          text: name,
          value: amount,
        };
      }

      return {
        text: name,
        value: 0,
      };
    })
    .filter((eventSurcharge) => {
      if (eventSurcharge.value > 0) {
        return true;
      }

      return false;
    });

  const eventsTotal = getTotalPrice(
    eventsSurcharge.map(({ value }) => value),
    currency
  );

  let voucherDiscount = 0;
  if (voucher && (voucher.type == 'gift_card' || !afterpay)) {
    voucherDiscount = voucher.granted_discount;
  }

  const totalPrice = getTotalPrice(
    [cabinTotal, provTotal, eventsTotal],
    currency
  );

  let entityDiscount = 0;
  let entityDiscountValue;
  const discount = currentEntity?.discounts?.find(
    (d) => d.nights_applicable === totalStay
  );

  if (discount) {
    if (discount.discount_symbol === '%') {
      entityDiscount = totalPrice * (discount.discount_value / 100);
      entityDiscountValue = `${discount.discount_value}${discount.discount_symbol}`;
    } else {
      entityDiscount = discount.discount_value;
      entityDiscountValue = `${discount.discount_symbol}${discount.discount_value}`;
    }
  }

  const total = getTotalPrice(
    [
      cabinTotal,
      provTotal,
      eventsTotal,
      voucherDiscount > 0 ? -voucherDiscount : 0,
      entityDiscount > 0 ? -entityDiscount : 0,
    ],
    currency
  );

  const gstTotal = getGST(total, currency);

  let totalPriceText = `${currencySymbol}${weeknights} x ${weeknightCount} night${
    weeknightCount > 1 ? 's' : ''
  }`;
  if (weeknightCount === 0 && weekendCount > 0)
    totalPriceText = `${currencySymbol}${weekend} x ${weekendCount} weekend`;
  else if (weekendCount > 0)
    totalPriceText = `${totalPriceText}<br/>${currencySymbol}${weekend} x ${weekendCount} weekend`;
  else if (weeknightCount === 1)
    totalPriceText = `${currencySymbol}${weeknightSingle} x ${weeknightCount} night`;

  const pricesList = [
    {
      text: totalPriceText,
      value: cabinTotal,
    },
    ...(discount && entityDiscount
      ? [
          {
            text: `${entityDiscountValue} ${discount.rule_name}`,
            value: entityDiscount,
          },
        ]
      : []),
    ...(voucher && voucherDiscount
      ? [
          {
            text: `Voucher ${voucher.code}`,
            value: voucher.granted_discount,
          },
          ...(voucher.provisions || []).map((v) => ({
            text: `${v.name} x ${v.quantity}`,
            value: 0,
          })),
          ...voucher.addons
            .filter(
              (v) =>
                v.value !== remedyKitUpgrade.price.toString() &&
                v.text !== remedyKitUpgrade.name
            )
            .map((v) => ({
              text: `${v.text} x ${v.quantity}`,
              value: 0,
            })),
        ]
      : []),
    ...checkedProvisions,
  ];

  let taxAndTotal = [
    {
      text: currency == 'GBP' ? 'VAT' : 'GST',
      value: gstTotal > 0 ? gstTotal : 0,
    },
    { text: `Total (${houseCard.currency})`, value: total > 0 ? total : 0 },
  ];

  let totalProvPrice = 0;
  if (addProvisions) {
    totalProvPrice = getMinorValue(provTotal, currency)
      .subtract(getMinorValue(initialProvTotal, currency))
      .toUnit();

    taxAndTotal = [
      ...taxAndTotal,
      {
        text: `Payment Outstanding (${houseCard.currency})`,
        value: totalProvPrice,
      },
    ];
  }

  console.log({
    pricesList,
    eventsSurcharge,
    taxAndTotal,
  });
  const priceDetails = [...pricesList, ...eventsSurcharge, ...taxAndTotal];

  const houseRulesData = pathOr({}, ['houseRules'], houseCard);
  const petsAreAllowed = pathOr(false, ['Pet Friendly'], houseRulesData);

  const houseRules: [string, boolean][] =
    Object.keys(houseRulesData).length > 0
      ? Object.keys(houseRulesData).map((key: string) => {
          // @ts-ignore
          return [key, houseRulesData[key]];
        })
      : [];
  const hasAddons = has('addons');

  const { addons } = pathOr([], ['options'], houseCard).find(hasAddons) || {
    addons: [],
  };
  const bookingAddon = pathOr([], ['bookingMetadata', 'addons'], bookingData);

  const specialAddons: any[] = addons
    .filter((a: { active: string; type: string }) => {
      return (
        petsAreAllowed &&
        a.active.toString() === 'true' &&
        a.type.toLowerCase() === doggo.name.toLowerCase()
      );
    })
    .map((a: any) => {
      const ba = bookingAddon.find((b: any) => b.id === a.id);
      const cp = (a.countryPrices || []).find(
        (c: any) => c && c.currency_code === currency
      );

      let countryPrice = cp && cp.price;
      if (totalStay && cp.per_night_prices) {
        for (const night of cp.per_night_prices) {
          if (night.night_count == totalStay) {
            countryPrice = night.price;
            break;
          }
        }
      }

      return {
        ...a,
        price: (cp && countryPrice) || a.price,
        currency: currencySymbol,
        quantity: pathOr(0, ['quantity'], ba),
      };
    });

  const provisionAddons = addons
    .filter(
      (a: { active: string; type: string; entities: { label: string }[] }) => {
        const isItem =
          a.active.toString() === 'true' &&
          ['item', 'package'].indexOf(a.type) > -1;

        if (isItem && a.entities) {
          for (const entity of a.entities) {
            if (currentEntity && entity.label == currentEntity.id) {
              return true;
            }
          }
        }

        return false;
      }
    )
    .map((a: any) => {
      const cp = (a.countryPrices || []).find(
        (c: any) => c && c.currency_code === currency
      );

      let countryPrice = cp && cp.price;
      if (totalStay && cp.per_night_prices) {
        for (const night of cp.per_night_prices) {
          if (night.night_count == totalStay) {
            countryPrice = night.price;
            break;
          }
        }
      }

      return {
        ...a,
        currency: currencySymbol,
        price: (cp && countryPrice) || a.price,
      };
    });

  let finalAddons: any[] = provisionAddons;
  const preselectedProvisionId = pathOr('', ['id'], props.preselectedProvision);

  if (!isEmpty(bookingAddon)) {
    finalAddons = provisionAddons.map((a: any) => {
      const ba = bookingAddon.find((b: any) => b.id === a.id);

      return {
        ...a,
        quantity: pathOr(0, ['quantity'], ba),
      };
    });
  }

  const initialAddons = [...finalAddons];

  if (!isEmpty(redirectProvisions)) {
    finalAddons = finalAddons.map((a: any) => {
      const rp = redirectProvisions.find((r: any) => r.text.includes(a.name));
      return {
        ...a,
        quantity: pathOr(0, ['quantity'], rp),
      };
    });
  }

  if (preselectedProvisionId) {
    finalAddons = finalAddons.map((a: any) => {
      if (a.id === preselectedProvisionId) {
        return {
          ...a,
          quantity: Number(pathOr(0, ['quantity'], a)) + 1,
        };
      }
      return a;
    });
  }

  const changeNameToUrl = (name: string) => {
    const replaced = name.toLowerCase().replace(/\s/g, '-');

    return `/${replaced}`;
  };

  useEffect(() => {
    if (currentEntity && !isRedirect) {
      window.analytics.track('Checkout Started', {
        affiliation: 'Unyoked',
        value: total,
        revenue: total,
        tax: gstTotal,
        shipping: 0,
        coupon: voucher?.id,
        discount: voucherDiscount,
        products: [
          {
            product_id: currentEntity.id,
            category: 'Cabin',
            name: currentEntity.title,
            brand: 'unyoked',
            price: cabinTotal / totalStay,
            quantity: totalStay,
            url: `${changeNameToUrl(currentEntity.title)}`,
            image_url: currentEntity.metadata.images?.[0],
          },
        ],
      });
    }

    if (!isEmpty(bookingAddon) || preselectedProvisionId) {
      if (!preselectedProvisionId) {
        const ip = [...initialAddons, ...specialAddons]
          .filter((fa) => parseInt(fa.quantity + '', 10) > 0)
          .map((fa): { text: string; value: number; quantity: number } => ({
            text: fa.name,
            value: Number(fa.price),
            quantity: parseInt(fa.quantity, 10),
          }));

        setInitialProvTotal(getTotalProvisionPrice(ip, currency));
      }
    }

    const cp = [...finalAddons, ...specialAddons]
      .filter((fa) => parseInt(fa.quantity + '', 10) > 0)
      .map((fa): { text: string; value: number; quantity: number } => ({
        text: fa.name,
        value: Number(fa.price),
        quantity: parseInt(fa.quantity, 10),
      }));

    setCheckedProvisions(cp);
  }, [currentEntity]);

  const profile = useSelectorSafe((state) => state.profile);
  const user = useSelectorSafe((state) => state.user);
  const email = pathOr('', ['data', 'user', 'email'], user);

  const profileData = pathOr(
    {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      address: '',
      city: '',
      state: '',
      postCode: '',
    },
    ['data', 'profile'],
    profile
  );

  const initialUserValues = {
    ...{
      firstName: profileData.firstName || '',
      lastName: profileData.lastName || '',
      address: profileData.address || '',
      city: profileData.city || '',
      state: profileData.state || '',
      postCode: profileData.postCode || '',
    },
    email,
    mobile: profileData.phoneNumber || '',
  };

  return (
    <View
      {...props}
      total={total}
      totalPrice={totalPrice}
      totalProvPrice={totalProvPrice}
      priceDetails={priceDetails}
      nights={totalStay}
      houseRules={houseRules}
      specialAddons={specialAddons}
      addons={finalAddons}
      currentGuests={currentGuests}
      setCurrentGuests={setCurrentGuests}
      notes={notes}
      setNotes={setNotes}
      checkedProvisions={checkedProvisions}
      setCheckedProvisions={setCheckedProvisions}
      bookingDetails={
        addProvisions
          ? {
              id: pathOr('', ['bookingId'], bookingData),
              checkin,
              checkout,
            }
          : { checkin, checkout, id: houseCard.id, timezone }
      }
      voucherType={voucher ? voucher.type : ''}
      voucherId={voucher ? voucher.id : ''}
      voucherDiscount={voucherDiscount}
      discount={`${entityDiscountValue} ${discount?.rule_name}`}
      discountValue={entityDiscount}
      initialUserValues={initialUserValues}
      isLoggedIn={!!useSelectorSafe((state) => state.token)}
      goToLogin={() =>
        dispatch(
          routerActions.link(ROUTES.LOGIN_SIGNUP, {
            redirectTo: ROUTES.HOUSE_PAYMENTS,
            redirectPayload: {
              isRedirect: true,
              id: houseCard.id,
              name: houseCard.name,
              data: {
                startDate: checkin,
                endDate: checkout,
                guests: currentGuests,
                redirectStep: 1,
                redirectNotes: notes,
                redirectProvisions: checkedProvisions,
                voucherCode: voucher ? voucher.code : undefined,
              },
            },
          })
        )
      }
      goToResults={() => {
        const options: any = { country: filterData?.location?.country };
        if (filterData?.location?.address)
          options.location = filterData.location.address;
        dispatch(routerActions.link(ROUTES.RESULTS, options));
      }}
      goToCabin={() =>
        dispatch(
          routerActions.link(ROUTES.RESULTS_ITEM, { cabin: houseCard.name })
        )
      }
      hasAddedProvisions={provTotal - (initialProvTotal || 0) !== 0}
      redirectStep={redirectStep}
      trackCheckoutStep={trackCheckoutStep}
      pricePerNight={cabinTotal / totalStay}
    />
  );
};

export default React.memo(HousePaymentsContainer);
