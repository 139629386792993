import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface StyleProps {
  isMobile: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    provisionsContainer: {
      marginTop: ({ isMobile }: StyleProps) => theme.spacing(isMobile ? 8 : 16),
      paddingLeft: '5%',
      paddingRight: '5%',
      [theme.breakpoints.up(1440)]: {
        paddingLeft: '8%',
        paddingRight: '8%',
      },
    },
    mobileContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '7%',
      '& > .MuiBox-root': {
        width: '100%',
      },
    },
    card: {
      marginBottom: '7%',
      paddingLeft: '2%',
      paddingRight: '2%',
    },
    title: {
      paddingLeft: '2%',
      [theme.breakpoints.up(1440)]: {
        fontSize: 32,
      },
    },
    select: {
      paddingLeft: '2%',
      width: '40%',
    },
  })
);
