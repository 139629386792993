import { Dispatch } from 'redux';
import { getBookingQuestionsEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import hubspotActions from 'store/actions/hubspot';

import { Store } from '../../types/store/Store';

const getBookingQuestions =
  () => (dispatch: Dispatch, getState: () => Store) => {
    dispatch(
      hubspotActions.getBookingQuestions(
        apiFetch<{}, any>(getBookingQuestionsEndpoint, {})(dispatch, getState).catch(err => {
        return {
            questions: {
              booking_questions_city: {
                label: 'Which is your closest city?',
                description: 'Select one',
              },
              booking_questions_country: {
                label: 'Which country are you in?',
                description: 'Select one',
              },
              booking_questions_date: {
                label: 'Choose an available date - check-in dates shown first',
                description:
                  'Click once for check-in, click again for check-out',
              },
              booking_questions_guests: {
                label: 'Select guests and nights?',
                description: 'How many people and how long?',
              },
              booking_questions_nights: {
                label: 'Select nights',
                description: '',
              },
            }
        }
      })
        //apiFetch<{}, any>(getBookingQuestionsEndpoint, {})(dispatch, getState)
      )
    );
  };

export default getBookingQuestions;
