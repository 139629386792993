import React, { FC } from 'react';

import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';

import ROUTES from '../../../routes';
import {
  authActions,
  routerActions,
  tokenActions,
} from '../../../store/actions';
import { AccountPublicProps, AccountProps } from './Account.props';
import View from './Account.view';
import authThunks from 'thunks/auth';

const AccountContainer: FC<AccountPublicProps> = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelectorSafe(
    (state) => state.user.data?.user,
    undefined
  );

  const settings = [
    {
      text: 'Personal Info',
      subtitle: 'Provide personal details and how we can reach you',
      onClick: () => dispatch(routerActions.link(ROUTES.ACCOUNT_PERSONAL_INFO)),
    },
    {
      text: 'Communications Preference',
      subtitle: 'Update your mailing list preferences',
      onClick: () => dispatch(routerActions.link(ROUTES.ACCOUNT_PREFERENCE_CENTRE)),
    },
    {
      text: 'Booking History',
      subtitle: 'Archive of your past and upcoming bookings',
      onClick: () =>
        dispatch(routerActions.link(ROUTES.ACCOUNT_BOOKING_HISTORY)),
    },
    {
      text: 'Payment Methods',
      subtitle: 'Archive and edit your payment methods',
      onClick: () =>
        dispatch(routerActions.link(ROUTES.ACCOUNT_PAYMENT_METHODS)),
    },
    {
      text: 'Privacy and Security Settings',
      subtitle: 'Update your password and review your privacy preferences',
      onClick: () =>
        dispatch(routerActions.link(ROUTES.ACCOUNT_SECURITY_SETTINGS)),
    },
    {
      text: 'Log Out',
      subtitle: 'Sign out of your account',
      onClick: () => {
        window.analytics.track('User Logged Out', {
          id: currentUser?.id,
          email: currentUser?.email,
        });
        window.analytics.reset();
        dispatch(authThunks.logout());
        dispatch(tokenActions.clear());
        setTimeout(() => dispatch(routerActions.link(ROUTES.ROOT)), 1000);
      },
    },
  ];

  const calcedProps: AccountProps = {
    ...props,
    settings,
  };

  return <View {...calcedProps} settings={settings} />;
};

export default React.memo(AccountContainer);
