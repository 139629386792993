import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';
import { pxToRem } from '../../../themeConfig/typography';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 46,
      },
    },
    ghostButton: {
      width: 200,
    },
    arrow: {
      fontSize: pxToRem(12),
    },
    horizontalLine: {
      backgroundColor: `${colors.primaryDarkGreen} !important`,
      height: 2.5,
    },
    cardUnderline: {
      backgroundColor: `${colors.primaryDarkGreen} !important`,
      height: 2,
    },
    contentContainer: {
      marginTop: theme.spacing(5),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(10),
        paddingLeft: '5%',
        paddingRight: '5%',
      },
    },
    modalTitle: {
      [theme.breakpoints.up(1440)]: {
        fontSize: 26,
      },
    },
    cardInputContainer: {
      borderBottom: `2px solid ${colors.primaryDarkGreen}`,
      paddingBottom: theme.spacing(1),
    },
    actions: {
      opacity: 0.7,
    },
  })
);
