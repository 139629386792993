import React, { FC } from 'react';

import { useDispatch } from 'react-redux';
import ROUTES from 'routes';
import { routerActions } from 'store/actions';
import useSelectorSafe from 'store/selectors/useSelectorSafe';

import useCurrentRoute from '../../../store/selectors/useCurrentRoute';
import { VoucherCardPublicProps } from './VoucherCard.props';
import View from './VoucherCard.view';

const VoucherCardContainer: FC<VoucherCardPublicProps> = (props) => {
  const dispatch = useDispatch();
  const currentRoute = useCurrentRoute();
  const currentUser = useSelectorSafe((state) => state.user.data?.user);

  return (
    <View
      {...props}
      onPrescribe={(payload: Record<string, any>) => {
        window.analytics.track('Prescription_ATC', {
          prescriptionName: payload.symptom,
          price: payload.price,
        });

        dispatch(routerActions.link(ROUTES.VOUCHER_PAYMENTS, payload));
      }}
      isInPaymentsPage={currentRoute === ROUTES.VOUCHER_PAYMENTS}
      currentUser={currentUser}
    />
  );
};

export default React.memo(VoucherCardContainer);
