import get from './get';
import getById from './getById';
import getByName from './getByName';
import getCategories from './getCategories';
import getForCalendar from './getForCalendar';
import getOffers from './getOffers';

const entityThunks = {
  get,
  getById,
  getByName,
  getForCalendar,
  getCategories,
  getOffers,
};

export default entityThunks;
