/* eslint-disable prettier/prettier */
import React, { FC, Fragment, useEffect } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import MenuIcon from '@material-ui/icons/Menu';
import ReactGA from 'react-ga';
import useCurrentRoute from 'store/selectors/useCurrentRoute';

import { CalendarIcon, Logo } from '../../../assets/svgs';
import { colors } from '../../../themeConfig/themeConstants';
import { HorizontalLine, Link, Modal, Typo } from '../../primitives';
import { AppBarFieldsProps } from './AppBarFields.props';
import { useStyles } from './AppBarFields.styles';

// @ts-ignore
const ModalContent: FC<T> = (props) => {
  const classes = useStyles(props);
  const { mobileItems, subItems } = props;
  const itemsLength = mobileItems.length;

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
      }}
    >
      <Box style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        {/*
          // @ts-ignore */}
        {mobileItems.map(({ header, links }, index) => (
          <Fragment key={header}>
            <Box mb={1.25}>
              <Typo variant="h4">{header}</Typo>
            </Box>
            {links.map((link: { title: string; onClick: () => void }) => (
              <Box key={link.title} mb={0.5}>
                <Link onClick={link.onClick}>
                  <Typo variant="body2">{link.title}</Typo>
                </Link>
              </Box>
            ))}
            {itemsLength === index + 1 ? null : (
              <HorizontalLine className={classes.horizontalLine} />
            )}
          </Fragment>
        ))}
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        {subItems && <HorizontalLine className={classes.horizontalLine} />}
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          {subItems &&
            subItems.map(
              (
                subItem: { title: string; onClick: () => void },
                index: number
              ) => (
                <Box key={subItem.title} className={classes.subItem}>
                  <Link onClick={subItem.onClick}>
                    <Typo variant="body2">{subItem.title}</Typo>
                  </Link>
                  {(index + 1) % 2 === 0 ? null : (
                    <Divider
                      orientation="vertical"
                      className={classes.verticalLine}
                    />
                  )}
                </Box>
              )
            )}
        </Box>
      </Box>
    </Box>
  );
};

// @ts-ignore
const HideOnScroll = (props) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {props.children}
    </Slide>
  );
};

const AppBarFieldsView: FC<AppBarFieldsProps> = ({
  mobileItems = [],
  subItems = [],
  linkColor = colors.primaryDarkGreen,
  leftAppBarItems = [],
  appBarItems = [],
  goToHome,
  hideFilterIcon,
  showMobileFilter,
}: AppBarFieldsProps) => {
  const trigger = useScrollTrigger();
  const [open, setOpen] = React.useState(false);
  const [elevation, setElevation] = React.useState(0);
  const [isSticky, setIsSticky] = React.useState(false);
  const ref = React.createRef();

  const classes = useStyles();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const currentRoute = useCurrentRoute();

  const onShowMobileFilter = () => {
    if (typeof showMobileFilter === 'function') {
      ReactGA.event({
        category: 'Navigation',
        action: 'Clicked Mobile filter',
      });

      showMobileFilter();
    }
  };

  const isActive = (nav) => {
    if (currentRoute.id === 'JOURNAL' && nav === 'Latest') {
      return classes.journalLink;
    }

    if (currentRoute.id === 'FIELD_RECORDINGS' && nav === 'Field Recordings') {
      return classes.journalLink;
    }

    if (currentRoute.id === 'WRITERS_PROGRAM' && nav === "Writer's Program") {
      return classes.journalLink;
    }

    if (currentRoute.id === 'FROM_THE_FIELD' && nav === 'From The Field') {
      return classes.journalLink;
    }

    if (currentRoute.id === 'DISPATCHES' && nav === 'Dispatches') {
      return classes.journalLink;
    }

    if (currentRoute.id === 'PERSPECTIVE' && nav === 'Perspective') {
      return classes.journalLink;
    }

    return '';
  };

  useEffect(() => {
    const cachedRef = ref.current;
    const observer = new IntersectionObserver(
      ([e]) => setIsSticky(e.boundingClientRect.top < 1),
      { rootMargin: '-1px 0px 0px 0px', threshold: [1] }
    );
    observer.observe(cachedRef);

    return function () {
      observer.unobserve(cachedRef);
    };
  });

  useEffect(() => {
    if (isSticky) {
      setElevation(4);
    } else {
      setElevation(0);
    }
  }, [isSticky]);

  useEffect(() => {
    setOpen(false);
  }, [isLarge]);

  return (
    <AppBar
      ref={ref}
      className={classes.appBar}
      elevation={elevation}
      style={{ position: 'sticky' }}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden mdUp>
          <Box
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'start',
              flexDirection: 'row',
            }}
          >
            <IconButton
              edge="start"
              style={{ color: linkColor }}
              aria-label="menu"
              disableRipple={false}
              onClick={() => setOpen(true)}
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </Box>
        </Hidden>
        <Hidden smDown>
          <Box
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'left',
              flexDirection: 'row',
            }}
          >
            <Grid item xs={2} md={2}>
              <Box style={{ display: 'flex', flexDirection: 'row' }}>
                {leftAppBarItems.map((item) => (
                  <Box key={item.text} mr={4}>
                    <Link onClick={item.onClick} animated={true}>
                      <Typo
                        variant="h5"
                        style={{
                          color: linkColor,
                          fontSize: 14,
                        }}
                      >
                        {item.text}
                      </Typo>
                    </Link>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid
              item
              xs={10}
              md={10}
              style={{
                flexBasis: '70%',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  flexDirection: 'row',
                }}
              >
                {appBarItems.map((item) => (
                  <Box key={item.text} mr={4}>
                    <Link onClick={item.onClick} animated={true}>
                      <Typo
                        variant="h5"
                        style={{
                          color: linkColor,
                          fontSize: 14,
                        }}
                        className={isActive(item.text)}
                      >
                        {item.text}
                      </Typo>
                    </Link>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Box>
        </Hidden>
      </Toolbar>
      <Modal
        open={open}
        closeIconLeft
        onClose={setOpen}
        contentContainerStyle={classes.mobileContainer}
      >
        <ModalContent mobileItems={mobileItems} subItems={subItems} />
      </Modal>
    </AppBar>
  );
};

export default React.memo(AppBarFieldsView);
