import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LeftArrow from 'assets/svgs/generated/LeftArrow';
import { Carousel, GhostButton, TextButton, Typo } from 'components/primitives';
import { Settings as SliderSettings } from 'react-slick';

import { JournalBlogCard } from '../../modules';
import JournalBlogCards from '../JournalBlogCards';
import { RecommendedProps } from './Recommended.props';
import { useStyles } from './Recommended.styles';

const sliderConfig: SliderSettings = {
  autoplay: false,
  arrows: true,
  infinite: true,
  slidesToShow: 1,
};

const CarouselArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <LeftArrow
      className={className}
      style={{ ...style }}
      onClick={() => onClick()}
    />
  );
};

const RecommendedView: FC<RecommendedProps> = (props) => {
  const {
    desktopContainerStyle,
    mobileContainerStyle,
    title,
    desc,
    moreButtonText,
    content,
    goToBlog,
    displayArea,
  } = props;
  const belowTablet = useMediaQuery('(max-width: 767px)');
  const classes = useStyles();
  const [viewNumber, setViewNumber] = React.useState(3);

  const ViewAll = () => {
    const count = 3;
    if (viewNumber + count < content.length) {
      setViewNumber(viewNumber + count);
    } else {
      setViewNumber(content.length);
    }
  };

  if (belowTablet) {
    if (displayArea === 'Home') {
      return <Box className={`${classes.homeMobile} ${mobileContainerStyle}`}>
        {title && (
          <Box mb={2}>
            <Typo variant="h3">{title}</Typo>
          </Box>
        )}
        <Carousel
          {...sliderConfig}
          nextArrow={<CarouselArrow />}
          prevArrow={<CarouselArrow />}
        >
          {content.slice(0, viewNumber).map((item) => (
            <Grid key={item.title} item sm={12}>
              <JournalBlogCard {...item} displayArea={displayArea} />
            </Grid>
          ))}
        </Carousel>
      </Box>
    }
    return (
      <Box className={`${mobileContainerStyle}`}>
        {title && (
          <Box mb={2}>
            <Typo variant="h3">{title}</Typo>
          </Box>
        )}

        {content.slice(0, viewNumber).map((item) => (
          <Grid key={item.title} item sm={12}>
            <JournalBlogCard {...item} displayArea={displayArea} />
          </Grid>
        ))}

        {!moreButtonText && viewNumber >= content.length ? null : (
          <GhostButton
            style={classes.moreButton}
            text={moreButtonText || ''}
            onClick={ViewAll}
          />
        )}
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      {title && (
        <Box className={`${classes.topContainer} ${desktopContainerStyle}`}>
          <Grid item xs={9} md={9}>
            <Box mb={1}>
              <Typo variant="h2">{title}</Typo>
            </Box>
          </Grid>
        </Box>
      )}
      <Box className={`${classes.bottomContainer} ${desktopContainerStyle}`}>
        <JournalBlogCards
          displayArea={displayArea}
          content={content.slice(0, viewNumber)}
        />
        {
          <Grid
            item
            xs={12}
            md={12}
            style={{
              textAlign: displayArea === 'Home' ? 'center' : 'right',
              alignSelf: 'flex-end',
            }}
          >
            {displayArea === 'Home' ? (
              <></>
            ) : (
              <TextButton
                style={classes.seeMore}
                color="primaryWhite"
                text={moreButtonText || 'More'}
                onClick={ViewAll}
              />
            )}
          </Grid>
        }
      </Box>
    </Box>
  );
};

export default React.memo(RecommendedView);
