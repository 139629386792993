import React, { FC } from 'react';

import Bush from 'assets/specifications/Bush.png';
import ReceptionFull from 'assets/specifications/Reception Full.png';
import ReceptionHalf from 'assets/specifications/Reception Half.png';
import ReceptionNone from 'assets/specifications/Reception None.png';
import Waterfall from 'assets/specifications/Waterfall.png';
import Wifi from 'assets/specifications/Wifi.png';
import Wildlife from 'assets/specifications/Wildlife.png';
import Winery from 'assets/specifications/Winery.png';

import { ImageContainer } from '..';
import { HouseSpecificationIconProps } from './HouseSpecificationIcon.props';

const HouseSpecificationIconView: FC<HouseSpecificationIconProps> = ({
  type,
  src,
  width,
  height,
}) => {
  const specificationImage = {
    'Body of water':
      'https://uny-assets.s3-ap-southeast-2.amazonaws.com/specifications/Body+of+WaterGreen.png',
    'Dirt road':
      'https://uny-assets.s3-ap-southeast-2.amazonaws.com/specifications/Dirt+RoadGreen.png',
    'Dog Friendly':
      'https://uny-assets.s3-ap-southeast-2.amazonaws.com/specifications/Doggo.png',
    Forest:
      'https://uny-assets.s3-ap-southeast-2.amazonaws.com/specifications/ForestGreen.png',
    Glade:
      'https://uny-assets.s3-ap-southeast-2.amazonaws.com/specifications/GladeGreen.png',
    Hills:
      'https://uny-assets.s3-ap-southeast-2.amazonaws.com/specifications/HillsGreen.png',
    'Mountain range':
      'https://uny-assets.s3-ap-southeast-2.amazonaws.com/specifications/Mountain+RangeGreen.png',
    Mountains:
      'https://uny-assets.s3-ap-southeast-2.amazonaws.com/specifications/MountainsGreen.png',
    Valley:
      'https://uny-assets.s3-ap-southeast-2.amazonaws.com/specifications/ValleyGreen.png',
    'Wi-fi': Wifi,
    'No Reception': ReceptionNone,
    'Reception (half)': ReceptionHalf,
    'Some Reception': ReceptionHalf,
    'Full Reception': ReceptionFull,
    Bush,
    Waterfall,
    Wildlife,
    Winery,
    Wifi,

    Qualmark:
      'https://openbook-v2.s3.ap-southeast-2.amazonaws.com/openbook/gJrEG4krvlqR394p7rUsPRPQKM46P56PoE7SFhTM.png',
    Secluded:
      'https://openbook-v2.s3.ap-southeast-2.amazonaws.com/openbook/5n79Ytq3oxGXHgohRbeobyWiDGlu0FvigJze3KMO.png',
    'Wood-fired Bath':
      'https://openbook-v2.s3.ap-southeast-2.amazonaws.com/openbook/X8JkTHlACM7roEbQ0AZ5wT7jrBGY7xusRLkzH2ya.png',
    'Family Friendly':
      'https://openbook-v2.s3.ap-southeast-2.amazonaws.com/openbook/Y5yVnIMQDlOG4HVxvIDonxAxMzDhvxguLX21m5at.png',
    Ocean:
      'https://openbook-v2.s3.ap-southeast-2.amazonaws.com/openbook/yzHtRq2swweoMta1r8WA17BDTtHxCFnG0D9ZiSuS.png',
    'Farm Animals':
      'https://openbook-v2.s3.ap-southeast-2.amazonaws.com/openbook/sH0OB7282fS6uVuZN59CvFtQOfAYDWrDollJAtHQ.webp',
    Hikes:
      'https://openbook-v2.s3.ap-southeast-2.amazonaws.com/openbook/7zmSFnV5CR0w70LLnTDH8a3yHNpAI7P78C4fm1EZ.png',
    'Pet Friendly':
      'https://openbook-v2.s3.ap-southeast-2.amazonaws.com/openbook/l4Tjsr3o0NxwQ1he2zWOHodOOUh5x9L8Uc1MPTz2.png',
  };
  return (
    <ImageContainer
      src={src || specificationImage[type || 'Forest']}
      alt={type || 'Forest'}
      style={{ width, height, objectFit: 'contain' }}
    />
  );
};

export default React.memo(HouseSpecificationIconView);
