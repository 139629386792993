import React, { FC, ReactElement, useEffect, useRef } from 'react';

import Box from '@material-ui/core/Box';
import { useScrollSpy } from 'context/ScrollSpyContext';

import { ScrollSpyProps } from './ScrollSpy.props';

const ScrollSpyView: FC<ScrollSpyProps> = (props: ScrollSpyProps) => {
  const { setVisibleSection, visibleSection } = useScrollSpy();
  const parentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!parentRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!visibleSection) setVisibleSection(entry.target.id);

        if (entry.isIntersecting) {
          setVisibleSection(entry.target.id);
        }
      });
    });

    [...parentRef.current.children].forEach((child) => {
      observer.observe(child);
    });

    return () => {
      if (!parentRef?.current?.children) return;
      [...parentRef?.current?.children].forEach((child) => {
        observer.unobserve(child);
      });
    };
  });

  return <div ref={parentRef}>{props.children}</div>;
};

export default ScrollSpyView;
