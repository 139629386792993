// ensure that id is in lowercase

export const cabins = [
  {
    id: 'bfef8080-0c21-11ea-9ea1-b770a28f0be3',
    url: 'xwt',
  },
  {
    id: 'd28b57a0-05c4-11ea-829c-8972024cd107',
    url: 'heike',
  },
  {
    id: 'be9cb350-060f-11ea-8383-8f93e1dd937c',
    url: 'rex',
  },
  {
    id: 'dcbe3ee0-060f-11ea-80a0-99e4ffead9a2',
    url: 'lucia',
  },
  {
    id: '24948da0-0661-11ea-8b82-4bc6f52d0a53',
    url: 'coralie',
  },
  {
    id: '877ca910-05c4-11ea-bc3b-9b901bd6b4e9',
    url: 'miguel',
  },
  {
    id: '3a21bf20-0661-11ea-8c05-639d24f6d5f1',
    url: 'yogie',
  },
  {
    id: '4b9bafe0-0661-11ea-b42c-8b06d9165dd4',
    url: 'wal',
  },
  {
    id: '5a8b4b20-0661-11ea-a265-7924e9b6696f',
    url: 'louka',
  },
  {
    id: '620d0df0-0661-11ea-9c72-c5f36f6080d2',
    url: 'anant',
  },
  {
    id: '74873fb0-0661-11ea-96b0-93787cd6d648',
    url: 'micah',
  },
  {
    id: '844e0780-0661-11ea-b19b-b7f35653c9c7',
    url: 'neada',
  },
  {
    id: 'b00b0830-64b8-11ea-99ec-4bc203601cdd',
    url: 'remy',
  },
  {
    id: '1f994e80-af80-11ea-98c4-3f992bf60e3e',
    url: 'das',
  },
  {
    id: 'c9901820-af7b-11ea-9931-77fb51fbe181',
    url: 'sadie',
  },
  {
    id: '2c5d5a50-ca58-11ea-8d80-1d031a3f24b7',
    url: 'sadie-in-bed',
  },
  {
    id: '6379f8e0-af80-11ea-8140-e3cc65f778b2',
    url: 'fern',
  },
  {
    id: '95a66b80-af80-11ea-bf07-a70f0b468ab4',
    url: 'yen',
  },
  {
    id: '2dfb0010-1fcd-11eb-bee6-c97823c526b1',
    url: 'ashi',
  },
  {
    id: '4a6d6750-1fcd-11eb-a003-d3969ee09926',
    url: 'pana',
  },
  {
    id: '801aa460-2497-11eb-b12f-11d39e795f95',
    url: 'veigo',
  },
  {
    id: 'd9c8e8e0-2497-11eb-accb-7beca9d33f2e',
    url: 'dawn',
  },
  {
    id: 'a8fbace0-2497-11eb-a067-85044c453d3d',
    url: 'lila',
  },
  {
    id: '01d43560-2498-11eb-a8a9-2131efe8193f',
    url: 'nicho',
  },
  {
    id: '976ea2b0-65df-11eb-b14c-978d42a5e746',
    url: 'bastian',
  },
  {
    id: 'b881e7d0-65df-11eb-ac05-55e803596258',
    url: 'saro',
  },
  {
    id: '4bbdb520-65df-11eb-b66b-c372784ca1c3',
    url: 'pascal',
  },
  {
    id: '72abb3f0-65df-11eb-a246-b1099fb5faf4',
    url: 'rani',
  },
  {
    id: 'd7368500-65de-11eb-a11d-a5a8b3b87e79',
    url: 'banjo',
  },
  {
    id: '6d1f4f70-a1d0-11eb-8d9b-6300c768abbb',
    url: 'yenya',
  },
  {
    id: '1b11c300-65df-11eb-b70f-034c5be50b30',
    url: 'kaia',
  },
];


const isProd = import.meta.env.VITE_NODE_ENV === 'prod';

const esme: string = isProd
  ? '20eb1020-0749-11ed-971c-6d2814da6cbd'
  : '20eb1020-0749-11ed-971c-6d2814da6cbd'

const cabinTime = {
  availability: {
    [esme]: { start: '4pm', end: '12pm' }
  }
}

export function getCabinTime(cabinId: string) {
  return cabinTime.availability[cabinId] ?? { start: '3pm', end: '11am' };
}