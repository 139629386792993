import React, { FC, ReactElement, useEffect, useState } from 'react';

import { Grid, Radio } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Button, Snackbar, Typo } from 'components/primitives';
import { isAfterpayDisabled } from 'consts/afterpay';
import { CURRENCY } from 'consts/locations';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';

import { PaymentSetupProps } from './PaymentSetup.props';
import { useStyles } from './PaymentSetup.styles';

const PaymentSetupView: FC<PaymentSetupProps> = (props: PaymentSetupProps) => {
  const classes = useStyles({});

  const [manualPayment, setIsManualPayment] = useState(true);
  const [updatePaymentOptionOnce, setUpdatePaymentOptionOnce] = useState(false);
  const [afterPayWidget, setAfterPayWidget] = useState<any>();

  const {
    method,
    intent,
    afterpay,
    voucherType,
    intentError,
    afterpayError,
    intentStatus,
    afterpayStatus,
    addProvisions,
    onPayNow,
    onPayWithAfterPay,
    clearIntent,
    clearAfterpay,
  } = props;

  const [loading, setLoading] = useState(
    [afterpayStatus, intentStatus].indexOf(ASYNC_STATUS.LOADING) > -1
  );
  const [errorMessage, setErrorMessage] = useState(
    intentError?.message || afterpayError?.message || 'Please try again!'
  );
  const [open, setOpen] = useState(
    intentError?.message || afterpayError?.message ? true : false
  );
  const [paymentTimeout, setPaymentTimeout] = useState(0);

  const loadAfterpayWidget = () => {
    if (props.amount > 0) {
      // @ts-ignore
      const AfterPay: any = window['AfterPay'];
      if (!AfterPay) {
        return console.error('AfterPay is undefined.');
      }

      if (isAfterpayDisabled(props.country, addProvisions)) {
        return console.log(`AfterPay is disabled for ${props.country}`);
      }

      const attributes = {
        currency: CURRENCY[props.country],
        amount: props.amount,
        introText: 'Pay',
        showInterestFree: false,
        styles: {
          fontFamily: 'Plain, sans-serif',
        },
      };

      if (!afterPayWidget) {
        setAfterPayWidget(
          new AfterPay.Widgets.Placement({
            target: '#btn-pay-with-afterpay',
            locale: `en-${props.country}`,
            context: 'CHECKOUT',
            publicKey: 'testKey',
            attributes,
          })
        );
      } else {
        afterPayWidget.update(attributes);
      }
    }
  };

  useEffect(() => {
    const isLoading =
      [afterpayStatus, intentStatus].indexOf(ASYNC_STATUS.LOADING) > -1;
    setLoading(isLoading);
    if (isLoading) {
      const timeout = setTimeout(() => {
        setLoading(false);
        clearIntent();
        clearAfterpay();
        setAfterPayWidget(null);
        setTimeout(() => {
          loadAfterpayWidget();
          if (!props.amount) {
            setErrorMessage(`It seems you haven't added a provision to add`);
          } else {
            setErrorMessage('Payment Timeout, Please try again later!');
          }
          setOpen(true);
        }, 100);
      }, 30000);
      setPaymentTimeout(timeout);
    } else {
      if (paymentTimeout) {
        clearTimeout(paymentTimeout);
        setPaymentTimeout(0);
      }
    }
  }, [intentStatus, afterpayStatus]);

  useEffect(() => {
    if (intentError?.message || afterpayError?.message) {
      setOpen(true);
      setErrorMessage(intentError?.message || afterpayError?.message);
      if (paymentTimeout) {
        clearTimeout(paymentTimeout);
        setPaymentTimeout(0);
        if (
          afterpayStatus != 'DISABLED' &&
          !isAfterpayDisabled(props.country, addProvisions)
        ) {
          setAfterPayWidget(null);
          loadAfterpayWidget();
        }

        setTimeout(() => {
          clearAfterpay();
          clearIntent();
        }, 5000);
      }
    } else {
      setOpen(false);
    }
  }, [intentError, afterpayError]);

  useEffect(() => {
    if (!updatePaymentOptionOnce && method) {
      setIsManualPayment(!method);
      setUpdatePaymentOptionOnce(true);
    }
  }, [method]);

  useEffect(() => {
    loadAfterpayWidget();

    return () => {
      if (paymentTimeout) {
        clearTimeout(paymentTimeout);
        setPaymentTimeout(0);
      }
    };
  });

  return (
    <Box>
      {method && (
        <Grid container direction="row">
          <Grid item xs={12} sm={12}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                marginLeft: -10,
              }}
            >
              <Radio
                checked={!manualPayment}
                onChange={() => setIsManualPayment(false)}
                value="saved"
                name="saved-payment-option"
                inputProps={{ 'aria-label': 'A' }}
              />
              <Typo variant="h6" style={{ marginBottom: 8 }}>
                Saved Payment
              </Typo>
            </Box>
            <Typo variant="body3" style={{ marginBottom: 8 }}>
              ...{method.id.substring(20, method.id.length)} {method.brand}....
              {method.last4Number}
            </Typo>
            <Typo variant="body3" style={{ marginBottom: 32 }}>
              {method.name}
            </Typo>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                marginLeft: -10,
                marginBottom: 32,
              }}
            >
              <Radio
                checked={manualPayment}
                onChange={() => setIsManualPayment(true)}
                value="manual"
                name="manual-payment-option"
                inputProps={{ 'aria-label': 'A' }}
              />
              <Typo variant="h6" style={{ marginBottom: 8 }}>
                One-Time Payment
              </Typo>
            </Box>
          </Grid>
        </Grid>
      )}
      <Button
        disabled={loading}
        text={loading ? 'Loading ...' : `Pay now with Card`}
        onClick={() => {
          setLoading(true);
          onPayNow(manualPayment ? null : method);
        }}
      />
      {props.amount > 0 &&
      afterpayStatus != 'DISABLED' &&
      !isAfterpayDisabled(props.country, addProvisions) ? (
        <>
          <Box mb={8} />
          <Typo variant={'h6'}>-- OR --</Typo>
          <Button
            disabled={loading}
            text={
              loading ? (
                'Loading ...'
              ) : (
                <span
                  className={classes.btnAfterPay}
                  id="btn-pay-with-afterpay"
                ></span>
              )
            }
            onClick={() => {
              if (voucherType && voucherType != 'gift_card') {
                setErrorMessage(
                  `Discount codes are not available with Afterpay. Please try another payment method.`
                );
                setOpen(true);
                return;
              }

              if (loading) return;
              setLoading(true);
              onPayWithAfterPay();
            }}
          />
        </>
      ) : null}
      <Snackbar
        autoHideDuration={null}
        message={errorMessage}
        open={open}
        type="error"
        onClose={() => setOpen(false)}
      />
    </Box>
  );
};

export default PaymentSetupView;
