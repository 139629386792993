import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '../../../themeConfig/themeConstants';

interface StyleProps {
  isMobile?: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: ({ isMobile }: StyleProps) => ({
      position: 'relative',
      minHeight: isMobile ? '100%' : '108%',
    }),
    line: {
      backgroundColor: colors.primaryDarkGreen,
      height: 2.5,
    },
    image: {
      width: '100%',
      objectFit: 'contain',
      height: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      // below tablets
      [theme.breakpoints.up(767)]: {
        height: 150,
      },
      [theme.breakpoints.up('md')]: {
        height: 175,
      },
      [theme.breakpoints.up('lg')]: {
        height: 250,
      },
    },
    bag: ({ isMobile }: StyleProps) => ({
      position: 'absolute',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      borderBottom: '2px solid',
      bottom: isMobile ? -40 : 0,
    }),
    ghostButton: {
      width: 180,
    },
    notAvailable: { 
      paddingLeft: '35px', 
      paddingRight: '35px', 
      fontSize: '0.6rem', 
      borderWidth: '3px !important', 
      fontWeight: 'bolder', 
      marginBottom: '5px' 
    }
  })
);
