import { CheckoutState } from 'types/store/CheckoutState';
import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'CHECKOUT';
const createCheckoutActions = makeCreateActions(ns);

const checkout = createCheckoutActions({
  setProps: presetActions.makeIdentity<CheckoutState>(),
});

export default checkout;
