import React, { FC } from 'react';

import { ContactUsPublicProps } from './ContactUs.props';
import View from './ContactUs.view';

const ContactUsContainer: FC<ContactUsPublicProps> = (props) => {
  return <View {...props} />;
};

export default React.memo(ContactUsContainer);
