import * as React from 'react';

function SvgRightArrow(props) {
  return (
    <svg width={15} height={26} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1 1l12 12.163L1 25"
        stroke="#F4F3D8"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgRightArrow;
